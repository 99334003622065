import { FormattedMessage } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { Button } from 'reactstrap';
import CSVLinkAsync, { HeaderDescriptor } from 'components/CSVLinkAsync';
import { formatPeopleForCSV } from './helpers';
import { createCSVFileName } from 'utils/util/util';
import { ICONS } from 'consts/consts';

interface Props {
  data: object[];
  columns: object[];
  headers: HeaderDescriptor[];
  exportActionTextFunction: (num: number) => string;
  personIsTopLevelObject: boolean;
}

const CSVDownload: FC<Props> = ({
  data,
  columns,
  headers,
  exportActionTextFunction,
  personIsTopLevelObject,
}) => {
  const [isDownloadRequested, setIsDownloadRequested] =
    useState<boolean>(false);

  const handleClick = useCallback(() => {
    setIsDownloadRequested(true);
  }, []);

  const onDownloadComplete = useCallback(() => {
    setIsDownloadRequested(false);
  }, []);

  const dataGenerator = useMemo(() => {
    if (isDownloadRequested) {
      return () => formatPeopleForCSV(data, columns, personIsTopLevelObject);
    }

    return undefined;
  }, [data, columns, isDownloadRequested, personIsTopLevelObject]);

  return (
    <>
      <Button color="light" className="btn-sm" onClick={handleClick}>
        <i className={ICONS.EXPORT + ' me-2'} />
        {exportActionTextFunction && exportActionTextFunction(data.length)}
        {!exportActionTextFunction && (
          <FormattedMessage
            id="app.widgets.task.export_people"
            defaultMessage="Export {count} people to CSV"
            values={{
              count: data.length,
            }}
          />
        )}
      </Button>
      {dataGenerator && (
        <CSVLinkAsync
          getData={dataGenerator}
          headers={headers}
          filename={createCSVFileName()}
          onDownloadComplete={onDownloadComplete}
        />
      )}
    </>
  );
};

export default CSVDownload;
