import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { capitalize } from '../../../utils/util/formatter';

const ObjectsDropdown: FC<Props> = ({
  selectText = (
    <FormattedMessage
      id="app.views.widgets.dropdowns.objects_dropdown.select_text.default"
      defaultMessage="Select..."
    />
  ),
  alwaysShowSelectText = false,
  disabled = false,
  ...props
}) => {
  const [validationMessage, setValidationMessage] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedTopLevelIndex, setSelectedTopLevelIndex] = useState(-1);

  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;

  const incomingValue = useMemo(() => {
    if (typeof props.value === 'string') {
      return props.value;
    }

    // if value is list of size one, extract from that list intelligently
    // (useful for when input is a singleton tag, e.g. skill, e.g. for custom
    // dropdown for positive_skills/negative_skills for a given perf
    // campaign); NOTE: value could be the integer 0 so check if
    // not undefined/null via != null
    return props.value != null && props.valueFromAttribute
      ? Array.isArray(props.value) && props.value.length === 1
        ? props.value[0][props.valueFromAttribute]
        : props.value[props.valueFromAttribute]
      : props.value;
  }, [props.value, props.valueFromAttribute]);

  const [selectedIndex, setSelectedIndex] = useState(
    // value is object id
    // @ts-expect-error
    props.objects && props.objects.findIndex((o) => o.id === incomingValue)
  );

  // if passed in object or value changes, update UI accordingly
  useEffect(() => {
    // value is object id
    setSelectedIndex(
      // @ts-expect-error
      props.objects && props.objects.findIndex((o) => o.id === incomingValue)
    );
  }, [props.objects, incomingValue]);

  useEffect(() => {
    if (propsRequired) {
      const isValid =
        typeof incomingValue !== 'undefined' && incomingValue !== null;
      // @ts-expect-error
      setValidationMessage(isValid ? null : 'Please select an answer.');
    } else {
      setValidationMessage(null);
    }
  }, [incomingValue, propsRequired]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  const propsOnChange = props.onChange;

  const renderItem = useMemo(
    () =>
      props.renderItem
        ? props.renderItem
        : (o) => {
            const title = capitalize(
              typeof o.name !== 'undefined' ? o.name : o.toString()
            );

            return o.icon ? (
              <>
                <i className={'me-2 ' + o.icon} />
                <span>{title}</span>
              </>
            ) : (
              title
            );
          },
    [props.renderItem]
  );

  const renderOption = props.renderOption ? props.renderOption : renderItem;

  const toggle = useCallback(() => {
    if (dropdownOpen) {
      setSelectedTopLevelIndex(-1);
    }

    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const selectIndex = useCallback(
    (index) => {
      // only allow the selected value to be changed programmatically
      // (this is meant for when changing the value should show a modal or other
      // kind of prompt to confirm the change)
      if (!props.onlyAllowProgrammaticChanges) {
        setSelectedIndex(index);
        setSelectedTopLevelIndex(-1);
      }

      toggle();

      if (propsOnChange) {
        propsOnChange({
          target: {
            name: props.name,
            // @ts-expect-error
            value: props.objects[index].id,
          },
        });
      }
    },
    [
      props.name,
      props.objects,
      props.onlyAllowProgrammaticChanges,
      propsOnChange,
      toggle,
    ]
  );

  const options = useMemo(() => {
    if (props.submenuByField) {
      // get list of top level menu items based on
      // whatever the submenu items are (and make unique)
      return [
        // @ts-expect-error
        ...new Set(props.objects.map((o) => o[props.submenuByField])),
      ].map((o) => ({
        id: o,
        name: o,
      }));
    } else {
      return props.objects;
    }
  }, [props.objects, props.submenuByField]);

  const onClickTopItem = useCallback(
    (obj, index) => {
      if (props.submenuByField) {
        // do nothing; just show submenu
      } else {
        return selectIndex(index);
      }
    },
    [props.submenuByField, selectIndex]
  );

  const propsRenderToggle = props.renderToggle;
  const dropdownToggle = useMemo(() => {
    if (props.renderToggle) {
      // @ts-expect-error
      return propsRenderToggle(
        selectedIndex === -1 || alwaysShowSelectText
          ? selectText
          : props.objects
          ? renderItem(props.objects[selectedIndex], toggle)
          : '',
        toggle
      );
    } else {
      return (
        // @ts-expect-error
        <DropdownToggle
          size={props.size}
          className={props.buttonClassName}
          // ensure that any super-long selections don't overflow the current page
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
          caret
          color="light"
        >
          {selectedIndex === -1 || alwaysShowSelectText
            ? selectText
            : props.objects
            ? renderItem(props.objects[selectedIndex], toggle)
            : ''}
        </DropdownToggle>
      );
    }
  }, [
    alwaysShowSelectText,
    props.buttonClassName,
    props.objects,
    props.renderToggle,
    selectText,
    props.size,
    propsRenderToggle,
    renderItem,
    selectedIndex,
    toggle,
  ]);

  const getSuboptions = useCallback(
    (obj) => {
      const suboptions = props.objects
        ? // @ts-expect-error
          props.objects.filter((o) => o[props.submenuByField] === obj.id)
        : undefined;

      return suboptions;
    },
    [props.objects, props.submenuByField]
  );

  if (props.submenuByField) {
    return (
      // @ts-expect-error
      <Dropdown
        className={props.className}
        isOpen={dropdownOpen}
        toggle={toggle}
        disabled={disabled}
      >
        {dropdownToggle}
        <DropdownMenu end={props.alignRight ? true : undefined}>
          {options.map((obj, index) => (
            <li key={index} className="dropright">
              <span
                role="button"
                className={
                  'dropdown-item dropdown-toggle' +
                  (selectedTopLevelIndex === index ? ' dropdown show' : '') +
                  (props.dropdownItemClassName
                    ? ' ' + props.dropdownItemClassName
                    : '')
                }
                onClick={() =>
                  setSelectedTopLevelIndex(
                    selectedTopLevelIndex === index ? -1 : index
                  )
                }
              >
                {renderOption(obj)}
              </span>
              {selectedTopLevelIndex === index && getSuboptions(obj) && (
                <DropdownMenu>
                  {/* @ts-expect-error */}
                  {getSuboptions(obj).map((subObj, subIndex) => (
                    <DropdownItem
                      // @ts-expect-error
                      className={props.dropdownItemClassName}
                      key={subIndex}
                      onClick={() =>
                        selectIndex(
                          props.objects &&
                            props.objects.findIndex((o) => o === subObj)
                        )
                      }
                    >
                      {renderOption(subObj)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </li>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  } else {
    return (
      // @ts-expect-error
      <Dropdown
        className={props.className}
        isOpen={dropdownOpen}
        toggle={toggle}
        disabled={disabled}
      >
        {dropdownToggle}
        <DropdownMenu
          // override Dashkit defaults so we don't have superwide menus
          style={{ minWidth: 'inherit' }}
          end={props.alignRight ? true : undefined}
        >
          {options?.map((obj, index) => (
            <DropdownItem
              // override Dashkit defaults so we don't have superwide menus
              style={{ width: 'inherit' }}
              // @ts-expect-error
              className={props.dropdownItemClassName}
              onClick={() => onClickTopItem(obj, index)}
              // @ts-expect-error
              key={obj.id !== undefined ? obj.id : index}
              // @ts-expect-error
              value={obj.id !== undefined ? obj.id : index}
            >
              {renderOption(obj)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
};

const ObjectsDropdown_propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  dropdownItemClassName: PropTypes.string,
  alwaysShowSelectText: PropTypes.bool,
  alignRight: PropTypes.bool,
  objects: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  renderToggle: PropTypes.func,
  renderItem: PropTypes.func,
  renderOption: PropTypes.func,
  selectText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  onValidChange: PropTypes.func,
  // value is id of object (not entire object itself), OR,
  // if valueFromAttribute is set, then we allow for entire object
  // and get value from the provided object's attribute
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    // can be an array of length 1, for smart handling of tags
    PropTypes.array,
  ]),
  valueFromAttribute: PropTypes.string,
  submenuByField: PropTypes.string,
  onlyAllowProgrammaticChanges: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof ObjectsDropdown_propTypes>;

export default React.memo(ObjectsDropdown);
