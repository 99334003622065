import * as consts from '../../../consts/consts';

import {
  CAMPAIGN_STATUSES,
  getPhaseByType,
  hasPhase,
} from '../../../utils/models/Campaign';
import {
  Campaign,
  OrganizationSettings,
  Person,
  PersonProfileDeltasMode,
  ReduxState,
} from '../../../types';
import {
  PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_SELF,
  getCampaignHasFeatureEnabledDefaultOn,
  getCampaignHasONA,
  getPhaseDateHasPassed,
} from '../../../utils/models/Performance';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
} from 'react';

import { PerformanceHistoryData } from './Trajectory/HistoryChartUtils';
import { isEnabledWithDefault } from 'utils/util/util';
import { useFeatures } from 'utils/util/features';
import { useSelector } from 'react-redux';

export interface PersonPerformanceContextData {
  canDisplayOna: boolean;
  showGoldStarsAndInfluenceToRecipient: boolean;
  displayRatingWidgets: boolean;
  displayCharts: boolean;
  canDisplayDeltas: boolean;
  performanceHistoryData: PerformanceHistoryData[];
  selectedCampaignData?: PerformanceHistoryData;
  previousCampaignDataWithOna?: PerformanceHistoryData;
  previousCampaignDataWithRating?: PerformanceHistoryData;
}

interface PerformanceCampaign extends Campaign {
  is_admin: boolean;
  status: string;
}

interface PersonPerformanceContextProviderProps {
  campaign: PerformanceCampaign;
  performanceHistoryData: PerformanceHistoryData[];
  person?: Person;
  meId?: number;
  isInReviewFlow: boolean;
  showManagerOnlyPerformanceDetails: boolean;
  setDisplayRatingWidgets?: (value: boolean) => void;
}

const PersonPerformanceContext = createContext<PersonPerformanceContextData>({
  canDisplayOna: false,
  showGoldStarsAndInfluenceToRecipient: false,
  displayRatingWidgets: false,
  displayCharts: false,
  canDisplayDeltas: false,
  performanceHistoryData: [],
  selectedCampaignData: undefined,
  previousCampaignDataWithOna: undefined,
  previousCampaignDataWithRating: undefined,
});

const getCanDisplayDeltas = (
  deltasMode: PersonProfileDeltasMode,
  isViewingSelf: boolean,
  showManagerOnlyPerformanceDetails: boolean
) => {
  switch (deltasMode) {
    case PersonProfileDeltasMode.EXCEPT_SELF:
      return !isViewingSelf && showManagerOnlyPerformanceDetails;
    case PersonProfileDeltasMode.ALL:
      return true;
    case PersonProfileDeltasMode.OFF:
    default:
      return false;
  }
};

const findPreviousCampaignWithRating = (
  data: PerformanceHistoryData[],
  currentCampaignIndex: number
): PerformanceHistoryData | undefined => {
  const campaignsBefore = data.slice(0, currentCampaignIndex).reverse();
  return campaignsBefore.find(
    (x) => x.rating?.value != null && x?.is_eligible_for_reporting
  );
};

const findPreviousCampaignWithOna = (
  data: PerformanceHistoryData[],
  currentCampaignIndex: number
): PerformanceHistoryData | undefined => {
  const campaignsBefore = data.slice(0, currentCampaignIndex).reverse();
  return campaignsBefore.find((x) => getCampaignHasONA(x.campaign));
};

export const PersonPerformanceContextProvider: FC<
  PropsWithChildren<PersonPerformanceContextProviderProps>
> = ({
  campaign,
  person,
  meId,
  isInReviewFlow,
  setDisplayRatingWidgets,
  showManagerOnlyPerformanceDetails,
  performanceHistoryData,
  children,
}) => {
  const currentProxyPerson = useSelector<ReduxState, Person | undefined>(
    (state) => state.currentProxyPerson
  );

  const orgSettings = useSelector<ReduxState, OrganizationSettings>(
    (state) => state.settings
  );

  const { features } = useFeatures();

  const showGoldStarsAndInfluenceToRecipient = useMemo(() => {
    return getCampaignHasFeatureEnabledDefaultOn(
      campaign,
      PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT
    );
  }, [campaign]);

  const isAdmin = useMemo(() => campaign?.is_admin, [campaign?.is_admin]);

  const viewerId = currentProxyPerson?.id ?? meId;

  const isViewingSelf = viewerId != null && viewerId === person?.id;

  const displayRatingWidgets = useMemo(
    () => hasPhase(campaign, PHASE_TYPE_EVALUATION),
    [campaign]
  );

  const displayCharts = isEnabledWithDefault(
    features,
    consts.FLAGS.PERFORMANCE_PROFILE_ENABLE_CHARTS,
    true
  );

  useEffect(() => {
    if (setDisplayRatingWidgets) {
      setDisplayRatingWidgets(displayRatingWidgets);
    }
  }, [displayRatingWidgets, setDisplayRatingWidgets]);

  const selfPhaseHasClosed = useMemo(
    () =>
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      getPhaseDateHasPassed(
        getPhaseByType(campaign, PHASE_TYPE_SELF)?.end_date
      ),
    [campaign]
  );

  const hasSelfPhase = useMemo(
    () => hasPhase(campaign, PHASE_TYPE_SELF),
    [campaign]
  );

  // non-admin people managers should not be able to see ONA until the self phase is finished
  const canDisplayOna =
    (isInReviewFlow || selfPhaseHasClosed || isAdmin) && hasSelfPhase;

  const deltasMode =
    orgSettings?.performance_profile_deltas_mode ??
    PersonProfileDeltasMode.EXCEPT_SELF;

  const canDisplayDeltas: boolean = getCanDisplayDeltas(
    deltasMode,
    isViewingSelf,
    showManagerOnlyPerformanceDetails
  );

  const selectedCampaignIndex = useMemo(() => {
    if (!campaign) return -1;
    return performanceHistoryData.findIndex(
      (data) => data.campaign.id === campaign.id
    );
  }, [performanceHistoryData, campaign]);

  const previousCampaignDataWithOna = useMemo(() => {
    return findPreviousCampaignWithOna(
      performanceHistoryData,
      selectedCampaignIndex
    );
  }, [performanceHistoryData, selectedCampaignIndex]);

  const previousCampaignDataWithRating = useMemo(() => {
    return findPreviousCampaignWithRating(
      performanceHistoryData,
      selectedCampaignIndex
    );
  }, [performanceHistoryData, selectedCampaignIndex]);

  const contextValue: PersonPerformanceContextData = useMemo(
    () => ({
      canDisplayOna,
      showGoldStarsAndInfluenceToRecipient,
      displayRatingWidgets,
      displayCharts,
      canDisplayDeltas,
      performanceHistoryData,
      selectedCampaignData: performanceHistoryData[selectedCampaignIndex],
      previousCampaignDataWithOna,
      previousCampaignDataWithRating,
    }),
    [
      canDisplayOna,
      showGoldStarsAndInfluenceToRecipient,
      displayRatingWidgets,
      displayCharts,
      canDisplayDeltas,
      performanceHistoryData,
      selectedCampaignIndex,
      previousCampaignDataWithOna,
      previousCampaignDataWithRating,
    ]
  );

  return (
    <PersonPerformanceContext.Provider value={contextValue}>
      {children}
    </PersonPerformanceContext.Provider>
  );
};

export const PersonPerformanceContextConsumer =
  PersonPerformanceContext.Consumer;

export default PersonPerformanceContext;
