import { Col, Row } from 'reactstrap';
import React, { useCallback, useState } from 'react';
import ActivityFeed from './ActivityFeed';
import DashboardAlerts from './DashboardAlerts';
import Page from '../Layout/Pages/Page';
import RightSidebar from './RightSidebar';
import TasksList from '../Tasks/TasksList';
import { connect } from 'react-redux';
import { anyHomepageFeedItemsAreEnabled } from 'utils/util/features';
import { Features } from 'types';

const Dashboard: React.FC<Props> = (props: Props) => {
  const [showCreateActivityDialog, setShowCreateActivityDialog] =
    useState(false);

  const onCreateActivityDialogClosed = useCallback(() => {
    setShowCreateActivityDialog(false);
  }, []);

  const openCreateActivityDialog = useCallback(
    () => setShowCreateActivityDialog(true),
    []
  );

  // if no activity feed is showing, show a "you have no tasks" indicator
  // instead of showing absolutely nothing.
  return (
    <Page>
      <Row className="mt-4 mt-md-5  justify-content-center">
        <Col className="col-12 col-xl-8">
          <DashboardAlerts className="mb-5" />
          {
            // @ts-expect-error
            (props.tasks?.length > 0 ||
              !anyHomepageFeedItemsAreEnabled(props.features)) && (
              <div className="mb-5">
                <TasksList isFullPage={false} />
              </div>
            )
          }
          {anyHomepageFeedItemsAreEnabled(props.features) && (
            <div>
              <ActivityFeed
                showCreateActivityDialog={showCreateActivityDialog}
                openCreateActivityDialog={openCreateActivityDialog}
                onCreateActivityDialogClosed={onCreateActivityDialogClosed}
              />
            </div>
          )}
        </Col>
        <RightSidebar />
      </Row>
    </Page>
  );
};

interface Props {
  tasks?: object[];
  fyis?: object[];
  features: Features;
}

const mapStateToProps = (state) => {
  const { tasks, features } = state;

  return {
    tasks,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(Dashboard));
