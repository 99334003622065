import {
  ACTIVITY_TYPE_PROJECT,
  getContributionPerson,
} from '../../utils/models/Activity';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import ActivityCard from './ActivityCard';
import { ERROR_404_DB_RECORD } from '../../consts/consts';
import ReactTagsInput from '../Widgets/Inputs/ReactTagsInput';
import { peopleObjectsAreEqual } from '../../utils/models/Person';
import { toast } from 'react-toastify';

const ActivityList: React.FC<Props> = ({
  isDemoOrPreviewMode = false,
  ...props
}: Props) => {
  const { formatMessage } = useIntl();
  const [showHiddenActivities, setShowHiddenActivities] = useState(false);

  const [contributionOverridesDict, setContributionOverridesDict] = useState(
    {}
  );

  const showVisibilityToggles = useMemo(() => {
    return (
      props.focalPerson &&
      props.isResumeView &&
      !props.isUneditablePerfResumeMode
    );
  }, [props.focalPerson, props.isResumeView, props.isUneditablePerfResumeMode]);

  const toggleContributionVisibility = useMemo(
    () =>
      showVisibilityToggles
        ? (c, error, hardErrorMessage) => {
            if (error) {
              // Prompt user to refresh through Toast on 'Not found.' error
              // since data may be out of sync with backend
              // We don't do it for all 404s in case there are other types of errors
              if (error?.response?.data?.detail === ERROR_404_DB_RECORD.name) {
                toast.error(ERROR_404_DB_RECORD.message);
              } else {
                toast.error(hardErrorMessage);
              }
            } else {
              setContributionOverridesDict({
                ...contributionOverridesDict,
                [c.id.toString()]: c,
              });
            }
          }
        : undefined,
    [contributionOverridesDict, showVisibilityToggles]
  );

  const getContributionFromOverrides = useCallback(
    (c) => {
      const foundContribution =
        c.id && contributionOverridesDict[c.id.toString()];
      return foundContribution ? foundContribution : c;
    },
    [contributionOverridesDict]
  );

  const propsSetActivities = props.setActivities;
  const propsActivities = props.activities;
  const propsSetDefaultActivityDateString = props.setDefaultActivityDateString;

  const setActivity = useMemo(
    () =>
      propsSetActivities
        ? (a) =>
            propsSetActivities(
              // @ts-expect-error
              propsActivities.map((a2) =>
                // @ts-expect-error
                a2.id.toString() === a.id.toString() ? a : a2
              )
            )
        : undefined,
    [propsSetActivities, propsActivities]
  );

  const allActivitiesWithoutOverrides = useMemo(
    () =>
      props.hideActivitiesWithoutFeedbackForFocalPerson && props.focalPerson
        ? // @ts-expect-error
          props.activities.filter(
            (a) =>
              // @ts-expect-error
              a?.contributions?.length > 0 &&
              // @ts-expect-error
              a?.contributions
                ?.filter((c) =>
                  peopleObjectsAreEqual(
                    getContributionPerson(c),
                    props.focalPerson
                  )
                )
                .findIndex((c) => c?.contribution_feedback?.length > 0) !== -1
          )
        : props.activities,
    [
      props.activities,
      props.focalPerson,
      props.hideActivitiesWithoutFeedbackForFocalPerson,
    ]
  );

  const allActivities = useMemo(
    () =>
      // @ts-expect-error
      allActivitiesWithoutOverrides
        // @ts-expect-error
        .filter((a) => !props.omit.find((id) => id === a.id))
        .map(
          (a) => ({
            ...a,
            // note: on /activities global page, a.contributions is undefined
            // @ts-expect-error
            contributions: a.contributions?.map(getContributionFromOverrides),
          }),
          []
        ),
    [allActivitiesWithoutOverrides, getContributionFromOverrides, props.omit]
  );

  const shouldShowActivity = useCallback(
    (activity) => {
      const userContribution = activity?.contributions?.find((c) =>
        peopleObjectsAreEqual(getContributionPerson(c), props.focalPerson)
      );

      return userContribution?.ignored_at ? false : true;
    },
    [props.focalPerson]
  );

  const visibleActivities = useMemo(() => {
    if (showVisibilityToggles) {
      return allActivities.filter(shouldShowActivity);
    } else {
      return allActivities;
    }
  }, [allActivities, shouldShowActivity, showVisibilityToggles]);

  const hiddenActivities = useMemo(() => {
    if (showVisibilityToggles) {
      return allActivities.filter((a) => !shouldShowActivity(a));
    } else {
      return null;
    }
  }, [allActivities, shouldShowActivity, showVisibilityToggles]);

  const hiddenActivityText = useMemo(() => {
    const showAllOrNoneText =
      props.isEditablePerfResumeMode || visibleActivities?.length === 0;

    if (hiddenActivities?.length === 1) {
      return showAllOrNoneText
        ? formatMessage({
            id: 'app.activity.activity_hierarchy.view_hidden_activity',
            defaultMessage: 'View 1 hidden activity',
          })
        : formatMessage({
            id: 'app.activity.activity_hierarchy.and_one_other',
            defaultMessage: 'and 1 other',
          });
    }

    return showAllOrNoneText
      ? formatMessage(
          {
            id: 'app.activity.activity_hierarchy.view_x_hidden_activities',
            defaultMessage: 'View {count, number} hidden activities',
          },
          { count: hiddenActivities?.length }
        )
      : formatMessage(
          {
            id: 'app.activity.activity_hierarchy.and_x_others',
            defaultMessage: 'and {count, number} others',
          },
          { count: hiddenActivities?.length }
        );
  }, [
    hiddenActivities?.length,
    props.isEditablePerfResumeMode,
    visibleActivities?.length,
    formatMessage,
  ]);

  const rehideText = useMemo(
    () =>
      hiddenActivities?.length === 1
        ? formatMessage({
            id: 'app.activity.activity_hierarchy.hide_hidden_activity',
            defaultMessage: 'Hide hidden activity',
          })
        : formatMessage(
            {
              id: 'app.activity.activity_hierarchy.hide_x_hidden_activities',
              defaultMessage: 'Hide {count, number} hidden activities',
            },
            { count: hiddenActivities?.length }
          ),
    [hiddenActivities?.length, formatMessage]
  );

  const propsOnAddNewContributionToExistingActivity =
    props.onAddNewContributionToExistingActivity;
  const propsOnCreateNewActivity = props.onCreateNewActivity;
  const activityAutocompleteCallback = useCallback(
    (addedActivities) => {
      // only take the first item
      if (!(addedActivities?.length > 0)) {
        return;
      }

      const a = addedActivities[0]?.object;

      // The 'object' field represents the Activity object
      // Exit if no object exists
      if (!a) {
        console.error('No object found for: ' + JSON.stringify(a));
        return;
      }

      if (a.id) {
        // this is an existing activity, so add yourself as a new contributor
        // @ts-expect-error
        propsOnAddNewContributionToExistingActivity({
          ...a,
          contributions: a?.contributions ? a.contributions : [],
        });
      } else {
        // If current date bucket is aggregated (ie. highlights), set today as end date).
        // Else, for activities added to non-current quarter, set that quarter as end date
        // intended to be used only for adding Activities through the
        // "Add activity title..." component
        if (props.periodEndsPresentDay) {
          // @ts-expect-error
          propsSetDefaultActivityDateString(new Date().toString());
        } else {
          const isCurrentPeriod =
            // @ts-expect-error
            new Date(props.defaultDateString) >= new Date();
          if (!isCurrentPeriod && props.defaultDateString) {
            // @ts-expect-error
            propsSetDefaultActivityDateString(props.defaultDateString);
          } else {
            // @ts-expect-error
            propsSetDefaultActivityDateString(new Date().toString());
          }
        }

        // this is a new activity, so open new activity dialog
        // @ts-expect-error
        propsOnCreateNewActivity({
          ...a,
          contributions: [],
        });
      }
    },
    [
      propsOnAddNewContributionToExistingActivity,
      propsOnCreateNewActivity,
      propsSetDefaultActivityDateString,
      props.defaultDateString,
      props.periodEndsPresentDay,
    ]
  );

  const renderActivityCard = useCallback(
    (activity, index) => {
      const selectButton = !!props.onSelect && (
        // @ts-expect-error
        <Button color="primary" onClick={() => props.onSelect(activity)}>
          {props.selectButtonText}
        </Button>
      );
      const card = (
        <>
          <ActivityCard
            buttonColor="light" // for lists, too many primary buttons is a bit too dramatic
            activity={activity}
            setActivity={setActivity}
            description={
              props.descriptionMap ? props.descriptionMap(activity) : undefined
            }
            bodyOnly={true}
            hideImages={props.hideImages}
            hideContributions={props.hideContributions}
            hideDescription={props.hideDescriptions}
            inModal={props.inModal}
            linked={true}
            hideEmojis={props.hideEmojis}
            openCreateActivityDialog={props.openCreateActivityDialog}
            maxFaces={props.maxFaces}
            hideIcon={props.hideIcon}
            hidePeople={props.hidePeople}
            hideDate={props.hideDates}
            focalPerson={props.focalPerson}
            focalSkill={props.focalSkill}
            focalCredential={props.focalCredential}
            isResumeView={props.isResumeView}
            isCompressedView={props.isCompressedView}
            insertBefore={props.insertBeforeEachActivity}
            isEditablePerfResumeMode={props.isEditablePerfResumeMode}
            isUneditablePerfResumeMode={props.isUneditablePerfResumeMode}
            onClickAcceptCredit={
              props.onClickAcceptCredit
                ? // @ts-expect-error
                  () => props.onClickAcceptCredit(activity)
                : undefined
            }
            showFeedback={props.showFeedback}
            visibilityToggleCallback={toggleContributionVisibility}
            showGiveOrRequestFeedbackButton={
              props.isResumeView &&
              !props.isEditablePerfResumeMode &&
              !props.isUneditablePerfResumeMode
            }
            addOrRemoveHighlight={props.addOrRemoveHighlight}
            isHighlight={props.isHighlight}
            activeHighlights={props.activeHighlights}
            secondAside={selectButton || undefined}
            isDemoOrPreviewMode={isDemoOrPreviewMode}
          />
        </>
      );

      if (props.isCompressedView) {
        return (
          <li key={index} className="ms-1 mb-1">
            {card}
          </li>
        );
      } else {
        return <ListGroupItem key={index}>{card}</ListGroupItem>;
      }
    },
    [isDemoOrPreviewMode, props, setActivity, toggleContributionVisibility]
  );

  if (
    props.hideActivitiesWithoutFeedbackForFocalPerson &&
    !(visibleActivities?.length > 0)
  ) {
    return (
      <div className="text-muted text-center">
        <FormattedMessage
          id="app.activity.activity_hierarchy.no_activities"
          defaultMessage="No activities from this period have feedback."
        />
      </div>
    );
  }

  // return nothing if no visible or hidden activities
  if (
    !(visibleActivities?.length > 0) &&
    // @ts-expect-error
    !(hiddenActivities?.length > 0) &&
    !props.showNewActivityAutocomplete
  ) {
    return <></>;
  }

  return (
    <ListGroup
      className={
        props.isCompressedView ? 'mb-3 ps-3' : 'list-group-flush my-n3'
      }
    >
      {visibleActivities.map(renderActivityCard)}
      {props.showNewActivityAutocomplete && (
        <ListGroupItem className="text-muted pb-3">
          <ReactTagsInput
            className="border-0 p-0"
            allowNew={true}
            allowDuplicateCreate={true}
            minQueryLength={1}
            elasticsearchOptions={{
              url: 'get-activities-by-name',
              index: 'activities',
              // exclude activities that the given person is already a part of
              getQuery: (q) =>
                q
                  ? {
                      name: q,
                      // @ts-expect-error
                      exclude_contributor_people: props.focalPerson.id
                        ? // @ts-expect-error
                          [props.focalPerson.id]
                        : undefined,
                    }
                  : {
                      // @ts-expect-error
                      exclude_contributor_people: props.focalPerson.id
                        ? // @ts-expect-error
                          [props.focalPerson.id]
                        : undefined,
                    },
            }}
            renderCreateOption={(x, setIndex, setType) => {
              setIndex('activities');
              // TODO: let user pick type from list
              // @ts-expect-error
              setType(ACTIVITY_TYPE_PROJECT.id);
              return {
                name: x,
                object: {
                  name: (
                    <span>
                      <FormattedMessage
                        id="app.activity.activity_list.render_create_option.new_activity"
                        defaultMessage='<span>Create new activity "</span>{activityName}<span>"</span>'
                        values={{
                          activityName: x,
                          span: (text) => (
                            <span className="fw-normal">{text}</span>
                          ),
                        }}
                      />
                    </span>
                  ),
                },
              };
            }}
            callback={activityAutocompleteCallback}
            placeholder={formatMessage({
              id: 'app.activity.activity_list.placeholder.text',
              defaultMessage: 'Add activity title...',
            })}
            clearTagsAfterCallback={true}
            autoFocus={props.autoFocus}
          />
        </ListGroupItem>
      )}
      {!showHiddenActivities &&
        // @ts-expect-error
        hiddenActivities?.length > 0 &&
        props.isCompressedView && (
          <li role="button" onClick={() => setShowHiddenActivities(true)}>
            <Button className="p-0 text-muted" color="link">
              {hiddenActivityText}
            </Button>
          </li>
        )}
      {!showHiddenActivities &&
        // @ts-expect-error
        hiddenActivities?.length > 0 &&
        !props.isCompressedView && (
          <ListGroupItem
            className="text-muted text-center"
            role="button"
            onClick={() => setShowHiddenActivities(true)}
          >
            <Button className="p-0 text-muted" color="link">
              {hiddenActivityText}
            </Button>
          </ListGroupItem>
        )}
      {showHiddenActivities &&
        // @ts-expect-error
        hiddenActivities?.length > 0 &&
        props.isCompressedView && (
          <li role="button" onClick={() => setShowHiddenActivities(false)}>
            <Button className="p-0 text-muted" color="link">
              {rehideText}
            </Button>
          </li>
        )}
      {showHiddenActivities &&
        // @ts-expect-error
        hiddenActivities?.length > 0 &&
        !props.isCompressedView && (
          <ListGroupItem
            className="text-muted text-center"
            role="button"
            onClick={() => setShowHiddenActivities(false)}
          >
            <Button className="p-0 text-muted" color="link">
              {rehideText}
            </Button>
          </ListGroupItem>
        )}
      {showHiddenActivities && hiddenActivities?.map(renderActivityCard)}
    </ListGroup>
  );
};

ActivityList.defaultProps = {
  isCompressedView: false,
  hideImages: false,
  hideContributions: false,
  hideDescriptions: false,
  hideDates: false,
  inModal: false,
  hideEmojis: false,
  hideIcon: false,
  hidePeople: false,
  hideActivitiesWithoutFeedbackForFocalPerson: false,
  addOrRemoveHighlight: false,
  isHighlight: false,
  activeHighlights: false,
  periodEndsPresentDay: false,
  selectButtonText: 'Select',
  omit: [],
};

type Props = InferProps<typeof ActivityList_propTypes>;

const ActivityList_propTypes = {
  isResumeView: PropTypes.bool,
  isCompressedView: PropTypes.bool,
  hideImages: PropTypes.bool,
  hideContributions: PropTypes.bool,
  hideDescriptions: PropTypes.bool,
  hideDates: PropTypes.bool,
  activities: PropTypes.arrayOf(PropTypes.object),
  setActivities: PropTypes.func,
  descriptionMap: PropTypes.func,
  inModal: PropTypes.bool,
  hideEmojis: PropTypes.bool,
  openCreateActivityDialog: PropTypes.func,
  focalPerson: PropTypes.object,
  focalSkill: PropTypes.object,
  focalCredential: PropTypes.object,
  maxFaces: PropTypes.number,
  hideIcon: PropTypes.bool,
  hidePeople: PropTypes.bool,
  insertBeforeEachActivity: PropTypes.func,
  isEditablePerfResumeMode: PropTypes.bool,
  isUneditablePerfResumeMode: PropTypes.bool,
  onClickAcceptCredit: PropTypes.func,
  hideActivitiesWithoutFeedbackForFocalPerson: PropTypes.bool,
  showFeedback: PropTypes.bool,
  showNewActivityAutocomplete: PropTypes.bool,
  onAddNewContributionToExistingActivity: PropTypes.func,
  onCreateNewActivity: PropTypes.func,
  autoFocus: PropTypes.bool,
  defaultDateString: PropTypes.string,
  setDefaultActivityDateString: PropTypes.func,
  addOrRemoveHighlight: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isHighlight: PropTypes.bool,
  activeHighlights: PropTypes.bool,
  periodEndsPresentDay: PropTypes.bool,
  selectButtonText: PropTypes.string,
  onSelect: PropTypes.func,
  omit: PropTypes.arrayOf(PropTypes.number),
  isDemoOrPreviewMode: PropTypes.bool,
};

// all tracking in app will be passed through here
export default React.memo(ActivityList);
