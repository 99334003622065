import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';

interface Props {
  id: string;
  className?: string;
}

const OmittedElement: FC<Props> = ({ id, className = '' }) => {
  return (
    <div id={id} className={className}>
      <FormattedMessage
        id="app.views.widgets.dashboards.team_dashboard.omitted.not_available"
        defaultMessage="n/a"
      />
      <UncontrolledPopover placement="left" trigger="hover" target={id}>
        <FormattedMessage
          id="app.views.widgets.dashboards.team_dashboard.data_omitted"
          defaultMessage="Cycle data related to this person has been omitted due to privacy settings (i.e. the person listed is yourself)."
        />
      </UncontrolledPopover>
    </div>
  );
};

export default OmittedElement;
