import React, { FC, useCallback, useEffect } from 'react';
import {
  setCurrentOrganization,
  setCurrentProxyPerson,
} from '../../../actions';

import PropTypes from 'prop-types';
import SelectInput from '../Inputs/SelectInput';
import { connect } from 'react-redux';
import { getPeopleOrAllQuery } from '../Inputs/ValidatedInputTypes';
import { setUserLocalStorage } from '../../../utils/models/User';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

const NavbarBrandDropdown: FC<Props> = (props) => {
  const { user } = useAuth0();
  const { formatMessage } = useIntl();

  const userSub = user?.sub;
  const propsSetCurrentOrganization = props.setCurrentOrganization;
  // @ts-expect-error
  const propsSetCurrentProxyPerson = props.setCurrentProxyPerson;

  const onChangeOrganization = useCallback(
    (object) => {
      propsSetCurrentOrganization(object);
      if (userSub) {
        setUserLocalStorage(
          userSub,
          props.currentProxyPerson,
          null,
          'currentOrganization',
          object
        );
      }

      // clear proxy user if one was set
      propsSetCurrentProxyPerson(null, object);
    },
    [
      props.currentProxyPerson,
      propsSetCurrentOrganization,
      propsSetCurrentProxyPerson,
      userSub,
    ]
  );

  const onChangeProxyPerson = useCallback(
    (object) => {
      if (!object.user_id) {
        toast.error(object.full_name + ' has no user attached.');
        return;
      }

      console.log('Proxying as ' + object.email);
      propsSetCurrentProxyPerson(object);
      if (userSub) {
        setUserLocalStorage(
          userSub,
          props.currentProxyPerson,
          null,
          'currentProxyPerson',
          object
        );
      }
    },
    [props.currentProxyPerson, propsSetCurrentProxyPerson, userSub]
  );

  // update UI when user, org, or proxy user changes
  useEffect(() => {
    /* DO NOTHING */
  }, [user, props.currentOrganization, props.currentProxyPerson]);

  // if admin, get options from full list from server; if not,
  // see dropdown of all options from your active/demo orgs
  return (
    <>
      {props.currentOrganization && (
        <div style={{ width: '150px' }}>
          <SelectInput
            right={true}
            clearable={false}
            value={props.currentOrganization}
            onChange={onChangeOrganization}
            menuWidth={'300px'}
            optionSize={'sm'}
            showDescriptionWhenSelected={false}
            options={
              props.isSuperAdmin
                ? undefined
                : props.organizations.map((o) => ({
                    object: o,
                  }))
            }
            elasticsearchOptions={
              props.isSuperAdmin
                ? {
                    omitOrgId: true,
                    url: 'get-organizations-by-name',
                    index: 'organizations',
                    getQuery: (q) =>
                      q
                        ? {
                            name: q,
                            sort: [
                              {
                                'name.raw': 'asc',
                              },
                            ],
                          }
                        : {
                            sort: [
                              {
                                'name.raw': 'asc',
                              },
                            ],
                          },
                  }
                : undefined
            }
          />
        </div>
      )}
      {props.isSuperAdmin && props.currentOrganization && (
        <div className="mt-3" style={{ width: '150px' }}>
          <SelectInput
            // key is necessary to rebuild component when id is changed
            // @ts-expect-error
            key={props.currentOrganization?.id}
            right={true}
            placeholder={formatMessage({
              id: 'app.views.widgets.dropdowns.navbar_brand_dropdown.placeholder.view_as',
              defaultMessage: 'View as...',
            })}
            clearable={false}
            value={props.currentProxyPerson}
            onChange={onChangeProxyPerson}
            menuWidth={'300px'}
            showDescriptionWhenSelected={false}
            elasticsearchOptions={
              props.isSuperAdmin
                ? {
                    index: 'people',
                    url: 'get-people-by-name',
                    getQuery: (q) => {
                      const data = getPeopleOrAllQuery(q);
                      return {
                        ...data,
                        // include user_id to identify if there is
                        // a user to proxy into or not
                        source_includes: ['user_id', ...data.source_includes],
                      };
                    },
                  }
                : undefined
            }
          />
        </div>
      )}
    </>
  );
};

const NavbarBrandDropdown_propTypes = {
  isSuperAdmin: PropTypes.bool,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentOrganization: PropTypes.func.isRequired,
};

type Props = PropTypes.InferProps<typeof NavbarBrandDropdown_propTypes>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, organizations } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    organizations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentOrganization: (org) => dispatch(setCurrentOrganization(org)),
    setCurrentProxyPerson: (u, org) => dispatch(setCurrentProxyPerson(u, org)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(NavbarBrandDropdown));
