import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person } from 'types';
import React, { FC, useEffect, useState } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import SimplePage from '../Layout/Pages/SimplePage';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';

const renderForm = (inputs) => {
  return <Row>{inputs}</Row>;
};

const getCol6 = (element) => {
  return <Col className="col-12 col-md-6">{element}</Col>;
};

const getCol12 = (element) => {
  return <Col className="col-12">{element}</Col>;
};

interface Props {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
}

const GeneralAdministration: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const [organizationDetails, setOrganizationDetails] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    if (props.currentOrganization?.id) {
      ConfirmAPI.getUrlWithCache(
        '/organizations/' + props.currentOrganization.id,
        '/organizations/' + props.currentOrganization.id,
        userSub,
        props.currentProxyPerson,
        {},
        (data) => {
          setOrganizationDetails(data);
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [props.currentOrganization?.id, userSub, props.currentProxyPerson]);

  // if it's a not found page, this is really permission denied, so show a friendly error message
  // @ts-expect-error
  if (errorMessage?.status === 404 || errorMessage?.status === 403) {
    return (
      <SimplePage
        title={formatMessage({
          id: 'app.views.organization.general_administration.title.organization_settings',
          defaultMessage: 'Organization settings',
        })}
        titleClassName="text-center"
      >
        <div className="text-center">
          <p className="text-start mb-4">
            <FormattedMessage
              id="app.views.organization.general_administration.only_admins"
              defaultMessage="
            Only your system administrator can edit your organization settings.
            If you believe that you should have access, contact customer
            support.
          "
            />
          </p>
        </div>
      </SimplePage>
    );
  }

  const loadOrRenderOutput = loadOrRender(organizationDetails, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return (
    <>
      <p className="text-muted mb-4">
        <FormattedMessage
          id="app.views.organization.general_administration.contact_support"
          defaultMessage="
        To change any of these settings, contact customer support.
      "
        />
      </p>
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            <FormattedMessage
              id="app.views.organization.general_administration.general_settings"
              defaultMessage="General settings"
            />
          </CardHeaderTitle>
        </CardHeader>
        <CardBody>
          <ValidatedForm
            object={
              organizationDetails
                ? {
                    // @ts-expect-error
                    ...organizationDetails,
                    // @ts-expect-error
                    email_domains: organizationDetails?.email_domains
                      ? // @ts-expect-error
                        organizationDetails.email_domains.join(', ')
                      : '',
                    email_aliases:
                      // @ts-expect-error
                      organizationDetails?.email_aliases &&
                      // @ts-expect-error
                      Object.keys(organizationDetails?.email_aliases) > 0
                        ? // @ts-expect-error
                          JSON.stringify(organizationDetails.email_aliases)
                        : '',
                    status:
                      // @ts-expect-error
                      organizationDetails?.status === 'A'
                        ? 'Active'
                        : // @ts-expect-error
                          'Inactive (' + organizationDetails.status + ')',
                  }
                : {}
            }
            callback={() => null}
            renderForm={renderForm}
            inputs={[
              {
                name: 'name',
                label: formatMessage({
                  id: 'app.views.organization.general_administration.name.label',
                  defaultMessage: 'Name',
                }),
                helperText: formatMessage({
                  id: 'app.views.organization.general_administration.name.helper_text',
                  defaultMessage: "Your organization's display name",
                }),
                disabled: true,
                wrapperFunction: getCol12,
              },
              {
                name: 'email_domains',
                label: formatMessage({
                  id: 'app.views.organization.general_administration.email_domains.label',
                  defaultMessage: 'Email domains',
                }),
                helperText: formatMessage({
                  id: 'app.views.organization.general_administration.email_domains.helper_text',
                  defaultMessage:
                    'Anyone with this email domain will have access to your organization.',
                }),
                disabled: true,
                wrapperFunction: getCol12,
              },
              {
                name: 'email_aliases',
                label: formatMessage({
                  id: 'app.views.organization.general_administration.email_aliases.label',
                  defaultMessage: 'Email aliases',
                }),
                helperText: formatMessage({
                  id: 'app.views.organization.general_administration.email_aliases.helper_text',
                  defaultMessage:
                    'For any old email domains that should map to your current email domains.',
                }),
                disabled: true,
                wrapperFunction: getCol12,
              },
              {
                name: 'slack_id',
                label: formatMessage({
                  id: 'app.views.organization.general_administration.slack_id.label',
                  defaultMessage: 'Slack organization ID',
                }),
                helperText: formatMessage({
                  id: 'app.views.organization.general_administration.slack_id.helper_text',
                  defaultMessage: 'Your Slack Team ID for notifications',
                }),
                disabled: true,
                wrapperFunction: getCol6,
              },
              {
                name: 'slack_public_recognition_channel_id',
                label: formatMessage({
                  id: 'app.views.organization.general_administration.slack_public_recognition_channel_id.label',
                  defaultMessage: 'Slack public recognition channel ID',
                }),
                helperText: formatMessage({
                  id: 'app.views.organization.general_administration.slack_public_recognition_channel_id.helper_text',
                  defaultMessage:
                    'Any recognition in Confirm will auto-post here.',
                }),
                disabled: true,
                wrapperFunction: getCol6,
              },
            ]}
          />
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(GeneralAdministration));
