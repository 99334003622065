export const SENSITIVE_DATA_OMITTED = 'sensitive_data_omitted';

export const toPersonIdToSurveyResponseLookup = (surveyResponses) => {
  const surveyResponseLookup = (surveyResponses ?? []).reduce((acc, sr) => {
    // Remove when we everything is typed and we know this is not possible
    if (sr?.person?.id) {
      acc[sr.person.id] = sr;
    }
    return acc;
  }, {});
  return surveyResponseLookup;
};
