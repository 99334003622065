import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AsyncSelect from 'react-select/async';
import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { connect } from 'react-redux';
import { getItemFromPerson } from '../../../utils/models/Person';
import { personMatchesQuerySimpleCase } from '../../../utils/util/util';
import { renderSuggestion } from './ValidatedAutosuggest';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

export const getItemFromValue = (valueInput, esIndexInput) => {
  if (!esIndexInput || !valueInput) {
    return valueInput;
  }

  // if it's an item already, convert it appropriately for handling below
  const esIndex = valueInput._index ? valueInput._index : esIndexInput;
  const value = valueInput._index ? valueInput.object : valueInput;

  if (esIndex === 'people') {
    const output = getItemFromPerson(value);
    return {
      ...output,
      // SelectInput expects a "label" field and a "value" field, whereas
      // the autocomplete widget which renders suggestions expectes "name" and "object",
      // so converting below to render appropriately
      value: output.object,
    };
  }

  // default for non-people objects
  return {
    _index: esIndex,
    id: value.id,
    // above objects have name field except users with full name
    name: ElasticsearchAPI.getObjectName(value, esIndex),
    url: ElasticsearchAPI.getObjectUrl(value, esIndex),
    object: value,
    // SelectInput expects a "label" field and a "value" field, whereas
    // the autocomplete widget which renders suggestions expectes "name" and "object",
    // so converting below to render appropriately
    value: value,
  };
};

const getValueFromItem = (item) => {
  return item.name ? item.name : item.full_name ? item.full_name : undefined;
};

const SelectInput = (props) => {
  // NOTE: using/setting inputValue / onInputChange will cause the input below to
  // lose focus after typing one character (or, alternatively, if you set them, unset the
  // below custom input)
  const selectedOption = useMemo(() => {
    if (props.value) {
      return getItemFromValue(props.value, props.elasticsearchOptions?.index);
    }
    return undefined;
  }, [props.value, props.elasticsearchOptions]);

  const { user } = useAuth0();
  const userSub = user?.sub;

  const Input = useCallback(
    (optionProps) => {
      if (optionProps.isHidden) {
        return <components.Input {...optionProps} />;
      }

      const onFocus = (e) => {
        if (optionProps.onFocus) {
          optionProps.onFocus(e);
        }
        if (props.inputProps.onFocus) {
          props.inputProps.onFocus(e);
        }
      };

      const onBlur = (e) => {
        if (optionProps.onBlur) {
          optionProps.onBlur(e);
        }
        if (props.inputProps.onBlur) {
          props.inputProps.onBlur(e);
        }
      };

      const onChange = (a, b, c) => {
        if (optionProps.onChange) {
          optionProps.onChange(a, b, c);
        }
        if (props.inputProps.onChange) {
          props.inputProps.onChange(a, b, c);
        }
      };

      const onKeyDown = (a, b, c) => {
        if (optionProps.onKeyDown) {
          optionProps.onKeyDown(a, b, c);
        }
        if (props.inputProps.onKeyDown) {
          props.inputProps.onKeyDown(a, b, c);
        }
      };

      return (
        <components.Input
          autoFocus={props.autoFocus}
          {...optionProps}
          {...{
            isDisabled: props.disabled,
            'aria-expanded': props.inputProps['aria-expanded'],
            role: props.inputProps['role'],
            onFocus: onFocus,
            onBlur: onBlur,
            onChange: onChange,
            onKeyDown: onKeyDown,
          }}
        />
      );
    },
    [props.disabled, props.inputProps, props.autoFocus]
  );

  const intl = useIntl();
  const { formatMessage } = intl;

  const SingleValue = useCallback(
    (singleValueProps) => {
      return (
        <components.SingleValue {...singleValueProps}>
          {renderSuggestion(
            intl,
            singleValueProps.data,
            null,
            true,
            props.selectedItemSize,
            props.showDescriptionWhenSelected
          )}
        </components.SingleValue>
      );
    },
    [intl, props.selectedItemSize, props.showDescriptionWhenSelected]
  );

  SingleValue.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  const Option = useCallback(
    (optionProps) => {
      return (
        <components.Option {...optionProps}>
          <div role="button">
            {renderSuggestion(
              intl,
              optionProps.data,
              null,
              true,
              props.optionSize
            )}
          </div>
        </components.Option>
      );
    },
    [props.optionSize, intl]
  );

  const Menu = useCallback((menuProps) => {
    return (
      <components.Menu {...menuProps}>{menuProps.children}</components.Menu>
    );
  }, []);

  const propsOnChange = props.onChange;
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;
  const incomingValue = props.value;
  const [validationMessage, setValidationMessage] = useState(null);

  const onChange = useCallback(
    (option) => {
      if (props.elasticsearchOptions?.index) {
        const value = getItemFromValue(
          option,
          props.elasticsearchOptions?.index
        );

        if (propsOnChange) {
          propsOnChange(value.object);
        }
      } else {
        if (propsOnChange) {
          propsOnChange(option);
        }
      }
    },
    [props.elasticsearchOptions?.index, propsOnChange]
  );

  // SelectInput expects a "label" field and a "value" field, whereas
  // the autocomplete widget which renders suggestions expectes "name" and "object",
  // so converting below to render appropriately
  const convertWithCallback = useCallback((callback, options) => {
    return callback(
      options.map((o) => ({
        ...o,
        label: o.name,
        value: o.object,
      }))
    );
  }, []);

  useEffect(() => {
    if (propsRequired) {
      const isValid = incomingValue != null;
      setValidationMessage(
        isValid
          ? null
          : formatMessage({
              id: 'app.widgets.inputs.select_input.required',
              defaultMessage: 'Please select an answer.',
            })
      );
    } else {
      setValidationMessage(null);
    }
  }, [incomingValue, propsRequired, formatMessage]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  const propsLoadOptions = props.loadOptions;
  const loadOptions = useCallback(
    (inputValue, callback) => {
      const hasTextInput = inputValue?.length > 0;

      const providedOptions = props.isDemoMode
        ? [
            ...(props.options || []),
            ...(props.demoPeople
              ?.filter((p) => personMatchesQuerySimpleCase(p, inputValue))
              .map((p) => ({
                ...getItemFromPerson(p),
                // ensure that these get filtered out based on the query
                isDemo: true,
              })) ?? []),
          ]
        : props.options;

      // if no text input, just show a message to encourage typing
      if (!hasTextInput) {
        const initialOptions = [];
        if (propsLoadOptions) {
          propsLoadOptions(
            undefined,
            () => {
              callback(initialOptions);
            },
            providedOptions
          );
        } else {
          return callback(initialOptions);
        }
      }

      // ensure that if something is selected, the dropdown below
      // filters based on that thing
      const query =
        !hasTextInput && selectedOption && getValueFromItem(selectedOption)
          ? getValueFromItem(selectedOption)
          : inputValue;

      if (propsLoadOptions) {
        propsLoadOptions(
          query,
          (options) => {
            convertWithCallback(callback, options);
          },
          providedOptions
        );
      } else if (props.elasticsearchOptions) {
        const q = props.elasticsearchOptions.getQuery
          ? props.elasticsearchOptions.getQuery(query)
          : query
          ? { query: query }
          : {};

        ElasticsearchAPI.search(
          // only cache if no query provided (so we don't take up too much
          // memory story search results as searches are done)
          q && Object.keys(q).length > 0 ? undefined : userSub,
          props.currentProxyPerson,
          props.elasticsearchOptions?.omitOrgId
            ? undefined
            : props.currentOrganization?.id,
          props.elasticsearchOptions.url,
          q,
          (hits) => {
            convertWithCallback(
              callback,
              providedOptions.concat(
                hits.map(
                  props.elasticsearchOptions.mapFunction
                    ? props.elasticsearchOptions.mapFunction
                    : ElasticsearchAPI.defaultSelectorMapFunction
                )
              )
            );
          },
          (error) => {
            console.error('SelectInput error: ' + JSON.stringify(error));
            convertWithCallback(callback, providedOptions);
          }
        );
      } else {
        convertWithCallback(callback, providedOptions);
      }
    },
    [
      props.isDemoMode,
      props.options,
      props.demoPeople,
      props.elasticsearchOptions,
      props.currentProxyPerson,
      props.currentOrganization?.id,
      selectedOption,
      propsLoadOptions,
      convertWithCallback,
      userSub,
    ]
  );

  const customStyles = useMemo(
    () => ({
      menu: (provided, state) => ({
        ...provided,
        width: props.menuWidth ? props.menuWidth : state.selectProps.width,
        right: props.right ? 0 : undefined,
        // ensure that nothing sits on top, e.g. a mention editor (Froala) w/zIndex 2
        zIndex: 9998,
      }),
    }),
    [props.menuWidth, props.right]
  );

  let asyncComponents = {
    Input,
    SingleValue,
    Option,
    Menu,
  };

  if (props.hideDropdownIndicator) {
    asyncComponents = {
      ...asyncComponents,
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    };
  }

  // we pass in a unique key that changes when the
  // selected option changes to ensure we refresh the
  // loadOptions when the value changes so selecting
  // the item shows a dropdownlist that matches what
  // is currently selected, even after this value
  // changes
  const key = useMemo(
    () =>
      selectedOption && getValueFromItem(selectedOption)
        ? getValueFromItem(selectedOption)
        : undefined,
    [selectedOption]
  );

  return (
    <AsyncSelect
      key={key}
      autoFocus={props.autoFocus}
      className={props.className}
      required={props.required}
      ref={props.innerRef ? props.innerRef : undefined}
      isSearchable={props.searchable}
      isClearable={props.clearable}
      isDisabled={props.disabled}
      isMulti={props.multiple}
      components={asyncComponents}
      name={props.name}
      placeholder={
        props.placeholder ??
        formatMessage({
          id: 'app.views.widgets.inputs.selectinput.select',
          defaultMessage: 'Select...',
        })
      }
      noOptionsMessage={() =>
        props.noOptionsMessage ??
        formatMessage({
          id: 'app.views.widgets.inputs.selectinput.type_to_see_options',
          defaultMessage: 'Type to see options...',
        })
      }
      value={selectedOption}
      onChange={onChange}
      loadOptions={loadOptions}
      isOptionSelected={props.isOptionSelected}
      defaultOptions={props.defaultOptions}
      styles={customStyles}
    />
  );
};

SelectInput.defaultProps = {
  inputProps: {},
  options: [],
  defaultOptions: true,
  clearable: true,
  searchable: true,
  showDescriptionWhenSelected: true,
  hideDropdownIndicator: false,
  multiple: false,
};

SelectInput.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  isDemoMode: PropTypes.bool,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  optionSize: PropTypes.string,
  menuWidth: PropTypes.string,
  selectedItemSize: PropTypes.string,
  showDescriptionWhenSelected: PropTypes.bool,
  esIndex: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  inputProps: PropTypes.object,
  isOptionSelected: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  loadOptions: PropTypes.func,
  elasticsearchOptions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onValidChange: PropTypes.func,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool,
  ]),
  currentOrganization: PropTypes.object.isRequired,
  hideDropdownIndicator: PropTypes.bool,
  right: PropTypes.bool,
  multiple: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, demoPeople } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    demoPeople,
  };
};

export default connect(mapStateToProps)(React.memo(SelectInput));
