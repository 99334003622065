import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person } from 'types';
import React, { FC, useEffect, useState } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import FeatureList from './FeatureAdministration/FeatureList';
import { ORGANIZATION_SETTINGS_USER_LISTS } from '../../utils/models/Organization';
import SimplePage from '../Layout/Pages/SimplePage';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';

const stripUserListKeys = (dict) => {
  const d = {};

  Object.keys(dict).forEach((k) => {
    if (ORGANIZATION_SETTINGS_USER_LISTS.indexOf(k) === -1) {
      d[k] = dict[k];
    }
  });

  return d;
};

interface Props {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
}

const FeatureAdministration: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const [organizationDetails, setOrganizationDetails] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    if (props.currentOrganization?.id) {
      const url =
        '/organizations/' + props.currentOrganization.id + '/features';
      ConfirmAPI.getUrlWithCache(
        url,
        url,
        userSub,
        props.currentProxyPerson,
        {},
        (data) => {
          setOrganizationDetails(data);
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [props.currentOrganization?.id, userSub, props.currentProxyPerson]);

  // if it's a not found page, this is really permission denied, so show a friendly error message
  // @ts-expect-error
  if (errorMessage?.status === 404 || errorMessage?.status === 403) {
    return (
      <SimplePage
        title={formatMessage({
          id: 'app.views.organization.general_administration.title.organization_settings',
          defaultMessage: 'Organization settings',
        })}
        titleClassName="text-center"
      >
        <div className="text-center">
          <p className="text-start mb-4">
            <FormattedMessage
              id="app.views.organization.general_administration.only_admins"
              defaultMessage="
            Only your system administrator can edit your organization settings.
            If you believe that you should have access, contact customer
            support.
          "
            />
          </p>
        </div>
      </SimplePage>
    );
  }

  const loadOrRenderOutput = loadOrRender(organizationDetails, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return (
    <>
      <p className="text-muted mb-4">
        <FormattedMessage
          id="app.views.organization.feature_administration.contact_support"
          defaultMessage="
        To change any of these features, contact customer support.
      "
        />
      </p>
      {
        /* @ts-expect-error */
        !!organizationDetails?.features && (
          /* @ts-expect-error */
          <FeatureList featureList={organizationDetails.features} />
        )
      }
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            <FormattedMessage
              id="app.views.organization.general_administration.feature_customizations"
              defaultMessage="Feature customizations"
            />
          </CardHeaderTitle>
        </CardHeader>
        <CardBody>
          <p className="text-muted">
            <FormattedMessage
              id="app.views.organization.general_administration.deeper_feature_customization"
              defaultMessage="
            Deeper feature customization for advanced users only. If you have
            any questions, contact customer support.
          "
            />
          </p>
          <div>
            <pre style={{ whiteSpace: 'pre-wrap' }}>
              {/* @ts-expect-error */}
              {organizationDetails?.settings
                ? JSON.stringify(
                    // @ts-expect-error
                    stripUserListKeys(organizationDetails?.settings),
                    null,
                    2
                  )
                : ''}
            </pre>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(FeatureAdministration));
