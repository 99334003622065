import React, { useCallback } from 'react';

export default (props) => {

  // if this component is focused and delete or backspace is pressed, trigger props.onDelete
  const handleKeyDown = useCallback((e) => {
    if (document.activeElement === e.target && (e.code === 'Delete' || e.code === 'Backspace')) {
      props.onDelete(e);
    }
  }, []);

  return (
    <button
      type='button'
      className={props.classNames.selectedTag}
      title={props.removeButtonText}
      onClick={props.onDelete}
      onKeyDown={handleKeyDown}
    >
      <span className={props.classNames.selectedTagName}>{props.tag.name}</span>
    </button>
  );
}
