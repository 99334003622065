import { Col, PopoverBody, Row } from 'reactstrap';
import {
  DevelopmentPlan,
  DevelopmentPlanStatus,
  getDevelopmentPlanStatus,
} from 'utils/models/DevelopmentPlan';
import {
  FORMAT_AVATAR_ONLY,
  FORMAT_AVATAR_WITH_TITLE,
} from 'views/Widgets/People/Filters/common';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';
import { comparePeople, getId, getPrettyDate } from 'utils/util/util';

import DevelopmentPlansFilterBar from './DevelopmentPlansFilterBar';
import FilterablePeopleTable from 'views/Widgets/People/FilterablePeopleTable';
import { ICONS } from 'consts/consts';
import SafeUncontrolledPopover from 'components/SafeUncontrolledPopover';

interface Props {
  developmentPlans: DevelopmentPlan[];
  hideHeaderControls?: boolean;
  filterStatuses: string[];
  toggleFilterStatuses: (status: string) => void;
}

const DevelopmentPlansTable: FC<Props> = ({
  developmentPlans,
  hideHeaderControls,
  filterStatuses,
  toggleFilterStatuses,
}) => {
  const { formatMessage, locale } = useIntl();
  return (
    <FilterablePeopleTable
      // we may use arrays in values, e.g. for list of direct reports, so this must be false
      arrayValuesUsedForFormatting={false}
      tableClassName="calibration-table"
      hideHeader={hideHeaderControls}
      headerContent={
        <Row className="align-items-center">
          <Col className="col-auto ps-2">
            <DevelopmentPlansFilterBar
              filterStatuses={filterStatuses}
              toggleFilterStatuses={toggleFilterStatuses}
            />
          </Col>
          <Col></Col>
        </Row>
      }
      rows={developmentPlans}
      getUniqueRowId={getId}
      columns={[
        {
          name: formatMessage({
            id: 'app.views.development_plans.development_plans_dashboard.name.candidate_person',
            defaultMessage: 'Candidate',
          }),
          field: 'candidate_person',
          isPerson: true,
          format: FORMAT_AVATAR_WITH_TITLE,
          csvName: ['Candidate name', 'Candidate email'],
          csvFormat: (c) => ({
            'Candidate name': c.full_name,
            'Candidate email': c.email,
          }),
          getFilterDisplayValue: (c) => c.full_name,
          nameTransformerFunction: (name) => name + ' as candidate',
          popoverContent: (
            <FormattedMessage
              id="app.views.development_plans.development_plans_dashboard.popover_content.candidate_person"
              defaultMessage="Name, title, and link to resume of candidate"
            />
          ),
          sort: (a, b) =>
            comparePeople(a?.candidate_person, b?.candidate_person),
        },
        {
          name: formatMessage({
            id: 'app.views.development_plans.development_plans_dashboard.name.supporter_person',
            defaultMessage: 'Supporter',
          }),
          field: 'supporter_person',
          isPerson: true,
          format: FORMAT_AVATAR_ONLY,
          csvFormat: (c) => c.email,
          getFilterDisplayValue: (c) => c.full_name,
          nameTransformerFunction: (name) => name + ' as author',
          ifEmpty: '-',
          popoverContent: (
            <FormattedMessage
              id="app.views.development_plans.development_plans_dashboard.popover_content.supporter_person"
              defaultMessage="The manager that is supporting the development plan"
            />
          ),
          sort: (a, b) =>
            comparePeople(a?.supporter_person, b?.supporter_person),
        },
        {
          name: formatMessage({
            id: 'app.views.development_plans.development_plans_dashboard.name.start_date',
            defaultMessage: 'Start date',
          }),
          field: 'start_date',
          popoverContent: (
            <FormattedMessage
              id="app.views.development_plans.development_plans_dashboard.popover_content.start_date"
              defaultMessage="When the development plan was started"
            />
          ),
          format: (date) => {
            return date ? getPrettyDate({ dateString: date, locale }) : '-';
          },
          hideFromFilters: true,
        },
        {
          name: formatMessage({
            id: 'app.views.development_plans.development_plans_dashboard.name.end_date',
            defaultMessage: 'End date',
          }),
          field: 'end_date',
          popoverContent: (
            <FormattedMessage
              id="app.views.development_plans.development_plans_dashboard.popover_content.end_date"
              defaultMessage="When the development plan ends, if ever."
            />
          ),
          format: (date) => {
            return date ? getPrettyDate({ dateString: date, locale }) : '-';
          },
          hideFromFilters: true,
        },
        {
          name: (
            <span>
              <i className={ICONS.ACTIVITY} />
            </span>
          ),
          filterIcon: ICONS.ACTIVITY,
          filterDescription: 'Status',
          csvName: formatMessage({
            id: 'app.views.development_plans.development_plans_dashboard.csv.status',
            defaultMessage: 'Status',
          }),
          csvFormat: (s: DevelopmentPlanStatus) =>
            getDevelopmentPlanStatus(s).name,
          field: 'status',
          format: (s: DevelopmentPlanStatus, uniqueIndexIdentifier) => {
            const status = getDevelopmentPlanStatus(s);
            return (
              <>
                <i
                  id={'status-' + uniqueIndexIdentifier}
                  className={status.icon}
                />
                <SafeUncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={'status-' + uniqueIndexIdentifier}
                >
                  <PopoverBody>
                    <div className="text-dark">
                      <FormattedMessage
                        id="app.views.development_plans.development_plans_dashboard.packet_popover_heading"
                        defaultMessage="<span>{heading}</span>"
                        values={{
                          heading: status.heading,
                          span: (chunks) => (
                            <span className="fw-bold">{chunks}</span>
                          ),
                        }}
                      />
                    </div>
                    <div>{status.description}</div>
                  </PopoverBody>
                </SafeUncontrolledPopover>
              </>
            );
          },
          popoverContent: (
            <FormattedMessage
              id="app.views.development_plans.development_plans_dashboard.popover_content.status"
              defaultMessage="Development plan status"
            />
          ),
        },
        {
          name: (
            <span>
              <i className={ICONS.ACKNOWLEDGED} />
            </span>
          ),
          filterIcon: ICONS.ACTIVITY,
          filterDescription: 'Candidate acknowledged',
          csvName: formatMessage({
            id: 'app.views.development_plans.development_plans_dashboard.csv.candidate_acknowledged_at',
            defaultMessage: 'Candidate acknowledged at',
          }),
          field: 'candidate_acknowledged_at',
          format: (datetime: Date | null, uniqueIndexIdentifier) => {
            return (
              <>
                {datetime && (
                  <>
                    <i
                      id={'status-' + uniqueIndexIdentifier}
                      className={ICONS.ACKNOWLEDGED}
                    />
                    <SafeUncontrolledPopover
                      trigger="hover"
                      placement="top"
                      target={'status-' + uniqueIndexIdentifier}
                    >
                      <PopoverBody>
                        <div className="text-dark">
                          <FormattedMessage
                            id="app.views.development_plans.development_plans_dashboard.candidate_acknowledged_at.popover"
                            defaultMessage="The candidate ackwnowledged at <span>{date}</span>"
                            values={{
                              date: getPrettyDate({
                                dateString: datetime,
                                locale,
                                includeTime: true,
                              }),
                              span: (chunks) => (
                                <span className="fw-bold">{chunks}</span>
                              ),
                            }}
                          />
                        </div>
                      </PopoverBody>
                    </SafeUncontrolledPopover>
                  </>
                )}
              </>
            );
          },
          popoverContent: (
            <FormattedMessage
              id="app.views.development_plans.development_plans_dashboard.popover_content.candidate_acknowledged_at"
              defaultMessage="Checked if the candidate acknowledged. Hover to see the date when this last happened."
            />
          ),
        },
        {
          name: formatMessage({
            id: 'app.views..development_plans.development_plans_dashboard.name.action',
            defaultMessage: 'Action',
          }),
          field: 'action',
          sortable: false,
          hideFromCSV: true,
          hideFromFilters: true,
          popoverContent: (
            <FormattedMessage
              id="app.views.development_plans.development_plans_dashboard.popover_content.action"
              defaultMessage="Review plans to change status, add comments, etc."
            />
          ),
        },
      ]}
      // sort by newest first
      defaultSort={['id']}
      defaultSortAtoZ={false}
    ></FilterablePeopleTable>
  );
};

export default DevelopmentPlansTable;
