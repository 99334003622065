import moment from 'moment';
import {
  ONE_DAY_IN_MILLISECONDS,
  ONE_HOUR_IN_MILLISECONDS,
  ONE_MINUTE_IN_MILLISECONDS,
} from 'consts/consts';

export function convertDateTimeToLocalTimeString(
  utcTime: moment.MomentInput
): string {
  return moment(utcTime).format('YYYY-MM-DDTHH:mm:ss.SSS');
}

export function convertDateTimeToUTCString(
  localTime: moment.MomentInput
): string {
  return moment(localTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
}

export function convertDateTimeToLocalTime(utcTime: moment.MomentInput): Date {
  return new Date(convertDateTimeToLocalTimeString(utcTime));
}

export function convertDateTimeToUTC(localTime: moment.MomentInput): Date {
  return new Date(convertDateTimeToUTC(localTime));
}

export function dateDiffWithUnit(
  baseDate: Date,
  targetDate: Date
): { diff: number; unit: 'day' | 'hour' | 'minute' } {
  const diffMillis = targetDate.getTime() - baseDate.getTime();

  const roundFn = diffMillis >= 0 ? Math.floor : Math.ceil;

  // Calculate the difference in days, hours, and minutes
  const diffDays = roundFn(diffMillis / ONE_DAY_IN_MILLISECONDS);
  const diffHours = roundFn(diffMillis / ONE_HOUR_IN_MILLISECONDS);
  const diffMinutes = roundFn(diffMillis / ONE_MINUTE_IN_MILLISECONDS);

  // Return the most appropriate unit
  if (Math.abs(diffDays) > 0) {
    return { diff: diffDays, unit: 'day' };
  } else if (Math.abs(diffHours) > 0) {
    return { diff: diffHours, unit: 'hour' };
  } else {
    return { diff: diffMinutes, unit: 'minute' };
  }
}
