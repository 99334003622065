import { Col, Row } from 'reactstrap';
import {
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_REPORTING,
  PHASE_TYPE_SELF,
} from '../../../utils/models/Performance';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getPhaseByType, getPhaseName } from '../../../utils/models/Campaign';

import ObjectsDropdown from './ObjectsDropdown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

/**
 * A dropdown control that allows a user to choose a cycle from
 * those held by their organization. They can optionally select a specific
 * phase within that cycle, and optionally it can be valid not to make a
 * selection (otherwise the control value will be defaulted to the most
 * recent campaign, first phase).
 *
 * @param {*} props
 * @returns
 */
const PerformanceCycleDropdown: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [selectedCycle, setSelectedCycle] = useState({
    // @ts-expect-error
    campaignId: parseInt(props.campaignId) || props.campaigns[0].id,
    phaseType:
      // @ts-expect-error
      props.phaseType ||
      (props?.campaignId &&
        props?.campaigns
          // @ts-expect-error
          ?.find((x) => x.id === parseInt(props.campaignId))?.phases?.[0]
          ?.type) ||
      // @ts-expect-error
      props?.campaigns[0]?.phases[0]?.type ||
      PHASE_TYPE_SELF,
  });
  const [options, setOptions] = useState([[], []]);

  const isOnParticipationDashboard = useMemo(() => {
    return location?.pathname?.includes('/participation');
  }, [location]);

  useEffect(() => {
    // NOTE: since we fetch from the cache initially, but then we fetch from
    // the server to check for any updates, we can't run the below only once;
    // we should run it any time the list of campaigns changes
    if (!props.campaigns?.length) {
      return;
    }

    const campaignOptions = props.campaigns.map((x) => ({
      // @ts-expect-error
      id: x.id,
      name: props.suffix
        ? // @ts-expect-error
          `${x.name}${props.suffix}`
        : formatMessage(
            {
              id: 'app.widgets.dropdowns.performance_cycle_dropdown.default_message',
              defaultMessage: '{name} cycle',
            },
            // @ts-expect-error
            { name: x.name }
          ),
      value: x,
    }));

    const haveCampaign =
      props.campaignId &&
      // @ts-expect-error
      props.campaigns.findIndex((x) => x.id === props.campaignId) !== -1;

    const campaign = haveCampaign
      ? // @ts-expect-error
        props.campaigns.find((x) => x.id === props.campaignId)
      : props.campaigns[0];

    // @ts-expect-error
    const hasPhaseType = !!getPhaseByType(campaign, props.phaseType);

    // @ts-expect-error
    const phaseOptions = campaign.phases
      .map((phase, index) => ({
        id: phase.type,
        name: getPhaseName(campaign, index, formatMessage),
        value: phase,
      }))
      .filter(
        (phase) =>
          !isOnParticipationDashboard ||
          (phase.id !== PHASE_TYPE_CALIBRATION &&
            phase.id !== PHASE_TYPE_REPORTING)
      );

    const cycle = {
      // @ts-expect-error
      campaignId: props.campaignId || props.campaigns[0].id,
      // @ts-expect-error
      phaseType: hasPhaseType ? props.phaseType : PHASE_TYPE_SELF,
    };

    setSelectedCycle(cycle);
    setOptions([campaignOptions, phaseOptions]);
  }, [
    // @ts-expect-error
    props.allowNoSelection,
    // @ts-expect-error
    props.campaign,
    props.campaignId,
    props.campaigns,
    // @ts-expect-error
    props.phaseType,
    props.suffix,
    location,
    isOnParticipationDashboard,
    formatMessage,
  ]);

  // 4. Set up callback to fire on changes
  const onChangeCampaign = useCallback(
    (e) => {
      if (e.target.value !== selectedCycle.campaignId) {
        const cycle = Object.assign({}, selectedCycle, {
          campaignId: e.target.value,
          phaseType:
            // @ts-expect-error
            props?.campaigns?.find((x) => x.id === e.target.value)?.phases?.[0]
              ?.type || selectedCycle.phaseType,
        });
        setSelectedCycle(cycle);
        // @ts-expect-error
        props.onChange(cycle);
      }
    },
    [props, selectedCycle]
  );

  const onChangePhase = useCallback(
    (e) => {
      if (e.target.value !== selectedCycle.phaseType) {
        const cycle = Object.assign({}, selectedCycle, {
          phaseType: e.target.value,
        });
        setSelectedCycle(cycle);
        // @ts-expect-error
        props.onChange(cycle);
      }
    },
    [props, selectedCycle]
  );

  // 6. Render markup
  return (
    <Row>
      <Col>
        <ObjectsDropdown
          selectText={formatMessage({
            id: 'app.widgets.dropdowns.performance_cycle_dropdown.select_cycle',
            defaultMessage: 'Select cycle...',
          })}
          objects={options[0]}
          value={selectedCycle?.campaignId}
          onChange={onChangeCampaign}
        />
      </Col>
      {props.showPhaseDropdown && (
        <Col>
          <ObjectsDropdown
            selectText={formatMessage({
              id: 'app.widgets.dropdowns.performance_cycle_dropdown.select_hase',
              defaultMessage: 'Select campaign phase...',
            })}
            objects={options[1]}
            value={selectedCycle?.phaseType}
            onChange={onChangePhase}
          />
        </Col>
      )}
      {/* @ts-expect-error */}
      {props.children && <Col>{props.children}</Col>}
    </Row>
  );
};

const PerformanceCycleDropdown_propTypes = {
  /** for showing at end of each item on the list */
  suffix: PropTypes.string,
  /** list of campaigns associated with the current user's org */
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** used for org id, to get active campaigns */
  currentOrganization: PropTypes.object.isRequired,
  /** user that the real user is currently pretending to be */
  currentProxyPerson: PropTypes.object,
  /** when option is chosen, callback taking the option value */
  onChange: PropTypes.func,
  /** show additional dropdown for campaign phases */
  showPhaseDropdown: PropTypes.bool,
  /** default campaign id to set dropdown to, if any */
  campaignId: PropTypes.number,
};

type Props = PropTypes.InferProps<typeof PerformanceCycleDropdown_propTypes>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(PerformanceCycleDropdown));
