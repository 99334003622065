import { Card, CardBody, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ActivityCard from '../Activities/ActivityCard';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import EmptyState from '../Widgets/EmptyState';
import FeedbackCard from '../Feedback/FeedbackCard';
import Loading from '../Widgets/Loading';
import PulseCheckCard from '../PulseChecks/PulseCheckCard';
import { connect } from 'react-redux';
import { getLatestNonEmptyContributionFromList } from '../../utils/models/Activity';
import { useAuth0 } from '@auth0/auth0-react';
import {
  activitiesInHomepageFeedIsEnabled,
  continuousFeedbackGivingFeedbackIsEnabled,
  continuousFeedbackInHomepageFeedIsEnabled,
  continuousFeedbackRecognitionIsEnabled,
  pulseChecksInHomepageFeedIsEnabled,
} from 'utils/util/features';
import { Features, Organization } from 'types';

const getFocalContributionsForDashboard = (activity) => {
  // get the most recent contribution
  const mostRecent = getLatestNonEmptyContributionFromList(
    activity.contributions
  );

  if (!mostRecent) {
    return [];
  }

  return [mostRecent];
};

const ActivityFeed: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const [isMounted, setIsMounted] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [highlights, setHighlights] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth0();
  const highlightsHeadingRef = useRef<HTMLHeadingElement>(null);

  const userSub = user?.sub;

  useEffect(() => {
    if (!isMounted || !props.features) {
      return;
    }

    // fetch highlights from ElasticSearch
    if (userSub && props.currentOrgId) {
      // if continuous feedback is enabled, pull that into the feed,
      // else pull from the activities-only feed
      ElasticsearchAPI.getHighlightsFeed(
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        props.currentOrgId,
        props.features,
        (newHighlights) => {
          if (isMounted) {
            setHighlights(newHighlights);
          }
        },
        (message) => {
          // We got an error from sentry where message.includes
          // wasn't a function.
          // I suspect we got an object instead of a string.
          // Leaving this code here to troubleshoot, can be safely removed once
          // we figure out which type is causing the error.
          if (message && typeof message !== 'string') {
            console.error(
              `Expected string, got ${typeof message}. Object was ${JSON.stringify(
                message
              )}`
            );
          }

          // fyi -- this is an string that comes from Axios, so we should not
          // translate it
          if (message?.includes && message.includes('Network Error')) {
            message = formatMessage({
              id: 'app.errors.network_error.try_again',
              defaultMessage:
                "We'sorry, there was a network problem. Please reload the page and try again.",
            });
          }
          setErrorMessage(message);
        }
      );
    }
  }, [
    isMounted,
    userSub,
    props.currentOrgId,
    // @ts-expect-error
    props.currentProxyPerson,
    props.features,
    formatMessage,
  ]);

  const publicActivityList = useMemo(() => {
    const publicActivities: string[] = [];

    if (activitiesInHomepageFeedIsEnabled(props.features)) {
      publicActivities.push(
        intl.formatMessage({
          id: 'app.activity_feed.public_activity.accomplishments',
          defaultMessage: 'accomplishments',
        })
      );
    }

    if (continuousFeedbackInHomepageFeedIsEnabled(props.features)) {
      if (continuousFeedbackGivingFeedbackIsEnabled(props.features)) {
        publicActivities.push(
          intl.formatMessage({
            id: 'app.activity_feed.public_activity.feedback',
            defaultMessage: 'feedback',
          })
        );
      }

      if (continuousFeedbackRecognitionIsEnabled(props.features)) {
        publicActivities.push(
          intl.formatMessage({
            id: 'app.activity_feed.public_activity.recognition',
            defaultMessage: 'recognition',
          })
        );
      }
    }

    if (pulseChecksInHomepageFeedIsEnabled(props.features)) {
      publicActivities.push(
        intl.formatMessage({
          id: 'app.activity_feed.public_activity.pulse_checks',
          defaultMessage: 'pulse checks',
        })
      );
    }

    return intl.formatList(publicActivities, { type: 'conjunction' });
  }, [intl, props.features]);

  // fetch highlights from ElasticSearch for this user
  if (!props.meId || !props.currentOrgId) {
    return <Loading />;
  }

  if (errorMessage) {
    return (
      <Card>
        <CardBody>{errorMessage}</CardBody>
      </Card>
    );
  }

  if (typeof highlights === 'undefined') {
    return <Loading />;
  }

  const emptyStateSubtitle = (
    <span>
      <FormattedMessage
        id="app.activity_feed.empty_state.header.subtitle.text"
        defaultMessage="There are no highlights shared yet."
      />{' '}
      {activitiesInHomepageFeedIsEnabled(props.features) && (
        <span
          className="text-primary"
          role="button"
          onClick={props.openCreateActivityDialog}
        >
          <FormattedMessage
            id="app.activity_feed.empty_state.header.subtitle.button"
            defaultMessage="Be the first to share!"
          />
        </span>
      )}
    </span>
  );

  return (
    <>
      {props.currentOrganization?.name && props.features && (
        <>
          <h4
            className="mb-4 text-muted d-inline-block"
            ref={highlightsHeadingRef}
          >
            <FormattedMessage
              id="app.activity_feed.header.title"
              defaultMessage="Highlights"
            />
          </h4>
          <UncontrolledPopover
            trigger="hover"
            placement="right"
            target={highlightsHeadingRef}
          >
            <h5 className="fw-bold mb-3">
              <FormattedMessage
                id="app.activity_feed.header.popover_description_header"
                defaultMessage="<strong>What shows in this highlights feed?</strong>"
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </h5>
            <FormattedMessage
              id="app.activity_feed.header.popover_description_contents"
              defaultMessage="Public activities and private activities that you have permission to see, if any, will appear here. This includes {public_activity_list}."
              values={{
                public_activity_list: publicActivityList,
              }}
            />
          </UncontrolledPopover>
        </>
      )}
      {
        // @ts-expect-error
        !(highlights?.length > 0) && (
          <Card>
            <CardBody>
              <EmptyState
                title={formatMessage({
                  id: 'app.activity_feed.empty_state.header.title',
                  defaultMessage: 'Get started',
                })}
                subtitle={emptyStateSubtitle}
              />
            </CardBody>
          </Card>
        )
      }
      {
        // @ts-expect-error
        highlights.map((highlight) => {
          if (highlight._index === 'feedback') {
            return (
              <FeedbackCard
                key={'f-' + highlight.id}
                feedback={highlight}
                showAvatars={true}
              />
            );
          }

          if (highlight._index === 'pulse_checks') {
            return (
              <PulseCheckCard
                key={'p-' + highlight.id}
                person={highlight.person}
                pulseCheck={highlight}
                avatarSize="sm"
              />
            );
          }

          return (
            <ActivityCard
              key={'a-' + highlight.id}
              showPrompt={true}
              activity={highlight}
              focalContributions={getFocalContributionsForDashboard(highlight)}
              setActivity={(a) =>
                // @ts-expect-error
                setHighlights(highlights.map((a2) => (a2.id === a.id ? a : a2)))
              }
              openCreateActivityDialog={props.openCreateActivityDialog}
            />
          );
        })
      }
    </>
  );
};

interface Props {
  features: Features;
  currentOrganization: Organization;
  currentOrgId: number;
  meId: number;
  showCreateActivityDialog: boolean;
  openCreateActivityDialog: () => void;
  onCreateActivityDialogClosed: () => void;
}

const mapStateToProps = (state) => {
  const { features, currentOrganization, currentProxyPerson, me } = state;

  return {
    features,
    currentOrganization,
    currentProxyPerson,
    currentOrgId: currentOrganization?.id,
    meId: me?.id,
  };
};

export default connect(mapStateToProps)(React.memo(ActivityFeed));
