import * as consts from '../../consts/consts';

import { Collapse, Nav, NavItem } from 'reactstrap';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  isSkillsAndBehaviorsDirectoryEnabled,
  surveysAndPerformanceNavIsEnabled,
} from 'utils/util/features';

const SiteNav: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const [expandedCollapseId, setExpandedCollapseId] = useState(null);

  const toggleExpandedCollapseId = useCallback(
    (id) => {
      if (expandedCollapseId === id) {
        setExpandedCollapseId(null);
      } else {
        setExpandedCollapseId(id);
      }
    },
    [expandedCollapseId]
  );

  const collapseAllNavItems = useCallback(() => {
    // wait very briefly to avoid visual hang
    setTimeout(() => {
      setExpandedCollapseId(null);
    }, 100);
  }, []);

  const showIfEnabled = props.showIfEnabled;
  const isSuperAdmin = props.isSuperAdmin;
  const isOrgSystemAdmin = props.isOrgSystemAdmin;

  // @ts-expect-error
  const features = useMemo(() => props.features || {}, [props.features]);
  const objectives_restrict_visibility_to_match_editability =
    // @ts-expect-error
    props?.settings?.objectives_restrict_visibility_to_match_editability ??
    false;
  const objectives_rollups_enabled =
    features?.objectives?.rollup_enabled ?? true;

  const surveysAndPerfEnabled = surveysAndPerformanceNavIsEnabled(features);
  const skillsAndBehaviorsDirectoryEnabled =
    isSkillsAndBehaviorsDirectoryEnabled(features);

  const hasAnyTeamItems = useMemo(() => {
    return (
      features.team_overview_dashboard?.enabled ||
      features.engagement?.enabled ||
      features.one_on_ones?.enabled ||
      features.continuous_feedback?.enabled ||
      surveysAndPerfEnabled ||
      features.development_plans?.enabled ||
      features.promotion_packets?.enabled ||
      features.objectives?.enabled
    );
  }, [features, surveysAndPerfEnabled]);

  const menuItems = useMemo(() => {
    const menuItems = [];

    // @ts-expect-error
    if (!props.errorMessage) {
      // @ts-expect-error
      menuItems.push(consts.HOME(formatMessage));

      if (hasAnyTeamItems) {
        // @ts-expect-error
        menuItems.push({
          name: formatMessage({
            id: 'app.consts.team.name',
            defaultMessage: 'Team',
          }),
          icon: consts.ICONS.TEAM,
          children: [
            // TODO: efficiently determine if the current user is a people manager and if
            // not, do not show this link or show something else useful
            ...showIfEnabled(
              'team_overview_dashboard',
              consts.TEAM(formatMessage)
            ),
            ...showIfEnabled(
              'engagement',
              consts.ENGAGEMENT_HUB(formatMessage)
            ),
            ...showIfEnabled(
              'one_on_ones',
              consts.TEAM_ONE_ON_ONES(formatMessage)
            ),
            ...showIfEnabled(
              'continuous_feedback',
              consts.TEAM_FEEDBACK_AND_RECOGNITION(formatMessage)
            ),
            ...(surveysAndPerfEnabled
              ? [consts.TEAM_PERFORMANCE_DASHBOARD_HEADER_LINK(formatMessage)]
              : []),
            ...showIfEnabled(
              'development_plans',
              consts.DEVELOPMENT_PLANS(formatMessage)
            ),
            ...showIfEnabled(
              'promotion_packets',
              consts.PROMOTION_PACKETS(formatMessage)
            ),
            ...showIfEnabled(
              'objectives',
              consts.MY_TEAM_OBJECTIVES(formatMessage)
            ),
          ],
        });
      }

      const companyChildren = [
        ...showIfEnabled(
          'people',
          features.people?.org_chart
            ? consts.PEOPLE_ORG_CHART(formatMessage)
            : consts.PEOPLE_DIRECTORY(formatMessage)
        ),
        ...(skillsAndBehaviorsDirectoryEnabled
          ? [consts.SKILLS_DIRECTORY(formatMessage)]
          : []),
        ...showIfEnabled(
          'credentials',
          consts.CREDENTIALS_DIRECTORY(formatMessage)
        ),
        ...(objectives_rollups_enabled
          ? showIfEnabled('objectives', consts.TEAM_OBJECTIVES(formatMessage))
          : []),
        ...(!objectives_restrict_visibility_to_match_editability &&
        !objectives_rollups_enabled
          ? showIfEnabled('objectives', {
              ...consts.TEAM_OBJECTIVES_TAB_PARTICIPATION(formatMessage),
              name: consts.TEAM_OBJECTIVES(formatMessage).name,
            })
          : []),
        ...showIfEnabled('levels', consts.LEVELING_FRAMEWORK(formatMessage)),
        ...showIfEnabled('nudges', consts.NUDGES_ADMINISTRATION(formatMessage)),
      ];

      if (companyChildren.length > 0) {
        // @ts-expect-error
        menuItems.push({
          name: formatMessage({
            id: 'app.consts.company.name',
            defaultMessage: 'Company',
          }),
          icon: consts.ICONS.COMPANY,
          children: companyChildren,
        });
      }

      if (isSuperAdmin || isOrgSystemAdmin) {
        // @ts-expect-error
        menuItems.push({
          name: formatMessage({
            id: 'app.consts.admin.name',
            defaultMessage: 'Admin',
          }),
          icon: consts.ICONS.ADMIN,
          children: [
            consts.ADMINISTRATION_PEOPLE(formatMessage),
            ...showIfEnabled(
              'performance',
              consts.ADMINISTRATION_CAMPAIGNS(formatMessage)
            ),
            consts.APP_INTEGRATIONS(formatMessage),
          ],
        });
      }
    }

    // if any items have exactly one child, change the path to the child's path
    // and remove the child (so we don't have links with one item) for convenience
    return menuItems.map((item) => {
      // @ts-expect-error
      if (item?.children?.length === 1) {
        return {
          // @ts-expect-error
          ...item,
          // @ts-expect-error
          path: item.children[0].path,
          children: undefined,
        };
      }

      return item;
    });
  }, [
    // @ts-expect-error
    props.errorMessage,
    formatMessage,
    hasAnyTeamItems,
    showIfEnabled,
    features.people?.org_chart,
    skillsAndBehaviorsDirectoryEnabled,
    objectives_rollups_enabled,
    objectives_restrict_visibility_to_match_editability,
    isSuperAdmin,
    isOrgSystemAdmin,
    surveysAndPerfEnabled,
  ]);

  return (
    <Nav navbar>
      {menuItems.map((menuItem, menuItemIndex) => {
        const togglerId = 'site-nav-toggler-' + menuItemIndex;
        const isExpanded = expandedCollapseId === togglerId;

        return (
          <NavItem key={menuItemIndex}>
            {!menuItem.external && !menuItem.children && (
              <NavLink
                exact={menuItem.exact ? menuItem.exact : true}
                to={menuItem.path}
                className="nav-link"
                onClick={collapseAllNavItems}
              >
                {menuItem.icon && <i className={menuItem.icon} />}
                {menuItem.name}
              </NavLink>
            )}
            {!menuItem.external && menuItem.children && (
              <>
                <span
                  role="button"
                  className="nav-link"
                  data-bs-toggle="collapse"
                  aria-expanded={isExpanded ? 'true' : 'false'}
                  onClick={() => toggleExpandedCollapseId(togglerId)}
                >
                  {menuItem.icon && <i className={menuItem.icon} />}
                  {menuItem.name}
                </span>
                <Collapse isOpen={isExpanded}>
                  <ul className="nav nav-sm flex-column">
                    {menuItem.children.map((item, index) => (
                      <NavItem key={index}>
                        <NavLink
                          className="nav-link"
                          exact={item.exact ? item.exact : true}
                          to={item.path}
                        >
                          {item.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </ul>
                </Collapse>
              </>
            )}
            {menuItem.external && (
              <a href={menuItem.path} className="nav-link">
                {menuItem.name}
              </a>
            )}
          </NavItem>
        );
      })}
    </Nav>
  );
};

const SiteNav_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  showIfEnabled: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  isOrgSystemAdmin: PropTypes.bool.isRequired,
};

type Props = PropTypes.InferProps<typeof SiteNav_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { features, currentOrganization, currentProxyPerson, me, settings } =
    state;

  return {
    settings,
    features,
    currentOrganization,
    currentProxyPerson,
    me,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(SiteNav));
