import * as consts from '../../consts/consts';

import { Button, Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import { Link } from 'react-router-dom';
import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import { Person } from '../../types';
import PreviewModal from './PreviewModal';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { parseJSON } from 'date-fns';
import { toast } from 'react-toastify';
import { toastErrorOrCallback } from '../../utils/util/util';
import { useConfirmApiWithDefault } from '../../utils/api/ApiHooks';

const MAX_BONUS = 5000;

type ReferralType = 'P' | 'R';

const buildReferralUrl = (code?: string): string =>
  `https://www.confirm.com/demo?utm_campaign=Referral%20program&utm_source=app&utm_medium=${code}`;

interface Props {
  me: Person;
}

interface ReferralResponseItem {
  id: number;
  created_at: string;
  referral_code: string;
  recipient_email: string;
  bonus_amount: number;
  type: ReferralType;
}

const Referrals: FC<Props> = ({ me }) => {
  const { formatMessage } = useIntl();

  const [reloadCount, setReloadCount] = useState<number>(0);
  const [previewOpened, setPreviewOpened] = useState<boolean>(false);

  const { data, status } = useConfirmApiWithDefault<ReferralResponseItem[]>({
    method: 'GET',
    url: '/referrals',
    params: reloadCount > 0 ? { reload: reloadCount } : undefined,
    defaultValue: [],
  });

  const personReferralUrl = useMemo(() => {
    const personalReferralCode = data.find((x) => x.type === 'P');
    return personalReferralCode
      ? buildReferralUrl(personalReferralCode.referral_code)
      : null;
  }, [data]);

  const referralsSent = useMemo(() => {
    return data
      .filter((r) => r.type === 'R')
      .map((r) => ({
        ...r,
        created_at: parseJSON(r.created_at),
      }));
  }, [data]);

  // we don't allow inviting the same people twice
  const excludeList = useMemo(
    () => referralsSent.map((x) => ({ email: x.recipient_email })),
    [referralsSent]
  );

  const bonusAmount = useMemo(
    () =>
      Math.min(
        MAX_BONUS,
        data.reduce((acc, curr) => acc + curr.bonus_amount, 0)
      ),
    [data]
  );

  const copyLink = useCallback(() => {
    if (personReferralUrl) {
      navigator.clipboard.writeText(personReferralUrl);
      toast.success(
        formatMessage({
          id: 'app.views.referrals.referrals.invite_via_link.copy_successful',
          defaultMessage: 'The link was copied to the clipboard.',
        })
      );
    }
  }, [personReferralUrl, formatMessage]);

  const submitInvites = useCallback(
    (data) => {
      if (data.people && data.people.length > 0) {
        ConfirmAPI.sendRequestToConfirm(
          'POST',
          '/referrals',
          data.people.map((p) => ({ recipient_email: p.email })),
          toastErrorOrCallback(
            (_response) => {
              toast.success(
                formatMessage({
                  id: 'app.views.referrals.referrals.invite_via_email.invites_sent',
                  defaultMessage: 'Invites sent!',
                }),
                { autoClose: 10000 } // 10 seconds
              );
              setReloadCount(reloadCount + 1);
            },
            undefined,
            10000 // autoClose = 10 seconds
          )
        );
      }
    },
    [formatMessage, reloadCount]
  );

  return (
    <Page title={consts.REFERRALS(formatMessage).name}>
      <></>
      <>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle tag="h3">
                  <FormattedMessage
                    id="app.views.referrals.referrals.banner.title"
                    defaultMessage={
                      '🎁 Refer a Business, Earn up to $5,000! 🎁'
                    }
                  />
                </CardTitle>

                <FormattedMessage
                  id="app.views.referrals.referrals.banner.body"
                  defaultMessage={
                    'Introduce a company to Confirm and earn $200 for every 200 employees they onboard.'
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="8">
            <Card>
              <CardBody>
                <CardTitle tag="h4">
                  <FormattedMessage
                    id="app.views.referrals.referrals.invite_via_link.title"
                    defaultMessage={'Invite business via link'}
                  />
                </CardTitle>
                <p>
                  <FormattedMessage
                    id="app.views.referrals.referrals.invite_via_link.body"
                    defaultMessage={
                      'Want to end the performance review nightmare? ✨ Join Confirm to identify the 🌟 top 15% driving half of your success, discover the hidden 🚧 bottom 5% causing hiccups, and seamlessly integrate engagement surveys, OKRs, and more with the insights from Organizational Network Analysis.'
                    }
                  />
                </p>
                <p>{personReferralUrl}</p>
                <p>
                  <Button color="primary" onClick={copyLink}>
                    <FormattedMessage
                      id="app.views.referrals.referrals.copy_link.title"
                      defaultMessage={'Copy link'}
                    />
                  </Button>
                </p>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle
                  tag="h4"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FormattedMessage
                    id="app.views.referrals.referrals.invite_via_email.title"
                    defaultMessage={'Invite business via email'}
                  />
                  <Button
                    className="pt-0"
                    onClick={() => setPreviewOpened(true)}
                    color={'link'}
                  >
                    <FormattedMessage
                      id="app.views.referrals.referrals.invite_via_email.preview_email"
                      defaultMessage={'Preview email'}
                    />
                  </Button>
                </CardTitle>
                <ValidatedForm
                  key={`referral_form_${reloadCount}`}
                  inputs={[
                    {
                      type: INPUT_TYPES.PEOPLE_EDITOR,
                      name: 'people',
                      label: '',
                      allowExternalPeople: true,
                      suggestions: [],
                      excludeList: excludeList,
                      excludeListErrorMessageFunction: (tag) =>
                        formatMessage(
                          {
                            id: 'app.views.referrals.referrals.form.people.already_invited',
                            defaultMessage: 'You already invited {name}',
                          },
                          {
                            name: tag.name,
                          }
                        ),
                    },
                  ]}
                  callback={submitInvites}
                  inlineSubmitButton={true}
                  buttonClassName={''}
                  submitText={
                    <FormattedMessage
                      id="app.views.referrals.referrals.form.submit"
                      defaultMessage="Send invite"
                    />
                  }
                />
                {status === 'LOADING' && <Loading />}
                {status === 'SUCCESS' && (
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage
                            id="app.views.referrals.referrals.table.email"
                            defaultMessage="Email"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="app.views.referrals.referrals.table.invitation_sent"
                            defaultMessage="Invitation sent"
                          />
                        </th>
                        <th>
                          <FormattedMessage
                            id="app.views.referrals.referrals.table.status"
                            defaultMessage="Status"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {referralsSent.map((referral) => (
                        <tr key={`referral_${referral.id}`}>
                          <td>{referral.recipient_email}</td>
                          <td>
                            <FormattedDate value={referral.created_at} />
                          </td>
                          <td>
                            <FormattedMessage
                              id="app.views.referrals.referrals.table.no_employees_onboarded"
                              defaultMessage="No employees onboarded yet"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>

            <div className="my-5">
              <h4>
                <FormattedMessage
                  id="app.views.referrals.referrals.review_header.title"
                  defaultMessage={
                    "Want to share the love but don't have someone specific in mind?"
                  }
                />
              </h4>
              <p>
                <FormattedMessage
                  id="app.views.referrals.referrals.review_header.body"
                  defaultMessage="📣 <link>Review Confirm on G2</link> to let people looking for performance management solutions know what you like about it!"
                  values={{
                    link: (str) => (
                      <Link
                        to={{ pathname: consts.G2_REVIEW_LINK_REFERRAL_PAGE }}
                        className="text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {str}
                      </Link>
                    ),
                  }}
                />
              </p>
            </div>
          </Col>
          <Col lg="4" md="12">
            <Card>
              <CardBody>
                <CardTitle tag="h4">
                  <FormattedMessage
                    id="app.views.referrals.referrals.summary.title"
                    defaultMessage={'Summary'}
                  />
                </CardTitle>
                {status === 'LOADING' && (
                  <Row>
                    <Col>
                      <Loading />
                    </Col>
                  </Row>
                )}
                {status === 'SUCCESS' && (
                  <Row>
                    <Col>
                      <FormattedMessage
                        id="app.views.referrals.referrals.summary.total_received"
                        defaultMessage={'Total received:'}
                      />
                    </Col>
                    <Col className="text-end col-auto">
                      <strong>
                        {'$'}
                        {bonusAmount}
                      </strong>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle tag="h4">
                  <FormattedMessage
                    id="app.views.referrals.referrals.how_it_works.title"
                    defaultMessage={'How it works'}
                  />
                </CardTitle>
                <ol>
                  <li className="mt-4">
                    <FormattedMessage
                      id="app.views.referrals.referrals.how_it_works.invite_and_introduce"
                      defaultMessage={
                        '<strong>📣 Invite & Introduce</strong>: Share the beauty of Confirm.com with businesses you know. Perfect for companies size 200-5000!'
                      }
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  </li>
                  <li className="mt-4">
                    <FormattedMessage
                      id="app.views.referrals.referrals.how_it_works.they_explore"
                      defaultMessage={
                        '<strong>🕵️‍♂️ They Explore</strong>: The referred company will need to schedule a call with our team through your exclusive referral link.'
                      }
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  </li>
                  <li className="mt-4">
                    <FormattedMessage
                      id="app.views.referrals.referrals.how_it_works.rewards_galore"
                      defaultMessage={
                        '<strong>🎉 Rewards Galore</strong>: For every 200 employees the referred company onboards, you receive $200. Maximum reward potential of $5,000 for a 5000-employee company!'
                      }
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  </li>
                </ol>
                <Row className="text-end">
                  <Col>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.confirm.com/en/articles/8511562-share-refer-earn-up-to-5000"
                    >
                      <FormattedMessage
                        id="app.views.referrals.referrals.learn_more"
                        defaultMessage={'Learn More '}
                      />{' '}
                      <i className={consts.ICONS.EXTERNAL}></i>
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
      <PreviewModal
        me={me}
        isOpen={previewOpened}
        onClosed={() => setPreviewOpened(false)}
      />
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { me } = state;

  return {
    me,
  };
};

const mapDispatchToProps = (_dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Referrals));
