import * as consts from '../../consts/consts';

import React, { FC } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import {
  orgIsActiveOrDemo,
  orgIsChurned,
} from '../../utils/models/Organization';

import ErrorBoundary from '../Error/ErrorBoundary';
import { Me, Person, Organization, ReduxState } from 'types';
import { connect } from 'react-redux';
import { redirectLoginOptionsGenerator } from '../../utils/util/utiltsx';
import { useIntl } from 'react-intl';
import { userHasCompletedWelcome } from '../../utils/models/User';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { withFallbackErrorWithSpecialInstructions } from '../../hacks/hacks';
import { processRipplingData } from 'utils/util/MergeRipplingUtils';

interface ProtectedRouteProps {
  component: ((...args: any[]) => any) | object;
  currentOrganization?: Organization;
  currentProxyPerson?: Person;
  hasCompletedWelcome: boolean;
  isHRBP?: boolean;
  match?: object;
  me?: Me;
  meId?: number;
  routes: object[];
  [key: string]: any; // for that ...args
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component,
  currentOrganization,
  hasCompletedWelcome,
  isHRBP,
  match,
  me,
  meId,
  routes,
  ...args
}) => {
  const location = useLocation();
  const params = useParams();
  const { formatMessage } = useIntl();

  const isSignup = location.pathname === '/signup';

  //const authParams = {route.authParams ? route.authParams : {}}
  const breadcrumbs = routes
    // Get all routes that contain the current one (except the top level "Dashboard" "/" path)
    // @ts-expect-error
    .filter(({ path }) => path !== '/' && match.path.startsWith(path))
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    // @ts-expect-error
    .map(({ path, ...rest }) => ({
      path: Object.keys(params).length
        ? Object.keys(params).reduce(
            (path, param) => path.replace(`:${param}`, params[param]),
            path
          )
        : path,
      ...rest,
    }));

  // page to return to if we need to redirect elsewhere for some reason
  const continueUrl = location.pathname + location.search + location.hash;

  // ensure that user is attached to an organization before anything else;
  // if not, show error message (NOTE: we check for null vs undefined because
  // undefined could be due to a network outage meaning an error will show,
  // but null means the server returned no org)
  if (meId && currentOrganization === null) {
    return <Redirect to={consts.NO_ORGANIZATION(formatMessage).path} />;
  }

  // ensure that user is attached to an active (or demo) organization; if not active, show error accordingly
  // TODO: show more customized org depending on specific status
  if (
    meId &&
    currentOrganization &&
    !orgIsActiveOrDemo(currentOrganization) &&
    !orgIsChurned(currentOrganization) // handle churned orgs below
  ) {
    return <Redirect to={consts.NO_ORGANIZATION(formatMessage).path} />;
  }

  if (me && me.is_customer_provisioned != null && !me.is_customer_provisioned) {
    return <Redirect to={consts.USER_NOT_PROVISIONED(formatMessage).path} />;
  }

  if (
    meId &&
    currentOrganization &&
    orgIsChurned(currentOrganization) &&
    !isHRBP // deny access to churned orgs unless user is an HRBP
  ) {
    return <Redirect to={consts.CHURNED_ORGANIZATION(formatMessage).path} />;
  }

  const ripplingData = processRipplingData(location.search);

  // ensure that when logged in, welcome has been completed before proceeding to given component
  if (
    meId &&
    !location.pathname?.startsWith(consts.LOGOUT(formatMessage).path) &&
    !location.pathname?.startsWith(consts.WELCOME(formatMessage).path) &&
    !ripplingData
  ) {
    if (!hasCompletedWelcome) {
      const RequireCompletedWelcomeComponent = () => {
        return (
          <Redirect
            to={
              consts.WELCOME(formatMessage).path +
              '?' +
              consts.URL_QUERY_PARAMETERS.CONTINUE_URL +
              '=' +
              encodeURIComponent(continueUrl)
            }
          />
        );
      };

      return <RequireCompletedWelcomeComponent />;
    }
  }

  // if not logged in, go back to this page after logging in
  const ComponentToRender = withAuthenticationRequired(
    // @ts-expect-error
    component,
    redirectLoginOptionsGenerator(continueUrl, isSignup)
  );

  return (
    <ErrorBoundary fallback={withFallbackErrorWithSpecialInstructions}>
      <ComponentToRender match={match} breadcrumbs={breadcrumbs} {...args} />
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { me, myConfigs, currentOrganization, currentProxyPerson } = state;

  return {
    me,
    currentOrganization,
    currentProxyPerson,
    meId: me?.id,
    hasCompletedWelcome: userHasCompletedWelcome(myConfigs),
  };
};

export default connect(mapStateToProps)(React.memo(ProtectedRoute));
