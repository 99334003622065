import { Button, Col, Row, Table, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICONS, PERFORMANCE } from '../../consts/consts';
import React, { FC, Fragment, useMemo, useState } from 'react';

import PersonCard from './Cards/PersonCard';
import PropTypes from 'prop-types';
import { getPersonField } from '../../utils/models/Takeaways';

const PeopleTable: FC<Props> = ({
  fields: propsFields = [
    'full_name',
    'tenure',
    'influence',
    'gold_stars',
    'heads_ups',
    'promotion',
    'rating',
  ],
  people: propsPeople = [],
  initialSize: propsInitialSize = 10,
  maxSize: propsMaxSize = 35,
  isTripleLeaderboard = false,
  showRating = true,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setExpanded] = useState(false);
  const initialSize = useMemo(() => {
    const numPeople = propsPeople?.length || 0;
    // @ts-expect-error
    return propsInitialSize > numPeople ? numPeople : propsInitialSize;
  }, [propsInitialSize, propsPeople?.length]);
  const maxPeople = useMemo(() => {
    const numPeople = propsPeople?.length || 0;
    const potentialMax = propsMaxSize || numPeople;
    return potentialMax > numPeople ? numPeople : potentialMax;
  }, [propsPeople?.length, propsMaxSize]);
  const headerFields = useMemo(() => {
    const nameheader = props.nameHeader
      ? props.nameHeader
      : formatMessage({
          id: 'app.widgets.people_table.header.name',
          defaultMessage: 'Name',
        });
    const fields = {
      full_name: [
        'string',
        nameheader,
        formatMessage({
          id: 'app.widgets.people_table.full_name',
          defaultMessage: 'Name, title, and link to performance report.',
        }),
        false,
      ],
      tenure: [
        'icon',
        ICONS.TENURE,
        formatMessage({
          id: 'app.widgets.people_table.tenure',
          defaultMessage:
            'How many months the person has been at confirm (in any role) since their last start date.',
        }),
        true,
      ],
      gold_stars: [
        'icon',
        ICONS.GOLD_STAR,
        formatMessage({
          id: 'app.widgets.people_table.gold_star',
          defaultMessage: 'Number of gold stars received during this cycle.',
        }),
        true,
      ],
      heads_ups: [
        'icon',
        ICONS.HEADS_UP,
        formatMessage({
          id: 'app.widgets.people_table.heads_ups',
          defaultMessage: 'Number of heads ups received during this cycle.',
        }),
        true,
      ],
      promotion: [
        'icon',
        ICONS.PROMOTION,
        formatMessage({
          id: 'app.widgets.people_table.promotion',
          defaultMessage:
            'Manager plans to submit a promotion packet for this person.',
        }),
        true,
      ],
    };
    if (showRating) {
      // @ts-expect-error
      fields.rating = [
        'icon',
        ICONS.FINAL_RATING,
        formatMessage({
          id: 'app.widgets.people_table.final_rating',
          defaultMessage: 'Final performance rating for this cycle.',
        }),
        true,
      ];
    }
    if (props.shouldShowInfluence) {
      // @ts-expect-error
      fields.influence = [
        'icon',
        ICONS.INFLUENCE,
        formatMessage({
          id: 'app.widgets.people_table.influence',
          defaultMessage:
            'Number of people that were energized or advised by this person during this cycle.',
        }),
        true,
      ];
    }
    return fields;
  }, [props.nameHeader, props.shouldShowInfluence, showRating, formatMessage]);

  const fields = useMemo(() => {
    let _fields = propsFields;
    if (!showRating) {
      // @ts-expect-error
      _fields = _fields.filter((f) => f !== 'rating');
    }
    return _fields;
  }, [propsFields, showRating]);

  const header = useMemo(
    () => (
      <tr>
        {/* @ts-expect-error */}
        {fields.map((f, index) =>
          // @ts-expect-error
          headerFields[f] ? (
            <th
              // @ts-expect-error
              className={'ps-0 pe-2' + (headerFields[f][3] ? ' text-end' : '')}
              key={index}
            >
              <>
                {/* @ts-expect-error */}
                {headerFields[f][0] === 'icon' ? (
                  <i
                    id={props.contentId + '-header-' + f}
                    // @ts-expect-error
                    className={headerFields[f][1]}
                  />
                ) : (
                  <span id={props.contentId + '-header-' + f}>
                    {/* @ts-expect-error */}
                    {headerFields[f][1]}
                  </span>
                )}
                {index >= 0 && (
                  <UncontrolledPopover
                    placement="top"
                    trigger="hover"
                    target={props.contentId + '-header-' + f}
                  >
                    {/* @ts-expect-error */}

                    {headerFields[f][2]}
                  </UncontrolledPopover>
                )}
              </>
              {index === 0 &&
                props.helpPopoverContent &&
                props.helpPopoverContentId && (
                  <>
                    <i
                      id={props.helpPopoverContentId}
                      className={
                        ICONS.HELP + ' ms-2 text-primary position-relative'
                      }
                      style={{
                        top: 1,
                      }}
                    />
                    <UncontrolledPopover
                      placement="top"
                      trigger="hover"
                      target={props.helpPopoverContentId}
                    >
                      {/* @ts-expect-error */}
                      {props.helpPopoverContent}
                    </UncontrolledPopover>
                  </>
                )}
            </th>
          ) : (
            <Fragment key={index}></Fragment>
          )
        )}
      </tr>
    ),
    [
      fields,
      props.contentId,
      props.helpPopoverContent,
      props.helpPopoverContentId,
      headerFields,
    ]
  );
  const body = useMemo(() => {
    const numPeople = isExpanded ? maxPeople : propsInitialSize;
    // @ts-expect-error
    return propsPeople.slice(0, numPeople).map((p, personIndex) => (
      <tr key={personIndex}>
        {/* @ts-expect-error */}
        {fields.map((f, fieldIndex) => {
          let value;
          let alignRight = false;

          if (f === 'full_name') {
            // replace full name with person widget
            value = (
              <PersonCard
                size="sm"
                // @ts-expect-error
                person={{
                  id: getPersonField(p, 'id'),
                  url: getPersonField(p, 'url'),
                  avatar: getPersonField(p, 'avatar'),
                  full_name: getPersonField(p, 'full_name'),
                  title: getPersonField(p, 'title'),
                  status: getPersonField(p, 'status'),
                }}
                hidePopover
                bodyOnly
                linked
                isExternalUrl
                urlSuffix={PERFORMANCE().path + '?manager=true'}
                leaderboardNumber={personIndex + 1}
              />
            );
          } else {
            value = getPersonField(p, f);

            if (typeof value === 'number') {
              alignRight = true;
            }

            if (f === 'influence_delta') {
              value = Math.round(value);
              value = value < 0 ? value : `+${value}`;
            }
          }

          return (
            <td
              className={
                'ps-0 pe-2' +
                (alignRight ? ' text-end' : '') +
                (f === 'full_name' && isTripleLeaderboard ? ' col-md-8' : '')
              }
              key={fieldIndex}
              style={{ fontSize: isTripleLeaderboard ? '14px' : '' }}
            >
              {value}
            </td>
          );
        })}
      </tr>
    ));
  }, [
    isExpanded,
    maxPeople,
    propsInitialSize,
    propsPeople,
    fields,
    isTripleLeaderboard,
  ]);

  return (
    <>
      <Table
        className={
          'card-table' + (isTripleLeaderboard ? ' triple-leaderboard' : '')
        }
      >
        <thead>{header}</thead>
        <tbody>{body}</tbody>
      </Table>
      {/* @ts-expect-error */}
      {initialSize != propsMaxSize && initialSize < propsPeople.length ? (
        <Row className="border-top pt-2">
          <Col>
            <Button
              style={{ paddingLeft: '1.5rem' }}
              color="link border-none"
              onClick={() => {
                setExpanded(!isExpanded);
              }}
            >
              {isExpanded ? (
                <FormattedMessage
                  id="util.show_less"
                  description="Appears after content on a button to show less of it"
                  defaultMessage="Show less"
                />
              ) : (
                <FormattedMessage
                  id="util.show_more"
                  description="Appears after content on a button to show more of it"
                  defaultMessage="Show more"
                />
              )}
            </Button>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

const PeopleTable_propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  nameHeader: PropTypes.string,
  helpPopoverContent: PropTypes.object,
  helpPopoverContentId: PropTypes.string,
  people: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  initialSize: PropTypes.number,
  maxSize: PropTypes.number,
  isTripleLeaderboard: PropTypes.bool,
  contentId: PropTypes.string.isRequired,
  shouldShowInfluence: PropTypes.bool,
  showRating: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof PeopleTable_propTypes>;

export default React.memo(PeopleTable);
