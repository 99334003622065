import * as consts from '../../consts/consts';

import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  INPUT_ATTRIBUTES,
  INPUT_TYPES,
} from '../Widgets/Inputs/ValidatedInputTypes';
import {
  MAX_SUGGESTED_SUPPORTERS,
  getRecommendationSearchFilters,
  getRecommendationSearchTerms,
  goalsAreEqual,
} from '../../utils/models/Goal';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getPrettyDate, prepTagsForSubmit } from '../../utils/util/util';

import AvatarGroup from '../Widgets/People/AvatarGroup';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import TagsList from '../Widgets/TagsList';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

export const GOAL_EDITOR_FIELDS = (formatMessage) => ({
  name: {
    required: true,
    name: 'name',
    label: formatMessage({
      id: 'app.views.goals.goal_form.name.label',
      defaultMessage: 'Title',
    }),
    placeholder: formatMessage({
      id: 'app.views.goals.goal_form.name.plcaeholder',
      defaultMessage: 'Enter title',
    }),
    type: INPUT_TYPES.TEXTAREA,
    maxLength: consts.GOAL_NAME_MAX_LENGTH,
  },
  tags: {
    ...INPUT_ATTRIBUTES(formatMessage).SKILLS_AND_CREDENTIALS,
    name: 'tags',
    helperText: formatMessage({
      id: 'app.views.goals.goal_form.tags.helper_text',
      defaultMessage: 'Providing this will enable resources to help you.',
    }),
  },
  targeted_at: {
    name: 'targeted_at',
    label: formatMessage({
      id: 'app.views.goals.goal_form.targeted_at.label',
      defaultMessage: 'Target completion date',
    }),
    placeholder: formatMessage({
      id: 'app.views.goals.goal_form.targeted_at.placeholder',
      defaultMessage: 'Target date',
    }),
    type: INPUT_TYPES.DATE_PICKER,
  },
  visibility: {
    type: INPUT_TYPES.TOGGLE,
    name: 'visibility',
  },
  aspirations: {
    //...INPUT_ATTRIBUTES.ASPIRATIONS,
    name: 'aspirations',
    label: formatMessage({
      id: 'app.views.goals.goal_form.aspirations.label',
      defaultMessage: 'What aspiration(s) is this goal associated with?',
    }),
  },
});

const GoalForm: FC<Props> = (props) => {
  const title = 'Create goal';
  const modalTitle = title;
  const anchorTrigger = 'create-goal';
  const propsOnClosed = props.onClosed;
  const propsOnDelete = props.onDelete;

  const { formatMessage, locale } = useIntl();
  const [goal, setGoal] = useState(props.goal);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);
  const propsSetAutosaveStatus = props.setAutosaveStatus;
  const [suggestedSupporters, setSuggestedSupporters] = useState(null);
  const [recommendationSearchFilters, setRecommendationSearchFilters] =
    useState(getRecommendationSearchFilters(props.goal));
  const [recommendationSearchTerms, setRecommendationSearchTerms] = useState(
    getRecommendationSearchTerms(props.goal)
  );

  // @ts-expect-error
  const currentOrgId = props.currentOrganization?.id;
  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    setGoal(props.goal);
  }, [props.goal]);

  useEffect(() => {
    if (propsSetAutosaveStatus && deleteValidationErrors) {
      propsSetAutosaveStatus(deleteValidationErrors);
    }
  }, [propsSetAutosaveStatus, deleteValidationErrors]);

  // when tags are updated, update supporter recommendations
  useEffect(() => {
    const newRecommendationSearchFilters = getRecommendationSearchFilters(goal);

    if (!isEqual(newRecommendationSearchFilters, recommendationSearchFilters)) {
      setRecommendationSearchFilters(newRecommendationSearchFilters);
    }
  }, [goal, recommendationSearchFilters]);

  // update terms to search course recommendations
  useEffect(() => {
    const newRecommendationSearchTerms = getRecommendationSearchTerms(goal);

    if (newRecommendationSearchTerms !== recommendationSearchTerms) {
      setRecommendationSearchTerms(newRecommendationSearchTerms);
    }
  }, [goal, recommendationSearchTerms]);

  useEffect(() => {
    if (recommendationSearchFilters && currentOrgId) {
      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        undefined,
        // @ts-expect-error
        props.currentProxyPerson,
        currentOrgId,
        'get-people-by-name',
        {
          unwrap_source: 'true',
          filters: recommendationSearchFilters,
          size: MAX_SUGGESTED_SUPPORTERS,
          sort: [
            {
              status: {
                unmapped_type: 'keyword',
                order: 'asc',
              },
              'full_name.raw': {
                unmapped_type: 'keyword',
                order: 'asc',
              },
            },
          ],
        },
        (hitSources) => {
          const newPeople = hitSources.filter(
            (p) => p.id?.toString() !== props.meId
          );
          setSuggestedSupporters(newPeople);
        },
        (message) => {
          console.error(
            'Get people by name search error: ' + JSON.stringify(message)
          );
        }
      );
    } else {
      // @ts-expect-error
      setSuggestedSupporters([]);
    }
  }, [
    currentOrgId,
    // @ts-expect-error
    props.currentProxyPerson,
    props.meId,
    recommendationSearchFilters,
    recommendationSearchTerms,
    userSub,
  ]);

  const onClosed = useCallback(() => {
    // NOTE: we do NOT reset other defaults because someone may accidentally close
    // the dialog either by pressing Esc or clicking outside the dialog; we want
    // reopening to keep the info in there
    if (propsOnClosed) {
      propsOnClosed();
    }
  }, [propsOnClosed]);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const transformedObject = {
        ...object,
        skills: prepTagsForSubmit(
          object.tags?.filter((o) => o._index === 'skills'),
          // @ts-expect-error
          props.currentOrganization?.id
        ),
        credentials: prepTagsForSubmit(
          object.tags?.filter((o) => o._index === 'credentials'),
          // @ts-expect-error
          props.currentOrganization?.id
        ),
        // @ts-expect-error
        organization: props.currentOrganization?.id,
        // don't pass to backend the placeholders used to generate the above
        tags: undefined,
      };

      return transformedObject;
    },
    // @ts-expect-error
    [props.currentOrganization?.id]
  );

  // render inputs into three separate steps
  const renderInputs = useCallback(
    (inputs) => {
      return (
        <Row
          className={'align-items-center' + (props.isReadOnly ? ' mb-3' : '')}
        >
          <Col>
            <Row>
              <Col className={props.inSidebar ? 'col-12' : 'col-9'}>
                {inputs[0]}
              </Col>
              <Col className={props.inSidebar ? 'col-auto' : 'col-3'}>
                <div
                  className={
                    'd-flex align-items-center' +
                    (props.inSidebar ? ' small text-muted' : '')
                  }
                >
                  <span className="text-muted small me-2">
                    <i className="fe fe-clock"></i>
                  </span>
                  {inputs[2]}
                </div>
              </Col>
            </Row>
            {/* @ts-expect-error */}
            {(goal?.name ||
              // @ts-expect-error
              goal?.skills?.length > 0 ||
              // @ts-expect-error
              goal?.credentials?.length > 0) && (
              <>
                <Row>
                  <Col>{inputs[1]}</Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      );
    },
    [
      // @ts-expect-error
      goal?.credentials?.length,
      // @ts-expect-error
      goal?.name,
      // @ts-expect-error
      goal?.skills?.length,
      props.inSidebar,
      props.isReadOnly,
    ]
  );

  // hide submit button (as we are autosaving)
  const renderForm = useCallback((inputs) => {
    return <>{inputs}</>;
  }, []);

  const onChange = useCallback(
    (obj) => {
      const newGoal = {
        ...obj,
        skills: obj.tags.filter((o) => o._index === 'skills'),
        credentials: obj.tags.filter((o) => o._index === 'credentials'),
        // strip intermediary fields
        tags: undefined,
      };

      // only change if an update occurred
      if (!goalsAreEqual(newGoal, goal)) {
        setGoal(newGoal);
      }
    },
    [goal]
  );

  const object = useMemo(() => {
    return {
      ...goal,
      tags: [
        // @ts-expect-error
        ...(goal?.skills
          ? // @ts-expect-error
            goal.skills.map((o) => ({ ...o, _index: 'skills' }))
          : []),
        // @ts-expect-error
        ...(goal?.credentials
          ? // @ts-expect-error
            goal.credentials.map((o) => ({
              ...o,
              _index: 'credentials',
            }))
          : []),
      ],
      // strip fields not user in the frontend
      skills: undefined,
      credentials: undefined,
    };
  }, [goal]);

  const onDeleteGoal = useCallback(() => {
    // @ts-expect-error
    if (goal?.id) {
      ConfirmAPI.sendRequestToConfirm(
        'DELETE',
        // @ts-expect-error
        '/goals/' + goal?.id,
        {},
        (response, error, hardErrorMessage = null) => {
          setIsDeleting(false);
          if (error) {
            // failure; keep modal open
            if (hardErrorMessage) {
              // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
              // leave this message blank as those errors will get surfaced below
              setDeleteValidationErrors(hardErrorMessage);
            } else {
              setDeleteValidationErrors(error);
            }
          } else {
            setDeleteModalIsOpen(false);
            // @ts-expect-error
            propsOnDelete();
          }
        },
        null
      );
    } else {
      // nothing to send to server
      // @ts-expect-error
      propsOnDelete();
    }
    // @ts-expect-error
  }, [goal.id, propsOnDelete]);

  const goalIsEmpty = useCallback((goal) => {
    return (
      !(goal.name?.length > 0) &&
      !(goal.description?.length > 0) &&
      !(goal.skills?.length > 0) &&
      !(goal.credentials?.length > 0)
    );
  }, []);

  const onNameBackspace = useCallback(
    (e) => {
      if (e.target?.value === '' || !e.target?.value) {
        // clear out saving indicators and don't keep saving
        setIsDeleting(true);
        // @ts-expect-error
        propsSetAutosaveStatus(null);

        if (goalIsEmpty(goal)) {
          // if goal has no date, tags, or supporters,
          // delete it without asking
          onDeleteGoal();
        } else {
          setDeleteModalIsOpen(true);
        }
      }
    },
    [goal, goalIsEmpty, onDeleteGoal, propsSetAutosaveStatus]
  );

  // @ts-expect-error
  const goalIdString = goal?.id ? goal.id : '0';

  if (props.isReadOnly) {
    return renderInputs([
      // @ts-expect-error
      object.name,
      <TagsList
        key={goalIdString.toString() + '-1'}
        // @ts-expect-error
        credentials={goal.credentials}
        // @ts-expect-error
        skills={goal.skills}
      />,
      <span key={goalIdString.toString() + '-2'}>
        {/* @ts-expect-error */}
        {goal.targeted_at
          ? // @ts-expect-error
            getPrettyDate({ dateString: goal.targeted_at, locale })
          : formatMessage({
              id: 'app.views.goals.no_target_date',
              defaultMessage: 'No target date',
            })}
      </span>,
    ]);
  }

  // not read only
  return (
    <>
      <Card className="card-sm mb-3">
        <CardBody>
          <ConfirmationDialogModal
            isOpen={deleteModalIsOpen}
            onClosed={() => {
              setIsDeleting(false);
              setDeleteValidationErrors(null);
            }}
            toggle={toggleDeleteModal}
            confirmCallback={onDeleteGoal}
            title={formatMessage({
              id: 'app.views.goals.goal_form.title.delete_goal',
              defaultMessage: 'Delete goal?',
            })}
            description={formatMessage({
              id: 'app.views.goals.goal_form.description.delete_goal',
              defaultMessage: 'Are you sure that you want to delete this goal?',
            })}
            confirmText={formatMessage({
              id: 'app.views.goals.goal_form.confirm_text.delete_goal',
              defaultMessage: 'Delete goal',
            })}
            validationErrors={deleteValidationErrors}
          />
          <ValidatedForm
            autoFocus={props.autoFocus}
            className="kanban-add-form"
            // @ts-expect-error
            block
            isOpen={props.isOpen}
            onOpened={props.onOpened}
            onClosed={onClosed}
            onChange={onChange}
            inline={props.inline}
            buttonClassName={props.buttonClassName}
            url="goals"
            title={title}
            modalTitle={modalTitle}
            anchorTrigger={anchorTrigger}
            object={object}
            setAutosaveStatus={isDeleting ? undefined : props.setAutosaveStatus}
            callback={props.callback}
            transformObjectBeforeSubmit={transformObjectBeforeSubmit}
            renderInputs={renderInputs}
            renderForm={renderForm}
            inputs={[
              {
                ...GOAL_EDITOR_FIELDS(formatMessage)['name'],
                placeholder: formatMessage({
                  id: 'app.views.goals.goal_form.goal_editor_fields.placeholder',
                  defaultMessage: 'Write a goal name',
                }),
                className: 'form-control-flush form-control-auto pt-0',
                style: {
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                  height: 24,
                },
                label: undefined,
                autoFocus: props.autoFocus,
                formGroupClassName: 'mb-0',
                onBackspace: onNameBackspace,
                hideMaxLengthIndicator: true,
              },
              {
                ...GOAL_EDITOR_FIELDS(formatMessage)['tags'],
                helperText: undefined,
                className: 'form-control-flush form-control-auto small',
                inputClassName:
                  'border-0 mt-2 p-0 goal-inline-react-tag-autocomplete',
                formGroupClassName: 'mb-0',
                placeholder: formatMessage({
                  id: 'app.views.goals.goal_form.goal_editor_fields_skills.placeholder',
                  defaultMessage:
                    'List skills and credentials you want to grow',
                }),
              },
              {
                ...GOAL_EDITOR_FIELDS(formatMessage)['targeted_at'],
                label: undefined,
                formGroupClassName: 'mb-n2',
                inputClassName: 'form-control-flush form-control-auto mb-0',
                hideClearButton: true,
              },
              {
                ...GOAL_EDITOR_FIELDS(formatMessage)['visibility'],
              },
            ]}
          />
        </CardBody>
      </Card>
      {/* @ts-expect-error */}
      {suggestedSupporters?.length > 0 && (
        <Row className="align-items-center small ms-2 mb-4 text-muted mt-n1">
          {/* @ts-expect-error */}
          {suggestedSupporters?.length > 0 && (
            <>
              <Col className="col-auto pe-0">
                <FormattedMessage
                  id="app.views.goals.goal_form.helpful_coworkers"
                  defaultMessage="Helpful coworkers: "
                />
              </Col>
              <Col className="col-auto ps-2">
                <AvatarGroup
                  isExternalUrl={true}
                  size="xs"
                  people={suggestedSupporters}
                />
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
};

const GoalForm_propTypes = {
  inSidebar: PropTypes.bool,
  meId: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool,
  buttonText: PropTypes.string,
  goal: PropTypes.object,
  isOpen: PropTypes.bool,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  onDelete: PropTypes.func,
  inline: PropTypes.bool,
  buttonClassName: PropTypes.string,
  currentOrganization: PropTypes.object.isRequired,
  hideButton: PropTypes.bool,
  setAutosaveStatus: PropTypes.func,
  callback: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof GoalForm_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(GoalForm));
