import CardRowDashboard, { CARD_TYPES } from './CardRowDashboard';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import EmptyState from '../EmptyState';
import Loading from '../Loading';
import PageError from '../../Layout/Pages/Errors/PageError';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { getCampaignRatings } from '../../../utils/models/Performance';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { type RouteComponentProps } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const PROMO_OPTIONS = [
  {
    name: 'Yes',
    label: 'Recommended',
    key: 'True',
  },
  {
    name: 'No',
    label: 'Not recommended',
    key: 'False',
  },
  {
    name: 'TBD',
    label: 'TBD - pending',
    key: '?',
  },
];

const DEFAULT_COLLAPSED_STATE = {
  'Draft ratings by business unit': true,
  'Previous ratings by business unit': true,
  'Previous promotion recommendations by business unit': true,
  'Draft ratings by department': true,
  'Previous ratings by department': true,
  'Previous promotion recommendations by department': true,
  'Draft ratings by senior leader': true,
  'Previous ratings by senior leader': true,
  'Previous promotion recommendations by senior leader': true,
  'Draft ratings by location': true,
  'Previous ratings by location': true,
  'Previous promotion recommendations by location': true,
  'Draft ratings by HRBP': true,
  'Previous ratings by HRBP': true,
  'Previous promotion recommendations by HRBP': true,
  'Draft ratings by function': true,
  'Previous ratings by function': true,
  'Previous promotion recommendations by function': true,
};

function createRatingRow(
  title,
  label,
  type,
  tooltip,
  dataKey,
  options,
  collapsedState,
  links,
  baselineKey,
  showOther
) {
  return [
    {
      tooltip,
      title,
      type: CARD_TYPES.DISTRIBUTION_INFO,
      options,
      datasets: [
        {
          label,
          type,
        },
      ],
      dataKey,
      collapsed: collapsedState ? !!collapsedState[title] : false,
      links,
      baselineKey,
      showOther,
    },
  ];
}

function createRatingRowTrio(
  name,
  dataKey,
  options,
  collapsedState,
  toggleRowCallback,
  showOther = true
) {
  const title = `Final ratings by ${name}`;
  const draftTitle = `Draft ratings by ${name}`;
  const previousTitle = `Previous ratings by ${name}`;
  return [
    createRatingRow(
      title,
      'Final',
      'final_rating',
      'Final rating',
      dataKey,
      options,
      collapsedState,
      [
        ...[
          {
            text: `${
              collapsedState[draftTitle] ? 'Show' : 'Hide'
            } ${draftTitle.toLowerCase()}`,
            action: () => toggleRowCallback(draftTitle),
          },
        ],
        ...[
          {
            text: `${
              collapsedState[previousTitle] ? 'Show' : 'Hide'
            } ${previousTitle.toLowerCase()}`,
            action: () => toggleRowCallback(previousTitle),
          },
        ],
      ],
      'overall.final_rating',
      showOther
    ),
    createRatingRow(
      draftTitle,
      'Draft',
      'rating',
      'Draft rating',
      dataKey,
      options,
      collapsedState,
      [],
      'overall.final_rating',
      showOther
    ),
    createRatingRow(
      previousTitle,
      'Previous',
      'previous_rating',
      'Previous rating',
      dataKey,
      options,
      collapsedState,
      [],
      'overall.final_rating',
      showOther
    ),
  ];
}

function createPromoRow(
  title,
  isPrevious,
  tooltip,
  dataKey,
  options,
  collapsedState,
  links,
  baselineKey,
  showOther
) {
  return [
    {
      tooltip,
      title,
      type: CARD_TYPES.DISTRIBUTION_INFO,
      options,
      datasets: [
        {
          label: `${
            isPrevious ? 'Promotion' : 'Previous promotion'
          } recommendations`,
          type: `${isPrevious ? 'previous_' : ''}recommend_for_promotion`,
        },
      ],
      dataKey,
      collapsed: collapsedState ? !!collapsedState[title] : false,
      links,
      baselineKey,
      showOther,
    },
  ];
}

function createPromoRowPair(
  name,
  dataKey,
  options,
  collapsedState,
  toggleRowCallback,
  showOther
) {
  const title = `Promotion recommendations by ${name}`;
  const previousTitle = `Previous promotion recommendations by ${name}`;
  return [
    createPromoRow(
      title,
      false,
      name,
      dataKey,
      options,
      collapsedState,
      [
        ...[
          {
            text: `${
              collapsedState[previousTitle] ? 'Show' : 'Hide'
            } ${previousTitle.toLowerCase()}`,
            action: () => toggleRowCallback(previousTitle),
          },
        ],
      ],
      'overall.recommend_for_promotion',
      showOther
    ),
    createPromoRow(
      previousTitle,
      true,
      name,
      dataKey,
      options,
      collapsedState,
      [],
      'overall.recommend_for_promotion',
      showOther
    ),
  ];
}

function createOverallRow(title, datasets, options) {
  return [
    {
      title,
      type: CARD_TYPES.DISTRIBUTION_INFO,
      options,
      datasets: datasets.map((d) => {
        return { ...d, key: 'overall' };
      }),
      dataKey: 'overall',
    },
  ];
}

const RatingsDashboard: FC<Props> = ({
  promotionOptions = PROMO_OPTIONS,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [collapsedState, setCollapsedState] = useState(DEFAULT_COLLAPSED_STATE);
  const [backingData, setBackingData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { user } = useAuth0();
  const userSub = useMemo(() => user?.sub, [user]);

  const [isRatingDashboardAllowed, setIsRatingDashboardAllowed] = useState();

  useEffect(() => {
    // @ts-expect-error
    const campaignIdToUse = props.campaign.id;
    // @ts-expect-error
    if (props.currentOrganization?.id && campaignIdToUse) {
      // @ts-expect-error
      const url = `/can-get-rating-stats?organization_id=${props.currentOrganization.id}&campaign=${campaignIdToUse}`;
      ConfirmAPI.getUrlWithCache(
        url,
        'can-get-rating-stats',
        // @ts-expect-error
        user.sub,
        // @ts-expect-error
        props.currentProxyPerson,
        undefined,
        (data) => {
          setIsRatingDashboardAllowed(data?.canSeeRatingStats);
        },
        (message) => {
          console.error(
            'Could not get rating dashboard permission: ' + message
          );
          setIsRatingDashboardAllowed(undefined);
        }
      );
    }
  }, [
    // @ts-expect-error
    props.campaign?.id,
    isRatingDashboardAllowed,
    // @ts-expect-error
    props.currentOrganization?.id,
    props.currentProxyPerson,
    // @ts-expect-error
    user.sub,
  ]);

  const ratingOptions = useMemo(() => {
    // @ts-expect-error
    const ratingOptions = getCampaignRatings(props.campaign, true);
    if (!ratingOptions) {
      return [];
    }
    ratingOptions.push({
      key: '?',
      label: '? - To be decided',
      name: 'To be decided',
      id: '?',
    });
    ratingOptions.forEach((x) => {
      x.name = x.id;
    });
    return ratingOptions;
    // @ts-expect-error
  }, [props.campaign]);

  const toggleRowCallback = useCallback(
    (title) => {
      const newState = { ...collapsedState };
      newState[title] = !newState[title];
      setCollapsedState(newState);
    },
    [collapsedState, setCollapsedState]
  );

  const createTrio = useCallback(
    (displayName, internalName, additionalOptions?: any) => {
      return createRatingRowTrio(
        displayName,
        internalName,
        ratingOptions,
        collapsedState,
        toggleRowCallback,
        additionalOptions
      );
    },
    [collapsedState, ratingOptions, toggleRowCallback]
  );

  const createPair = useCallback(
    (displayName, internalName, additionalOptions?: any) => {
      return createPromoRowPair(
        displayName,
        internalName,
        promotionOptions,
        collapsedState,
        toggleRowCallback,
        additionalOptions
      );
    },
    [collapsedState, promotionOptions, toggleRowCallback]
  );

  const dashboardRows = useMemo(
    () => [
      createOverallRow(
        'Overall rating distribution',
        [
          {
            label: 'Previous',
            tooltip: 'Previous rating',
            type: 'previous_rating',
          },
          { label: 'Draft', tooltip: 'Draft rating', type: 'rating' },
          { label: 'Final', tooltip: 'Final rating', type: 'final_rating' },
        ],
        ratingOptions
      ),
      // NOTE: this should match campaigns.py TAGS list
      // AND should match the below other list
      ...createTrio('business unit', 'business_unit'),
      ...createTrio('business unit ID', 'business_unit_id'),
      ...createTrio('department', 'department'),
      ...createTrio('department ID', 'department_id'),
      ...createTrio('senior leader', 'leader', false),
      ...createTrio('function', 'function'),
      ...createTrio('function ID', 'function_id'),
      ...createTrio('cost center', 'cost_center'),
      ...createTrio('cost center ID', 'cost_center_id'),
      ...createTrio('level', 'level'),
      ...createTrio('level ID', 'level_id'),
      ...createTrio('location', 'location'),
      ...createTrio('country', 'country'),
      ...createTrio('HRBP', 'hrbp'),
      createOverallRow(
        'Promotion recommendation distribution',
        [
          {
            label: 'Previous',
            tooltip: 'Previous recommendation',
            type: 'previous_recommend_for_promotion',
          },
          {
            label: 'Current',
            tooltip: 'Current recommendation',
            type: 'recommend_for_promotion',
          },
        ],
        promotionOptions
      ),
      // NOTE: this should match the above list
      ...createPair('business unit', 'business_unit'),
      ...createPair('business unit ID', 'business_unit_id'),
      ...createPair('department', 'department'),
      ...createPair('department ID', 'department_id'),
      ...createPair('senior leader', 'leader', false),
      ...createPair('function', 'function'),
      ...createPair('function ID', 'function_id'),
      ...createPair('cost center', 'cost_center'),
      ...createPair('cost center ID', 'cost_center_id'),
      ...createPair('level', 'level'),
      ...createPair('level ID', 'level_id'),
      ...createPair('location', 'location'),
      ...createPair('country', 'country'),
      ...createPair('HRBP', 'hrbp'),
    ],
    [createPair, createTrio, promotionOptions, ratingOptions]
  );

  useEffect(() => {
    if (
      userSub !== undefined &&
      // @ts-expect-error
      props.currentOrganization?.id !== undefined &&
      // @ts-expect-error
      props.campaign?.id !== undefined
    ) {
      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        userSub,
        props.currentProxyPerson,
        // @ts-expect-error
        props.currentOrganization.id,
        'get-rating-stats',
        // @ts-expect-error
        { campaign_id: props.campaign.id },
        (hits) => {
          if (hits.length > 0) {
            setBackingData(hits[0].rating_stats || {});
          }
        },
        (msg) => {
          setErrorMessage(msg);
        }
      );
    }
  }, [
    userSub,
    props.currentOrganization,
    // @ts-expect-error
    props.campaign,
    setBackingData,
    setErrorMessage,
    props.currentProxyPerson,
  ]);

  if (errorMessage) {
    console.error('Could not load rating stats: ' + errorMessage);
    return <PageError message={errorMessage} />;
  }

  if (!isRatingDashboardAllowed) {
    const errorMessage = formatMessage({
      id: 'app.views.widgets.dashboards.ratings_dashboard.title.choose_another_campaign_details',
      defaultMessage:
        'You do not have permission to see the rating dashboard for this cycle. Please choose another.',
    });
    return (
      <Card>
        <CardBody>
          <EmptyState
            title={formatMessage({
              id: 'app.views.widgets.dashboards.ratings_dashboard.title.choose_another_campaign',
              defaultMessage: 'Choose another cycle',
            })}
            subtitle={errorMessage}
          />
        </CardBody>
      </Card>
    );
  }

  if (typeof backingData === 'undefined') {
    return <Loading />;
  }

  return (
    <>
      <CardRowDashboard
        rows={dashboardRows}
        data={backingData}
        emptyStateText={formatMessage({
          id: 'app.views.widgets.dashboards.ratings_dashboard.emptyStateText.no_available_data',
          defaultMessage: 'No available data.',
        })}
      />
    </>
  );
};
const RatingsDashboard_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  promotionOptions: PropTypes.arrayOf(PropTypes.object),
  ratingOptions: PropTypes.arrayOf(PropTypes.object),
};

type Props = PropTypes.InferProps<typeof RatingsDashboard_propTypes> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(RatingsDashboard))
);
