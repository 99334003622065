import { Col, Row } from 'reactstrap';
import React, { FC, PropsWithChildren } from 'react';

import Avatar from '../../Widgets/People/Avatar';
import { BasicPerson } from '../../../types';

export type CommentGroupProps = PropsWithChildren<{
  author: BasicPerson;
  noMargin?: boolean;
}>;

export const CommentGroup: FC<CommentGroupProps> = (props) => {
  const className = 'flex-nowrap' + (props.noMargin ? '' : ' mb-4');
  return (
    <Row className={className}>
      <Col className="col-auto">
        <Avatar size="sm" person={props.author} />
      </Col>
      <Col className="ms-n3">
        <div>
          <div
            className="comment-body d-block px-3"
            style={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            }}
          >
            <Row>
              <Col>
                <div className="mb-0 py-1">
                  <div className="fr-view">{props.children}</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};
