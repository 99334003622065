import {
  ASSESS_ORGANIZATION_EMPLOYEE_NPS_COMMENT,
  ASSESS_ORGANIZATION_EMPLOYEE_NPS_QUESTION,
  DEFAULT_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION,
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED,
  PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_OPTIONAL,
  PERFORMANCE_FEATURE_HIDE_PULSE_ANONYMOUS_DESCRIPTION,
  WIZARD_TYPE_ENPS_ONLY,
  addAnonymousResponseValues,
  addCustomResponseValues,
  extractAnonymousResponses,
  extractCustomResponses,
  getAutoFocusIndex,
  getCampaignFeature,
  getPerformanceFeatureEnabled,
  getStepNumber,
  getWizardType,
  perfCampaignCallback,
  prepareOpenResponseQuestion,
} from '../../utils/models/Performance';
import {
  CAMPAIGN_STATUSES,
  toPersonWithSurvey,
} from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';
import { type RouteComponentProps } from 'react-router-dom';

import PerformancePage from './PerformancePage';
import PropTypes from 'prop-types';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { applyCustomFiltersToQuestions } from '../Widgets/People/Filters/common';
import { connect } from 'react-redux';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { ReduxState } from 'types';

const PerformanceStepAssessOrganization: FC<Props> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const campaign = props.campaign;

  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const unfilteredAssessOrganizationQuestions = useMemo(() => {
    const customAssessOrganizationQuestions = getCampaignFeature(
      campaign,
      PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS
    );

    // Check if original "Quick pulse engagement survey" enabled in cycle
    const assessOrganizationEnabled = getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
    );

    // @ts-expect-error
    const wizardType = getWizardType(campaign);

    const engagementQuestions =
      assessOrganizationEnabled && wizardType !== WIZARD_TYPE_ENPS_ONLY
        ? customAssessOrganizationQuestions ??
          DEFAULT_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS
        : [];

    const employeeNPSEnabled = getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED
    );

    const employeeNPSQuestions = employeeNPSEnabled
      ? [
          {
            ...ASSESS_ORGANIZATION_EMPLOYEE_NPS_QUESTION,
            required: !getPerformanceFeatureEnabled(
              campaign,
              PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_OPTIONAL
            ),
          },
          ASSESS_ORGANIZATION_EMPLOYEE_NPS_COMMENT(formatMessage),
        ]
      : [];

    const questions = [...employeeNPSQuestions, ...engagementQuestions];

    const autoFocusIndex = getAutoFocusIndex(questions);
    return questions.map((q, index) =>
      prepareOpenResponseQuestion(
        formatMessage,
        q,
        [],
        false,
        // @ts-expect-error
        props.me.given_name,
        false,
        false,
        campaign,
        props.currentOrganization,
        autoFocusIndex === index,
        index,
        isDemoOrPreviewMode
      )
    );
  }, [
    campaign,
    isDemoOrPreviewMode,
    // @ts-expect-error
    props.me.given_name,
    props.currentOrganization,
    formatMessage,
  ]);

  const assessOrganizationQuestions = useMemo(
    () =>
      applyCustomFiltersToQuestions({
        questions: unfilteredAssessOrganizationQuestions,
        campaign,
        targetPerson: toPersonWithSurvey({}, props.currentPerfSurveyResponse),
      }),
    [
      unfilteredAssessOrganizationQuestions,
      campaign,
      props.currentPerfSurveyResponse,
    ]
  );

  if (
    unfilteredAssessOrganizationQuestions &&
    unfilteredAssessOrganizationQuestions.length > 0 &&
    (!assessOrganizationQuestions || assessOrganizationQuestions.length === 0)
  ) {
    console.error('No assessOrganizationQuestions found');
  }

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(data);
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          history,
          props.demoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      history,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      propsSetCurrentPerfSurveyResponse,
      formatMessage,
    ]
  );

  const object = useMemo(() => {
    // @ts-expect-error
    const responses = props.currentPerfSurveyResponse?.responses;
    const obj = {};

    if (typeof responses === 'object') {
      addCustomResponseValues(responses, obj);
      addAnonymousResponseValues(responses, obj);
    }

    return obj;
  }, [props.currentPerfSurveyResponse]);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const responses = {
        ...extractCustomResponses(object),
        ...extractAnonymousResponses(object),
      };

      return {
        // @ts-expect-error
        id: props.currentPerfSurveyResponse?.id,
        // @ts-expect-error
        campaign: campaign.id,
        step: currentStepNumber,
        responses: responses,
      };
    },
    // @ts-expect-error
    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse?.id]
  );

  return (
    <PerformancePage
      campaign={campaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_assess_organization.title.feedback_for_us',
        defaultMessage: 'Feedback for us',
      })}
    >
      <></>
      <div className="mb-5">
        <FormattedMessage
          id="app.views.performance.assess_organization.introduction"
          defaultMessage="Can you tell us about your {organization} experience? Please answer the questions below."
          values={{
            // @ts-expect-error
            organization: props.currentOrganization.name,
          }}
        />{' '}
        {!getCampaignFeature(
          campaign,
          PERFORMANCE_FEATURE_HIDE_PULSE_ANONYMOUS_DESCRIPTION
        ) && (
          <FormattedMessage
            id="app.views.performance.assess_organization.your_responses_are_confidential"
            defaultMessage="Your responses are confidential."
          />
        )}
      </div>
      <ValidatedForm
        draftAutosaveEnabled={!isDemoOrPreviewMode}
        // @ts-expect-error
        uniqueFormKey={`campaign-${campaign.id}-assess-organization`}
        method={'PATCH'}
        url={
          // @ts-expect-error
          campaign.status === CAMPAIGN_STATUSES.DEMO
            ? undefined
            : 'survey-responses'
        }
        callback={callback}
        buttonIsBlock={false}
        buttonClassName="mt-0"
        submitText={formatMessage({
          id: 'app.views.performance.performance_step_assess_organization.submit_text.save_and_continue',
          defaultMessage: 'Save and continue',
        })}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        object={object}
        inputs={assessOrganizationQuestions}
        organization={props.currentOrganization}
      />
    </PerformancePage>
  );
};

const PerformanceStepAssessOrganization_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = PropTypes.InferProps<
  typeof PerformanceStepAssessOrganization_propTypes
> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformanceStepAssessOrganization)));
