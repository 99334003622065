import { Person } from 'types';

export interface TeamDashboardPersonProgressFields {
  team_perf_completed: boolean;
  team_perf_completed_self_phase: number;
  team_perf_completed_others_phase: number;
  team_perf_completed_others_phase_alt: number;
  team_perf_completed_evaluation_phase: number;
  team_perf_completed_calibration_phase: number;
  team_perf_completed_reporting_phase: number;
  team_perf_completed_self_phase_percent: number;
  team_perf_completed_others_phase_percent: number;
  team_perf_completed_others_phase_percent_alt: number;
  // will be undefined if no direct reports
  team_perf_completed_evaluation_phase_percent?: number;
  team_perf_completed_calibration_phase_percent: number;
  team_perf_completed_reporting_phase_percent: number;
  team_perf_total_eligible: number;
  team_perf_total_eligible_evaluation_phase: number;
}

function generateTeamProgressFields(
  teamMembers: Array<Person> = []
): TeamDashboardPersonProgressFields | null {
  const membersEligible = teamMembers.filter(
    // @ts-expect-error
    (p) => p.is_active_and_participating_in_any_phase
  );
  const total = membersEligible?.length;
  const membersWithDirectReports = teamMembers.filter(
    // @ts-expect-error
    (p) => typeof p.perf_progress_evaluation_phase !== 'undefined'
  );
  const totalMembersWithDirectReports = membersWithDirectReports?.length;

  if (total === 0) {
    return null;
  }

  const completedSelfPhaseCount = membersEligible.filter(
    // @ts-expect-error
    (p) => p.perf_progress_self_phase >= 100
  )?.length;
  const completedOthersPhaseCount = membersEligible.filter(
    (p) =>
      // @ts-expect-error
      p.perf_progress_others_phase !== undefined &&
      // @ts-expect-error
      p.perf_progress_others_phase >= 100
  )?.length;
  const completedOthersPhaseCountAlt = membersEligible.filter(
    (p) =>
      // @ts-expect-error
      p.perf_progress_others_phase_alt !== undefined &&
      // @ts-expect-error
      p.perf_progress_others_phase_alt >= 100
  )?.length;
  const completedEvaluationPhaseCount = membersWithDirectReports.filter(
    // @ts-expect-error
    (p) => p.perf_progress_evaluation_phase >= 100
  )?.length;
  const completedCalibrationPhaseCount = membersEligible.filter(
    // @ts-expect-error
    (p) => p.perf_progress_calibration_phase >= 100
  )?.length;
  const completedReportingPhaseCount = membersEligible.filter(
    // @ts-expect-error
    (p) => p.perf_progress_reporting_phase >= 100
  )?.length;

  return {
    // team_perf_progress is for current phase
    team_perf_completed: total > 0,
    team_perf_completed_self_phase: completedSelfPhaseCount,
    team_perf_completed_others_phase: completedOthersPhaseCount,
    team_perf_completed_others_phase_alt: completedOthersPhaseCountAlt,
    team_perf_completed_evaluation_phase: completedEvaluationPhaseCount,
    team_perf_completed_calibration_phase: completedCalibrationPhaseCount,
    team_perf_completed_reporting_phase: completedReportingPhaseCount,
    team_perf_completed_self_phase_percent: Math.round(
      (completedSelfPhaseCount * 100) / total
    ),
    team_perf_completed_others_phase_percent: Math.round(
      (completedOthersPhaseCount * 100) / total
    ),
    team_perf_completed_others_phase_percent_alt: Math.round(
      (completedOthersPhaseCountAlt * 100) / total
    ),
    team_perf_completed_evaluation_phase_percent:
      totalMembersWithDirectReports === 0
        ? undefined
        : Math.round(
            (completedEvaluationPhaseCount * 100) /
              totalMembersWithDirectReports
          ),
    team_perf_completed_calibration_phase_percent: Math.round(
      (completedCalibrationPhaseCount * 100) / total
    ),
    team_perf_completed_reporting_phase_percent: Math.round(
      (completedReportingPhaseCount * 100) / total
    ),
    team_perf_total_eligible: total,
    team_perf_total_eligible_evaluation_phase: totalMembersWithDirectReports,
  };
}

export function calculateProgressOnlyDataHashMap(
  people: Array<Person>,
  peopleManagerIdMap: Record<number, number>
): Record<string, TeamDashboardPersonProgressFields> {
  // generate hashmap of each person id and their team member people objects
  // (i.e. direct reports and below in the chain of command)
  const teamMembersMap: Record<number, Array<Person>> = people.reduce(
    (acc, p) => {
      let currentPersonId: number = p.id;

      const seenPersonIds = new Set([currentPersonId]);
      while (currentPersonId in peopleManagerIdMap) {
        currentPersonId = peopleManagerIdMap[currentPersonId];
        if (currentPersonId in acc) {
          acc[currentPersonId].push(p);
        } else {
          acc[currentPersonId] = [p];
        }

        // avoid loops for reporting cycles (e.g. founders reporting to each other)
        if (seenPersonIds.has(currentPersonId)) {
          break;
        }
        seenPersonIds.add(currentPersonId);
      }

      return acc;
    },
    {}
  );

  const hashmap = {};
  for (const person of people) {
    hashmap[person.id] = generateTeamProgressFields(teamMembersMap[person.id]);
  }

  return hashmap;
}
