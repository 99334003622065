import { Button, Card, CardBody } from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getManagerPerson,
  getPhaseByType,
} from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_MANAGER_CONTRIBUTION_FEEDBACK,
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PHASE_TYPE_OTHERS,
  getCampaignFeature,
  getCampaignHasFeatureEnabled,
  getPerformanceFeatureEnabled,
  getPhaseOpenResponseQuestions,
  getStepNumber,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../utils/models/Performance';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';
import { type RouteComponentProps } from 'react-router-dom';

import PerformancePage from './PerformancePage';
import PerformancePersonAssessment from './PerformancePersonAssessment';
import PropTypes from 'prop-types';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import { ReduxState } from 'types';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { applyCustomFiltersToQuestions } from '../Widgets/People/Filters/common';
import { connect } from 'react-redux';
import { setCurrentPerfSurveyResponse } from '../../actions';
import BackButtonLink from 'views/Widgets/Buttons/BackButtonLink';

const PerformanceStepAssessManager: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;
  const propsDemoPeople = props.demoPeople;

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(
          updateLatestPerfStep(
            props.currentPerfSurveyResponse,
            currentStepNumber
          )
        );
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          history,
          propsDemoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      campaign,
      history,
      propsDemoPeople,
      formatMessage,
    ]
  );

  const [isOnFirstPage, setIsOnFirstPage] = useState(true);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);

  const me = props.me;
  const person = getManagerPerson(me, campaign, props.demoPeople, false);

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? // @ts-expect-error
          props.currentPerfSurveyResponse.id
        : undefined,
      // @ts-expect-error
      campaign: campaign.id,
      step: currentStepNumber,
      responses: {},
    }),
    // @ts-expect-error
    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse]
  );

  const allowContributionFeedback = getPerformanceFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_MANAGER_CONTRIBUTION_FEEDBACK
  );

  const isEligibleToGiveUpwardFeedback =
    // @ts-expect-error
    props.currentPerfSurveyResponse?.configs
      ?.is_writing_upward_manager_review !== false;

  const unfilteredFreeResponseQuestions = useMemo(() => {
    const hasCustomQuestions = getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
    );

    if (hasCustomQuestions) {
      return getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
      );
    }
    // @ts-expect-error
    return getPhaseOpenResponseQuestions(
      getPhaseByType(campaign, PHASE_TYPE_OTHERS)
    );
  }, [campaign]);

  const freeResponseQuestions = useMemo(
    () =>
      applyCustomFiltersToQuestions({
        questions: unfilteredFreeResponseQuestions,
        campaign,
        targetPerson: person,
      }),
    [unfilteredFreeResponseQuestions, campaign, person]
  );

  if (
    unfilteredFreeResponseQuestions &&
    unfilteredFreeResponseQuestions.length > 0 &&
    (!freeResponseQuestions || freeResponseQuestions.length === 0)
  ) {
    console.error('no freeResponseQuestions found');
  }

  const backButtonLinkCallback = useCallback(() => setIsOnFirstPage(true), []);

  return (
    <PerformancePage
      campaign={campaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_assess_manager.title.provide_feedback_to_your_manager',
        defaultMessage: 'Provide feedback to your manager.',
      })}
    >
      <>
        {allowContributionFeedback && person && !isOnFirstPage && (
          <>
            <BackButtonLink onClick={backButtonLinkCallback} />
            <span className="text-muted mx-3">{'∙'}</span>
          </>
        )}
        {allowContributionFeedback && person && (
          <span className="text-muted me-4">
            <FormattedMessage
              id="app.views.performance.performance_step_assess_manager.step_1_of_2"
              defaultMessage="Step 1 of 2"
            />
          </span>
        )}
        {allowContributionFeedback && person && isOnFirstPage && (
          <Button
            color="primary"
            disabled={isContinueDisabled}
            onClick={() => setIsOnFirstPage(false)}
            className="mt-0"
          >
            <FormattedMessage
              id="app.views.performance.performance_step_assess_manager.button.save_and_continue"
              defaultMessage="
            Save and continue
          "
            />
          </Button>
        )}
      </>
      {(!person || !isEligibleToGiveUpwardFeedback) && (
        <>
          <Card>
            {!person && (
              <CardBody>
                <FormattedMessage
                  id="app.views.performance.performance_step_assess_manager.other.not_eligible_for_feedback.decription"
                  defaultMessage="
                You do not need to provide feedback to your manager this cycle.
                This is because either you or your manager are not eligible for
                this cycle. If you have any questions, please contact your HR
                Business Partner.
              "
                />
              </CardBody>
            )}
            {person && !isEligibleToGiveUpwardFeedback && (
              <CardBody>
                <FormattedMessage
                  id="app.views.performance.performance_step_assess_manager.person.not_eligible_for_feedback"
                  defaultMessage="
                You do not need to provide feedback to your manager this cycle.
                This is because either you or your manager are not eligible for
                this cycle. If you have any questions, please contact your HR
                Business Partner.
              "
                />
              </CardBody>
            )}
          </Card>
          <ValidatedForm
            buttonClassName="mt-3"
            method={object.id ? 'PATCH' : 'POST'}
            url={
              // @ts-expect-error
              campaign.status === CAMPAIGN_STATUSES.DEMO
                ? undefined
                : 'survey-responses'
            }
            buttonIsBlock={false}
            object={object}
            inputs={[]}
            callback={callback}
            submitText={formatMessage({
              id: 'app.views.performance.performance_step_assess_manager.submit_text.continue',
              defaultMessage: 'Continue',
            })}
          />
        </>
      )}
      {person && isEligibleToGiveUpwardFeedback && (
        <PerformancePersonAssessment
          // @ts-expect-error
          campaign={campaign}
          setCampaign={setCampaign}
          currentStepNumber={currentStepNumber}
          relationshipType={RELATIONSHIP_TYPES.REPORTS_TO}
          callback={callback}
          person={person}
          allowContributionFeedback={allowContributionFeedback}
          isUpwardFeedbackToManager={true}
          isOnFirstPage={isOnFirstPage}
          setIsOnFirstPage={setIsOnFirstPage}
          setIsContinueDisabled={setIsContinueDisabled}
          openResponseQuestions={freeResponseQuestions}
        />
      )}
    </PerformancePage>
  );
};

const PerformanceStepAssessManager_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = PropTypes.InferProps<
  typeof PerformanceStepAssessManager_propTypes
> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformanceStepAssessManager)));
