import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import {
  FEEDBACK_TYPE_ACTIONABLE,
  FEEDBACK_TYPE_NOTE,
  FEEDBACK_TYPE_RECOGNITION,
  FEEDBACK_WITHOUT_REQUESTS_TYPES,
} from '../../utils/models/Feedback';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import FeedbackCard from '../Feedback/FeedbackCard';
import Loading from '../Widgets/Loading';
import PropTypes from 'prop-types';
import ReactTagsInput from '../Widgets/Inputs/ReactTagsInput';
import { ReduxState } from 'types';
import { clone } from 'lodash';
import { connect } from 'react-redux';
import { filterUniqueByESIndexandIdAndPutMostFrequentFirst } from '../../utils/util/util';
import { getItemFromValue } from '../Widgets/Inputs/SelectInput';
import { loadOrRender } from '../../utils/util/formatter';
import { useIntl } from 'react-intl';

export const PEOPLE_GROUPINGS = {
  DIRECT_REPORTS: 'direct_reports',
  FULL_TEAM: 'full_team',
  EVERYONE: 'everyone',
};

// filter tag elements
const tagsAreEqual = (a, b) => {
  if (a?._index !== b?._index) {
    return false;
  }

  return a.id === b.id;
};

// returns true if arr1 has any elements in common with arr2 based on the
// equality function provided
const hasOverlap = (arr1, arr2, equalityFunction) => {
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.findIndex((b) => equalityFunction(arr1[i], b)) !== -1) {
      return true;
    }
  }

  return false;
};

const PersonFeedbackList: FC<Props> = ({
  showAvatars = true,
  isDemoOrPreviewMode = false,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const [includeFilters, setIncludeFilters] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);

  const toggleFilterType = useCallback(
    (tier) => {
      // @ts-expect-error
      if (!filterTypes.find((it) => it.id === tier.id)) {
        // @ts-expect-error
        setFilterTypes([...filterTypes, tier]);
      } else {
        // @ts-expect-error
        setFilterTypes(filterTypes.filter((it) => it.id !== tier.id));
      }
    },
    [filterTypes]
  );

  const feedbackList = useMemo(() => {
    // @ts-expect-error
    if (!(props.feedbackList?.length > 0)) {
      return [];
    }

    let finalFeedbackList;

    if (props.showGlobalFeedback) {
      finalFeedbackList = clone(props.feedbackList);
    } else if (props.showSentFeedback) {
      // @ts-expect-error
      finalFeedbackList = props.feedbackList.filter((f) =>
        // @ts-expect-error
        peopleObjectsAreEqual(f.author_person, props.person)
      );
    } else {
      // if person is any one of the subjects (even if they are the author), show them
      // @ts-expect-error
      finalFeedbackList = props.feedbackList.filter((f) =>
        // @ts-expect-error
        f.subject_people?.find((p) => peopleObjectsAreEqual(p, props.person))
      );
    }

    // filter by whichever types are selected
    if (filterTypes?.length > 0) {
      // @ts-expect-error
      const filterTypeIds = filterTypes.map((t) => t.id);
      finalFeedbackList = finalFeedbackList.filter(
        (f) => filterTypeIds.indexOf(f.type) !== -1
      );
    }

    // filter by any include filters if they are selected (UNION, not intersection)
    if (includeFilters?.length > 0) {
      finalFeedbackList = finalFeedbackList.filter(
        (f) =>
          hasOverlap(
            [f.author_person, ...f.subject_people],
            includeFilters
              // @ts-expect-error
              .filter((inc) => inc._index === 'people')
              // @ts-expect-error
              .map((inc) => inc.object),
            peopleObjectsAreEqual
          ) ||
          hasOverlap(
            f.activities,
            includeFilters
              // @ts-expect-error
              .filter((inc) => inc._index === 'activities')
              // @ts-expect-error
              .map((inc) => inc.object),
            tagsAreEqual
          ) ||
          hasOverlap(
            f.skills,
            includeFilters
              // @ts-expect-error
              .filter((inc) => inc._index === 'skills')
              // @ts-expect-error
              .map((inc) => inc.object),
            tagsAreEqual
          )
      );
    }

    // filter by visibility
    finalFeedbackList = finalFeedbackList.filter((f) => {
      if (f.visibility === 'O') {
        // if the visibility is manager only, display only if manager view
        return props.showManagerOnlyPerformanceDetails;
      } else if (f.visibility === 'A') {
        // if the visibility author only check that the current person is the author
        // @ts-expect-error
        return f.author_person.id === props.me?.id;
      }
      return true;
    });

    if (props.hideActionableFeedback) {
      return finalFeedbackList.filter(
        (f) => f.type !== FEEDBACK_TYPE_ACTIONABLE(formatMessage).id
      );
    } else {
      return finalFeedbackList;
    }
  }, [
    filterTypes,
    includeFilters,
    props.feedbackList,
    props.hideActionableFeedback,
    props.person,
    props.showGlobalFeedback,
    props.showSentFeedback,
    // @ts-expect-error
    props.me?.id,
    props.showManagerOnlyPerformanceDetails,
    formatMessage,
  ]);

  const tagSuggestions = useMemo(() => {
    if (!(feedbackList?.length > 0)) {
      return [];
    }

    const suggestionLists = feedbackList.reduce(
      (acc, f) => {
        for (const key in acc) {
          if (key === 'people') {
            // pass in entire person object
            acc[key] = acc[key].concat([f.author_person, ...f.subject_people]);
          } else if (key === 'activities') {
            acc[key] = acc[key].concat(f.activities);
          } else if (key === 'skills') {
            acc[key] = acc[key].concat(f.skills);
          }
        }

        return acc;
      },
      {
        // NOTE: order is what takes precedence
        // in autocomplete dropdown when options are limited
        people: [],
        activities: [],
        skills: [],
      }
    );

    let newSuggestions = [];
    for (const index in suggestionLists) {
      newSuggestions = newSuggestions.concat(
        suggestionLists[index].map((s) => getItemFromValue(s, index))
      );
    }

    return filterUniqueByESIndexandIdAndPutMostFrequentFirst(newSuggestions);
  }, [feedbackList]);

  const loadOrRenderOutput = loadOrRender(
    props.feedbackList,
    props.errorMessage
  );

  const isMe = useMemo(
    // @ts-expect-error
    () => peopleIdsAreEqual(props.me?.id, props.person?.id),
    [props.me, props.person]
  );

  const hasContent = useMemo(
    () => feedbackList?.length > 0,
    [feedbackList?.length]
  );

  const renderCustomEmptyMessage = props?.renderCustomEmptyMessage;

  const emptyMessage = useMemo(() => {
    if (hasContent) {
      return null;
    }

    if (renderCustomEmptyMessage) {
      return renderCustomEmptyMessage();
    }

    if (filterTypes?.length > 0 || includeFilters?.length > 0) {
      return formatMessage({
        id: 'app.views.person.person_feedback_list.no_results',
        defaultMessage: 'No results',
      });
    }

    if (props.showGlobalFeedback === PEOPLE_GROUPINGS.DIRECT_REPORTS) {
      return formatMessage({
        id: 'app.views.person.person_feedback_list.none_directs',
        defaultMessage:
          'No feedback, recognition, or notes regarding your direct reports is visible.',
      });
    }

    if (props.showGlobalFeedback === PEOPLE_GROUPINGS.FULL_TEAM) {
      return formatMessage({
        id: 'app.views.person.person_feedback_list.none_full_team',
        defaultMessage:
          'No feedback, recognition, or notes regarding your extended team is visible.',
      });
    }

    if (props.showGlobalFeedback === PEOPLE_GROUPINGS.EVERYONE) {
      return formatMessage({
        id: 'app.views.person.person_feedback_list.none_everyone',
        defaultMessage:
          'No feedback, recognition, or notes in your organization are visible.',
      });
    }

    if (props.showSentFeedback) {
      if (isMe) {
        return formatMessage({
          id: 'app.views.person.person_feedback_list.none_sent_by_me',
          defaultMessage: 'You have not sent any feedback or recognition.',
        });
      } else {
        return formatMessage(
          {
            id: 'app.views.person.person_feedback_list.none_sent_by_person',
            defaultMessage:
              'No feedback or recognition visible to you has been sent by {person}.',
          },
          // @ts-expect-error
          { person: props.person?.given_name }
        );
      }
    } else {
      if (isMe) {
        return formatMessage({
          id: 'app.views.person.person_feedback_list.no_feedback_recognition_to_me',
          defaultMessage: 'You have not received any feedback or recognition.',
        });
      } else {
        return formatMessage(
          {
            id: 'app.views.person.person_feedback_list.no_feedback_recognition_to_person',
            defaultMessage:
              'No feedback, recognition, or notes visible to you about {person} have been recorded.',
          },
          // @ts-expect-error
          { person: props.person?.given_name }
        );
      }
    }
  }, [
    hasContent,
    filterTypes?.length,
    includeFilters?.length,
    isMe,
    formatMessage,
    props.showGlobalFeedback,
    props.showSentFeedback,
    // @ts-expect-error
    props.person?.given_name,
    renderCustomEmptyMessage,
  ]);

  const bodyContent = useMemo(() => {
    if (emptyMessage) {
      return <span className="fst-italic">{emptyMessage}</span>;
    }

    return (
      <>
        {feedbackList.map((feedback, index) => {
          const cardOutput = (
            <FeedbackCard
              key={index}
              feedback={feedback}
              showAvatars={showAvatars}
              // @ts-expect-error
              bodyOnly={props.bodyOnly}
              isDemoOrPreviewMode={isDemoOrPreviewMode}
            />
          );

          if (props.bodyOnly) {
            // body-only content in this component needs to be embedded in a list group
            return (
              <ListGroupItem key={index} className="border-0 p-0 pb-3">
                {cardOutput}
              </ListGroupItem>
            );
          }

          return cardOutput;
        })}
      </>
    );
  }, [
    emptyMessage,
    feedbackList,
    isDemoOrPreviewMode,
    props.bodyOnly,
    showAvatars,
  ]);

  const mainBodyOutput = useMemo(() => {
    if (props.bodyOnly) {
      if (emptyMessage) {
        return bodyContent;
      } else {
        // body-only content in this component needs to be embedded in a list group
        return (
          <ListGroup className="list-group-flush mb-n3">
            {bodyContent}
          </ListGroup>
        );
      }
    }

    if (emptyMessage) {
      return (
        <Card>
          <CardBody>{bodyContent}</CardBody>
        </Card>
      );
    } else {
      return bodyContent;
    }
  }, [bodyContent, emptyMessage, props.bodyOnly]);

  const output = useMemo(
    () => (
      <>
        {!props.hideFilters && (
          <Row>
            <Col className="d-none d-md-inline">
              <ReactTagsInput
                // @ts-expect-error
                className={props.className}
                // @ts-expect-error
                inputClassName={props.inputClassName}
                tagsAreEqual={tagsAreEqual}
                value={includeFilters}
                allowNew={false}
                suggestions={tagSuggestions}
                placeholder={formatMessage({
                  id: 'app.views.person.person_feedback_list.placeholder.filter_by_name_or_anything_else',
                  defaultMessage: 'Filter by name or anything else',
                })}
                callback={setIncludeFilters}
                useTagCards={true}
              />
            </Col>
            <Col className="col-auto pe-0">
              <div className="text-muted mt-2 pt-1">
                <i className="fe fe-filter me-2" />
              </div>
            </Col>
            <Col className="col-auto">
              <ul className="pagination mb-4">
                {(props.hideActionableFeedback
                  ? [
                      FEEDBACK_TYPE_RECOGNITION(formatMessage),
                      FEEDBACK_TYPE_NOTE(formatMessage),
                    ]
                  : FEEDBACK_WITHOUT_REQUESTS_TYPES(formatMessage)
                ).map((type, index) => (
                  <li
                    key={index}
                    className={
                      'page-item' +
                      // @ts-expect-error
                      (filterTypes.some((it) => it.id === type.id)
                        ? ' active'
                        : '')
                    }
                    role="button"
                    onClick={() => toggleFilterType(type)}
                  >
                    <span className="page-link">
                      <i className={'me-2 ' + type.icon} />
                      {type.title}
                    </span>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        )}
        {mainBodyOutput}
      </>
    ),
    [
      filterTypes,
      includeFilters,
      mainBodyOutput,
      // @ts-expect-error
      props.className,
      props.hideActionableFeedback,
      props.hideFilters,
      // @ts-expect-error
      props.inputClassName,
      tagSuggestions,
      toggleFilterType,
      formatMessage,
    ]
  );

  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (typeof props.feedbackList === 'undefined') {
    const loadingMessage = formatMessage({
      id: 'app.views.person.person_feedback_list.loading_feedback',
      defaultMessage: 'Loading feedback...',
    });

    return <Loading message={loadingMessage} />;
  }

  return output;
};

const PersonFeedbackList_propTypes = {
  bodyOnly: PropTypes.bool,
  showAvatars: PropTypes.bool,
  hideFilters: PropTypes.bool,
  person: PropTypes.object.isRequired,
  feedbackList: PropTypes.arrayOf(PropTypes.object),
  errorMessage: PropTypes.string,
  me: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  currentOrganization: PropTypes.object.isRequired,
  campaign: PropTypes.object,
  surveyResponse: PropTypes.object,
  incomingRelationshipsWithFeedback: PropTypes.arrayOf(PropTypes.object),
  showManagerOnlyFeedbackDetails: PropTypes.bool,
  showManagerOnlyPerformanceDetails: PropTypes.bool,
  showSentFeedback: PropTypes.bool,
  showGlobalFeedback: PropTypes.string,
  isAdminable: PropTypes.bool,
  hideActionableFeedback: PropTypes.bool,
  isPerformanceReview: PropTypes.bool,
  renderCustomEmptyMessage: PropTypes.func,
  isDemoOrPreviewMode: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof PersonFeedbackList_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentProxyPerson, currentOrganization } = state;

  return {
    me,
    currentProxyPerson,
    currentOrganization,
  };
};

export default connect(mapStateToProps)(React.memo(PersonFeedbackList));
