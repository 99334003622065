import { Person } from 'types';

interface PersonWithIdAndManagerIds extends Person {
  id: number;
  manager: Person | null;
  parentIds: Array<number>;
}

// takes a list of people with managers inside and
// returns a key-value pair of person id to manager id
// as strings
export const getPersonIdToManagerIdMap = (
  peopleWithValidManagersOrNoManager: Array<PersonWithIdAndManagerIds>
): Record<string, string> => {
  return peopleWithValidManagersOrNoManager.reduce((acc, p) => {
    if (p.manager?.id) {
      acc[p.id.toString()] = p.manager.id.toString();
    }
    return acc;
  }, {} as Record<string, string>);
};
