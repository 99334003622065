import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import { CAMPAIGN_STATUSES } from '../../utils/models/Campaign';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import ContributionCard from '../Activities/ContributionCard';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import ModalActivityEditorButton from '../Activities/ModalActivityEditorButton';
import ModalContributionEditor from '../Activities/ModalContributionEditor';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import Page404 from '../Layout/Pages/Errors/Page404';
import PersonTimelineActivities from './PersonTimelineActivities';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { getContributionPerson } from '../../utils/models/Activity';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { yyyymmddToLocalDate } from '../../utils/util/util';
import { type RouteComponentProps } from 'react-router-dom';

const PersonProfileFeedback: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  // @ts-expect-error
  const campaign = props.campaign;

  const [showCreateActivityDialog, setShowCreateActivityDialog] =
    useState(false);
  const [addedActivities, setAddedActivities] = useState([]);
  const [editContributionModal, setEditContributionModal] = useState(false);
  const toggleEditContributionModal = () =>
    setEditContributionModal(!editContributionModal);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [confirmDeleteModalActivity, setConfirmDeleteModalActivity] =
    useState(null);

  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);
  const propsSetHasUnsavedChanges = props.setHasUnsavedChanges;

  // default needs to be undefined, since null is a valid value
  const [defaultActivityDateString, setDefaultActivityDateString] =
    useState(undefined);

  const setCurrentActivityWithContributionForPerson = useCallback(
    (activity) => {
      // set activity, but insert contribution (without id) for person
      // if one doesn't already exist
      const currentContributionIndex =
        activity?.contributions &&
        activity.contributions.findIndex((c) =>
          peopleObjectsAreEqual(getContributionPerson(c), props.person)
        );

      if (currentContributionIndex === -1) {
        const newContribution = {
          contributor_person: props.person,
          activity: activity,
        };

        const activityWithNewContribution = {
          ...activity,
          contributions:
            activity.contributions?.length > 0
              ? [...activity.contributions, newContribution]
              : [newContribution],
        };

        setCurrentActivity(activityWithNewContribution);
      } else {
        setCurrentActivity(activity);
      }
    },
    [props.person]
  );

  const toggleConfirmDeleteModalActivity = useCallback(
    (activity) => {
      if (confirmDeleteModalActivity === activity) {
        setConfirmDeleteModalActivity(null);
      } else {
        setConfirmDeleteModalActivity(activity);
      }
    },
    [confirmDeleteModalActivity]
  );

  const onContributionModalClosed = useCallback(() => {
    setEditContributionModal(false);
  }, []);

  const openCreateActivityDialog = useCallback(() => {
    setShowCreateActivityDialog(true);
  }, []);

  const onCreateActivityDialogClosed = useCallback(() => {
    setShowCreateActivityDialog(false);
  }, []);

  const addOrUpdateActivity = useCallback(
    (activity) => {
      setCurrentActivityWithContributionForPerson(activity);
      if (
        addedActivities &&
        addedActivities.findIndex(
          // @ts-expect-error
          (a) => activity.id.toString() === a.id.toString()
        ) === -1
      ) {
        // @ts-expect-error
        setAddedActivities([activity, ...addedActivities]);
      } else {
        setAddedActivities(
          // @ts-expect-error
          addedActivities.map((a) =>
            // @ts-expect-error
            a.id.toString() === activity.id.toString() ? activity : a
          )
        );
      }
    },
    [addedActivities, setCurrentActivityWithContributionForPerson]
  );

  const onActivityCreated = useCallback(
    (activity) => {
      // note: we do NOT show the contribution modal
      // as contribution details are added inline
      setDefaultActivityDateString(undefined);
      addOrUpdateActivity(activity);
    },
    [addOrUpdateActivity]
  );

  const onActivityUpdated = useCallback(
    (activity) => {
      addOrUpdateActivity(activity);
    },
    [addOrUpdateActivity]
  );

  const onSubmitContribution = useCallback(
    (contribution) => {
      const updatedActivity = {
        // @ts-expect-error
        ...currentActivity,
        // @ts-expect-error
        contributions: currentActivity.contributions.map((c) =>
          (!('id' in c) &&
            peopleObjectsAreEqual(c?.contributor_person, person)) ||
          c.id?.toString() === contribution.id?.toString()
            ? contribution
            : c
        ),
      };

      return onActivityUpdated(updatedActivity);
    },
    // @ts-expect-error
    [currentActivity, onActivityUpdated, person]
  );

  const addContributionToActivity = useCallback(
    (activity) => {
      setCurrentActivityWithContributionForPerson(activity);
      setEditContributionModal(true);
    },
    [setCurrentActivityWithContributionForPerson]
  );

  const onCreateNewActivity = useCallback(
    (activity) => {
      setCurrentActivityWithContributionForPerson(activity);
      setShowCreateActivityDialog(true);
    },
    [setCurrentActivityWithContributionForPerson]
  );

  const person = props.person;
  // @ts-expect-error
  const isMe = peopleIdsAreEqual(person?.id, props.meId);
  const ADD_FEEDBACK_TITLE = isMe
    ? formatMessage({
        id: 'app.views.person.person_profile_feedback.add_reflection',
        defaultMessage: 'Add reflection',
      })
    : formatMessage({
        id: 'app.views.person.person_profile_feedback.add_feedback',
        defaultMessage: 'Add feedback',
      });
  const EDIT_FEEDBACK_TITLE = isMe
    ? formatMessage({
        id: 'app.views.person.person_profile_feedback.edit_reflection',
        defaultMessage: 'Edit reflection',
      })
    : formatMessage({
        id: 'app.views.person.person_profile_feedback.edit_feedback',
        defaultMessage: 'Edit feedback',
      });
  const DELETE_FEEDBACK_TITLE = isMe
    ? formatMessage({
        id: 'app.views.person.person_profile_feedback.delete_reflection',
        defaultMessage: 'Delete reflection',
      })
    : formatMessage({
        id: 'app.views.person.person_profile_feedback.delete_feedback',
        defaultMessage: 'Delete feedback',
      });
  const DELETE_FEEDBACK_QUESTION = isMe
    ? formatMessage({
        id: 'app.views.person.person_profile_feedback.are_you_sure_delete_reflection',
        defaultMessage: 'Are you sure that you want to delete this reflection?',
      })
    : formatMessage({
        id: 'app.views.person.person_profile_feedback.are_you_sure_delete_feedback',
        defaultMessage: 'Are you sure that you want to delete this feedback?',
      });
  const FEEDBACK_SAVED = isMe
    ? formatMessage({
        id: 'app.views.person.person_profile_feedback.reflection_saved',
        defaultMessage: 'Reflection saved!',
      })
    : formatMessage({
        id: 'app.views.person.person_profile_feedback.feedback_saved',
        defaultMessage: 'Feedback saved!',
      });
  const FEEDBACK_DELETED = isMe
    ? formatMessage({
        id: 'app.views.person.person_profile_feedback.reflection_deleted',
        defaultMessage: 'Reflection deleted!',
      })
    : formatMessage({
        id: 'app.views.person.person_profile_feedback.feedback_deleted',
        defaultMessage: 'Feedback deleted!',
      });

  const onSubmitFeedback = useCallback(
    (activity, currentContribution, newOrUpdatedContributionFeedback) => {
      const updatedFeedback =
        campaign.status === CAMPAIGN_STATUSES.DEMO
          ? {
              ...newOrUpdatedContributionFeedback,
              author_person: props.me,
            }
          : newOrUpdatedContributionFeedback;

      const currentFeedbackList = currentContribution.contribution_feedback
        ? currentContribution.contribution_feedback
        : [];

      let newContribution = null;

      if (
        currentFeedbackList &&
        currentFeedbackList.findIndex(
          (cf) => cf.id?.toString() === updatedFeedback.id?.toString()
        ) === -1
      ) {
        newContribution = {
          ...currentContribution,
          contribution_feedback: [updatedFeedback, ...currentFeedbackList],
        };
      } else {
        newContribution = {
          ...currentContribution,
          contribution_feedback: currentFeedbackList.map((cf) =>
            cf.id?.toString() === updatedFeedback.id?.toString()
              ? updatedFeedback
              : cf
          ),
        };
      }
      const updatedActivity = {
        ...activity,
        contributions: activity.contributions.map((c) =>
          // @ts-expect-error
          c.id?.toString() === newContribution.id?.toString()
            ? newContribution
            : c
        ),
      };

      toast.success(FEEDBACK_SAVED);
      return onActivityUpdated(updatedActivity);
    },
    [FEEDBACK_SAVED, onActivityUpdated, campaign.status, props.me]
  );

  const onDeleteFeedback = useCallback(
    (activity, currentContribution, deletedContributionFeedback) => {
      const deletedFeedback =
        campaign.status === CAMPAIGN_STATUSES.DEMO
          ? {
              ...deletedContributionFeedback,
              author_person: props.me,
            }
          : deletedContributionFeedback;

      const currentFeedbackList = currentContribution.contribution_feedback
        ? currentContribution.contribution_feedback
        : [];

      const updatedContribution = {
        ...currentContribution,
        contribution_feedback: currentFeedbackList.filter(
          (cf) => cf.id?.toString() !== deletedFeedback.id?.toString()
        ),
      };

      const updatedActivity = {
        ...activity,
        contributions: activity.contributions.map((c) =>
          c.id?.toString() === updatedContribution.id?.toString()
            ? updatedContribution
            : c
        ),
      };

      toast.success(FEEDBACK_DELETED);
      return onActivityUpdated(updatedActivity);
    },
    [FEEDBACK_DELETED, onActivityUpdated, campaign.status, props.me]
  );

  const transformFeedbackObjectBeforeSubmit = useCallback(
    (object) => ({
      id: object.id,
      positive_comments: object.positive_comments,
      negative_comments: object.negative_comments,
      campaign: campaign.id,
      contribution: object.id ? undefined : object.contribution,
      type: props.relationshipType,
    }),
    [campaign.id, props.relationshipType]
  );

  const perfCoverageStartDate = useMemo(() => {
    if (campaign?.coverage_start_date) {
      return yyyymmddToLocalDate(campaign.coverage_start_date);
    }
    return null;
  }, [campaign]);

  const insertBeforeEachActivity = useCallback(
    (activity, isOpen, toggleFeedback, onClosedFeedback = null) => {
      if (!(activity?.contributions?.length > 0)) {
        return <></>;
      }

      const currentContributionIndex =
        activity?.contributions &&
        activity.contributions.findIndex((c) =>
          peopleObjectsAreEqual(getContributionPerson(c), person)
        );
      const currentContribution =
        activity.contributions[currentContributionIndex];

      const renderActivityInputs = (inputs) => {
        return (
          <>
            <div className="mb-3 fw-bold">{activity.name}</div>
            <Row className="mb-4">
              <Col>
                <ContributionCard
                  preventLocking={true}
                  preventInteraction={true}
                  isExternalUrl={true}
                  activity={activity}
                  contributionIndex={currentContributionIndex}
                />
              </Col>
            </Row>
            <Row>
              <Col>{inputs}</Col>
            </Row>
          </>
        );
      };

      const myContributionFeedback =
        currentContribution?.contribution_feedback &&
        currentContribution.contribution_feedback?.find((cf) =>
          peopleIdsAreEqual(cf.author_person?.id, props.meId)
        );

      const confirmDelete = () => {
        ConfirmAPI.sendRequestToConfirm(
          'DELETE',
          // @ts-expect-error
          campaign.status === CAMPAIGN_STATUSES.DEMO
            ? undefined
            : '/contribution-feedback/' + myContributionFeedback.id,
          {},
          (response, error, hardErrorMessage = null) => {
            if (error) {
              // failure; keep modal open
              if (hardErrorMessage) {
                // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
                // leave this message blank as those errors will get surfaced below
                setDeleteValidationErrors(hardErrorMessage);
              } else {
                setDeleteValidationErrors(error);
              }
            } else {
              onDeleteFeedback(
                activity,
                currentContribution,
                myContributionFeedback
              );

              setConfirmDeleteModalActivity(null);
            }
          },
          null
        );
      };

      return (
        <>
          <ModalEditor
            method={myContributionFeedback?.id ? 'PATCH' : 'POST'}
            url={
              campaign.status === CAMPAIGN_STATUSES.DEMO
                ? undefined
                : 'contribution-feedback'
            }
            title={
              myContributionFeedback?.id
                ? EDIT_FEEDBACK_TITLE
                : ADD_FEEDBACK_TITLE
            }
            renderInputs={renderActivityInputs}
            callback={(cf) =>
              onSubmitFeedback(activity, currentContribution, cf)
            }
            submitText={formatMessage({
              id: 'app.views.person.person_profile_feedback.modal.submit_text',
              defaultMessage: 'Save and continue',
            })}
            isOpen={isOpen}
            toggle={toggleFeedback}
            onClosed={onClosedFeedback}
            object={
              myContributionFeedback
                ? myContributionFeedback
                : {
                    contribution: currentContribution?.id,
                  }
            }
            transformObjectBeforeSubmit={transformFeedbackObjectBeforeSubmit}
            inputs={[
              {
                required: true,
                name: 'positive_comments',
                type: INPUT_TYPES.TEXTAREA,
                maxLength: 1000,
                minRows: 2,
                label: (
                  <span>
                    <span className="fe fe-chevrons-up me-2 text-success"></span>
                    {isMe
                      ? 'What did you do well and believe you should continue doing?'
                      : 'What do you believe ' +
                        // @ts-expect-error
                        person.given_name +
                        ' did well and should continue doing?'}
                  </span>
                ),
              },
              {
                required: true,
                name: 'negative_comments',
                type: INPUT_TYPES.TEXTAREA,
                maxLength: 1000,
                minRows: 2,
                label: (
                  <span>
                    <span className="fe fe-chevrons-down me-2 text-danger"></span>
                    {isMe
                      ? 'What would you change or do differently in the future?'
                      : 'What would you recommend ' +
                        // @ts-expect-error
                        props.person.given_name +
                        ' consider changing or doing differently in the future?'}
                  </span>
                ),
              },
            ]}
          />
          {props.showFeedbackButtons && myContributionFeedback?.id && (
            <>
              <ConfirmationDialogModal
                isOpen={confirmDeleteModalActivity === activity}
                onClosed={() => setDeleteValidationErrors(null)}
                toggle={() => toggleConfirmDeleteModalActivity(activity)}
                confirmCallback={confirmDelete}
                title={DELETE_FEEDBACK_TITLE + '?'}
                description={DELETE_FEEDBACK_QUESTION}
                confirmText={DELETE_FEEDBACK_TITLE}
                validationErrors={deleteValidationErrors}
              />
              <div className="resume-item-action">
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle
                    tag="div"
                    className="btn btn-sm btn-primary btn-edit-reflections"
                    role="button"
                  >
                    <FormattedMessage
                      id="app.views.person.person_profile_feedback.edit"
                      defaultMessage="Edit"
                    />
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem onClick={toggleFeedback}>
                      {EDIT_FEEDBACK_TITLE}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => toggleConfirmDeleteModalActivity(activity)}
                    >
                      {DELETE_FEEDBACK_TITLE}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </>
          )}
          {props.showFeedbackButtons && !myContributionFeedback?.id && (
            <div className="resume-item-action">
              <Button
                color="primary"
                className="btn-sm"
                onClick={toggleFeedback}
              >
                {ADD_FEEDBACK_TITLE}
              </Button>
            </div>
          )}
        </>
      );
    },
    [
      ADD_FEEDBACK_TITLE,
      DELETE_FEEDBACK_QUESTION,
      DELETE_FEEDBACK_TITLE,
      EDIT_FEEDBACK_TITLE,
      confirmDeleteModalActivity,
      deleteValidationErrors,
      isMe,
      onDeleteFeedback,
      onSubmitFeedback,
      person,
      campaign.status,
      props.meId,
      // @ts-expect-error
      props.person.given_name,
      props.showFeedbackButtons,
      toggleConfirmDeleteModalActivity,
      transformFeedbackObjectBeforeSubmit,
      formatMessage,
    ]
  );

  if (!campaign) {
    return <Page404 />;
  }

  return (
    // @ts-expect-error
    <div className={props.className}>
      {currentActivity && (
        <ModalContributionEditor
          isOpen={editContributionModal}
          onClosed={onContributionModalClosed}
          toggle={toggleEditContributionModal}
          activity={currentActivity}
          contributionIndex={
            // @ts-expect-error
            currentActivity?.contributions &&
            // @ts-expect-error
            currentActivity.contributions.findIndex((c) =>
              peopleObjectsAreEqual(getContributionPerson(c), person)
            )
          }
          onSubmitActivity={onActivityUpdated}
          callback={onSubmitContribution}
          hideConfettiModal={props.hideConfettiModalsWhenClaimingContributions}
          setHasUnsavedChanges={propsSetHasUnsavedChanges}
          hideFeedbackRequestPrompt={
            props.hideFeedbackRequestPromptsWhenClaimingContributions
          }
        />
      )}
      <ModalActivityEditorButton
        isOpen={showCreateActivityDialog}
        // @ts-expect-error
        activityType={currentActivity ? currentActivity.type : undefined}
        // @ts-expect-error
        activityName={currentActivity ? currentActivity.name : undefined}
        onClosed={onCreateActivityDialogClosed}
        hideButton={true}
        callback={onActivityCreated}
        showContributorFields={true}
        setHasUnsavedChanges={propsSetHasUnsavedChanges}
        defaultActivityDateString={defaultActivityDateString}
      />
      <PersonTimelineActivities
        campaign={campaign}
        surveyResponse={props.currentPerfSurveyResponse}
        aggregateCampaignActivities={true}
        activeHighlights={true}
        hideHeader={true}
        showNewActivityAutocomplete={true}
        // @ts-expect-error
        getActivities={props.getActivities}
        getFeedback={props.getFeedback}
        addedActivities={addedActivities}
        collapseActivitiesBeforeDate={perfCoverageStartDate}
        isEditablePerfResumeMode={true}
        showAddToProfileButton={true}
        person={person}
        openCreateActivityDialog={openCreateActivityDialog}
        onClickAcceptCredit={addContributionToActivity}
        insertBeforeEachActivity={
          props.showFeedbackButtons ? insertBeforeEachActivity : undefined
        }
        showFeedback={props.showFeedbackButtons}
        onAddNewContributionToExistingActivity={addContributionToActivity}
        onCreateNewActivity={onCreateNewActivity}
        setDefaultActivityDateString={setDefaultActivityDateString}
      />
    </div>
  );
};

const PersonProfileFeedback_propTypes = {
  relationshipType: PropTypes.string,
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  meId: PropTypes.number.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  getActivities: PropTypes.func,
  getFeedback: PropTypes.func,
  showFeedbackButtons: PropTypes.bool,
  hideConfettiModalsWhenClaimingContributions: PropTypes.bool,
  hideFeedbackRequestPromptsWhenClaimingContributions: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func,
  currentPerfSurveyResponse: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof PersonProfileFeedback_propTypes> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentPerfSurveyResponse } = state;

  return {
    me: me,
    meId: me?.id,
    currentOrganization,
    currentPerfSurveyResponse,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(PersonProfileFeedback))
);
