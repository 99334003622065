import React, { FC } from 'react';
import { Feature, Organization, Person } from 'types';
import { connect } from 'react-redux';
import FeatureToggle from './FeatureToggle';
import { Card, CardBody, CardHeader } from 'reactstrap';
import CardHeaderTitle from 'views/Widgets/Cards/CardHeaderTitle';

interface Props {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  featureList: Array<Feature>;
}

const FeatureList: FC<Props> = ({ featureList }) => {
  if (featureList.length === 0) {
    return <></>;
  }

  const categoryControllers: { [key: string]: Feature } = {};

  // bucket features by category, then output cards
  // with the category at the top, and the list of
  // features in the card body
  const publicCategories: { [key: string]: Array<Feature> } = {};
  const superAdminCategories: { [key: string]: Array<Feature> } = {};
  const allCategories: Set<string> = new Set();

  featureList.forEach((feature) => {
    allCategories.add(feature.category);

    if (feature.controls_category) {
      categoryControllers[feature.category] = feature;
    } else {
      const catDict = feature.superuser_only
        ? superAdminCategories
        : publicCategories;
      if (!catDict[feature.category]) {
        catDict[feature.category] = [];
      }
      catDict[feature.category].push(feature);
    }
  });

  const alphabetizedCategories = Array.from(allCategories).sort((a, b) =>
    a.localeCompare(b)
  );

  return (
    <>
      {alphabetizedCategories.map((category) => (
        <Card key={category}>
          <CardHeader>
            <CardHeaderTitle>
              {!!categoryControllers[category] && (
                <FeatureToggle
                  key={categoryControllers[category].name}
                  feature={categoryControllers[category]}
                />
              )}
              {!categoryControllers[category] && <>{category}</>}
            </CardHeaderTitle>
          </CardHeader>
          <CardBody className="mt-n2 mb-n2">
            {!!publicCategories[category] &&
              publicCategories[category].map((feature, index) => (
                <FeatureToggle
                  className={'py-2' + (index === 0 ? '' : ' mt-3')}
                  key={feature.name}
                  feature={feature}
                />
              ))}
            {!!superAdminCategories[category] &&
              superAdminCategories[category].map((feature, index) => (
                <FeatureToggle
                  className={
                    'py-2' +
                    (!publicCategories[category] && index === 0 ? '' : ' mt-3')
                  }
                  key={feature.name}
                  feature={feature}
                />
              ))}
          </CardBody>
        </Card>
      ))}
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(FeatureList));
