import {
  Button,
  Card,
  CardBody,
  Col,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useRef, useState } from 'react';

import Avatar from '../People/Avatar';
import AvatarGroup from '../../Widgets/People/AvatarGroup';
import ExternalPersonEnrichedProfileCard from './ExternalPersonEnrichedProfileCard';
import { FormattedMessage } from 'react-intl';
import Highlighted from '../Highlighted';
import PersonEnrichedProfileCard from './PersonEnrichedProfileCard';
import PropTypes from 'prop-types';
import { Star } from 'react-feather';
import { TEXT_COLORS } from '../../../consts/consts';

const ObjectCard = (props) => {
  const history = useHistory();

  const addToResume = useCallback(() => {
    history.push('/profile');
  }, [history]);

  const [beforeContentIsOpen, setBeforeContentIsOpen] = useState(false);
  const toggleBeforeContent = () =>
    setBeforeContentIsOpen(!beforeContentIsOpen);
  const beforeContentOnClose = () => {
    // TODO
  };

  const highlightHoverRef = useRef();

  let style = props.style;
  let header = props.query ? (
    <Highlighted
      className="text-dark"
      highlightClassName="text-muted"
      text={
        props.stringNameForQueries ? props.stringNameForQueries : props.name
      }
      highlight={props.query}
    />
  ) : (
    props.name
  );

  if (props.query && props.stringNameForQueries && props.formatNameForQuery) {
    header = props.formatNameForQuery(header);
  }

  if (props.url) {
    header = (
      <Link
        to={props.url}
        target={props.isExternalUrl ? '_blank' : undefined}
        rel={props.isExternalUrl ? 'noopener noreferrer' : undefined}
      >
        {header}
      </Link>
    );
  }

  const hasIcon = props.people || props.person || props.icon;
  const isXSorSmaller = props.size === 'xs' || props.size === 'xxs';

  const iconDiv = props.icon && (
    <div className={'avatar align-top avatar-' + props.size}>
      <div
        className={
          'avatar-title bg-primary-soft rounded-circle text-primary ' +
          (props.size === 'xxs' ? 'fs-sm' : 'fs-lg')
        }
      >
        <i
          className={props.icon}
          title={props.iconTitle ? props.iconTitle : undefined}
        ></i>
      </div>
    </div>
  );

  const compressMargins = props.inDropdown && !isXSorSmaller;

  let body = (
    <>
      {props.insertBefore &&
        props.insertBefore(
          beforeContentIsOpen,
          toggleBeforeContent,
          beforeContentOnClose
        )}
      <Row
        className={
          'flex-nowrap' +
          (props.bodyOnly && props.className ? ' ' + props.className : '')
        }
        style={style}
      >
        {hasIcon && (
          <Col
            xs="auto"
            className={'position-relative ' + (compressMargins ? ' ms-n3' : '')}
          >
            {props.addOrRemoveHighlight &&
              (props.isHighlight || props.activeHighlights) && (
                <>
                  <button
                    ref={highlightHoverRef}
                    className={
                      'highlight-circle align-top btn btn-rounded-circle btn-white btn-sm ' +
                      (props.isHighlight
                        ? ''
                        : props.activeHighlights
                        ? 'display-inline-on-list-group-item-hover '
                        : '')
                    }
                    disabled={!props.activeHighlights}
                    role={props.activeHighlights ? 'button' : undefined}
                    style={{
                      position: 'absolute',
                      top: '0.4rem',
                      right: '3.95rem',
                    }}
                    onClick={
                      props.activeHighlights
                        ? props.addOrRemoveHighlight
                        : () => {
                            /* DO NOTHING */
                          }
                    }
                  >
                    <span
                      className={
                        props.isHighlight
                          ? ''
                          : props.activeHighlights
                          ? 'display-inline-on-list-group-item-hover '
                          : ''
                      }
                      style={{
                        position: 'relative',
                        top: -1,
                        zIndex: 1,
                        color: TEXT_COLORS.warning,
                      }}
                    >
                      <Star
                        size="14"
                        strokeWidth="1px"
                        fill={props.isHighlight ? TEXT_COLORS.warning : 'white'}
                      />
                    </span>
                  </button>
                  {props.activeHighlights && (
                    <UncontrolledPopover
                      placement="bottom"
                      trigger="hover"
                      target={highlightHoverRef}
                    >
                      <PopoverBody className="text-dark">
                        {props.isHighlight
                          ? 'Unhighlight this activity.'
                          : 'Highlight this activity to your manager as more important than the others.'}
                      </PopoverBody>
                    </UncontrolledPopover>
                  )}
                </>
              )}
            {!props.hideAvatar && props.person && !props.people && (
              <Avatar
                className="align-top"
                person={props.person}
                size={props.size}
                linked={props.url ? true : false}
                isExternalUrl={props.isExternalUrl}
                hidePopover={props.hidePopover}
                leaderboardNumber={props.leaderboardNumber}
              />
            )}
            {!props.person && props.people && (
              <AvatarGroup
                className="align-top"
                people={props.people}
                format="circle"
                size={props.size}
                linked={props.url ? true : false}
                isExternalUrl={props.isExternalUrl}
                hidePopover={props.hidePopover}
              />
            )}
            {(props.insertBefore || props.onClickIcon) &&
              props.url &&
              props.icon &&
              typeof props.icon === 'string' &&
              !props.person &&
              !props.people && (
                <span
                  onClick={
                    props.insertBefore ? toggleBeforeContent : props.onClickIcon
                  }
                  role="button"
                >
                  {iconDiv}
                </span>
              )}
            {!(props.insertBefore || props.onClickIcon) &&
              props.url &&
              props.icon &&
              typeof props.icon === 'string' &&
              !props.person &&
              !props.people && (
                <Link
                  to={props.url}
                  target={props.isExternalUrl ? '_blank' : undefined}
                  rel={props.isExternalUrl ? 'noopener noreferrer' : undefined}
                >
                  {iconDiv}
                </Link>
              )}
            {!props.url &&
              props.icon &&
              typeof props.icon === 'string' &&
              !props.person &&
              !props.people &&
              iconDiv}
            {props.url &&
              props.icon &&
              typeof props.icon !== 'string' &&
              !props.person &&
              !props.people && (
                <Link
                  to={props.url}
                  target={props.isExternalUrl ? '_blank' : undefined}
                  rel={props.isExternalUrl ? 'noopener noreferrer' : undefined}
                >
                  {props.icon}
                </Link>
              )}
            {!props.url &&
              props.icon &&
              typeof props.icon !== 'string' &&
              !props.person &&
              !props.people &&
              props.icon}
          </Col>
        )}
        <Col className="my-auto">
          <Row className="flex-nowrap p-0 align-items-center">
            {props.preAside && <Col className="col-auto">{props.preAside}</Col>}
            <Col
              className={
                hasIcon
                  ? (!props.hideAvatar ? 'ms-n3' : 'ms-n4') +
                    (props.onClose ? ' me-4' : '') +
                    (props.size === 'xxs' ? ' pe-1 ps-2' : '') +
                    (props.inPopover ? ' pt-1' : '')
                  : props.size === 'xxs'
                  ? ' ps-3 pe-1'
                  : ''
              }
            >
              {isXSorSmaller && (
                <div>
                  <h4
                    className={
                      (props.headerClassName
                        ? props.headerClassName
                        : 'text-dark') + (props.aside ? ' mb-1' : ' d-inline')
                    }
                    style={props.headerStyle}
                  >
                    {header}
                  </h4>
                  {props.description && (
                    <small
                      className={'text-muted' + (props.aside ? '' : ' ms-3')}
                    >
                      {props.description}
                    </small>
                  )}
                </div>
              )}
              {!isXSorSmaller && (
                <>
                  <h4
                    className={
                      (props.headerClassName
                        ? props.headerClassName
                        : 'text-dark') + ' my-1'
                    }
                    style={props.headerStyle}
                  >
                    {header}
                  </h4>
                  {props.description && (
                    <div
                      className={
                        (props.size === 'sm' ? 'small ' : '') +
                        'text-muted mb-0'
                      }
                    >
                      {props.description}
                    </div>
                  )}
                </>
              )}
              {props.showEnrichedProfile && props.person?.id && (
                <PersonEnrichedProfileCard person={props.person} />
              )}
              {props.showEnrichedProfile &&
                props.person &&
                !props.person.id && (
                  <ExternalPersonEnrichedProfileCard person={props.person} />
                )}
            </Col>
            {props.aside && (
              <Col
                className={
                  'col-auto' +
                  (props.secondAside || (!props.secondAside && compressMargins)
                    ? ' me-n3'
                    : '')
                }
              >
                {props.aside}
              </Col>
            )}
            {props.secondAside && (
              <Col className={'col-auto' + (compressMargins ? ' me-n3' : '')}>
                {props.secondAside}
              </Col>
            )}
          </Row>
          {props.headerOptions && (
            <Row>
              <Col className="px-0">{props.headerOptions}</Col>
            </Row>
          )}
          {props.headerChildren}
        </Col>
      </Row>
      {props.children}
    </>
  );

  if (props.bodyOnly) {
    return body;
  }

  let cardStyle = {
    ...props.style,
  };

  if (props.inline) {
    cardStyle['boxShadow'] = 'none';

    if (isXSorSmaller) {
      cardStyle['marginBottom'] = '4px';
    }
  }

  return (
    <Card
      className={
        '' +
        (props.inline ? ' d-inline-block align-top me-2' : '') +
        (props.onClose && !props.disabled
          ? isXSorSmaller
            ? ' pe-2'
            : ' pe-5'
          : '') +
        (props.className ? ' ' + props.className : '') +
        (props.isValid ? '' : ' alert-danger')
      }
      role={props.role}
      style={cardStyle}
    >
      <CardBody
        className={
          (props.onClose
            ? isXSorSmaller
              ? props.size === 'xxs'
                ? 'py-1 px-2'
                : 'p-2'
              : 'p-3'
            : '') +
          (props.lockedMessage && props.lockedMessageOnClick
            ? ' blurred-content'
            : '') +
          (props.cardBodyClassName ? ' ' + props.cardBodyClassName : '') +
          (props.inPopover ? ' align-items-center d-flex' : '')
        }
      >
        {body}
      </CardBody>
      {props.lockedMessage && props.lockedMessageOnClick && (
        <div
          className="blurred-content-overlay"
          onClick={props.lockedMessageOnClick}
          role="button"
        >
          <div>
            <span className="fe fe-lock mb-1 d-block" />
            <span className="blurred-content-overlay-text">
              {props.lockedMessage}
            </span>
            <div>
              <Button
                className="btn btn-sm mt-2"
                color="primary"
                onClick={addToResume}
              >
                <FormattedMessage
                  id="app.views.widgets.cards.object_card.add_to_resume"
                  defaultMessage="
                Add to resume
              "
                />
              </Button>
            </div>
          </div>
        </div>
      )}
      {!props.disabled && props.onClose && (
        <div
          className={'position-absolute' + (isXSorSmaller ? '' : ' mt-2 me-2')}
          style={{
            top: '4px',
            right: '5px',
          }}
        >
          <button
            type="button"
            onClick={props.onClose}
            title={props.closeButtonText}
            className="object-card-close-button d-block btn m-0 p-0"
          >
            <i
              className={'fe fe-x ' + (isXSorSmaller ? 'font-sm' : 'font-xl')}
            />
          </button>
        </div>
      )}
    </Card>
  );
};

ObjectCard.defaultProps = {
  size: 'sm',
  bodyOnly: false,
  inline: false,
  inDropdown: false,
  style: {},
  closeButtonText: 'Close',
  isValid: true,
  addOrRemoveHighlight: false,
  isHighlight: false,
  activeHighlights: false,
  hideAvatar: false,
  disabled: false,
};

ObjectCard.propTypes = {
  className: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  headerStyle: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  iconTitle: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  formatNameForQuery: PropTypes.func,
  stringNameForQueries: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  url: PropTypes.string,
  query: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  bodyOnly: PropTypes.bool,
  inline: PropTypes.bool,
  inDropdown: PropTypes.bool,
  closeButtonText: PropTypes.string,
  onClose: PropTypes.func,
  person: PropTypes.object,
  people: PropTypes.arrayOf(PropTypes.object),
  showEnrichedProfile: PropTypes.bool,
  isExternalUrl: PropTypes.bool,
  aside: PropTypes.node,
  preAside: PropTypes.node,
  headerOptions: PropTypes.node,
  headerChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  lockedMessage: PropTypes.string,
  lockedMessageOnClick: PropTypes.func,
  insertBefore: PropTypes.func,
  onClickIcon: PropTypes.func,
  hidePopover: PropTypes.bool,
  inPopover: PropTypes.bool,
  leaderboardNumber: PropTypes.number,
  addOrRemoveHighlight: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isHighlight: PropTypes.bool,
  activeHighlights: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  role: PropTypes.string,
};

export default React.memo(ObjectCard);
