import axios, { type AxiosInstance } from 'axios';
import {
  getFriendlyUserFacingErrorObjectAndMessage,
  getInsecureHashCodeFromString,
} from '../util/util';
import {
  getUserLocalStorage,
  removeUserLocalStorage,
  setUserLocalStorage,
} from '../models/User';

import { Person } from '../../types';
import { isEmpty, isEqual } from 'lodash';
import { defaultBackoffRetryConfig } from './axiosRetry';

type ObjectId = string | number;

type ConfirmAPIType = {
  sendRequestToConfirm: (
    method: string,
    url: string,
    data: any,
    callback: (...args: any[]) => void,
    preSubmit?: (() => void) | null,
    additionalHeaders?: any
  ) => void;
  OBJECT_TYPES: { [key: string]: string };
  getObjectUrl: (
    objectType: string,
    objectId: ObjectId,
    queryParams?: any,
    currentProxyPerson?: Person | null | undefined
  ) => string;
  getCacheKey: (
    auth0UserSubForCaching: unknown,
    objectType: string,
    objectId: ObjectId
  ) => ObjectId;
  getObjectFromCache: (
    auth0UserSubForCaching: unknown,
    currentProxyPerson: Person | null | undefined,
    objectType: string,
    objectId: ObjectId,
    ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists?: boolean | null
  ) => unknown;
  setObjectInCache: (
    auth0UserSubForCaching: unknown,
    currentProxyPerson: Person | null | undefined,
    objectType: string,
    objectId: ObjectId,
    object: unknown,
    ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists?: number | null
  ) => unknown;
  removeObjectFromCache: (
    auth0UserSubForCaching: unknown,
    currentProxyPerson: Person | null | undefined,
    objectType: string,
    objectId: ObjectId
  ) => unknown;
  getUrlWithCache: (
    url: string,
    objectType: string,
    auth0UserSubForCaching: unknown,
    currentProxyPerson: Person | null | undefined,
    requestData: any,
    callback: (...args: any[]) => void,
    errorCallback: (...args: any[]) => void,
    ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists?: number | null
  ) => void;
  getObject: (
    auth0UserSubForCaching: unknown,
    currentProxyPerson: Person | null | undefined,
    objectType: string,
    objectId: ObjectId,
    callback: (...args: any[]) => void,
    errorCallback: (...args: any[]) => void,
    queryParams?: any
  ) => void;
} & AxiosInstance;

// for sending requests to Confirm server
// @ts-expect-error
const ConfirmAPI: ConfirmAPIType = axios.create({
  baseURL: process.env.REACT_APP_CONFIRM_API_URL,
  responseType: 'json',
});

// N.B. axios treats "null" and "undefined" values differently. when using POST/PATCH,
// fields with a value of "null" are passed as "null" in JSON, whereas fields with a
// value of "undefined" are silently STRIPPED from the request body. if you want to
// send a signal to the backend that it should clear a field value during update, you
// must not use "undefined" because the backend will not see it. use "null" instead
// https://github.com/axios/axios/issues/1139
// https://github.com/axios/axios/pull/1987
ConfirmAPI.sendRequestToConfirm = (
  method,
  url,
  data,
  callback,
  preSubmit = null,
  additionalHeaders = null
) => {
  try {
    // run presubmit function if one was provided
    if (preSubmit) {
      preSubmit();
    }

    let requestFunction;

    // set any optional headers if provided
    const config: { headers?: any; params?: any } = additionalHeaders
      ? { headers: additionalHeaders }
      : {};

    // Only setting retry on GET requests since
    // POST/PUT/PATHC/DELETE requests might not be idempotent
    // and can cause side effects if retried
    // Evaluate case by case when api is used
    // TODO: set a mechanism to add extra config
    if (method === 'GET') {
      config.params = data;
      requestFunction = () =>
        ConfirmAPI.get(url, {
          ...config,
          // @ts-expect-error
          retryConfig: defaultBackoffRetryConfig(),
        });
    } else if (method === 'DELETE') {
      config.params = data;
      requestFunction = () => ConfirmAPI.delete(url, config);
    } else if (method === 'POST') {
      requestFunction = () => ConfirmAPI.post(url, data, config);
    } else if (method === 'PUT') {
      requestFunction = () => ConfirmAPI.put(url, data, config);
    } else if (method === 'PATCH') {
      requestFunction = () => ConfirmAPI.patch(url, data, config);
    }

    // submit request
    requestFunction()
      .then((response) => {
        const status = response.status;
        const responseHeaders = response?.headers;

        if (status === 200 || status === 201 || status === 204) {
          const data = status === 204 && !response.data ? {} : response.data;
          if (Object.prototype.hasOwnProperty.call(data, 'error')) {
            // soft failure; surface details
            callback(null, data.error, undefined, responseHeaders);
          } else {
            // pass the new data back to the callback
            callback(data, undefined, undefined, responseHeaders);
          }
        } else {
          // unexpected response status; hard failure
          callback(null, response, 'Error ' + status, responseHeaders);
        }
      })
      .catch((error) => {
        const responseHeaders = error?.response?.headers;

        // Soft error (e.g. validation)
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */

          // NOTE: we want this to be null as in the case of a soft failure (e.g. a 400 validation error due to bad
          // input provided by the user, we do NOT want to pass back a hard error message as we don't want to show that
          // in the UI; we want to just send the data so it is parsed and shown in the UI field-by-field)
          let errorMessage: any = null;

          // if any other type of custom error was provided, use that
          if (error.response.data) {
            if (error.response.data.detail) {
              errorMessage = error.response.data.detail;
            } else if (error.response.data.message) {
              errorMessage = error.response.data.message;
            } else if (
              typeof error.response.data === 'string' ||
              error.response.status >= 500
            ) {
              // sometimes it's a hard error but no detail/message is provided; make sure we provide a "hard" error
              // message to avoid the "two-column" error message broken UX
              errorMessage = error.response.data;
              console.error('Hard error: ' + JSON.stringify(errorMessage));
              if (typeof errorMessage === 'object') {
                // must convert errorMessage to string or we get a WSoD
                errorMessage = errorMessage?.error || 'Internal error';
              }
            }
          } else {
            if (error.response.detail) {
              errorMessage = error.response.detail;
            } else if (error.response.message) {
              errorMessage = error.response.message;
            } else if (error.response.statusText) {
              errorMessage = error.response.statusText;
            } else {
              errorMessage = error.toString();
            }
          }

          callback(null, error.response, errorMessage, responseHeaders);
        } else if (error.request) {
          // hard failure; take it from detail or message field if it exists, else convert to string
          const errorMessage = error.toString();

          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.error('Request error: ' + errorMessage);
          callback(null, error, errorMessage, responseHeaders);
        } else {
          // if this is a token refresh, don't return anything (so screen doesn't change)
          if (
            error.error !== 'login_required' &&
            error.error !== 'consent_required'
          ) {
            // hard failure; take it from detail or message field if it exists, else convert to string
            const errorMessage = error.toString();

            // Something happened in setting up the request and triggered an Error
            console.error('Unexpected error: ' + errorMessage);
            callback(null, error, errorMessage, responseHeaders);
          }
        }
      });
  } catch (error: any) {
    // hard failure
    const errorMessage = error.toString();
    console.error('Error: ' + errorMessage);
    callback(null, error, errorMessage, undefined);
  }
};

const CACHE_KEY_PREFIX = 'confirmApiUrl::';

// object types for Confirm API backend urls and local storage cache
ConfirmAPI.OBJECT_TYPES = {
  PEOPLE: 'people',
  ACTIVITIES: 'activities',
  CREDENTIALS: 'credentials',
  SKILLS: 'skills',
  PACKETS: 'packets',
  // objects not in DB but for which we have defined endpoints
  USER_PERFORMANCE: 'user-performance',
  PERSON_PERFORMANCE: 'person-performance',
  RECOMMENDATIONS: 'get-recommendations-for-object',
  // for administration
  CAMPAIGNS: 'campaigns',
  DATASETS: 'datasets',
};

ConfirmAPI.getObjectUrl = (
  objectType,
  objectId,
  queryParams,
  currentProxyPerson = null
) => {
  // inject proxy person into query if provided
  let url = '/' + objectType + '/' + objectId;
  const params = queryParams ? queryParams : {};
  if (currentProxyPerson?.email) {
    params['proxy'] = currentProxyPerson.email;
  }
  if (!isEmpty(params)) {
    url += '?' + new URLSearchParams(params).toString();
  }
  return url;
};

ConfirmAPI.getCacheKey = (auth0UserSubForCaching, objectType, objectId) => {
  const url = ConfirmAPI.getObjectUrl(objectType, objectId);
  return CACHE_KEY_PREFIX + url;
};

ConfirmAPI.getObjectFromCache = (
  auth0UserSubForCaching,
  currentProxyPerson,
  objectType,
  objectId,
  ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists = null
) => {
  if (!auth0UserSubForCaching) {
    return null;
  }

  const cacheKey = ConfirmAPI.getCacheKey(
    auth0UserSubForCaching,
    objectType,
    objectId
  );

  return getUserLocalStorage(
    auth0UserSubForCaching,
    currentProxyPerson,
    null,
    cacheKey,
    !!ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists
  );
};

ConfirmAPI.setObjectInCache = (
  auth0UserSubForCaching,
  currentProxyPerson,
  objectType,
  objectId,
  object,
  ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists = null
) => {
  if (!auth0UserSubForCaching || !objectId) {
    return null;
  }

  const cacheKey = ConfirmAPI.getCacheKey(
    auth0UserSubForCaching,
    objectType,
    objectId
  );

  return setUserLocalStorage(
    auth0UserSubForCaching,
    currentProxyPerson,
    null,
    cacheKey,
    object,
    // @ts-expect-error
    ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists
  );
};

ConfirmAPI.removeObjectFromCache = (
  auth0UserSubForCaching,
  currentProxyPerson,
  objectType,
  objectId
) => {
  if (!auth0UserSubForCaching) {
    return null;
  }

  const cacheKey = ConfirmAPI.getCacheKey(
    auth0UserSubForCaching,
    objectType,
    objectId
  );

  return removeUserLocalStorage(
    auth0UserSubForCaching,
    currentProxyPerson,
    null,
    cacheKey
  );
};

// get object from backend (pulling from local storage cache first and calling the callback
// twice in that case), and saving to local storage if auth0UserSubForCaching is provided
ConfirmAPI.getObject = (
  auth0UserSubForCaching,
  currentProxyPerson,
  objectType,
  objectId,
  callback,
  errorCallback,
  queryParams
) => {
  const url = ConfirmAPI.getObjectUrl(
    objectType,
    objectId,
    queryParams,
    currentProxyPerson
  );

  // if auth0 user sub is provided, set data
  // callback from cache first to speed up
  // user perception, then fetch the latest
  // from the server
  const cachedResult = ConfirmAPI.getObjectFromCache(
    auth0UserSubForCaching,
    currentProxyPerson,
    objectType,
    objectId
  );

  if (cachedResult) {
    callback(cachedResult);
  }

  return ConfirmAPI.sendRequestToConfirm(
    'GET',
    url,
    {},
    (data, error, hardErrorMessage, responseHeaders) => {
      if (hardErrorMessage) {
        errorCallback(hardErrorMessage);
        callback(null, undefined, undefined, responseHeaders);

        // clear cache as this could be a 404 or another type of error,
        // so if object were deleted or changed, we want to clear it out
        ConfirmAPI.removeObjectFromCache(
          auth0UserSubForCaching,
          currentProxyPerson,
          objectType,
          objectId
        );
      } else if (error) {
        errorCallback(error);
        callback(null, undefined, undefined, responseHeaders);

        // clear cache as this could be a 404 or another type of error,
        // so if object were deleted or changed, we want to clear it out
        ConfirmAPI.removeObjectFromCache(
          auth0UserSubForCaching,
          currentProxyPerson,
          objectType,
          objectId
        );
      } else {
        // if this matches local storage (deep equal), don't return again (to avoid re-rendering)
        if (!cachedResult || !isEqual(cachedResult, data)) {
          ConfirmAPI.setObjectInCache(
            auth0UserSubForCaching,
            currentProxyPerson,
            objectType,
            data?.id,
            data
          );
          return callback(data, undefined, undefined, responseHeaders);
        }
      }
    }
  );
};

// get object from backend (pulling from local storage cache first and calling the callback
// twice in that case), and saving to local storage if auth0UserSubForCaching is provided
ConfirmAPI.getUrlWithCache = (
  url,
  objectType,
  auth0UserSubForCaching,
  currentProxyPerson,
  requestData,
  callback,
  errorCallback,
  // WARNING: passing in this flag means that it will pull from the
  // cache and NOT request from the server if it's in the cache; if
  // you want to instead first pull from the cache and then call
  // the callback again with data from the server, leave this param null/undefined
  ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists = null
) => {
  // we need an object id for caching; data should be unique based on
  // request data passed in, so hash the strigified version of requestData
  // to get a unique id (or default to "all" if no request data)
  const objectId: ObjectId = requestData
    ? getInsecureHashCodeFromString(JSON.stringify(requestData))
    : 'all';

  // if auth0 user sub is provided, set data
  // callback from cache first to speed up
  // user perception, then fetch the latest
  // from the server
  const cachedResult = ConfirmAPI.getObjectFromCache(
    auth0UserSubForCaching,
    currentProxyPerson,
    objectType,
    objectId,
    !!ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists
  );

  if (cachedResult) {
    callback(cachedResult);

    if (ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists) {
      // do NOT fetch from server if cached if this param is passed in
      return;
    }
  }

  // add proxied user as a param if provided
  if (currentProxyPerson?.email) {
    requestData = {
      ...requestData,
      proxy: currentProxyPerson.email,
    };
  }

  return ConfirmAPI.sendRequestToConfirm(
    'GET',
    url,
    requestData,
    (data, error, hardErrorMessage, responseHeaders) => {
      if (error || hardErrorMessage) {
        const [errorObject, friendlyErrorMessage] =
          getFriendlyUserFacingErrorObjectAndMessage(error, hardErrorMessage);
        if (typeof errorCallback === 'function') {
          errorCallback(
            friendlyErrorMessage ? friendlyErrorMessage : errorObject
          );
        } else {
          console.error(
            'getUrlWithCache: errorCallback required, not provided'
          );
        }
        callback(null, undefined, undefined, responseHeaders);

        // clear cache as this could be a 404 or another type of error,
        // so if object were deleted or changed, we want to clear it out
        ConfirmAPI.removeObjectFromCache(
          auth0UserSubForCaching,
          currentProxyPerson,
          objectType,
          objectId
        );
      } else {
        // if this matches local storage (deep equal), don't return again (to avoid re-rendering)
        if (!cachedResult || !isEqual(cachedResult, data)) {
          ConfirmAPI.setObjectInCache(
            auth0UserSubForCaching,
            currentProxyPerson,
            objectType,
            objectId,
            data,
            ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists
          );
          return callback(data, undefined, undefined, responseHeaders);
        }
      }
    }
  );
};

export default ConfirmAPI;
