import * as consts from '../../../../consts/consts';

import { Col, Row, NavLink } from 'reactstrap';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { scrollTo } from '../../../../utils/util/utiltsx';
import { FormattedMessage, type IntlShape } from 'react-intl';

const getNavSectionButton = (
  hashUrl,
  icon,
  title,
  subtitle,
  mdWidth = 'col-md-2',
  icon2 = null
) => (
  <Col
    key={`column-${hashUrl}`}
    className={'d-flex flex-column col mb-3 ' + mdWidth}
    xs="12"
    sm="6"
    md="4"
    xxl="2"
  >
    <div
      role="button"
      className="flex-grow-1 nounderline py-4 mb-0 align-items-center w-100 lift card hover-shadow"
      onClick={() => scrollTo(hashUrl)}
    >
      <div className="avatar">
        <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
          <i className={icon} />
          {icon2 && <i className={icon2} />}
        </div>
      </div>
      <h3 className="mb-0 mt-3 text-dark text-center">{title}</h3>
      <p className="small text-muted mb-0 text-center">{subtitle}</p>
    </div>
  </Col>
);

type NavSectionConfiguration = {
  hashUrl: string;
  icon: string;
  title: string;
  subtitle: string;
  mdWidth?: string;
  icon2?: string;
};

interface NavSectionConfigurationOptions {
  campaignHasEngagementSurvey: boolean;
  shouldShowEmployeeNPS: boolean;
}

export const findNextSectionFor = (
  currentSection: string,
  configuration: NavSectionConfiguration[]
): NavSectionConfiguration | undefined => {
  const currentSectionIdx = configuration.findIndex(
    (x) => x.hashUrl === currentSection
  );

  if (currentSectionIdx >= 0 && currentSectionIdx < configuration.length - 1) {
    const found = configuration[currentSectionIdx + 1];
    return found;
  }
  return undefined;
};

export const getNavSectionConfiguration = (
  formatMessage: IntlShape['formatMessage'],
  rows: any[],
  {
    campaignHasEngagementSurvey,
    shouldShowEmployeeNPS,
  }: NavSectionConfigurationOptions
): NavSectionConfiguration[] => {
  const enabledSections = rows.flatMap((row) =>
    row.filter((el) => !!el.id).map((el) => el.id)
  );

  // only show gold stars and influence as separate buttons if
  // this would not make the total number of buttons exceed 6
  // (which is the UI limit to keep them in one row)
  // (note: sections include welcome and final recommendations so
  // check for 8 or fewer)
  const shouldShowGoldStarsAndInfluenceSeparateButtons =
    enabledSections.length <= 8;

  return [
    ...(shouldShowGoldStarsAndInfluenceSeparateButtons
      ? [
          {
            hashUrl: 'gold-stars-header',
            icon: consts.ICONS.GOLD_STAR,
            title: formatMessage({
              id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.impact.title',
              defaultMessage: 'Impact',
            }),
            subtitle: formatMessage({
              id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.impact.sub_title',
              defaultMessage: 'Who stands out',
            }),
          },
          {
            hashUrl: 'influence-header',
            icon: consts.ICONS.INFLUENCE,
            title: formatMessage({
              id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.influence.title',
              defaultMessage: 'Influence',
            }),
            subtitle: formatMessage({
              id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.incluence.sub_title',
              defaultMessage: 'Who enables change',
            }),
          },
        ]
      : []),
    {
      hashUrl: 'gold-stars-and-influence-header',
      icon: consts.ICONS.GOLD_STAR,
      title: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.gold_star_influence.title',
        defaultMessage: 'All recognition',
      }),
      subtitle: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.gold_star_influence.sub_title',
        defaultMessage: 'Impact and influence',
      }),
      mdWidth: 'col-md-2',
      // until we get TakeawaysDashboard.js converted to typescript
      icon2: consts.ICONS.INFLUENCE,
    },
    shouldShowEmployeeNPS
      ? {
          hashUrl: 'employee-nps-header',
          icon: consts.ICONS.ENGAGEMENT_SURVEY + ' text-secondary',
          title: formatMessage({
            id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.employee_nps.title',
            defaultMessage: 'eNPS',
          }),
          subtitle: formatMessage({
            id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.employee_nps.sub_title',
            defaultMessage: 'Employee NPS',
          }),
        }
      : campaignHasEngagementSurvey
      ? {
          hashUrl: 'engagement-survey-header',
          icon: consts.ICONS.ENGAGEMENT_SURVEY + ' text-secondary',
          title: formatMessage({
            id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.engagement.title',
            defaultMessage: 'Engagement',
          }),
          subtitle: formatMessage({
            id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.engagement.sub_title',
            defaultMessage: 'Survey results',
          }),
        }
      : null,
    {
      hashUrl: 'talent-matrix-header',
      icon: consts.ICONS.TALENT_MATRIX,
      title: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.talent_matrices.title',
        defaultMessage: 'Talent Matrices',
      }),
      subtitle: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.talent_matrices.sub_title',
        defaultMessage: '9-box, etc.',
      }),
    },
    {
      hashUrl: 'skills-and-behaviors-header',
      icon: consts.ICONS.SKILL + ' text-success',
      title: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.skills.title',
        defaultMessage: 'Competencies',
      }),
      subtitle: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.skills.sub_title',
        defaultMessage: 'Skills and behaviors',
      }),
    },
    {
      hashUrl: 'concerns-header',
      icon: consts.ICONS.HEADS_UP,
      title: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.heads_up.title',
        defaultMessage: 'Concerns',
      }),
      subtitle: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.heads_up.sub_title',
        defaultMessage: 'Red flags',
      }),
    },
    {
      hashUrl: 'whats-next-header',
      icon: consts.ICONS.RECOMMENDATION,
      title: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.recommendation.title',
        defaultMessage: 'Recommendations',
      }),
      subtitle: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.nav_selection.button.recommendation.sub_title',
        defaultMessage: "What's next?",
      }),
      mdWidth: 'col-md-2',
    },
  ].filter(
    (x) => x != null && enabledSections.includes(x.hashUrl)
  ) as NavSectionConfiguration[];
};

interface NextSectionLinkProps {
  sectionId: string;
  configuration: NavSectionConfiguration[];
}

export const NextSectionLink: FC<NextSectionLinkProps> = ({
  sectionId,
  configuration,
}) => {
  const [textDecoration, setTextDecoration] = useState('none');
  const section = findNextSectionFor(sectionId, configuration);

  const handleOnClick = useCallback(
    () => scrollTo(section?.hashUrl ?? ''),
    [section?.hashUrl]
  );
  const handleOnMouseEnter = useCallback(
    () => setTextDecoration('underline'),
    []
  );
  const handleOnMouseLeave = useCallback(() => setTextDecoration('none'), []);

  if (!section) {
    return null;
  }

  return (
    <NavLink
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      role="button"
      style={{
        display: 'inline-block',
        textDecoration: textDecoration,
      }}
    >
      <span className="text-muted">
        <FormattedMessage
          id="app.views.widgets.dashboards.takeaways_dashboard.next_button.text"
          defaultMessage="Next: "
        />
      </span>{' '}
      <i className={section.icon} />{' '}
      {section.icon2 && <i className={section.icon2} />}{' '}
      <span className="fw-bold text-dark">{section.title}</span>
    </NavLink>
  );
};

interface NavigationButtonsProps {
  disabled?: boolean;
  configuration: NavSectionConfiguration[];
}

const adaptNavigationConfiguration = (
  configuration: NavSectionConfiguration[]
): NavSectionConfiguration[] => {
  if (configuration.length > 6) {
    // chop the seventh since it doesn't look good to have more than 6 buttons....
    return configuration.filter((it) => it.hashUrl !== 'whats-next-header');
  }
  return configuration;
};

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  disabled = false,
  configuration,
}) => {
  const navigationConfiguration = useMemo(
    () => adaptNavigationConfiguration(configuration),
    [configuration]
  );

  const buttons = useMemo(
    () =>
      navigationConfiguration.map((x) =>
        getNavSectionButton(
          x.hashUrl,
          x.icon,
          x.title,
          x.subtitle,
          x.mdWidth,
          // @ts-expect-error
          x.icon2
        )
      ),
    [navigationConfiguration]
  );

  if (disabled) {
    return null;
  }

  return <Row className="mb-4 d-flex">{buttons}</Row>;
};

export default NavigationButtons;
