import * as types from '../actions/types';

import { getUserLocalStorage, setUserLocalStorage } from '../utils/models/User';

import { campaignIsActiveOrDemoPerfCampaign } from '../utils/models/Campaign';
import { isEqual } from 'lodash';
import { levelingFixtures } from '../FixturesData';
import { orgIsActiveOrDemo } from '../utils/models/Organization';

// if all parts of object match, don't change state (to avoid re-rendering anything)
const leftIfDeepEqual = (a, b) => {
  if (isEqual(a, b)) {
    return a;
  } else {
    return b;
  }
};

export const initialState = {
  // core features

  // The auth user from Auth0.
  authUser: null,
  // Confirm platform User/Person reflecting
  // who logged-in.
  underlyingAuthMe: null,

  // logged in user's corresponding PERSON for the current organization;
  // if using the proxy feature ("view as") this contains the proxied PERSON (not user)
  me: null,
  myConfigs: {},

  currentOrganization: null,
  // Only when using the proxy feature this contains
  // the proxied user.
  currentProxyPerson: null,
  organizations: [],
  isLoadingApp: true,
  isUnableToLoadDueToError: false,
  loadingErrorMessage: null,
  loading: true,
  features: {},

  // perf (aka performance cycles)
  // NOTE: we set campaigns to null and surveyResponses to null to indicate
  // nothing has been returned from the server (instead of []'s which indicates
  // the server says there are no perf campaigns for the given user
  campaigns: null,
  surveyResponses: null,

  // TODO: change these to be a dict based on campaign ids, similar to
  // currentDemoOrPreviewPerfCampaigns below, to support multiple campaigns
  // running in parallel
  currentPerfSurveyResponse: null,
  currentPerfCampaign: null,

  // this is a dict of campaign ids to campaigns and is for demos/previews only;
  // each campaign is set to null on any page load and we only update it in the
  // current page (i.e. it gets cleared out on a refresh)
  currentDemoOrPreviewPerfCampaigns: {},

  // tasks
  tasks: [],
  acceptedTask: null,
  declinedTask: null,

  // HRIS integration
  appIntegrations: [],

  // DB object descriptors
  attachedContentTypes: {},

  // demo/dummy/synthetic data
  demoPeople: levelingFixtures.people,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH_USER: {
      // fetch data from LocalStorage to enable fetching needed elements from the given page
      // while app is initializing (for performance)

      // core features
      let features,
        settings,
        me,
        myConfigs,
        underlyingAuthMe,
        organizations,
        currentOrganization,
        // perf
        campaigns,
        surveyResponses,
        currentPerfCampaign,
        currentPerfSurveyResponse;

      if (action.authUser?.sub) {
        // core features
        features = getUserLocalStorage(
          action.authUser.sub,
          state.currentProxyPerson,
          null,
          'features'
        );
        settings = getUserLocalStorage(
          action.authUser.sub,
          state.currentProxyPerson,
          null,
          'settings'
        );
        me = getUserLocalStorage(
          action.authUser.sub,
          state.currentProxyPerson,
          null,
          'me'
        );
        myConfigs = getUserLocalStorage(
          action.authUser.sub,
          state.currentProxyPerson,
          null,
          'myConfigs'
        );
        underlyingAuthMe = getUserLocalStorage(
          action.authUser.sub,
          null, // Don't pass currentProxyPerson.
          null,
          'underlyingAuthMe'
        );
        organizations = getUserLocalStorage(
          action.authUser.sub,
          state.currentProxyPerson,
          null,
          'organizations'
        );

        // perf
        // NOTE: we don't cache surveyResponses, currentPerfCampaign,
        // or currentPerfSurveyResponse as these can change and when
        // loading the page from scratch, any differences can be jarring.
        // @ts-expect-error
        campaigns = getUserLocalStorage(action.authUser.sub, null, 'campaigns');
      }

      return {
        ...state,

        // core features
        authUser: action.authUser,
        isLoadingApp: true,
        isUnableToLoadDueToError: false,
        features,
        settings,
        me,
        myConfigs,
        underlyingAuthMe,
        organizations,
        currentOrganization,

        // perf
        campaigns,
        surveyResponses,
        currentPerfCampaign,
        currentPerfSurveyResponse,
      };
    }

    case types.SET_ME: {
      // save "me" for pulling from local storage
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'me',
        action.me
      );

      if (!state.currentProxyPerson) {
        setUserLocalStorage(
          // @ts-expect-error
          state.authUser.sub,
          null, // Don't pass currentProxyPerson.
          null,
          'underlyingAuthMe',
          action.me
        );
      }

      return {
        ...state,
        me: leftIfDeepEqual(state.me, action.me),
        underlyingAuthMe: !state.currentProxyPerson
          ? leftIfDeepEqual(state.underlyingAuthMe, action.me)
          : state.underlyingAuthMe,
      };
    }

    case types.UPDATE_ME: {
      // just passes in changes (instead of the full object)
      const newMe = {
        // @ts-expect-error
        ...state.me,
        ...action.changes,
      };

      // save updated user for pulling from local storage
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'me',
        newMe
      );

      if (!state.currentProxyPerson) {
        setUserLocalStorage(
          // @ts-expect-error
          state.authUser.sub,
          null, // Don't pass currentProxyPerson.
          null,
          'underlyingAuthMe',
          newMe
        );
      }

      return {
        ...state,
        me: leftIfDeepEqual(state.me, newMe),
        underlyingAuthMe: !state.currentProxyPerson
          ? leftIfDeepEqual(state.underlyingAuthMe, newMe)
          : state.underlyingAuthMe,
      };
    }

    case types.UPDATE_MY_CONFIGS: {
      // just passes in changes (instead of the full object)
      const newConfigs = {
        ...state.myConfigs,
        ...action.changes,
      };

      // save updated user for pulling from local storage
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'myConfigs',
        newConfigs
      );

      return {
        ...state,
        myConfigs: leftIfDeepEqual(state.myConfigs, newConfigs),
      };
    }

    case types.SET_CURRENT_ORGANIZATION:
      // save updated current organization for pulling from local storage
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'currentOrganization',
        action.organization
      );
      return { ...state, currentOrganization: action.organization };

    case types.SET_CURRENT_PROXY_PERSON:
      // note: we do not save person in local storage (but person is used
      // for fetching key from local storage)
      return {
        ...state,
        currentProxyPerson: action.person,
        // the organization is optional, for super users, to proxy only an org without a user
        currentProxyOrganization: action.organization,
      };

    case types.SET_CURRENT_PERF_CAMPAIGN:
      // save updated proxy person for pulling from local storage
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'currentPerfCampaign',
        action.campaign
      );
      return { ...state, currentPerfCampaign: action.campaign };

    case types.SET_CURRENT_PERF_SURVEY_RESPONSE:
      // save updated proxy person for pulling from local storage
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'currentPerfSurveyResponse',
        action.surveyResponse
      );
      return { ...state, currentPerfSurveyResponse: action.surveyResponse };

    case types.SET_CURRENT_DEMO_OR_PREVIEW_PERF_CAMPAIGN:
      // note: we do not save in local storage as it should only persist
      // for the current session
      return {
        ...state,
        currentDemoOrPreviewPerfCampaigns: {
          ...state.currentDemoOrPreviewPerfCampaigns,
          [action.campaign.id]: action.campaign,
        },
      };

    case types.RENDER_APP: {
      if (!action.data) {
        return {
          ...state,
          isLoadingApp: false,
          isUnableToLoadDueToError: true,
        };
      }

      const activeOrDemoOrgs =
        action.data.organizations?.length > 0
          ? action.data.organizations.filter(orgIsActiveOrDemo)
          : [];

      // if any org is active/demo, pick first in list that is eligible, else pick first
      // (and only persist current org if it's still in the org list)
      const currentOrg =
        state.currentOrganization &&
        action.data.organizations.findIndex(
          (o) =>
            o?.id &&
            // @ts-expect-error
            o?.id?.toString() === state.currentOrganization?.id?.toString()
        ) !== -1
          ? // NOTE: we want to use the item in the orgs list and NOT the state, because of the proxy
            // case where we first select the org, then select the person to proxy; if we proxy as
            // a system admin for the org, that exists on the org level
            action.data.organizations.find(
              (o) =>
                o?.id &&
                // @ts-expect-error
                o?.id?.toString() === state.currentOrganization?.id?.toString()
            )
          : activeOrDemoOrgs?.length > 0
          ? activeOrDemoOrgs[0]
          : action.data.organizations?.length > 0
          ? action.data.organizations[0]
          : null;

      const currentProxy = state.currentProxyPerson;

      // cache values for local storage (for faster loading later)
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'features',
        action.data.features
      );
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'settings',
        action.data.settings
      );
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'me',
        action.data.user.person
      );
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'myConfigs',
        action.data.user.configs
      );
      if (!currentProxy) {
        setUserLocalStorage(
          // @ts-expect-error
          state.authUser.sub,
          null, // Don't pass currentProxyPerson.
          null,
          'underlyingAuthMe',
          action.data.user.person
        );
      }
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'organizations',
        action.data.organizations
      );
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'currentOrganization',
        currentOrg
      );
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'currentProxyPerson',
        currentProxy
      );

      return {
        ...state,
        features: leftIfDeepEqual(state.features, action.data.features),
        // @ts-expect-error
        settings: leftIfDeepEqual(state.settings, action.data.settings),
        me: leftIfDeepEqual(state.me, action.data.user.person),
        myConfigs: leftIfDeepEqual(state.myConfigs, action.data.user.configs),
        underlyingAuthMe: !currentProxy
          ? leftIfDeepEqual(state.underlyingAuthMe, action.data.user.person)
          : state.underlyingAuthMe,
        currentOrganization: leftIfDeepEqual(
          state.currentOrganization,
          currentOrg
        ),
        currentProxyPerson: leftIfDeepEqual(
          state.currentProxyPerson,
          currentProxy
        ),
        organizations: leftIfDeepEqual(
          state.organizations,
          action.data.organizations
        ),
        attachedContentTypes: leftIfDeepEqual(
          state.attachedContentTypes,
          action.data.attached_content_types
        ),
        originalOrganization: currentOrg,
        isLoadingApp: false,
        isUnableToLoadDueToError: false,
      };
    }

    case types.SET_CAMPAIGNS_AND_SURVEY_RESPONSES: {
      if (!action.data) {
        return {
          ...state,
          isUnableToLoadDueToError: true,
        };
      }

      const activeOrDemoPerfCampaigns =
        action.data.campaigns?.length > 0
          ? action.data.campaigns.filter(campaignIsActiveOrDemoPerfCampaign)
          : [];

      // if any perf campaign is active/demo, pick first in list that is eligible, else pick first
      // (and only persist current perf campaign if it's still in the active or demo perf campaign list)
      let currentPerfCamp = null;
      if (activeOrDemoPerfCampaigns?.length > 0) {
        currentPerfCamp = activeOrDemoPerfCampaigns[0];
      }

      // if any survey response exists for the current perf campaign,
      // set that as currentPerfCampaign
      const currentPerfSurveyResponse =
        action.data.survey_responses?.length > 0 && currentPerfCamp
          ? action.data.survey_responses.find(
              // @ts-expect-error
              (s) => s.campaign === currentPerfCamp.id
            )
          : null;

      // Store if org has leveling framework
      const levelingFramework = action.data.leveling_framework_exists;

      // NOTE: we don't cache surveyResponses, currentPerfCampaign,
      // or currentPerfSurveyResponse as these can change and when
      // loading the page from scratch, any differences can be jarring.
      setUserLocalStorage(
        // @ts-expect-error
        state.authUser.sub,
        state.currentProxyPerson,
        null,
        'campaigns',
        action.data.campaigns
      );

      // If none of the fetched data fields below differ from what is in the
      // current state, we leave the state as is, and avoid an extra
      // update, which would reset all page data and potentially
      // impact user experience (since this query could take a while to load)
      if (
        isEqual(state.campaigns, action.data.campaigns) &&
        isEqual(state.surveyResponses, action.data.surveyResponses) &&
        isEqual(state.currentPerfSurveyResponse, currentPerfSurveyResponse) &&
        isEqual(state.currentPerfCampaign, currentPerfCamp) &&
        // @ts-expect-error
        isEqual(state.orgHasLevelingFramework, levelingFramework)
      ) {
        return state;
      }

      return {
        ...state,
        campaigns: leftIfDeepEqual(state.campaigns, action.data.campaigns),
        surveyResponses: leftIfDeepEqual(
          state.surveyResponses,
          action.data.surveyResponses
        ),
        currentPerfCampaign: leftIfDeepEqual(
          state.currentPerfCampaign,
          currentPerfCamp
        ),
        currentPerfSurveyResponse: leftIfDeepEqual(
          state.currentPerfSurveyResponse,
          currentPerfSurveyResponse
        ),
        orgHasLevelingFramework: leftIfDeepEqual(
          // @ts-expect-error
          state.orgHasLevelingFramework,
          levelingFramework
        ),
      };
    }

    case types.SET_TASKS:
      if (!action.data) {
        console.error('Error fetching tasks.');
        return;
      }

      // NOTE: we do not cache tasks because we want
      // to be certain that the tasks have not completed,
      // as we show a popup for a pending task when
      // a person loads a page on Confirm
      return { ...state, tasks: action.data.tasks };

    case types.LOAD_APP_INTEGRATIONS:
      return { ...state, loading: true, isUnableToLoadDueToError: false };

    case types.RENDER_APP_INTEGRATIONS:
      if (!action.data) {
        return {
          ...state,
          loading: false,
          loadingErrorMessage:
            'There was an error loading your app integrations. Please try again later or contact customer support.',
        };
      }

      return {
        ...state,
        appIntegrations: action.data?.results,
        loading: false,
        loadingErrorMessage: null,
      };

    case types.LOAD_INVITATION:
      return {
        ...state,
        invitation: action.invitation,
        callback: action.callback,
      };

    case types.CLAIM_INVITATION:
      return {
        ...state,
        invitation: action.invitation,
        callback: action.callback,
      };

    case types.ADD_APP_INTEGRATION:
      return {
        ...state,
        appIntegrations: [
          { ...action.appIntegration },
          ...state.appIntegrations,
        ],
      };

    case types.UPDATE_APP_INTEGRATION:
      return {
        ...state,
        appIntegrations: state.appIntegrations.map((appIntegration) =>
          action.appIntegration &&
          // @ts-expect-error
          appIntegration.id === action.appIntegration.id
            ? action.appIntegration
            : appIntegration
        ),
      };

    case types.ACCEPT_TASK:
      return {
        ...state,
        acceptedTask: action.task,
      };

    case types.DECLINE_TASK:
      return {
        ...state,
        declinedTask: action.task,
      };

    default:
      return state;
  }
};

export default reducer;
