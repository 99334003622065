import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const DEFAULT_CHECKED_VALUE = 'Y';
export const DEFAULT_UNCHECKED_VALUE = 'N';

export const BASIC_PERSON_FIELDS = [
  'id',
  'url',
  'avatar',
  'given_name',
  'family_name',
  'full_name',
  'status',
  'title',
  // get email for use with proxy person
  'email',
];

export const DETAILED_PERSON_FIELDS = [
  'function',
  'function_id',
  'location',
  'country',
  'cost_center_id',
  'cost_center',
  'department_id',
  'department',
  'business_unit_id',
  'business_unit',
];

// NOTE: these type names (lowercased, e.g. "textarea") are used in Campaign configurations,
// so we CANNOT change them if they are being used there -- also, if you ADD any here,
// make sure to add them to validators.py where you see this list so custom questions
// added in places like feedback requests will be validated properly
export const INPUT_TYPES = {
  TEXT: 'TEXT', // default (one line simple input), chosen if nothing else matches
  TEXTAREA: 'TEXTAREA',
  RICH_TEXT_EDITOR: 'RICH_TEXT_EDITOR',
  MENTION_EDITOR: 'MENTION_EDITOR',
  MEDIA_UPLOADER: 'MEDIA_UPLOADER',
  SWITCH: 'SWITCH',
  SWITCH_WITH_TIMEFRAME_SELECTOR: 'SWITCH_WITH_TIMEFRAME_SELECTOR',
  TOGGLE: 'TOGGLE',
  DROPDOWN: 'DROPDOWN',
  SELECT: 'SELECT',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_MULTI: 'CHECKBOX_MULTI',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  BUTTON_OPTIONS: 'BUTTON_OPTIONS',
  AUTOSUGGEST: 'AUTOSUGGEST',
  TAGS_INPUT: 'TAGS_INPUT',
  DATE_PICKER: 'DATE_PICKER',
  PEOPLE_EDITOR: 'PEOPLE_EDITOR',
  PLACES_INPUT: 'PLACES_INPUT',
  CONTACT_METHOD_EDITOR: 'CONTACT_METHOD_EDITOR',
  LIKERT: 'LIKERT',
  SWITCHBANK: 'SWITCHBANK',
  PERCENTAGE_INPUT: 'PERCENTAGE_INPUT',
  TABLE_EDITOR: 'TABLE_EDITOR',
  QUESTIONS_EDITOR: 'QUESTIONS_EDITOR',
  INCLUDE_EXCLUDE_FILTER: 'INCLUDE_EXCLUDE_FILTER',
  STAR_RATING: 'STAR_RATING',
  EMPLOYEE_NPS: 'EMPLOYEE_NPS',
  EMPLOYEE_NPS_PREVIEW: 'EMPLOYEE_NPS_PREVIEW',

  // for use in campaign phase configurations
  // (note: campaign configs are lowercase but we uppercase before processing)
  SECTION: 'SECTION',
  SKILLS: 'SKILLS',

  // For custom components (may not even be input)
  CUSTOM_INPUT: 'CUSTOM_INPUT',
  // For custom non istantiated components that are wired with the form
  CUSTOM_COMPONENT: 'CUSTOM_COMPONENT',
};

export const getQueryWithType = (q) =>
  q
    ? { name: q, source_includes: ['id', 'name', 'type', 'visibility'] }
    : { source_includes: ['id', 'name', 'type', 'visibility'] };

export const INPUT_ATTRIBUTES = (formatMessage) => ({
  SKILLS_AND_ACTIVITIES: {
    type: INPUT_TYPES.TAGS_INPUT,
    matchingValidatorNames: ['skills', 'activities'],
    renderCreateOption: (x, setIndex) => {
      // default to skill type
      setIndex('skills');

      // note: we set the index on hover because the clicks are stolen
      // by the react tags input, so this is a hack to let us capture
      // the index
      return {
        name: x,
        object: {
          // NOTE: we do not allow creating an activity on the fly
          // as activities must have at least one contribution to be created
          // to ensure we don't get a lot of trash data
          name: (
            <span className="d-flex" style={{ whiteSpace: 'nowrap' }}>
              <span>
                <span className="d-inline-block fw-normal mt-2">
                  <FormattedMessage
                    id="app.views.widgets.inputs.validate_input.attributes.skills_and_activities.create_skill"
                    defaultMessage='Create skill "{skillName}"'
                    values={{ skillName: x }}
                  />
                </span>
              </span>
            </span>
          ),
        },
      };
    },
    elasticsearchOptions: {
      url: 'get-skills-and-activities-by-name',
      getQuery: getQueryWithType,
    },
    // for when a dropdown type is used instead, e.g. in campaign configurations
    // that use a dropdown list of skills
    valueFromAttribute: 'name',
  },
  SKILLS_AND_CREDENTIALS: {
    type: INPUT_TYPES.TAGS_INPUT,
    matchingValidatorNames: ['skills', 'credentials'],
    renderCreateOption: (x, setIndex) => {
      // default to skill type
      setIndex('skills');

      // note: we set the index on hover because the clicks are stolen
      // by the react tags input, so this is a hack to let us capture
      // the index
      return {
        name: x,
        object: {
          name: (
            <span className="d-flex" style={{ whiteSpace: 'nowrap' }}>
              <span>
                <span className="d-inline-block fw-normal mt-2">
                  <FormattedMessage
                    id="app.views.widgets.inputs.validate_input.attributes.skills_and_credentials.create_element"
                    defaultMessage='Create "{element}" as:'
                    values={{ element: x }}
                  />
                </span>
              </span>
              <Button
                color="light"
                className="btn btn-sm mx-3"
                onMouseOver={() => setIndex('skills')}
              >
                <FormattedMessage
                  id="app.views.widgets.inputs.validate_input.attributes.skills_and_credentials.skill"
                  defaultMessage="Skill"
                />
              </Button>
              <Button
                color="light"
                className="btn btn-sm"
                onMouseOver={() => setIndex('credentials')}
              >
                <FormattedMessage
                  id="app.views.widgets.inputs.validate_input.attributes.skills_and_credentials.credential"
                  defaultMessage="Credential"
                />
              </Button>
            </span>
          ),
        },
      };
    },
    elasticsearchOptions: {
      url: 'get-skills-and-credentials-by-name',
      getQuery: getQueryWithType,
    },
    // for when a dropdown type is used instead, e.g. in campaign configurations
    // that use a dropdown list of skills
    valueFromAttribute: 'name',
  },
  SKILLS: {
    type: INPUT_TYPES.TAGS_INPUT,
    matchingValidatorNames: ['skills'],
    helperText: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.attributes.skills.helper_text',
      defaultMessage: 'For example, "Python" or "writing"',
    }),
    renderCreateOption: (x, setIndex) => {
      // default to skill type
      setIndex('skills');

      return {
        name: x,
        object: {
          name: (
            <span>
              <span className="fw-normal">
                <FormattedMessage
                  id="app.views.widgets.inputs.validate_input.attributes.skills.create_skill"
                  defaultMessage='Create skill "{skillName}"'
                  values={{ skillName: x }}
                />
              </span>
            </span>
          ),
        },
      };
    },
    elasticsearchOptions: {
      url: 'get-skills-by-name',
      getQuery: getQueryWithType,
    },
    // for when a dropdown type is used instead, e.g. in campaign configurations
    // that use a dropdown list of skills
    valueFromAttribute: 'name',
  },
  CREDENTIALS: {
    type: INPUT_TYPES.TAGS_INPUT,
    matchingValidatorNames: ['credentials'],
    helperText: formatMessage({
      id: 'app.views.widgets.inputs.validate_input.attributes.credentials.helper_text',
      defaultMessage: 'For example, "Series 63" or "CEP Level I"',
    }),
    renderCreateOption: (x, setIndex) => {
      // default to credentials type
      setIndex('credentials');

      return {
        name: x,
        object: {
          name: (
            <span>
              <span className="fw-normal">
                {' '}
                <FormattedMessage
                  id="app.views.widgets.inputs.validate_input.attributes.credentials.create_credential"
                  defaultMessage='Create credential "{credentialName}"'
                  values={{ credentialName: x }}
                />
              </span>
            </span>
          ),
        },
      };
    },
    elasticsearchOptions: {
      url: 'get-credentials-by-name',
      getQuery: getQueryWithType,
    },
    // for when a dropdown type is used instead, e.g. in campaign configurations
    // that use a dropdown list of skills
    valueFromAttribute: 'name',
  },
  ACCOUNT_NOTIFICATION_SWITCHBANK: {
    type: INPUT_TYPES.SWITCHBANK,
    checkedValue: DEFAULT_CHECKED_VALUE,
    uncheckedValue: DEFAULT_UNCHECKED_VALUE,
    isHorizontalLayout: true,
    labelClassName: 'fw-bold',
    defaultValue: DEFAULT_CHECKED_VALUE,
  },
});

export const getPeopleOrAllQuery = (q, addSourceIncludes = []) => ({
  name: q,
  source_includes: [...BASIC_PERSON_FIELDS, ...addSourceIncludes],
});
