import {
  PERFORMANCE_FEATURE_ALLOW_EXTERNAL_MANAGER_RATINGS,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  getCampaignHasEvaluationPhase,
  getCampaignHasFeatureEnabled,
  getCampaignRatings,
} from './Performance';
import { floor, isNumber } from 'lodash';

import { COLORBLIND_COLORS } from '../../consts/consts';
import { SENSITIVE_DATA_OMITTED } from './SurveyResponse';
import { dateDiffInMonths } from '../util/util';

export const BREAKOUT_INFLUENCER_TENURE_THRESHOLD = 6;
export const LOW_INFLUENCER_TENURE_THRESHOLD = 12;

export const SCATTERPLOT_POINT_COLORS = {
  low: COLORBLIND_COLORS.red,
  medium: COLORBLIND_COLORS.blue,
  high: COLORBLIND_COLORS.green,
  newToRole: 'lightgrey',
  tbd: 'darkgrey',
  transparent: COLORBLIND_COLORS.transparent,
};

// NOTE: this should match campaigns.py TAGS list
export const BREAKDOWN_FIELDS = (formatMessage) => ({
  function: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.function',
    defaultMessage: 'Function',
  }),
  function_id: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.function_id',
    defaultMessage: 'Function ID',
  }),
  level: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.level',
    defaultMessage: 'Level',
  }),
  level_id: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.level_id',
    defaultMessage: 'Level ID',
  }),
  rating: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.rating',
    defaultMessage: 'Rating',
  }),
  department: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.department',
    defaultMessage: 'Department',
  }),
  department_id: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.department_id',
    defaultMessage: 'Department ID',
  }),
  business_unit: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.business_unit',
    defaultMessage: 'Business unit',
  }),
  business_unit_id: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.business_unit_id',
    defaultMessage: 'Business unit ID',
  }),
  cost_center: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.cost_center',
    defaultMessage: 'Cost Center',
  }),
  cost_center_id: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.cost_center_id',
    defaultMessage: 'Cost center ID',
  }),
  location: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.location',
    defaultMessage: 'Location',
  }),
  country: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.country',
    defaultMessage: 'Country',
  }),
  leader: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.leader',
    defaultMessage: 'Senior leader',
  }),
  hrbp: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.hrbp',
    defaultMessage: 'HRBP',
  }),
});

export const FILTER_FIELDS = (formatMessage) => ({
  ...BREAKDOWN_FIELDS(formatMessage),
  manager_full_name: formatMessage({
    id: 'app.views.widgets.models.takeaways.field.manager_full_name',
    defaultMessage: 'Manager',
  }),
});

export const PERSON_FIELDS = {
  email: 0,
  id: 1,
  full_name: 2,
  manager_email: 3,
  tenure: 4,
  gold_stars: 5,
  heads_ups: 6,
  influence: 7,
  advises: 8,
  energizes: 9,
  business_unit: 10,
  cost_center_id: 11,
  department: 12,
  function: 13,
  location: 14,
  level_id: 15,
  leader: 16,
  hrbp: 17,
  promotion: 18,
  rating: 19,
  influence_delta: 20,
  skills_from_self: 21,
  skills_from_manager: 22,
  skills_from_others: 23,
  is_participating: 24,
  avatar: 25,
  title: 26,
  url: 27,
  cost_center: 28,
  manager_full_name: 29,
  status: 30,
};

export const sortByValue = (a, b, descending = true) => {
  const aValue = a?.value;
  const bValue = b?.value;
  // always sort null/undefined towards the end
  if (aValue == null && bValue == null) {
    return 0;
  } else if (bValue == null) {
    return -1;
  } else if (aValue == null) {
    return 1;
  } else {
    return descending ? bValue - aValue : aValue - bValue;
  }
};

export const sortChartLabels = (a, b) => {
  const icmp = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
  if (isNumber(a?.name) && isNumber(b?.name)) {
    // if "name" is a number, then we are sorting ratings, so use desc order
    return icmp.compare(b?.name, a?.name);
  } else {
    return icmp.compare(a?.name, b?.name);
  }
};

export const getPersonField = (p, f) => {
  if (!Object.prototype.hasOwnProperty.call(PERSON_FIELDS, f)) {
    console.error(`getPersonField: Invalid person field: ${f}`);
  }
  return p[PERSON_FIELDS[f]];
};

export const countSkills = (counters, skills) => {
  if (!skills) {
    return counters;
  }
  skills.forEach((skill) => {
    if (skill.name in counters) {
      counters[skill.name]++;
    } else {
      counters[skill.name] = 1;
    }
  });
  return counters;
};

export const incrementCounter = (counter, key, count = 1) => {
  if (key in counter) {
    counter[key]++;
  } else {
    counter[key] = count;
  }
};

// add contents of b counter to a
export const addCounters = (a, b) => {
  if (!b) {
    return;
  }
  for (const [name, count] of Object.entries(b[0])) {
    // @ts-expect-error
    incrementCounter(a[0], name, count);
  }
  for (const [name, count] of Object.entries(b[1])) {
    // @ts-expect-error
    incrementCounter(a[1], name, count);
  }
};

// read a list of skill objects (with name, id, and type) and return a tuple of counters
export const parseSkillList = (skills) => {
  const skillCounter = {};
  const behaviorCounter = {};
  if (skills && skills.length > 0) {
    skills.forEach((s) => {
      if (s.type === 'E') {
        incrementCounter(skillCounter, s.name);
      } else {
        incrementCounter(behaviorCounter, s.name);
      }
    });
  }
  return [skillCounter, behaviorCounter];
};

export const extractPeopleFromSurveyResponses = (
  responses,
  end_date,
  campaign
) => {
  const people = {};

  if (!responses) {
    return people;
  }

  const filterOptions = {
    level_id: {},
    function: {},
    department: {},
    business_unit: {},
    leader: {},
    location: {},
    cost_center: {},
    manager_email: {},
    manager_full_name: {},
  };

  for (const r of responses) {
    if (r[SENSITIVE_DATA_OMITTED]) {
      continue;
    }
    const is_not_participating = r?.configs?.is_participating === false;
    const start_date =
      r?.configs?.latest_hire_date ||
      r?.person?.latest_hire_date ||
      r?.person?.original_hire_date;
    const tenure = dateDiffInMonths(start_date, end_date);
    const person = [
      r.person.email,
      r.person.id,
      `${r?.person?.legal_given_name || r?.person?.given_name} ${
        r?.person?.family_name
      }`,
      r?.person?.manager?.email,
      tenure,
      0,
      0,
      0,
      0,
      0,
      r?.configs?.business_unit,
      r?.configs?.cost_center_id,
      r?.configs?.department,
      r?.configs?.function,
      r?.configs?.location,
      r?.configs?.level_id,
      r?.configs?.leader,
      r?.configs?.hrbp,
      false,
      // ratings could have been provided externally (in dataset)
      r?.configs?.final_rating ?? r?.configs?.rating ?? null,
      0,
      [parseSkillList(r?.positive_skills), parseSkillList(r?.negative_skills)],
      [
        [{}, {}],
        [{}, {}],
      ],
      [
        [{}, {}],
        [{}, {}],
      ],
      !is_not_participating,
      r?.person?.avatar,
      r?.configs?.title || r?.person?.title,
      r?.person?.url,
      r?.configs?.cost_center,
      r?.configs?.manager_full_name,
      r.person.status,
    ];
    Object.keys(filterOptions).forEach((filter) => {
      const value = r?.configs[filter];
      if (value) {
        filterOptions[filter][value] = value;
      }
    });
    people[person[PERSON_FIELDS.email]] = person;
  }

  const showRatings =
    (getCampaignHasEvaluationPhase(campaign) &&
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      )) ||
    getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_ALLOW_EXTERNAL_MANAGER_RATINGS
    );

  if (showRatings) {
    const ratingScale = getCampaignRatings(campaign, true);
    if (ratingScale) {
      // @ts-expect-error
      filterOptions.rating = Object.fromEntries(
        ratingScale.map((x) => [x.name, x.value])
      );
    }
  }

  return [people, filterOptions];
};

export const getStatsFromRelationship = (relationship, people) => {
  const email = relationship.to_person?.email;
  const toPerson = people[email];
  if (!toPerson) {
    //console.log(`No person found for ${email}`);
    return;
  }
  if (relationship.type === 'E') {
    toPerson[PERSON_FIELDS.energizes]++;
    toPerson[PERSON_FIELDS.influence]++;
  } else if (relationship.type === 'A') {
    toPerson[PERSON_FIELDS.advises]++;
    toPerson[PERSON_FIELDS.influence]++;
  } else if (relationship.type === 'H') {
    toPerson[PERSON_FIELDS.heads_ups]++;
    if (relationship?.negative_skills && relationship.negative_skills.length) {
      const counters = parseSkillList(relationship?.negative_skills);
      const negative_counters = getPersonField(
        toPerson,
        'skills_from_others'
      )[1];
      addCounters(negative_counters, counters);
    }
  } else if (relationship.type === 'G') {
    toPerson[PERSON_FIELDS.gold_stars]++;
    if (relationship?.positive_skills && relationship.positive_skills.length) {
      const counters = parseSkillList(relationship?.positive_skills);
      const positive_counters = getPersonField(
        toPerson,
        'skills_from_others'
      )[0];
      addCounters(positive_counters, counters);
    }
  } else if (relationship.type === 'D') {
    toPerson[PERSON_FIELDS.promotion] = relationship.recommend_for_promotion;
    toPerson[PERSON_FIELDS.rating] =
      relationship.final_rating ||
      relationship.rating ||
      toPerson[PERSON_FIELDS.rating];
    const new_positive_counter = parseSkillList(relationship.positive_skills);
    const new_negative_counter = parseSkillList(relationship.negative_skills);
    const positive_counter = getPersonField(toPerson, 'skills_from_manager')[0];
    const negative_counter = getPersonField(toPerson, 'skills_from_manager')[1];
    addCounters(negative_counter, new_negative_counter);
    addCounters(positive_counter, new_positive_counter);
  }
};

const addSkillBehaviorData = (a, b) => {
  if (!b) {
    return;
  }
  addCounters(a[0], b[0]);
  addCounters(a[1], b[1]);
};

export const addSkillOwners = (skillOwners, person, source) => {
  const positive = {};
  const negative = {};
  const key = `skills_from_${source}`;
  const [[pskill, pbehavior], [nskill, nbehavior]] = getPersonField(
    person,
    key
  );
  Object.assign(positive, pskill, pbehavior);
  Object.assign(negative, nskill, nbehavior);

  Object.keys(positive).forEach((skill) => {
    if (!(skill in skillOwners.positive)) {
      skillOwners.positive[skill] = [];
    }
    skillOwners.positive[skill].push(person);
  });
  Object.keys(negative).forEach((skill) => {
    if (!(skill in skillOwners.negative)) {
      skillOwners.negative[skill] = [];
    }
    skillOwners.negative[skill].push(person);
  });
  return { positive, negative };
};

export const getSkillsAndBehaviors = (people) => {
  const skills_from_self = [
    [{}, {}],
    [{}, {}],
  ];
  const skills_from_manager = [
    [{}, {}],
    [{}, {}],
  ];
  const skills_from_others = [
    [{}, {}],
    [{}, {}],
  ];
  const skillsFromSelfSkillOwners = { positive: {}, negative: {} };
  const skillsFromManagerSkillOwners = { positive: {}, negative: {} };
  const skillsFromOthersSkillOwners = { positive: {}, negative: {} };
  Object.values(people).forEach((person) => {
    addSkillBehaviorData(
      skills_from_self,
      getPersonField(person, 'skills_from_self')
    );
    addSkillBehaviorData(
      skills_from_manager,
      getPersonField(person, 'skills_from_manager')
    );
    addSkillBehaviorData(
      skills_from_others,
      getPersonField(person, 'skills_from_others')
    );
    addSkillOwners(skillsFromSelfSkillOwners, person, 'self');
    addSkillOwners(skillsFromManagerSkillOwners, person, 'manager');
    addSkillOwners(skillsFromOthersSkillOwners, person, 'others');
  });
  const skillsData = {
    fromSelf: skills_from_self,
    fromManager: skills_from_manager,
    fromOthers: skills_from_others,
  };

  return [
    skillsData,
    skillsFromSelfSkillOwners,
    skillsFromManagerSkillOwners,
    skillsFromOthersSkillOwners,
  ];
};

export const getStatsFromData = (data) => {
  if (!data) {
    return {
      count: 0,
      total: 0,
      average: 0,
      p25: 0,
      p75: 0,
      min: 0,
      max: 0,
    };
  }
  const total = data.reduce((acc, x) => x + acc);
  const average = total / data.length;
  data.sort((a, b) => parseInt(a) - parseInt(b));
  const min = data[0];
  const p25 = data[floor(data.length / 4)];
  const p75 = data[floor((data.length * 3) / 4)];
  const max = data[data.length - 1];
  return {
    count: data.length,
    total,
    average,
    p25,
    p75,
    min,
    max,
  };
};

export const createBreakdownData = (formatMessage, people, campaign) => {
  const fields = Object.keys(BREAKDOWN_FIELDS(formatMessage));
  const breakdownData = {
    individual: [],
    campaign: campaign,
  };
  fields.forEach((f) => {
    breakdownData[f] = {};
  });
  for (const [email, person] of Object.entries(people)) {
    // @ts-expect-error
    breakdownData.individual.push({
      name: email,
      // @ts-expect-error
      id: person[PERSON_FIELDS.id],
      // @ts-expect-error
      full_name: person[PERSON_FIELDS.full_name],
      // @ts-expect-error
      tenure: person[PERSON_FIELDS.tenure],
      // @ts-expect-error
      gold_stars: person[PERSON_FIELDS.gold_stars],
      // @ts-expect-error
      heads_ups: person[PERSON_FIELDS.heads_ups],
      // @ts-expect-error
      influence: person[PERSON_FIELDS.influence],
      // @ts-expect-error
      avatar: person[PERSON_FIELDS.avatar],
      // @ts-expect-error
      title: person[PERSON_FIELDS.title],
      // @ts-expect-error
      url: person[PERSON_FIELDS.url],
      // @ts-expect-error
      rating: person[PERSON_FIELDS.rating],
      // CUSTOM FOR TESTING
      influence_gold_stars:
        // @ts-expect-error
        person[PERSON_FIELDS.gold_stars] + person[PERSON_FIELDS.influence],
      // @ts-expect-error
      status: person[PERSON_FIELDS.status],
    });
    fields.forEach((f) => {
      // @ts-expect-error
      const name = person[PERSON_FIELDS[f]];
      if (!(name in breakdownData[f])) {
        breakdownData[f][name] = {
          name: name,
          count: 1,
          tenure__data: [getPersonField(person, 'tenure')],
          gold_stars__data: [getPersonField(person, 'gold_stars')],
          heads_ups__data: [getPersonField(person, 'heads_ups')],
          influence__data: [getPersonField(person, 'influence')],
        };
      } else {
        breakdownData[f][name].count++;
        breakdownData[f][name].tenure__data.push(
          getPersonField(person, 'tenure')
        );
        breakdownData[f][name].gold_stars__data.push(
          getPersonField(person, 'gold_stars')
        );
        breakdownData[f][name].heads_ups__data.push(
          getPersonField(person, 'heads_ups')
        );
        breakdownData[f][name].influence__data.push(
          getPersonField(person, 'influence')
        );
      }
    });
  }
  fields.forEach((f) => {
    breakdownData[f] = Object.values(breakdownData[f])
      .sort(sortChartLabels)
      .map((d) => {
        const data = {
          name:
            // @ts-expect-error
            d.name === undefined
              ? formatMessage({
                  id: 'app.views.widgets.models.takeaways.field.not_availabe',
                  defaultMessage: 'N/A',
                  description: 'Not available abbreviation',
                })
              : // @ts-expect-error
                d.name,
          // @ts-expect-error
          count: d.count,
          // @ts-expect-error
          tenure__stats: getStatsFromData(d.tenure__data),
          // @ts-expect-error
          gold_stars__stats: getStatsFromData(d.gold_stars__data),
          // @ts-expect-error
          heads_ups__stats: getStatsFromData(d.heads_ups__data),
          // @ts-expect-error
          influence__stats: getStatsFromData(d.influence__data),
        };
        // @ts-expect-error
        data.tenure = data.tenure__stats.average;
        // @ts-expect-error
        data.gold_stars = data.gold_stars__stats.total;
        // @ts-expect-error
        data.heads_ups = data.heads_ups__stats.total;
        // @ts-expect-error
        data.influence = data.influence__stats.total;
        return data;
      });
  });
  return breakdownData;
};

// given a Distribution (hash where keys are numeric values (e.g. # of gold stars) and values
// are the number of people with that # of gold stars (for example)
// return average, max, and median, 25th percentile, 75th percentile in an object
export const calculateDistributionStats = (data) => {
  const stats = {
    people: 0,
    totalValue: 0,
    average: 0,
    median: -1,
    perc25: -1,
    perc75: -1,
    max: 0,
  };
  if (!data || data.length === 0) {
    return stats;
  }
  // @ts-expect-error
  let sortedData = Object.entries(data).sort((a, b) => a[0] - b[0]);

  // handles if data is an object (inlcudes people and also count)
  if (sortedData.length && typeof sortedData[0][1] === 'object') {
    // @ts-expect-error
    sortedData = sortedData.map(([key, value]) => [key, value.count]);
  }

  sortedData.forEach(([value, count]) => {
    // @ts-expect-error
    stats.totalValue += parseInt(value) * parseInt(count);
    // @ts-expect-error
    stats.people += parseInt(count);
  });
  stats.average = stats.totalValue / stats.people;
  if (sortedData.length > 0) {
    stats.max = parseInt(sortedData[sortedData.length - 1][0]);
  }

  // find percentiles/median
  let tmpCount = 0;
  sortedData.forEach(([value, count]) => {
    // @ts-expect-error
    tmpCount += parseInt(count);
    if (stats.perc25 === -1 && tmpCount >= Math.floor(stats.people / 4)) {
      stats.perc25 = parseInt(value);
    }
    if (stats.median === -1 && tmpCount >= Math.floor(stats.people / 2)) {
      stats.median = parseInt(value);
    }
    if (stats.perc75 === -1 && tmpCount >= Math.floor((stats.people * 3) / 4)) {
      stats.perc75 = parseInt(value);
    }
  });

  return stats;
};

export const processDistributionData = (distributionData) => ({
  gold_star: calculateDistributionStats(distributionData.gold_star),
  heads_up: calculateDistributionStats(distributionData.heads_up),
  influence: calculateDistributionStats(distributionData.influence),
  energizes: calculateDistributionStats(distributionData.energizes),
  advises: calculateDistributionStats(distributionData.advises),
});

export const getAverageInfluence = (people) => {
  let total_influence = 0;
  let total_tenure = 0;
  Object.values(people).forEach((person) => {
    total_influence += getPersonField(person, 'influence');
    total_tenure += getPersonField(person, 'tenure');
  });

  return total_influence / total_tenure;
};

export const processPeople = (people) => {
  const average_influence = getAverageInfluence(people);
  const gold_star_leaderboard = [];
  const heads_up_leaderboard = [];
  const influence_leaderboard = [];
  const distribution = {
    gold_star: {},
    influence: {},
    heads_up: {},
    energizes: {},
    advises: {},
  };
  Object.values(people).forEach((person) => {
    // @ts-expect-error
    const energizes = person[PERSON_FIELDS.energizes];
    // @ts-expect-error
    const advises = person[PERSON_FIELDS.advises];
    // @ts-expect-error
    const gold_stars = person[PERSON_FIELDS.gold_stars];
    // @ts-expect-error
    const influence = person[PERSON_FIELDS.influence];
    // @ts-expect-error
    const heads_ups = person[PERSON_FIELDS.heads_ups];
    // @ts-expect-error
    const tenure = person[PERSON_FIELDS.tenure];
    // @ts-expect-error
    person[PERSON_FIELDS.influence_delta] =
      influence - average_influence * tenure;

    // @ts-expect-error
    influence_leaderboard.push(person);
    if (gold_stars > 0) {
      // @ts-expect-error
      gold_star_leaderboard.push(person);
    }
    if (heads_ups > 0) {
      // @ts-expect-error
      heads_up_leaderboard.push(person);
    }
    if (!(heads_ups in distribution.heads_up)) {
      distribution.heads_up[heads_ups] = 1;
    } else {
      distribution.heads_up[heads_ups] += 1;
    }
    if (!(gold_stars in distribution.gold_star)) {
      distribution.gold_star[gold_stars] = {
        count: 1,
        people: [
          {
            // @ts-expect-error
            name: person[PERSON_FIELDS.full_name],
            // @ts-expect-error
            url: person[PERSON_FIELDS.url],
          },
        ],
      };
    } else {
      distribution.gold_star[gold_stars].count += 1;
      distribution.gold_star[gold_stars].people.push({
        // @ts-expect-error
        name: person[PERSON_FIELDS.full_name],
        // @ts-expect-error
        url: person[PERSON_FIELDS.url],
      });
    }
    if (!(influence in distribution.influence)) {
      distribution.influence[influence] = {
        count: 1,
        people: [
          {
            // @ts-expect-error
            name: person[PERSON_FIELDS.full_name],
            // @ts-expect-error
            url: person[PERSON_FIELDS.url],
          },
        ],
      };
    } else {
      distribution.influence[influence].count += 1;
      distribution.influence[influence].people.push({
        // @ts-expect-error
        name: person[PERSON_FIELDS.full_name],
        // @ts-expect-error
        url: person[PERSON_FIELDS.url],
      });
    }
    if (!(energizes in distribution.energizes)) {
      distribution.energizes[energizes] = 1;
    } else {
      distribution.energizes[energizes] += 1;
    }
    if (!(advises in distribution.advises)) {
      distribution.advises[advises] = 1;
    } else {
      distribution.advises[advises] += 1;
    }
  });
  gold_star_leaderboard.sort(
    (a, b) => b[PERSON_FIELDS.gold_stars] - a[PERSON_FIELDS.gold_stars]
  );
  heads_up_leaderboard.sort(
    (a, b) => b[PERSON_FIELDS.heads_ups] - a[PERSON_FIELDS.heads_ups]
  );

  influence_leaderboard.sort(
    (a, b) =>
      getPersonField(b, 'influence_delta') -
      getPersonField(a, 'influence_delta')
  );

  const super_influencers = influence_leaderboard.filter(
    // Only include people with influence in super influencer list
    (x) => getPersonField(x, 'influence_delta') > 0
  );

  const low_influencers = influence_leaderboard
    // Don't include people who have influence in low influencer list
    .filter((x) => getPersonField(x, 'influence_delta') <= 0)
    .filter(
      // Don't include tenure less than 1 year in low influencer list
      (x) => getPersonField(x, 'tenure') >= LOW_INFLUENCER_TENURE_THRESHOLD
    )
    .reverse(); // People with lowest influence should be at top

  // Take breakout influencers from [already filtered list of] super influencers
  const breakout_influencers = super_influencers.filter(
    (x) => getPersonField(x, 'tenure') <= BREAKOUT_INFLUENCER_TENURE_THRESHOLD
  );

  return [
    gold_star_leaderboard,
    heads_up_leaderboard,
    distribution,
    super_influencers,
    low_influencers,
    breakout_influencers,
  ];
};

export const getFormattedLabel = (field, label) => {
  if (field === 'rating' && label === null) {
    return '?';
  }
  return label;
};
