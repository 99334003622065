import { ColumnDescriptor } from '../FilterablePeopleTable/FilterablePeopleTableColumnSelectorModal/types';
import { type IntlShape } from 'react-intl';
import { getIconForFieldName } from '../Filters/common';

export const PERSON_METADATA_NON_SENSITIVE_FIELD_IDS: string[] = [
  'function',
  'function_id',
  'cost_center',
  'cost_center_id',
  'business_unit',
  'business_unit_id',
  'department',
  'department_id',
  'location',
  'country',
];

export const personNonSensitiveColumnDescriptors = (
  formatMessage: IntlShape['formatMessage']
): ColumnDescriptor[] => {
  return PERSON_METADATA_NON_SENSITIVE_FIELD_IDS.map((field) => ({
    field,
    popoverContent: getDescriptionForFieldName(field, formatMessage),
    name: getDescriptionForFieldName(field, formatMessage) ?? field,
    icon: getIconForFieldName(field),
    csvName: getDescriptionForFieldName(field, formatMessage) ?? field,
    filterIcon: getIconForFieldName(field),
  }));
};

export const getDescriptionForFieldName = (
  fieldName: string,
  formatMessage: IntlShape['formatMessage']
) => {
  switch (fieldName) {
    case 'title':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.title',
        defaultMessage: 'Title',
      });
    case 'people':
    case 'manager':
    case 'manager_or_above':
    case 'final_rating_provided_by_person':
      // allow for PersonCard avatar icon to take precedence
      return undefined;
    case 'level':
    case 'level_id':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.level',
        defaultMessage: 'Level',
      });
    case 'function':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.function',
        defaultMessage: 'Function',
      });
    case 'function_id':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.function_id',
        defaultMessage: 'Function ID',
      });
    case 'cost_center':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.cost_center',
        defaultMessage: 'Cost center',
      });
    case 'cost_center_id':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.cost_center_id',
        defaultMessage: 'Cost center ID',
      });
    case 'country':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.country',
        defaultMessage: 'Country',
      });
    case 'business_unit':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.business_unit',
        defaultMessage: 'Business unit',
      });
    case 'business_unit_id':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.business_unit_id',
        defaultMessage: 'Business unit ID',
      });
    case 'department':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.department',
        defaultMessage: 'Department',
      });
    case 'department_id':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.department_id',
        defaultMessage: 'Department ID',
      });
    case 'final_rating_text':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.final_rating',
        defaultMessage: 'Final rating',
      });
    case 'location':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.location',
        defaultMessage: 'Location',
      });
    case 'hrbp':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.hrbp',
        defaultMessage: 'HRBP',
      });
    case 'leader':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.leader',
        defaultMessage: 'Leader',
      });
    case 'calibration_text':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.calibration_flags',
        defaultMessage: 'Calibration flags',
      });
    case 'calibration_status':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.calibration_status',
        defaultMessage: 'Calibration status',
      });
    case 'report_release_status':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.report_release_status',
        defaultMessage: 'Report release status',
      });
    case 'report_acknowledgement_status':
      return formatMessage({
        id: 'app.views.widgets.dashboard.team_dashboard.filter_description.report_acknowledgement_status',
        defaultMessage: 'Report acknowledgement status',
      });
  }

  return fieldName;
};
