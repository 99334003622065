import { Button, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Organization,
  OrganizationSettings,
  ReduxState,
} from '../../../../types';
import React, { FC, useCallback, useEffect, useState } from 'react';

import ConfirmAPI from '../../../../utils/api/ConfirmAPI';
import { INPUT_TYPES } from '../ValidatedInputTypes';
import { LOCALES_DISPLAY_LIST_FOR_SELECT } from '../../../../locale/messages';
import ModalEditor from '../../Modals/ModalEditor';
import RichTextViewer from '../RichTextViewer';
import ValidatedForm from '../../Forms/ValidatedForm';
import { connect } from 'react-redux';
import { useConfirmApi } from '../../../../utils/api/ApiHooks';

interface Props {
  settings: OrganizationSettings;
  currentOrganization: Organization;
  type: string;
  translationNamespace: string;
  translationVisibility: 'A' | 'U' | undefined;
  jsonPath: string;
  disabled: boolean;
  name: string;
  value: string;
}

const InputTranslationEditor: FC<Props> = ({
  currentOrganization,
  settings,
  type,
  translationNamespace,
  translationVisibility,
  jsonPath,
  disabled,
  value,
}) => {
  const { formatMessage } = useIntl();
  const isTranslatable =
    settings.internationalization.enabled &&
    [
      INPUT_TYPES.TEXT,
      INPUT_TYPES.RICH_TEXT_EDITOR,
      INPUT_TYPES.TEXTAREA,
    ].includes(type) &&
    translationNamespace != null &&
    jsonPath != null &&
    !disabled &&
    value;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [object, setObject] = useState();

  const { data, status } = useConfirmApi<{ translations: any }>({
    method: 'GET',
    url: '/custom-translations',
    params: { key: jsonPath, namespace: translationNamespace },
    disabled: !isModalOpen,
  });

  useEffect(() => {
    if (data && data.translations && status === 'SUCCESS') {
      setObject(data.translations);
    }
  }, [data, status]);

  const translationInputs = LOCALES_DISPLAY_LIST_FOR_SELECT.filter(
    (locale) =>
      settings.internationalization.supported_locales.includes(locale.id) &&
      locale.id != settings.internationalization.default_locale
  ).map((locale) => ({
    type: type,
    label: locale.name,
    helperText: undefined,
    name: locale.id,
    value: '',
  }));

  const handleSave = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'POST',
      '/custom-translations',
      {
        translations: object,
        key: jsonPath,
        namespace: translationNamespace,
        organization_id: currentOrganization?.id,
        visibility: translationVisibility ?? 'A',
      },
      (response, error, hardErrorMessage = null) => {
        if (response && !error && !hardErrorMessage) {
          setIsModalOpen(false);
        }
      },
      null
    );
  }, [
    object,
    jsonPath,
    translationNamespace,
    translationVisibility,
    currentOrganization?.id,
  ]);

  if (!isTranslatable) {
    return <></>;
  }

  return (
    <Col className="col-auto ps-0 ms-0">
      <Button
        className="btn btn-light translation-button"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <i className="fe fe-globe" />
      </Button>

      {isModalOpen && (
        <ModalEditor
          hideSubmitButton={true}
          title={formatMessage({
            id: 'app.views.widgets.inputs.input_translation_editor.input_translation_editor.title.edit_translations',
            defaultMessage: 'Edit Translations',
          })}
          isOpen={isModalOpen}
          toggle={() => {
            setIsModalOpen(false);
          }}
          callback={() => {}}
        >
          <>
            <h3>
              <FormattedMessage
                id="app.views.widgets.inputs.input_translation_editor.input_translation_editor.original"
                defaultMessage="Orginal"
              />
            </h3>
            <div className="mb-3" style={{ minHeight: '50px' }}>
              <RichTextViewer model={value ?? '<b>No conent</b>'} />
            </div>
            <ValidatedForm
              inForm={true}
              inputs={[...(status === 'SUCCESS' ? translationInputs : [])]}
              onChange={setObject}
              hideSubmitButton={true}
              object={object}
              callback={() => {}}
            />
            {status === 'SUCCESS' && (
              <Button
                color="primary"
                onClick={handleSave}
                className="w-100 mt-4"
              >
                <FormattedMessage
                  id="app.views.widgets.inputs.input_translation_editor.input_translation_editor.button.save_translations"
                  defaultMessage="Save translations"
                />
              </Button>
            )}
          </>
        </ModalEditor>
      )}
    </Col>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, settings } = state;

  return {
    currentOrganization,
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(InputTranslationEditor));
