import { getCurrentCampaignCommon } from 'utils/models/Campaign';

export const getCurrentCampaignForKeyTakeaways = (commonParams) => {
  return getCurrentCampaignCommon({
    ...commonParams,
    urlParams: {
      include_talent_matrices: 'true',
      include_terminated_people: 'true',
      include_engagement_survey_results: 'true',
    },
  });
};
