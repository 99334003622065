import { RatingScale } from './Performance';
import { formatIntegerList } from 'utils/util/util';

export interface AutoCalibationRuleCondition {
  type: string;
  delta: number;
  direction: 'up' | 'down';
  target?: 'positive' | 'negative' | 'neutral' | number[];
}

export interface AutoCalibrationRulePeriod {
  type: string;
  duration: number;
}
export interface AutoCalibrationRule {
  template: string;
  condition: AutoCalibationRuleCondition;
  period: AutoCalibrationRulePeriod;
}

export interface PreCompiledAutoCalibrationRule extends AutoCalibrationRule {
  compiledTemplate: string;
  compiledTemplateVariableNames: string[];
}

const REGEX_VARIABLE = /{([^}]*)}/g;

const buildRatingsString = (target, ratingScale) => {
  if (!target || !ratingScale?.length) {
    return '';
  }

  const average = ratingScale.reduce((a, b) => a + b, 0) / ratingScale.length;
  if (target === 'positive') {
    return formatIntegerList(ratingScale.filter((r) => r > average));
  } else if (target === 'negative') {
    return formatIntegerList(ratingScale.filter((r) => r < average));
  } else if (target === 'neutral') {
    return formatIntegerList(ratingScale.filter((r) => r === average));
  } else if (Array.isArray(target)) {
    return formatIntegerList(ratingScale.filter((r) => target.includes(r)));
  } else {
    console.error(`Unable to build ratings string for ratings ${target}`);
    return '';
  }
};

const buildPeriodString = (period: AutoCalibrationRulePeriod) => {
  return period.duration + ' ' + period.type;
};

const linearReplaceAll = (
  str: string,
  mapObj: Record<string, any>
): { result: string; remainingVariableNames: string[] } => {
  const remainingVariableNames: string[] = [];
  const result = str.replace(REGEX_VARIABLE, function (matched, variable) {
    if (!mapObj[variable]) {
      remainingVariableNames.push(variable);
      return matched;
    }
    return mapObj[variable];
  });
  return { result, remainingVariableNames };
};

export const preComplileCalibrationRules = (
  rules: AutoCalibrationRule[],
  ratingScale: RatingScale = [1, 2, 3, 4, 5]
): PreCompiledAutoCalibrationRule[] => {
  return rules.map((rule) => {
    const {
      result: compiledTemplate,
      remainingVariableNames: compiledTemplateVariableNames,
    } = linearReplaceAll(rule.template, {
      ...rule.condition,
      period: buildPeriodString(rule.period),
      target: buildRatingsString(rule.condition.target, ratingScale),
    });

    return {
      ...rule,
      compiledTemplate,
      compiledTemplateVariableNames,
    };
  });
};

export const getCalibrationFlags = (
  calibrationFlags: number[],
  rules: PreCompiledAutoCalibrationRule[],
  personName: string
) => {
  const flags: string[] = [];
  const missingFlags: string[] = [];
  for (let i = 0; i < rules.length; i++) {
    if (calibrationFlags.includes(i)) {
      flags.push(rules[i].compiledTemplate.replace('{name}', personName));
    } else {
      missingFlags.push(rules[i].compiledTemplate.replace('{name}', 'Person'));
    }
  }
  return [flags, missingFlags];
};
