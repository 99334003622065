import { FormattedMessage, useIntl } from 'react-intl';
import React, { useMemo } from 'react';

import { Campaign } from '../../../types';
import SafeUncontrolledPopover from '../../../components/SafeUncontrolledPopover';
import { useCampaignToggles } from './hooks';
import { ICONS } from 'consts/consts';

const formatBoolean = (value) => {
  if (value === true) {
    return <i className="h1 fe fe-check text-success" />;
  } else if (value === false) {
    return '-';
  } else {
    return '?';
  }
};

const boolSort = (a, b) => {
  if (a === b) {
    return 0;
  }

  if (a === true) {
    return -1;
  }

  if (b === true) {
    return 1;
  }

  return 0;
};

export const useConfiguration = (campaign: Campaign) => {
  const { formatMessage } = useIntl();
  const {
    peer360sAreEnabled,
    upwardFeedbackisEnabled,
    managerRatingsOfDirectsIsEnabled,
    evaluationPhaseIsEnabled,
    highPriorityPeersAreEnabled,
    externalRatingsAreEnabled,
  } = useCampaignToggles(campaign);

  const campaignSurveyResponseParticipationOptions = useMemo(
    () => [
      {
        field: 'location',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.location.text',
          defaultMessage: 'Location',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.location.popover_content',
          defaultMessage: 'Location',
        }),
        filterOnly: true,
      },
      {
        field: 'function',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.function.text',
          defaultMessage: 'Function',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.function.popover_content',
          defaultMessage: 'Function',
        }),
        filterOnly: true,
      },
      {
        field: 'cost_center',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.cost_center.text',
          defaultMessage: 'Cost center',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.cost_center.popover_content',
          defaultMessage: 'Cost center',
        }),
        filterOnly: true,
      },
      {
        field: 'department',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.department.text',
          defaultMessage: 'Department',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.department.popover_content',
          defaultMessage: 'Department',
        }),
        filterOnly: true,
      },
      {
        field: 'business_unit',
        name: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.business_unit.text',
          defaultMessage: 'Business unit',
        }),
        popoverContent: formatMessage({
          id: 'app.views.widgets.inputs.include_exclude_filter.column.business_unit.popover_content',
          defaultMessage: 'Business unit',
        }),
        filterOnly: true,
      },
      {
        field: 'is_participating',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants.name.is_participating',
          defaultMessage: 'Any columns are true',
        }),
        csvOnly: true,
        defaultValue: true,
        sort: (a, b) => boolSort(a.is_participating, b.is_participating),
        columnClassName: 'col-1',
        format: formatBoolean,
      },
      {
        field: 'is_participating_in_self_phase',
        name: formatMessage({
          id: 'app.views.administration.campaign_participants.name.is_participating_in_self_phase',
          defaultMessage: 'Participating',
        }),
        defaultValue: true,
        sort: (a, b) =>
          boolSort(
            a.is_participating_in_self_phase,
            b.is_participating_in_self_phase
          ),
        columnClassName: 'col-1',
        popoverContent: (
          <>
            <span className="fw-bold">
              <FormattedMessage
                id="app.views.administration.campaign_participants.is_participating_in_self_phase.popover_content.initial.text"
                defaultMessage="Is participating in this cycle."
              />
            </span>{' '}
            <FormattedMessage
              id="app.views.administration.campaign_participants.is_participating_in_self_phase.popover_content.message.text"
              defaultMessage=' For
                anyone who should participate in the cycle. This should be
                "Yes" for everyone (unless someone is on leave), even if
                they will be on vacation when the cycle officially starts and need
                to complete it early. For these situations, as long as the campaign
                is published, you can send them a secret link (see the FAQs tab) to
                complete it early.
              '
            />
          </>
        ),
        format: formatBoolean,
        bulkEdit: {
          helperText: (
            <FormattedMessage
              id="app.views.administration.campaign_participants.bulk_edit.is_participating_in_self_phase.helper_text"
              defaultMessage="Is participating in this cycle. <link>Learn more</link>"
              values={{
                link: (chunks) => (
                  <>
                    <a
                      className="text-link"
                      id="learn-more-campaign-participants-bulk-edit-is_participating_in_self_phase"
                    >
                      {chunks}
                    </a>
                    <SafeUncontrolledPopover
                      placement="top"
                      trigger="hover"
                      target="learn-more-campaign-participants-bulk-edit-is_participating_in_self_phase"
                    >
                      <FormattedMessage
                        id="app.views.administration.campaign_participants.bulk_edit.is_participating_in_self_phase.helper_text.learn_more.hover"
                        defaultMessage='For anyone who should participate in the cycle.
                             This should be, "Yes" for everyone (unless someone is on leave),
                             even if they will be on vacation when the cycle officially starts and need to complete it early.
                             For these situations, as long as the cycle is published, you can send them a secret link (see the FAQs tab) to complete it early.'
                      />
                    </SafeUncontrolledPopover>
                  </>
                ),
              }}
            />
          ),
        },
      },
      ...(peer360sAreEnabled
        ? [
            {
              field: 'is_writing_peer_reviews',
              name: (
                <>
                  <i className="fe fe-user" />
                  <i className="fe fe-arrow-right" />
                </>
              ),
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.is_writing_peer_reviews',
                defaultMessage: 'Writing peer reviews',
              }),
              defaultValue: true,
              sort: (a, b) =>
                boolSort(a.is_writing_peer_reviews, b.is_writing_peer_reviews),
              columnClassName: 'col-1',
              popoverContent: (
                <>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.is_writing_peer_reviews.popover_content.initial.text"
                      defaultMessage="
                        Is eligible to write peer feedback reviews.
                      "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.is_writing_peer_reviews.popover_content.message.text"
                    defaultMessage='
                      Some organizations choose to set this to "No" for
                      new employees, but we recommend all employees write something
                      if they are participating in the cycle to give peers a sense
                      of how their experience is working with them, even if brief.
                    '
                  />
                </>
              ),
              format: formatBoolean,
              bulkEdit: {
                helperText: (
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.bulk_edit.is_writing_peer_reviews.helper_text"
                    defaultMessage="Is writing peer reviews in this cycle. <link>Learn more</link>"
                    values={{
                      link: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            id="learn-more-campaign-participants-bulk-edit-is_writing_peer_reviews"
                          >
                            {chunks}
                          </a>
                          <SafeUncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="learn-more-campaign-participants-bulk-edit-is_writing_peer_reviews"
                          >
                            <FormattedMessage
                              id="app.views.administration.campaign_participants.bulk_edit.is_writing_peer_reviews.helper_text.learn_more.hover"
                              defaultMessage=' Some organizations choose to set this to "No" for
                              new employees, but we recommend all employees write something
                              if they are participating in the cycle to give peers a sense
                              of how their experience is working with them, even if brief.'
                            />
                          </SafeUncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                ),
              },
            },
            {
              field: 'is_receiving_peer_reviews',
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.is_receiving_peer_reviews',
                defaultMessage: 'Receiving peer reviews',
              }),
              name: (
                <>
                  <i className="fe fe-user" />
                  <i className="fe fe-arrow-left" />
                </>
              ),
              defaultValue: true,
              sort: (a, b) =>
                boolSort(
                  a.is_receiving_peer_reviews,
                  b.is_receiving_peer_reviews
                ),
              columnClassName: 'col-1',
              popoverContent: (
                <>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.is_receiving_peer_reviews.popover_content.initial.text"
                      defaultMessage="
                        Is eligible to receive peer feedback reviews.
                      "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.administration.campaign_participants..is_receiving_peer_reviews.popover_content.message.text"
                    defaultMessage='
                      Some organizations choose to set this to "No" for
                      new employees, but we recommend all employees for companies
                      that choose to do peer feedback be eligible to receive
                      reviews from peers to help them get a sense of how they
                      are onboarding.
                    '
                  />
                </>
              ),
              format: formatBoolean,
              bulkEdit: {
                helperText: (
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.bulk_edit.is_receiving_peer_reviews.helper_text"
                    defaultMessage="Is eligible to peer feedback reviews. <link>Learn more</link>"
                    values={{
                      link: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            id="learn-more-campaign-participants-bulk-edit-is_receiving_peer_reviews"
                          >
                            {chunks}
                          </a>
                          <SafeUncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="learn-more-campaign-participants-bulk-edit-is_receiving_peer_reviews"
                          >
                            <FormattedMessage
                              id="app.views.administration.campaign_participants.bulk_edit.is_receiving_peer_reviews.helper_text.learn_more.hover"
                              defaultMessage='Some organizations choose to set this to "No" for
                              new employees, but we recommend all employees for companies
                              that choose to do peer feedback be eligible to receive reviews
                              from peers to help them get a sense of how they are onboarding.'
                            />
                          </SafeUncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                ),
              },
            },
          ]
        : []),
      ...(upwardFeedbackisEnabled
        ? [
            {
              field: 'is_writing_upward_manager_review',
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.is_writing_upward_manager_review',
                defaultMessage: 'Writing upward feedback',
              }),
              name: (
                <>
                  <i className="fe fe-user" />
                  <i className="fe fe-arrow-up" />
                </>
              ),
              defaultValue: true,
              sort: (a, b) =>
                boolSort(
                  a.is_writing_upward_manager_review,
                  b.is_writing_upward_manager_review
                ),
              columnClassName: 'col-1',
              popoverContent: (
                <>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.is_writing_upward_manager_review.initial.text"
                      defaultMessage="
                        Is eligibile to write upward feedback to manager.
                      "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.is_writing_upward_manager_review.message.text"
                    defaultMessage='
                      Some organizations choose to set this to "No" for
                      people with new managers, but we recommend all employees give
                      upward feedback if they are participating so the manager can
                      get a sense of how they are onboarding.
                    '
                  />
                </>
              ),
              format: formatBoolean,
              bulkEdit: {
                helperText: (
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.bulk_edit.is_writing_upward_manager_review.helper_text"
                    defaultMessage="Is eligibile to write upward feedback to manager. <link>Learn more</link>"
                    values={{
                      link: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            id="learn-more-campaign-participants-bulk-edit-is_writing_upward_manager_review"
                          >
                            {chunks}
                          </a>
                          <SafeUncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="learn-more-campaign-participants-bulk-edit-is_writing_upward_manager_review"
                          >
                            <FormattedMessage
                              id="app.views.administration.campaign_participants.bulk_edit.is_writing_upward_manager_review.helper_text.learn_more.hover"
                              defaultMessage='Some organizations choose to set this to "No" for
                              people with new managers, but we recommend all employees give
                              upward feedback if they are participating so the manager can
                              get a sense of how they are onboarding.'
                            />
                          </SafeUncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                ),
              },
            },
          ]
        : []),
      ...(evaluationPhaseIsEnabled
        ? [
            {
              field: 'is_evaluated_by_manager',
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.is_evaluated_by_manager',
                defaultMessage: 'Evaluated by manager',
              }),
              name: (
                <>
                  <i className="fe fe-user" />
                  <i className="fe fe-arrow-down" />
                </>
              ),
              defaultValue: true,
              sort: (a, b) =>
                boolSort(a.is_evaluated_by_manager, b.is_evaluated_by_manager),
              columnClassName: 'col-1',
              popoverContent: (
                <>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.is_evaluated_by_manager.popover_content.initial.text"
                      defaultMessage="
                    Is eligible to receive an evaluation from their manager.
                  "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.administration.campaign_participants..is_evaluated_by_manager.popover_content.message.text"
                    defaultMessage='
                  Some organizations choose to set this to "No" for new employees or contractors.'
                  />
                </>
              ),
              format: formatBoolean,
              bulkEdit: {
                helperText: (
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.bulk_edit.is_evaluated_by_manager.helper_text"
                    defaultMessage="Is eligible to receive an evaluation from their manager. <link>Learn more</link>"
                    values={{
                      link: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            id="learn-more-campaign-participants-bulk-edit-is_evaluated_by_manager"
                          >
                            {chunks}
                          </a>
                          <SafeUncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="learn-more-campaign-participants-bulk-edit-is_evaluated_by_manager"
                          >
                            <FormattedMessage
                              id="app.views.administration.campaign_participants.bulk_edit.is_evaluated_by_manager.helper_text.learn_more.hover"
                              defaultMessage='Some organizations choose to set this to "No" for new employees or contractors.'
                            />
                          </SafeUncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                ),
              },
            },
          ]
        : []),
      ...(managerRatingsOfDirectsIsEnabled
        ? [
            {
              field: 'is_rated',
              csvName: 'Receiving rating',
              name: (
                <>
                  <i className="fe fe-user" />
                  <i className="fe fe-check" />
                  <i className="fe fe-arrow-down" />
                </>
              ),
              defaultValue: true,
              sort: (a, b) => boolSort(a.is_rated, b.is_rated),
              columnClassName: 'col-1',
              popoverContent: (
                <>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.is_rated.initial.text"
                      defaultMessage="
                          This person will receive a rating from their manager.
                        "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.is_rated.message.text"
                    defaultMessage='
                        Some organizations choose to set this to "No" for
                        new employees, but we recommend all employees be reviewed if
                        they are participating in the cycle to get a sense of how
                        their onboarding is going as a new employee.
                      '
                  />
                </>
              ),
              format: formatBoolean,
              bulkEdit: {
                helperText: (
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.bulk_edit.is_rated.helper_text"
                    defaultMessage="This person will receive a rating from their manager. <link>Learn more</link>"
                    values={{
                      link: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            id="learn-more-campaign-participants-bulk-edit-is_rated"
                          >
                            {chunks}
                          </a>
                          <SafeUncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="learn-more-campaign-participants-bulk-edit-is_rated"
                          >
                            <FormattedMessage
                              id="app.views.administration.campaign_participants.bulk_edit.is_rated.helper_text.learn_more.hover"
                              defaultMessage='Some organizations choose to set this to "No" for
                                new employees, but we recommend all employees be reviewed if
                                they are participating in the cycle to get a sense of how
                                their onboarding is going as a new employee.'
                            />
                          </SafeUncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                ),
              },
            },
          ]
        : []),
      ...(evaluationPhaseIsEnabled
        ? [
            {
              field: 'is_only_receiving_review',
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.is_only_receiving_review',
                defaultMessage: 'On leave so only receiving review',
              }),
              name: (
                <>
                  <i className="fe fe-alert-triangle" />
                  <i className="fe fe-log-out" />
                </>
              ),
              defaultValue: false,
              sort: (a, b) =>
                boolSort(
                  a.is_only_receiving_review,
                  b.is_only_receiving_review
                ),
              columnClassName: 'col-1',
              popoverContent: (
                <>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.is_only_receiving_review.initial.text"
                      defaultMessage="
                        Is not participating but will still receive manager review.
                      "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.is_only_receiving_review.message.text"
                    defaultMessage='
                      Typically reserved for "On Leave" employees who
                      still need to receive a manager review but cannot otherwise
                      participate in the cycle or be contacted by the company.
                    '
                  />
                </>
              ),
              format: formatBoolean,
              bulkEdit: {
                helperText: (
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.bulk_edit.is_only_receiving_review.helper_text"
                    defaultMessage="Is not participating but will still receive manager review. <link>Learn more</link>"
                    values={{
                      link: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            id="learn-more-campaign-participants-bulk-edit-is_only_receiving_review"
                          >
                            {chunks}
                          </a>
                          <SafeUncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="learn-more-campaign-participants-bulk-edit-is_only_receiving_review"
                          >
                            <FormattedMessage
                              id="app.views.administration.campaign_participants.bulk_edit.is_only_receiving_review.helper_text.learn_more.hover"
                              defaultMessage='Typically reserved for "On Leave" employees who
                              still need to receive a manager review but cannot otherwise
                              participate in the cycle or be contacted by the company.'
                            />
                          </SafeUncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                ),
              },
            },
          ]
        : []),
      ...(highPriorityPeersAreEnabled
        ? [
            {
              field: 'is_only_selecting_high_priority_peers',
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.is_only_selecting_high_priority_peers',
                defaultMessage: 'Only selecting peers for direct reports',
              }),
              name: (
                <>
                  <i className="fe fe-alert-triangle" />
                  <i className="fe fe-arrow-down" />
                </>
              ),
              defaultValue: false,
              sort: (a, b) =>
                boolSort(
                  a.is_only_selecting_high_priority_peers,
                  b.is_only_selecting_high_priority_peers
                ),
              columnClassName: 'col-1',
              popoverContent: (
                <>
                  <span className="fw-bold">
                    <FormattedMessage
                      id="app.views.administration.campaign_participants.is_only_selecting_high_priority_peers.initial.text"
                      defaultMessage="
                        Is not participating, except for choosing peers to review
                        direct reports.
                      "
                    />
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.is_only_selecting_high_priority_peers.message.text"
                    defaultMessage="
                      For organizations that strongly prefer that new managers not
                      participate in the Self + Network section but should still
                      select peers to write peer feedback reviews for each of
                      their direct reports.
                    "
                  />
                </>
              ),
              format: formatBoolean,
              bulkEdit: {
                helperText: (
                  <FormattedMessage
                    id="app.views.administration.campaign_participants.bulk_edit.is_only_selecting_high_priority_peers.helper_text"
                    defaultMessage="Is not participating, except for choosing peers to review
                    direct reports. <link>Learn more</link>"
                    values={{
                      link: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            id="learn-more-campaign-participants-bulk-edit-is_only_selecting_high_priority_peers"
                          >
                            {chunks}
                          </a>
                          <SafeUncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="learn-more-campaign-participants-bulk-edit-is_only_selecting_high_priority_peers"
                          >
                            <FormattedMessage
                              id="app.views.administration.campaign_participants.bulk_edit.is_only_selecting_high_priority_peers.helper_text.learn_more.hover"
                              defaultMessage="For organizations that strongly prefer that new managers not
                              participate in the Self + Network section but should still
                              select peers to write peer feedback reviews for each of their direct
                              reports."
                            />
                          </SafeUncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                ),
              },
            },
          ]
        : []),
      ...(externalRatingsAreEnabled
        ? [
            {
              field: 'rating',
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.external_draft_rating',
                defaultMessage: 'Draft rating from external system',
              }),
              name: (
                <>
                  <i className={ICONS.DRAFT_RATING} />
                </>
              ),
              sort: (a, b) => b - a,
              popoverContent: (
                <FormattedMessage
                  id="app.views.administration.campaign_participants.external_draft_rating.initial.text"
                  defaultMessage="Draft rating imported from external system"
                />
              ),
            },
            {
              field: 'final_rating',
              csvName: formatMessage({
                id: 'app.views.administration.campaign_participants.name.external_final_rating',
                defaultMessage: 'Final rating from external system',
              }),
              name: (
                <>
                  <i className={ICONS.FINAL_RATING} />
                </>
              ),
              sort: (a, b) => b - a,
              popoverContent: (
                <FormattedMessage
                  id="app.views.administration.campaign_participants.external_final_rating.initial.text"
                  defaultMessage="Final rating imported from external system"
                />
              ),
            },
          ]
        : []),
    ],
    [
      formatMessage,
      peer360sAreEnabled,
      upwardFeedbackisEnabled,
      evaluationPhaseIsEnabled,
      managerRatingsOfDirectsIsEnabled,
      highPriorityPeersAreEnabled,
      externalRatingsAreEnabled,
    ]
  );

  return campaignSurveyResponseParticipationOptions;
};
