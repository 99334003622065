import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import PerformanceHistoryTooltip, { TooltipCard } from './HistoryChartTooltip';
import React, { FC, createRef, useMemo, useRef } from 'react';

import CardHeaderTitle from '../../../Widgets/Cards/CardHeaderTitle';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/client';
import { Scatter } from 'react-chartjs-2';
import alertImage from '../../../../assets/img/icons/alert.png';
import { getCampaignRatings } from '../../../../utils/models/Performance';

const PerformanceHistoryChart: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const chartOptions = useMemo(
    () => ({
      maintainAspectRatio: true,
      events: ['mousemove'],
      tooltips: {
        enabled: false,
        mode: 'point',
        custom: function (tooltipModel) {
          const TOOLTIP_ID = `performance-history-chartjs-tooltip-${props.personId}`;
          const TOOLTIP_CLASS = 'performance-history-chartjs-tooltip';

          // Tooltip Element
          let tooltipEl = document.getElementById(TOOLTIP_ID);

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            if (tooltipEl) {
              tooltipEl.remove();
            }
            return;
          }

          // find elements with class and different id and destroy
          // may be leftovers from a different view
          const nodes = document.querySelectorAll(`.${TOOLTIP_CLASS}`);
          const toRemove = Array.from(nodes).filter((n) => n.id !== TOOLTIP_ID);
          toRemove.forEach((n) => n.remove());

          if (tooltipModel.dataPoints) {
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = TOOLTIP_ID;
              tooltipEl.className = TOOLTIP_CLASS;
              document.body.appendChild(tooltipEl);
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            let tooltipBody;
            // Set Text

            if (tooltipModel.body) {
              const graphIndex = tooltipModel.dataPoints[0].datasetIndex;
              const pointIndex = tooltipModel.dataPoints[0].index;

              const isSelf = graphIndex === 0;
              const isTenure = graphIndex === 1;

              // @ts-expect-error
              const dataPoint = { ...props.cycleDatapoints[pointIndex] };
              let formattedDatapoint = {};
              let previousDatapoint = {};
              if (pointIndex) {
                // @ts-expect-error
                previousDatapoint = props.cycleDatapoints[pointIndex - 1];
              }

              if (isSelf) {
                formattedDatapoint = {
                  ...(dataPoint?.person || {}),
                  ...dataPoint,
                  // @ts-expect-error
                  titleChange: props.individualAlerts.includes(pointIndex),
                };
              }
              if (isTenure) {
                formattedDatapoint = {
                  ...(dataPoint?.benchmarks?.organization?.tenure || {}),
                  campaign: dataPoint.campaign?.name,
                  title: formatMessage(
                    {
                      id: 'app.views.person.person_performance.trajectory.history_chart.tenure',
                      defaultMessage:
                        'Tenure 50th percentile ({monthsRangeStart}-{monthsRangeEnd} months)',
                    },
                    {
                      monthsRangeStart:
                        dataPoint.tenure_months - 3 < 0
                          ? 0
                          : dataPoint.tenure_months - 3,
                      monthsRangeEnd: dataPoint.tenure_months + 3,
                    }
                  ),
                };
              }

              const content = (
                <TooltipCard
                  key={0}
                  // @ts-expect-error
                  type={isSelf}
                  data={formattedDatapoint}
                  previousData={previousDatapoint}
                  callback={isSelf ? props.callback : null}
                  formatMessage={formatMessage}
                  // @ts-expect-error
                  managerView={props.managerView}
                />
              );
              tooltipBody = (
                <PerformanceHistoryTooltip
                  previousData={previousDatapoint}
                  data={formattedDatapoint}
                  // @ts-expect-error
                  type={isSelf}
                  content={content}
                />
              );
            }

            // `this` will be the overall tooltip
            // @ts-expect-error
            const position = this._chart.canvas.getBoundingClientRect();

            const leftPosition =
              position.left + window.scrollX + tooltipModel.caretX;
            const topPosition =
              position.top + window.scrollY + tooltipModel.caretY;

            // Display, position, and set styles for font
            // @ts-expect-error
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = leftPosition + 'px';
            tooltipEl.style.top = topPosition + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding =
              tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';

            ReactDOM.createRoot(tooltipEl).render(tooltipBody);

            setTimeout(() => {
              window.requestAnimationFrame(() => {
                // @ts-expect-error
                const width = tooltipEl.offsetWidth;
                if (leftPosition + width > window.innerWidth) {
                  const updatedLeft = leftPosition - width;
                  if (updatedLeft >= 0) {
                    // @ts-expect-error
                    tooltipEl.style.left = leftPosition - width + 'px';
                  }
                }
                // @ts-expect-error
                tooltipEl.style.visibility = 'visible';
                // @ts-expect-error
                tooltipEl.onmouseenter = function () {
                  // @ts-expect-error
                  tooltipEl.onmouseleave = function () {
                    document.getElementById(TOOLTIP_ID)?.remove();
                  };
                };
              });
            }, 0);
          }
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              // @ts-expect-error
              labelString: props.options.x.label,
            },
            ticks: {
              // @ts-expect-error
              min: props.options.x.tickMin,
              // @ts-expect-error
              max: props.options.x.tickMax,
              // @ts-expect-error
              stepSize: props.options.x.tickSize,
              // @ts-expect-error
              maxTicksLimit: props.options.x.maxTicksLimit,
              // @ts-expect-error
              ...(props.options.x.callback
                ? // @ts-expect-error
                  { callback: props.options.x.callback }
                : {}),
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              // @ts-expect-error
              labelString: props.options.y.label,
            },
            ticks: {
              min: 0,
              // @ts-expect-error
              max: props.options.y.tickMax,
              beginAtZero: true,
              // @ts-expect-error
              stepSize: props.options.y.tickSize,
              // @ts-expect-error
              maxTicksLimit: props.options.y.maxTicksLimit,
              // @ts-expect-error
              ...(props.options.y.callback
                ? // @ts-expect-error
                  { callback: props.options.y.callback }
                : {}),
            },
            gridLines: {
              display: true,
              color: '#edf2f9',
              lineWidth: 2,
            },
          },
        ],
      },
    }),
    [
      props.options,
      props.individualAlerts,
      props.cycleDatapoints,
      props.callback,
      props.managerView,
      props.personId,
      formatMessage,
    ]
  );

  const managerRatingLearnMoreRef = useRef(() => createRef());
  const networkRecognitionLearnMoreRef = useRef(() => createRef());

  const calculateManagerRatingBuckets = (cycles) => {
    const buckets = {
      aboveAverage: 0,
      belowAverage: 0,
      average: 0,
    };

    cycles.forEach((e) => {
      if (
        !e.campaign.phases ||
        !getCampaignRatings(e.campaign) ||
        e.rating.value === undefined ||
        e.rating.value === null
      ) {
        return;
      }
      const ratingScale = getCampaignRatings(e.campaign) || [];
      ratingScale.sort((a, b) => a.value - b.value);
      const maxRating = ratingScale[ratingScale.length - 1]?.value;
      let minRating = ratingScale[0]?.value;

      // Skip 0/"new to role" rating
      if (minRating === 0) {
        minRating = ratingScale[1].value;
      }

      if (e.rating.value === maxRating) {
        buckets.aboveAverage++;
      } else if (e.rating.value === minRating) {
        buckets.belowAverage++;
      } else {
        buckets.average++;
      }
    });
    // @ts-expect-error
    buckets.total = Object.values(buckets).reduce((a, b) => a + b, 0);
    return {
      ...buckets,
      // @ts-expect-error
      aboveAverageRatio: (buckets.aboveAverage / buckets.total).toFixed(2),
      // @ts-expect-error
      belowAverageRatio: (buckets.belowAverage / buckets.total).toFixed(2),
      // @ts-expect-error
      averageRatio: (buckets.average / buckets.total).toFixed(2),
    };
  };

  const managerRatingSummary = useMemo(() => {
    if (!props.data2) {
      return null;
    }

    const ratingSummary = calculateManagerRatingBuckets(props.data2);
    // @ts-expect-error
    if (ratingSummary.total === 0) {
      // This means no ratings have been provided historically
      return null;
    }
    // @ts-expect-error
    if (ratingSummary.aboveAverageRatio >= 0.5) {
      return {
        element: (
          <span className="fw-bold">
            <FormattedMessage
              id="app.views.person.person_performance.trajectory.history_chart.overperforming"
              defaultMessage="overperforming"
            />
          </span>
        ),
        type: 'the highest',
        cycles: ratingSummary.aboveAverage,
        // @ts-expect-error
        total: ratingSummary.total,
      };
    }
    // @ts-expect-error
    if (ratingSummary.belowAverageRatio >= 0.5) {
      return {
        element: (
          <span className="fw-bold">
            <FormattedMessage
              id="app.views.person.person_performance.trajectory.history_chart.underperforming"
              defaultMessage="underperforming"
            />
          </span>
        ),
        type: 'the lowest',
        cycles: ratingSummary.belowAverage,
        // @ts-expect-error
        total: ratingSummary.total,
      };
    }
    return {
      element: (
        <span className="fw-bold">
          <FormattedMessage
            id="app.views.person.person_performance.trajectory.history_chart.performing_as_expected"
            defaultMessage="performing as expected"
          />
        </span>
      ),
      type: 'an average',
      cycles: ratingSummary.average,
      // @ts-expect-error
      total: ratingSummary.total,
    };
  }, [props?.data2]);

  return (
    <Card className="card-fill">
      <CardHeader>
        {/* @ts-expect-error */}
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody className="mt-0">
        <Row>
          <Col className={'align-self-center col-12'}>
            <Scatter
              key={`scatter_${props.personId}`}
              data={props.data}
              options={chartOptions}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h6 className="text-muted text-uppercase">
              <FormattedMessage
                id="app.views.person.person_performance.trajectory.history_chart.how_to_read"
                defaultMessage="How to read this graph"
              />
            </h6>
            <div>
              {props.chartType === 'rating' ? (
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.history_chart.how_to_read_contents.manager_rating"
                  defaultMessage="This graph represents {firstName}'s manager ratings over time."
                  values={{
                    // @ts-expect-error
                    organizationName: props.organization.name,
                    firstName: props.firstName,
                    a: (chunks) => (
                      <>
                        <a
                          className="text-link"
                          // @ts-expect-error
                          ref={managerRatingLearnMoreRef}
                        >
                          {chunks}
                        </a>
                        <UncontrolledPopover
                          trigger={'hover click'}
                          placement="top"
                          // @ts-expect-error
                          target={managerRatingLearnMoreRef}
                        >
                          <FormattedMessage
                            id="app.views.person.person_performance.trajectory.history_chart.manager_rating.learn_more"
                            defaultMessage="{firstName} has received {rating} manager rating <bold>{cycles} out of the {total}</bold> previous cycles."
                            values={{
                              firstName: props.firstName,
                              // @ts-expect-error
                              rating: managerRatingSummary.type,
                              // @ts-expect-error
                              cycles: managerRatingSummary.cycles,
                              // @ts-expect-error
                              total: managerRatingSummary.total,
                              bold: (chunks) => (
                                <span className="fw-bold">{chunks}</span>
                              ),
                            }}
                          />
                        </UncontrolledPopover>
                      </>
                    ),
                  }}
                />
              ) : (
                <>
                  <FormattedMessage
                    id="app.views.person.person_performance.trajectory.history_chart.how_to_read_contents.network_recognition"
                    defaultMessage="Recognition is the number of Influence and Gold Star nominations from {firstName}'s colleagues. Network recognition typically increases with tenure, as shown by the 'company average' trendline. <a>Learn more</a>"
                    values={{
                      firstName: props.firstName,
                      a: (chunks) => (
                        <>
                          <a
                            className="text-link"
                            // @ts-expect-error
                            ref={networkRecognitionLearnMoreRef}
                          >
                            {chunks}
                          </a>
                          <UncontrolledPopover
                            trigger={'hover click'}
                            placement="top"
                            // @ts-expect-error
                            target={networkRecognitionLearnMoreRef}
                          >
                            <FormattedMessage
                              id="app.views.person.person_performance.trajectory.history_chart.network_recognition.learn_more"
                              defaultMessage="Recognition is the number of people who reported being energized or advised by you, plus the number of people who gave you a Gold Star for making an outstanding impact. It's important to remember that certain roles or groups may be more likely to receive recognition than others. <p>What if I'm below the trend line?</p> The absence of recognition isn't a sign of poor performance! If you feel your recognition is low relative to the quality of your contributions, talk to your manager. You may find opportunities to increase visibility or impact for your team. <p>What if I'm above the trend line?</p> More people have identified you as a source of advice, energy, or impact relative to the average employee. Employees who are more tenured or more senior generally receive more recognition. <br></br> Don't expect recognition to increase with every cycle. While recognition increases with tenure for the average employee, yours may increase or decrease from one period to the next."
                              values={{
                                p: (chunks) => (
                                  <p className="fw-bold mt-3 mb-1">{chunks}</p>
                                ),
                                br: () => <br />,
                              }}
                            />
                          </UncontrolledPopover>
                        </>
                      ),
                    }}
                  />
                  <hr />
                  <h6 className="text-muted text-uppercase mt-4 mb-2">
                    <FormattedMessage
                      id="app.views.person.person_performance.trajectory.history_chart.graph_key"
                      defaultMessage="Key"
                    />
                  </h6>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="col-auto">
                          <div
                            className="d-inline-block align-middle bg-primary"
                            style={{ width: '20px', height: '4px' }}
                          />
                        </Col>
                        <Col className="px-0 col-auto">
                          <div className="d-inline-block align-middle">
                            {props.name}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-auto">
                          <img
                            className="d-inline-block align-middle"
                            src={alertImage}
                            width={20}
                            height={20}
                          />
                        </Col>
                        <Col className="px-0 col-auto">
                          <div className="d-inline-block align-middle">
                            {' '}
                            <FormattedMessage
                              id="app.views.person.person_performance.trajectory.history_chart.role_change"
                              defaultMessage="Role change"
                            />
                          </div>
                        </Col>
                      </Row>
                      {props.includeAverage && (
                        <Row>
                          <Col className="col-auto">
                            <div
                              className="d-inline-block align-middle bg-warning me-2"
                              style={{
                                width: '6px',
                                height: '4px',
                              }}
                            />
                            <div
                              className="d-inline-block align-middle bg-warning"
                              style={{
                                width: '6px',
                                height: '4px',
                              }}
                            />
                          </Col>
                          <Col className="px-0 col-auto">
                            <div className="d-inline-block align-middle">
                              <FormattedMessage
                                id="app.views.person.person_performance.trajectory.history_chart.company_average"
                                defaultMessage="Company average"
                              />
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const PerformanceHistoryChart_propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.object.isRequired,
  data2: PropTypes.array,
  options: PropTypes.object.isRequired,
  individualAlerts: PropTypes.array,
  cycleDatapoints: PropTypes.array,
  callback: PropTypes.func,
  overlaps: PropTypes.object,
  name: PropTypes.string,
  includeAverage: PropTypes.bool,
  managerView: PropTypes.bool,
  personId: PropTypes.number,
  chartType: PropTypes.string.isRequired, // "rating" | "recognition"
  firstName: PropTypes.string.isRequired,
  organization: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof PerformanceHistoryChart_propTypes>;

export default PerformanceHistoryChart;
