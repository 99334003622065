import * as consts from '../../consts/consts';

import {
  INPUT_TYPES,
  getPeopleOrAllQuery,
} from '../../views/Widgets/Inputs/ValidatedInputTypes';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { OrganizationSettings } from 'types';
import { PERSON_METADATA_NON_SENSITIVE_FIELD_IDS } from 'views/Widgets/People/Table/commons';
import React from 'react';
import RichTextViewer from '../../views/Widgets/Inputs/RichTextViewer';
import { YYYY_MM_DD_WITH_DASHES_FORMAT } from '../../views/Widgets/Inputs/DatePicker';
import { isValidGoogleDocUrl } from '../util/util';

// Should match models.py in backend
export const PACKET_STATUS_DRAFT = {
  id: 'D',
  // NOTE: sort order 1 is reserved for when someone has nominated
  // for promotion BUT has not yet created a packet at all
  sortOrder: 2,
  name: 'draft',
  headingPlain: 'Draft',
  heading: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.heading.draft"
      defaultMessage="Draft"
    />
  ),
  icon: consts.ICONS.DRAFT,
  textColor: 'text-muted',
  description: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.description.draft"
      defaultMessage="Packet drafted but not yet submitted"
    />
  ),
};

export const PACKET_STATUS_PENDING = {
  id: 'P',
  sortOrder: 3,
  name: 'pending',
  headingPlain: 'Pending',
  heading: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.heading.pending"
      defaultMessage="Pending"
    />
  ),
  icon: consts.ICONS.PENDING,
  textColor: 'text-warning',
  description: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.description.pending"
      defaultMessage="Submitted but pending approval or denial"
    />
  ),
};

export const PACKET_STATUS_APPROVED = {
  id: 'A',
  sortOrder: 4,
  name: 'approved',
  headingPlain: 'Approved',
  heading: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.heading.approved"
      defaultMessage="Approved"
    />
  ),
  icon: consts.ICONS.APPROVED,
  textColor: 'text-success',
  description: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.description.approved"
      defaultMessage="Promotion approved"
    />
  ),
};

export const PACKET_STATUS_DENIED = {
  id: 'E',
  sortOrder: 5,
  name: 'denied',
  headingPlain: 'Denied',
  heading: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.heading.denied"
      defaultMessage="Denied"
    />
  ),
  icon: consts.ICONS.DENIED,
  textColor: 'text-danger',
  description: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.description.denied"
      defaultMessage="Promotion denied"
    />
  ),
};

export const PACKET_STATUS_WITHDRAWN = {
  id: 'W',
  sortOrder: 6,
  name: 'withdrawn',
  headingPlain: 'Withdrawn',
  heading: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.heading.withdrawn"
      defaultMessage="Withdrawn"
    />
  ),
  icon: consts.ICONS.WITHDRAWN,
  textColor: 'text-muted',
  description: (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packets.description.withdrawn"
      defaultMessage="Packet withdrawn"
    />
  ),
};

export const PACKET_STATUSES = [
  PACKET_STATUS_DRAFT,
  PACKET_STATUS_PENDING,
  PACKET_STATUS_APPROVED,
  PACKET_STATUS_DENIED,
  PACKET_STATUS_WITHDRAWN,
];

const MANAGEMENT_ROLE_OPTIONS = [
  {
    id: 'N',
    name: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.management_role_options.no"
        defaultMessage="No"
      />
    ),
  },
  {
    id: 'Y',
    name: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.management_role_options.yes"
        defaultMessage="Yes"
      />
    ),
  },
];

const PROMO_PACKET_COMMON_INPUTS = [
  {
    required: true,
    name: 'configs_is_management_role',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.configs_is_management_role"
        defaultMessage="Is this promotion for a people management role?"
      />
    ),
    type: INPUT_TYPES.BUTTON_OPTIONS,
    className: 'w-100',
    buttonClassName: 'btn-sm',
    selectedButtonClassName: 'btn-sm',
    options: MANAGEMENT_ROLE_OPTIONS,
  },
  {
    required: true,
    name: 'configs_new_level_id',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.configs_new_level_id"
        defaultMessage="New level"
      />
    ),
    helperText: (
      <>
        <FormattedMessage
          id="app.utils.models.promotion_packets.see_leveling_framework_for_guidance"
          defaultMessage="See <link>leveling framework</link> for guidance."
          values={{
            link: (chunks) => (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="/leveling-framework"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </>
    ),
  },
  {
    required: true,
    name: 'configs_is_job_family_change',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.configs_is_job_family_change"
        defaultMessage="Is this a job family change?"
      />
    ),
    helperText: (
      <>
        <FormattedMessage
          id="app.utils.models.promotion_packets.most_promotions_are_not"
          defaultMessage="Most promotions are not job family changes. See <link>leveling framework</link> for guidance."
          values={{
            link: (chunks) => (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="/leveling-framework"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </>
    ),
    type: INPUT_TYPES.MULTIPLE_CHOICE,
    className: 'w-100',
    buttonClassName: 'btn-sm',
    selectedButtonClassName: 'btn-sm',
    options: [
      {
        id: 'N',
        name: (
          <FormattedMessage
            id="app.utils.models.configs_is_job_family_change.option.no"
            defaultMessage="No"
          />
        ),
      },
      {
        id: 'Y',
        name: (
          <FormattedMessage
            id="app.utils.models.configs_is_job_family_change.option.yes"
            defaultMessage="Yes, this is a job family change."
          />
        ),
      },
    ],
  },
  {
    name: 'configs_new_title',
    helperText: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.helper_text.configs_new_title"
        defaultMessage="Optional, only if non-standard title is needed."
      />
    ),
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.configs_new_title"
        defaultMessage="New business title"
      />
    ),
  },
];

export const getCurrentJobFamilyLabel = (jobFamily) => {
  return (
    <span>
      <FormattedMessage
        id="app.utils.models.promotion_packets.is_this_a_job_family_change"
        defaultMessage="Is this a job family change{jobFamily, select, null {} other {, away from <bold>{jobFamily}</bold>}}?"
        values={{
          jobFamily: jobFamily,
          bold: (chunks) => <span className="fw-bold">{chunks}</span>,
        }}
      />
    </span>
  );
};

export const PROMO_PACKET_COMMENT_FIELDS = [
  {
    type: INPUT_TYPES.MENTION_EDITOR,
    name: 'comment',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.comment"
        defaultMessage="Comments"
      />
    ),
    minRows: 2,
    helperText: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.helper_text.comment"
        defaultMessage="Optional"
      />
    ),
  },
];

const PROMO_PACKET_TEMPLATE_INPUTS = (formatMessage) => [
  {
    draftOnly: true,
    required: true,
    name: 'configs_packet_url',
    label: formatMessage({
      id: 'app.utils.models.promotion_packets.label.configs_packet_url',
      defaultMessage:
        'Do you need to share this packet outside the normal chain of command?',
    }),
    helperText: formatMessage({
      id: 'app.utils.models.promotion_packets.helper_text.configs_packet_url',
      defaultMessage:
        'You can create a Google doc to manage sharing manually if so.',
    }),
    type: INPUT_TYPES.MULTIPLE_CHOICE,
    className: 'w-100',
    buttonClassName: 'btn-sm',
    selectedButtonClassName: 'btn-sm',
    allowCustom: true,
    customOptionText: formatMessage({
      id: 'app.utils.models.promotion_packets.configs_packet_url.option.yes',
      defaultMessage: 'Yes, use google doc',
    }),
    customOptionLabel: formatMessage({
      id: 'app.utils.models.promotion_packets.custom_option_label.configs_packet_url',
      defaultMessage:
        'Create a google doc for the packet and put the url here:',
    }),
    customOptionPlaceholder: 'https://docs.google.com/...',
    options: [
      {
        id: 'N',
        name: formatMessage({
          id: 'app.utils.models.promotion_packets.configs_packet_url.option.no',
          defaultMessage: 'No, use universal template',
        }),
      },
    ],
  },
];

export const PROMO_PACKET_INITIAL_SUBMIT_INPUTS = (formatMessage) => [
  {
    required: true,
    type: INPUT_TYPES.SELECT,
    name: 'candidate_person',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.candidate_person"
        defaultMessage="Candidate"
      />
    ),
    clearable: false,
    elasticsearchOptions: {
      index: 'people',
      url: 'get-people-by-name',
      // @ts-expect-error
      getQuery: (q) => getPeopleOrAllQuery(q, ['job_family']),
    },
  },
  ...PROMO_PACKET_COMMON_INPUTS,
  ...PROMO_PACKET_TEMPLATE_INPUTS(formatMessage),
];

export const PROMO_PACKET_DRAFT_TO_PENDING_SUBMIT_INPUTS = [
  ...PROMO_PACKET_COMMON_INPUTS,
];

export const PROMO_PACKET_DENIAL_SUBMIT_INPUTS = [
  {
    type: INPUT_TYPES.SELECT,
    name: 'previous_reviewer_person',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.previous_reviewer_person_deny"
        defaultMessage="Who do you attest is denying?"
      />
    ),
    helperText: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.helper_text.previous_reviewer_person_deny"
        defaultMessage="If you are the official denier, leave this blank."
      />
    ),
    clearable: false,
    elasticsearchOptions: {
      index: 'people',
      url: 'get-people-by-name',
      getQuery: getPeopleOrAllQuery,
    },
  },
  ...PROMO_PACKET_COMMENT_FIELDS,
];

const PROMO_PACKET_ADMIN_ONLY_FIELDS = [
  {
    adminOnly: true,
    type: INPUT_TYPES.SELECT,
    name: 'configs_new_job_code',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.configs_new_job_code"
        defaultMessage="New job code"
      />
    ),
    helperText: (
      <>
        <FormattedMessage
          id="app.utils.models.promotion_packets.see_leveling_framework_for_guidance"
          defaultMessage="See <link>leveling framework</link> for guidance."
          values={{
            link: (chunks) => (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="/leveling-framework"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </>
    ),
  },
  {
    adminOnly: true,
    name: 'configs_new_location',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.configs_new_location"
        defaultMessage="New location"
      />
    ),
    helperText: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.helper_text.configs_new_location"
        defaultMessage="If no change, leave blank."
      />
    ),
  },
  {
    adminOnly: true,
    required: true,
    type: INPUT_TYPES.DATE_PICKER,
    format: YYYY_MM_DD_WITH_DASHES_FORMAT,
    name: 'configs_new_position_effective_date',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.configs_new_position_effective_date"
        defaultMessage="Promotion effective date"
      />
    ),
  },
];

export const PROMO_PACKETS_BULK_APPROVAL_SUBMIT_INPUTS = [
  {
    type: INPUT_TYPES.SELECT,
    name: 'previous_reviewer_person',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.previous_reviewer_person"
        defaultMessage="Who do you attest is approving?"
      />
    ),
    helperText: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.helper_text.previous_reviewer_person"
        defaultMessage="If you are the official approver, leave this blank."
      />
    ),
    clearable: false,
    elasticsearchOptions: {
      index: 'people',
      url: 'get-people-by-name',
      getQuery: getPeopleOrAllQuery,
    },
  },
];

export const PROMO_PACKETS_NON_BULK_APPROVAL_SUBMIT_INPUTS = [
  {
    type: INPUT_TYPES.SELECT,
    name: 'next_reviewer_person',
    label: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.label.next_reviewer_person"
        defaultMessage="Who needs to approve next, if anyone?"
      />
    ),
    helperText: (
      <FormattedMessage
        id="app.utils.models.promotion_packets.helper_text.next_reviewer_person"
        defaultMessage="If this is the final approval, leave this blank."
      />
    ),
    clearable: false,
    elasticsearchOptions: {
      index: 'people',
      url: 'get-people-by-name',
      getQuery: getPeopleOrAllQuery,
    },
  },
];

export const PROMO_PACKET_APPROVAL_SUBMIT_INPUTS = [
  ...PROMO_PACKETS_BULK_APPROVAL_SUBMIT_INPUTS,
  ...PROMO_PACKETS_NON_BULK_APPROVAL_SUBMIT_INPUTS,
  ...PROMO_PACKET_COMMON_INPUTS,
  ...PROMO_PACKET_ADMIN_ONLY_FIELDS,
  ...PROMO_PACKET_COMMENT_FIELDS,
];

export const PROMO_PACKET_WITHDRAW_INPUTS = [...PROMO_PACKET_COMMENT_FIELDS];

export const PROMO_PACKET_UNDO_APPROVAL_INPUTS = [
  ...PROMO_PACKET_COMMENT_FIELDS,
];

export const PROMO_PACKET_UNDO_DENIAL_INPUTS = [...PROMO_PACKET_COMMENT_FIELDS];

// TODO: only show admin fields if admin
export const PROMO_PACKET_DRAFT_EDIT_INPUTS = (formatMessage) => [
  ...PROMO_PACKET_COMMON_INPUTS,
  ...PROMO_PACKET_TEMPLATE_INPUTS(formatMessage),
];

export const PROMO_PACKET_NON_DRAFT_EDIT_INPUTS_IS_ADMIN = [
  ...PROMO_PACKET_COMMON_INPUTS,
  ...PROMO_PACKET_ADMIN_ONLY_FIELDS,
];

export const PROMO_PACKET_NON_DRAFT_EDIT_INPUTS_IS_NOT_ADMIN = [
  ...PROMO_PACKET_COMMON_INPUTS,
];

const formatCustomPromoPacketQuestions = (questions) => {
  return questions.map((q) => ({
    ...q,
    // use RichTextViewer for label and helperText which could contain html
    label: <RichTextViewer model={q.label} expanded={true} />,
    helperText: <RichTextViewer model={q.helperText} expanded={true} />,
  }));
};

export const getEnabledInputsForOrganization = (
  inputs,
  isDraft,
  isAdmin,
  promoPacketOrgSettings: OrganizationSettings
) => {
  // if custom configs_* questions defined in org, replace all config_*
  // questions with the customConfigQuestions provided
  let customConfigsQuestions =
    promoPacketOrgSettings?.default_promotion_packet_questions;

  // go through customConfigsQuestions and ensure that each question starts with configs_*
  // else throw error and just return inputs
  if (
    customConfigsQuestions &&
    customConfigsQuestions.find((q) => !q.name.startsWith('configs_'))
  ) {
    console.error(
      'Custom configs questions must start with configs_*, falling back to defaults'
    );
    return inputs;
  }

  if (!customConfigsQuestions) {
    return inputs;
  }

  // get index of first configs_* question (as they all go together)
  const firstConfigsQuestionIndex = inputs.findIndex((input) =>
    input.name.startsWith('configs_')
  );

  // if no config questions, return inputs as is
  if (firstConfigsQuestionIndex === -1) {
    return inputs;
  }

  // get non-config questions (those before and after any questions
  // that start with "configs_")
  const nonConfigQuestions = inputs.filter(
    (i) => !i.name.startsWith('configs_')
  );

  if (!isDraft) {
    customConfigsQuestions = customConfigsQuestions.filter((q) => !q.draftOnly);
  }

  if (!isAdmin) {
    customConfigsQuestions = customConfigsQuestions.filter((q) => !q.adminOnly);
  }

  // insert at firstConfigsQuestionIndex the customConfigsQuestions
  // and return
  return [
    ...nonConfigQuestions.slice(0, firstConfigsQuestionIndex),
    ...formatCustomPromoPacketQuestions(customConfigsQuestions),
    ...nonConfigQuestions.slice(firstConfigsQuestionIndex),
  ];
};

export const transformPacketConfigs = (obj, isForward) => {
  const outputObj = {};

  if (isForward) {
    // move .config items into _config fields for editing
    Object.keys(obj).forEach((key) => {
      if (key === 'configs') {
        Object.keys(obj[key]).forEach((configKey) => {
          outputObj['configs_' + configKey] = obj[key][configKey];
        });
      } else {
        outputObj[key] = obj[key];
      }
    });
  } else {
    // move configs_* items into .config object
    // @ts-expect-error
    outputObj.configs = {};
    Object.keys(obj).forEach((key) => {
      if (key.startsWith('configs_')) {
        // @ts-expect-error
        outputObj.configs[key.replace('configs_', '')] = obj[key];
      } else {
        outputObj[key] = obj[key];
      }
    });
  }

  return outputObj;
};

const expandPersonMetadata = (p) => {
  return Object.keys(p ?? {}).reduce((acc: object, key: string) => {
    if (PERSON_METADATA_NON_SENSITIVE_FIELD_IDS.includes(key)) {
      return { ...acc, [key]: p[key] };
    }
    return acc;
  }, {});
};

export const preparePacketForFrontend = (p) => {
  if (!p) {
    return null;
  }

  return {
    ...transformPacketConfigs(p, true),
    candidate_person: p.candidate_person ? p.candidate_person : undefined,
    ...expandPersonMetadata(p.candidate_person),
    author_person: p.author_person ? p.author_person : undefined,
    previous_reviewer_person: p.previous_reviewer_person
      ? p.previous_reviewer_person
      : undefined,
    next_reviewer_person: p.next_reviewer_person
      ? p.next_reviewer_person
      : undefined,
  };
};

// we save as Y or N to the database to allow for using
// custom options or other custom strings if needed on a
// per client basis
const isManagementRoleAsYOrN = (levelId, levelOptions) => {
  return levelOptions?.find((l) => l.id === levelId)?.type === 'management'
    ? 'Y'
    : 'N';
};

export const promoPacketsTransformObjectBeforeSubmit = (
  p,
  customLevelOptions
) => {
  const objToSubmit = {
    ...transformPacketConfigs(
      {
        ...p,
        configs_is_management_role:
          p.configs_new_level_id &&
          isPromoPacketManagementRoleInferredFromOptions(customLevelOptions)
            ? isManagementRoleAsYOrN(p.configs_new_level_id, customLevelOptions)
            : p.configs_is_management_role,
      },
      false
    ),
    candidate_person: p.candidate_person ? p.candidate_person.id : undefined,
    author_person: p.author_person ? p.author_person.id : undefined,
    comments: undefined,

    // set reviewers to null instead of undefined if they exist
    // in the object so that if they are being cleared out, this
    // is passed in to the backend
    // NOTE: we call "parseInt" here for resilience just in case the ids
    // for next reviewer are set to strings in the org settings
    previous_reviewer_person: p.previous_reviewer_person
      ? parseInt(p.previous_reviewer_person.id)
      : p.previous_reviewer_person === null
      ? null
      : undefined,
    next_reviewer_person: p.next_reviewer_person
      ? parseInt(p.next_reviewer_person.id)
      : p.next_reviewer_person === null
      ? null
      : undefined,
  };

  // HACK to prevent saving of wrong data
  // @ts-expect-error
  if (objToSubmit.configs?.new_job_code?.constructor === Object) {
    return {
      ...objToSubmit,
      configs: {
        // @ts-expect-error
        ...objToSubmit.configs,
        // @ts-expect-error
        new_job_code: objToSubmit.configs?.new_job_code.object,
      },
    };
  }

  // set description to undefined so we don't overwrite the description
  // the user provided when saving
  // @ts-expect-error
  objToSubmit.description = undefined;

  return objToSubmit;
};

export const onValidatePromotionPacket = (
  formatMessage,
  obj,
  isPromoPacketManagementRoleInferred,
  promoPacketOrgSettings: OrganizationSettings
) => {
  const errors = {};

  if (!isPromoPacketManagementRoleInferred && !obj.configs_is_management_role) {
    // TODO: make "required" flag work for options input instead of this custom check
    errors['configs_is_management_role'] = formatMessage({
      id: 'app.utils.models.promotion_packets.error.configs_is_management_role',
      defaultMessage: 'Select an option.',
    });
  }

  const fieldIsInQuestionsList = (fieldName) => {
    return (
      promoPacketOrgSettings?.default_promotion_packet_questions &&
      promoPacketOrgSettings.default_promotion_packet_questions.find(
        (q) => q.name === fieldName
      )
    );
  };

  // ensure there is a new level provided if either no custom questions are provided
  // (meaning, by default, level is included), OR the custom questions contain the
  // configs_new_level_id question
  if (
    !obj.configs_new_level_id &&
    (!promoPacketOrgSettings?.default_promotion_packet_questions ||
      fieldIsInQuestionsList('configs_new_level_id'))
  ) {
    // TODO: make "required" flag work for options input instead of this custom check
    errors['configs_new_level_id'] = formatMessage({
      id: 'app.utils.models.promotion_packets.error.configs_new_level_id',
      defaultMessage: 'Choose a new level.',
    });
  }

  // if there was a job code before and this is an approval, ensure there is a job
  // code provided after
  if (
    obj.status === PACKET_STATUS_APPROVED.id &&
    fieldIsInQuestionsList('configs_new_job_code') &&
    obj.configs_job_code &&
    !obj.configs_new_job_code
  ) {
    // TODO: make "required" flag work for options input instead of this custom check
    errors['configs_new_job_code'] = formatMessage({
      id: 'app.utils.models.promotion_packets.error.configs_new_job_code',
      defaultMessage: 'Select an option.',
    });
  }

  if (
    fieldIsInQuestionsList('configs_packet_url') &&
    obj.configs_packet_url &&
    obj.configs_packet_url !== 'N' &&
    !isValidGoogleDocUrl(obj.configs_packet_url)
  ) {
    errors['configs_packet_url'] = formatMessage({
      id: 'app.utils.models.promotion_packets.error.configs_packet_url',
      defaultMessage:
        'Enter a valid Google Doc URL (https://docs.google.com/...).',
    });
  }

  return errors;
};

// we accept string like "E1", "E2", "M4", "M5", etc., OR, to hide
// the "is people manager" option so it's selected automatically based
// on the type, we also accept objects, e.g.
// {"id": "E1", "name": "E1 - individual contributor", "type": "exempt"},
// {"id": "M4", "name": "M4 - people manager", "type": "management"},
// etc.
export const mapPromoPacketsCustomLevelOption = (o) =>
  typeof o === 'string' ? { id: o, name: o } : o;

// if the org provides level options where each option
// is an object with type "management", "exempt", or "nonexempt",
// then we can infer the answer to this question and thus can
// set it and not ask the user explicitly
export const isPromoPacketManagementRoleInferredFromOptions = (options) => {
  if (!(options?.length > 0)) {
    return false;
  }

  // every item in this list must be an object with one of the types below
  // for us to consider every option as inferring a management role
  return (
    options.findIndex(
      (o) =>
        typeof o !== 'object' ||
        (o.type !== 'exempt' &&
          o.type !== 'nonexempt' &&
          o.type !== 'management')
    ) === -1
  );
};
