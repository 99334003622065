import { Badge, Button, List, UncontrolledPopover } from 'reactstrap';
import {
  ESObjective,
  ObjectiveWithRef as Objective,
  statusDescriptorFor,
} from '../../utils/models/Objective';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ButtonWithIcon from '../../components/ButtonWithIcon/ButtonWithIcon';
import { DATE_PARAMETER } from './ObjectivesTimeFrameSelector';
import { Link } from 'react-router-dom';
import { Person } from '../../types';
import ReactTagsInput from '../Widgets/Inputs/ReactTagsInput';
import { getDatePart } from '../../utils/util/time';
import { getQuarter } from '../../utils/models/Activity';
import { yyyymmddToLocalDate } from '../../utils/util/util';

const getObjectiveUrl = (obj) => {
  const owner = obj.collaborators[0];
  const startTimeframe = obj.coverage_start_date;
  const key = obj.key;
  return `${owner.url}/objectives?${DATE_PARAMETER}=${startTimeframe}#key=${key}`;
};

// Show objectives linked to the current
//   1. if there are none, show call to action
//   2. if there are some, show tags + icon to link more
//   3. when click on icon / call to action, replace with ReactTagsInput
//      a. on save, go back to normal display
//         1) optimistically add new linked objective before getting server response
//         2) if api call fails, show toast and remove objective that failed to link

interface Props {
  id: string;
  textIfEmpty: string;
  prefixIfPresent?: string;
  coverageStartDate?: Date | string;
  coverageEndDate?: Date | string;
  objectives?: Objective[] | ESObjective[];
  owner_person: Person;
  callback: (objectives: Objective[]) => void;
  isEditable: boolean;
  maxElements?: number;
  isParentObjective?: boolean;
  targetObjectiveKey?: string;
}

const LinkedObjectives: FC<Props> = ({
  id,
  textIfEmpty,
  prefixIfPresent,
  coverageStartDate = new Date(),
  coverageEndDate = new Date(),
  objectives = [],
  owner_person,
  callback = (e) => console.log(`LinkedObjective callback: ${e}`),
  isEditable,
  maxElements = 1000,
  isParentObjective = false,
  targetObjectiveKey,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isAutofocus, setIsAutofocus] = useState(false);
  const [currentInput, setCurrentInput] = useState('');
  const badgeRef = useRef<HTMLDivElement>(document.createElement('div'));
  const intl = useIntl();
  const { formatMessage } = intl;

  const onBadgeClick = useCallback(() => {
    if (isEditable) {
      setIsEditing(true);
      return;
    }
  }, [isEditable]);

  const coverage_start_date = useMemo(() => {
    if (!coverageStartDate) {
      return '';
    } else if (typeof coverageStartDate !== 'string') {
      return getDatePart(coverageStartDate);
    } else {
      return coverageStartDate;
    }
  }, [coverageStartDate]);

  const coverage_end_date = useMemo(() => {
    if (!coverageEndDate) {
      return '';
    } else if (typeof coverageEndDate !== 'string') {
      return getDatePart(coverageEndDate);
    } else {
      return coverageEndDate;
    }
  }, [coverageEndDate]);

  const tags = useMemo(
    () =>
      objectives.map((x) => ({
        id: x.id,
        _index: 'objectives',
        name: x.name,
        object: x,
        url: getObjectiveUrl(x),
      })),
    [objectives]
  );

  const placeholderPopover = useMemo(() => {
    if (currentInput?.length > 0) {
      return '';
    }

    const quarterName = getQuarter(yyyymmddToLocalDate(coverage_start_date));

    return (
      <UncontrolledPopover placement="top" trigger="hover legacy" target={id}>
        <>
          <FormattedMessage
            id="app.views.widget.inputs.objective_input.placeholder.popover.text"
            defaultMessage="Search by the objective's name or the owner's name. Only
                            objectives from {quarterName} can be linked."
            values={{ quarterName }}
          />
        </>
      </UncontrolledPopover>
    );
  }, [coverage_start_date, currentInput, id]);

  // needed to set autofocus after clicking
  useEffect(() => {
    if (isEditing) {
      setIsAutofocus(true);
    }
  }, [isEditing]);

  if (isEditing)
    return (
      <>
        <div
          id={id}
          className={`fw-normal pt-1 text-primary small ${
            !prefixIfPresent ? 'mb-n3' : ''
          }`}
        >
          <ReactTagsInput
            label={<i className="fe fe-link me-1" />}
            id={id}
            className="border-0 p-0 d-inline-block mt-n2 mb-n2"
            allowNew={false}
            allowDuplicateCreate={true}
            deliverEmptyInputChange={true}
            minQueryLength={1}
            elasticsearchOptions={{
              url: 'get-objectives-for-linking/v2',
              index: 'objectives',
              getQuery: (q) => ({
                query: q,
                owner_person_id: owner_person.id,
                coverage_start_date: coverage_start_date,
                coverage_end_date: coverage_end_date,
                tags: tags.map((x) => x.id),
                apply_feature_filters: true,
                target_objective_key: targetObjectiveKey,
              }),
            }}
            callback={(e) => {
              setIsEditing(false);
              setCurrentInput('');
              callback(e.map((x) => x.object));
            }}
            value={tags}
            placeholder={formatMessage({
              id: 'app.views.widget.inputs.objective_input.linked_objectives.tag.editing.placeholder',
              defaultMessage: 'Search objective',
            })}
            noSuggestionsText={formatMessage({
              id: 'app.views.widget.inputs.objective_input.linked_objectives.tag.editing.no_suggestion_text',
              defaultMessage: 'No objectives found',
            })}
            autoFocus={isAutofocus}
            // essential: by default ReactTags filters out elastic search results
            // where the query is not in the objective name (undocumented behavior)
            // this breaks finding objectives by name of the owner
            // https://github.com/i-like-robots/react-tags/blob/main/lib/ReactTags.js#L76
            suggestionsFilter={() => true}
            onInputChange={(q) => setCurrentInput(q)}
            excludeTagMatchFunction={(x) => x.id}
            onBlur={() => setIsEditing(false)}
            disableLengthCheck={true}
            // we want to increase odds of discoverability
            // if searching by a person's name (note: we raise
            // the default limit on the backend as well)
            maxSuggestionsLength={10}
            maxTags={maxElements}
          />
        </div>
        {placeholderPopover}
      </>
    );

  if (tags.length > 0)
    return isParentObjective ? (
      <div
        className="fw-normal small pb-2 d-inline-flex mw-100 mt-1"
        style={{ marginLeft: '23px' }}
      >
        {prefixIfPresent && (
          <span className="text-muted d-inline-block">{prefixIfPresent}</span>
        )}

        <Link
          to={tags[0].url}
          target="_blank"
          className="d-inline-block text-truncate ps-2"
          style={{ maxWidth: '65%' }}
        >
          {tags[0].name}
        </Link>

        {isEditable && (
          <i
            className="fe fe-edit text-primary align-baseline p-0 ps-2 btn d-inline"
            style={{ lineHeight: 1 }}
            onClick={() => setIsEditing(true)}
          />
        )}
      </div>
    ) : (
      <>
        <Badge
          color="info"
          className="bg-info-soft objective-list--linked-badge align-self-center position-relative"
          id={`linked-badge-${id}`}
          onClick={onBadgeClick}
          innerRef={badgeRef}
        >
          <FormattedMessage
            id="app.views.widget.inputs.objective_input.linked_objectives.tag.not_editing.count"
            defaultMessage="{tags} related {tags, plural, one {item} other {items}}"
            values={{
              tags: tags.length,
            }}
          />
        </Badge>
        <UncontrolledPopover
          placement="top"
          target={badgeRef}
          trigger="hover legacy"
          delay={{ show: 60, hide: 300 }}
        >
          <List
            className="mx-2  my-1 d-flex gap-2 flex-column "
            type="unstyled"
          >
            {tags.map((tag) => {
              const badgeColor =
                statusDescriptorFor(tag.object.status, intl)?.color ??
                'success';
              return (
                <li key={tag.url} className="d-flex ps-2 position-relative">
                  <span
                    className={`bg-${badgeColor} d-inline-block align-bottom me-2 position-absolute`}
                    style={{ width: '2px', height: '100%', left: '-2px' }}
                  />
                  <Link to={tag.url} target="_blank">
                    {tag.name}
                  </Link>
                </li>
              );
            })}
          </List>
        </UncontrolledPopover>
      </>
    );

  if (isEditable)
    return isParentObjective ? (
      <Button
        color="link"
        size="sm"
        style={{ marginLeft: '15px' }}
        onClick={() => setIsEditing(true)}
      >
        {textIfEmpty}
      </Button>
    ) : (
      <ButtonWithIcon
        icon="link"
        color="link"
        className="ms-n2"
        onClick={() => setIsEditing(true)}
      >
        {textIfEmpty}
      </ButtonWithIcon>
    );
  return <></>;
};

export default LinkedObjectives;
