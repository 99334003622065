import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes, { InferProps } from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { getQuarter, getTimeframeText } from '../../utils/models/Activity';

import ActivityList from './ActivityList';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import FilterInput from '../Widgets/Search/FilterInput';
import { FormattedMessage } from 'react-intl';
import Loading from '../Widgets/Loading';
import PageError from '../Layout/Pages/Errors/PageError';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

const sortByReverseDate = (a, b) => {
  // @ts-expect-error
  return new Date(b.date_completed) - new Date(a.date_completed);
};

const ActivitiesDirectory: React.FC<Props> = (props: Props) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [activities, setActivities] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filterName, setFilterName] = useState('');

  // default to list view (which is basically "resume" view)
  const [isTimeView, setIsTimeView] = useState(true);
  const toggleIsTimeView = () => setIsTimeView(!isTimeView);

  const { user } = useAuth0();
  const userSub = user?.sub;
  // @ts-expect-error
  const currentOrgId = props.currentOrganization?.id;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const MAX_TO_SHOW = 9999;

    if (userSub && currentOrgId) {
      const queryParams = {
        size: MAX_TO_SHOW,
        sort: [
          {
            'name.raw': 'asc',
          },
        ],
        source_includes: [
          'id',
          'name',
          'date_completed',
          'date_started',
          'visibility',
          'type',

          'contributions.id',
          'contributions.contributor_role',

          // NOTE: these should match BASIC_PERSON_FIELDS
          'contributions.contributor_person.id',
          'contributions.contributor_person.url',
          'contributions.contributor_person.avatar',
          'contributions.contributor_person.given_name',
          'contributions.contributor_person.family_name',
          'contributions.contributor_person.full_name',
          'contributions.contributor_person.status',
          'contributions.contributor_person.title',
          'contributions.contributor_person.email',
        ],
      };

      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        filterName ? undefined : userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        currentOrgId,
        'get-activities-by-name',
        {
          name: filterName ? filterName : undefined,
          ...queryParams,
        },
        (hits) => {
          if (isMounted) {
            const newActivities = hits.map((h) => h._source);
            setActivities(newActivities);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
    // @ts-expect-error
  }, [isMounted, userSub, filterName, currentOrgId, props.currentProxyPerson]);

  const activitiesByQuarter = useMemo(() => {
    return isTimeView
      ? (activities ? [...activities] : [])
          .sort(sortByReverseDate)
          .reduce((arr, a) => {
            // @ts-expect-error
            const q = getTimeframeText(a?.date_completed);

            // @ts-expect-error
            if (typeof arr[q] === 'undefined') {
              // @ts-expect-error
              arr[q] = [a];
            } else {
              // @ts-expect-error
              arr[q] = [...arr[q], a];
            }

            return arr;
          }, {})
      : null;
  }, [isTimeView, activities]);

  const activitiesContent = useMemo(
    () => (
      <>
        {
          // @ts-expect-error
          activities?.length === 0 && (
            <div className="text-center mb-4">
              <span className="text-muted">
                <FormattedMessage
                  id="app.views.activity_directory.activity.no_result.text"
                  defaultMessage="No results found"
                />
              </span>
            </div>
          )
        }
        {
          // @ts-expect-error
          activities?.length > 0 && (
            <ActivityList
              activities={activities}
              inModal={props.inModal}
              hideEmojis={true}
              hideImages={true}
              hideContributions={true}
              hideDescriptions={true}
            />
          )
        }
      </>
    ),
    [activities, props.inModal]
  );

  if (errorMessage) {
    console.error('Could not load all activities: ' + errorMessage);
    return <PageError message={errorMessage} />;
  }

  if (typeof activities === 'undefined') {
    return <Loading />;
  }

  const onChangeFilter = (e) => {
    setFilterName(e.target.value);
  };

  return (
    <Row className="justify-content-md-center">
      {/*<Col md={4} className="order-1 order-md-2">
        {!props.inModal && (
          <ActivityListCard
            title="Recommended activities"
            loaderFunction={(p, c, e) =>
              ElasticsearchAPI.getTopActivities(user?.sub, currentOrgId, p, c, e)
            }
            hideDescriptions={true}
            emptyState="You have no recommended teams."
          />
        )}
      </Col>*/}
      <Col md={props.inModal ? 12 : 8} className="order-2 order-md-1">
        <Row>
          <Col>
            <FilterInput
              value={filterName}
              onChange={onChangeFilter}
              // @ts-expect-error
              autoFocus={props.inModal}
            />
          </Col>
          <Col className="col-auto">
            <div className="btn-group" role="group">
              <Button
                onClick={toggleIsTimeView}
                color={isTimeView ? 'light' : 'white'}
                className="btn btn-sm2"
              >
                <span className="fe fe-clock"></span>
              </Button>
              <Button
                onClick={toggleIsTimeView}
                color={isTimeView ? 'white' : 'light'}
                className="btn btn-sm2"
              >
                <span className="fe fe-list"></span>
              </Button>
            </div>
          </Col>
        </Row>
        {props.inModal && <div className="mt-4">{activitiesContent}</div>}
        {!props.inModal && (
          <>
            {!isTimeView && (
              <Card className="mt-4 mb-0">
                <CardBody>{activitiesContent}</CardBody>
              </Card>
            )}
            {isTimeView &&
              // @ts-expect-error
              Object.keys(activitiesByQuarter).map((key, index) => {
                // @ts-expect-error
                const activitiesForQuarter = activitiesByQuarter[key];

                return (
                  <Fragment key={index}>
                    <div
                      className={
                        'text-center text-muted mb-3' +
                        (index === 0 ? ' mt-4' : '')
                      }
                    >
                      {getQuarter(key)}
                    </div>
                    <Card>
                      <CardBody>
                        {!(activitiesForQuarter?.length > 0) && (
                          <div className="text-center">
                            <span className="text-muted">
                              <FormattedMessage
                                id="app.views.activity_directory.activity.body.key.title"
                                defaultMessage="No activities were completed in {activityKey}."
                                values={{ activityKey: key }}
                              />
                            </span>
                          </div>
                        )}
                        {activitiesForQuarter?.length > 0 && (
                          <ActivityList
                            activities={activitiesForQuarter}
                            hideEmojis={true}
                            hideImages={true}
                            hideContributions={true}
                            hideDescriptions={true}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </Fragment>
                );
              })}
          </>
        )}
      </Col>
    </Row>
  );
};

ActivitiesDirectory.defaultProps = {
  inModal: false,
};

type Props = InferProps<typeof ActivitiesDirectory_propTypes>;

const ActivitiesDirectory_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  inModal: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(ActivitiesDirectory));
