import React, { ErrorInfo, FC } from 'react';
import { Modal, type ModalProps } from 'reactstrap';

import ErrorBoundary from '../views/Error/ErrorBoundary';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const ToastError: FC<{ error: any }> = ({ error }) => {
  const { formatMessage } = useIntl();

  if (error?.domRemovedError) {
    toast.error(
      formatMessage({
        id: 'app.hacks.error.with.special.instructions.modal.toast.text',
        defaultMessage:
          'Please disable your language browser extension. Language plugins like Google Translate change the data in Confirm and causes it to break. Disable the extension, refresh, and try again. You can set your preferred language from your account page if your organization has enabled that language as an option. If you do not see your language, contact your HR representative to ask for support in Confirm for that language.',
      })
    );
  } else {
    toast.error(
      formatMessage({
        id: 'app.components.error.safe_modal.generic.error.toast.text',
        defaultMessage:
          'An error occurred. Please try again later or contact customer support.',
      })
    );
  }
  return <div />;
};
const toastErrorFn = (error?: Error, _errorInfo?: ErrorInfo) => (
  <ToastError error={error} />
);

const SafeModal: FC<ModalProps> = ({ children, ...otherProps }) => {
  return (
    <ErrorBoundary fallback={toastErrorFn}>
      <Modal {...otherProps}>{children}</Modal>
    </ErrorBoundary>
  );
};

export default SafeModal;
