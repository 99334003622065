import {
  ADMIN_ONLY_PERSON_FIELDS,
  formatNamesForCsv,
  peopleArrayCountThenNameSort,
  peopleObjectsAreEqual,
} from '../../../../utils/models/Person';
import {
  getUserLocalStorage,
  setUserLocalStorage,
} from '../../../../utils/models/User';
import { useEffect, useState } from 'react';

import { BASIC_PERSON_FIELDS } from '../../Inputs/ValidatedInputTypes';
import { CAMPAIGN_STATUSES } from '../../../../utils/models/Campaign';
import ElasticsearchAPI from '../../../../utils/api/ElasticsearchAPI';
import { ICONS } from '../../../../consts/consts';
import { type IntlShape } from 'react-intl';
import { Person } from 'types';
import { comparePeople } from '../../../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';

export const FORMAT_AVATAR_WITH_TITLE = 'at';
export const FORMAT_AVATAR_ONLY = 'ao';
export const FORMAT_AVATAR_GROUP = 'ag';

const BELOW_MANAGER_FILTER_PREFIX = 'below_';

export const ADDITIONAL_FILTERS_KEY = 'additionalFilters';

const itemsForFieldNameAreEqual = (fieldName, a, b, column) => {
  if (!a && !b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  if (column.isPerson) {
    return peopleObjectsAreEqual(a, b);
  }

  return a === b;
};

export const rowMatchesFilter = (row, filter) => {
  if (
    (filter.isPerson || filter._index === 'people') &&
    row[filter._index] &&
    filter?.object
  ) {
    return peopleObjectsAreEqual(row[filter._index], filter?.object);
  } else {
    return itemsForFieldNameAreEqual(
      filter._index,
      row[filter._index],
      filter.object,
      filter
    );
  }
};

const rowMatchesFilters = (row, filters, opts) => {
  if (!(filters?.length > 0)) {
    return true;
  }

  // group filters by type
  const filtersByIndex = filters.reduce((acc, f) => {
    if (f._index in acc) {
      acc[f._index].push(f);
    } else {
      acc[f._index] = [f];
    }
    return acc;
  }, {});

  // look at each filter type separately
  for (const filtersForType of Object.values(filtersByIndex)) {
    // if any of the filters succeeds, this filter type succeeds (i.e. "OR")
    // @ts-expect-error
    const thisFilterTypeMatches = filtersForType.some((f) =>
      opts?.customRowMatchesFilter
        ? opts.customRowMatchesFilter(row, f)
        : rowMatchesFilter(row, f)
    );

    // if any filter type fails, then the whole match fails
    if (!thisFilterTypeMatches) {
      return false;
    }
  }

  // only if all of the filter types succeed, the whole match succeeds (i.e. "AND")
  return true;
};

const filteredRows = ({ rows, includeFilters, excludeFilters, opts }) => {
  // only process the below when we have everything we need
  if (!(rows?.length > 0)) {
    return [];
  }

  // filter by any filter tags set
  if (!(includeFilters?.length > 0) && !(excludeFilters?.length > 0)) {
    return rows;
  }

  const includedRows =
    includeFilters?.length > 0
      ? rows.filter((r) => rowMatchesFilters(r, includeFilters, opts))
      : rows;

  const includedPeopleWithExcludes =
    excludeFilters?.length > 0
      ? includedRows.filter((r) => !rowMatchesFilters(r, excludeFilters, opts))
      : includedRows;

  return includedPeopleWithExcludes;
};

const getIconForFieldName = (fieldName) => {
  switch (fieldName) {
    case 'title':
      return ICONS.TITLE_FILTER;
    case 'people':
    case 'manager':
    case 'manager_or_above':
    case 'final_rating_provided_by_person':
      // allow for PersonCard avatar icon to take precedence
      return undefined;

    // feature-related fields
    case 'pulse_checks':
      return ICONS.PULSE_CHECKS;
    case 'status':
      return ICONS.ACTIVITY;
    case 'feedback':
      return ICONS.FEEDBACK;
    case 'recognition':
      return ICONS.RECOGNITION;
    case 'objective':
      return ICONS.OBJECTIVE;
    case 'aspiration':
      return ICONS.ASPIRATION;
    case 'rating':
      return ICONS.PERFORMANCE;
    case 'direct_reports':
      return ICONS.DIRECT_REPORTS;
    case 'one_on_ones':
      return ICONS.ONE_ON_ONES;
    case 'operating_manual':
      return ICONS.OPERATING_MANUAL;

    // Team dashboard-related fields
    case 'level':
    case 'level_id':
      return ICONS.LEVEL_FILTER;
    case 'function':
    case 'function_id':
      return ICONS.FUNCTION_FILTER;
    case 'cost_center':
    case 'cost_center_id':
      return ICONS.COST_CENTER_FILTER;
    case 'business_unit':
    case 'business_unit_id':
      return ICONS.BUSINESS_UNIT_FILTER;
    case 'department':
    case 'department_id':
      return ICONS.DEPARTMENT_FILTER;
    case 'final_rating_text':
      return ICONS.FINAL_RATING_FILTER;
    case 'location':
    case 'country':
      return ICONS.LOCATION_FILTER;
    case 'hrbp':
      return ICONS.HRBP_FILTER;
    case 'leader':
      return ICONS.LEADER_FILTER;
    case 'calibration_text':
    case 'calibration_status':
      return ICONS.CALIBRATION_FILTER;
  }

  return undefined;
};

const fallbackIfMissingSurveyResponse = (row) => {
  // Get the data from the survey response config attached to the person
  // rather than the person object
  if (!('surveyResponseConfig' in row) || !row.surveyResponseConfig) {
    console.warn(
      'Filtering questions without the survey response config data, falling back to person data.',
      row
    );
    return row;
  }
  return row.surveyResponseConfig;
};

const surveyResponseRowMatchesFilter = (row, filter) => {
  const target = fallbackIfMissingSurveyResponse(row);

  return itemsForFieldNameAreEqual(
    filter._index,
    target[filter._index],
    filter.object,
    filter
  );
};

const applyCustomFiltersToQuestions = ({
  questions,
  campaign,
  targetPerson,
}) => {
  const isDemoOrPreviewMode = campaign?.status === CAMPAIGN_STATUSES.DEMO;

  return (
    questions?.filter((it) => {
      const filteredQuestions = filteredRows({
        rows: targetPerson ? [targetPerson] : [],
        includeFilters: it?.filters?.include ?? [],
        excludeFilters: it?.filters?.exclude ?? [],
        opts: { customRowMatchesFilter: surveyResponseRowMatchesFilter },
      });
      return isDemoOrPreviewMode || !it.filters || filteredQuestions.length;
    }) ?? null
  );
};

const FILTERING_STORAGE_KEY = 'orgPeopleFiltering';

const usePeopleInOrganizationForFiltering = ({
  currentOrganization,
  currentProxyPerson,
}) => {
  const { user } = useAuth0();
  const userSub = user?.sub;
  const currentOrgId = currentOrganization?.id;
  const [error, setError] = useState(null);
  const [people, setPeople] = useState(() => {
    return getUserLocalStorage(
      userSub,
      currentProxyPerson,
      currentOrgId,
      FILTERING_STORAGE_KEY,
      true
    );
  });

  useEffect(() => {
    if (!people && userSub && currentOrgId) {
      // cache for 1 hour locally
      const ALL_PEOPLE_CACHE_MS = 1000 * 60 * 60;
      // this is a hard limit, should fetch paginated if orgs with > 9999 people
      const MAX_PEOPLE_TO_SHOW = 9999;
      ElasticsearchAPI.getPeopleByName(
        null, // disabled API cache to cache the trasnformed objects directly using localstorage
        currentProxyPerson,
        currentOrgId,
        queryParams(MAX_PEOPLE_TO_SHOW, {
          unwrap_source: 'true',
        }),
        (hitSources) => {
          const transformedPeople = transformPeopleForFilters(hitSources);
          setUserLocalStorage(
            userSub,
            currentProxyPerson,
            currentOrgId,
            FILTERING_STORAGE_KEY,
            transformedPeople,
            // @ts-expect-error
            ALL_PEOPLE_CACHE_MS
          );
          setPeople(transformedPeople);
        },
        (message) => {
          console.error(
            'Error fetching people in organization for filtering',
            message
          );
          setError(message);
        },
        ALL_PEOPLE_CACHE_MS
      );
    }
    // @ts-expect-error
  }, [people, userSub, user.email, currentOrgId, currentProxyPerson]);

  return [people, error];
};

const transformPeopleForFilters = (people) => {
  if (!Array.isArray(people)) {
    return [];
  }

  return people.map((p) => ({
    ...p,
    person: p,
    // add field for each person above in chain of command for filtering
    // by a person's full team
    ...(p.chain_of_command_list
      ? p.chain_of_command_list.reduce(
          (dict, p) => ({
            ...dict,
            [BELOW_MANAGER_FILTER_PREFIX + p.id]: p,
          }),
          {}
        )
      : {}),
  }));
};
function queryParams(maxPeopleToShow, extraParams) {
  return {
    size: maxPeopleToShow,
    sort: [
      {
        status: {
          unmapped_type: 'keyword',
          order: 'asc',
        },
        'full_name.raw': {
          unmapped_type: 'keyword',
          order: 'asc',
        },
      },
    ],
    source_includes: [
      ...BASIC_PERSON_FIELDS,
      ...ADMIN_ONLY_PERSON_FIELDS,
      'manager',
      // for showing "full team" filters
      'chain_of_command_list',
    ],
    ...extraParams,
  };
}

const generateAggregateColumns = (
  managersList,
  columnsThatHaveAtLeastOneDatapointInRows,
  formatMessage,
  showAdditionalManagers = false
) => {
  return [
    {
      name: formatMessage({
        id: 'app.widgets.task.person',
        defaultMessage: 'Person',
      }),
      field: 'person',
      isPerson: true,
      format: FORMAT_AVATAR_WITH_TITLE,
      csvName: ['Name', 'Email', 'Title', 'Given Name', 'Family Name'],
      csvFormat: (c) => ({
        Name: c.full_name,
        Email: c.email,
        Title: c.title,
        'Given Name': c.given_name,
        'Family Name': c.family_name,
      }),
      getFilterDisplayValue: (p) => p.full_name,
      sort: (a, b) => comparePeople(a?.person, b?.person),
    },
    {
      name: formatMessage({
        id: 'app.widgets.task.manager.abbreviated.header',
        defaultMessage: 'Mgr',
      }),
      field: 'manager',
      isPerson: true,
      format: FORMAT_AVATAR_ONLY,
      csvFormat: (p) => p.email || '',
      getFilterDisplayValue: (p) => p.full_name,
      nameTransformerFunction: (p) =>
        formatMessage(
          {
            id: 'app.widgets.task.name_as_manager',
            defaultMessage: '{name} as manager',
          },
          { name: p.full_name }
        ),
      ifEmpty: '-',
      popoverContent: formatMessage({
        id: 'app.widgets.task.manager',
        defaultMessage: 'Manager',
      }),
      sort: (a, b) => comparePeople(a?.manager, b?.manager),
    },
    ...(showAdditionalManagers
      ? [
          {
            name: formatMessage({
              id: 'app.widgets.task.additional_managers.abbreviated.header',
              defaultMessage: 'Additional Mgr(s)',
            }),
            field: 'additional_managers',
            format: FORMAT_AVATAR_GROUP,
            multi: true,
            csvFormat: formatNamesForCsv,
            sort: (a, b) =>
              peopleArrayCountThenNameSort(
                a.additional_managers,
                b.additional_managers
              ),
          },
        ]
      : []),
    // DEPRECATED
    // map fields for filtering for each person in the chain of command
    ...managersList.map((p) => ({
      filterOnly: true,
      name: formatMessage(
        {
          id: 'app.widgets.task.full_team_of_manager',
          defaultMessage: "{name}'s full team",
        },
        { name: p.full_name }
      ),
      field: BELOW_MANAGER_FILTER_PREFIX + p.id,
      isPerson: true,
      getFilterDisplayValue: (p) => p.full_name,
      nameTransformerFunction: () =>
        formatMessage(
          {
            id: 'app.widgets.task.full_team_of_manager',
            defaultMessage: "{name}'s full team",
          },
          { name: p.full_name }
        ),
    })),
    ...columnsThatHaveAtLeastOneDatapointInRows,
  ];
};

export const getItemDescriptorForFullTeamFilter = (
  manager: Person,
  formatMessage: IntlShape['formatMessage']
) => ({
  key: BELOW_MANAGER_FILTER_PREFIX + manager.id,
  descriptor: {
    filterOnly: true,
    name: formatMessage(
      {
        id: 'app.widgets.task.full_team_of_manager',
        defaultMessage: "{name}'s full team",
      },
      { name: manager.full_name }
    ),
    field: BELOW_MANAGER_FILTER_PREFIX + manager.id,
    isPerson: true,
    getFilterDisplayValue: (manager) => manager.full_name,
    nameTransformerFunction: () =>
      formatMessage(
        {
          id: 'app.widgets.task.full_team_of_manager',
          defaultMessage: "{name}'s full team",
        },
        { name: manager.full_name }
      ),
  },
});

export const getSuggestionsForAdditionalFilter = (
  key,
  descriptor,
  fieldValue
) => ({
  _index: key,
  isPerson: descriptor.isPerson,
  nameTransformerFunction: descriptor.nameTransformerFunction,
  name:
    typeof descriptor.getFilterDisplayValue === 'function'
      ? descriptor.getFilterDisplayValue(fieldValue)
      : fieldValue,
  icon: undefined,
  description: descriptor.filterDescription
    ? descriptor.filterDescription
    : descriptor.name,
  object:
    typeof descriptor.getFilterValue === 'function'
      ? descriptor.getFilterValue(fieldValue)
      : fieldValue,
});

export {
  BELOW_MANAGER_FILTER_PREFIX,
  itemsForFieldNameAreEqual,
  filteredRows,
  getIconForFieldName,
  generateAggregateColumns,
  applyCustomFiltersToQuestions,
  usePeopleInOrganizationForFiltering,
};
