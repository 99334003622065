import { Button, Col, Row } from 'reactstrap';
import { CAMPAIGN_STATUSES, getPhaseByType } from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PHASE_TYPE_EVALUATION,
  getDirectReportsEligibleForFeedback,
  getStepNumber,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../utils/models/Performance';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';

import PeopleInputForm from '../../assets/img/illustrations/people-input-form.png';
import PerformancePage from './PerformancePage';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { type RouteComponentProps } from 'react-router-dom';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { toPersonIdToSurveyResponseLookup } from 'utils/models/SurveyResponse';

const PerformanceStepEvaluationPhaseWelcome: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const campaign = props.campaign;

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const propsDemoPeople = props.demoPeople;

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(
          updateLatestPerfStep(
            props.currentPerfSurveyResponse,
            currentStepNumber
          )
        );
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          history,
          propsDemoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      campaign,
      history,
      propsDemoPeople,
      formatMessage,
    ]
  );

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? // @ts-expect-error
          props.currentPerfSurveyResponse.id
        : undefined,
      // @ts-expect-error
      campaign: campaign.id,
      step: currentStepNumber,
      responses: {},
    }),
    // @ts-expect-error
    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse]
  );

  const evaluationPhaseStartDateString = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_EVALUATION)?.start_date,
    [campaign]
  );

  const evaluationPhaseEndDateString = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_EVALUATION)?.end_date,
    [campaign]
  );

  const hasDirectReports = useMemo(
    () =>
      campaign &&
      getDirectReportsEligibleForFeedback(
        props.me,
        campaign,
        // @ts-expect-error
        campaign?.relationships,
        // @ts-expect-error
        toPersonIdToSurveyResponseLookup(campaign?.survey_responses),
        props.demoPeople
      )?.length > 0,
    [campaign, props.demoPeople, props.me]
  );

  const evaluationPhaseHasOpened = useMemo(
    () =>
      // @ts-expect-error
      campaign?.status === CAMPAIGN_STATUSES.DEMO ||
      new Date(evaluationPhaseStartDateString) <= new Date(),
    // @ts-expect-error
    [evaluationPhaseStartDateString, campaign?.status]
  );

  return (
    <PerformancePage
      campaign={campaign}
      title={
        hasDirectReports
          ? formatMessage({
              id: 'app.views.performance.performance_step_evaluation_phase_welcome.start_manager_review_phase',
              defaultMessage: 'Start manager review phase',
            })
          : formatMessage({
              id: 'app.views.performance.performance_step_evaluation_phase_welcome.no_eligible_direct_reports_no_direct_reports',
              defaultMessage: 'No eligible direct reports',
            })
      }
    >
      <Row>
        <Col className="col-12 pb-3 pb-md-0 col-md-4">
          <img src={PeopleInputForm} className="w-100 pe-5" />
        </Col>
        <Col className="col-md-8">
          {!hasDirectReports && (
            <div className="mb-4 mt-4">
              <FormattedMessage
                id="app.views.performance.performance_step_evaluation_phase_welcome.no_eligible_direct_reports"
                defaultMessage="
              You do not have any direct reports eligible for feedback this
              cycle. If you believe this is a mistake, please contact your HR
              representative to fix this.
            "
              />
            </div>
          )}
          {hasDirectReports && (
            <>
              <div className="mb-4 mt-4">
                <FormattedMessage
                  id="app.views.performance.performance_step_evaluation_phase_welcome.what_next"
                  defaultMessage="
                Next, you'll review the performance of each your direct
                reports and provide your own comments.
              "
                />
              </div>
              <div className="mb-4">
                <FormattedMessage
                  id="app.views.performance.performance_step_evaluation_phase_welcome.you_have_until_date"
                  defaultMessage="You will have until {date} to complete your
                responses. You can revise them any time until then.
              "
                  values={{
                    date: getPrettyDate({
                      dateString: evaluationPhaseEndDateString,
                      locale,
                    }),
                  }}
                />
              </div>
              {!evaluationPhaseHasOpened && (
                <span>
                  <Button color="primary" disabled={true}>
                    <FormattedMessage
                      id="app.views.performance.performance_step_evaluation_phase_welcome.button.continue"
                      defaultMessage="
                    Continue
                  "
                    />
                  </Button>
                  <span className="text-muted ms-3">
                    <FormattedMessage
                      id="app.views.performance.performance_step_evaluation_phase_welcome.phase_starts_on"
                      defaultMessage="
                    This phase starts on {date}."
                      values={{
                        date: getPrettyDate({
                          dateString: evaluationPhaseStartDateString,
                          locale,
                        }),
                      }}
                    />
                  </span>
                </span>
              )}
              {evaluationPhaseHasOpened && (
                <ValidatedForm
                  buttonClassName="mt-0"
                  method={object.id ? 'PATCH' : 'POST'}
                  url={
                    // @ts-expect-error
                    campaign.status === CAMPAIGN_STATUSES.DEMO
                      ? undefined
                      : 'survey-responses'
                  }
                  buttonIsBlock={false}
                  object={object}
                  inputs={[]}
                  callback={callback}
                  submitText={formatMessage({
                    id: 'app.views.performance.performance_step_evaluation_phase_welcome.submit_text.continue',
                    defaultMessage: 'Continue',
                  })}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </PerformancePage>
  );
};

const PerformanceStepEvaluationPhaseWelcome_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = PropTypes.InferProps<
  typeof PerformanceStepEvaluationPhaseWelcome_propTypes
> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformanceStepEvaluationPhaseWelcome)));
