import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import AvatarGroup from '../Widgets/People/AvatarGroup';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import { FormattedMessage } from 'react-intl';
import Modal from '../../components/SafeModal';
import PropTypes from 'prop-types';
import Question from '../Widgets/Questions/Question';
import RelativeTime from '../Widgets/RelativeTime';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';

const ModalFeedbackRequestSelector: FC<Props> = (props) => {
  const [selectedFeedbackRequest, setSelectedFeedbackRequest] = useState(null);
  const [fetchedFeedbackRequestList, setFetchedFeedbackRequestList] =
    useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    // @ts-expect-error
    if (!userSub || !props.currentOrganization?.id || !props.isOpen) {
      return;
    }

    setFetchedFeedbackRequestList(undefined);
    ElasticsearchAPI.getSentFeedbackRequests(
      userSub,
      // @ts-expect-error
      props.currentProxyPerson,
      // @ts-expect-error
      props.currentOrganization?.id,
      (feedbackRequests) => {
        // filter to only those with custom questions on them
        const feedbackRequestsWithQuestions = feedbackRequests.filter(
          (fr) => fr.custom_questions?.length > 0
        );

        setFetchedFeedbackRequestList(feedbackRequestsWithQuestions);
      },
      (message) => {
        setErrorMessage(message);
      }
    );
  }, [
    // @ts-expect-error
    props.currentOrganization?.id,
    // @ts-expect-error
    props.currentProxyPerson,
    props.isOpen,
    userSub,
  ]);

  const propsCallback = props.callback;
  const propsToggle = props.toggle;
  const callback = useCallback(() => {
    // @ts-expect-error
    propsCallback(selectedFeedbackRequest);
    // @ts-expect-error
    propsToggle();
  }, [propsCallback, propsToggle, selectedFeedbackRequest]);

  const feedbackRequestOnClick = useCallback((feedbackRequest) => {
    setSelectedFeedbackRequest(feedbackRequest);
  }, []);

  // @ts-expect-error
  const orgSpecificTemplatesList = props?.settings?.feedback_request_templates;

  // if either templates or previous requests exists, set
  // first available as default
  useEffect(() => {
    // by default, select first one
    // @ts-expect-error
    if (fetchedFeedbackRequestList?.length > 0) {
      // @ts-expect-error
      setSelectedFeedbackRequest(fetchedFeedbackRequestList[0]);
    } else if (orgSpecificTemplatesList?.length > 0) {
      setSelectedFeedbackRequest(orgSpecificTemplatesList[0]);
    }
  }, [fetchedFeedbackRequestList, orgSpecificTemplatesList]);

  const hasAnythingAvailableToImport =
    // @ts-expect-error
    fetchedFeedbackRequestList?.length > 0 ||
    orgSpecificTemplatesList?.length > 0;

  const feedbackRequestList = useMemo(() => {
    if (!hasAnythingAvailableToImport) {
      return null;
    }

    const templatesList = orgSpecificTemplatesList ?? [];

    return (
      <>
        {templatesList?.length > 0 && (
          <>
            <h6 className="text-uppercase text-muted mb-2">
              <FormattedMessage
                id="app.views.feedback.modal_feedback_request_selector.templates"
                defaultMessage="Templates"
              />
            </h6>
            <ListGroup
              className="mb-4"
              style={{ maxHeight: '30rem', overflowY: 'auto' }}
            >
              {templatesList?.map((template, i) => {
                return (
                  <ListGroupItem
                    key={template?.id || i}
                    role="button"
                    className={
                      selectedFeedbackRequest === template
                        ? 'active'
                        : undefined
                    }
                    onClick={() => feedbackRequestOnClick(template)}
                  >
                    <Row className="align-items-center">
                      <Col>
                        <div onClick={() => feedbackRequestOnClick(template)}>
                          {template.name}
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </>
        )}
        <h6 className="text-uppercase text-muted mb-2">
          <FormattedMessage
            id="app.views.feedback.modal_feedback_request_selector.previous_requests"
            defaultMessage="Previous requests"
          />
        </h6>
        <ListGroup style={{ maxHeight: '30rem', overflowY: 'auto' }}>
          {/* @ts-expect-error */}
          {!(fetchedFeedbackRequestList?.length > 0) && (
            <>
              <FormattedMessage
                id="app.views.feedback.modal_feedback_request_selector.no_request_sent"
                defaultMessage="No requests sent"
              />
            </>
          )}
          {/* @ts-expect-error */}
          {fetchedFeedbackRequestList?.map((feedbackRequest) => {
            return (
              <ListGroupItem
                key={feedbackRequest.id}
                role="button"
                className={
                  selectedFeedbackRequest === feedbackRequest
                    ? 'active'
                    : undefined
                }
                onClick={() => feedbackRequestOnClick(feedbackRequest)}
              >
                <Row className="align-items-center">
                  <Col
                    className="col-auto"
                    style={{ position: 'relative', top: '-2px' }}
                  >
                    <AvatarGroup
                      size="xxs"
                      people={feedbackRequest.subject_people}
                    />
                  </Col>
                  <Col className="px-0">
                    <div
                      onClick={() => feedbackRequestOnClick(feedbackRequest)}
                    >
                      <RelativeTime
                        unit="day"
                        datetime={feedbackRequest.created_at}
                      />
                    </div>
                  </Col>
                </Row>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </>
    );
  }, [
    feedbackRequestOnClick,
    fetchedFeedbackRequestList,
    hasAnythingAvailableToImport,
    selectedFeedbackRequest,
    orgSpecificTemplatesList,
  ]);

  const feedbackRequestQuestionsList = useMemo(() => {
    if (!selectedFeedbackRequest) {
      return null;
    }

    // @ts-expect-error
    return selectedFeedbackRequest.custom_questions?.map((question) => (
      <Question
        key={question.name}
        value={{
          name: 'feedback_request_preview_' + question.name,
          ...question,
        }}
        disabled={true}
      />
    ));
  }, [selectedFeedbackRequest]);

  const loadOrRenderOutput = loadOrRender(
    fetchedFeedbackRequestList,
    errorMessage
  );

  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return (
    // @ts-expect-error
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      {/* @ts-expect-error */}
      <ModalHeader toggle={props.toggle}>
        <FormattedMessage
          id="app.views.feedback.modal_feedback_request_selector.import_previous_request"
          defaultMessage="Import from a template or previous request"
        />
      </ModalHeader>
      <ModalBody>
        {!hasAnythingAvailableToImport && (
          <FormattedMessage
            id="app.views.feedback.modal_feedback_request_selector.no_feedback_requests"
            defaultMessage="You have no previous feedback requests with custom questions."
          />
        )}
        {hasAnythingAvailableToImport && (
          <Row>
            <Col className="col-4">{feedbackRequestList}</Col>
            <Col className="col-8">{feedbackRequestQuestionsList}</Col>
          </Row>
        )}
        <Row>
          <Col className="col-12">
            <Button
              className="mt-4 d-block w-100"
              color="primary"
              onClick={callback}
              disabled={!hasAnythingAvailableToImport}
            >
              <FormattedMessage
                id="app.views.feedback.modal_feedback_request_selector.import_questions"
                defaultMessage="Import questions"
              />
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

const ModalFeedbackRequestSelector_propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  callback: PropTypes.func,
};

type Props = PropTypes.InferProps<
  typeof ModalFeedbackRequestSelector_propTypes
>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, settings } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    settings,
  };
};

export default connect(mapStateToProps)(
  React.memo(ModalFeedbackRequestSelector)
);
