import * as consts from '../../consts/consts';

import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { FormattedMessage, useIntl, type IntlShape } from 'react-intl';
import { AuthUser, Me, InitOrganization, ReduxState } from '../../types';
import {
  INPUT_ATTRIBUTES,
  INPUT_TYPES,
} from '../Widgets/Inputs/ValidatedInputTypes';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SiApple,
  SiGoogle,
  SiLinkedin,
  SiMicrosoft,
  SiYahoo,
} from 'react-icons/si';
import { updateMe } from '../../actions';
import {
  useConfirmIntl,
  useLanguageSettings,
} from '../../locale/ConfirmIntlContext';

// @ts-expect-error
import { IntercomAPI } from '../../vendor/react-intercom';
import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import PersonAvatarEditor from '../Widgets/People/PersonAvatarEditor';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// input objects are currently hard to type,
// they are too dynamic for now atm
// {
//   // Fixed keys.
//   name: string;
//   label: string;
//   helperText: string;
//   switches: Array<{ name: string }>;
//   // Optional keys.
//   type: string;
//   objects: any;
//   defaultValue: any;
//   isHorizontalLayout: true;
//   labelClassName: string;
// }
type InputT = any;

const headers = (currentOrganization: InitOrganization): React.ReactElement => {
  const instant_messaging_cols = {
    slack: (
      <Col className="col-auto pe-1">
        <i className="fe fe-slack me-2" />
        <FormattedMessage
          id="app.views.account.notifications.headers.slack"
          defaultMessage="Slack"
        />
      </Col>
    ),
    msteams: (
      <Col className="col-auto pe-1">
        <i className="fe fe-message-square me-2" />
        <FormattedMessage
          id="app.views.account.notifications.headers.teams"
          defaultMessage="Teams"
        />
      </Col>
    ),
  };

  let instant_messaging = null;
  if (currentOrganization.instant_messaging) {
    instant_messaging =
      instant_messaging_cols[currentOrganization.instant_messaging];
  }

  return (
    <ListGroupItem key="headers" className="pb-0 border-bottom-0">
      <Row className="align-items-center">
        <Col>
          <Row className="align-items-center">
            <Col></Col>
            <Row xs="1" sm="12" className="justify-content-end">
              {instant_messaging}
              <Col className="col-auto pe-3">
                <i className="fe fe-mail me-2" />
                <FormattedMessage
                  id="app.views.account.notifications.headers.email"
                  defaultMessage="Email"
                />
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

const renderNotificationsFormInputs = (
  inputs,
  currentOrganization
): React.ReactElement => {
  return (
    <ListGroup className="list-group-flush my-n3">
      {headers(currentOrganization)}
      {inputs.map((input, index) => (
        <ListGroupItem key={index} className={index === 0 ? 'pt-2' : ''}>
          <Row className="align-items-center">
            <Col>{input}</Col>
          </Row>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

const CHECKED_VALUE = 'Y';
const UNCHECKED_VALUE = 'N';

const notificationOptions = (
  formatMessage: (object) => string
): Array<{ id: string; name: string }> => [
  {
    id: 'W',
    name: formatMessage({
      id: 'app.views.account.notifications.frequency.weekly',
      defaultMessage: 'Weekly',
    }),
  },
  {
    id: 'D',
    name: formatMessage({
      id: 'app.views.account.notifications.frequency.daily',
      defaultMessage: 'Daily',
    }),
  },
  {
    id: 'N',
    name: formatMessage({
      id: 'app.views.account.notifications.frequency.never',
      defaultMessage: 'Never',
    }),
  },
];

// **IMPORTANT** NOTE: CHANGING ANY OF THESE REQUIRES CHANGING THE
// BACKEND TEMPLATES (templates.py) TO MATCH
// BEGIN-NOSCAN
const switchInputs = (
  { formatMessage }: IntlShape,
  currentOrganization: InitOrganization
): Array<InputT> => {
  const instant_messaging = currentOrganization.instant_messaging;

  return [
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'feedback-requested',
      label: formatMessage({
        id: 'app.views.account.notifications.items.feedback_requested.label',
        defaultMessage: 'Feedback requested',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.feedback_requested.helper_text',
        defaultMessage: 'Someone requested feedback from you.',
      }),
      switches: [
        ...(instant_messaging
          ? [{ name: instant_messaging + '-feedback-requested' }]
          : []),
        { name: 'email-feedback-requested' },
      ],
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'feedback-received',
      label: formatMessage({
        id: 'app.views.account.notifications.items.feedback_received.label',
        defaultMessage: 'Feedback received',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.feedback_received.helper_text',
        defaultMessage: 'Someone gave you feedback or recognition.',
      }),
      switches: [
        ...(instant_messaging
          ? [{ name: instant_messaging + '-feedback-received' }]
          : []),
        { name: 'email-feedback-received' },
      ],
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'provided-contribution-comment',
      label: formatMessage({
        id: 'app.views.account.notifications.items.comment_received.label',
        defaultMessage: 'Comment received',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.comment_received.helper_text',
        defaultMessage: 'Someone commented on one of your contributions.',
      }),
      switches: [
        ...(instant_messaging
          ? [{ name: instant_messaging + '-provided-contribution-comment' }]
          : []),
        { name: 'email-provided-contribution-comment' },
      ],
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'asking-for-a-contribution',
      label: formatMessage({
        id: 'app.views.account.notifications.items.accept_credit.label',
        defaultMessage: 'Accept credit',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.accept_credit.helper_text',
        defaultMessage:
          'Someone wants to add you to an activity and give you credit.',
      }),
      switches: [
        ...(instant_messaging
          ? [{ name: instant_messaging + '-asking-for-a-contribution' }]
          : []),
        { name: 'email-asking-for-a-contribution' },
      ],
    },
    {
      name: 'email-reminder-add-contribution-details',
      label: formatMessage({
        id: 'app.views.account.notifications.items.accept_credit_reminders.label',
        defaultMessage: 'Accept credit reminders',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.accept_credit_reminders.helper_text',
        defaultMessage:
          'Reminder to accept the credit someone wants to give you on an activity.',
      }),

      type: INPUT_TYPES.SWITCH,
      defaultValue: CHECKED_VALUE,
      checkedValue: CHECKED_VALUE,
      uncheckedValue: UNCHECKED_VALUE,
      isHorizontalLayout: true,
      labelClassName: 'fw-bold',
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'new-contributor',
      label: formatMessage({
        id: 'app.views.account.notifications.items.new_contribution_on_your_activity.label',
        defaultMessage: 'New contribution on your activity',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.new_contribution_on_your_activity.helper_text',
        defaultMessage:
          'A new contribution was added to an activity you are a part of.',
      }),
      switches: [
        ...(instant_messaging
          ? [{ name: instant_messaging + '-new-contributor' }]
          : []),
        { name: 'email-new-contributor' },
      ],
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'added-to-a-team',
      label: formatMessage({
        id: 'app.views.account.notifications.items.team_invitation.label',
        defaultMessage: 'Team invitation',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.team_invitation.helper_text',
        defaultMessage: 'Someone else added you to a team.',
      }),
      switches: [
        ...(instant_messaging
          ? [{ name: instant_messaging + '-added-to-a-team' }]
          : []),
        { name: 'email-added-to-a-team' },
      ],
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'perf-direct-report-completed-phase',
      label: formatMessage({
        id: 'app.views.account.notifications.items.performance_cycle_updates.label',
        defaultMessage: 'Performance cycle updates',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.performance_cycle_updates.helper_text',
        defaultMessage:
          'A direct report completed a performance cycle phase (managers only).',
      }),
      switches: [
        ...(instant_messaging
          ? [
              {
                name: instant_messaging + '-perf-direct-report-completed-phase',
              },
            ]
          : []),
        { name: 'email-perf-direct-report-completed-phase' },
      ],
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'feedback-received-other',
      label: formatMessage({
        id: 'app.views.account.notifications.items.direct_report_feedback.label',
        defaultMessage: 'Direct report feedback',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.direct_report_feedback.helper_text',
        defaultMessage:
          'Someone gave your direct report feedback or recognition (managers only).',
      }),
      switches: [
        ...(instant_messaging
          ? [{ name: instant_messaging + '-feedback-received-other' }]
          : []),
        { name: 'email-feedback-received-other' },
      ],
    },
    {
      ...INPUT_ATTRIBUTES(formatMessage).ACCOUNT_NOTIFICATION_SWITCHBANK,
      name: 'nudges',
      label: formatMessage({
        id: 'app.views.account.notifications.items.nudges.label',
        defaultMessage: 'Nudges',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.nudges.helper_text',
        defaultMessage: 'Someone sent you a nudge.',
      }),
      switches: [
        ...(instant_messaging ? [{ name: instant_messaging + '-nudges' }] : []),
        { name: 'email-nudges' },
      ],
    },
    /*
  // disable email activity updates for now
  {
    name: 'email-activity-update',
    label: 'Activity updates',
    helperText: 'Get a summary of recent activities from coworkers.',
    type: INPUT_TYPES.DROPDOWN,
    objects: notificationOptions,
    defaultValue: notificationOptions[0].id,
  },
  */
    {
      name: 'email-reactions-notification',
      label: formatMessage({
        id: 'app.views.account.notifications.items.emoji_reactions.label',
        defaultMessage: 'Emoji reactions',
      }),
      helperText: formatMessage({
        id: 'app.views.account.notifications.items.emoji_reactions.helper_text',
        defaultMessage:
          'Get a summary of recent reactions to your activities by coworkers.',
      }),
      type: INPUT_TYPES.DROPDOWN,
      objects: notificationOptions(formatMessage),
      defaultValue: notificationOptions(formatMessage)[0].id,
      isHorizontalLayout: true,
      labelClassName: 'fw-bold',
    },
  ];
};
// END-NOSCAN

const instantMessagingUpsell = (
  { formatMessage }: IntlShape,
  currentOrganization: InitOrganization
): React.ReactElement | undefined => {
  if (currentOrganization.instant_messaging) {
    return undefined;
  }

  return (
    <CardFooter>
      <p className="text-secondary">
        {formatMessage({
          id: 'app.views.account.notifications.card_footer.instant_messaging_upsell',
          defaultMessage:
            'If you would rather receive your notifications in Slack or Microsoft Teams, reach out to your HRBP.',
        })}
      </p>
    </CardFooter>
  );
};

// "expand" the settings object that comes from the database to
// contain nested elements for each SwitchBank. the opposite of
// transformObjectBeforeSubmit()
//
// per models.py:325 the default notification setting if the
// flag is missing is true i.e. "Y"
const expandSettings = (
  intl: IntlShape,
  settings: object,
  currentOrganization: InitOrganization
): object =>
  Object.fromEntries(
    switchInputs(intl, currentOrganization).map((input: InputT) => [
      input.name,
      input.type === INPUT_TYPES.SWITCHBANK
        ? Object.fromEntries(
            input.switches.map((sw) => [
              sw.name,
              settings && settings[sw.name] !== undefined
                ? settings[sw.name]
                : 'Y',
            ])
          )
        : settings[input.name] !== undefined
        ? settings[input.name]
        : 'Y',
    ])
  );

const flattenSettings = (backingData: object): object => {
  const x = Object.entries(backingData).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      // @ts-expect-error
      acc = acc.concat(Object.entries(value));
    } else {
      // @ts-expect-error
      acc.push([key, value]);
    }
    return acc;
  }, []);

  return Object.fromEntries(x);
};

// now that SwitchInput directly writes the "flattened" switch,
// all we need to do here is remove the "expanded" objects from
// settings which were created by expandSettings, as they now
// hold out-of-date data
const transformObjectBeforeSubmit = (object) => flattenSettings(object);

interface Props {
  authUser: AuthUser;
  currentOrganization: InitOrganization;
  me: Me;
  settings: object;
  updateMe: (Me) => void;
}

const Account: React.FC<Props> = ({
  authUser,
  currentOrganization,
  me,
  settings,
  updateMe,
}) => {
  const intl = useIntl();
  const { setCurrentLocale } = useConfirmIntl();
  const languageSettings = useLanguageSettings();
  const [notificationSettings, setNotificationSettings] = useState(
    expandSettings(intl, settings, currentOrganization)
  );
  const initialNotification = useRef(true);

  const toggleSupport = () => {
    IntercomAPI('show');
  };

  useEffect(() => {
    if (settings) {
      setNotificationSettings(
        expandSettings(intl, settings, currentOrganization)
      );
    }
  }, [intl, settings, currentOrganization]);

  const notificationsCallback = useCallback(() => {
    return (data) => {
      if (data) {
        // otherwise, we always see a 'Setting saved!' for initial population of component
        if (!initialNotification.current) {
          toast.success(
            intl.formatMessage({
              id: 'app.views.account.notifications.toast.setting_saved.success',
              defaultMessage: 'Setting saved!',
            })
          );
        } else {
          initialNotification.current = false;
        }
      }
    };
  }, [intl, initialNotification]);

  const getCol12 = (element) => {
    return <Col className="col-12">{element}</Col>;
  };

  const renderForm = (inputs) => {
    return <Row>{inputs}</Row>;
  };

  const oauthProviders = ({ formatMessage }) => [
    {
      subPrefix: 'google-', // google-oauth2
      icon: <SiGoogle size={15} />,
      name: formatMessage({
        id: 'app.views.account.notifications.oauth_providers.google.name',
        defaultMessage: 'Google',
      }),
    },
    {
      subPrefix: 'linkedin-', // google-oauth2
      icon: <SiLinkedin size={15} />,
      name: formatMessage({
        id: 'app.views.account.notifications.oauth_providers.linkedin.name',
        defaultMessage: 'LinkedIn',
      }),
    },
    {
      subPrefix: 'apple-', // google-oauth2
      icon: <SiApple size={15} />,
      name: formatMessage({
        id: 'app.views.account.notifications.apple.google.name',
        defaultMessage: 'Apple',
      }),
    },
    {
      subPrefix: 'windowslive-', // google-oauth2
      icon: <SiMicrosoft size={15} />,
      name: formatMessage({
        id: 'app.views.account.notifications.oauth_providers.microsoft_live.name',
        defaultMessage: 'Microsoft (Windows Live)',
      }),
    },
    {
      subPrefix: 'yahoo-', // google-oauth2
      icon: <SiYahoo size={15} />,
      name: formatMessage({
        id: 'app.views.account.notifications.oauth_providers.yahoo.name',
        defaultMessage: 'Yahoo!',
      }),
    },
  ];

  let oauthLine;

  for (const i in oauthProviders(intl)) {
    const provider = oauthProviders(intl)[i];
    if (authUser.sub.startsWith(provider.subPrefix)) {
      oauthLine = (
        <span>
          <span
            className={'me-2'}
            style={{ position: 'relative', top: '-2px' }}
          >
            {provider.icon}
          </span>{' '}
          <FormattedMessage
            id="app.views.account.notifications.oauth_providers.head.text"
            defaultMessage="Authenticated through {providerName}"
            values={{ providerName: provider.name }}
          />
        </span>
      );
    }
  }

  if (!oauthLine) {
    // create default line re: password as this is a manual sign in (so has a username/password)
    oauthLine = (
      <span>
        <i
          className={'fe fe-lock me-2'}
          style={{ position: 'relative', top: '1px' }}
        />{' '}
        <FormattedMessage
          id="app.views.account.notifications.oauth_providers.head.fallback.text"
          defaultMessage="To change your password, log out, then click 'Forgot password?' on the login screen."
        />
      </span>
    );
  }

  const onSubmitCallback = (data) => {
    if (data) {
      // update user with profile locally for later use/reference in the app
      updateMe({
        slug: data.slug,
        url: data.url,
        locale: data.locale,
      });
      setCurrentLocale(data.locale);

      toast.success(
        intl.formatMessage({
          id: 'app.views.account.notifications.toast.subit.success',
          defaultMessage: 'Saved!',
        })
      );
    }
  };

  const tabs: Array<object> = [];

  const person = me;

  const formatMessage = intl.formatMessage;

  const propsUpdateMe = updateMe;
  const updateAvatarImageUrlCallback = useCallback(
    (newUrl) => {
      propsUpdateMe({ avatar: newUrl });
    },
    [propsUpdateMe]
  );

  const renderInputs = useCallback(
    (inputs) => {
      return (
        <>
          <Row>
            <Col className="col-auto">
              <PersonAvatarEditor
                person={person}
                callback={updateAvatarImageUrlCallback}
              />
            </Col>
            <Col className="pe-0">
              <Row>
                <Col>{inputs[0]}</Col>
                <Col className="pe-0">{inputs[1]}</Col>
              </Row>
            </Col>
          </Row>
          {inputs.slice(2)}
        </>
      );
    },
    [person, updateAvatarImageUrlCallback]
  );

  const renderNotificationsInputs = useCallback(
    (inputs) => renderNotificationsFormInputs(inputs, currentOrganization),
    [currentOrganization]
  );

  tabs.push({
    path: consts.ACCOUNT(formatMessage).path,
    name: formatMessage({
      id: 'app.views.account.tabs.general.text',
      defaultMessage: 'General',
    }),
    content: (
      <>
        <ValidatedForm
          object={{
            given_name: person.given_name,
            family_name: person.family_name,
            email: person.email,
            organization_name: currentOrganization.name,
          }}
          callback={() => null}
          renderForm={renderForm}
          renderInputs={renderInputs}
          inputs={[
            {
              disabled: true,
              name: 'given_name',
              label: formatMessage({
                id: 'app.views.account.general.first_name.text',
                defaultMessage: 'First name',
              }),
            },
            {
              disabled: true,
              name: 'family_name',
              label: formatMessage({
                id: 'app.views.account.general.last_name.text',
                defaultMessage: 'Last name',
              }),
            },
            {
              disabled: true,
              name: 'email',
              label: formatMessage({
                id: 'app.views.account.general.email.text',
                defaultMessage: 'Email',
              }),

              wrapperFunction: getCol12,
            },
            {
              disabled: true,
              name: 'organization_name',
              label: formatMessage({
                id: 'app.views.account.general.organization.text',
                defaultMessage: 'Organization',
              }),

              wrapperFunction: getCol12,
            },
          ]}
        />
        <p className="text-muted">{oauthLine}</p>
        <p className="text-muted">
          <FormattedMessage
            id="app.views.account.general.concern.text"
            defaultMessage="Have a concern or want to change something above? <link>Let us know</link>"
            values={{
              link: (text) => (
                <span
                  role="button"
                  className="text-primary"
                  onClick={toggleSupport}
                >
                  {text}
                </span>
              ),
            }}
          />
        </p>
        <hr className="my-5" />
        {!languageSettings ? (
          <Loading />
        ) : (
          <ValidatedForm
            url={'/people/' + me.id + '/preferences'}
            method="PATCH"
            object={{
              locale: me.locale,
              slug: me.slug,
            }}
            callback={onSubmitCallback}
            submitText={formatMessage({
              id: 'app.views.account.general.save.button.text',
              defaultMessage: 'Save',
            })}
            buttonClassName="btn-inline-block"
            buttonIsBlock={false}
            inputs={[
              {
                required: true,
                disabled: !languageSettings.enabled,
                name: 'locale',
                label: formatMessage({
                  id: 'app.views.account.general.locale.text',
                  defaultMessage: 'Display language',
                }),
                helperText: formatMessage({
                  id: 'app.views.account.general.locale.helper_text',
                  defaultMessage:
                    'Language for buttons, titles, emails, and other text from Confirm',
                }),
                type: INPUT_TYPES.DROPDOWN,
                objects: languageSettings.supported_locales,
                defaultValue: languageSettings.current_locale,
                wrapperFunction: getCol12,
              },
              {
                className: 'ps-3',
                name: 'slug',
                label: formatMessage({
                  id: 'app.views.account.general.edit_profile_url.label',
                  defaultMessage: 'Edit profile URL',
                }),
                helperText: formatMessage({
                  id: 'app.views.account.general.edit_profile_url.helper_text',
                  defaultMessage: 'Personalize the URL for your profile.',
                }),
                prependedText: process.env.REACT_APP_CONFIRM_APP_URL + '/',
                inputGroupTextClassName: 'bg-light',
              },
            ]}
          />
        )}
      </>
    ),
  });

  tabs.push({
    path: consts.ACCOUNT_NOTIFICATIONS(formatMessage).path,
    name: formatMessage({
      id: 'app.views.account.tabs.notifications.text',
      defaultMessage: 'Notifications',
    }),

    content: (
      <Card>
        <CardBody>
          <ValidatedForm
            object={notificationSettings}
            url="/settings"
            method="POST"
            doNotSendOrganization={true}
            callback={notificationsCallback}
            submitOnChange={true}
            renderInputs={renderNotificationsInputs}
            transformObjectBeforeSubmit={transformObjectBeforeSubmit}
            inputs={switchInputs(intl, currentOrganization)}
          />
        </CardBody>
        {instantMessagingUpsell(intl, currentOrganization)}
      </Card>
    ),
  });

  return (
    <Container className="container-lg container-fluid">
      <Row className="justify-content-center">
        <Col className="col-12 col-lg-10 col-xl-8">
          <Page
            pretitle={formatMessage({
              id: 'app.views.account.page.pre_title.text',
              defaultMessage: 'Personal',
            })}
            title={formatMessage({
              id: 'app.views.account.page.title.text',
              defaultMessage: 'Account',
            })}
            shouldRenderContainer={false}
            tabs={tabs}
          ></Page>
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = (dispatch): object => {
  return {
    updateMe: (me) => dispatch(updateMe(me)),
  };
};

const mapStateToProps = (state: ReduxState): object => {
  const { authUser, me, myConfigs, currentOrganization, currentProxyPerson } =
    state;

  return {
    authUser,
    me,
    currentOrganization,
    currentProxyPerson,
    settings: myConfigs?.settings,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Account));
