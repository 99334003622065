import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  getPersonDisplayTitleHtml,
  getUnattributedPerson,
} from '../../../utils/models/Person';

import ExternalPersonEnrichedProfileCard from '../Cards/ExternalPersonEnrichedProfileCard';
import { BasicPerson } from '../../../types';
import PersonEnrichedProfileCard from '../Cards/PersonEnrichedProfileCard';
import { useIntl } from 'react-intl';

interface Props {
  person: BasicPerson;
  size?: string;
  src?: string;
  className?: string;
  alt?: string;
  onClick?: () => void;
  style?: object;
  imageStyle?: object;
  hidePopover?: boolean;
  popoverHeader?: React.ReactNode;
  popoverChildren?: React.ReactNode;
  popoverContent?: React.ReactNode;
  popoverPlacement?: string;
  unattributedRelationshipType?: string;
  leaderboardNumber?: number;
  isExternalUrl?: boolean;
  linkToSubPath?: string;
  role?: string;
  hash?: string;
}

const UnlinkedAvatar: FC<Props> = ({ popoverPlacement = 'top', ...props }) => {
  const { formatMessage } = useIntl();
  const person =
    typeof props.person?.id === 'undefined' &&
    props.unattributedRelationshipType
      ? getUnattributedPerson(
          formatMessage,
          props.hash,
          props.unattributedRelationshipType
        )
      : props.person;
  const linkRef = useRef();
  const [ready, setReady] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, []);

  let className = props.size ? 'avatar-' + props.size : 'avatar-sm';
  if (props.className) {
    className += ' ' + props.className;
  }

  const src = props.src ? props.src : person.avatar;
  const alt = props.alt ? props.alt : '';

  const popoverHeader = props.popoverHeader
    ? props.popoverHeader
    : person.full_name;

  const popoverChildren = props.popoverChildren
    ? props.popoverChildren
    : // @ts-expect-error
      person.popoverChildren;

  const popoverContent = props.popoverContent
    ? props.popoverContent
    : // @ts-expect-error
      person.popoverContent;

  const imageStyle = props.imageStyle ? props.imageStyle : {};

  return (
    <>
      <div
        className={'avatar ' + className}
        // @ts-expect-error
        ref={linkRef}
        role={props.role}
        style={props.style}
        onClick={props.onClick}
        title={''}
        data-original-title={person.full_name}
      >
        {src && (
          <img
            alt={alt}
            className="avatar-img rounded-circle"
            style={imageStyle}
            role={props.onClick ? 'button' : undefined}
            src={src}
          />
        )}
        {!src && (
          <span
            style={imageStyle}
            className={'avatar-title rounded-circle'}
            role={props.onClick ? 'button' : undefined}
          >
            {person.given_name
              ? person.given_name.charAt(0).toUpperCase()
              : // @ts-expect-error
              person.email
              ? // @ts-expect-error
                person.email.charAt(0).toUpperCase()
              : '?'}
            {person.family_name
              ? person.family_name.charAt(0).toUpperCase()
              : ''}
          </span>
        )}
        {/* @ts-expect-error */}
        {props.leaderboardNumber >= 0 && (
          <span
            className="avatar-xxs position-absolute avatar-title rounded-circle"
            style={{ bottom: '-3px', right: '-5px', fontSize: '0.7rem' }}
          >
            {props.leaderboardNumber}
          </span>
        )}
      </div>
      {!props.hidePopover &&
        ready &&
        linkRef?.current &&
        (popoverHeader || popoverChildren || popoverContent) && (
          <Popover
            trigger="hover focus"
            // @ts-expect-error
            placement={popoverPlacement}
            isOpen={popoverOpen}
            target={linkRef.current}
            toggle={toggle}
          >
            <PopoverHeader>{popoverHeader}</PopoverHeader>
            <PopoverBody>
              {popoverChildren}
              {!popoverChildren && (
                <div>
                  {getPersonDisplayTitleHtml(formatMessage, person)}
                  {/* @ts-expect-error */}
                  {person.id && <PersonEnrichedProfileCard person={person} />}
                  {/* @ts-expect-error */}
                  {!person.id && (
                    <ExternalPersonEnrichedProfileCard person={person} />
                  )}
                </div>
              )}
              {popoverContent}
            </PopoverBody>
          </Popover>
        )}
    </>
  );
};

export default React.memo(UnlinkedAvatar);
