import {
  ACTIVITY_VISIBILITIES,
  fromLegacyVisibilityToSimplified,
} from '../../../utils/models/Activity';
import { Button, Card, CardBody, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { INPUT_ATTRIBUTES, INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import React, { useCallback, useMemo, useState } from 'react';

import { CONTRIBUTION_EDITOR_ATTRIBUTES } from '../Inputs/ValidatedInput';
import ModalActivitySelector from '../Modals/ModalActivitySelector';
import PropTypes from 'prop-types';
import ValidatedForm from '../Forms/ValidatedForm';
import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import ConfirmAPI from 'utils/api/ConfirmAPI';

const SimpleActivityEditorCard = (props) => {
  const { formatMessage } = useIntl();
  const campaign = props.campaign;
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = useCallback(
    () => setConfirmDeleteModal(!confirmDeleteModal),
    [confirmDeleteModal]
  );
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);

  const thisElementId = useMemo(
    () => `simple-activity-editor-card-${props.object.id}`,
    [props.object]
  );

  const visibilityOptions = useMemo(
    () =>
      ACTIVITY_VISIBILITIES(formatMessage).map((x) => ({
        ...x,
        name:
          x.id === 'E'
            ? formatMessage(
                {
                  id: 'app.views.widgets.cards.simple_activity_editor_card.everyone_at_organization',
                  defaultMessage: 'Everyone at {organizationName}',
                },
                { organizationName: props.organizationName }
              )
            : x.name,
      })),
    [props.organizationName, formatMessage]
  );

  const isActivityImported = useMemo(
    () => props.object.isImport,
    [props.object]
  );

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const toggleImportModal = useCallback(
    () => setIsImportModalOpen(!isImportModalOpen),
    [isImportModalOpen]
  );

  const propsPersonId = props.person.id;
  const propsCallback = props.callback;

  const importActivity = useCallback(
    (activity) => {
      const contribution = activity.contributions.find(
        (x) => x.contributor_person.id === propsPersonId
      );
      propsCallback({
        isImport: true,
        id: activity.id,
        contribution_id: contribution.id,
        description: contribution.description,
        organization: activity.organization.id,
        title: activity.name,
        visibility: activity.visibility,
        skills: contribution.skills,
      });
    },
    [propsCallback, propsPersonId]
  );

  const clearImport = useCallback(() => {
    props.callback(null);
  }, [props]);

  const titlePopoverId = useMemo(
    () => `title-${props.object.id}`,
    [props.object]
  );

  const visibilityPopoverId = useMemo(
    () => `visibility-${props.object.id}`,
    [props.object]
  );

  const onClickImport = useCallback(
    () => (isActivityImported ? clearImport() : toggleImportModal()),
    [clearImport, isActivityImported, toggleImportModal]
  );

  const inputs = useMemo(
    () => [
      {
        inputElementId: titlePopoverId,
        name: 'title',
        placeholder: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.title',
          defaultMessage: 'Title',
        }),
        required: true,
        disabled: isActivityImported,
        className: 'fw-bold',
        maxLength: 255,
        postscript: (
          <div
            className="small text-primary"
            onClick={onClickImport}
            role="button"
            style={{
              position: 'relative',
              top: '-5px',
              left: '14px',
            }}
          >
            {formatMessage(
              {
                id: 'app.views.widgets.cards.simple_activity_editor_card.import_existing',
                defaultMessage:
                  '{isActivityImported, select, true {Undo import} other {or import existing...}}',
              },
              {
                isActivityImported: !!isActivityImported,
              }
            )}
          </div>
        ),
      },
      {
        ...CONTRIBUTION_EDITOR_ATTRIBUTES,
        label: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.what_impact_did_you_make',
          defaultMessage: 'What impact did you make?',
        }),
        name: 'description',
        className: 'simple-activity-editor-rte',
      },
      {
        label: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.what_skills',
          defaultMessage: 'What skills or behaviors did you use?',
        }),
        ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
        helperText: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.helper_text',
          defaultMessage: 'Example: communication, writing, patience',
        }),
        name: 'skills',
      },
      {
        inputElementId: `visibility-${props.object.id}`,
        required: true,
        disabled: isActivityImported,
        name: 'visibility',
        type: INPUT_TYPES.SELECT,
        clearable: false,
        searchable: false,
        defaultOptions: visibilityOptions,
        isOptionSelected: (option, selectValue) => {
          return selectValue.length > 0 && selectValue[0] === option.id;
        },
        defaultValue: props.defaultVisibility,
        label: (
          <>
            <span id={visibilityPopoverId}>
              {formatMessage({
                id: 'app.views.widgets.cards.simple_activity_editor_card.who_can_see',
                defaultMessage: 'Who can see your accomplishment?',
              })}{' '}
              <i
                id={visibilityPopoverId + '-help'}
                className="small text-primary fe fe-help-circle"
              />
            </span>
            <UncontrolledPopover
              placement="top"
              trigger="hover"
              target={visibilityPopoverId + '-help'}
            >
              <div>
                <FormattedMessage
                  id="app.views.widgets.cards.simple_activity_editor_card.everyone_at"
                  defaultMessage="Everyone at {organizationName} has a profile page where others can see their accomplishments. You can choose to display this accomplishment on your profile or keep it private so only managers and above and HR can see."
                  values={{
                    organizationName: props.organizationName,
                  }}
                />
              </div>
              {props.activitiesInHomepageFeedIsEnabled && (
                <div className="mt-3">
                  <FormattedMessage
                    id="app.views.widgets.cards.simple_activity_editor_card.if_activities"
                    defaultMessage="In addition, on the homepage highlights feed, your accomplishments are displayed to those that have visibility to see them."
                  />
                </div>
              )}
            </UncontrolledPopover>
          </>
        ),
        className: 'simple-activity-editor-card-visibility',
      },
    ],
    [
      titlePopoverId,
      formatMessage,
      isActivityImported,
      onClickImport,
      props.object.id,
      props.defaultVisibility,
      props.organizationName,
      props.activitiesInHomepageFeedIsEnabled,
      visibilityOptions,
      visibilityPopoverId,
    ]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        ...object,
        visibility: object?.visibility
          ? object.visibility.id
          : props.defaultVisibility,
      };
    },
    [props.defaultVisibility]
  );

  const validatedFormBackingData = useMemo(() => {
    const adaptedObjectVisibility = fromLegacyVisibilityToSimplified(
      props.object?.visibility,
      formatMessage
    );
    const visibility = visibilityOptions.find(
      (x) => x.id === (adaptedObjectVisibility || props.defaultVisibility)
    );
    return {
      ...props.object,
      visibility,
    };
  }, [formatMessage, props.defaultVisibility, props.object, visibilityOptions]);

  const onClosedDeleteModal = useCallback(
    () => setDeleteValidationErrors(null),
    []
  );

  const isActivityEmptyAndUnsaved = useMemo(() => {
    return (
      props.object &&
      !props.object.id &&
      !props.object.title &&
      !props.object.description &&
      !props.object.skills
    );
  }, [props.object]);

  const propsOnDelete = props.onDelete;

  const onDeleteButtonClicked = useCallback(
    () =>
      isActivityImported
        ? clearImport()
        : isActivityEmptyAndUnsaved
        ? propsOnDelete()
        : toggleConfirmDeleteModal(),
    [
      clearImport,
      isActivityEmptyAndUnsaved,
      isActivityImported,
      propsOnDelete,
      toggleConfirmDeleteModal,
    ]
  );

  const confirmDelete = useCallback(() => {
    // if this activity doesn't have an id, it means it was
    // just created locally but not saved to the DB, so we can
    // consider it deleted
    if (!props.object.id) {
      setConfirmDeleteModal(false);
      propsOnDelete();
      return;
    }

    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      '/activities/' + props.object.id,
      {
        delete_contribution: true,
      },
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            setDeleteValidationErrors(hardErrorMessage);
          } else {
            setDeleteValidationErrors(error);
          }
        } else {
          setConfirmDeleteModal(false);
          propsOnDelete();
        }
      },
      null
    );
  }, [props.object.id, propsOnDelete]);

  return (
    <div id={thisElementId}>
      <ModalActivitySelector
        campaign={campaign}
        isOpen={isImportModalOpen}
        onSelect={importActivity}
        toggle={toggleImportModal}
        startDate={props.startDate}
        person={props.person}
        title={formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.import_existing_activity',
          defaultMessage: 'Import existing activity',
        })}
        description={formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.import_existing_activity_description',
          defaultMessage: 'Import an existing activity from your profile.',
        })}
        selectActivityText={formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.select_activity',
          defaultMessage: 'Select activity',
        })}
        omit={props.omit}
      />
      <Card className={props.className} role={props.role} style={props.style}>
        <CardBody className="pb-1">
          <div
            className="avatar avatar-sm"
            style={{
              position: 'absolute',
              top: '-0.85rem',
              left: '-0.85rem',
            }}
          >
            <div
              className={
                'avatar-title fs-lg rounded-circle text-white ' + 'bg-warning'
              }
            >
              <i
                className={'fe fe-award'}
                style={{ position: 'relative', top: '-1px' }}
              ></i>
            </div>
          </div>
          <div
            className="avatar avatar-xs"
            style={{
              position: 'absolute',
              top: '-0.5rem',
              right: '-0.5rem',
            }}
          >
            <ConfirmationDialogModal
              isOpen={confirmDeleteModal}
              onClosed={onClosedDeleteModal}
              toggle={toggleConfirmDeleteModal}
              confirmCallback={confirmDelete}
              title={formatMessage({
                id: 'app.views.widgets.cards.simple_activity_editor_card.title.delete_this_accomplishment',
                defaultMessage: 'Delete this accomplishment?',
              })}
              description={formatMessage({
                id: 'app.views.widgets.cards.simple_activity_editor_card.confirmText.are_you_sure_delete',
                defaultMessage:
                  'Are you sure you want to delete this accomplishment?',
              })}
              confirmText={formatMessage({
                id: 'app.views.widgets.cards.simple_activity_editor_card.confirmText.delete_accomplishment',
                defaultMessage: 'Delete accomplishment',
              })}
              validationErrors={deleteValidationErrors}
            />
            <Button
              className="avatar-title rounded-circle"
              onClick={onDeleteButtonClicked}
              color="light"
            >
              <i
                className={'fe fe-x'}
                style={{ position: 'relative', top: '-1px' }}
              ></i>
            </Button>
          </div>
          <ValidatedForm
            className="mb-0"
            method="PATCH"
            url={undefined}
            callback={props.callback}
            submitOnChange={true}
            object={validatedFormBackingData}
            buttonClassName={'mt-0'}
            transformObjectBeforeSubmit={transformObjectBeforeSubmit}
            inputs={inputs}
            disableUnsavedChangesPrompt={props.disableUnsavedChangesPrompt}
            isSubmitting={props.isSubmitting}
          />
        </CardBody>
      </Card>
    </div>
  );
};

SimpleActivityEditorCard.defaultProps = {
  defaultVisibility: 'E',
};

SimpleActivityEditorCard.propTypes = {
  defaultVisibility: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  callback: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  className: PropTypes.object,
  organizationName: PropTypes.string,
  activitiesInHomepageFeedIsEnabled: PropTypes.bool,
  emptyStateText: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableUnsavedChangesPrompt: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default React.memo(SimpleActivityEditorCard);
