import { InitOrganization, Me, Person } from '../../types';
import {
  ObjectiveWithRef as Objective,
  calculateDescendantsStats,
} from '../../utils/models/Objective';
import {
  ObjectiveItem,
  ObjectiveItemBody,
  ObjectiveItemControls,
  ObjectiveItemHead,
  ObjectiveItemTitle,
} from './components';
import React, { FC, useCallback, useState } from 'react';
import {
  hasNoUsefulInformation,
  hasPersonalEditability,
  useObjectiveActions,
} from '../Objectives/ObjectiveOperations';

import { Badge } from 'reactstrap';
import LinkedObjectives from '../Objectives/LinkedObjectives';
import ObjectiveCollaborators from '../Objectives/ObjectiveCollaborators';
import ObjectiveIndicator from '../Objectives/ObjectiveIndicator';
import ObjectiveItemAddDeleteControls from './ObjectiveItemAddDeleteControls';
import ObjectiveItemMoreControls from './ObjectiveItemMoreControls';
import ObjectiveText from '../Objectives/ObjectiveText';
import ObjectivesBreakdownBar from '../Objectives/ObjectivesBreakdownBar';
import PersonalObjectiveAddItem from './PersonalObjectiveAddItem';
import PersonalObjectiveItem from './PersonalObjectiveItem';
import PersonalObjectiveItemCopyToNextPeriodModal from './PersonalObjectiveItemCopyToNextPeriodModal';
import PersonalObjectiveItemDeleteModal from './PersonalObjectiveItemDeleteModal';
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

interface Props {
  isRootElement?: boolean;
  objective: Objective;
  showLabelHeadings?: boolean;
  updateParentObjective?: (objective: Objective) => void;
  forceShowChildren?: boolean;
  currentOrganization: InitOrganization;
  me: Me;
  person: Person;
  isEditable?: boolean;
  isInPopover?: boolean;
  hideScoresAndWeight?: boolean;
  hideRelatedObjectives?: boolean;
  enableParentEdit: boolean;
  accessiblePeopleIds: number[];
  showDescendantsCount?: boolean;
  showDescendantsBreakdown?: boolean;
  applyFiltering?: boolean;
  textToHighlight?: string;
  autoFocus?: boolean;
}

const PersonalObjectiveItemRoot: FC<Props> = ({
  objective,
  updateParentObjective = () => {
    //do nothing
  },
  forceShowChildren = false,
  currentOrganization,
  me,
  person,
  isEditable: isGlobalEditable = false,
  isInPopover = false,
  hideScoresAndWeight = false,
  hideRelatedObjectives = false,
  enableParentEdit,
  accessiblePeopleIds,
  showDescendantsCount = false,
  showDescendantsBreakdown = false,
  applyFiltering = false,
  textToHighlight,
  autoFocus = false,
}) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = React.useState<Objective>(objective);

  const { totalDescendants, descedantsStatusBreakdown, matchDescendants } =
    calculateDescendantsStats(value);
  const [showChildren, setShowChildren] = React.useState<boolean>(
    () => forceShowChildren || matchDescendants
  );

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCopyToNextPeriodModal, setShowCopyToNextPeriodModal] =
    useState<boolean>(false);
  const [indent] = useState<number>(0);
  const isEditable: boolean =
    isGlobalEditable && hasPersonalEditability(value, accessiblePeopleIds);

  const onChildChange = useCallback((objective) => {
    setValue(objective);
    if (objective.children.length == 0) {
      setShowChildren(false);
    }
  }, []);

  const {
    deleteObjective,
    addChild,
    updateRelatedObjectivesRoot,
    setRootParentObjective,
    updateIndicatorRoot,
    updateTextRoot,
    updateTextKeyDownRoot,
  } = useObjectiveActions({
    me,
    person,
    firstDay: objective.coverage_start_date,
    lastDay: objective.coverage_end_date,
    currentOrganization,
  });

  const onToggleItemCopyModal = useCallback(
    () => setShowCopyToNextPeriodModal(false),
    []
  );

  if (!isEditable && hasNoUsefulInformation(value)) {
    return null;
  }

  return (
    <>
      <ObjectiveItem
        key={value.key}
        aria-label={'container-objective-' + value.key}
        indent={indent}
      >
        <ObjectiveItemHead>
          <PersonalObjectiveItemDeleteModal
            isOpen={showDeleteModal}
            onClosed={() => setShowDeleteModal(false)}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            objective={value}
            onDelete={({ deleteAllChildren, removeForAllCollaborators }) =>
              deleteObjective({
                value,
                updateParentObjective,
                deleteAllChildren,
                removeForAllCollaborators,
              })
            }
            me={me}
            person={person}
          />
          <PersonalObjectiveItemCopyToNextPeriodModal
            isOpen={showCopyToNextPeriodModal}
            onClosed={onToggleItemCopyModal}
            toggle={onToggleItemCopyModal}
            objective={value}
            person={person}
          />
          {!hideRelatedObjectives && !!(value.name?.length > 0) && (
            <LinkedObjectives
              isParentObjective
              id={`parent-objective-${objective.key}`}
              textIfEmpty={formatMessage({
                id: 'app.views.widget.inputs.objective_input.linked_objectives.parent.editing',
                defaultMessage: 'Set as subobjective',
              })}
              prefixIfPresent={formatMessage({
                id: 'app.views.widget.inputs.objective_input.linked_objectives.parent.child_of',
                defaultMessage: 'Subobjective of',
              })}
              targetObjectiveKey={value.key}
              objectives={
                value?.parent_objective ? [value.parent_objective] : []
              }
              owner_person={value.owner_person}
              coverageStartDate={value.coverage_start_date}
              coverageEndDate={value.coverage_end_date}
              isEditable={enableParentEdit && isEditable}
              callback={(rootParentObjectives) =>
                setRootParentObjective({
                  rootParentObjectives,
                  value,
                  setValue,
                })
              }
              maxElements={1}
            />
          )}
        </ObjectiveItemHead>
        <ObjectiveItemBody>
          <ObjectiveItemTitle
            isInPopover={isInPopover}
            isEditable={isEditable}
            fullWidth={hideScoresAndWeight && !isInPopover && !isEditable}
            showChildren={showChildren}
            hasChildren={(value?.children?.length ?? 0) > 0}
            onClick={() =>
              (value?.children?.length ?? 0) > 0 &&
              setShowChildren(!showChildren)
            }
          >
            {isEditable ? (
              <TextareaAutosize
                disabled={!isEditable}
                ref={value.ref}
                autoFocus={autoFocus || value.isNew}
                role="listitem"
                aria-label={'objective-' + value.key}
                name={'input-' + value.key}
                // ref={(ref) => (value.ref = ref)} // TODO: check check!, never had the ref initialized
                style={{ overflowY: 'hidden' }}
                className={
                  'form-control form-control-flush fw-bold pb-0 mt-2 ' +
                  (value.name ? 'pt-0' : 'pt-2')
                }
                placeholder={formatMessage({
                  id: 'app.views.person.personal_objective_item_root.text_editor.placeholder',
                  defaultMessage: 'Write an objective name',
                })}
                value={value.name}
                onChange={(e) => updateTextRoot({ e, value, setValue })}
                onKeyDown={(e) =>
                  updateTextKeyDownRoot({
                    e,
                    value,
                    setShowDeleteModal,
                    updateParentObjective,
                    setValue,
                    setShowChildren,
                  })
                }
              />
            ) : (
              <p
                className={
                  'form-control form-control-flush mb-0 ' +
                  (!applyFiltering || value.matched ? 'fw-bold' : '')
                }
                role="listitem"
                aria-label={'objective-' + value.key}
                id={'input-' + value.key}
              >
                {showDescendantsCount && totalDescendants > 0 && (
                  <>
                    <Badge className="bg-light rounded-pill me-2">
                      {totalDescendants}
                    </Badge>
                  </>
                )}
                <ObjectiveText
                  value={value.name}
                  textToHighlight={textToHighlight}
                />
              </p>
            )}
          </ObjectiveItemTitle>
          <ObjectiveItemControls>
            {isEditable && !hideScoresAndWeight && (
              <ObjectiveItemAddDeleteControls
                isEditable={isEditable}
                setShowChildren={setShowChildren}
                setShowDeleteModal={setShowDeleteModal}
                addChild={addChild}
                value={value}
                setValue={setValue}
              />
            )}
            {value.related_to &&
              value.related_to.length > 0 &&
              !hideRelatedObjectives &&
              value.name?.length > 0 && (
                <LinkedObjectives
                  id={`linked-objectives-${objective.key}`}
                  textIfEmpty={formatMessage({
                    id: 'app.views.widget.inputs.person_objectives.linked_objectives.related_to.editing',
                    defaultMessage: 'link related',
                  })}
                  objectives={value.related_to}
                  owner_person={value.owner_person}
                  coverageStartDate={value.coverage_start_date}
                  coverageEndDate={value.coverage_end_date}
                  isEditable={false}
                  callback={(relatedObjectives) => {
                    updateRelatedObjectivesRoot({
                      value,
                      setValue,
                      relatedObjectives,
                    });
                  }}
                />
              )}
            {!hideScoresAndWeight && (
              <>
                <ObjectiveCollaborators
                  objective={value}
                  onSave={(updatedChild) => {
                    setValue({
                      ...value,
                      collaborators: updatedChild.collaborators,
                    });
                  }}
                  disabled={!isEditable}
                />
                <ObjectiveIndicator
                  disabled={!isEditable}
                  objective={value}
                  onChange={(updatedObj) =>
                    updateIndicatorRoot({ updatedObj, value, setValue })
                  }
                  me={me}
                />
              </>
            )}
            {showDescendantsBreakdown && (
              <ObjectivesBreakdownBar breakdown={descedantsStatusBreakdown} />
            )}
            {isEditable && !hideScoresAndWeight && (
              <ObjectiveItemMoreControls
                isEditable={isEditable}
                setShowChildren={setShowChildren}
                setShowDeleteModal={setShowDeleteModal}
                setShowCopyToNextPeriodModal={setShowCopyToNextPeriodModal}
                addChild={addChild}
                value={value}
                setValue={setValue}
              />
            )}
          </ObjectiveItemControls>
        </ObjectiveItemBody>
      </ObjectiveItem>
      {(isEditable || !!value?.children?.length) && (
        <>
          {!!value?.children?.length &&
            value.children.map((child) => (
              <PersonalObjectiveItem
                hidden={!showChildren}
                isEditable={isEditable}
                isInPopover={isInPopover}
                hideScoresAndWeight={hideScoresAndWeight}
                hideRelatedObjectives={hideRelatedObjectives}
                key={child.key}
                objective={child}
                parentObjective={value}
                updateParentObjective={onChildChange}
                person={person}
                indent={indent + 1}
                accessiblePeopleIds={accessiblePeopleIds}
                showDescendantsCount={showDescendantsCount}
                showDescendantsBreakdown={showDescendantsBreakdown}
                textToHighlight={textToHighlight}
              />
            ))}
          {isEditable && (
            <PersonalObjectiveAddItem
              hidden={!showChildren}
              indent={indent}
              onClick={() => addChild({ value, setValue, setShowChildren })}
              value={value}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { me, currentOrganization, features } = state;

  return {
    me,
    currentOrganization,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PersonalObjectiveItemRoot));
