import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import { Popover, PopoverBody } from 'reactstrap';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  filterUniqueByEmail,
  getItemFromPerson,
  getPersonFromItem,
  isValidEmailAddressWithDomain,
  peopleObjectsAreEqual,
} from '../../../utils/models/Person';
import {
  hasPerson,
  personMatchesQuerySimpleCase,
} from '../../../utils/util/util';

import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import PropTypes from 'prop-types';
import ReactTagsInput from '../Inputs/ReactTagsInput';
import { capitalize } from '../../../utils/util/formatter';
import { connect } from 'react-redux';
import { getPeopleOrAllQuery } from '../Inputs/ValidatedInputTypes';
import { useAuth0 } from '@auth0/auth0-react';

// show suggestions that match the query via name/email/title
const suggestionsFilter = (suggestion, queryAnyCase) => {
  // for people, we trust that ES did the correct filtering
  if (suggestion._index === 'people' && !suggestion.isDemo) return true;

  const p = getPersonFromItem(suggestion);
  const query = queryAnyCase.toLowerCase();

  return personMatchesQuerySimpleCase(p, query);
};

const PeopleEditor = (props) => {
  const { formatMessage } = useIntl();
  const ref = useRef();
  const [isMounted, setIsMounted] = useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(true);
  const togglePopover = () => setPopoverIsOpen(!popoverIsOpen);

  const [validationMessage, setValidationMessage] = useState(null);
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;
  const propsMinSelections = props.minSelections;
  const propsMaxSelections = props.maxSelections;
  const propsOnInputChange = props.onInputChange;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [people, setPeople] = useState(props.value);
  const [potentialPerson, setPotentialPerson] = useState(null);
  const [inviteSuggestions, setInviteSuggestions] = useState([]);
  const { user } = useAuth0();

  const userSub = user?.sub;
  const currentOrgId = props.currentOrganization?.id;
  const validDomains = props.allowExternalPeople
    ? undefined
    : props.currentOrganization?.email_domains;
  const excludeList = props.excludeList ? props.excludeList : [];
  const allExcludes = props.allowSelf
    ? excludeList
    : excludeList.concat(props.me);

  useEffect(() => {
    // ensure that updating what is passed in updates UI
    setPeople(props.value);
  }, [props.value]);

  const fetchPersonDataViaEmail = useCallback(
    (email) => {
      // Legacy method, used to find a person on Clearbit.
      // Now only typed in data is used.

      if (!props.allowExternalPeople) {
        return;
      }

      if (isValidEmailAddressWithDomain(email, validDomains)) {
        // We return a potential person with just that email.
        setPotentialPerson({ email: email?.toLowerCase() });
      }
    },
    [props.allowExternalPeople, validDomains]
  );

  const onInputChange = useCallback(
    (value) => {
      if (propsOnInputChange) {
        propsOnInputChange(value);
      }

      if (!value.includes('@')) {
        return;
      }

      if (
        !potentialPerson?.email?.toLowerCase().startsWith(value?.toLowerCase())
      ) {
        setPotentialPerson(null);
      }

      fetchPersonDataViaEmail(value);
    },
    [
      fetchPersonDataViaEmail,
      potentialPerson?.email,
      propsOnInputChange,
      setPotentialPerson,
    ]
  );

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch most recent invitations sent and append as recommendations
    if (props.showInviteSuggestions && userSub && currentOrgId) {
      ElasticsearchAPI.getSentInvitations(
        userSub,
        props.currentProxyPerson,
        currentOrgId,
        (newInvitations) => {
          if (isMounted) {
            setInviteSuggestions(newInvitations.map((i) => i.recipient_person));
          }
        },
        (message) => {
          console.error('Sent invitations fetch error: ' + message);
        }
      );
    }
  }, [
    isMounted,
    userSub,
    currentOrgId,
    props.showInviteSuggestions,
    props.currentProxyPerson,
  ]);

  let noSuggestionsText = formatMessage(
    {
      id: 'app.views.widgets.forms.people_editor.no_suggestions',
      defaultMessage:
        'Enter a valid name or {validDomainsCount, plural, =0 {email} one {{validDomains} email} other {one of {validDomains} email}}',
    },
    {
      validDomainsCount: validDomains?.length ?? 0,
      validDomains: validDomains?.map((it) => '@' + it).join(', ') ?? '',
    }
  );

  const suggestionsTransform = useCallback((suggestions) => {
    // if there is a clearbit suggestion that already
    // has the given user's email address in the suggestions,
    // don't show the clearbit one (as we want to pick the
    // user or person in this case)
    return suggestions.filter((item) => {
      return (
        item._index !== 'clearbit' ||
        (suggestions &&
          suggestions.findIndex(
            (i) =>
              i._index !== 'clearbit' &&
              i.object?.email?.toLowerCase() ===
                item?.object?.email?.toLowerCase()
          ) === -1)
      );
    });
  }, []);

  const propsCallback = props.callback;
  const callback = useCallback(
    (people) => propsCallback(people.map(getPersonFromItem)),
    [propsCallback]
  );

  const emailValidationFunction = useCallback(
    (email, ignoreAllowExternalPeopleCheck = false) => {
      // if allowExternalPeople is false, we don't allow any people not already in
      // the organization, so all emails should be rejected.
      if (!props.allowExternalPeople && !ignoreAllowExternalPeopleCheck) {
        return false;
      }

      return isValidEmailAddressWithDomain(email?.toLowerCase(), validDomains);
    },
    [props.allowExternalPeople, validDomains]
  );

  useEffect(() => {
    // if there are any invalid people, require their removal first
    if (people?.length > 0) {
      const invalidPeople = people.filter(
        (p) => !props.isDemoMode && !emailValidationFunction(p.email, true)
      );
      if (invalidPeople.length > 0) {
        setValidationMessage(
          formatMessage(
            {
              id: 'app.views.widgets.forms.people_editor.remove_invalid',
              defaultMessage: 'Remove invalid people: {peopleList}',
            },
            {
              peopleList: invalidPeople.map((p) => p.full_name).join(', '),
            }
          )
        );
        return;
      }
    }

    if (propsRequired && people?.length == 0) {
      setValidationMessage(
        formatMessage({
          id: 'app.views.widgets.forms.people_editor.required',
          defaultMessage: 'This field is required.',
        })
      );
      return;
    } else {
      setValidationMessage(null);
    }

    if (propsMinSelections && people?.length < propsMinSelections) {
      setValidationMessage(
        formatMessage(
          {
            id: 'app.views.widgets.forms.people_editor.min_selections',
            defaultMessage:
              'Please select at least {propsMinSelections} {propsMinSelections, plural, one {person} other {people}}.',
          },
          {
            propsMinSelections,
          }
        )
      );
      return;
    } else {
      setValidationMessage(null);
    }

    if (propsMaxSelections && people?.length > propsMaxSelections) {
      setValidationMessage(
        formatMessage(
          {
            id: 'app.views.widgets.forms.people_editor.max_selections',
            defaultMessage:
              'Please select no more than {propsMaxSelections} {propsMaxSelections, plural, one {person} other {people}}.',
          },
          { propsMaxSelections }
        )
      );
      return;
    } else {
      setValidationMessage(null);
    }
  }, [
    emailValidationFunction,
    people,
    props.isDemoMode,
    propsRequired,
    propsMinSelections,
    propsMaxSelections,
    formatMessage,
  ]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  const suggestions = props.suggestions ? props.suggestions : [];

  // only show suggestions if unique by email, email is valid, and not already on person list
  // and only show 5 suggestions at a time
  const MAX_SUGGESTIONS_LENGTH = props.maxSuggestions
    ? props.maxSuggestions
    : 5;
  const filteredSuggestions = filterUniqueByEmail(
    suggestions.concat(inviteSuggestions)
  )
    .filter(
      (s) =>
        s.email &&
        !hasPerson(allExcludes, s) &&
        !hasPerson(people, s) &&
        isValidEmailAddressWithDomain(s.email?.toLowerCase(), validDomains)
    )
    .slice(0, MAX_SUGGESTIONS_LENGTH);

  const tagsAreEqual = (a, b) => {
    // match if object ids match (for user case)
    if (a?.id && b?.id && a.id.toString() === b.id.toString()) {
      return true;
    }

    // match if emails match (for clearbit or unrecognized email case)
    return (
      a?.email && b?.email && a.email.toLowerCase() === b.email.toLowerCase()
    );
  };

  const recommendationsList = useMemo(() => {
    if (filteredSuggestions?.length > 0) {
      return (
        <small className="mt-2">
          <FormattedMessage
            id="app.views.widgets.forms.people_editor.suggested"
            defaultMessage="Suggested: {suggestions}"
            values={{
              suggestions: (
                <>
                  {filteredSuggestions.map((person, index) => (
                    <Fragment key={index}>
                      {index === 0 ? '' : ', '}
                      <span
                        className="btn-link"
                        role="button"
                        onClick={() => {
                          const newPeople = [...people, person];
                          setPeople(newPeople);
                          propsCallback(newPeople);
                        }}
                      >
                        {person.full_name}
                      </span>
                    </Fragment>
                  ))}
                </>
              ),
            }}
          />
        </small>
      );
    } else {
      return null;
    }
  }, [filteredSuggestions, people, propsCallback]);

  const excludeListErrorMessageFunction = useMemo(() => {
    if (props.excludeListErrorMessageFunction) {
      return props.excludeListErrorMessageFunction;
    } else if (props.excludeListErrorMessage) {
      // if universal error message provided, use that in all cases
      // by defaulting this to undefined
      return undefined;
    } else {
      // by default, if allowSelf is false, show "you cannot add yourself"
      // when you see yourself, otherwise show "<Name> has already been added"
      return (tag) => {
        if (!props.allowSelf && peopleObjectsAreEqual(tag.object, props.me)) {
          return formatMessage({
            id: 'app.views.widgets.forms.people_editor.cannot_add_self',
            defaultMessage: 'You cannot add yourself.',
          });
        } else {
          return formatMessage(
            {
              id: 'app.views.widgets.forms.people_editor.already_added',
              defaultMessage: '{tagName} has already been added.',
            },
            { tagName: capitalize(tag.name) }
          );
        }
      };
    }
  }, [
    props.allowSelf,
    props.excludeListErrorMessage,
    props.excludeListErrorMessageFunction,
    props.me,
    formatMessage,
  ]);

  const internalSuggestions = useMemo(
    () =>
      props.isDemoMode
        ? props.demoPeople?.map((p) => ({
            ...getItemFromPerson(p),
            // ensure that these get filtered out based on the query
            isDemo: true,
          }))
        : potentialPerson
        ? [
            {
              _index: 'clearbit', // not really clearbit
              object: potentialPerson,
              // needs to be email to match current search and show in dropdown
              name: potentialPerson.email?.toLowerCase(),
            },
          ]
        : null,
    [potentialPerson, props.demoPeople, props.isDemoMode]
  );

  return (
    <div ref={ref}>
      <ReactTagsInput
        disabled={props.disabled}
        className={props.className}
        inputClassName={props.inputClassName}
        name={props.name}
        tagsAreEqual={tagsAreEqual}
        onInputChange={onInputChange}
        autoFocus={props.autoFocus}
        deliverEmptyInputChange={props.deliverEmptyInputChange}
        propagateOnClearInput={props.propagateOnClearInput}
        allowNew={false}
        onBlur={props.onBlur}
        value={people?.map(getItemFromPerson)}
        suggestions={internalSuggestions}
        suggestionsFilter={suggestionsFilter}
        suggestionsTransform={suggestionsTransform}
        placeholder={
          props.placeholder ||
          (props.allowExternalPeople &&
            formatMessage({
              id: 'app.views.widgets.forms.people_editor.names_or_emails',
              defaultMessage: 'Names or emails',
            })) ||
          formatMessage({
            id: 'app.views.widgets.forms.people_editor.enter_names',
            defaultMessage: 'Enter names',
          })
        }
        onBeforeChange={props.onBeforeChange}
        callback={(receivedPeople) => {
          setPeople(receivedPeople.map(getPersonFromItem));
          callback(receivedPeople);
        }}
        noSuggestionsText={noSuggestionsText}
        elasticsearchOptions={{
          url: 'get-people-by-name',
          getQuery: getPeopleOrAllQuery,
          ...props.elasticsearchOptions,
        }}
        useTagCards={true}
        validationFunction={emailValidationFunction}
        excludeList={allExcludes}
        excludeListErrorMessage={props.excludeListErrorMessage}
        excludeListErrorMessageFunction={excludeListErrorMessageFunction}
        excludeTagMatchFunction={getPersonFromItem}
        showPlaceholderAfterFirstTag={props.showPlaceholderAfterFirstTag}
      />
      {props.showInviteInstructions && props.allowExternalPeople && (
        <div className="text-muted small mb-0 mt-2">
          {validDomains?.length > 0 && (
            <div>
              <i className="fe fe-mail me-2" />
              <FormattedMessage
                id="app.views.widgets.forms.people_editor.emails.validation.domains"
                defaultMessage="Emails must end in {validDomains}."
                values={{
                  validDomains: (
                    <>
                      <FormattedList
                        type="disjunction"
                        value={validDomains.maps((it, index) => (
                          <span key={index} className="fw-bold">
                            {it}
                          </span>
                        ))}
                      />
                    </>
                  ),
                }}
              />
            </div>
          )}
        </div>
      )}
      {!props.showSuggestionsAsPopover &&
        filteredSuggestions?.length > 0 &&
        recommendationsList}
      {props.showSuggestionsAsPopover &&
        ref?.current &&
        filteredSuggestions?.length > 0 && (
          <Popover
            trigger=""
            placement="right"
            target={ref?.current}
            isOpen={popoverIsOpen}
            toggle={togglePopover}
          >
            <PopoverBody>{recommendationsList}</PopoverBody>
          </Popover>
        )}
    </div>
  );
};

PeopleEditor.defaultProps = {
  value: [],
  suggestions: [],
  excludeList: [],
  disabled: false,
  autoFocus: false,
  allowSelf: false,
  allowUserObjects: false,
  deliverEmptyInputChange: false,
  propagateOnClearInput: false,
};

PeopleEditor.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  name: PropTypes.string,
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  suggestions: PropTypes.arrayOf(PropTypes.object),
  excludeList: PropTypes.arrayOf(PropTypes.object),
  callback: PropTypes.func.isRequired,
  onValidChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onInviteEmailFieldToggle: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  inline: PropTypes.bool,
  inForm: PropTypes.bool,
  autoFocus: PropTypes.bool,
  allowSelf: PropTypes.bool,
  allowExternalPeople: PropTypes.bool,
  placeholder: PropTypes.string,
  showSuggestionsAsPopover: PropTypes.bool,
  showPlaceholderAfterFirstTag: PropTypes.bool,
  showInviteInstructions: PropTypes.bool,
  showInviteSuggestions: PropTypes.bool,
  maxSuggestions: PropTypes.number,
  excludeListErrorMessage: PropTypes.string,
  excludeListErrorMessageFunction: PropTypes.func,
  isDemoMode: PropTypes.bool,
  deliverEmptyInputChange: PropTypes.bool,
  propagateOnClearInput: PropTypes.bool,
  onBeforeChange: PropTypes.func,
  elasticsearchOptions: PropTypes.object,
  onBlur: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { me, currentProxyPerson, currentOrganization, demoPeople } = state;

  return {
    me,
    currentProxyPerson,
    currentOrganization,
    demoPeople,
  };
};

export default connect(mapStateToProps)(React.memo(PeopleEditor));
