import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  Ref,
  useEffect,
  useMemo,
  useState,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';

interface Props {
  required: boolean;
  innerRef?: Ref<HTMLTextAreaElement>;
  minRows: number;
  maxRows: number;
  role: string;
  style: object;
  name: string;
  placeholder: string;
  value: string;
  className: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  autoComplete: string;
  autoFocus: boolean;
  disabled: boolean;
  onKeyDown: KeyboardEventHandler<HTMLTextAreaElement>;
  maxLength: number;
}

const TextArea: FC<Props> = (props) => {
  const [autoFocusTriggered, setAutoFocusTriggered] = useState(false);

  const inputRef: Ref<HTMLTextAreaElement> = useMemo(
    () => props.innerRef ?? React.createRef(),
    [props.innerRef]
  );

  // since this component may be initially rendered as disabled but then soon enabled,
  // we need to manually control autofocus so it only triggers after the component
  // is enabled
  useEffect(() => {
    if (
      !autoFocusTriggered &&
      !props.disabled &&
      props.autoFocus &&
      // @ts-expect-error
      inputRef?.current
    ) {
      setAutoFocusTriggered(true);

      // @ts-expect-error
      inputRef.current.focus();
    }
  }, [autoFocusTriggered, props.disabled, props.autoFocus, inputRef]);

  return (
    <TextareaAutosize
      required={props.required}
      ref={inputRef}
      minRows={props.minRows}
      maxRows={props.maxRows}
      role={props.role}
      style={props.style}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      className={props.className}
      onChange={props.onChange}
      autoComplete={props.autoComplete}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      maxLength={props.maxLength}
    />
  );
};

export default React.memo(TextArea);
