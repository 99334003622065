import {
  createIntl,
  createIntlCache,
  type IntlShape,
  type MessageFormatElement,
} from 'react-intl';
import { loadLocale } from './messages';

import React, { useMemo } from 'react';

import { useOrganizationSettings } from '../utils/util/hooks';

export type LocalesDict = { [key: string]: IntlShape };

type MessagesType =
  | Record<string, string>
  | Record<string, MessageFormatElement[]>;

interface ConfirmIntlsContextType {
  locales: LocalesDict | null;
}

const ConfirmIntlsContext = React.createContext<
  ConfirmIntlsContextType | undefined
>({ locales: null });

const ConfirmIntlsProvider = ({ children }) => {
  const organizationSettings = useOrganizationSettings();

  const intlCache = useMemo(() => createIntlCache(), []);

  const [locales, setLocales] = React.useState<LocalesDict | null>(null);

  React.useEffect(() => {
    if (organizationSettings?.internationalization?.enabled) {
      const supported_locales =
        organizationSettings?.internationalization?.supported_locales;
      const localesData = supported_locales.map(
        (locale) =>
          new Promise<{ locale: string; messages: MessagesType }>((res) =>
            loadLocale(locale).then((data) =>
              res({ locale, messages: data as MessagesType })
            )
          )
      );

      Promise.all(localesData).then(
        (
          messagesData: {
            locale: string;
            messages: MessagesType;
          }[]
        ) => {
          const localesDict = messagesData.reduce((acc, localeData) => {
            return {
              ...acc,
              [localeData.locale]: createIntl(
                { locale: localeData.locale, messages: localeData.messages },
                intlCache
              ),
            };
          }, {});
          setLocales(localesDict);
        }
      );
    } else {
      setLocales({});
    }
  }, [
    organizationSettings?.internationalization?.enabled,
    organizationSettings?.internationalization?.supported_locales,
    intlCache,
  ]);

  return (
    <ConfirmIntlsContext.Provider value={{ locales }}>
      {children}
    </ConfirmIntlsContext.Provider>
  );
};

const useConfirmIntls = (): ConfirmIntlsContextType => {
  const context = React.useContext(ConfirmIntlsContext);
  if (context == null) {
    throw new Error('useIntl must be used within a ConfirmIntlsProvider');
  }
  return context;
};

export { ConfirmIntlsProvider, useConfirmIntls };
