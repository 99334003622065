import {
  ObjectiveWithRef as Objective,
  ObjectivePerson,
} from '../../utils/models/Objective';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import AvatarGroup from '../Widgets/People/AvatarGroup';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { DATE_PARAMETER } from './ObjectivesTimeFrameSelector';
import { ICONS } from '../../consts/consts';
import ObjectiveCollaboratorsPopover from './ObjectiveCollaboratorsPopover';
import { useIntl } from 'react-intl';

interface Props {
  objective: Objective;
  onSave: (objective: Objective) => void;
  disabled?: boolean;
}

const ObjectiveCollaborators: FC<Props> = ({ objective, onSave, disabled }) => {
  const { formatMessage } = useIntl();
  const [collaboratorsPopoverOpen, setCollaboratorsPopoverOpen] =
    useState(false);

  const toggle = useCallback(
    () => !disabled && setCollaboratorsPopoverOpen(!collaboratorsPopoverOpen),
    [disabled, collaboratorsPopoverOpen]
  );

  const onClose = useCallback(() => {
    setCollaboratorsPopoverOpen(false);
  }, []);

  // this caputres the case when the objective lose editability
  useEffect(() => {
    if (disabled) {
      setCollaboratorsPopoverOpen(false);
    }
  }, [disabled]);

  const allPeople = objective?.collaborators ?? [];

  const avatarsRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <button
        type="button"
        className="focusable-element"
        title={formatMessage({
          id: 'app.views.person.personal_objectives.collaborators.title',
          defaultMessage: 'Collaborators',
        })}
        ref={avatarsRef}
      >
        {allPeople.length > 0 ? (
          <AvatarGroup
            size="xs"
            className="pt-1 d-none d-md-inline-block"
            people={allPeople.map((it) => ({
              ...it,
              onClick: () => {
                if (!disabled) {
                  setCollaboratorsPopoverOpen(true);
                }
              },
            }))}
            maxFaces={2}
            unlinked={!disabled}
            linkToSubPath={`/objectives?${DATE_PARAMETER}=${objective.coverage_start_date}#key=${objective.key}`}
            isExternalUrl={true}
          />
        ) : (
          !disabled && (
            <div
              className="btn btn-sm btn-rounded-circle btn-light mt-1"
              role="button"
              onClick={() => setCollaboratorsPopoverOpen(true)}
            >
              <i className={ICONS.ADD_PERSON} />
            </div>
          )
        )}
      </button>
      <ObjectiveCollaboratorsPopover
        target={avatarsRef}
        isOpen={collaboratorsPopoverOpen}
        onClose={onClose}
        toggle={toggle}
        initialValue={objective.collaborators}
        onSave={(collaborators: ObjectivePerson[]) => {
          ConfirmAPI.sendRequestToConfirm(
            'POST',
            `/objectives/${objective.key}/collaborators`,
            { collaborators: collaborators.map((it) => it.id) },
            (response, error) => {
              if (response) {
                // setLoading(false);
                // setItems(response.changes);
              }
              if (error) {
                // setLoading(false);
                // setError(true);
              }
            },
            null,
            null
          );
          onSave({
            ...objective,
            collaborators,
          });
        }}
      />
    </>
  );
};

export default ObjectiveCollaborators;
