import React, { FC, useCallback, useRef, useState } from 'react';
import { Campaign, Person, Relationship } from '../../../../types';
import TeamDashboardPeersPopover, {
  TeamDashboardEditPeersModalType,
} from './TeamDashboardPeersPopover';
import AvatarGroup from '../../People/AvatarGroup';
import { ICONS } from '../../../../consts/consts';
import ConfirmAPI from '../../../../utils/api/ConfirmAPI';

interface TeamDashboardEditablePeerListProps {
  organizationId: number;
  campaign?: Campaign;
  relationships: Relationship[];
  peers: Person[];
  editTitle: string;
  targetPersonId: number;
  type: TeamDashboardEditPeersModalType;
  readonly: boolean;
  callback: (relationships: TeamDashboardEditModalCallbackResponse) => void;
  people: any[];
}

export interface TeamDashboardEditModalCallbackResponse {
  type: TeamDashboardEditPeersModalType;
  relationships: Relationship[];
  person_id: number;
}

const TeamDashboardEditablePeerList: FC<TeamDashboardEditablePeerListProps> = ({
  organizationId,
  campaign,
  relationships,
  peers,
  editTitle,
  targetPersonId,
  type,
  readonly,
  callback,
  people,
}) => {
  const avatarsRef = useRef<HTMLButtonElement>(null);
  const [feedbackPeersPopoverOpen, setFeedbackPeersPopoverOpen] =
    useState(false);

  const toggle = useCallback(
    () => !readonly && setFeedbackPeersPopoverOpen(!feedbackPeersPopoverOpen),
    [readonly, feedbackPeersPopoverOpen]
  );

  const onClose = useCallback(() => {
    setFeedbackPeersPopoverOpen(false);
  }, []);

  const onSave = useCallback(
    (peers: Person[]) => {
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        `/performance/admin/update-peers`,
        {
          peers,
          type,
          targetPersonId,
          campaign: campaign?.id,
          organization: organizationId,
        },
        (response, error) => {
          if (error) {
            // setLoading(false);
            // setError(true);
          } else if (response) {
            callback({
              type: type,
              relationships: response.peer_relationships,
              person_id: targetPersonId,
            });
          }
        },
        null,
        null
      );
    },
    [callback, campaign?.id, organizationId, targetPersonId, type]
  );

  if (!peers || !campaign?.id) {
    return null;
  }

  return (
    <>
      <span role="button" title={editTitle} ref={avatarsRef}>
        {peers.length > 0 ? (
          <AvatarGroup
            key={`peers_${type}_${targetPersonId}`}
            size="xs"
            people={peers.map((p) => ({
              ...p,
              onClick: () => {
                if (!readonly) {
                  setFeedbackPeersPopoverOpen(true);
                }
              },
            }))}
            unlinked={!readonly}
          />
        ) : (
          !readonly && (
            <div
              className="btn btn-sm btn-rounded-circle btn-light mt-1"
              role="button"
              onClick={() => setFeedbackPeersPopoverOpen(true)}
            >
              <i className={ICONS.ADD_PERSON} />
            </div>
          )
        )}
      </span>
      <TeamDashboardPeersPopover
        key={`peers_edit_${type}_${targetPersonId}`}
        campaign={campaign}
        relationships={relationships}
        target={avatarsRef}
        isOpen={feedbackPeersPopoverOpen}
        onClose={onClose}
        toggle={toggle}
        peers={peers}
        title={editTitle}
        targetPersonId={targetPersonId}
        onSave={onSave}
        type={type}
        dataset_people={people}
      />
    </>
  );
};

export default TeamDashboardEditablePeerList;
