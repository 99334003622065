import { AuthUser, Me, Organization } from 'types';
import { Button, Card, Col, Row } from 'reactstrap';
import Cron, { HEADER } from 'react-cron-generator';
import {
  EditableNudge,
  NUDGE_TYPE_CHECK_IN,
  NUDGE_TYPE_CUSTOM,
  NUDGE_TYPE_GIVE_FEEDBACK_TO_DIRECT_REPORTS,
  NUDGE_TYPE_REQUEST_FEEDBACK,
  NUDGE_TYPE_UPDATE_OBJECTIVES,
  Nudge,
  NudgeData,
  NudgeForm,
  NudgeMethod,
  NudgeRecipientsType,
  NudgeScheduleType,
  NudgeStatus,
  NudgeType,
  nudgeRecipientsType,
} from '../types';
import {
  INPUT_TYPES,
  getPeopleOrAllQuery,
} from 'views/Widgets/Inputs/ValidatedInputTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getNotificationMethods, toSortedJoin } from 'consts/consts';

import Loading from 'views/Widgets/Loading';
import ModalEditorButton from 'views/Widgets/Modals/ModalEditorButton';
import { configForLocale } from 'locale/messages';
import { connect } from 'react-redux';
import { createNudgeLocalizations } from './utils';
import { getUserIsSuperAdmin } from 'utils/models/User';
import { useConfirmIntls } from 'locale/ConfirmIntlsContext';
import { useFeatures } from 'utils/util/features';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

const getCol4 = (element) => {
  return <Col className="col-12 col-md-4">{element}</Col>;
};

function stripProtocol(url: string): string {
  return url.replace(/^https?:\/\//, '');
}

function replaceProtocol(
  baseUrl: string,
  url: string | undefined
): string | undefined {
  if (!url) {
    return url;
  }
  const protocol = baseUrl.split('://')[0];
  const withoutProtocol = stripProtocol(url);
  return `${protocol}://${withoutProtocol}`;
}

function urlsMatch(originalUrl: string, userUrl: string | undefined): boolean {
  if (!userUrl) {
    return false;
  }
  const normalizedOriginal = stripProtocol(originalUrl);
  const normalizedUser = stripProtocol(userUrl);

  return normalizedUser.startsWith(normalizedOriginal);
}

const cronDaysOrder = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

function normalizeCronExpression(cron: string): string {
  const parts = cron.split(' ');
  if (parts.length === 7) {
    const days = parts[5].split(',');
    if (days.length > 1) {
      const sorted = days.sort((a, b) => {
        return cronDaysOrder.indexOf(a) - cronDaysOrder.indexOf(b);
      });
      parts[5] = sorted.join(',');
    }
  }
  return parts.join(' ');
}

interface Props {
  currentOrganization: Organization;
  me: Me;
  authUser: AuthUser;
  isOpen: boolean;
  toggle: () => void;
  onOpened?: () => void;
  onClosed: () => void;
  hideButton: boolean;
  inline?: boolean;
  buttonClassName?: string;
  anchorTrigger?: string;
  nudge?: Nudge | null;
  onSubmitCallback?: (data: NudgeForm) => void;
}

const nudgeScheduleTypes = (formatMessage) => [
  {
    id: NudgeScheduleType.Once,
    name: formatMessage({
      id: 'app.views.nudges.modalnudgeeditorbutton.schedule_types.once',
      defaultMessage: 'Once',
    }),
  },
  {
    id: NudgeScheduleType.Recurring,
    name: formatMessage({
      id: 'app.views.nudges.modalnudgeeditorbutton.schedule_types.recurring',
      defaultMessage: 'Recurring',
    }),
  },
];

const flattenNudge = (nudge?: Nudge | null): NudgeForm | null => {
  if (!nudge) {
    return null;
  }

  const { methods, data, ...rest } = nudge;
  const flattened = {
    ...rest,
    ...data,
    medium: toSortedJoin(methods),
  };

  return flattened;
};

const ModalNudgeEditorButton: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const { locales } = useConfirmIntls();
  const { features } = useFeatures();
  const [nudge, setNudge] = useState<NudgeForm | null>(null);
  const isEditing = !!props.nudge;
  const hasDirectReports: boolean = (props.me?.direct_reports?.length ?? 0) > 0;
  const isSystemAdmin: boolean =
    props.currentOrganization?.is_system_admin ?? false;
  const isSuperAdmin: boolean = getUserIsSuperAdmin(props.authUser);
  const baseUrl = process.env.REACT_APP_CONFIRM_APP_URL || '';

  useEffect(() => {
    setNudge(flattenNudge(props.nudge));
  }, [props.nudge]);

  const cronstrueLocale = useMemo(() => {
    const localeConfig = configForLocale(locale);
    return localeConfig.cronstrueLocale;
  }, [locale]);

  const propsButtonText = isEditing
    ? formatMessage({
        id: 'app.views.nudges.modalnudgeeditorbutton.button.edit_nudge',
        defaultMessage: 'Edit nudge',
      })
    : formatMessage({
        id: 'app.views.nudges.modalnudgeeditorbutton.button.add_nudge',
        defaultMessage: 'Add nudge',
      });

  const userTimezone = useMemo(() => {
    const tz = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
    return tz;
  }, []);

  const onChange = useCallback((newNudge: NudgeForm) => {
    setNudge(newNudge);
  }, []);

  const onValidate = useCallback(
    (object: NudgeForm) => {
      const errors = {};
      if (!urlsMatch(baseUrl, object.action_url?.trim().toLowerCase())) {
        errors['action_url'] = formatMessage(
          {
            id: 'app.views.nudges.modalnudgeeditorbutton.validation.action_url',
            defaultMessage:
              'The nudge should direct to a page inside Confirm ({baseUrl})',
          },
          { baseUrl }
        );
      }

      return errors;
    },
    [formatMessage, baseUrl]
  );

  const getButtonComponent = useCallback(
    (p) =>
      props.hideButton ? (
        <></>
      ) : (
        <Button color="primary" {...p}>
          {propsButtonText}
        </Button>
      ),
    [propsButtonText, props.hideButton]
  );

  const title = propsButtonText;

  const modalTitle = title;
  const submitText = title;

  const anchorTrigger = props.anchorTrigger;

  // Return everyone below + themseves
  const getAdminableOrUnderPersonIncludedQuery = useCallback(
    (q) => {
      const getAllPeopleQuery = getPeopleOrAllQuery(q);
      if (isSuperAdmin || isSystemAdmin) return getAllPeopleQuery;
      return {
        ...getAllPeopleQuery,
        show_only_adminable_or_under_person_included: true,
      };
    },
    [isSuperAdmin, isSystemAdmin]
  );

  const resetNudge = useCallback(() => {
    setNudge(null);
  }, [setNudge]);

  const transformObjectBeforeSubmit = useCallback(
    (object: NudgeForm): EditableNudge => {
      const { medium, action_url, ...rest } = object;
      const data: NudgeData = {
        recipients_people: object.recipients_people?.map((p) => ({
          id: p.id,
          email: p.email,
          full_name: p.full_name,
        })),
        recipients_filter: object.recipients_filter,
      };
      const methods: NudgeMethod[] = (
        medium
          ? medium
              .split(',')
              .filter((m) =>
                Object.values(NudgeMethod).includes(m as NudgeMethod)
              )
          : []
      ) as NudgeMethod[];

      const cloned = {
        ...rest,
        methods,
        action_url: replaceProtocol(baseUrl, action_url),
        data: data,
        last_sent: null,
      };

      return cloned;
    },
    [baseUrl]
  );

  const propsOnSubmitCallback = props.onSubmitCallback;
  const onSubmitCallback = useCallback(
    (data) => {
      if (propsOnSubmitCallback) {
        propsOnSubmitCallback(data);
      }
    },
    [propsOnSubmitCallback]
  );

  const renderModalHeader = useCallback(
    (modalTitle) => {
      return nudge && !isEditing ? (
        <>
          <Button
            className="btn-sm me-2 btn-rounded-circle bg-primary-soft text-primary border-0"
            color="light"
            style={{ width: '1.5rem', height: '1.5rem' }}
            onClick={resetNudge}
          >
            <span className="fe fe-chevron-left" />
          </Button>{' '}
          {modalTitle}
        </>
      ) : (
        modalTitle
      );
    },
    [nudge, isEditing, resetNudge]
  );

  const initNudge = useCallback(
    (nudgeType: NudgeType): void => {
      if (!props.currentOrganization?.id) {
        return;
      }

      const nudge: NudgeForm = {
        key: uuidv4(),
        organization_id: props.currentOrganization.id!,
        subject: nudgeType.subject,
        text: nudgeType.text,
        action_url: nudgeType.url,
        methods: [],
        schedule_type: nudgeType.schedule_type ?? NudgeScheduleType.Recurring,
        scheduled_timezone: userTimezone,
        scheduled_time: undefined,
        scheduled_recurrence: nudgeType.scheduled_recurrence,
        status: NudgeStatus.Active,
        recipients_type: NudgeRecipientsType.Self,
      };

      createNudgeLocalizations(
        nudgeType,
        nudge,
        props.currentOrganization.id,
        locales,
        setNudge
      );
    },
    [setNudge, props.currentOrganization?.id, userTimezone, locales]
  );

  const onCronExpressionChange = useCallback(
    (cron) => {
      if (nudge) {
        console.log('cron', cron);
        const newNudge = {
          ...nudge,
          scheduled_recurrence: normalizeCronExpression(cron),
        };
        setNudge(newNudge);
      }
    },
    [nudge, setNudge]
  );

  const inputs = useMemo(() => {
    const notification_medium_options = getNotificationMethods(
      formatMessage,
      props.currentOrganization
    );

    const items = [
      {
        type: INPUT_TYPES.SWITCH,
        name: 'status',
        label: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.status.label',
          defaultMessage: 'Enabled',
        }),
        helperHover: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.status.helper',
          defaultMessage:
            "If disabled, the notification will be paused until it's enabled again.",
        }),
        checkedValue: NudgeStatus.Active,
        uncheckedValue: NudgeStatus.Paused,
        wrapperFunction: getCol4,
      },
      {
        required: true,
        type: INPUT_TYPES.DROPDOWN,
        name: 'medium',
        label: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.status.medium',
          defaultMessage: 'Medium',
        }),
        objects: notification_medium_options,
        wrapperFunction: getCol4,
      },
      {
        required: true,
        type: INPUT_TYPES.DROPDOWN,
        name: 'schedule_type',
        label: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.schedule_type.label',
          defaultMessage: 'Schedule type',
        }),
        objects: nudgeScheduleTypes(formatMessage),
        wrapperFunction: getCol4,
      },
      ...(nudge?.schedule_type == NudgeScheduleType.Once
        ? [
            {
              required: true,
              type: INPUT_TYPES.DATE_PICKER,
              enableTime: true,
              name: 'scheduled_time',
              label: formatMessage(
                {
                  id: 'app.views.nudges.modalnudgeeditorbutton.inputs.scheduled_time.label',
                  defaultMessage: 'Scheduled time ({timezone})',
                },
                {
                  timezone: userTimezone,
                }
              ),
            },
          ]
        : [
            {
              required: true,
              type: INPUT_TYPES.CUSTOM_INPUT,
              name: 'scheduled_recurrence',
              label: formatMessage(
                {
                  id: 'app.views.nudges.modalnudgeeditorbutton.inputs.scheduled_recurrence.label',
                  defaultMessage: 'Scheduled recurrence ({timezone})',
                },
                {
                  timezone: userTimezone,
                }
              ),
              component: (
                <Cron
                  value={nudge?.scheduled_recurrence}
                  options={{
                    headers: [HEADER.WEEKLY, HEADER.MONTHLY],
                  }}
                  showResultText={true}
                  locale={cronstrueLocale}
                  showResultCron={false}
                  onChange={onCronExpressionChange}
                />
              ),
            },
          ]),
      {
        required: true,
        type: INPUT_TYPES.DROPDOWN,
        name: 'recipients_type',
        label: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.recipient_type.label',
          defaultMessage: 'Who should receive this nudge?',
        }),
        objects: nudgeRecipientsType(
          formatMessage,
          isSuperAdmin,
          isSystemAdmin,
          props.currentOrganization?.is_hrbp ?? false,
          hasDirectReports,
          props.currentOrganization.name
        ),
      },
      ...(nudge?.recipients_type == NudgeRecipientsType.Filter
        ? [
            {
              required: true,
              type: INPUT_TYPES.INCLUDE_EXCLUDE_FILTER,
              name: 'recipients_filter',
              label: formatMessage({
                id: 'app.views.nudges.modalnudgeeditorbutton.inputs.recipient_filter.label',
                defaultMessage: 'Select people',
              }),
            },
          ]
        : []),
      ...(nudge?.recipients_type == NudgeRecipientsType.SpecificPeople
        ? [
            {
              required: true,
              type: INPUT_TYPES.PEOPLE_EDITOR,
              allowSelf: true,
              name: 'recipients_people',
              label: formatMessage({
                id: 'app.views.nudges.modalnudgeeditorbutton.inputs.recipient_people.label',
                defaultMessage: 'Select people',
              }),
              elasticsearchOptions: {
                getQuery: getAdminableOrUnderPersonIncludedQuery,
              },
            },
          ]
        : []),
      {
        required: true,
        type: INPUT_TYPES.TEXT,
        name: 'subject',
        label: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.subject.label',
          defaultMessage: 'What is the subject of this nudge?',
        }),
        jsonPath: '$.subject',
      },
      {
        required: true,
        type: INPUT_TYPES.TEXTAREA,
        name: 'text',
        label: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.text.label',
          defaultMessage: 'What is the body of this nudge?',
        }),
        jsonPath: '$.text',
      },
      {
        required: true,
        type: INPUT_TYPES.TEXT,
        name: 'action_url',
        label: formatMessage({
          id: 'app.views.nudges.modalnudgeeditorbutton.inputs.action_url.label',
          defaultMessage: 'Where should clicking this nudge go?',
        }),
      },
    ];
    return items;
  }, [
    nudge,
    formatMessage,
    cronstrueLocale,
    onCronExpressionChange,
    props.currentOrganization,
    isSuperAdmin,
    isSystemAdmin,
    hasDirectReports,
    getAdminableOrUnderPersonIncludedQuery,
    userTimezone,
  ]);

  const getInitNudgeCallback = useCallback(
    (type: NudgeType) => {
      return () => initNudge(type);
    },
    [initNudge]
  );

  // render inputs into separate steps
  const renderInputs = useCallback(
    (inputs) => {
      const types: NudgeType[] = [];

      const nudgeTypeCheckIn = NUDGE_TYPE_CHECK_IN(formatMessage);
      if (nudgeTypeCheckIn.isEnabled(features)) {
        types.push(nudgeTypeCheckIn);
      }

      const nudgeTypeUpdateObjectives =
        NUDGE_TYPE_UPDATE_OBJECTIVES(formatMessage);
      if (nudgeTypeUpdateObjectives.isEnabled(features)) {
        types.push(nudgeTypeUpdateObjectives);
      }

      const nudgeTypeGiveFeedbackToDirectReports =
        NUDGE_TYPE_GIVE_FEEDBACK_TO_DIRECT_REPORTS(formatMessage);
      if (nudgeTypeGiveFeedbackToDirectReports.isEnabled(features)) {
        types.push(nudgeTypeGiveFeedbackToDirectReports);
      }

      const nudgeTypeRequestFeedback =
        NUDGE_TYPE_REQUEST_FEEDBACK(formatMessage);
      if (nudgeTypeRequestFeedback.isEnabled(features)) {
        types.push(nudgeTypeRequestFeedback);
      }

      const nudgeTypeCustom = NUDGE_TYPE_CUSTOM(formatMessage);
      if (nudgeTypeCustom.isEnabled(features)) {
        types.push(nudgeTypeCustom);
      }

      return (
        <>
          {!nudge && (
            <Row className="mb-n4">
              {types.map((type, index) => (
                <Col
                  key={index}
                  className={
                    'col-12 py-2 py-md-0 mb-4 ' +
                    // if it's the last item, and we have an odd number of items
                    // make it full width
                    (index === types.length - 1 && types.length % 2 !== 0
                      ? 'col-md-12'
                      : 'col-md-6')
                  }
                  onClick={getInitNudgeCallback(type)}
                >
                  <Card
                    id={'add-nudge-type-' + type.id}
                    className="py-4 mb-0 align-items-center lift"
                    role="button"
                  >
                    <div className="avatar">
                      <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                        <i className={type.icon}></i>
                      </div>
                    </div>
                    <h3 className="mb-0 mt-3">{type.name}</h3>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
          {nudge && <Row className="nudge-edit">{inputs}</Row>}
        </>
      );
    },
    [nudge, features, getInitNudgeCallback, formatMessage]
  );

  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {inputs}
          <Row>{nudge && <Col>{submitButton}</Col>}</Row>
        </>
      );
    },
    [nudge]
  );

  const propsOnClosed = props.onClosed;

  const onClosed = useCallback(() => {
    resetNudge();
    if (propsOnClosed) {
      propsOnClosed();
    }
  }, [propsOnClosed, resetNudge]);

  return locales != null ? (
    <ModalEditorButton
      block
      isOpen={props.isOpen}
      toggle={props.toggle}
      onValidate={onValidate}
      onOpened={props.onOpened}
      onClosed={onClosed}
      onChange={onChange}
      buttonComponentGenerator={getButtonComponent}
      inline={props.inline}
      buttonClassName={props.buttonClassName}
      url={isEditing ? `nudges/${nudge?.id}` : 'nudges'}
      method={isEditing ? 'PUT' : 'POST'}
      title={title}
      modalTitle={modalTitle}
      submitText={submitText}
      anchorTrigger={anchorTrigger}
      object={nudge}
      transformObjectBeforeSubmit={transformObjectBeforeSubmit}
      callback={onSubmitCallback}
      renderModalHeader={renderModalHeader}
      renderInputs={renderInputs}
      renderForm={renderForm}
      inputs={inputs}
      translationNamespace={`nudge_${nudge?.key}`}
      translationVisibility={'U'}
    />
  ) : (
    <Loading />
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, me, authUser } = state;

  return {
    currentOrganization,
    me: me,
    authUser,
  };
};

export default connect(mapStateToProps)(React.memo(ModalNudgeEditorButton));
