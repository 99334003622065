import * as consts from '../../consts/consts';

import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  findAttachedObjectType,
  getId,
  getPrettyDate,
  renderErrorOrCallback,
} from '../../utils/util/util';
import {
  formatNamesForCsv,
  peopleArrayCountThenNameSort,
  peopleIdsAreEqual,
} from '../../utils/models/Person';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import EmptyState from '../Widgets/EmptyState';
import { FEEDBACK_TYPE_REQUEST } from '../../utils/models/Feedback';
import { FORMAT_AVATAR_GROUP } from '../Widgets/People/Filters/common';
import FeedbackCard from './FeedbackCard';
import FeedbackDeclineCard from './FeedbackDeclineCard';
import FeedbackRequestCard from './FeedbackRequestCard';
import FilterablePeopleTable from '../Widgets/People/FilterablePeopleTable';
import { Link } from 'react-router-dom';
import Modal from '../../components/SafeModal';
import ModalFeedbackEditorButton from './ModalFeedbackEditorButton';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import TagsList from '../Widgets/TagsList';
import { connect } from 'react-redux';
import { customizableSkillAndActivityIndicator } from './CustomizableFeedback';
import { loadOrRender } from '../../utils/util/formatter';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

const topicsArrayCountThenNameSort = (
  aActivitiesAndSkills,
  bActivitiesAndSkills
) => {
  const a = [
    ...(aActivitiesAndSkills.activities || []),
    ...(aActivitiesAndSkills.skills || []),
  ];
  const b = [
    ...(bActivitiesAndSkills.activities || []),
    ...(bActivitiesAndSkills.skills || []),
  ];

  // sort by topics count first, then sort by name
  if (a.length > b.length) {
    return -1;

    // if topics count is the same, sort by name
  } else if (a.length === b.length) {
    const aName = a[0]?.name;
    const bName = b[0]?.name;
    return aName < bName ? -1 : aName > bName ? 1 : 0;

    // if topics count is less, sort by topics count
  } else {
    return 1;
  }
};

const FeedbackRequestTable: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const [fetchedFeedbackRequestList, setFetchedFeedbackRequestList] =
    useState(undefined);

  const feedbackRequestList = useMemo(() => {
    if (props?.disableFetchAllRequests) {
      // @ts-expect-error
      setFetchedFeedbackRequestList(props.newFeedbackRequests);
      return props.newFeedbackRequests;
    }

    if (typeof fetchedFeedbackRequestList === 'undefined') {
      return undefined;
    }

    // if there are new feedback requests created from the
    // external modal, merge them with the fetched list
    // @ts-expect-error
    return props.newFeedbackRequests?.length > 0
      ? // @ts-expect-error
        [...props.newFeedbackRequests, ...fetchedFeedbackRequestList]
      : fetchedFeedbackRequestList;
  }, [
    fetchedFeedbackRequestList,
    props?.disableFetchAllRequests,
    props.newFeedbackRequests,
  ]);

  const [feedbackRequestToShowInModal, setFeedbackRequestToShowInModal] =
    useState(null);
  const toggleFeedbackRequestModal = () => {
    setFeedbackRequestToShowInModal(null);
  };
  const [feedbackResponsesToShowInModal, setFeedbackResponsesToShowInModal] =
    useState(null);
  const toggleFeedbackResponsesModal = () => {
    setFeedbackResponsesToShowInModal(null);
  };
  const [feedbackReminderToShowInModal, setFeedbackReminderToShowInModal] =
    useState(null);
  const toggleFeedbackReminderModal = () => {
    setFeedbackReminderToShowInModal(null);
  };

  const [sendReminderValidationErrors, setSendReminderValidationErrors] =
    useState(null);

  const onSendReminder = (feedback) => {
    ConfirmAPI.sendRequestToConfirm(
      'POST',
      '/feedback-requests/' + feedback.id + '/reminder',
      {},
      renderErrorOrCallback(
        () => {
          toggleFeedbackReminderModal();
          setFeedbackReminderToShowInModal(null);

          // show toast to user that reminder is sent
          toast.success(
            formatMessage({
              id: 'app.views.feedback.feedback_request_table.reminder_sent',
              defaultMessage: 'Reminder sent!',
            })
          );
        },
        (error) => {
          setSendReminderValidationErrors(error);
        }
      )
    );
  };

  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    if (props?.disableFetchAllRequests) {
      return;
    }
    setFetchedFeedbackRequestList(undefined);
    ElasticsearchAPI.getSentFeedbackRequests(
      userSub,
      // @ts-expect-error
      props.currentProxyPerson,
      // @ts-expect-error
      props.currentOrganization?.id,
      (newFeedbackList) => {
        setFetchedFeedbackRequestList(newFeedbackList);
      },
      (message) => {
        setErrorMessage(message);
      }
    );
  }, [
    // @ts-expect-error
    props.currentOrganization?.id,
    // @ts-expect-error
    props.currentProxyPerson,
    props?.disableFetchAllRequests,
    userSub,
  ]);

  const rows = useMemo(() => {
    // @ts-expect-error
    return feedbackRequestList?.length > 0
      ? // @ts-expect-error
        feedbackRequestList.map((r, index) => {
          // authors of associated feedback are considered completed
          const completedPeople = r.requested_people.filter((p) =>
            r.feedback?.find((f) =>
              peopleIdsAreEqual(f.author_person?.id, p?.id)
            )
          );

          // authors of declined feedback are considered declined
          const declinedPeople = r.requested_people.filter((p) =>
            r.feedback_declines?.find((f) =>
              peopleIdsAreEqual(f.author_person?.id, p?.id)
            )
          );

          // anyone not in the completed or declined list is considered pending
          const pendingPeople = r.requested_people.filter(
            (p) => !completedPeople.includes(p) && !declinedPeople.includes(p)
          );

          return {
            ...r,
            requested_people_completed: completedPeople,
            requested_people_declined: declinedPeople,
            requested_people_pending: pendingPeople,
            // set tags as merge of activities and skills
            topics: {
              customContent: customizableSkillAndActivityIndicator({
                attachedObjectType: findAttachedObjectType(
                  // @ts-expect-error
                  props.attachedContentTypes,
                  r?.content_type
                ),
                r,
              }),
              activities: r.activities || [],
              skills: r.skills || [],
            },
            actions: [
              null,
              <UncontrolledDropdown key={index}>
                <DropdownToggle
                  className="btn btn-sm btn-rounded-circle btn-white"
                  role="button"
                  style={{
                    marginLeft: '1.2rem',
                  }}
                >
                  <i
                    className="fe fe-more-horizontal"
                    style={{ position: 'relative', top: '2px' }}
                  />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem
                    onClick={() => {
                      setFeedbackResponsesToShowInModal(r);
                    }}
                  >
                    <FormattedMessage
                      id="app.views.feedback.feedback_request_table.view_responses"
                      defaultMessage="View responses"
                    />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setFeedbackRequestToShowInModal(r);
                    }}
                  >
                    <FormattedMessage
                      id="app.views.feedback.feedback_request_table.view_sent_request"
                      defaultMessage="View sent request"
                    />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setFeedbackReminderToShowInModal(r);
                    }}
                  >
                    <FormattedMessage
                      id="app.views.feedback.feedback_request_table.send_reminder"
                      defaultMessage="Send reminder"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>,
            ],
          };
        })
      : [];
  }, [feedbackRequestList, props.attachedContentTypes]);

  const formatTopicsForCsv = useCallback((topicsDict) => {
    if (!topicsDict) return;
    const topics = Object.values(topicsDict).flat();
    // @ts-expect-error
    return topics?.map((t) => t?.name || t).join(', ');
  }, []);

  const columns = useMemo(
    () => [
      {
        name: formatMessage({
          id: 'app.views.feedback.feedback_request_table.request_date',
          defaultMessage: 'Request date',
        }),
        format: (val) => {
          return getPrettyDate({ dateString: val, locale });
        },
        field: 'created_at',
      },
      {
        name: formatMessage({
          id: 'app.views.feedback.feedback_request_table.subjects',
          defaultMessage: 'Subjects',
        }),
        field: 'subject_people',
        format: FORMAT_AVATAR_GROUP,
        csvFormat: formatNamesForCsv,
        sort: (a, b) =>
          peopleArrayCountThenNameSort(a.subject_people, b.subject_people),
      },
      {
        name: formatMessage({
          id: 'app.views.feedback.feedback_request_table.topics',
          defaultMessage: 'Topics',
        }),
        field: 'topics',
        format: (value) =>
          value?.customContent ?? (
            <TagsList
              className="d-inline"
              style={{ position: 'relative', top: '-1px' }}
              activities={value.activities}
              skills={value.skills}
              isExternalUrl={true}
              truncated={true}
            />
          ),
        csvFormat: formatTopicsForCsv,
        sort: (a, b) => topicsArrayCountThenNameSort(a, b),
      },
      {
        name: formatMessage({
          id: 'app.views.feedback.feedback_request_table.pending',
          defaultMessage: 'Pending',
        }),
        field: 'requested_people_pending',
        format: FORMAT_AVATAR_GROUP,
        csvFormat: formatNamesForCsv,
        sort: (a, b) =>
          peopleArrayCountThenNameSort(
            a.requested_people_pending,
            b.requested_people_pending
          ),
      },
      {
        name: formatMessage({
          id: 'app.views.feedback.feedback_request_table.declined',
          defaultMessage: 'Declined',
        }),
        field: 'requested_people_declined',
        format: FORMAT_AVATAR_GROUP,
        csvFormat: formatNamesForCsv,
        sort: (a, b) =>
          peopleArrayCountThenNameSort(
            a.requested_people_declined,
            b.requested_people_declined
          ),
      },
      {
        name: formatMessage({
          id: 'app.views.feedback.feedback_request_table.completed',
          defaultMessage: 'Completed',
        }),
        field: 'requested_people_completed',
        format: FORMAT_AVATAR_GROUP,
        csvFormat: formatNamesForCsv,
        sort: (a, b) =>
          peopleArrayCountThenNameSort(
            a.requested_people_completed,
            b.requested_people_completed
          ),
      },
      {
        name: (
          <span>
            <i className={consts.ICONS.ACTION} />{' '}
            <FormattedMessage
              id="app.views.team.team_overview.columns.actions.name"
              defaultMessage="Actions"
            />
          </span>
        ),
        field: 'actions',
        hideFromCSV: true,
        hideFromFilters: true,
        style: {
          width: '5rem',
        },
      },
    ],
    [formatMessage, formatTopicsForCsv, locale]
  );

  const addFeedbackOrNotesButtonText = useMemo(
    () =>
      formatMessage({
        id: 'app.views.feedback.feedback_request_table.send_feedback_request',
        defaultMessage: 'Send feedback request',
      }),
    [formatMessage]
  );

  const emptyStateNewFeedbackSentCallback = useCallback(
    (newFeedbackRequest) => {
      // add new feedback sent to top of feedback request list
      // @ts-expect-error
      setFetchedFeedbackRequestList([
        newFeedbackRequest,
        ...(fetchedFeedbackRequestList || []),
      ]);
    },
    [fetchedFeedbackRequestList]
  );

  const emptyStateActionButtons = useMemo(() => {
    return (
      <ModalFeedbackEditorButton
        buttonClassName="btn-sm"
        buttonText={addFeedbackOrNotesButtonText}
        feedback={{
          type: FEEDBACK_TYPE_REQUEST(formatMessage),
          subject_people: [props.me],
        }}
        callback={emptyStateNewFeedbackSentCallback}
      />
    );
  }, [
    addFeedbackOrNotesButtonText,
    emptyStateNewFeedbackSentCallback,
    props.me,
    formatMessage,
  ]);

  const exportActionTextFunction = useCallback(
    (count) => (
      <FormattedMessage
        id="app.views.feedback.feedback_request_table.export_feedback_requests"
        defaultMessage="Export {count} requests to CSV"
        values={{ count: count }}
      />
    ),
    []
  );

  const loadOrRenderOutput = loadOrRender(
    fetchedFeedbackRequestList,
    errorMessage
  );

  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  const hasResponsesOrDeclines =
    // @ts-expect-error
    feedbackResponsesToShowInModal?.feedback?.length > 0 ||
    // @ts-expect-error
    feedbackResponsesToShowInModal?.feedback_declines?.length > 0;

  return (
    <>
      {/* @ts-expect-error */}
      {!(fetchedFeedbackRequestList?.length > 0) && (
        <Card>
          <CardBody>
            <EmptyState
              title={formatMessage({
                id: 'app.views.feedback.feedback_request_table.you_have_not_sent',
                defaultMessage: 'You have not sent any feedback requests.',
              })}
              subtitle={formatMessage({
                id: 'app.views.feedback.feedback_request_table.send_your_first',
                defaultMessage: 'Send your first feedback request.',
              })}
            >
              {emptyStateActionButtons}
            </EmptyState>
          </CardBody>
        </Card>
      )}
      {/* @ts-expect-error */}
      {fetchedFeedbackRequestList?.length > 0 && (
        <>
          {feedbackRequestToShowInModal && (
            <Modal
              isOpen={feedbackRequestToShowInModal !== null}
              toggle={toggleFeedbackRequestModal}
            >
              <ModalHeader toggle={toggleFeedbackRequestModal}>
                <FormattedMessage
                  id="app.views.feedback.feedback_request_table.view_sent_request"
                  defaultMessage="View sent request"
                />
              </ModalHeader>
              <ModalBody>
                <FeedbackRequestCard
                  feedbackRequest={feedbackRequestToShowInModal}
                  isPreview={true}
                />
              </ModalBody>
            </Modal>
          )}
          {feedbackResponsesToShowInModal && (
            <Modal
              isOpen={feedbackResponsesToShowInModal !== null}
              toggle={toggleFeedbackResponsesModal}
            >
              <ModalHeader toggle={toggleFeedbackResponsesModal}>
                <FormattedMessage
                  id="app.views.feedback.feedback_request_table.view_responses"
                  defaultMessage="View responses"
                />
              </ModalHeader>
              <ModalBody>
                {!hasResponsesOrDeclines && (
                  <EmptyState
                    title={formatMessage({
                      id: 'app.views.feedback.feedback_request_table.no_responses',
                      defaultMessage: 'Nobody has completed this feedback yet.',
                    })}
                  />
                )}
                {hasResponsesOrDeclines && (
                  <ListGroup className="list-group-flush mb-n4">
                    {/* @ts-expect-error */}
                    {feedbackResponsesToShowInModal.feedback?.map(
                      (feedback, index) => {
                        const cardOutput = (
                          <ListGroupItem
                            key={`list-group-item-${index}`}
                            className="border-0 p-0 pb-4"
                          >
                            <FeedbackCard
                              key={`feedback-card-item-${index}`}
                              feedback={{
                                ...feedback,
                                // include the full feedback request object instead
                                // of the partial one in the feedback subobject from Elasticsearch
                                feedback_request:
                                  feedbackResponsesToShowInModal,
                              }}
                              showAvatars={true}
                              // @ts-expect-error
                              bodyOnly={true}
                            />
                          </ListGroupItem>
                        );

                        return cardOutput;
                      }
                    )}
                    {/* @ts-expect-error */}
                    {feedbackResponsesToShowInModal.feedback_declines?.map(
                      (feedbackDecline, index) => {
                        const cardOutput = (
                          <ListGroupItem className="border-0 p-0 pb-4">
                            <FeedbackDeclineCard
                              key={index}
                              feedbackDecline={feedbackDecline}
                              feedbackRequest={feedbackResponsesToShowInModal}
                              showAvatars={true}
                              // @ts-expect-error
                              bodyOnly={true}
                            />
                          </ListGroupItem>
                        );

                        return cardOutput;
                      }
                    )}
                  </ListGroup>
                )}
              </ModalBody>
            </Modal>
          )}
          {feedbackReminderToShowInModal && (
            <ConfirmationDialogModal
              confirmationButtonColor="primary"
              isOpen={feedbackReminderToShowInModal !== null}
              onClosed={() => {
                setSendReminderValidationErrors(null);
              }}
              toggle={toggleFeedbackReminderModal}
              confirmCallback={() =>
                onSendReminder(feedbackReminderToShowInModal)
              }
              title={formatMessage({
                id: 'app.views.feedback.feedback_request_table.send_reminder',
                defaultMessage: 'Send reminder',
              })}
              description={formatMessage({
                id: 'app.views.feedback.feedback_request_table.are_you_sure_reminder',
                defaultMessage:
                  'Are you sure that you want to send a reminder to anyone who has not responded?',
              })}
              confirmText={formatMessage({
                id: 'app.views.feedback.feedback_request_table.send_reminder',
                defaultMessage: 'Send reminder',
              })}
              validationErrors={sendReminderValidationErrors}
            />
          )}
          <FilterablePeopleTable
            hideFilters={true}
            arrayValuesUsedForFormatting={false}
            hideExportButton={props.hideExportButton}
            headerContent={
              !props.hideHeaderContent ? (
                <div className="d-flex justify-content-end h-100">
                  <Link
                    className="align-self-center"
                    target="_blank"
                    rel="noopener noreferrer"
                    to="/profile/feedback"
                  >
                    <FormattedMessage
                      id="app.views.feedback.feedback_request_table.view_my_feedback"
                      defaultMessage="View my feedback"
                    />{' '}
                    <i className="fe fe-external-link" />
                  </Link>
                </div>
              ) : (
                ''
              )
            }
            rows={rows}
            columns={columns}
            exportActionTextFunction={exportActionTextFunction}
            getUniqueRowId={getId}
          />
        </>
      )}
    </>
  );
};

const FeedbackRequestTable_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  // for inserting new requests sent without reloading page
  newFeedbackRequests: PropTypes.array,
  disableFetchAllRequests: PropTypes.bool,
  hideHeaderContent: PropTypes.bool,
  hideExportButton: PropTypes.bool,
  attachedContentTypes: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<typeof FeedbackRequestTable_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, attachedContentTypes } =
    state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    attachedContentTypes,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(FeedbackRequestTable));
