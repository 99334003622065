import React, { FC, useMemo } from 'react';
import {
  TalentMatrixCampaignModel,
  useTalentMatrixConfiguration,
} from '../TalentMatrix';

import Editor from './Editor';
import Loading from 'views/Widgets/Loading';
import { useCampaignEditorPhase } from 'views/Administration/CampaignEditorPhaseContext';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  disabled: boolean;
  onCallback: (value: TalentMatrixCampaignModel) => void;
  value: TalentMatrixCampaignModel;
}

const GridEditorWrapper: FC<Props> = ({ disabled, onCallback, value }) => {
  const { formatMessage } = useIntl();
  const campaignContext = useCampaignEditorPhase();

  const { status, data } = useTalentMatrixConfiguration({
    campaignId: campaignContext?.campaignId,
    disabled,
  });

  const metricsAreValidForCampaign = useMemo(() => {
    if (!data) {
      return false;
    }

    // if someone changed features that caused a metric here to be unavailable
    // (e.g. someone had manager ratings on, added that metric, then disabled
    // manager ratings meaning it's no longer an available metric), show
    // an error as the frontend would fail hard if we tried to render the editor
    return (
      data.available_metrics.find((it) => it.id === value.x_axis_metric) &&
      data.available_metrics.find((it) => it.id === value.y_axis_metric)
    );
  }, [data, value.x_axis_metric, value.y_axis_metric]);

  return (
    <>
      {status === 'LOADING' ||
        (!data && (
          <Loading
            message={formatMessage({
              id: 'app.views.talent_matrix.talent_matrix_grid_editor_wrapper.message.fetching_campaign_data',
              defaultMessage: 'Fetching campaign data',
            })}
          />
        ))}
      {status === 'SUCCESS' && !!data && (
        <>
          {!metricsAreValidForCampaign && (
            <FormattedMessage
              id="app.views.talent_matrix.talent_matrix_grid_editor_wrapper.message.metrics_disabled"
              defaultMessage="You have disabled a metric used by this matrix. Add the metrics back to the cycle configuration to be able to edit this matrix again."
            />
          )}
          {!!metricsAreValidForCampaign && (
            <Editor
              availableMetrics={data.available_metrics}
              onCallback={onCallback}
              value={value}
            />
          )}
        </>
      )}
    </>
  );
};
export default GridEditorWrapper;
