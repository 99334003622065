import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { CAMPAIGN_STATUSES, getPhaseByType } from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  G2_REVIEW_LINK_PERF_ALL_DONE,
  PERFORMANCE_STEP_EVERYTHING_COMPLETE,
} from '../../consts/consts';
import {
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_SELF,
  getDirectReportsEligibleForFeedback,
  getStepNumber,
} from '../../utils/models/Performance';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  continuousFeedbackGivingFeedbackIsEnabled,
  continuousFeedbackRecognitionIsEnabled,
} from '../../utils/util/features';
import { useLocation, withRouter } from 'react-router';
import { type RouteComponentProps, Link } from 'react-router-dom';

import AngryPeopleImage from '../../assets/img/illustrations/people-angry.png';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import Confetti from 'react-dom-confetti';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import HappyPeopleImage from '../../assets/img/illustrations/people-happy.png';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import PerformancePage from './PerformancePage';
import {
  Campaign,
  Features,
  Me,
  Organization,
  Person,
  ReduxState,
} from 'types';
import UnhappyPeopleImage from '../../assets/img/illustrations/people-unhappy.png';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { toPersonIdToSurveyResponseLookup } from 'utils/models/SurveyResponse';

const confettiConfig = {
  angle: '90',
  spread: '140',
  startVelocity: '56',
  elementCount: '200',
  dragFriction: '0.09',
  duration: '3440',
  stagger: 0,
  width: '10px',
  height: '14px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const confettiContainerStyle = {
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  pointerEvents: 'none',
};

const PerformanceStepEverythingComplete: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [confettiShouldFire, setConfettiShouldFire] = useState(false);
  const [selectedFeelingOption, setSelectedFeelingOption] = useState(
    typeof props.currentPerfSurveyResponse?.responses?.feeling_end ===
      'undefined'
      ? -1
      : props.currentPerfSurveyResponse?.responses?.feeling_end
  );
  const [cesScoreSelected, setCesScoreSelected] = useState(
    typeof props.currentPerfSurveyResponse?.responses?.confirm_product_ces ===
      'undefined'
      ? -1
      : props.currentPerfSurveyResponse?.responses?.confirm_product_ces
  );

  const continuousRecognitionOrFeedbackFeatureIsEnabled = useMemo(
    () =>
      continuousFeedbackRecognitionIsEnabled(props.features) ||
      continuousFeedbackGivingFeedbackIsEnabled(props.features),
    [props.features]
  );

  const intl = useIntl();
  const { locale } = intl;

  const campaign = props.campaign;
  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(data);
      }
    },
    [propsSetCurrentPerfSurveyResponse]
  );

  const feelingOptions = useMemo(
    () => [
      {
        id: 3,
        image: HappyPeopleImage,
        input: intl.formatMessage({
          id: 'app.views.performance.performance_step_everything_complete.input_3',
          defaultMessage: 'Good',
          description:
            'Response option to how are you feeling about doing this perf cycle',
        }),
      },
      {
        id: 2,
        image: UnhappyPeopleImage,
        input: intl.formatMessage({
          id: 'app.views.performance.performance_step_everything_complete.input_2',
          defaultMessage: 'Okay',
          description:
            'Response option to how are you feeling about doing this perf cycle',
        }),
      },
      {
        id: 1,
        image: AngryPeopleImage,
        input: intl.formatMessage({
          id: 'app.views.performance.performance_step_everything_complete.input_1',
          defaultMessage: "Don't ask",
          description:
            'Response option to how are you feeling about doing this perf cycle',
        }),
      },
    ],
    [intl]
  );

  const directReports = useMemo(
    () =>
      campaign &&
      getDirectReportsEligibleForFeedback(
        props.me,
        campaign,
        // @ts-expect-error
        campaign.relationships,
        // @ts-expect-error
        toPersonIdToSurveyResponseLookup(campaign.survey_responses),
        props.demoPeople
      ),
    [campaign, props.demoPeople, props.me]
  );

  const selfPhase = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_SELF),
    [campaign]
  );
  const othersPhase = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_OTHERS),
    [campaign]
  );
  const evaluationPhase = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_EVALUATION),
    [campaign]
  );

  // if manager, this is the end of the evaluation phase, otherwise
  // it's the end of the other phase if that exists, else it's the end
  // of the self phase (for campaigns without an others phase)
  const phaseEndDateString = useMemo(
    () =>
      campaign &&
      (evaluationPhase && directReports?.length > 0
        ? evaluationPhase
        : othersPhase
        ? othersPhase
        : selfPhase
      )?.end_date,
    [directReports?.length, evaluationPhase, othersPhase, campaign, selfPhase]
  );

  // fire confetti only on option being selected
  // (to increase odds of person clicking an answer)
  useEffect(() => {
    if (selectedFeelingOption !== -1) {
      setConfettiShouldFire(true);
    }
  }, [selectedFeelingOption]);

  // reset confetti so it can be fired when clicking page
  useEffect(() => {
    if (confettiShouldFire) {
      setConfettiShouldFire(false);
    }
  }, [confettiShouldFire]);

  const onPageClick = useCallback(() => {
    // show confetti when modal opens
    setConfettiShouldFire(true);
  }, []);

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const transformFeelingEndObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        id: object.id,
        campaign: campaign.id,
        step: currentStepNumber,
        responses: {
          feeling_end: object.feeling_end,
        },
      };
    },
    [currentStepNumber, campaign.id]
  );

  const transformConfirmProductFeedbackObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        id: object.id,
        campaign: campaign.id,
        step: currentStepNumber,
        responses: {
          confirm_product_feedback: object.confirm_product_feedback || '',
        },
      };
    },
    [currentStepNumber, campaign.id]
  );

  const feelingObject = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? props.currentPerfSurveyResponse.id
        : undefined,
      feeling_end: selectedFeelingOption,
    }),
    [props.currentPerfSurveyResponse, selectedFeelingOption]
  );

  const productFeedbackObject = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? props.currentPerfSurveyResponse.id
        : undefined,
      confirm_product_feedback:
        props.currentPerfSurveyResponse?.confirm_product_feedback,
      confirm_product_ces: props.currentPerfSurveyResponse?.confirm_product_ces,
    }),
    [props.currentPerfSurveyResponse]
  );

  const onChange = useCallback((o) => {
    setSelectedFeelingOption(o.feeling_end);
  }, []);

  const productFeedbackSubmitCallback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(data);
      }
    },
    [propsSetCurrentPerfSurveyResponse]
  );

  const productFeedbackHasBeenCompleted = useMemo(
    () =>
      typeof props.currentPerfSurveyResponse?.responses
        ?.confirm_product_feedback !== 'undefined',
    [props.currentPerfSurveyResponse?.responses?.confirm_product_feedback]
  );

  const allowSolicitingG2Review = useMemo(() => {
    return (
      selectedFeelingOption === 3 &&
      // 4 and 5 are the "good" options for CES
      cesScoreSelected >= 4 &&
      props.features?.solicit_g2_reviews?.enabled
    );
  }, [
    cesScoreSelected,
    props.features?.solicit_g2_reviews?.enabled,
    selectedFeelingOption,
  ]);

  const cesScoreClicked = useCallback(
    (val) => {
      setCesScoreSelected(val);

      if (
        // @ts-expect-error
        campaign.status === CAMPAIGN_STATUSES.DEMO ||
        !props.currentPerfSurveyResponse?.id
      ) {
        return;
      }

      // send CES score to the server
      ConfirmAPI.sendRequestToConfirm(
        'PATCH',
        '/survey-responses/' + props.currentPerfSurveyResponse?.id,
        {
          campaign: campaign.id,
          step: currentStepNumber,
          responses: {
            confirm_product_ces: val,
          },
        },
        (data, error, hardErrorMessage) => {
          // no-op in success case, but should log error
          // in failure case
          if (error || hardErrorMessage) {
            console.error(
              'Failed to send CES to backend: ' +
                JSON.stringify(error || hardErrorMessage)
            );
          }
        }
      );
    },
    [
      campaign.id,
      // @ts-expect-error
      campaign.status,
      currentStepNumber,
      props.currentPerfSurveyResponse?.id,
    ]
  );

  return (
    <PerformancePage
      campaign={campaign}
      title={PERFORMANCE_STEP_EVERYTHING_COMPLETE(formatMessage).name}
      // For card buttons, that's enough contrast so only include the
      // first page content in a card
      wrapBodyInCard={false}
    >
      <></>
      {/* @ts-expect-error */}
      <div style={confettiContainerStyle}>
        <Confetti
          className={'react-dom-confetti-overlay'}
          active={confettiShouldFire}
          // @ts-expect-error
          config={confettiConfig}
        />
      </div>
      <Row>
        <Col>
          <div className="mb-4" onClick={onPageClick}>
            {campaign?.properties?.is_engagement_survey_only ? (
              <FormattedMessage
                id="app.views.performance.performance_step_everything_complete.feeling.engagement_survey_only"
                defaultMessage="How are you feeling about doing this survey now that you are done?"
              />
            ) : (
              <FormattedMessage
                id="app.views.performance.performance_step_everything_complete.feeling"
                defaultMessage="How are you feeling about doing this cycle now that you are done?"
              />
            )}
          </div>
          <ValidatedForm
            method={'PATCH'}
            url={
              // @ts-expect-error
              campaign.status === CAMPAIGN_STATUSES.DEMO
                ? undefined
                : 'survey-responses'
            }
            buttonIsBlock={false}
            object={feelingObject}
            onChange={onChange}
            submitOnChange={true}
            inputs={[
              {
                type: INPUT_TYPES.BUTTON_OPTIONS,
                name: 'feeling_end',
                allowCustom: true,
                className: '',
                buttonClassName: 'py-4 mb-0 align-items-center lift card',
                selectedButtonClassName:
                  'py-4 mb-0 align-items-center lift card border-primary bg-primary-soft',
                options: feelingOptions,
              },
            ]}
            callback={callback}
            transformObjectBeforeSubmit={transformFeelingEndObjectBeforeSubmit}
          />
          {selectedFeelingOption !== -1 && (
            <>
              <div className="mt-5 mb-4" onClick={onPageClick}>
                <span className="fw-bold">
                  {'🎉 '}
                  <FormattedMessage
                    id="app.views.performance.performance_step_everything_complete.completed_everything"
                    defaultMessage="You've completed everything!"
                  />
                </span>{' '}
                <FormattedMessage
                  id="app.views.performance.performance_step_everything_complete.make_changes"
                  defaultMessage="
                You can make changes until {date} at 11:59pm your local time.
                "
                  values={{
                    date: getPrettyDate({
                      dateString: phaseEndDateString,
                      locale,
                    }),
                  }}
                />
                {continuousRecognitionOrFeedbackFeatureIsEnabled && (
                  <div className="text-muted small">
                    <FormattedMessage
                      id="app.views.performance.performance_step_everything_complete.give_feedback_after"
                      defaultMessage="Want to give feedback or recognition at any time? <link>Learn how</link>"
                      values={{
                        link: (chunks) => (
                          <>
                            <UncontrolledPopover
                              trigger="hover"
                              target="learn-how-popover"
                            >
                              <FormattedMessage
                                id="app.views.performance.performance_step_everything_complete.click_the_add_button"
                                defaultMessage="Click the <span>Add</span> button at the top of the page after you exit the cycle. <section>You can give feedback or recognition at any time this way, even after the cycle is over.</section>"
                                values={{
                                  span: (chunks) => (
                                    <span
                                      style={{
                                        borderRadius: 100,
                                      }}
                                      className="mx-1 btn-sm bg-primary-soft text-primary border-0 px-3 py-2"
                                    >
                                      <i className="fe fe-plus me-1 small"></i>
                                      {chunks}
                                    </span>
                                  ),
                                  section: (chunks) => (
                                    <div className="mt-4">{chunks}</div>
                                  ),
                                }}
                              />
                            </UncontrolledPopover>
                            <span
                              className="text-primary"
                              role="button"
                              id="learn-how-popover"
                            >
                              {chunks}
                            </span>
                          </>
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {selectedFeelingOption !== -1 && (
            <>
              <Card className="mt-5">
                {(cesScoreSelected === -1 ||
                  !productFeedbackHasBeenCompleted) && (
                  <>
                    <CardHeader>
                      <CardHeaderTitle className="text-center font-lg">
                        <FormattedMessage
                          id="app.views.performance.performance_step_everything_complete.ces_question.header"
                          defaultMessage="How easy to use was Confirm to complete {campaign}?"
                          values={{
                            campaign: campaign.name,
                          }}
                        />
                      </CardHeaderTitle>
                    </CardHeader>
                  </>
                )}
                {selectedFeelingOption !== -1 &&
                  cesScoreSelected !== -1 &&
                  productFeedbackHasBeenCompleted && (
                    <CardHeader>
                      <CardHeaderTitle>
                        {'🙌 '}
                        <FormattedMessage
                          id="app.views.performance.performance_step_everything_complete.thanks_feedback"
                          defaultMessage="Thank you for sharing your feedback!"
                        />
                      </CardHeaderTitle>
                    </CardHeader>
                  )}
                <CardBody>
                  {(cesScoreSelected === -1 ||
                    !productFeedbackHasBeenCompleted) && (
                    <>
                      <Row className="justify-content-center">
                        {[1, 2, 3, 4, 5].map((score) => (
                          <Col key={score} className="col-2 pe-0">
                            <Button
                              className="w-100 py-4"
                              color={
                                cesScoreSelected === score ? 'primary' : 'white'
                              }
                              onClick={() => cesScoreClicked(score)}
                            >
                              {score}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                      <Row className="mt-3 text-muted small justify-content-center">
                        <Col className="col-10">
                          <Row className="justify-content-between">
                            <Col className="col-auto">
                              <FormattedMessage
                                id="app.views.performance.performance_step_everything_complete.difficult"
                                defaultMessage="Difficult"
                              />
                            </Col>
                            <Col className="col-auto pe-0">
                              <FormattedMessage
                                id="app.views.performance.performance_step_everything_complete.easy"
                                defaultMessage="Easy"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  {selectedFeelingOption !== -1 &&
                    cesScoreSelected !== -1 &&
                    !productFeedbackHasBeenCompleted && (
                      <Row className="pt-4 justify-content-center">
                        <Col className="col-10 pe-0">
                          <ValidatedForm
                            buttonClassName="mt-0"
                            buttonIsBlock={false}
                            method={'PATCH'}
                            url={
                              // @ts-expect-error
                              campaign.status === CAMPAIGN_STATUSES.DEMO
                                ? undefined
                                : 'survey-responses'
                            }
                            object={productFeedbackObject}
                            transformObjectBeforeSubmit={
                              transformConfirmProductFeedbackObjectBeforeSubmit
                            }
                            inputs={[
                              {
                                className: 'mb-0',
                                type: INPUT_TYPES.TEXTAREA,
                                name: 'confirm_product_feedback',
                                minRows: 3,
                                label: intl.formatMessage({
                                  id: 'app.views.performance.performance_step_everything_complete.how_can_confirm',
                                  defaultMessage:
                                    'How can the Confirm team improve your experience using Confirm?',
                                }),
                                helperText: intl.formatMessage({
                                  id: 'app.views.performance.performance_step_everything_complete.your_feedback',
                                  defaultMessage: 'Your feedback is optional.',
                                }),
                              },
                            ]}
                            callback={productFeedbackSubmitCallback}
                            submitText={formatMessage({
                              id: 'app.views.performance.performance_step_everything_complete.submit_text.submit',
                              defaultMessage: 'Submit',
                            })}
                          />
                        </Col>
                      </Row>
                    )}
                  {selectedFeelingOption !== -1 &&
                    cesScoreSelected !== -1 &&
                    productFeedbackHasBeenCompleted && (
                      <Row>
                        <Col>
                          {allowSolicitingG2Review && (
                            <div>
                              <p>
                                {'📢 '}
                                <FormattedMessage
                                  id="app.views.performance.performance_step_everything_complete.confirm_mission"
                                  defaultMessage="<span>Confirm is on a mission</span> to ensure everyone is
                        rightly recognized for the difference that they make at work."
                                  values={{
                                    span: (chunks) => (
                                      <span className="fw-bold">{chunks}</span>
                                    ),
                                  }}
                                />
                                <br />
                                <FormattedMessage
                                  id="app.views.performance.performance_step_everything_complete.spread_the_word"
                                  defaultMessage="If you believe in our mission, would you be willing to spread the word about Confirm?"
                                />
                              </p>
                              <Link
                                // @ts-expect-error
                                tag="button"
                                to={{ pathname: G2_REVIEW_LINK_PERF_ALL_DONE }}
                                className="btn btn-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FormattedMessage
                                  id="app.views.performance.performance_step_everything_complete.write_g2_review"
                                  defaultMessage="Yes, review Confirm on G2"
                                />
                              </Link>
                              <span className="ps-3 text-muted">
                                <FormattedMessage
                                  id="app.views.performance.performance_step_everything_complete.or_return_to_home"
                                  defaultMessage="or <span>return to home</span>"
                                  values={{
                                    span: (chunks) => (
                                      <Link to={'/'} className="text-muted">
                                        {chunks}
                                      </Link>
                                    ),
                                  }}
                                />
                              </span>
                            </div>
                          )}
                          {!allowSolicitingG2Review && (
                            <Link
                              // @ts-expect-error
                              tag="button"
                              to={'/'}
                              className="btn btn-primary"
                            >
                              <FormattedMessage
                                id="app.views.performance.performance_step_everything_complete.return_home"
                                defaultMessage="Return to home"
                              />
                            </Link>
                          )}
                        </Col>
                      </Row>
                    )}
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </PerformancePage>
  );
};

interface Props extends RouteComponentProps {
  me: Me;
  features: Features;
  currentOrganization: Organization;
  currentPerfSurveyResponse: any;
  currentProxyPerson?: Person;
  setCurrentPerfSurveyResponse: (changes: any) => void;
  campaign: Campaign;
  setCampaign: (changes: any) => void;
  demoPeople: Person[];
}

const mapStateToProps = (state: ReduxState) => {
  const {
    me,
    features,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  } = state;

  return {
    me,
    features,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformanceStepEverythingComplete)));
