import { Button, ButtonGroup, Col, Row, UncontrolledPopover } from 'reactstrap';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ButtonOptionsInput: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const propsOnChange = props.onChange;
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;
  const incomingValue = props.value;
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (propsRequired) {
      const isValid = incomingValue != null;
      setValidationMessage(
        isValid
          ? null
          : formatMessage({
              id: 'app.widgets.inputs.button_option.required',
              defaultMessage: 'Please select an option.',
            })
      );
    } else {
      setValidationMessage(null);
    }
  }, [incomingValue, propsRequired, formatMessage]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  const onChange = useCallback(
    (id) => {
      if (propsOnChange) {
        propsOnChange(id);
      }
    },
    [propsOnChange]
  );

  if (props.allowCustom) {
    return (
      // @ts-expect-error
      <Row className={props.className}>
        {props.options.map((o, index) => (
          <Col key={index} className="col-4 col-md-3 py-2 py-md-0">
            <button
              // we need this to ensure clicking the button
              // doesn't submit the form
              type="button"
              // @ts-expect-error
              className={
                // @ts-expect-error
                props.value === o.id
                  ? props.selectedButtonClassName
                  : props.buttonClassName
              }
              // @ts-expect-error
              onClick={() => onChange(o.id)}
              // @ts-expect-error
              autoFocus={
                // @ts-expect-error
                props.autoFocus && (index === 0 || props.value === o.id)
              }
            >
              {/* @ts-expect-error */}
              {o.name && <span>{o.name}</span>}
              {/* @ts-expect-error */}
              {o.image && <img className="w-75 mb-4" src={o.image} />}
              {/* @ts-expect-error */}
              {o.input && <h3 className="mb-0 text-center">{o.input}</h3>}
            </button>
          </Col>
        ))}
      </Row>
    );
  } else {
    return (
      // @ts-expect-error
      <ButtonGroup className={props.className}>
        {props.options?.map((o, index) => (
          <Fragment key={index}>
            {/* @ts-expect-error */}
            <Button
              id={'button-option-' + index}
              // @ts-expect-error
              color={props.value === o.id ? 'primary' : 'light'}
              className={
                // @ts-expect-error
                props.value === o.id
                  ? props.selectedButtonClassName
                  : props.buttonClassName
              }
              role="button"
              style={{ marginLeft: 1 }}
              disabled={props.disabled}
              // @ts-expect-error
              onClick={() => onChange(o.id)}
            >
              {/* @ts-expect-error */}
              {o.icon && <i className={o.icon + ' me-2'} />}
              {/* @ts-expect-error */}
              {o.name && <span>{o.name}</span>}
              {/* @ts-expect-error */}
              {o.image && <img className="w-75 mb-4" src={o.image} />}
              {/* @ts-expect-error */}
              {o.input && <h3 className="mb-0">{o.input}</h3>}
            </Button>
            {/* @ts-expect-error */}
            {o.popoverContent && (
              <UncontrolledPopover
                placement="bottom"
                trigger="hover"
                target={'button-option-' + index}
              >
                {/* @ts-expect-error */}
                {o.popoverContent}
              </UncontrolledPopover>
            )}
          </Fragment>
        ))}
      </ButtonGroup>
    );
  }
};

const ButtonOptionsInput_propTypes = {
  className: PropTypes.string,
  allowCustom: PropTypes.bool,
  buttonClassName: PropTypes.string,
  selectedButtonClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onValidChange: PropTypes.func,
  required: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof ButtonOptionsInput_propTypes>;

export default React.memo(ButtonOptionsInput);
