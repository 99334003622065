import { Organization, ReduxState } from 'types';
import React, { useCallback, useEffect, useState } from 'react';

import ConfirmAPI from 'utils/api/ConfirmAPI';
import { useInterval } from 'utils/util/hooks';
import { useSelector } from 'react-redux';
import OutdatedDashboardBanner from '../../views/Widgets/Dashboards/OutdatedDashboardBanner';

const POLL_FREQUENCY = 1000;

export type Completion = {
  progress: number;
  total: number;
};

type ApiStatusResponse = {
  status: string;
  progress: number;
  total: number;
};

type TaskDescriptor = {
  task_key: string;
  status: string;
};

type UseBackgroundTaskParams<T = any> = {
  disabled?: boolean;
  taskDescriptor?: TaskDescriptor;
  onTaskCompleted: (data: T) => void;
  onError: (data: object) => void;
};

export const useBackgroundTask = ({
  disabled = false,
  taskDescriptor,
  onTaskCompleted,
  onError,
}: UseBackgroundTaskParams) => {
  const currentProxyPersonEmail = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );
  const currentOrganization = useSelector<ReduxState, Organization | undefined>(
    (state) => state.currentOrganization
  );

  const [completion, setCompletion] = useState<Completion>({
    progress: 0,
    total: 100,
  });

  const resetInternalState = useCallback(() => {
    setCompletion({ progress: 0, total: 100 });
  }, []);

  useEffect(() => {
    resetInternalState();
  }, [resetInternalState, taskDescriptor?.task_key]);

  const handleOnTaskCompleted = useCallback(
    (data) => {
      onTaskCompleted(data);
    },
    [onTaskCompleted]
  );

  const handleOnError = useCallback(
    (error) => {
      onError(error);
    },
    [onError]
  );

  const pollOnBackgroundTask = useCallback(() => {
    if (!taskDescriptor?.task_key) {
      return;
    }
    ConfirmAPI.sendRequestToConfirm(
      'GET',
      '/backgroundtasks/' + taskDescriptor.task_key + '/status',
      {
        proxy: currentProxyPersonEmail,
        organization_id: currentOrganization?.id,
      },
      (data_status: ApiStatusResponse) => {
        setCompletion(data_status);
        if (data_status?.status === 'C') {
          ConfirmAPI.sendRequestToConfirm(
            'GET',
            '/backgroundtasks/' + taskDescriptor.task_key + '/output',
            {
              proxy: currentProxyPersonEmail,
              organization_id: currentOrganization?.id,
            },
            (response, error) => {
              if (error) {
                handleOnError(error);
                return;
              }
              handleOnTaskCompleted(response);
            }
          );
        }
      }
    );
  }, [
    taskDescriptor?.task_key,
    currentProxyPersonEmail,
    currentOrganization?.id,
    handleOnTaskCompleted,
    handleOnError,
  ]);

  useInterval(
    pollOnBackgroundTask,
    POLL_FREQUENCY,
    !!taskDescriptor?.task_key &&
      taskDescriptor?.status === 'submitted' &&
      !disabled
  );

  return { completion };
};

export const usePageStatusIndicator = (
  backgroundTaskKey,
  handleBackgroundTaskCompleted,
  setPageStatusIndicator
) => {
  useEffect(() => {
    if (backgroundTaskKey) {
      setPageStatusIndicator(
        <OutdatedDashboardBanner
          backgroundTaskKey={backgroundTaskKey}
          onTaskCompleted={handleBackgroundTaskCompleted}
        />
      );
    } else {
      setPageStatusIndicator(undefined);
    }
  }, [
    backgroundTaskKey,
    handleBackgroundTaskCompleted,
    setPageStatusIndicator,
  ]);
};
