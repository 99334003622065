import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  addMonths,
  getFriendlyUserFacingErrorObjectAndMessage,
} from '../../utils/util/util';
import {
  compareActivityDates,
  getContributionPerson,
  getQuarter,
  getTimeframeText,
  getTimeframeTextDict,
} from '../../utils/models/Activity';
import {
  getPersonDisplayTitleHtml,
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import ActivityList from '../Activities/ActivityList';
import Avatar from '../Widgets/People/Avatar';
import { CAMPAIGN_STATUSES } from '../../utils/models/Campaign';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import Loading from '../Widgets/Loading';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { insertFeedbackIntoActivities } from '../../utils/models/Feedback';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { withRouter, type RouteComponentProps } from 'react-router-dom';
import { ReduxState } from 'types';

const PersonTimelineActivities: FC<Props> = ({
  isDemoOrPreviewMode = false,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [isMounted, setIsMounted] = useState(false);
  const [collapseActivitiesBeforeDate, setCollapseActivitiesBeforeDate] =
    useState(props.collapseActivitiesBeforeDate);
  const [fetchedActivities, setFetchedActivities] = useState(props.activities);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [highlights, setHighlights] = useState(
    // @ts-expect-error
    (props?.surveyResponse?.highlights || []).map((a) => a.id)
  );

  const { user } = useAuth0();
  const userSub = user?.sub;
  // @ts-expect-error
  const currentOrgId = props.currentOrganization?.id;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const surveyResponseHighlights =
      // @ts-expect-error
      props.surveyResponse?.highlights?.map((a) => a.id) || [];
    setHighlights(surveyResponseHighlights);
  }, [props.surveyResponse]);

  // @ts-expect-error
  const isMe = peopleIdsAreEqual(props.me?.id, props.person?.id);

  // Fetch activities associated with this person (if not passed in as a prop already)
  useEffect(() => {
    // if passed in, no need to fetch it from the backend
    if (props.activities) {
      setFetchedActivities(props.activities);
      return;
    }

    if (!isMounted) {
      return;
    }

    if (userSub && currentOrgId) {
      setIsLoading(true);
      ElasticsearchAPI.getActivitiesForContributorPerson(
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        currentOrgId,
        // @ts-expect-error
        props.person.id,
        // @ts-expect-error
        undefined,
        (newActivities) => {
          if (isMounted) {
            setIsLoading(false);
            setFetchedActivities(newActivities);
          }
        },
        (message) => {
          setErrorMessage(message);
        },
        props.showManagerOnlyPerformanceDetails
      );
    }
    // reload page when new activities fetched
  }, [
    currentOrgId,
    isMounted,
    props.activities,
    // @ts-expect-error
    props.currentProxyPerson,
    props.person,
    props.showManagerOnlyPerformanceDetails,
    userSub,
  ]);

  // combined fetched activities with any "after-the-fact" activities passed
  // in, e.g. via Performance cycles where you don't want people to leave
  // the screen
  const compiledActivities = useMemo(() => {
    // @ts-expect-error
    if (!(fetchedActivities?.length > 0)) {
      return props.addedActivities ? props.addedActivities : [];
    }

    // @ts-expect-error
    if (!(props.addedActivities?.length > 0)) {
      return fetchedActivities;
    }

    // insert any added activities that don't exist in fetched activities
    // @ts-expect-error
    return props.addedActivities.reduce((acc, a) => {
      if (
        acc &&
        // @ts-expect-error
        acc.findIndex((a2) => a2.id.toString() === a.id.toString()) === -1
      ) {
        // @ts-expect-error
        return [a, ...acc].sort(compareActivityDates);
      } else {
        // @ts-expect-error
        return acc.map((a2) => (a2.id.toString() === a.id.toString() ? a : a2));
      }
    }, fetchedActivities);
  }, [fetchedActivities, props.addedActivities]);

  const activities = useMemo(
    // @ts-expect-error
    () => insertFeedbackIntoActivities(compiledActivities, props.feedbackList),
    [compiledActivities, props.feedbackList]
  );

  // @ts-expect-error
  const propsGetActivities = props.getActivities;
  useEffect(() => {
    // if getFeedback function is provided, every time activities are updated,
    // return any feedback attached to these activities
    if (propsGetActivities && activities) {
      return propsGetActivities(activities);
    }
  }, [activities, propsGetActivities]);

  const propsGetFeedback = props.getFeedback;
  useEffect(() => {
    // if getFeedback function is provided, every time activities are updated,
    // return any feedback attached to these activities
    if (propsGetFeedback && activities) {
      return propsGetFeedback(
        activities.reduce((acc, activity) => {
          const relevantContributions = activity.contributions.filter((c) =>
            props.person
              ? peopleObjectsAreEqual(getContributionPerson(c), props.person)
              : true
          );
          if (relevantContributions?.length > 0) {
            const newFeedback = relevantContributions.reduce((acc2, c) => {
              if (c.contribution_feedback?.length > 0) {
                return [...c.contribution_feedback, ...acc2];
              }
              return acc2;
            }, []);

            if (newFeedback?.length > 0) {
              return [...newFeedback, ...acc];
            }
          }

          return acc;
        }, [])
      );
    }
  }, [activities, props.person, propsGetFeedback]);

  const allQuarters = useMemo(() => {
    let minJoinDateOrCampaignStartDate = addMonths(new Date(), -12);
    // @ts-expect-error
    if (props.person?.date_joined) {
      // @ts-expect-error
      minJoinDateOrCampaignStartDate = Math.min(
        // @ts-expect-error
        new Date(props.person?.date_joined),
        minJoinDateOrCampaignStartDate
      );
    }
    if (props.collapseActivitiesBeforeDate) {
      // @ts-expect-error
      minJoinDateOrCampaignStartDate = Math.min(
        // @ts-expect-error
        new Date(props.collapseActivitiesBeforeDate),
        minJoinDateOrCampaignStartDate
      );
    }

    const oldestActivityOrJoinDate = activities?.reduce((oldestDate, a) => {
      const activityDate = new Date(a?.date_completed);
      if (a?.date_completed && activityDate < oldestDate) {
        return activityDate;
      } else {
        return oldestDate;
      }
    }, minJoinDateOrCampaignStartDate);

    return getTimeframeTextDict(oldestActivityOrJoinDate);
  }, [
    activities,
    props.collapseActivitiesBeforeDate,
    // @ts-expect-error
    props.person?.date_joined,
  ]);

  const activitiesByPeriod = useMemo(() => {
    const categorizedActivities = activities?.reduce((arr, a) => {
      const q = getTimeframeText(a?.date_completed);

      // @ts-expect-error
      if (typeof arr[q] === 'undefined') {
        // @ts-expect-error
        arr[q] = [a];
      } else {
        // @ts-expect-error
        arr[q] = [...arr[q], a];
      }

      return arr;
    }, allQuarters);

    // When we are showing the timeline as part of perf, we want
    // to group all of the activities that have ended since the
    // campaign's coverage start date together
    if (props.aggregateCampaignActivities) {
      const campaignStart = getTimeframeText(
        props.collapseActivitiesBeforeDate
      );

      // Assumes that the order is chronological (an assumption we've
      // already been making elsewhere in this code)
      // TODO: Potentially look at sorting this
      const dates = Object.keys(categorizedActivities);
      const campaignCutoff = dates.findIndex(
        (q) => getQuarter(q) === getQuarter(campaignStart)
      );

      // Once we find the cutoff in the date list, we group all the dates
      // that come at or before the cutoff into a single bucket
      if (campaignCutoff !== -1) {
        const campaignDates = dates.slice(0, campaignCutoff + 1);
        const nonCampaignDates = dates.slice(campaignCutoff + 1);
        const campaignActivities = campaignDates.reduce(
          (acc, date) => acc.concat(categorizedActivities[date]),
          []
        );

        const campaignLabel = formatMessage(
          {
            id: 'app.views.person.person_timeline_activities.quarter_to_present',
            defaultMessage: '{quarter} - present',
          },
          { quarter: getQuarter(campaignStart) }
        );

        const campaignCategorizedActivities = {};
        campaignCategorizedActivities[campaignLabel] = {
          // Specify date here to use as defaultDateString
          // (for default Activity creation)
          // @ts-expect-error
          date: campaignStart.toString(),
          activities: campaignActivities,
        };
        nonCampaignDates.forEach(
          (date) =>
            (campaignCategorizedActivities[date] = categorizedActivities[date])
        );
        return campaignCategorizedActivities;
      }
    }

    return categorizedActivities;
  }, [
    activities,
    allQuarters,
    props.aggregateCampaignActivities,
    props.collapseActivitiesBeforeDate,
    formatMessage,
  ]);

  const periodKeysList = useMemo(
    () => (activitiesByPeriod ? Object.keys(activitiesByPeriod) : undefined),
    [activitiesByPeriod]
  );

  const collapsePeriodsBeyondNumber = useMemo(() => {
    if (props.aggregateCampaignActivities) {
      // If this is displaying the campaign grouping, and there is a date
      // for collapsing, then we assume that the 0 index is the (campaign
      // start date to present) grouping, and we collapse everything afterwards
      // (ie. after index 0). Otherwise, we do not collapse anything
      return collapseActivitiesBeforeDate ? 0 : undefined;
    }

    // @ts-expect-error
    if (!collapseActivitiesBeforeDate || !(periodKeysList?.length > 0)) {
      return undefined;
    }

    // @ts-expect-error
    const cutoffIndex = periodKeysList.findIndex(
      (q) =>
        getQuarter(q) ===
        getQuarter(getTimeframeText(collapseActivitiesBeforeDate))
    );

    if (cutoffIndex === -1) {
      return undefined;
    } else {
      return cutoffIndex;
    }
  }, [
    collapseActivitiesBeforeDate,
    props.aggregateCampaignActivities,
    periodKeysList,
  ]);

  const addButtonText = useMemo(() => {
    // @ts-expect-error
    if (props.addButtonText) {
      // @ts-expect-error
      return props.addButtonText;
    }
    return isMe
      ? formatMessage({
          id: 'app.views.person.person_timeline_activities.add_to_my_resume',
          defaultMessage: 'Add to my resume',
        })
      : formatMessage(
          {
            id: 'app.views.person.person_timeline_activities.add_to_someones_resume',
            defaultMessage: "Add to {personName}'s resume",
          },
          {
            // @ts-expect-error
            personName: props.person?.given_name?.trim(),
          }
        );
    // @ts-expect-error
  }, [isMe, props.person, props.addButtonText, formatMessage]);

  const updateHighlights = useCallback(
    (updatedHighlights) => {
      // @ts-expect-error
      if (props.campaign.status && props?.surveyResponse?.id) {
        // @ts-expect-error
        if (props.campaign.status === CAMPAIGN_STATUSES.DEMO) {
          setHighlights(updatedHighlights);
          return;
        }

        ConfirmAPI.sendRequestToConfirm(
          'PATCH',
          // @ts-expect-error
          'survey-responses/' + props.surveyResponse.id,
          {
            // @ts-expect-error
            id: props.surveyResponse.id,
            highlights: updatedHighlights,
          },
          (data, error, hardErrorMessage) => {
            if (data) {
              setHighlights(updatedHighlights);
            }

            if (error || hardErrorMessage) {
              const [errorObject, friendlyErrorMessage] =
                getFriendlyUserFacingErrorObjectAndMessage(
                  error,
                  hardErrorMessage
                );
              console.error(
                'Error updating highlight: ' + JSON.stringify(errorObject)
              );
              toast.error(friendlyErrorMessage);
            }
          }
        );
      }
    },
    // @ts-expect-error
    [props.campaign?.status, props?.surveyResponse?.id]
  );

  const addHighlight = useCallback(
    (activityId) => {
      const activity = activities.find((a) => a.id === activityId);
      const allHighlights = [...highlights, activity.id];
      updateHighlights(allHighlights);
    },
    [activities, highlights, updateHighlights]
  );

  const removeHighlight = useCallback(
    (activityId) => {
      const index = highlights.findIndex((a) => a === activityId);
      if (index !== -1) {
        const highlightsCopy = [...highlights];
        highlightsCopy.splice(index, 1);
        updateHighlights(highlightsCopy);
      }
    },
    [highlights, updateHighlights]
  );

  const highlightsBody = useMemo(() => {
    if (props.aggregateCampaignActivities && highlights.length > 0) {
      const highlightsList = activities.filter((a) =>
        highlights.includes(a.id)
      );
      return (
        <>
          <div className="text-center text-muted mb-3">
            {/* @ts-expect-error */}
            {'Highlights (' + periodKeysList[0] + ')'}
          </div>
          <Card>
            <CardBody>
              <ActivityList
                isResumeView={true}
                isEditablePerfResumeMode={props.isEditablePerfResumeMode}
                isUneditablePerfResumeMode={props.isUneditablePerfResumeMode}
                activities={highlightsList}
                focalPerson={props.person}
                hideEmojis={true}
                hideImages={true}
                hideContributions={true}
                hideDescriptions={true}
                hideIcon={props.hideIcon}
                hidePeople={props.hidePeople}
                hideDates={props.isCompressedView}
                insertBeforeEachActivity={props.insertBeforeEachActivity}
                onClickAcceptCredit={props.onClickAcceptCredit}
                hideActivitiesWithoutFeedbackForFocalPerson={
                  props.hideActivitiesWithoutFeedbackForFocalPerson
                }
                showFeedback={props.showFeedback}
                onAddNewContributionToExistingActivity={
                  props.onAddNewContributionToExistingActivity
                }
                onCreateNewActivity={props.onCreateNewActivity}
                defaultDateString={Date()}
                setDefaultActivityDateString={
                  props.setDefaultActivityDateString
                }
                addOrRemoveHighlight={
                  props.aggregateCampaignActivities && removeHighlight
                }
                isHighlight={true}
                activeHighlights={props.activeHighlights}
                selectButtonText={props.selectButtonText}
                onSelect={props.onSelect}
                isDemoOrPreviewMode={isDemoOrPreviewMode}
              />
            </CardBody>
          </Card>
        </>
      );
    }
  }, [
    activities,
    highlights,
    periodKeysList,
    props.activeHighlights,
    props.aggregateCampaignActivities,
    props.hideActivitiesWithoutFeedbackForFocalPerson,
    props.hideIcon,
    props.hidePeople,
    props.insertBeforeEachActivity,
    props.isCompressedView,
    isDemoOrPreviewMode,
    props.isEditablePerfResumeMode,
    props.isUneditablePerfResumeMode,
    props.onAddNewContributionToExistingActivity,
    props.onClickAcceptCredit,
    props.onCreateNewActivity,
    props.onSelect,
    props.person,
    props.selectButtonText,
    props.setDefaultActivityDateString,
    props.showFeedback,
    removeHighlight,
  ]);

  const resumeBody = useMemo(() => {
    return (
      <>
        {props.aggregateCampaignActivities && highlightsBody}
        {periodKeysList?.map((key, index) => {
          if (
            typeof collapsePeriodsBeyondNumber !== 'undefined' &&
            index > collapsePeriodsBeyondNumber
          ) {
            return <Fragment key={index} />;
          }

          // The extra check is in case this is a "grouped" bucket (for perf)
          let activitiesForPeriod =
            activitiesByPeriod[key]?.activities || activitiesByPeriod[key];

          if (props.aggregateCampaignActivities) {
            activitiesForPeriod = activitiesForPeriod.filter(
              (a) => !highlights.includes(a.id)
            );
          }

          const periodString = getQuarter(key);
          const activityBody = (
            <>
              {!props.hideActivitiesWithoutFeedbackForFocalPerson &&
                !props.isEditablePerfResumeMode &&
                !props.isCompressedView &&
                !(activitiesForPeriod?.length > 0) && (
                  <div className="text-center">
                    <span className="text-muted">
                      <FormattedMessage
                        id="app.views.person.person_timeline_activities.no_activities"
                        defaultMessage="No activities were completed in {periodString}."
                        values={{
                          periodString: periodString,
                        }}
                      />
                      {isMe && props.openCreateActivityDialog && (
                        <>
                          {' '}
                          <span
                            className="text-primary"
                            role="button"
                            onClick={props.openCreateActivityDialog}
                          >
                            {addButtonText}
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                )}
              {(props.isEditablePerfResumeMode ||
                props.isUneditablePerfResumeMode ||
                activitiesForPeriod?.length > 0) && (
                <ActivityList
                  isResumeView={true}
                  isEditablePerfResumeMode={props.isEditablePerfResumeMode}
                  isUneditablePerfResumeMode={props.isUneditablePerfResumeMode}
                  isCompressedView={props.isCompressedView}
                  activities={activitiesForPeriod}
                  focalPerson={props.person}
                  hideEmojis={true}
                  hideImages={true}
                  hideContributions={true}
                  hideDescriptions={true}
                  hideIcon={props.hideIcon}
                  hidePeople={props.hidePeople}
                  hideDates={props.isCompressedView}
                  insertBeforeEachActivity={props.insertBeforeEachActivity}
                  onClickAcceptCredit={props.onClickAcceptCredit}
                  hideActivitiesWithoutFeedbackForFocalPerson={
                    props.hideActivitiesWithoutFeedbackForFocalPerson
                  }
                  showFeedback={props.showFeedback}
                  showNewActivityAutocomplete={
                    props.showNewActivityAutocomplete
                  }
                  onAddNewContributionToExistingActivity={
                    props.onAddNewContributionToExistingActivity
                  }
                  onCreateNewActivity={props.onCreateNewActivity}
                  autoFocus={index === 0}
                  defaultDateString={activitiesByPeriod[key]?.date || key}
                  setDefaultActivityDateString={
                    props.setDefaultActivityDateString
                  }
                  addOrRemoveHighlight={
                    props.aggregateCampaignActivities && addHighlight
                  }
                  activeHighlights={props.activeHighlights}
                  periodEndsPresentDay={!!activitiesByPeriod[key]?.activities} // For "grouped" bucket
                  selectButtonText={props.selectButtonText}
                  onSelect={props.onSelect}
                  // @ts-expect-error
                  omit={props.omit}
                  isDemoOrPreviewMode={isDemoOrPreviewMode}
                />
              )}
            </>
          );

          if (props.isCompressedView) {
            return (
              <Fragment key={index}>
                {activitiesForPeriod?.length > 0 && (
                  <div className="text-muted mb-0">{periodString}</div>
                )}
                {activityBody}
              </Fragment>
            );
          } else {
            const areNonHighlightAdditionalContributions =
              props.aggregateCampaignActivities &&
              !index &&
              highlights.length > 0;

            const nonHighlightActivitiesForPeriod = activitiesForPeriod?.filter(
              (a) => !highlights.includes(a.id)
            );

            // show nothing here if they have highlights but no non-highlight activities,
            // as they will see the highlights section right above, i.e.
            // Don't show "Additional contributions" section at all if it is empty
            if (
              areNonHighlightAdditionalContributions &&
              !nonHighlightActivitiesForPeriod?.length
            ) {
              return <Fragment key={index}></Fragment>;
            }

            return (
              <Fragment key={index}>
                <div className="text-center text-muted mb-3">
                  {areNonHighlightAdditionalContributions
                    ? formatMessage(
                        {
                          id: 'app.views.person.person_timeline_activities.additional_contributions',
                          defaultMessage: 'Additional contributions ({period})',
                        },
                        {
                          period: periodString,
                        }
                      )
                    : periodString}
                </div>
                <Card>
                  <CardBody>{activityBody}</CardBody>
                </Card>
              </Fragment>
            );
          }
        })}
        {collapseActivitiesBeforeDate &&
          collapsePeriodsBeyondNumber !== undefined &&
          // @ts-expect-error
          collapsePeriodsBeyondNumber < periodKeysList.length - 1 && (
            <div
              className="btn-link mb-4 text-center"
              role="button"
              onClick={() => setCollapseActivitiesBeforeDate(null)}
            >
              <FormattedMessage
                id="app.views.person.person_timeline_activities.view_previous_activities"
                defaultMessage="View previous activities"
              />
            </div>
          )}
      </>
    );
  }, [
    activitiesByPeriod,
    addButtonText,
    addHighlight,
    collapseActivitiesBeforeDate,
    collapsePeriodsBeyondNumber,
    formatMessage,
    highlights,
    highlightsBody,
    isMe,
    periodKeysList,
    props.activeHighlights,
    props.aggregateCampaignActivities,
    props.hideActivitiesWithoutFeedbackForFocalPerson,
    props.hideIcon,
    props.hidePeople,
    props.insertBeforeEachActivity,
    props.isCompressedView,
    isDemoOrPreviewMode,
    props.isEditablePerfResumeMode,
    props.isUneditablePerfResumeMode,
    props.omit,
    props.onAddNewContributionToExistingActivity,
    props.onClickAcceptCredit,
    props.onCreateNewActivity,
    props.onSelect,
    props.openCreateActivityDialog,
    props.person,
    props.selectButtonText,
    props.setDefaultActivityDateString,
    props.showFeedback,
    props.showNewActivityAutocomplete,
  ]);

  const resumeBodyWithTabs = useMemo(
    () =>
      props.secondaryTab ? (
        <>
          <ul className="nav nav-tabs nav-tabs-sm mb-4 mt-0">
            <li
              className="nav-item"
              role="button"
              onClick={() => setCurrentTabIndex(0)}
            >
              <span
                className={
                  'nav-link' + (currentTabIndex === 0 ? ' active' : '')
                }
              >
                <FormattedMessage
                  id="app.views.person.person_timeline_activities.resume"
                  defaultMessage="Resume"
                  description="Resume meaning a person's resume of work, not resume meaning continue"
                />
              </span>
            </li>
            <li
              className="nav-item"
              role="button"
              onClick={() => setCurrentTabIndex(1)}
            >
              <span
                className={
                  'nav-link' + (currentTabIndex === 1 ? ' active' : '')
                }
              >
                {/* @ts-expect-error */}
                {props.secondaryTab.title}
              </span>
            </li>
          </ul>
          {currentTabIndex === 0 && resumeBody}
          {/* @ts-expect-error */}
          {currentTabIndex === 1 && props.secondaryTab.content}
        </>
      ) : (
        resumeBody
      ),
    [currentTabIndex, props.secondaryTab, resumeBody]
  );

  if (errorMessage) {
    return (
      <Card>
        <CardBody>{errorMessage}</CardBody>
      </Card>
    );
  }

  if (!activities || !props.person || isLoading) {
    return <Loading />;
  }

  if (props.isEditablePerfResumeMode || props.isUneditablePerfResumeMode) {
    const titleInsert = getPersonDisplayTitleHtml(formatMessage, props.person);
    // @ts-expect-error
    const locationInsert = props.person.location
      ? formatMessage(
          {
            id: 'app.views.person.person_timeline_activities.location',
            defaultMessage:
              '{withTitle, select, true { in {location}} other { Located in {location}}}',
          },
          {
            withTitle: !!titleInsert,
            // @ts-expect-error
            location: props.person.location,
          }
        )
      : '';

    return (
      <Card>
        <CardBody className="pb-0">
          {!props.hideHeader && (
            <Row className="row mb-5">
              <Col className="col-auto">
                {/* @ts-expect-error */}
                <Avatar size="md" person={props.person} />
              </Col>
              <Col className="col ms-n3">
                {/* @ts-expect-error */}
                <h3 className="mb-0 mt-2">{props.person.full_name}</h3>
                {(titleInsert || locationInsert) && (
                  <div className="text-muted small">
                    {titleInsert}
                    {locationInsert}
                  </div>
                )}
                {!props.showAddToProfileButton && props.isCompressedView && (
                  <div className="small">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      // @ts-expect-error
                      href={props.person.url}
                    >
                      <FormattedMessage
                        id="app.views.person.person_timeline_activities.view_full_resume"
                        defaultMessage="View full resume"
                      />
                      <span className="fe fe-external-link ms-2"></span>
                    </a>
                  </div>
                )}
              </Col>
              {props.showAddToProfileButton && (
                <>
                  <Col className="col-auto text-end">
                    <Button
                      // @ts-expect-error
                      onClick={props.openCreateActivityDialog}
                      color="light"
                      className="btn mb-4"
                    >
                      {addButtonText}
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          )}
          {resumeBodyWithTabs}
        </CardBody>
      </Card>
    );
  } else {
    return resumeBody;
  }
};

const PersonTimelineActivities_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  showAddToProfileButton: PropTypes.bool,
  openCreateActivityDialog: PropTypes.func,
  // if no activities passed in, they will be fetched automatically
  activities: PropTypes.arrayOf(PropTypes.object),
  // this is for activities added in real-time that shouldn't fetch from the server
  // (they should just be added to the list in the UI if not already present)
  addedActivities: PropTypes.arrayOf(PropTypes.object),
  hideIcon: PropTypes.bool,
  hidePeople: PropTypes.bool,
  isCompressedView: PropTypes.bool,
  secondaryTab: PropTypes.object,
  insertBeforeEachActivity: PropTypes.func,
  collapseActivitiesBeforeDate: PropTypes.object,
  isEditablePerfResumeMode: PropTypes.bool,
  isUneditablePerfResumeMode: PropTypes.bool,
  onClickAcceptCredit: PropTypes.func,
  getActivites: PropTypes.func,
  getFeedback: PropTypes.func,
  hideActivitiesWithoutFeedbackForFocalPerson: PropTypes.bool,
  showFeedback: PropTypes.bool,
  showManagerOnlyPerformanceDetails: PropTypes.bool,
  feedbackList: PropTypes.arrayOf(PropTypes.object),
  hideHeader: PropTypes.bool,
  showNewActivityAutocomplete: PropTypes.bool,
  onAddNewContributionToExistingActivity: PropTypes.func,
  onCreateNewActivity: PropTypes.func,
  setDefaultActivityDateString: PropTypes.func,
  aggregateCampaignActivities: PropTypes.bool,
  activeHighlights: PropTypes.bool,
  surveyResponse: PropTypes.object,
  campaign: PropTypes.object,
  omit: PropTypes.arrayOf(PropTypes.object),
  selectButtonText: PropTypes.string,
  onSelect: PropTypes.func,
  isDemoOrPreviewMode: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof PersonTimelineActivities_propTypes> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentProxyPerson } = state;

  return {
    me,
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(PersonTimelineActivities))
);
