import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ModalEditor from './ModalEditor';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

// Notes for using this widget:
// toggle and/or isOpen can be passed in externally; if they are,
// they will be used, otherwise if omitted, this widget will handle
// its own toggling open and closed
const ModalEditorButton = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const propsSetHasUnsavedChanges = props.setHasUnsavedChanges;

  // NOTE: if you want an anchorTrigger to work properly,
  // i.e. open the modal on page load, you must pass toggle in as a prop
  const toggle = useMemo(
    () => (props.toggle ? props.toggle : () => setIsOpen(!isOpen)),
    [isOpen, props.toggle]
  );

  // if props.isOpen is updated externally, update isOpen accordingly
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const propsCallback = props.callback;
  const callback = useCallback(
    (data, error, hardErrorMessage) => {
      // close modal when there's success from the callback
      if (data) {
        setIsOpen(false);
      }

      return propsCallback(data, error, hardErrorMessage);
    },
    [propsCallback]
  );

  const propsOnClosed = props.onClosed;
  const onClosed = useCallback(() => {
    // to ensure a second open can be closed
    setIsOpen(false);

    if (propsOnClosed) {
      propsOnClosed();
    }
  }, [propsOnClosed]);

  const modalEditor = useMemo(
    () => (
      <ModalEditor
        {...props}
        submitText={props.submitText ? props.submitText : props.buttonText}
        // we pass in props.isOpen instead of the locally defined isOpen if props.toggle is
        // defined to prevent an infinite loop that causes hanging when submitting data in a dialog
        isOpen={props.toggle ? props.isOpen : isOpen}
        toggle={toggle}
        className={props.modalClassName}
        callback={callback}
        onClosed={onClosed}
        buttonClassName={props.submitButtonClassName}
        setHasUnsavedChanges={propsSetHasUnsavedChanges}
      >
        {props.children}
      </ModalEditor>
    ),
    [callback, isOpen, onClosed, props, propsSetHasUnsavedChanges, toggle]
  );

  const buttonComponentGenerator = useMemo(() => {
    if (props.inline) {
      return null;
    }

    if (props.buttonComponentGenerator) {
      return props.buttonComponentGenerator;
    }

    const generateButton = (p) => {
      const button = (
        <Button
          onClick={p.onClick}
          className={props.buttonClassName ? props.buttonClassName : ''}
          color={props.color ? props.color : 'primary'}
          role={props.role}
          style={
            props.disabled && props.disabledHoverText
              ? {
                  pointerEvents: 'none',
                  ...(props.style ? props.style : {}),
                }
              : props.style
          }
          disabled={props.disabled}
        >
          {props.buttonText ? props.buttonText : props.title}
        </Button>
      );

      // if we need to show a disabled popover, we need to manipulate
      // the dom; we avoid doing this only when we have to because
      // of potential layout issues related to the outer span
      if (props.disabled && props.disabledHoverText) {
        const uniqueLocationString =
          'btn-' + uniqueId('modal-editor-button-') + '-save-button';
        return (
          <>
            <span id={uniqueLocationString} style={{ cursor: 'not-allowed' }}>
              {button}
            </span>
            {props.disabled && props.disabledHoverText && (
              <UncontrolledPopover
                placement="bottom"
                trigger="hover"
                target={uniqueLocationString}
              >
                <PopoverBody className="text-dark">
                  {props.disabledHoverText}
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </>
        );
      } else {
        return button;
      }
    };

    return generateButton;
  }, [
    props.buttonClassName,
    props.buttonComponentGenerator,
    props.buttonText,
    props.color,
    props.disabled,
    props.disabledHoverText,
    props.inline,
    props.role,
    props.style,
    props.title,
  ]);

  // inline means don't show button, just show editor itself
  if (props.inline) {
    return modalEditor;
  }

  return (
    <>
      {buttonComponentGenerator({
        onClick: toggle,
        className: props.buttonClassName,
      })}
      {modalEditor}
    </>
  );
};

ModalEditorButton.defaultProps = {
  object: {},
  inline: false,
  isOpen: false,
};

ModalEditorButton.propTypes = {
  object: PropTypes.object,
  disabled: PropTypes.bool,
  disabledHoverText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readOnly: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  inputs: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  modalTitle: PropTypes.string,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  buttonComponentGenerator: PropTypes.func,
  buttonClassName: PropTypes.string,
  submitButtonClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  url: PropTypes.string,
  method: PropTypes.string,
  preSubmit: PropTypes.func,
  transformObjectBeforeSubmit: PropTypes.func,
  renderModalHeader: PropTypes.func,
  renderInputs: PropTypes.func,
  renderForm: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  anchorTrigger: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  inline: PropTypes.bool,
  inlineSubmitButton: PropTypes.bool,
  inForm: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func,
  noValidate: PropTypes.bool,
  block: PropTypes.bool,
  toggle: PropTypes.func,
  hideSubmitButton: PropTypes.bool,
  onInputsChange: PropTypes.func,
  jsonPath: PropTypes.string,
  translationNamespace: PropTypes.string,
  translationVisibility: PropTypes.string,
};

export default React.memo(ModalEditorButton);
