import { Card, CardBody } from 'reactstrap';
import React, { FC } from 'react';

import AvatarGroup from 'views/Widgets/People/AvatarGroup';
import BucketDescriptor from './BucketDescriptor';
import { FormattedMessage } from 'react-intl';
import { Person } from 'types';
import SafeUncontrolledPopover from 'components/SafeUncontrolledPopover';
import { v4 as uuidv4 } from 'uuid';

const Cell: FC<{
  title: string;
  rows: number;
  columns: number;
  x: number;
  y: number;
  x_axis_boundary: [string, string];
  y_axis_boundary: [string, string];
  x_metric_description: string;
  y_metric_description: string;
  y_axis_reversed: boolean;
  x_axis_reversed: boolean;
  percentage: number;
  debug?: boolean;
  people?: Person[];
}> = ({
  title,
  x,
  y,
  percentage,
  debug = false,
  people = [],
  x_axis_boundary,
  y_axis_boundary,
  x_axis_reversed,
  y_axis_reversed,
  x_metric_description,
  y_metric_description,
}) => {
  const cellId = 'talent-matrix-cell' + uuidv4();

  return (
    <>
      <SafeUncontrolledPopover target={cellId} placement="top" trigger="hover">
        <BucketDescriptor
          boundaries={x_axis_boundary}
          metric_description={x_metric_description}
          is_reversed={x_axis_reversed}
        />
        <BucketDescriptor
          boundaries={y_axis_boundary}
          metric_description={y_metric_description}
          is_reversed={y_axis_reversed}
        />
      </SafeUncontrolledPopover>
      <Card
        className="h-100 mb-0 text-center"
        style={{
          backgroundColor: `rgba(100,161,240,${percentage / 200})`,
          borderRadius: '0rem',
        }}
      >
        <CardBody id={cellId}>
          <div>
            <span className="fs-3">{title}</span>
          </div>
          {debug && <div>{`(${x}-${y})`}</div>}
          <div>
            <strong className="fs-1">{`${percentage}%`}</strong>
          </div>
          <div>
            {people.length > 0 && (
              <>
                <AvatarGroup
                  people={people}
                  maxFaces={5}
                  size="xs"
                  className="py-2 text-center"
                />
                <div>
                  <span>
                    <FormattedMessage
                      id="app.views.talent_matrix.talent_matrix_grid._people"
                      defaultMessage="{count, plural, one {# person} other {# people}}"
                      values={{ count: people.length }}
                    />
                  </span>
                </div>
              </>
            )}
            {/* Height hack to have a row of all empty cells match height of AvatarGroup + span above
                so all boxes have same vertical height but don't waste too much space */}
            {people.length === 0 && <div style={{ height: '3.7815rem' }}></div>}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Cell;
