import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  Features,
  Organization,
  OrganizationSettings,
  Person,
  ReduxState,
} from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ICONS,
  ONE_HOUR_IN_MILLISECONDS,
  PERFORMANCE,
  PROFILE_TAB_PERFORMANCE,
  PROMOTION_PACKETS,
} from '../../consts/consts';
import { Link, useHistory } from 'react-router-dom';
import {
  PACKET_STATUSES,
  PACKET_STATUS_APPROVED,
  PACKET_STATUS_DENIED,
  PACKET_STATUS_DRAFT,
  PACKET_STATUS_PENDING,
  PACKET_STATUS_WITHDRAWN,
  PROMO_PACKET_APPROVAL_SUBMIT_INPUTS,
  PROMO_PACKET_DENIAL_SUBMIT_INPUTS,
  PROMO_PACKET_DRAFT_EDIT_INPUTS,
  PROMO_PACKET_DRAFT_TO_PENDING_SUBMIT_INPUTS,
  PROMO_PACKET_NON_DRAFT_EDIT_INPUTS_IS_ADMIN,
  PROMO_PACKET_NON_DRAFT_EDIT_INPUTS_IS_NOT_ADMIN,
  PROMO_PACKET_UNDO_APPROVAL_INPUTS,
  PROMO_PACKET_UNDO_DENIAL_INPUTS,
  PROMO_PACKET_WITHDRAW_INPUTS,
  getCurrentJobFamilyLabel,
  getEnabledInputsForOrganization,
  isPromoPacketManagementRoleInferredFromOptions,
  mapPromoPacketsCustomLevelOption,
  onValidatePromotionPacket,
  preparePacketForFrontend,
  promoPacketsTransformObjectBeforeSubmit,
} from '../../utils/models/PromotionPackets';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getPrettyDate, isValidGoogleDocUrl } from '../../utils/util/util';

import CommentsLog from '../Widgets/CommentsLog';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
//import ConfirmAPI from '../../utils/api/ConfirmAPI';
import Loading from '../Widgets/Loading';
import Modal from '../../components/SafeModal';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import ModalEditorButton from '../Widgets/Modals/ModalEditorButton';
import Page from '../Layout/Pages/Page';
import PersonCard from '../Widgets/Cards/PersonCard';
import PromotionPacketFeedback from './PromotionPacketFeedback';
import RichTextEditor from '../Widgets/Inputs/RichTextEditor';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import { connect } from 'react-redux';
import { getUserLocalStorage } from '../../utils/models/User';
import { loadOrRender } from '../../utils/util/formatter';
import { parseAndFormatQuestionResponse } from '../../utils/models/Performance';
import { peopleIdsAreEqual } from '../../utils/models/Person';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { withRichTextViewer } from '../Widgets/Inputs/ValidatedInput';
import { promotionPacketFeedbackRequestsAreEnabled } from 'utils/util/features';

const GENERIC_PROMOTION_PACKET_DEFAULT_DESCRIPTION =
  '<p><strong>Contributions, metrics & impact</strong></p><p>[List contributions and examples of impact here...]</p><p></p><p><strong>Growth demonstrated</strong></p><p>[Describe how this person has grown such that the promotion is justified...]</p><p></p><p><strong>Challenges</strong></p><p>[Identify potential challenges here...]</p>';

interface Props {
  promotionPacketId: string;
  className?: string;
  inModal: boolean;
  callback?: (arg: any) => void;
  toggle?: () => void;
  onClosed?: () => void;
  meId: number;
  features: Features;
  currentOrganization: Organization;
  currentProxyPerson: Person;
  settings: OrganizationSettings;
}

const PromotionPacket: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const toggleShowHistory = useCallback(
    () => setShowHistory(!showHistory),
    [showHistory]
  );
  const [jobCatalog, setJobCatalog] = useState(undefined);
  const [showModalEditor, setShowModalEditor] = useState(false);
  const toggleShowModalEditor = useCallback(
    () => setShowModalEditor(!showModalEditor),
    [showModalEditor]
  );

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const { user } = useAuth0();

  const [promotionPacket, setPromotionPacket] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const userSub = user?.sub;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch promotion packet from backend
    if (
      userSub &&
      typeof promotionPacket === 'undefined' &&
      props.promotionPacketId
    ) {
      ConfirmAPI.getObject(
        null, // Do not cache this search
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.PACKETS,
        props.promotionPacketId,
        (data) => {
          if (isMounted) {
            // @ts-expect-error
            setPromotionPacket(preparePacketForFrontend(data));
          }
        },
        (message) => {
          setErrorMessage(message);
        },
        props.currentProxyPerson
          ? { proxy: props.currentProxyPerson.email }
          : undefined
      );
    }
  }, [
    isMounted,
    userSub,
    props.currentProxyPerson,
    props.promotionPacketId,
    promotionPacket,
    props,
  ]);

  const isAdmin = useMemo(
    // @ts-expect-error
    () => promotionPacket?.is_adminable,
    // @ts-expect-error
    [promotionPacket?.is_adminable]
  );

  // fetch org setting if there's a custom promo packet default description
  const promoPacketOrgSettings = props.settings;

  const saveDatasetLocally = useCallback((datasetHits) => {
    const jobCatalogDatasets = datasetHits.map((h) => h._source);
    if (jobCatalogDatasets?.length > 0) {
      setJobCatalog(
        jobCatalogDatasets[0].mapped_data.map((i) => ({
          // for visual display in dropdown
          object: i.id, // these are job codes (e.g. for Carta)
          // we map job codes to descriptions (e.g. for Carta)
          name: i.description ? i.description : i.title,
          // show full description detail based on aspects of this job
          description:
            i.job_family_group + ' > ' + i.job_family + ' > ' + i.level,

          // keep other items for lookups
          title: i.title,
          level: i.level,
          job_family_group: i.job_family_group,
          job_family: i.job_family,
        }))
      );
    }
  }, []);

  useEffect(() => {
    // @ts-expect-error
    if (!isMounted || !promotionPacket?.is_adminable) {
      return;
    }

    // fetch from local storage if already exists there given the size
    // TODO: expire after, say, 24 hours
    const jobCatalogDatasets = getUserLocalStorage(
      userSub,
      props.currentProxyPerson,
      props.currentOrganization?.id,
      'get-job-catalog-datasets',
      true
    );

    if (jobCatalogDatasets) {
      saveDatasetLocally(jobCatalogDatasets);
      return;
    }

    if (
      userSub &&
      // @ts-expect-error
      promotionPacket?.is_adminable &&
      typeof jobCatalog === 'undefined'
    ) {
      ElasticsearchAPI.search(
        userSub,
        props.currentProxyPerson,
        props.currentOrganization?.id,
        'get-job-catalog-datasets',
        { size: 1 },
        (hits) => {
          if (isMounted) {
            saveDatasetLocally(hits);
          }
        },
        (message) => {
          setErrorMessage(message);
        },
        // don't fetch again if this is already in local storage within TTL range
        ONE_HOUR_IN_MILLISECONDS
      );
    }
  }, [
    isMounted,
    jobCatalog,
    // @ts-expect-error
    promotionPacket?.is_adminable,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    saveDatasetLocally,
    userSub,
  ]);

  const candidate_person = useMemo(
    // @ts-expect-error
    () => promotionPacket?.candidate_person,
    // @ts-expect-error
    [promotionPacket?.candidate_person]
  );

  const author_person = useMemo(
    // @ts-expect-error
    () => promotionPacket?.author_person,
    // @ts-expect-error
    [promotionPacket?.author_person]
  );

  const next_reviewer_person = useMemo(
    // @ts-expect-error
    () => promotionPacket?.next_reviewer_person,
    // @ts-expect-error
    [promotionPacket?.next_reviewer_person]
  );

  const status = useMemo(
    () =>
      // @ts-expect-error
      PACKET_STATUSES.find((s) => s.id === promotionPacket?.status) ||
      PACKET_STATUS_DRAFT,
    // @ts-expect-error
    [promotionPacket?.status]
  );

  const highLevelDetails1 = useMemo(
    () => [
      // @ts-expect-error
      ...(promotionPacket?.configs_time_in_role_in_months
        ? [
            {
              className: 'col-6',
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.time_in_role"
                  defaultMessage="Time in current role"
                />
              ),
              description:
                // @ts-expect-error
                promotionPacket?.configs_time_in_role_in_months + ' months',
            },
          ]
        : []),
      // @ts-expect-error
      ...(promotionPacket?.configs_tenure_in_months
        ? [
            {
              className: 'col-6',
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.total_tenure"
                  defaultMessage="Total tenure"
                />
              ),
              description: formatMessage(
                {
                  id: 'app.views.promotion_packets.promotion_packet.name.months',
                  defaultMessage:
                    '{months, plural, one {1 month} other {{months} months}}',
                },
                {
                  // @ts-expect-error
                  months: promotionPacket?.configs_tenure_in_months ?? 0,
                }
              ),
            },
          ]
        : []),
      // @ts-expect-error
      ...(promotionPacket?.configs_title
        ? [
            {
              className: 'col-12',
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.current_title"
                  defaultMessage="Current title"
                />
              ),
              // @ts-expect-error
              description: promotionPacket?.configs_title,
            },
          ]
        : []),
      // @ts-expect-error
      ...(promotionPacket?.configs_level_id &&
      // @ts-expect-error
      promotionPacket?.configs_job_profile
        ? [
            {
              className: 'col-12',
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.current_job_profile_level"
                  defaultMessage="Current job profile / level"
                />
              ),
              description:
                // @ts-expect-error
                promotionPacket?.configs_job_profile +
                ' / ' +
                // @ts-expect-error
                promotionPacket?.configs_level_id,
            },
          ]
        : []),
      {
        className: 'col-12',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packet.name.last_perf_cycle"
            defaultMessage="Last perf cycle"
          />
        ),
        description: (
          <span>
            <Link
              to={candidate_person?.url + PERFORMANCE().path}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="app.views.promotion_packets.promotion_packet.view_perf_results"
                defaultMessage="View perf results"
              />
            </Link>
          </span>
        ),
      },
    ],
    [
      // @ts-expect-error
      promotionPacket?.configs_time_in_role_in_months,
      // @ts-expect-error
      promotionPacket?.configs_tenure_in_months,
      // @ts-expect-error
      promotionPacket?.configs_title,
      // @ts-expect-error
      promotionPacket?.configs_level_id,
      // @ts-expect-error
      promotionPacket?.configs_job_profile,
      candidate_person?.url,
      formatMessage,
    ]
  );

  const getUserFacingValueOfPacketField = (promotionPacket, field) => {
    if (field === 'configs_is_management_role') {
      return typeof promotionPacket?.configs_is_management_role === 'undefined'
        ? '-'
        : promotionPacket?.configs_is_management_role === 'Y'
        ? 'Yes'
        : 'No';
    }

    return promotionPacket?.[field];
  };

  const customQuestionResponses = useMemo(() => {
    // if the org defined any custom questions, include their output here
    const customQuestions =
      promoPacketOrgSettings?.default_promotion_packet_questions;

    // @ts-expect-error
    if (!customQuestions?.length > 0 || !promotionPacket) {
      // no custom questions defined
      return null;
    }

    // @ts-expect-error
    return customQuestions.map((q) => {
      // display "-" for configs_packet_url if we are not using a URL
      let displayValue = parseAndFormatQuestionResponse({
        q: {
          question: q,
          response: getUserFacingValueOfPacketField(promotionPacket, q.name),
        },
        // @ts-expect-error
        noneElement: '-',
        locale,
        formatMessage,
      });
      if (q.name === 'configs_packet_url' && displayValue === 'N') {
        displayValue = '-';
      }
      return {
        name: q.summaryLabel ? (
          q.summaryLabel
        ) : (
          <RichTextViewer model={q.label} expanded={true} />
        ),
        description: displayValue,
      };
    });
  }, [promoPacketOrgSettings, promotionPacket, locale, formatMessage]);

  const allowsField = useCallback(
    (field) => {
      // if the org defined any custom questions, include their output here
      const customQuestions =
        promoPacketOrgSettings?.default_promotion_packet_questions;
      // @ts-expect-error
      if (!customQuestions?.length > 0) {
        // if packet isn't set in UI yet, return true so we initially display
        // these fields
        if (!promotionPacket) {
          return true;
        }

        return typeof promotionPacket?.[field] !== 'undefined';
      }

      // @ts-expect-error
      return customQuestions.find((q) => q.name === field);
    },
    [promoPacketOrgSettings, promotionPacket]
  );

  const highLevelDetails2 = useMemo(
    () => [
      ...(!customQuestionResponses && allowsField('configs_new_level_id')
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.proposed_level"
                  defaultMessage="Proposed level"
                />
              ),
              // @ts-expect-error
              previousDescription: promotionPacket?.configs_level_id,
              // @ts-expect-error
              description: promotionPacket?.configs_new_level_id || '-',
            },
          ]
        : []),
      ...(!customQuestionResponses && allowsField('configs_is_management_role')
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.people_management_role"
                  defaultMessage="People management role"
                />
              ),
              description: getUserFacingValueOfPacketField(
                promotionPacket,
                'configs_is_management_role'
              ),
            },
          ]
        : []),
      ...(!customQuestionResponses && allowsField('configs_new_title')
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.proposed_business_title"
                  defaultMessage="Proposed business title"
                />
              ),
              // @ts-expect-error
              description: promotionPacket?.configs_new_title || '-',
            },
          ]
        : []),
      ...(!customQuestionResponses &&
      allowsField('configs_new_position_effective_date')
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.proposed_effective_date"
                  defaultMessage="Proposed effective date"
                />
              ),
              // @ts-expect-error
              description: promotionPacket?.configs_new_position_effective_date
                ? getPrettyDate({
                    dateString:
                      // @ts-expect-error
                      promotionPacket?.configs_new_position_effective_date,
                    locale,
                  })
                : '-',
            },
          ]
        : []),
      // @ts-expect-error
      ...(customQuestionResponses?.length > 0 ? customQuestionResponses : []),
    ],
    [customQuestionResponses, allowsField, promotionPacket, locale]
  );

  const highLevelDetails3 = useMemo(
    () => [
      {
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packet.name.status"
            defaultMessage="Status"
          />
        ),
        description: (
          <>
            <i
              className={status.icon}
              style={{ position: 'relative', top: '2px' }}
            />{' '}
            <span>{status.heading}</span>
          </>
        ),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packet.name.most_recently_reviewed_by"
            defaultMessage="Most recently reviewed by"
          />
        ),
        // @ts-expect-error
        description: promotionPacket?.previous_reviewer_person ? (
          <PersonCard
            size="xxs"
            bodyOnly
            // @ts-expect-error
            person={promotionPacket?.previous_reviewer_person}
            linked
            isExternalUrl={true}
            showDescription={false}
          />
        ) : (
          '-'
        ),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packet.name.next_approval_needed"
            defaultMessage="Next approval needed"
          />
        ),
        // @ts-expect-error
        description: promotionPacket?.next_reviewer_person ? (
          <PersonCard
            size="xxs"
            bodyOnly
            // @ts-expect-error
            person={promotionPacket?.next_reviewer_person}
            linked
            isExternalUrl={true}
            showDescription={false}
          />
        ) : (
          '-'
        ),
      },
      ...(allowsField('configs_new_job_code')
        ? [
            {
              name: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet.name.new_job_code"
                  defaultMessage="New job code"
                />
              ),
              // @ts-expect-error
              description: promotionPacket?.configs_new_job_code || '-',
            },
          ]
        : []),
    ],
    [
      status.icon,
      status.heading,
      // @ts-expect-error
      promotionPacket?.previous_reviewer_person,
      // @ts-expect-error
      promotionPacket?.next_reviewer_person,
      // @ts-expect-error
      promotionPacket?.configs_new_job_code,
      allowsField,
    ]
  );

  const isDraft = useMemo(
    () => status === PACKET_STATUS_DRAFT || status === PACKET_STATUS_WITHDRAWN,
    [status]
  );

  const isApprovedOrDenied = useMemo(
    () => status === PACKET_STATUS_APPROVED || status === PACKET_STATUS_DENIED,
    [status]
  );

  // only allow editing if admin or if this hasn't yet been approved or denied
  const isEditable = useMemo(
    () => isAdmin || !isApprovedOrDenied,
    [isAdmin, isApprovedOrDenied]
  );

  const onSubmitPromotionPacketDescription = useCallback(
    (data) => {
      if (data) {
        let m = data.description.match(
          /<p>Date of Last Open Role.*?:\s*(?:\[MM\/YY\])?\s*(.*?)<\/p>/
        );
        if (m) {
          data.configs_last_orm_transfer = m[1];
        }

        m = data.description.match(
          /<p>Does this promotion yield any org changes\?\s*(?:\[Y\/N\])?\s*(.*?)<\/p>/
        );
        if (m) {
          data.configs_has_org_changes = m[1];
        }

        m = data.description.match(
          /<p>If so, please describe them:\s*(?:\[Describe org changes\.\.\.\])?\s*(.*?)<\/p>/
        );
        if (m) {
          data.configs_org_changes = m[1];
        }
      }
      // cache updated packet so reloading doesn't flash in old packet data
      ConfirmAPI.setObjectInCache(
        userSub,
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.PACKETS,
        data?.id,
        data
      );
    },
    [props.currentProxyPerson, userSub]
  );

  const callback = useCallback(
    (p, toastSuccessMessage) => {
      if (p) {
        // @ts-expect-error
        setPromotionPacket(preparePacketForFrontend(p));
        if (props.callback) {
          props.callback(p);
        }

        // submit appropriate toast
        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
        }

        // if this is a modal, close the modal
        if (props.inModal) {
          // @ts-expect-error
          props.toggle();
        }
      }
    },
    [props]
  );

  const authorIsMe = useMemo(
    () =>
      author_person &&
      props.meId &&
      peopleIdsAreEqual(props.meId, author_person?.id),
    [author_person, props.meId]
  );

  const nextReviewerIsMe = useMemo(
    () =>
      next_reviewer_person &&
      props.meId &&
      peopleIdsAreEqual(props.meId, next_reviewer_person.id),
    [next_reviewer_person, props.meId]
  );

  const defaultPromoPacketNewPositonEffectiveDate = useMemo(
    () =>
      promoPacketOrgSettings &&
      promoPacketOrgSettings.default_promotion_effective_date
        ? promoPacketOrgSettings.default_promotion_effective_date
        : undefined,
    [promoPacketOrgSettings]
  );

  const levelIdInputOverrides = useMemo(
    () =>
      promoPacketOrgSettings &&
      promoPacketOrgSettings.default_promotion_level_options
        ? {
            type: INPUT_TYPES.DROPDOWN,
            objects: promoPacketOrgSettings.default_promotion_level_options.map(
              mapPromoPacketsCustomLevelOption
            ),
          }
        : {},
    [promoPacketOrgSettings]
  );

  const templateOverride = useMemo(
    () =>
      (promoPacketOrgSettings &&
        promoPacketOrgSettings.default_promotion_url_input_attributes) ||
      {},
    [promoPacketOrgSettings]
  );

  const jobCatalogDefaultOptions = useMemo(() => {
    // @ts-expect-error
    if (!(jobCatalog?.length > 0)) {
      return [];
    }

    const options = [];
    let matchingEntry = null;

    // first option is current option if set
    // @ts-expect-error
    if (promotionPacket?.configs_new_job_code) {
      // @ts-expect-error
      matchingEntry = jobCatalog.find(
        // @ts-expect-error
        (c) => c.object === promotionPacket?.configs_new_job_code
      );

      if (matchingEntry) {
        options.push(matchingEntry);
      }
    }

    // default to any that match current level
    // @ts-expect-error
    if (promotionPacket?.configs_new_level_id) {
      // @ts-expect-error
      const allMatchLevelOptions = jobCatalog.filter(
        (o) =>
          o !== matchingEntry &&
          // @ts-expect-error
          o.level === promotionPacket?.configs_new_level_id
      );

      // if there's an exact match based on person's current
      // job family, use that
      // @ts-expect-error
      if (promotionPacket?.configs_job_family) {
        const exactMatches = allMatchLevelOptions.filter(
          (o) =>
            o !== matchingEntry &&
            // @ts-expect-error
            promotionPacket?.configs_job_family === o.job_family
        );

        if (exactMatches?.length > 0) {
          return options.concat(exactMatches);
        }
      }

      // default to all for given level
      return options.concat(allMatchLevelOptions);
    }

    return options;
  }, [
    jobCatalog,
    // @ts-expect-error
    promotionPacket?.configs_job_family,
    // @ts-expect-error
    promotionPacket?.configs_new_job_code,
    // @ts-expect-error
    promotionPacket?.configs_new_level_id,
  ]);

  // necessary to prevent all options from showing
  // as selected (unusual selectInput bug)
  const jobCatalogIsOptionSelected = useCallback(() => {
    return false;
  }, []);

  const loadJobCatalogOptions = useCallback(
    (inputValue, callback) => {
      // search only after 2 characters and there is a catalog
      // @ts-expect-error
      if (!(inputValue?.length > 1) || !(jobCatalog?.length > 0)) {
        return callback([]);
      }

      const lowercaseInput = inputValue.toLowerCase();

      return callback(
        // @ts-expect-error
        jobCatalog.filter(
          (j) =>
            j.name.toLowerCase().includes(lowercaseInput) ||
            j.description.toLowerCase().includes(lowercaseInput)
        )
      );
    },
    [jobCatalog]
  );

  const isManagementRoleInferred = useMemo(() => {
    return (
      promoPacketOrgSettings &&
      isPromoPacketManagementRoleInferredFromOptions(
        promoPacketOrgSettings?.default_promotion_level_options
      )
    );
  }, [promoPacketOrgSettings]);

  const formatEditPacketInputs = useCallback(
    (inputs, isDraft, isAdmin) => {
      const filteredInputs = getEnabledInputsForOrganization(
        inputs,
        isDraft,
        isAdmin,
        promoPacketOrgSettings
      );

      return (
        isManagementRoleInferred
          ? filteredInputs.filter(
              (i) => i.name !== 'configs_is_management_role'
            )
          : filteredInputs
      ).map((i) =>
        i.name === 'configs_new_job_code'
          ? {
              ...i,
              loadOptions: loadJobCatalogOptions,
              defaultOptions: jobCatalogDefaultOptions,
              isOptionSelected: jobCatalogIsOptionSelected,
            }
          : i.name === 'configs_new_position_effective_date'
          ? {
              ...i,
              defaultValue: defaultPromoPacketNewPositonEffectiveDate,
            }
          : i.name === 'configs_new_level_id'
          ? {
              ...i,
              ...levelIdInputOverrides,
            }
          : i.name === 'configs_packet_url'
          ? withRichTextViewer({
              ...i,
              ...templateOverride,
            })
          : i.name === 'configs_is_job_family_change' &&
            // @ts-expect-error
            promotionPacket?.configs_job_family
          ? {
              ...i,
              label: getCurrentJobFamilyLabel(
                // @ts-expect-error
                promotionPacket?.configs_job_family
              ),
            }
          : i
      );
    },
    [
      defaultPromoPacketNewPositonEffectiveDate,
      isManagementRoleInferred,
      jobCatalogDefaultOptions,
      jobCatalogIsOptionSelected,
      levelIdInputOverrides,
      loadJobCatalogOptions,
      // @ts-expect-error
      promotionPacket?.configs_job_family,
      promoPacketOrgSettings,
      templateOverride,
    ]
  );

  const formatPacketForFormSubmit = useCallback(
    (p) => {
      // @ts-expect-error
      if (!(jobCatalog?.length > 0)) {
        return p;
      }

      // convert job catalog from string to object in selectInput so it is
      // selected already on load
      return {
        ...p,
        configs_new_job_code:
          // @ts-expect-error
          jobCatalog.find((c) => c.object === p?.configs_new_job_code) ||
          // if this is an approval and exact match found and nothing set yet, and job family is
          // not changing, default dropdown to make the HRBP's life easier
          (p.status === PACKET_STATUS_APPROVED.id &&
          p?.configs_is_job_family_change === 'N' &&
          jobCatalogDefaultOptions?.length === 1
            ? jobCatalogDefaultOptions[0]
            : undefined),
      };
    },
    [jobCatalog, jobCatalogDefaultOptions]
  );

  const prettyEffectiveDate = useMemo(
    () =>
      defaultPromoPacketNewPositonEffectiveDate
        ? getPrettyDate({
            dateString: defaultPromoPacketNewPositonEffectiveDate,
            locale,
          })
        : null,
    [defaultPromoPacketNewPositonEffectiveDate, locale]
  );

  const defaultPromotionApprovers = useMemo(
    () =>
      (promoPacketOrgSettings &&
        promoPacketOrgSettings.default_promotion_approvers) ||
      {},
    [promoPacketOrgSettings]
  );

  const nextReviewerFromOrgSettings = useMemo(() => {
    // @ts-expect-error
    const approversDict = defaultPromotionApprovers?.level_id;
    const approverForAll = approversDict?.['all'];

    if (
      !approversDict ||
      // @ts-expect-error
      (!approverForAll && !promotionPacket?.configs_new_level_id)
    ) {
      return null;
    }

    // example for ORG_SETTING_DEFAULT_PROMOTION_APPROVERS, e.g. for Carta
    // defining that Henry Ward should approve all packets for Director level and above,
    // i.e. levels M7, M8, M9, M10, and M11 (note: 'all' can be provided which means
    // this person should approve all promo packets regardless of level; level isn't even
    // a needed field, next approver will always be this person)
    /*
    "default_promotion_approvers": {
      "level_id": {
        "M7": {
          "id": "1361",
          "avatar":
            "https://mcusercontent.com/ed13eb4f28f620394aa4ef136/images/1a9cc01c-a64c-4ba3-aee6-478a5a200b71.jpg",
          "full_name": "Henry Ward",
          "title": "CEO"
        },
        "M8": {
          "id": "1361",
          "avatar":
            "https://mcusercontent.com/ed13eb4f28f620394aa4ef136/images/1a9cc01c-a64c-4ba3-aee6-478a5a200b71.jpg",
          "full_name": "Henry Ward",
          "title": "CEO"
        },
        "M9": {
          "id": "1361",
          "avatar":
            "https://mcusercontent.com/ed13eb4f28f620394aa4ef136/images/1a9cc01c-a64c-4ba3-aee6-478a5a200b71.jpg",
          "full_name": "Henry Ward",
          "title": "CEO"
        },
        "M10": {
          "id": "1361",
          "avatar":
            "https://mcusercontent.com/ed13eb4f28f620394aa4ef136/images/1a9cc01c-a64c-4ba3-aee6-478a5a200b71.jpg",
          "full_name": "Henry Ward",
          "title": "CEO"
        },
        "M11": {
          "id": "1361",
          "avatar":
            "https://mcusercontent.com/ed13eb4f28f620394aa4ef136/images/1a9cc01c-a64c-4ba3-aee6-478a5a200b71.jpg",
          "full_name": "Henry Ward",
          "title": "CEO"
        }
      }
    }
    */

    if (
      approverForAll ||
      // @ts-expect-error
      promotionPacket?.configs_new_level_id in
        // @ts-expect-error
        (defaultPromotionApprovers?.level_id ?? {})
    ) {
      const nextReviewer =
        approverForAll ||
        // @ts-expect-error
        defaultPromotionApprovers.level_id[
          // @ts-expect-error
          promotionPacket?.configs_new_level_id
        ];

      // only make this the next review if this person is NOT already the next reviewer
      // (as in this case, this means )
      if (
        nextReviewer &&
        nextReviewer?.id?.toString() !==
          // @ts-expect-error
          promotionPacket?.next_reviewer_person?.id?.toString()
      ) {
        return nextReviewer;
      }
    }

    // default approver not found in org settings
    return null;
  }, [
    // @ts-expect-error
    defaultPromotionApprovers?.level_id,
    // @ts-expect-error
    promotionPacket?.configs_new_level_id,
    // @ts-expect-error
    promotionPacket?.next_reviewer_person?.id,
  ]);

  const getPacketSubmitButton = useCallback(
    (
      status,
      submitPacketInputs,
      title,
      successMessage,
      color = 'primary',
      convertForApprovalOrDenial = false
    ) => {
      return (
        <ModalEditorButton
          // @ts-expect-error
          method={promotionPacket?.id ? 'PATCH' : 'POST'}
          url="/packets"
          buttonClassName="btn-block"
          color={color}
          title={title}
          submitText={title}
          object={formatPacketForFormSubmit({
            // @ts-expect-error
            ...promotionPacket,
            // if defaultPromoPacketNewPositonEffectiveDate is set and
            // configs_new_position_effective_date is not, prepopulate it
            // so that a save will show that date
            configs_new_position_effective_date:
              // @ts-expect-error
              promotionPacket?.configs_new_position_effective_date ||
              defaultPromoPacketNewPositonEffectiveDate,

            // change status to pending on submission
            // and pass in the person approving as the previous approver,
            // and default the next approver to null
            status: status.id,
            ...(convertForApprovalOrDenial
              ? {
                  previous_reviewer_person:
                    // @ts-expect-error
                    promotionPacket.next_reviewer_person,
                  // prepopulate next reviewer if warranted by org settings
                  next_reviewer_person:
                    status === PACKET_STATUS_APPROVED
                      ? nextReviewerFromOrgSettings
                      : null,
                }
              : {}),
          })}
          renderInputs={
            isDraft
              ? (inputs) => {
                  return (
                    <>
                      {inputs}
                      {prettyEffectiveDate && (
                        <div className="text-center text-muted">
                          <FormattedMessage
                            id="app.views.promotion_packets.promotion_packet.if_approved_effective"
                            defaultMessage="If approved, this promotion will be effective {prettyEffectiveDate}."
                            values={{
                              prettyEffectiveDate: prettyEffectiveDate,
                            }}
                          />
                        </div>
                      )}
                    </>
                  );
                }
              : undefined
          }
          inputs={formatEditPacketInputs(submitPacketInputs, isDraft, isAdmin)}
          onValidate={(o) =>
            onValidatePromotionPacket(
              formatMessage,
              o,
              isManagementRoleInferred,
              promoPacketOrgSettings
            )
          }
          transformObjectBeforeSubmit={(p) =>
            promoPacketsTransformObjectBeforeSubmit(
              p,
              promoPacketOrgSettings &&
                promoPacketOrgSettings.default_promotion_level_options
            )
          }
          callback={(p) => callback(p, successMessage)}
        ></ModalEditorButton>
      );
    },
    [
      promotionPacket,
      formatPacketForFormSubmit,
      defaultPromoPacketNewPositonEffectiveDate,
      nextReviewerFromOrgSettings,
      isDraft,
      formatEditPacketInputs,
      isAdmin,
      prettyEffectiveDate,
      isManagementRoleInferred,
      promoPacketOrgSettings,
      callback,
      formatMessage,
    ]
  );

  const toggleConfirmDeleteModal = useCallback(
    () => setConfirmDeleteModal(!confirmDeleteModal),
    [confirmDeleteModal]
  );

  const deletePacket = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      // @ts-expect-error
      '/packets/' + promotionPacket.id,
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            toast.error(hardErrorMessage);
          } else {
            toast.error(error);
          }
        } else {
          // go to packet dashboard and show toast
          toast.success(
            formatMessage({
              id: 'app.views.promotion_packets.promotion_packet.packet_deleted',
              defaultMessage: 'Packet deleted!',
            })
          );
          history.replace('/packets');
        }
      },
      null
    );
  }, [promotionPacket, history, formatMessage]);

  const actions = useMemo(
    () => (
      <Row className="align-items-center">
        {(status === PACKET_STATUS_DRAFT ||
          status === PACKET_STATUS_WITHDRAWN) && (
          <Col className="col-auto">
            {getPacketSubmitButton(
              PACKET_STATUS_PENDING,
              PROMO_PACKET_DRAFT_TO_PENDING_SUBMIT_INPUTS,
              formatMessage(
                {
                  id: 'app.views.promotion_packets.promotion_packet.buttons.submmit.title',
                  defaultMessage:
                    ' {type, select, resubmit {Resubmit} other {Submit}} for approval',
                },
                {
                  type:
                    status === PACKET_STATUS_WITHDRAWN ? 'resubmit' : 'other',
                }
              ),
              formatMessage({
                id: 'app.views.promotion_packets.promotion_packet.buttons.submit.success_message',
                defaultMessage: 'Packet submitted successfully.',
              })
            )}
          </Col>
        )}
        {status === PACKET_STATUS_PENDING && (
          <>
            {(isAdmin || nextReviewerIsMe) && (
              <>
                <Col className="col-auto">
                  {getPacketSubmitButton(
                    PACKET_STATUS_APPROVED,
                    PROMO_PACKET_APPROVAL_SUBMIT_INPUTS,
                    formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.buttons.approve.title',
                      defaultMessage: 'Approve',
                    }),
                    formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.buttons.approve.success_message',
                      defaultMessage: 'Promotion approved successfully.',
                    }),
                    'primary',
                    true
                  )}
                </Col>
                <Col className="col-auto">
                  {getPacketSubmitButton(
                    PACKET_STATUS_DENIED,
                    PROMO_PACKET_DENIAL_SUBMIT_INPUTS,
                    formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.buttons.deny.title',
                      defaultMessage: 'Deny',
                    }),
                    formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.buttons.deny.success_message',
                      defaultMessage: 'Promotion denied successfully.',
                    }),
                    'primary',
                    true
                  )}
                </Col>
              </>
            )}
            {(isAdmin || authorIsMe) && (
              <Col className="col-auto">
                {getPacketSubmitButton(
                  PACKET_STATUS_WITHDRAWN,
                  PROMO_PACKET_WITHDRAW_INPUTS,
                  formatMessage({
                    id: 'app.views.promotion_packets.promotion_packet.buttons.withdraw.title',
                    defaultMessage: 'Withdraw',
                  }),
                  formatMessage({
                    id: 'app.views.promotion_packets.promotion_packet.buttons.withdraw.success_message',
                    defaultMessage: 'Packet withdrawn successfully.',
                  }),
                  'light'
                )}
              </Col>
            )}
          </>
        )}
        {status === PACKET_STATUS_APPROVED && isAdmin && (
          <Col className="col-auto">
            {getPacketSubmitButton(
              PACKET_STATUS_PENDING,
              PROMO_PACKET_UNDO_APPROVAL_INPUTS,
              formatMessage({
                id: 'app.views.promotion_packets.promotion_packet.buttons.undo_approval.title',
                defaultMessage: 'Undo approval',
              }),
              formatMessage({
                id: 'app.views.promotion_packets.promotion_packet.buttons.undo_approval.success_message',
                defaultMessage: 'Approval undone successfully.',
              })
            )}
          </Col>
        )}
        {status === PACKET_STATUS_DENIED && isAdmin && (
          <Col className="col-auto">
            {getPacketSubmitButton(
              PACKET_STATUS_PENDING,
              PROMO_PACKET_UNDO_DENIAL_INPUTS,
              formatMessage({
                id: 'app.views.promotion_packets.promotion_packet.buttons.undo_denial.title',
                defaultMessage: 'Undo denial',
              }),
              formatMessage({
                id: 'app.views.promotion_packets.promotion_packet.buttons.undo_denial.success_message',
                defaultMessage: 'Denial undone successfully.',
              })
            )}
          </Col>
        )}
        {(isAdmin || authorIsMe) && isDraft && (
          <Col className="col-auto">
            <Button color="light" onClick={toggleConfirmDeleteModal}>
              <i className="me-2 fe fe-trash-2" />
              <FormattedMessage
                id="app.views.promotion_packets.promotion_packet.delete_action"
                defaultMessage="Delete"
              />
            </Button>
          </Col>
        )}
        <Col className="col-auto">
          <Button color="light" onClick={toggleShowHistory}>
            <i className={'me-2 ' + ICONS.HISTORY} />
            {showHistory
              ? formatMessage({
                  id: 'app.views.promotion_packets.promotion_packet.hide_history_action',
                  defaultMessage: 'Hide history',
                })
              : formatMessage({
                  id: 'app.views.promotion_packets.promotion_packet.view_history_action',
                  defaultMessage: 'View history',
                })}
          </Button>
        </Col>
        {props.inModal && (
          <Col className="col-auto">
            <Link
              // @ts-expect-error
              tag="Button"
              className="btn btn-light"
              to={
                PROMOTION_PACKETS(formatMessage).path +
                '/' +
                // @ts-expect-error
                promotionPacket?.id
              }
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <i className="me-2 fe fe-external-link" />{' '}
              <FormattedMessage
                id="app.views.promotion_packets.promotion_packet.open_in_new_window_action"
                defaultMessage="Open in new window"
              />
            </Link>
          </Col>
        )}
        {!props.inModal && (
          <Col className="col-auto">
            <Button color="light" onClick={() => window.print()}>
              <i className="me-2 fe fe-upload" />
              <FormattedMessage
                id="app.views.promotion_packets.promotion_packet.export_action"
                defaultMessage="Export"
              />
            </Button>
          </Col>
        )}
        {confirmDeleteModal && (
          <ConfirmationDialogModal
            isOpen={confirmDeleteModal}
            toggle={toggleConfirmDeleteModal}
            confirmCallback={deletePacket}
            title={formatMessage({
              id: 'app.views.promotion_packets.promotion_packet.title.delete_promotion_packet',
              defaultMessage: 'Delete promotion packet?',
            })}
            description={formatMessage({
              id: 'app.views.promotion_packets.promotion_packet.description.are_you_sure_that_you_want_to_delete',
              defaultMessage:
                'Are you sure that you want to delete this promotion packet?',
            })}
            confirmText={formatMessage({
              id: 'app.views.promotion_packets.promotion_packet.confirm_text.delete_promotion_packet',
              defaultMessage: 'Delete Promotion Packet',
            })}
          />
        )}
      </Row>
    ),
    [
      authorIsMe,
      confirmDeleteModal,
      deletePacket,
      getPacketSubmitButton,
      isAdmin,
      isDraft,
      nextReviewerIsMe,
      // @ts-expect-error
      promotionPacket?.id,
      props.inModal,
      showHistory,
      status,
      toggleConfirmDeleteModal,
      toggleShowHistory,
      formatMessage,
    ]
  );

  const defaultPromoPacketDescription = useMemo(
    () =>
      promoPacketOrgSettings &&
      promoPacketOrgSettings.default_promotion_packet_description
        ? promoPacketOrgSettings.default_promotion_packet_description
        : GENERIC_PROMOTION_PACKET_DEFAULT_DESCRIPTION,
    [promoPacketOrgSettings]
  );

  const isExternalGoogleDoc = useMemo(
    // @ts-expect-error
    () => isValidGoogleDocUrl(promotionPacket?.configs_packet_url),
    // @ts-expect-error
    [promotionPacket?.configs_packet_url]
  );

  const promotionPacketBody = useMemo(
    () =>
      promotionPacket ? (
        <>
          <ModalEditor
            isOpen={showModalEditor}
            toggle={toggleShowModalEditor}
            // @ts-expect-error
            method={promotionPacket?.id ? 'PATCH' : 'POST'}
            url="/packets"
            title={formatMessage({
              id: 'app.views.promotion_packets.promotion_packet.title.edit_promotion_packet_details',
              defaultMessage: 'Edit promotion packet details',
            })}
            submitText={formatMessage({
              id: 'app.views.promotion_packets.promotion_packet.submit_text.save',
              defaultMessage: 'Save',
            })}
            // @ts-expect-error
            color="primary"
            object={formatPacketForFormSubmit(promotionPacket)}
            inputs={formatEditPacketInputs(
              isDraft
                ? PROMO_PACKET_DRAFT_EDIT_INPUTS(formatMessage)
                : isAdmin
                ? PROMO_PACKET_NON_DRAFT_EDIT_INPUTS_IS_ADMIN
                : PROMO_PACKET_NON_DRAFT_EDIT_INPUTS_IS_NOT_ADMIN,
              isDraft,
              isAdmin
            )}
            onValidate={(o) =>
              onValidatePromotionPacket(
                formatMessage,
                o,
                isManagementRoleInferred,
                promoPacketOrgSettings
              )
            }
            transformObjectBeforeSubmit={(p) =>
              promoPacketsTransformObjectBeforeSubmit(
                p,
                promoPacketOrgSettings &&
                  promoPacketOrgSettings.default_promotion_level_options
              )
            }
            callback={callback}
          ></ModalEditor>
          <Row>
            <Col className="col-12 col-md-6">
              <Card>
                <CardBody>
                  <Row>
                    {highLevelDetails1.map((item, index) => (
                      <Col
                        key={index}
                        className={
                          item.className +
                          ' ' +
                          (index === highLevelDetails1?.length - 1
                            ? ''
                            : 'mb-4')
                        }
                      >
                        <div>
                          <h6 className="text-uppercase text-muted mb-2">
                            {item.name}
                          </h6>
                          <span className="h2 mb-0">
                            {
                              // @ts-expect-error
                              item.previousDescription && (
                                <>
                                  {
                                    // @ts-expect-error
                                    item.previousDescription
                                  }{' '}
                                  <span className="fe fe-arrow-right" />{' '}
                                </>
                              )
                            }
                            {item.description}
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col
              className="col-12 col-md-6"
              role={isEditable ? 'button' : undefined}
              onClick={isEditable ? toggleShowModalEditor : undefined}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {highLevelDetails2.map((item, index) => (
                        <div className={index === 0 ? '' : 'mt-4'} key={index}>
                          <h6 className="text-uppercase text-muted mb-2">
                            {item.name}
                          </h6>
                          <span className="h2 mb-0">
                            {item.previousDescription && (
                              <>
                                {item.previousDescription}{' '}
                                <span className="fe fe-arrow-right" />{' '}
                              </>
                            )}
                            {item.description}
                          </span>
                        </div>
                      ))}
                    </Col>
                    <Col>
                      {highLevelDetails3.map((item, index) => (
                        <div className={index === 0 ? '' : 'mt-4'} key={index}>
                          <h6 className="text-uppercase text-muted mb-2">
                            {item.name}
                          </h6>
                          <span className="h2 mb-0">
                            {
                              // @ts-expect-error
                              item.previousDescription && (
                                <>
                                  {
                                    // @ts-expect-error
                                    item.previousDescription
                                  }{' '}
                                  <span className="fe fe-arrow-right" />{' '}
                                </>
                              )
                            }
                            {item.description}
                          </span>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className={showHistory ? 'col-12 col-md-8' : 'col-12'}>
              {isExternalGoogleDoc && (
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          // @ts-expect-error
                          href={promotionPacket.configs_packet_url}
                        >
                          {
                            // @ts-expect-error
                            promotionPacket.configs_packet_url
                          }
                        </a>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="light"
                          className="btn-sm"
                          onClick={toggleShowModalEditor}
                        >
                          <FormattedMessage
                            id="app.views.promotion_packets.promotion_packet.edit_url_action"
                            defaultMessage="Edit URL"
                          />
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-0">
                    <iframe
                      frameBorder="0"
                      // @ts-expect-error
                      src={promotionPacket.configs_packet_url}
                      title={formatMessage({
                        id: 'app.views.promotion_packets.promotion_packet.title.file',
                        defaultMessage: 'file',
                      })}
                      width="100%"
                      height="1000"
                    ></iframe>
                  </CardBody>
                </Card>
              )}
              {!isExternalGoogleDoc && (
                <Card className="activity-card">
                  <RichTextEditor
                    autoSave={true}
                    autoFocus={false}
                    showResetToDefaultButton={true}
                    method="PATCH"
                    url="packets"
                    title={formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.title.promotion_packet_content',
                      defaultMessage: 'Promotion packet content',
                    })}
                    name="description"
                    defaultValue={defaultPromoPacketDescription}
                    placeholder={formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.placeholder.enter_content',
                      defaultMessage: 'Enter content',
                    })}
                    saveId={props.promotionPacketId}
                    // @ts-expect-error
                    value={promotionPacket?.description}
                    config={{
                      heightMin: 300,
                    }}
                    callback={onSubmitPromotionPacketDescription}
                    emptyStateTitle={formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.emptyStateTitle.no_content',
                      defaultMessage: 'No content',
                    })}
                    emptyStateSubtitle={formatMessage({
                      id: 'app.views.promotion_packets.promotion_packet.emptyStateSubtitle.please_provide_content_for_this',
                      defaultMessage:
                        'Please provide content for this promotion packet.',
                    })}
                  />
                </Card>
              )}
            </Col>
            {showHistory && (
              <Col className="col-12 col-md-4">
                <CommentsLog
                  className="promo-package-history"
                  title={formatMessage({
                    id: 'app.views.promotion_packets.promotion_packet.title.history',
                    defaultMessage: 'History',
                  })}
                  isRichText={true}
                  isExternalUrl={true}
                  // @ts-expect-error
                  comments={promotionPacket?.comments}
                  emptyStateText={formatMessage({
                    id: 'app.views.promotion_packets.promotion_packet.emptyStateText.packet_has_not_been_submitted_yet',
                    defaultMessage: 'Packet has not been submitted yet.',
                  })}
                />
              </Col>
            )}
          </Row>
        </>
      ) : (
        <Loading
          message={formatMessage({
            id: 'app.views.promotion_packets.promotion_packet.message.loading_promotion_packet',
            defaultMessage: 'Loading promotion packet data...',
          })}
        />
      ),
    [
      promotionPacket,
      showModalEditor,
      toggleShowModalEditor,
      formatPacketForFormSubmit,
      formatEditPacketInputs,
      isDraft,
      isAdmin,
      callback,
      highLevelDetails1,
      isEditable,
      highLevelDetails2,
      highLevelDetails3,
      showHistory,
      isExternalGoogleDoc,
      defaultPromoPacketDescription,
      props.promotionPacketId,
      onSubmitPromotionPacketDescription,
      isManagementRoleInferred,
      promoPacketOrgSettings,
      formatMessage,
    ]
  );

  // TODO: modularize so we aren't duplicating
  // the close button's look/feel/function
  const closeButton = useMemo(
    () => (
      <button
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label={formatMessage({
          id: 'app.views.promotion_packets.promotion_packet.aria_label.close',
          defaultMessage: 'Close',
        })}
        onClick={props.onClosed}
      />
    ),
    [props.onClosed, formatMessage]
  );

  const requestsAreEnabled = useMemo(() => {
    return promotionPacketFeedbackRequestsAreEnabled(props.features);
  }, [props.features]);

  const output = useMemo(() => {
    // TODO: modularize so we aren't duplicating
    // modal top-level configurations that are standard
    // for all Confirm modals
    if (props.inModal) {
      return (
        <Modal
          // prevent Esc from closing editor (to avoid issues e.g.
          // when someone escapes file dialog and presses twice)
          keyboard={false}
          // prevent hiding when clicking outside
          backdrop="static"
          className={props.className}
          // autofocus must be false to enable inputs to have autoFocus on them directly
          autoFocus={false}
          isOpen={!!props.promotionPacketId}
          toggle={props.toggle}
          onClosed={props.onClosed}
          size="xl"
        >
          <ModalHeader toggle={props.toggle} close={closeButton}>
            {candidate_person && (
              <Row className="align-items-center">
                <Col className="pe-0">
                  <PersonCard
                    size="xs"
                    bodyOnly
                    person={{
                      ...candidate_person,
                      url: candidate_person?.url + PERFORMANCE().path,
                    }}
                    linked
                    showDescription={false}
                    isExternalUrl={true}
                  />
                </Col>
              </Row>
            )}
          </ModalHeader>
          <ModalBody>
            <Row className="align-items-center pb-4">
              {author_person && (
                <>
                  <Col className="col-auto pe-0">
                    <FormattedMessage
                      id="app.views.promotion_packets.promotion_packet.packet_created_by_prefix"
                      defaultMessage="Packet created by"
                    />
                  </Col>
                  <Col className="ps-2">
                    <PersonCard
                      size="xxs"
                      bodyOnly
                      person={author_person}
                      linked
                      showDescription={false}
                      isExternalUrl={true}
                      headerStyle={{ whiteSpace: 'nowrap' }}
                    />
                  </Col>
                </>
              )}
              <Col />
              <Col className="col-auto">{actions}</Col>
            </Row>
            {promotionPacketBody}
            {promotionPacket && requestsAreEnabled && (
              <PromotionPacketFeedback
                promotionPacket={promotionPacket}
                className="pt-5"
              />
            )}
          </ModalBody>
          <ModalFooter>{actions}</ModalFooter>
        </Modal>
      );
    }

    return (
      <Page
        person={candidate_person}
        pretitle={
          <>
            <Link
              className="text-primary"
              to={PROMOTION_PACKETS(formatMessage).path}
            >
              <FormattedMessage
                id="app.views.promotion_packets.promotion_packet.promotion_packets"
                defaultMessage="Promotion packets"
              />
            </Link>
            {' > '}
            <FormattedMessage
              id="app.views.promotion_packets.promotion_packet.promotion packet"
              defaultMessage="promotion packet"
            />
          </>
        }
        title={candidate_person?.full_name}
        titleLink={
          candidate_person &&
          candidate_person?.url + PROFILE_TAB_PERFORMANCE(formatMessage).path
        }
        subtitle={
          <Row>
            <Col className="col-auto pe-0">
              <FormattedMessage
                id="app.views.promotion_packets.promotion_packet.promotion_packet_created_by_prefix"
                defaultMessage="Promotion packet created by"
              />
            </Col>
            <Col className="ps-2">
              <PersonCard
                size="xxs"
                bodyOnly
                person={author_person}
                linked
                showDescription={false}
                isExternalUrl={true}
                headerStyle={{ whiteSpace: 'nowrap' }}
              />
            </Col>
          </Row>
        }
      >
        {actions}
        {promotionPacketBody}
        {promotionPacket && requestsAreEnabled && (
          <PromotionPacketFeedback
            promotionPacket={promotionPacket}
            className="pt-5"
          />
        )}
      </Page>
    );
  }, [
    props.inModal,
    props.className,
    props.promotionPacketId,
    props.toggle,
    props.onClosed,
    candidate_person,
    formatMessage,
    author_person,
    actions,
    promotionPacketBody,
    requestsAreEnabled,
    promotionPacket,
    closeButton,
  ]);

  // ensure we get the default setting from the server first
  // before rendering so we don't show the default description
  // when there may be a per-org custom description
  if (typeof defaultPromoPacketDescription === 'undefined') {
    return <Loading />;
  }

  const loadOrRenderOutput = loadOrRender(promotionPacket, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return output;
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, features, settings } =
    state;

  return {
    currentOrganization,
    currentProxyPerson,
    meId: me?.id,
    features,
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(PromotionPacket));
