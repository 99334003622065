import { Button, ButtonGroup, Col, Input, Row } from 'reactstrap';
import React, {
  KeyboardEvent,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Organization } from '../../../types';
import { useIntl } from 'react-intl';

interface EmployeeNPSInputProps {
  name?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  value?: string;
  organization: Organization;
  autoFocus?: boolean;
}

const EmployeeNPSInput: React.FC<EmployeeNPSInputProps> = ({
  name,
  onChange,
  required = false,
  value = '',
  organization,
  autoFocus = false,
}) => {
  const { formatMessage } = useIntl();
  const [selectedValue, setSelectedValue] = useState<string | null>(
    value || null
  );
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([]);

  // Update selected value when value prop changes
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleClick = useCallback(
    (value: string) => {
      const newValue = selectedValue === value ? '' : value;
      setSelectedValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, selectedValue]
  );

  // Deliberately not localizing numbers at the moment
  const scale: string[] = useMemo(
    () => Array.from({ length: 11 }, (_, index) => String(index)),
    []
  );

  const getKeyDownHandler = useCallback(
    (currentIndex: number) => (e: KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        const nextIndex = (currentIndex + 1) % scale.length;
        buttonsRef.current[nextIndex]?.focus();
      } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        const prevIndex = (currentIndex - 1 + scale.length) % scale.length;
        buttonsRef.current[prevIndex]?.focus();
      }
    },
    [scale]
  );

  return (
    <>
      <Row>
        <span className="fr-view fw-bold mb-4 mt-2" role="label">
          {formatMessage(
            {
              id: 'app.views.widgets.inputs.employee_nps.label',
              defaultMessage:
                'How likely are you to recommend {organization} as a great place to work?',
            },
            { organization: organization?.name }
          )}
        </span>
      </Row>
      <Row className="px-3">
        {/* we put this before the button group so an initial tab will focus
            on the first button, but we don't autofocus on 0 otherwise to
            avoid psychologically anchoring the user to a "0" rating. */}
        <Input
          autoFocus={autoFocus}
          type="number"
          name={name}
          value={selectedValue || ''}
          required={required}
          onChange={(e) => setSelectedValue(e.target.value)}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            // occupy height of the buttons
            height: '7.5rem',
            opacity: '0',
            pointerEvents: 'none',
            zIndex: '-1',
          }}
        />
        <ButtonGroup className="rounded-lg bg-white px-0">
          {scale.map((item, index) => (
            <Button
              key={item}
              innerRef={function (el) {
                buttonsRef.current[index] = el;
              }}
              className="text-nowrap d-flex align-items-center justify-content-center"
              onClick={() => handleClick(item)}
              onKeyDown={getKeyDownHandler(index)}
              outline={selectedValue !== item}
              active={selectedValue === item}
              color="secondary"
              style={{
                height: '4rem',
                fontVariantNumeric: 'tabular-nums',
              }}
            >
              {item}
            </Button>
          ))}
        </ButtonGroup>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between mt-3 mb-4 text-secondary">
            <div style={{ flex: 1, textAlign: 'start' }} role="label">
              {formatMessage({
                id: 'app.views.widgets.inputs.employee_nps_scale.values.strongly_disagree',
                defaultMessage: 'Not at all likely',
              })}
            </div>
            <div style={{ flex: 1, textAlign: 'end' }} role="label">
              {formatMessage({
                id: 'app.views.widgets.inputs.employee_nps_scale.values.strongly_agree',
                defaultMessage: 'Extremely likely',
              })}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="fr-view fw-bold mt-4" role="label">
          {formatMessage({
            id: 'app.views.widgets.inputs.employee_nps.comment_label',
            defaultMessage: 'Add any comments or context below',
          })}
        </span>
      </Row>
    </>
  );
};

export default React.memo(EmployeeNPSInput);
