import * as consts from './consts/consts';
const now = new Date();

export const levelingFixtures = {
  people: [
    {
      id: '100000',
      avatar: 'https://randomuser.me/api/portraits/women/10.jpg',
      given_name: 'Jasmin',
      family_name: 'Freeman',
      title: 'CEO',
      department: 'Executive',
      performance: 2,
      level_id: 'L10',
      job_profile: 'JOB12345 - Chief Executive Officer',
      track: consts.TRACKS.MANAGEMENT,
      ote: 200000,
      equity: 1000000,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2
      ),
      start_date: new Date('2017-03-06'),
    },
    {
      id: '100001',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
      given_name: 'Aubrey',
      family_name: 'Henson',
      title: 'Senior Product Manager I',
      department: 'Product',
      career_path: 4,
      level_id: 'L7',
      job_profile: 'JOB00107 - Senior Product Manager I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100009,
      performance: 4,
      flight_risk: 1,
      ote: 155000,
      equity: 40000,
      fairness: 0.16,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4
      ),
      start_date: new Date('2017-06-11'),
    },
    {
      id: '100002',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      given_name: 'Basil',
      family_name: 'Moon',
      title: 'Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L4',
      job_profile: 'JOB00204 - Software Engineer I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 2,
      flight_risk: 1,
      ote: 135000,
      equity: 35000,
      fairness: 0.05,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2 * 7
      ),
      start_date: new Date('2019-11-23'),
    },
    {
      id: '100003',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      given_name: 'Colette',
      family_name: 'Lloyd',
      title: 'Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L4',
      job_profile: 'JOB00304 - Software Engineer I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 4,
      flight_risk: 3,
      ote: 130000,
      equity: 50000,
      fairness: 0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 3 * 7
      ),
      start_date: new Date('2019-10-24'),
    },
    {
      id: '100004',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      given_name: 'Douglas',
      family_name: 'Morales',
      title: 'Associate Product Manager I',
      department: 'Product',
      career_path: 4,
      level_id: 'L2',
      job_profile: 'JOB00402 - Associate Product Manager I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100009,
      performance: 3,
      flight_risk: 1,
      ote: 95000,
      equity: 20000,
      fairness: -0.01,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 6 * 7
      ),
      start_date: new Date('2018-02-17'),
    },
    {
      id: '100005',
      avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
      given_name: 'Elisabeth',
      family_name: 'Little',
      title: 'Product Manager II',
      department: 'Product',
      career_path: 4,
      level_id: 'L5',
      job_profile: 'JOB00505 - Product Manager II',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100009,
      performance: 1,
      flight_risk: 5,
      ote: 145000,
      equity: 40000,
      fairness: 0,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 30 * 2
      ),
      start_date: new Date('2018-01-05'),
    },
    {
      id: '100006',
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      given_name: 'Fredric',
      family_name: 'Vincent',
      title: 'Software Engineer II',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L5',
      job_profile: 'JOB00605 - Frontend SWE II',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 4,
      flight_risk: 1,
      ote: 145000,
      equity: 60000,
      fairness: -0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2 * 7
      ),
      start_date: new Date('2019-07-09'),
    },
    {
      id: '100007',
      avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
      given_name: 'Glenda',
      family_name: 'Haas',
      title: 'Customer Success Manager I',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L4',
      job_profile: 'JOB00704 - Enterprise CSM I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100014,
      performance: 1,
      flight_risk: 1,
      ote: 75000,
      equity: 20000,
      fairness: 0.04,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4 * 30
      ),
      start_date: new Date('2015-12-12'),
    },
    {
      id: '100008',
      avatar: 'https://randomuser.me/api/portraits/women/5.jpg',
      given_name: 'Hannah',
      family_name: 'Gaines',
      title: 'Senior Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L7',
      job_profile: 'JOB00807 - Senior Frontent SWE I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 2,
      flight_risk: 3,
      ote: 155000,
      equity: 100000,
      fairness: -0.07,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 5 * 30
      ),
      start_date: new Date('2018-01-10'),
    },
    {
      id: '100009',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      given_name: 'Isaac',
      family_name: 'Salazar',
      title: 'Director, Product',
      department: 'Product',
      career_path: 4,
      level_id: 'L8',
      job_profile: 'JOB00908 - Product Director',
      track: consts.TRACKS.MANAGEMENT,
      manager: 100000,
      performance: 2,
      flight_risk: 3,
      ote: 185000,
      equity: 140000,
      fairness: -0.05,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4
      ),
      start_date: new Date('2018-02-08'),
    },
    {
      id: '100010',
      avatar: 'https://randomuser.me/api/portraits/women/6.jpg',
      given_name: 'Joan',
      family_name: 'Esparza',
      title: 'Senior Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L7',
      job_profile: 'JOB00107 - Senior SWE I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 3,
      flight_risk: 1,
      ote: 160000,
      equity: 100000,
      fairness: 0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 30
      ),
      start_date: new Date('2018-07-02'),
    },
    {
      id: '100011',
      avatar: 'https://randomuser.me/api/portraits/women/7.jpg',
      given_name: 'Kellie',
      family_name: 'Shaffer',
      title: 'Director, Engineering',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L8',
      job_profile: 'JOB00118 - Engineering Director',
      track: consts.TRACKS.MANAGEMENT,
      manager: 100000,
      performance: 3,
      flight_risk: 1,
      ote: 200000,
      equity: 150000,
      fairness: 0.01,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 3 * 7
      ),
      start_date: new Date('2017-09-22'),
    },
    {
      id: '100012',
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      given_name: 'Leland',
      family_name: 'Brown',
      title: 'Customer Success Manager II',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L5',
      job_profile: 'JOB00125 - Enterprise CSM II',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100014,
      performance: 1,
      flight_risk: 5,
      ote: 80000,
      equity: 30000,
      fairness: -0.12,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 5 * 30
      ),
      start_date: new Date('2019-08-19'),
      has_pip: true,
    },
    {
      id: '100013',
      avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
      given_name: 'Miles',
      family_name: 'Gallegos',
      title: 'Customer Success Manager I',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L4',
      job_profile: 'JOB00134 - Enterprise CSM I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100014,
      performance: 4,
      flight_risk: 1,
      ote: 75000,
      equity: 20000,
      fairness: 0,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 8 * 30
      ),
      start_date: new Date('2019-10-26'),
    },
    {
      id: '100014',
      avatar: 'https://randomuser.me/api/portraits/women/8.jpg',
      given_name: 'Nita',
      family_name: 'May',
      title: 'Director, Customer Success',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L8',
      job_profile: 'JOB00148 - Director, Custom Success',
      track: consts.TRACKS.MANAGEMENT,
      manager: 100000,
      performance: 3,
      flight_risk: 1,
      ote: 30000,
      equity: 120000,
      fairness: 0,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4 * 7
      ),
      start_date: new Date('2019-02-14'),
    },
    {
      id: '100015',
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
      given_name: 'Ollie',
      family_name: 'Shields',
      title: 'Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L4',
      job_profile: 'JOB00154 - Backend SWE I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 2,
      flight_risk: 1,
      ote: 130000,
      equity: 70000,
      fairness: 0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1
      ),
      start_date: new Date('2019-01-13'),
    },
  ].map((p) => {
    return {
      ...p,
      full_name: p.given_name + ' ' + p.family_name,
      link: '/leveling/people/' + p.id,
      pronouns:
        p.avatar.indexOf('women') !== -1 ? 'she/her/hers' : 'he/him/his',
    };
  }),
};
