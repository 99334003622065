import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useEscape, useOutsideClick } from '../../utils/util/hooks';

import { ObjectivePerson } from '../../utils/models/Objective';
import PeopleEditor from '../Widgets/Forms/PeopleEditor';
import { UncontrolledPopover } from 'reactstrap';

interface Props {
  initialValue?: ObjectivePerson[];
  toggle: () => void;
  onSave: (people: ObjectivePerson[]) => void;
  onClose: () => void;
  target: React.RefObject<HTMLElement>;
  isOpen: boolean;
  minPeople?: number;
}

const ObjectiveCollaboratorsPopover: FC<Props> = ({
  initialValue,
  toggle,
  onSave,
  onClose,
  target,
  isOpen,
  minPeople = 1,
}) => {
  const { formatMessage } = useIntl();
  const [people, setPeople] = useState(initialValue || []);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAutofocus, setIsAutofocus] = useState<boolean>(false);

  const popoverRef = useRef<HTMLDivElement>(null);

  useEscape(() => onClose());

  const handleInputChange = useCallback((value) => {
    setIsEditing(!!value);
  }, []);

  useOutsideClick(popoverRef, () => {
    if (!isEditing) {
      toggle();
    }
  });

  useEffect(() => {
    setErrorMessage('');
  }, [isOpen]);

  // necessary to trigger autofocus when opened (using isOpen directly
  // does not work)
  useEffect(() => {
    setIsAutofocus(isOpen);
  }, [isOpen]);

  return (
    <UncontrolledPopover
      target={target}
      delay={0}
      placement="bottom"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div
        ref={popoverRef}
        role="dialog"
        title={formatMessage({
          id: 'app.views.widgets.inputs.objective_input.collaborators_editor.title',
          defaultMessage: 'Collaborators Editor',
        })}
      >
        <div className="fw-bold">
          <FormattedMessage
            id="app.views.widgets.inputs.objective_input.add_collaborators"
            defaultMessage="Add collaborators"
          />
        </div>
        <div className="text-muted small mb-3">
          <FormattedMessage
            id="app.views.widgets.inputs.objective_input.add_sub_objectives"
            defaultMessage="Collaborators can edit and add sub-objectives."
          />
        </div>
        <PeopleEditor
          autoFocus={isAutofocus}
          allowSelf={true}
          placeholder={formatMessage({
            id: 'app.views.widgets.inputs.objective_input.collaborators_editor.people_editor.placeholder',
            defaultMessage: 'Enter names',
          })}
          value={people}
          callback={(value) => {
            if (minPeople && value.length < minPeople) {
              setErrorMessage(
                formatMessage(
                  {
                    id: 'app.views.widgets.inputs.objective_input.collaborators_editor.people_editor.min_people',
                    defaultMessage:
                      'You must have at least {minPeople} collaborator.',
                  },
                  { minPeople }
                )
              );
              return;
            }
            setPeople(value);
            onSave(value);
          }}
          onInputChange={handleInputChange}
          deliverEmptyInputChange={true}
          propagateOnClearInput={true}
        />
        {errorMessage && (
          <div className="invalid-feedback">
            <span className="invalid-feedback">{errorMessage}</span>
          </div>
        )}
      </div>
    </UncontrolledPopover>
  );
};

export default ObjectiveCollaboratorsPopover;
