import {
  Organization,
  RouteConfig,
  RouteConfigFn,
  RouteConfigFnWithDefault,
} from 'types';

// This is the magic number used to return all records from ElasticSearch
export const ELASTIC_MAX_SIZE_AND_PAGINATE = 10000;

export const FEEDBACK_DESCRIPTION_MAX_LENGTH = 1000;
export const ACTIVITY_DESCRIPTION_MAX_LENGTH = 2500;
export const ASPIRATION_NAME_MAX_LENGTH = 100;
export const ASPIRATION_DESCRIPTION_MAX_LENGTH = 2500;
export const GOAL_NAME_MAX_LENGTH = 100;
export const GOAL_DESCRIPTION_MAX_LENGTH = 2500;
export const SCORE_COMMENTS_MAX_LENGTH = 1000;
export const PULSE_COMPANY_SUPPORT_MAX_LENGTH = 1000;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultMessageFn = ({ id, defaultMessage }) => defaultMessage;

export const NOT_AVAILABLE = 'n/a';

export const ICONS = {
  // navigation
  HOME: 'fe fe-home',
  TEAM: 'fe fe-users',
  COMPANY: 'fe fe-briefcase',
  ADMIN: 'fe fe-settings',

  // other
  ACTION: 'fe fe-settings',
  ACTIVITY: 'fe fe-activity',
  ADD_PERSON: 'fe fe-user-plus',
  ASPIRATION: 'fe fe-cloud-lightning',
  AWARD: 'fe fe-award',
  CONTRIBUTION: 'fe fe-thumbs-up',
  CREDENTIAL: 'fe fe-bookmark',
  DIRECT_REPORTS: 'fe fe-users',
  ENGAGEMENT_SURVEY: 'fe fe-bar-chart-2',
  FEEDBACK: 'fe fe-message-square',
  FEEDBACK_REQUEST: 'fe fe-help-circle',
  FEEDBACK_DECLINE: 'fe fe-x-circle',
  GOAL: 'fe fe-compass',
  OBJECTIVE: 'fe fe-flag',
  OBJECTIVE_EXPAND: 'fe fe-chevron-right',
  OBJECTIVE_LOADING: 'fe fe-rotate-cw',
  OBJECTIVE_COLLAPSE: 'fe fe-chevron-down',
  NOTE: 'fe fe-edit',
  ONE_ON_ONES: 'fe fe-calendar',
  OPERATING_MANUAL: 'fe fe-file',
  ORG_CHART: 'fe fe-git-merge',
  PEOPLE: 'fe fe-users',
  PUBLIC: 'fe fe-globe',
  PRIVATE: 'fe fe-lock',
  UNLOCKED: 'fe fe-unlock',
  PULSE_CHECKS: 'fe fe-activity',
  PERFORMANCE: 'fe fe-bar-chart',
  DEVELOPMENT_PLAN: 'fe fe-activity',
  PERSON: 'fe fe-user',
  RECOGNITION: 'fe fe-thumbs-up',
  REQUEST: 'fe fe-log-in',
  RESUME: 'fe fe-file-text',
  SKILL: 'fe fe-zap',
  BEHAVIOR: 'fe fe-star',
  // note: we include color here for consistency
  RECOMMENDATION: 'fe fe-gift text-info',
  TALENT_MATRIX: 'fe fe-box text-info',

  // Performance and team administration (filters)
  TITLE_FILTER: 'fe fe-align-left',
  LEVEL_FILTER: 'fe fe-bar-chart',
  FUNCTION_FILTER: 'fe fe-box',
  COST_CENTER_FILTER: 'fe fe-home',
  BUSINESS_UNIT_FILTER: 'fe fe-briefcase',
  DEPARTMENT_FILTER: 'fe fe-users',
  FINAL_RATING_FILTER: 'fe fe-edit',
  LOCATION_FILTER: 'fe fe-map-pin',
  HRBP_FILTER: 'fe fe-user',
  LEADER_FILTER: 'fe fe-flag',
  JOB_FAMILY_FILTER: 'fe fe-refresh-cw',
  CALIBRATION_FILTER: 'fe fe-sliders',
  REPORT_STATUS_NOT_RELEASED: 'fe fe-inbox',
  REPORT_STATUS_RELEASED: 'fe fe-share',
  REPORT_STATUS_CONVERATION_NOT_ACKNOWLEDGED: 'fe fe-help-circle',
  REPORT_STATUS_CONVERATION_ACKNOWLEDGED: 'fe fe-message-square',

  // Performance and team administration (data table)
  MANAGEMENT_TIER: 'fe fe-share-2',
  ALERT: 'fe fe-alert-circle text-danger',
  OMITTED_DATA: 'fe fe-alert-triangle text-danger',
  NOT_ELIGIBLE: 'fe fe-user-x text-muted',
  TERMINATED: 'fe fe-user-minus text-muted',
  EXPORT: 'fe fe-upload',
  IMPORT: 'fe fe-download',
  TENURE: 'fe fe-calendar',
  INFLUENCE: 'fe fe-share-2 text-primary',
  GOLD_STAR: 'fe fe-star text-warning',
  HEADS_UP: 'fe fe-flag text-danger',
  HEADS_UP_SOFT: 'fe fe-lock', // for a softer icon on the input side
  FORMER_MANAGER: 'fe fe-user text-secondary',
  PROMOTION: 'fe fe-chevrons-up text-success',
  PREVIOUS_FINAL_RATING_HEADER: 'fe fe-skip-back text-muted',
  PREVIOUS_FINAL_RATING: 'fe fe-edit-3 text-muted',
  DRAFT_RATING: 'fe fe-edit-3 text-primary',
  FINAL_RATING: 'fe fe-edit-3 text-dark',
  ORIGINAL_CALIBRATION_FLAG: 'fe fe-info text-primary',
  CALIBRATOR: 'fe fe-edit text-primary',
  DRAFT_RATING_DELTA: 'fe fe-shuffle text-primary',
  PREVIOUS_RATING_DELTA: 'fe fe-shuffle text-muted',
  RELEASE_REPORT: 'fe fe-share',
  RELEASE_REPORT_STATUS: 'fe fe-message-square text-primary',
  CONVERSATION: 'fe fe-message-square text-primary',
  NOT_ACKNOWLEDGED: 'fe fe-help-circle text-primary',
  ACKNOWLEDGED: 'fe fe-check text-primary',
  COMPLETE: 'fe fe-check text-success',
  ON_HOLD: 'fe fe-x text-muted',
  HELP: 'fe fe-help-circle',
  SALARY_INCREASE: 'fe fe-dollar-sign',

  // status icons (e.g. for promotion packets)
  DRAFT: 'fe fe-file-text text-muted',
  PENDING: 'fe fe-clock text-warning',
  APPROVED: 'fe fe-check text-success',
  DENIED: 'fe fe-x-circle text-danger',
  WITHDRAWN: 'fe fe-x-circle text-muted',

  // other icons
  HISTORY: 'fe fe-file-text',
  LINK: 'fe fe-link',
  ADD_PEOPLE: 'fe fe-user-plus',
  REFERRAL: 'fe fe-heart',
  EXTERNAL: 'fe fe-external-link',
  CHECK_OPTIONS: 'fe fe-check-square',
  COPY: 'fe fe-copy',

  // messaging
  EMAIL: 'fe fe-mail',
  SLACK: 'fe fe-slack',
  MSTEAMS: 'fe fe-message-square', // feathericons doesn't have an icon for teams
};

export const FORMER_EMPLOYEE_WORDING = (formatMessage) =>
  formatMessage({
    id: 'app.consts.former_team_member',
    defaultMessage: 'Former team member',
  });

export const ON_LEAVE_WORDING = (formatMessage) =>
  formatMessage({
    id: 'app.consts.on_leave',
    defaultMessage: 'On leave',
  });

export const NO_TITLE_WORDING = (formatMessage) =>
  formatMessage({
    id: 'app.consts.no_title',
    defaultMessage: 'No title provided yet',
  });

export const URL_QUERY_PARAMETERS = {
  CONTINUE_URL: 'continue',
};

export const TRACKS = {
  MANAGEMENT: 'M',
  EXEMPT: 'E',
  NONEXEMPT: 'N',
};

export const ERROR_404: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.error_404.name',
    defaultMessage: 'Not Found',
  }),
  path: '*',
});

export const ENG_TEAM_NOTIFIED_OF_ERROR = (formatMessage) =>
  formatMessage({
    id: 'app.consts.error_500.eng_team_notified',
    defaultMessage:
      'An unexpected error has occurred. The engineering team has been notified. Please try again later.',
  });

// Received when db can't find a record (possibly because it was deleted)
// NOTE: Different from ERROR_404 due to capital 'F' and period
export const ERROR_404_DB_RECORD = {
  name: 'Not found.',
  message:
    "We're sorry, but we couldn't locate that record. Please refresh to get the latest data!",
};

export const ERROR_500: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.error.500',
    defaultMessage: 'Error',
  }),
  path: '/500',
});

export const SESSION_FORCE_RELOGIN: RouteConfig = {
  name: 'Session Force Re-Login',
  path: '/force-relogin',
};

export const SESSION_POST_FORCED_LOGIN: RouteConfig = {
  name: 'Session Post Login',
  path: '/post-forced-login',
};

export const APP_INTEGRATION_POST_AUTH_PAGE: RouteConfig = {
  name: 'App Integration Post Auth',
  path: '/integrations/post-auth',
};

export const IDP_INITIATED_OIDC_FLOW: RouteConfig = {
  name: 'IdP Initiated Flow',
  path: '/idp-initiated-flow',
};

export const NO_ORGANIZATION: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.no_organization',
    defaultMessage: 'No Organization',
  }),
  path: '/no-organization',
});

export const CHURNED_ORGANIZATION: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.churned_organization',
    defaultMessage: 'Your organization no longer has access',
  }),
  path: '/no-access',
});

export const USER_NOT_PROVISIONED: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.user_not_provisioned',
    defaultMessage: 'User Not Provisioned',
  }),
  path: '/user-not-provisioned',
});

export const WELCOME: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({ id: 'app.consts.welcome', defaultMessage: 'Welcome' }),
  path: '/welcome',
});

export const WELCOME_STEP_1: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.welcome_step_1',
    defaultMessage: 'Welcome',
  }),
  path: '/welcome/step-1',
});

export const HOME: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.home.name',
    defaultMessage: 'Home',
  }),
  path: '/',
  icon: ICONS.HOME,
});

export const TEAM_ONE_ON_ONES: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.oneonones.name',
    defaultMessage: '1:1s',
  }),
  path: '/one-on-ones',
});

export const TEAM_FEEDBACK_AND_RECOGNITION: RouteConfigFn = (
  formatMessage
) => ({
  name: formatMessage({
    id: 'app.consts.feedback.name',
    defaultMessage: 'Feedback & recognition',
  }),
  path: '/feedback',
});

export const MY_TEAM_OBJECTIVES: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.team_objectives.name',
    defaultMessage: 'Objectives',
  }),
  path: '/profile/objectives',
});

export const TASKS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({ id: 'app.consts.tasks.name', defaultMessage: 'Tasks' }),
  path: '/tasks',
});

export const I18N_TEST: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.i18n_test.name',
    defaultMessage: 'Internazionalization Test',
  }),
  path: '/i18n-test',
});

export const LOCAL_STORAGE_TEST: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.local_storage_test.name',
    defaultMessage: 'Local storage Test',
  }),
  path: '/local-storage-content-test',
});

export const OPPORTUNITIES: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.opportunities.name',
    defaultMessage: 'Opportunities',
  }),
  path: '/opportunities',
});

export const OPPORTUNITIES_DASHBOARD: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.opportunities_dashboard.name',
    defaultMessage: 'All Opportunities',
  }),
  path: '/opportunities',
});

export const OPPORTUNITY_PROBLEMS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.opportunities_problems.name',
    defaultMessage: 'Problems Marketplace',
  }),
  path: OPPORTUNITIES(formatMessage).path + '/problems',
});

export const OPPORTUNITY_IDEAS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.opportunities_ideas.name',
    defaultMessage: 'Ideas',
  }),
  path: OPPORTUNITIES(formatMessage).path + '/ideas',
});

export const OPPORTUNITY_MENTORSHIPS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.opportunities_mentorship.name',
    defaultMessage: 'Mentorships',
  }),
  path: OPPORTUNITIES(formatMessage).path + '/mentorships',
});

export const SUPERUSER_ADMIN: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.superuser_admin.name',
    defaultMessage: 'Superuser Admin',
  }),
  // @ts-expect-error
  path: process.env.REACT_APP_CONFIRM_ADMIN_URL,
  external: true,
});

export const ACCOUNT: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.account.name',
    defaultMessage: 'Account',
  }),
  path: '/account',
});

export const ACCOUNT_NOTIFICATIONS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.account_notification.name',
    defaultMessage: 'Notifications',
  }),
  path: '/account/notifications',
});

export const PROFILE: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.profile.name',
    defaultMessage: 'Profile',
  }),
  path: '/profile',
});

export const MY_PROFILE: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.my_profile.name',
    defaultMessage: 'My profile',
  }),
  path: '/profile',
});

export const MY_OBJECTIVES: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.my_objectives.name',
    defaultMessage: 'My objectives',
  }),
  path: '/profile/objectives',
});

export const MY_ASPIRATIONS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.my_aspirations.name',
    defaultMessage: 'My aspirations',
  }),
  path: '/profile/goals',
});

export const MY_FEEDBACK: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.my_feedback.name',
    defaultMessage: 'My feedback',
  }),
  path: '/profile/feedback',
});

export const MY_PERFORMANCE: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.my_performance.name',
    defaultMessage: 'My performance',
  }),
  path: '/profile/performance',
});

// Subtab of profiles
export const PROFILE_TAB_OBJECTIVES: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.profile_tab_objectives.name',
    defaultMessage: 'Objectives',
  }),
  path: '/objectives',
});

export const PROFILE_TAB_ONE_ON_ONES: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.profile_tab_one_on_ones.name',
    defaultMessage: '1:1s',
  }),
  path: '/one-on-ones',
});

export const PROFILE_TAB_OPERATING_MANUAL: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.profile_tab_operating_manual.name',
    defaultMessage: 'Operating manual',
  }),
  path: '/manual',
});

export const PROFILE_TAB_GOALS_AND_ASPIRATIONS: RouteConfigFn = (
  formatMessage
) => ({
  name: formatMessage({
    id: 'app.consts.profile_tab_goals_and_aspirations.name',
    defaultMessage: 'Goals and aspirations',
  }),
  path: '/goals',
});

export const PROFILE_TAB_FEEDBACK: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.profile_tab_feedback.name',
    defaultMessage: 'Feedback',
  }),
  path: '/feedback',
});

export const PROFILE_TAB_PERFORMANCE: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.profile_tab_performance.name',
    defaultMessage: 'Performance',
  }),
  path: '/performance',
});

export const OBJECTIVES: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.objectives.name',
    defaultMessage: 'Objectives',
  }),
  path: '/objectives',
});

export const MAINTENANCE: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.maintenance.name',
    defaultMessage: 'Confirm is undergoing maintenance',
  }),
  path: '/maintenance',
});

export const LOGOUT: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.logout.name',
    defaultMessage: 'Log out',
  }),
  path: '/logout',
});

export const LOGIN: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.login.name',
    defaultMessage: 'Log in',
  }),
  path: '/login',
});

export const SIGNUP: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.signup.name',
    defaultMessage: 'Signup',
  }),
  path: '/signup',
});

export const ORGANIZATIONS: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.organizations.name',
    defaultMessage: 'Organizations',
  }),
  path: '/organizations',
});

export const APP_INTEGRATIONS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.app_integrations.name',
    defaultMessage: 'Integrations',
  }),
  path: '/integrations',
});

export const PEOPLE_SUBROUTE: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.peple_subroute.name',
    defaultMessage: 'People',
  }),
  path: '/p',
});

export const PEOPLE_ORG_CHART: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.org_chart.name',
    defaultMessage: 'Org chart',
  }),
  path: '/people',
});

export const PEOPLE_DIRECTORY: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.people.name',
    defaultMessage: 'People',
  }),
  path: '/people/list',
});

export const PEOPLE_DIRECTORY_LIST: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.people_directory_list.name',
    defaultMessage: 'List',
  }),
  path: '/people/list',
});

export const ACTIVITIES_DIRECTORY: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.activities_directory.name',
    defaultMessage: 'Activities',
  }),
  path: '/activities',
});

export const SKILLS_DIRECTORY: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.skills_and_behaviors.name',
    defaultMessage: 'Skills and behaviors',
  }),
  path: '/skills',
});

export const CREDENTIALS_DIRECTORY: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.credentials.name',
    defaultMessage: 'Credentials',
  }),
  path: '/credentials',
});

export const CONTRIBUTIONS: RouteConfigFn = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.contributions.name',
    defaultMessage: 'Contributions',
  }),
  path: '/contributions',
});

export const ACTIVITIES: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.activities.name',
    defaultMessage: 'Activities',
  }),
  path: '/activities',
});

export const SKILLS: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.skills.name',
    defaultMessage: 'Skills',
  }),
  path: '/skills',
});

export const CREDENTIALS: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.credentials.name',
    defaultMessage: 'Credentials',
  }),
  path: '/credentials',
});

export const PERFORMANCE: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance.name',
    defaultMessage: 'Performance',
  }),
  path: '/performance',
});

export const PERFORMANCE_MY_REPORT: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance_my_report.name',
    defaultMessage: 'My report',
  }),
  path: '/profile/performance',
});

/* url underneath /profile of tab for a given person's previous
   performance cycle for use in manager's review */
export const PERFORMANCE_PREVIOUS: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance_previous.name',
    defaultMessage: 'Previous performance cycle',
  }),
  path: '/performance?manager=true&previous=true',
});

/* PERFORMANCE CYCLE ADMINISTRATION */

export const TEAM_PERFORMANCE_DASHBOARD_HEADER_LINK: RouteConfigFn = (
  formatMessage
) => ({
  name: formatMessage({
    id: 'app.consts.survey_performance.name',
    defaultMessage: 'Surveys & performance',
  }),
  path: PERFORMANCE(formatMessage).path + '/admin',
});

export const TEAM_PERFORMANCE_DASHBOARD: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.team_performance_dashboard.name',
    defaultMessage: 'Team',
  }),
  path: PERFORMANCE(formatMessage).path + '/admin',
});

export const PERFORMANCE_ADMINISTRATION_PEER_SELECTION: RouteConfigFnWithDefault =
  (formatMessage = defaultMessageFn) => ({
    name: formatMessage({
      id: 'app.consts.performance_administration_peer_selection.name',
      defaultMessage: 'Performance Administration: Peer Selection',
    }),
    path: TEAM_PERFORMANCE_DASHBOARD(formatMessage).path + '/peers',
  });

export const PARTICIPATION_DASHBOARD: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.participation_dashboard.name',
    defaultMessage: 'Participation dashboard',
  }),
  path: PERFORMANCE(formatMessage).path + '/participation',
});

export const RATINGS_DASHBOARD: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.ratings_dashboard.name',
    defaultMessage: 'Ratings dashboard',
  }),
  path: PERFORMANCE(formatMessage).path + '/ratings',
});

export const TAKEAWAYS_DASHBOARD: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.takeaways_dashboard.name',
    defaultMessage: 'Performance takeaways & recommendations',
  }),
  path: PERFORMANCE(formatMessage).path + '/takeaways',
});

/* PERFORMANCE CYCLE STEPS */

export const PERFORMANCE_COMPLETE_REVIEW: RouteConfigFnWithDefault = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance_complete_review.name',
    defaultMessage: 'Complete review',
  }),
  path: '/performance',
});

export const PERFORMANCE_PHASE_BASICS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_phase_basics.name',
    defaultMessage: 'You + your network',
  }),
});

export const PERFORMANCE_PHASE_OTHERS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_phase_others.name',
    defaultMessage: 'Peer + upward feedback',
  }),
});

export const PERFORMANCE_PHASE_EVALUATION = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_phase_evaluation.name',
    defaultMessage: 'Manager reviews',
  }),
});

export const PERFORMANCE_PHASE_CALIBRATION = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_phase_calibration.name',
    defaultMessage: 'Calibration',
  }),
});

export const PERFORMANCE_PHASE_REPORTING = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_phase_reporting.name',
    defaultMessage: 'Conversations',
  }),
});

export const PERFORMANCE_PHASE_NAMES = (formatMessage) => ({
  S: PERFORMANCE_PHASE_BASICS(formatMessage).name,
  O: PERFORMANCE_PHASE_OTHERS(formatMessage).name,
  E: PERFORMANCE_PHASE_EVALUATION(formatMessage).name,
  C: PERFORMANCE_PHASE_CALIBRATION(formatMessage).name,
  R: PERFORMANCE_PHASE_REPORTING(formatMessage).name,
});

export const PERFORMANCE_STEP_WELCOME = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_welcome.name',
    defaultMessage: 'Welcome',
  }),
  path: PERFORMANCE(formatMessage).path + '/welcome',
});

export const PERFORMANCE_STEP_OTHERS_PHASE_WELCOME = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_others_phase_welcome.name',
    defaultMessage: 'Begin',
  }),
  path: PERFORMANCE(formatMessage).path + '/phase-others',
});

export const PERFORMANCE_STEP_EVALUATION_PHASE_WELCOME = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_evaluation_phase_welcome.name',
    defaultMessage: 'Begin',
  }),
  path: PERFORMANCE(formatMessage).path + '/phase-evaluation',
});

export const PERFORMANCE_STEP_SELF_PHASE_COMPLETE = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_self_phase_complete.name',
    defaultMessage: 'Phase complete',
  }),
  path: PERFORMANCE(formatMessage).path + '/phase-self-complete',
});

export const PERFORMANCE_STEP_OTHERS_PHASE_COMPLETE = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_others_phase_complete.name',
    defaultMessage: 'Phase complete',
  }),
  path: PERFORMANCE(formatMessage).path + '/phase-others-complete',
});

export const PERFORMANCE_STEP_EVERYTHING_COMPLETE = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_everything_complete.name',
    defaultMessage: 'One last thing...',
  }),
  path: PERFORMANCE(formatMessage).path + '/all-done',
});

export const PERFORMANCE_STEP_BASICS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_basics.name',
    defaultMessage: 'Basics',
  }),
  path: PERFORMANCE(formatMessage).path + '/basics',
});

export const PERFORMANCE_STEP_NETWORK = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_network.name',
    defaultMessage: 'Network',
  }),
  path: PERFORMANCE(formatMessage).path + '/network',
});

export const PERFORMANCE_STEP_CALLOUTS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_callouts.name',

    defaultMessage: 'Callouts',
  }),
  path: PERFORMANCE(formatMessage).path + '/callouts',
  // page # of Callout details screen
  detailsPage: '2',
});

export const PERFORMANCE_STEP_CHOOSE_MUST_HAVE_PEERS = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_choose_must_have_peers.name',
    defaultMessage: 'Request peers',
  }),
  path: PERFORMANCE(formatMessage).path + '/request-peers',
});

export const PERFORMANCE_STEP_ASSESS_SELF = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_assess_self.name',
    defaultMessage: 'Self-reflection',
  }),
  path: PERFORMANCE(formatMessage).path + '/self',
});

export const PERFORMANCE_STEP_ASSESS_MANAGER = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_assess_manager.name',
    defaultMessage: 'Manager',
  }),
  path: PERFORMANCE(formatMessage).path + '/manager',
});

export const PERFORMANCE_STEP_ASSESS_PEERS = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_assess_peers.name',
    defaultMessage: 'Peers',
  }),
  path: PERFORMANCE(formatMessage).path + '/assess-peers',
});

export const PERFORMANCE_STEP_ASSESS_OPTIONAL_PEERS = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_assess_optional_peers.name',

    defaultMessage: 'Optional peers',
  }),
  path: PERFORMANCE(formatMessage).path + '/optional-peers',
});

export const PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS = (
  formatMessage = defaultMessageFn
) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_assess_direct_reports.name',

    defaultMessage: 'Team',
  }),
  path: PERFORMANCE(formatMessage).path + '/direct-reports',
});

export const PERFORMANCE_STEP_ASSESS_ORGANIZATION = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.performance_step_assess_organization.name',

    defaultMessage: 'Organization',
  }),
  path: PERFORMANCE(formatMessage).path + '/organization',
});

/* NON PERFORMANCE-RELATED PATHS */

export const DEVELOPMENT_PLANS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.development_plans.name',
    defaultMessage: 'Development plans',
  }),
  path: '/development-plans',
});

export const PROMOTION_PACKETS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.promo_packets.name',
    defaultMessage: 'Promotion packets',
  }),
  path: '/packets',
});

export const GOALS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.goals.name',

    defaultMessage: 'Goals',
  }),
  path: '/goals',
});

export const LEVELING_FRAMEWORK = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.leveling_framework.name',
    defaultMessage: 'Leveling framework',
  }),
  path: '/leveling-framework',
});

export const NUDGES_ADMINISTRATION = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.nudges_administration.name',
    defaultMessage: 'Nudges administration',
  }),
  path: '/nudges',
});

export const INVITATIONS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.invitations.name',
    defaultMessage: 'Invitations',
  }),
  path: '/invitations',
});

export const ORGANIZATION_SETTINGS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.organization_settings.name',
    defaultMessage: 'Organization settings',
  }),
  path: '/settings',
});

export const ORGANIZATION_SETTINGS_GENERAL = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.organization_settings_general.name',
    defaultMessage: 'General',
  }),
  path: ORGANIZATION_SETTINGS(formatMessage).path + '/general',
});

export const ORGANIZATION_SETTINGS_FEATURES = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.organization_settings_features',
    defaultMessage: 'Features',
  }),
  path: ORGANIZATION_SETTINGS(formatMessage).path + '/features',
});

export const ORGANIZATION_SETTINGS_ADMINISTRATORS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.organization_settings_administrators',
    defaultMessage: 'Administrators',
  }),
  path: ORGANIZATION_SETTINGS(formatMessage).path + '/administrators',
});

export const ADMINISTRATION = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.administration.name',
    defaultMessage: 'Administration',
  }),
  path: '/admin',
});

export const ADMINISTRATION_CAMPAIGNS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.cycle_administration.name',
    defaultMessage: 'Cycle administration',
  }),
  path: ADMINISTRATION(formatMessage).path + '/cycles',
});

export const ADMINISTRATION_PEOPLE = (formatMessage) => ({
  name: formatMessage({
    id: 'app.widgets.task.ADMINISTRATION_PEOPLE.name',
    defaultMessage: 'People administration',
  }),
  path: ADMINISTRATION(formatMessage).path + '/people',
});

export const ADMINISTRATION_PEOPLE_ADMINISTRATORS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.widgets.task.ADMINISTRATION_PEOPLE_ADMINISTRATORS.name',
    defaultMessage: 'Administrators',
  }),
  path: ADMINISTRATION(formatMessage).path + '/people/administrators',
});

export const PREVIEW_CAMPAIGN = (formatMessage = defaultMessageFn) => ({
  name: formatMessage({
    id: 'app.consts.preview_campaign.name',
    defaultMessage: 'Preview cycle',
  }),
  path: '/preview/cycles',
});

export const TEAM = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.team.alignment.name',
    defaultMessage: 'Alignment',
  }),
  path: '/team',
});

export const ENGAGEMENT_HUB = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.engagement_hub.name',
    defaultMessage: 'Engagement',
  }),
  path: '/engagement/overview',
});

export const TEAM_OBJECTIVES = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.team_objectives.name',
    defaultMessage: 'Objectives',
  }),
  path: '/team/objectives/overview',
});

export const TEAM_OBJECTIVES_TAB_OVERVIEW = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.team_objectives.tab.overview.name',
    defaultMessage: 'Overview',
  }),
  path: TEAM_OBJECTIVES(formatMessage).path,
});

export const TEAM_OBJECTIVES_TAB_PARTICIPATION = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.team_objectives.tab.participation.name',
    defaultMessage: 'Participation',
  }),
  path: '/team/objectives/participation',
});

export const REFERRALS = (formatMessage) => ({
  name: formatMessage({
    id: 'app.consts.referrals.name',
    defaultMessage: 'Refer Confirm',
  }),
  path: '/referrals',
});

// Intercom support articles
export const SUPPORT_PORTAL_PREFIX = 'https://support.confirm.com/en/articles/';
export const SUPPORT_PORTAL = {
  // security
  SECURITY_OVERVIEW: SUPPORT_PORTAL_PREFIX + '3824064-security-on-confirm',
  SECURITY_THIRD_PARTY_SIGNIN:
    SUPPORT_PORTAL_PREFIX +
    '3854774-why-is-it-required-to-sign-in-with-a-third-party',

  // activity sharing
  ACTIVITY_SHARING_BEST_PRACTICES:
    SUPPORT_PORTAL_PREFIX + '4637349-best-practices-for-sharing-activity',

  // performance cycles
  PERFORMANCE_USING_CONFIRM_RELEASING_REPORTS:
    SUPPORT_PORTAL_PREFIX + '5244669-releasing-and-viewing-performance-reports',
  PERFORMANCE_REPORT_OVERVIEW:
    SUPPORT_PORTAL_PREFIX + '5112693-what-will-my-performance-report-look-like',

  // people manager resources
  ROCK_STARS_VS_SUPERSTARS:
    SUPPORT_PORTAL_PREFIX + '5743023-rock-stars-vs-superstars',
  FLOW_STATE: SUPPORT_PORTAL_PREFIX + '5743041-flow-state',
  PERFORMANCE_IMPROVEMENT_PLANS:
    SUPPORT_PORTAL_PREFIX + '5743054-performance-improvement-plans',

  ONE_ON_ONES: SUPPORT_PORTAL_PREFIX + '6925983-1-1s',
};

export const PERFORMANCE_QUESTIONS = (formatMessage) => ({
  energizers: (duration, organizationName) =>
    formatMessage(
      {
        id: 'app.consts.performance_questions.energizers',
        defaultMessage:
          'During the past {duration}, who at {organizationName} motivated or energized you?',
      },
      {
        duration,
        organizationName,
      }
    ),
  advisors: (duration, organizationName) =>
    formatMessage(
      {
        id: 'app.consts.performance_questions.advisors',
        defaultMessage:
          'During the past {duration}, who at {organizationName} did you go to for help and advice?',
      },
      { duration, organizationName }
    ),
  stakeholders: (duration, organizationName) =>
    formatMessage(
      {
        id: 'app.consts.performance_questions.stakeholders',
        defaultMessage:
          'Based on the past {duration}, who at {organizationName} can give you feedback about your work?',
      },
      { duration, organizationName }
    ),
  goldstars: (duration) =>
    formatMessage(
      {
        id: 'app.consts.performance_questions.goldstars',
        defaultMessage:
          'Based on the past {duration}, who do you consider to be a "Gold Star" contributor?',
      },
      { duration }
    ),
  headsups: (duration) =>
    formatMessage(
      {
        id: 'app.consts.performance_questions.headsups',
        defaultMessage:
          'Based on the past {duration}, who do you believe needs additional support or attention?',
      },
      { duration }
    ),
  formers: (duration) =>
    formatMessage(
      {
        id: 'app.consts.performance_questions.formers',
        defaultMessage:
          'In the past {duration}, who used to report to you but no longer does?',
      },
      { duration }
    ),
});

export const CONFIRM_DEMO_FULL_PATH = 'https://www.confirm.com/demo';

// set generous defaults (that don't include script to prevent XSS attacks)
export const FROALA_CONFIG_DEFAULTS = {
  htmlAllowedTags: [
    'a',
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'bdi',
    'bdo',
    'blockquote',
    'br',
    'button',
    'canvas',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'hgroup',
    'hr',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    'menu',
    'menuitem',
    'meter',
    'nav',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'pre',
    'progress',
    'queue',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'style',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'title',
    'tr',
    'track',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
  ],
  htmlAllowedAttrs: [
    'accept',
    'accept-charset',
    'accesskey',
    'action',
    'align',
    'allowfullscreen',
    'allowtransparency',
    'alt',
    'aria-.*',
    'async',
    'autocomplete',
    'autofocus',
    'autoplay',
    'autosave',
    'background',
    'bgcolor',
    'border',
    'charset',
    'cellpadding',
    'cellspacing',
    'checked',
    'cite',
    'class',
    'color',
    'cols',
    'colspan',
    'content',
    'contenteditable',
    'contextmenu',
    'controls',
    'coords',
    'data',
    'data-.*',
    'datetime',
    'default',
    'defer',
    'dir',
    'dirname',
    'disabled',
    'download',
    'draggable',
    'dropzone',
    'enctype',
    'for',
    'form',
    'formaction',
    'frameborder',
    'headers',
    'height',
    'hidden',
    'high',
    'href',
    'hreflang',
    'http-equiv',
    'icon',
    'id',
    'ismap',
    'itemprop',
    'keytype',
    'kind',
    'label',
    'lang',
    'language',
    'list',
    'loop',
    'low',
    'max',
    'maxlength',
    'media',
    'method',
    'min',
    'mozallowfullscreen',
    'multiple',
    'muted',
    'name',
    'novalidate',
    'open',
    'optimum',
    'pattern',
    'ping',
    'placeholder',
    'playsinline',
    'poster',
    'preload',
    'pubdate',
    'radiogroup',
    'readonly',
    'rel',
    'required',
    'reversed',
    'rows',
    'rowspan',
    'sandbox',
    'scope',
    'scoped',
    'scrolling',
    'seamless',
    'selected',
    'shape',
    'size',
    'sizes',
    'span',
    'src',
    'srcdoc',
    'srclang',
    'srcset',
    'start',
    'step',
    'summary',
    'spellcheck',
    'style',
    'tabindex',
    'target',
    'title',
    'type',
    'translate',
    'usemap',
    'value',
    'valign',
    'webkitallowfullscreen',
    'width',
    'wrap',
  ],
  htmlAllowedEmptyTags: [
    'textarea',
    'a',
    'iframe',
    'object',
    'video',
    'style',
    '.fa',
    '.fr-emoticon',
    '.fr-inner',
    'path',
    'line',
    'hr',
  ],
};

export const NETWORK_ERROR = 'Error: Network Error';

export const NETWORK_ERROR_MESSAGE =
  'There was a temporary network problem. Please try again.';

export const SUBMIT_ERROR =
  'Sorry, something went wrong! The technical team was notified and will fix it ASAP. Please try again later.';

export const MISSING_PERMISSIONS =
  'You do not have permission to see this page.';

export const INVALID_REQUEST =
  'Invalid request. If this is unexpected, please contact customer support.';

export const INVALID_STATE = 'Invalid state';

// word-for-word match from Auth0 when denied due to not verifying email
export const VERIFY_EMAIL_ERROR_MESSAGE =
  'Please verify your email before logging in.';

// startsWith match from Auth0 when denied due to user manually setting the computer time.
export const TIME_ERROR_MESSAGE_INCIPIT =
  'Expiration Time (exp) claim error in the ID token;';

export const UNAUTHED_REDIRECT_KEY = 'unauthed_redirect';

export const INTERCOM_HMAC_CACHE_KEY = 'intercom_hmac_cache_key';

export const UNSAVED_CHANGES_PROMPT =
  'You have unsaved changes. Are you sure you want to discard them?';

export const CHART_COLORS = [
  '#95AAC9',
  '#A6C5F7',
  '#2C7BE5',
  '#FD3216',
  '#00FE35',
  '#6A76FC',
  '#FED4C4',
  '#FE00CE',
  '#0DF9FF',
  '#F6F926',
  '#FF9616',
  '#479B55',
  '#EEA6FB',
  '#DC587D',
  '#D626FF',
  '#6E899C',
  '#00B5F7',
  '#B68E00',
  '#C9FBE5',
  '#FF0092',
  '#22FFA7',
  '#E3EE9E',
  '#86CE00',
  '#BC7196',
  '#7E7DCD',
  '#FC6955',
  '#E48F72',
];

export const PERFORMANCE_METRICS = [
  'influence',
  'heads_ups',
  'gold_stars',
  'influence_gold_stars',
];

export const PERFORMANCE_METRIC_NAMES = (formatMessage) => ({
  influence: formatMessage({
    id: 'app.views.perromance_metric_names.influence',
    defaultMessage: 'Influence',
  }),
  heads_ups: formatMessage({
    id: 'app.views.perromance_metric_names.heads_ups',
    defaultMessage: 'Heads ups',
  }),
  gold_stars: formatMessage({
    id: 'app.views.perromance_metric_names.gold_stars',
    defaultMessage: 'Gold stars',
  }),
  influence_gold_stars: formatMessage({
    id: 'app.views.perromance_metric_names.influence_gold_stars',
    defaultMessage: 'Influence and gold stars',
  }),
});

export const COLORBLIND_COLORS = {
  red: 'rgb(213, 94, 0, 0.7)',
  blue: 'rgb(86, 180, 233, 0.7)',
  green: 'rgb(0, 158, 115, 0.7)',
  transparent: 'rgb(0, 0, 0, 0)',
};

export const TEXT_COLORS = {
  warning: '#F6C343',
  muted: '#95AAC9',
  darkText: '#12263F',
};

export const STANDARD_COLORS = {
  primary: '#2C7BE5',
  white: 'white',
};

export const WHATS_NEW = {
  name: "What's new?",
  path: 'https://updates.confirm.com/en',
};

export const OKRS_LEARN_MORE = (formatMessage) => ({
  name: formatMessage({
    id: 'app.views.person.person_objectives.nav_link.learn_more',
    defaultMessage: 'Learn more about OKRs',
  }),
  path: 'https://support.confirm.com/en/articles/6005129-objectives-and-key-results-okrs',
});

export const FLAGS = {
  ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS:
    'allow_feedback_requests_when_claiming_contributions',
  ALLOW_CALENDAR_INTEGRATION: 'allow_calendar_integration',
  HIDE_FORMER_EMPLOYEES: 'hide_former_employees',
  AUTO_REDIRECT_TO_PERF: 'auto_redirect_to_perf',
  PEOPLE_CSV_IMPORTER: 'people_csv_importer',
  ENGAGEMENT_SURVEY_SUMMARIES: 'engagement_survey_summaries',
  ENGAGEMENT_HUB: 'engagement_hub',
  PERFORMANCE_PROFILE_ENABLE_DISTRIBUTIONS:
    'performance_profile_enable_distributions',
  PERFORMANCE_PROFILE_ENABLE_CHARTS: 'performance_profile_enable_charts',
  REFERRALS: 'referrals',
};

// Some handy time constants.
// We keep the same list in admin/api/consts.py.
export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_HOUR_IN_SECONDS = 60 * ONE_MINUTE_IN_SECONDS;
export const ONE_DAY_IN_SECONDS = 24 * ONE_HOUR_IN_SECONDS;
export const ONE_WEEK_IN_SECONDS = 7 * ONE_DAY_IN_SECONDS;

export const ONE_SECOND_IN_MILLISECONDS = 1000;
export const ONE_MINUTE_IN_MILLISECONDS =
  ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS;
export const ONE_HOUR_IN_MILLISECONDS =
  ONE_HOUR_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS;
export const ONE_DAY_IN_MILLISECONDS =
  ONE_DAY_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS;
export const ONE_WEEK_IN_MILLISECONDS =
  ONE_WEEK_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS;

export const ENGAGEMENT_SURVEY_OTHERS_EXPLANATION = (formatMessage) =>
  formatMessage({
    id: 'app.consts.engagement_survey_others_explanation',
    defaultMessage:
      "The 'Others' category represents all respondents whose grouping was too small to be presented separately.",
  });

export const G2_REVIEW_LINK_PERF_ALL_DONE =
  'https://www.g2.com/contributor/perf-all-done';

export const G2_REVIEW_LINK_REFERRAL_PAGE =
  'https://www.g2.com/contributor/app-referral-page';

// Make sure this agrees with DEFAULT_ACCOMPLISHMENTS_MINIMUM in admin/api/models.py
export const DEFAULT_ACCOMPLISHMENTS_MINIMUM = 1;

// Notification medium. Keep in sync with admin/api/utils.py[NotificationMedium]
export const NOTIFICATION_MEDIUM = {
  EMAIL: 'email',
  MSTEAMS: 'msteams',
  SLACK: 'slack',
};

// Storage layer representation of the medium.
// As in models.py[NotificationBatch.NotificationMethods].
export const NOTIFICATION_METHODS = {
  EMAIL: 'E',
  SLACK: 'S',
  MSTEAMS: 'M',
};

export const toSortedJoin = (list: string[]): string => {
  const listCopy = [...list];
  listCopy.sort();
  return listCopy.join(',');
};

export const getNotificationMethods = (
  formatMessage,
  organization?: Organization
): { id: string; name: string }[] => {
  const notification_medium_options = [
    {
      id: toSortedJoin([NOTIFICATION_METHODS.EMAIL]),
      name: formatMessage({
        id: 'app.views.widgets.modals.modal_schedule_notification_button.medium.email_only',
        defaultMessage: 'Email Only',
      }),
    },
  ];

  if (organization?.instant_messaging === NOTIFICATION_MEDIUM.SLACK) {
    notification_medium_options.push(
      ...[
        {
          id: toSortedJoin([NOTIFICATION_METHODS.SLACK]),
          name: formatMessage({
            id: 'app.views.widgets.modals.modal_schedule_notification_button.medium.slack_only',
            defaultMessage: 'Slack Only',
          }),
        },
        {
          id: toSortedJoin([
            NOTIFICATION_METHODS.SLACK,
            NOTIFICATION_METHODS.EMAIL,
          ]),
          name: formatMessage({
            id: 'app.views.widgets.modals.modal_schedule_notification_button.medium.email_slack',
            defaultMessage: 'Email & Slack',
          }),
        },
      ]
    );
  }

  if (organization?.instant_messaging === NOTIFICATION_MEDIUM.MSTEAMS) {
    notification_medium_options.push(
      ...[
        {
          id: toSortedJoin([NOTIFICATION_METHODS.MSTEAMS]),
          name: formatMessage({
            id: 'app.views.widgets.modals.modal_schedule_notification_button.medium.msteams_only',
            defaultMessage: 'MS Teams Only',
          }),
        },
        {
          id: toSortedJoin([
            NOTIFICATION_METHODS.MSTEAMS,
            NOTIFICATION_METHODS.EMAIL,
          ]),
          name: formatMessage({
            id: 'app.views.widgets.modals.modal_schedule_notification_button.medium.email_msteams',
            defaultMessage: 'Email & MS Teams',
          }),
        },
      ]
    );
  }

  return notification_medium_options;
};
