import * as consts from '../../consts/consts';

import {
  ACTIVITY_VISIBILITY_EVERYONE,
  contributionMatchesCredential,
  contributionMatchesPerson,
  contributionMatchesSkill,
  getActivityDateSubtitle,
  getActivityType,
  getActivityVisibilityType,
  getContributionPerson,
  getContributionsForCredential,
  getContributionsForPerson,
  getContributionsForSkill,
  getContributorRole,
} from '../../utils/models/Activity';
import { Col, Row, UncontrolledPopover } from 'reactstrap';
import {
  FEEDBACK_TYPE_ACTIONABLE,
  FEEDBACK_TYPE_REQUEST,
} from '../../utils/models/Feedback';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  getUnattributedPerson,
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';
import {
  ignoreContribution,
  loadTasks,
  unignoreContribution,
} from '../../actions';

import Avatar from '../Widgets/People/Avatar';
import AvatarGroup from '../Widgets/People/AvatarGroup';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ContributionCard from './ContributionCard';
import EmojiBar from '../Widgets/EmojiBar';
import FeedbackCard from '../Feedback/FeedbackCard';
import ImageGallery from 'react-image-gallery';
import ModalFeedbackEditorButton from '../Feedback/ModalFeedbackEditorButton';
import ObjectCard from '../Widgets/Cards/ObjectCard';
import PropTypes from 'prop-types';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import { connect } from 'react-redux';
import { getAttributedOrUnattributedPersonHeading } from '../../utils/models/Campaign';
import { getImagesFromHtmlDescription } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

const ActivityGallery = (props) => {
  const ref = useRef();

  const toogleFullScreen = useCallback(() => {
    if (ref?.current) {
      // @ts-expect-error
      ref.current?.toggleFullScreen();
    }
  }, [ref]);

  return (
    <ImageGallery
      lazyLoad={true}
      onClick={toogleFullScreen}
      ref={ref}
      items={props.images.map((i) => ({
        ...i,
        thumbnail: i.thumbnail ? i.thumbnail : i.original,
      }))}
      thumbnailPosition={props.images?.length > 1 ? 'right' : undefined}
      showFullscreenButton={false}
      useBrowserFullscreen={true}
      showPlayButton={false}
      showBullets={false}
      showNav={false}
      showThumbnails={props.images?.length > 1}
    />
  );
};

ActivityGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ActivityCard = ({ isDemoOrPreviewMode = false, ...props }) => {
  const [showAllContributions, setShowAllContributions] = useState(false);
  const [showGiveOrRequestFeedbackModal, setShowGiveOrRequestFeedbackModal] =
    useState(false);
  const toggleGiveOrRequestFeedback = useCallback(() => {
    setShowGiveOrRequestFeedbackModal(!showGiveOrRequestFeedbackModal);
  }, [showGiveOrRequestFeedbackModal]);

  const { user } = useAuth0();
  const intl = useIntl();
  const { locale, formatMessage } = intl;

  const userSub = user?.sub;
  const unconfirmedRef = useRef();
  const history = useHistory();

  const propsActivity = props.activity;
  const propsSetActivity = props.setActivity;
  const propsLoadTasks = props.loadTasks;
  const propsOpenCreateActivityDialog = props.openCreateActivityDialog;
  const propsOnClickAcceptCredit = props.onClickAcceptCredit;
  const visibility = getActivityVisibilityType(propsActivity, formatMessage);

  const onDeleteContribution = useCallback(
    (contribution) => {
      const newActivity = {
        ...propsActivity,
        contributions: propsActivity?.contributions.filter(
          (c) => c.id !== contribution.id
        ),
      };

      propsSetActivity(newActivity);

      // save updated activity to the cache so reloading later retains the contribution update
      ConfirmAPI.setObjectInCache(
        userSub,
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.ACTIVITIES,
        newActivity?.id,
        newActivity
      );
    },
    [propsActivity, propsSetActivity, userSub, props.currentProxyPerson]
  );

  // if this is the current user's contribution, allow inline editing and deleting
  const callback = useCallback(
    (contribution) => {
      const contributions = propsActivity.contributions
        ? propsActivity.contributions
        : [];

      let newActivity = null;

      if (contributions.find((c) => c.id === contribution.id)) {
        // if this is an existing contribution with an id, replace it
        newActivity = {
          ...propsActivity,
          contributions: contributions.map((c) =>
            c.id === contribution.id ? contribution : c
          ),
        };
      } else {
        // id not already in list, so replace the "stub" frontend-only contribution
        newActivity = {
          ...propsActivity,
          contributions: contributions.map((c) => (!c.id ? contribution : c)),
        };
      }

      propsSetActivity(newActivity);

      // save updated activity to the cache so reloading later retains the contribution update
      ConfirmAPI.setObjectInCache(
        userSub,
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.ACTIVITIES,
        // @ts-expect-error
        newActivity?.id,
        newActivity
      );

      // refresh tasks (which may go down by one if the above was claiming a contribution
      propsLoadTasks(userSub, props.currentProxyPerson?.email);
    },
    [
      propsActivity,
      propsSetActivity,
      userSub,
      props.currentProxyPerson,
      propsLoadTasks,
    ]
  );

  const hasFocus = useMemo(
    () =>
      props.focalPerson ||
      props.focalSkill ||
      props.focalCredential ||
      props.focalContributions,
    [
      props.focalContributions,
      props.focalCredential,
      props.focalPerson,
      props.focalSkill,
    ]
  );

  // focus on anything provided that should be focused on;
  // if none provided, don't show the contribution, just the core activity
  const focalContributions = useMemo(() => {
    if (props.focalPerson) {
      return getContributionsForPerson(props.activity, props.focalPerson);
    } else if (props.focalSkill) {
      return getContributionsForSkill(props.activity, props.focalSkill);
    } else if (props.focalCredential) {
      return getContributionsForCredential(
        props.activity,
        props.focalCredential
      );
    } else if (props.focalContributions) {
      return props.focalContributions;
    } else {
      return [];
    }
  }, [
    props.activity,
    props.focalPerson,
    props.focalSkill,
    props.focalCredential,
    props.focalContributions,
  ]);

  const formatActivityTitle = useCallback(
    (activityName) => {
      const name = (
        <>
          {activityName
            ? activityName
            : intl.formatMessage(
                {
                  id: 'app.activity.activity_card.acivity.title.untitled',
                  defaultMessage: 'Untitled {activityType}',
                  description: 'Activity title for untitled activities',
                },
                { activityType: props.activity?.type?.name }
              )}
        </>
      );

      if (props.isResumeView) {
        let role = null;
        const person = props.focalPerson
          ? props.focalPerson
          : props.focalContributions?.length > 0
          ? getContributionPerson(props.focalContributions[0])
          : null;

        const actualRole = getContributorRole(props.activity, person);
        if (actualRole) {
          role = actualRole;
        }

        return (
          <span>
            {role ? `${role}, ` : ''}
            <span
              className={props.isCompressedView ? 'text-muted' : 'fw-normal'}
            >
              {name}
            </span>
          </span>
        );
      }

      return name;
    },
    [
      props.activity,
      props.isResumeView,
      props.focalPerson,
      props.focalContributions,
      props.isCompressedView,
      intl,
    ]
  );

  const activityTitle = useMemo(() => {
    return formatActivityTitle(props.activity.name);
  }, [formatActivityTitle, props.activity.name]);

  const person = useMemo(() => {
    return props.focalPerson
      ? props.focalPerson
      : props.focalContributions?.length > 0
      ? getContributionPerson(props.focalContributions[0])
      : null;
  }, [props.focalContributions, props.focalPerson]);

  const isUnconfirmed = useMemo(() => {
    if (props.isResumeView) {
      // if this is a private activity with no contributions, consider it hidden
      if (
        props.activity.visibility !==
          ACTIVITY_VISIBILITY_EVERYONE(formatMessage).id &&
        props.activity.contributions?.length === 0
      ) {
        // TODO: have a better solution as if somoene
        // deleted all accomplishments this would show false
        // but should be true for anyone with a private accomplishment
        return false;
      }

      const personsContribution =
        person?.id &&
        props.activity?.contributions &&
        props.activity.contributions.find((c) =>
          peopleObjectsAreEqual(getContributionPerson(c), person)
        );

      // only confirm if user has claimed their own contribution
      // (as indicated by a role being set in it) or they explicitly ignored it
      // (indicating they acknowledge it but want to hide it on the resume)
      return (
        personsContribution &&
        !personsContribution.contributor_role &&
        !personsContribution.ignored_at
      );
    }

    return false;
  }, [props.isResumeView, props.activity, person, formatMessage]);

  const contributors = useMemo(
    () =>
      props.activity?.contributions
        ? props.activity.contributions.map(getContributionPerson)
        : [],
    [props.activity.contributions]
  );

  const isMe = useMemo(
    () => peopleIdsAreEqual(props.meId, person?.id),
    [person?.id, props.meId]
  );

  const onClickAcceptCredit = useCallback(() => {
    return isMe
      ? propsOnClickAcceptCredit
        ? propsOnClickAcceptCredit()
        : history.push(
            consts.ACTIVITIES().path + '/' + props.activity.id + '#claim'
          )
      : null;
  }, [history, isMe, props.activity.id, propsOnClickAcceptCredit]);

  const shouldHideContribution = useMemo(
    () =>
      props.hideContributions || !hasFocus || !(focalContributions?.length > 0),
    [focalContributions?.length, hasFocus, props.hideContributions]
  );

  const contributionsToShow = useMemo(() => {
    if (showAllContributions || !focalContributions) {
      // sort so focal contributions come last; this ensures that clicking
      // "show more" loads above instead of below (the latter would be jarring)
      return focalContributions
        ? props.activity.contributions
            .filter(
              (c) =>
                focalContributions &&
                focalContributions.findIndex((c2) => c.id === c2.id) === -1
            )
            .concat(focalContributions)
        : props.activity.contributions;
    } else {
      return focalContributions;
    }
  }, [props.activity.contributions, showAllContributions, focalContributions]);

  const isFocalContribution = useCallback(
    (contribution) => {
      if (props.focalPerson) {
        return contributionMatchesPerson(contribution, props.focalPerson);
      } else if (props.focalSkill) {
        return contributionMatchesSkill(contribution, props.focalSkill);
      } else if (props.focalCredential) {
        return contributionMatchesCredential(
          contribution,
          props.focalCredential
        );
      } else if (props.focalContributions) {
        return (
          // match if contribution id matches
          props.focalContributions &&
          props.focalContributions.findIndex(
            (c) => c.id?.toString() === contribution.id?.toString()
          ) !== -1
        );
      } else {
        return false;
      }
    },
    [
      props.focalPerson,
      props.focalSkill,
      props.focalCredential,
      props.focalContributions,
    ]
  );

  const myContribution = useMemo(() => {
    return props.focalPerson
      ? props.activity?.contributions?.find(isFocalContribution)
      : null;
  }, [isFocalContribution, props.activity?.contributions, props.focalPerson]);

  const propsIgnoreContribution = props.ignoreContribution;
  const propsUnignoreContribution = props.unignoreContribution;
  const toggleVisibility = useCallback(() => {
    if (myContribution.ignored_at) {
      propsUnignoreContribution(myContribution, props.visibilityToggleCallback);
    } else {
      propsIgnoreContribution(myContribution, props.visibilityToggleCallback);
    }
  }, [
    myContribution,
    props.visibilityToggleCallback,
    propsIgnoreContribution,
    propsUnignoreContribution,
  ]);

  const hasSoleContributor = useMemo(
    () =>
      props.activity?.contributions?.length === 1 &&
      props.activity.contributions[0].is_highlight,
    [props.activity]
  );

  const description = useMemo(
    () => (
      <>
        {!hasSoleContributor && !props.isCompressedView && isUnconfirmed && (
          <>
            <span
              // @ts-expect-error
              ref={unconfirmedRef}
              className="badge rounded-pill bg-warning my-0 me-2"
              role={isMe ? 'button' : undefined}
              style={{ position: 'relative', top: -1 }}
              onClick={onClickAcceptCredit}
            >
              <FormattedMessage
                id="app.activity.activity_card.description.unconfirmed.badge"
                defaultMessage="Unconfirmed"
              />
            </span>
            <UncontrolledPopover
              delay={50}
              trigger={'hover'}
              placement="bottom"
              // @ts-expect-error
              target={unconfirmedRef}
            >
              {isMe && (
                <>
                  <FormattedMessage
                    id="app.activity.activity_card.description.unconfirmed.badge.popover.me"
                    defaultMessage="You have not yet accepted credit. {acceptCreditButton}"
                    values={{
                      acceptCreditButton: (
                        <span
                          className="text-primary"
                          role="button"
                          onClick={onClickAcceptCredit}
                        >
                          <FormattedMessage
                            id="app.activity.activity_card.description.unconfirmed.badge.popover.me.accept_credit"
                            defaultMessage="Accept credit"
                          />
                        </span>
                      ),
                    }}
                  />
                </>
              )}
              {!isMe && person && (
                <>
                  <FormattedMessage
                    id="app.activity.activity_card.description.unconfirmed.badge.popover.not_me.accept_credit"
                    defaultMessage="{personName} has not yet accepted credit for this contribution."
                    values={{
                      personName: person?.given_name,
                    }}
                  />
                </>
              )}
            </UncontrolledPopover>
          </>
        )}
        {!props.hideDate &&
          getActivityDateSubtitle(props.activity, true, locale)}
        {
          <>
            {' '}
            <span
              id={'visibility-activity-' + props.activity.id}
              style={{ position: 'relative', top: '1px' }}
              className={'ms-1 ' + visibility.icon}
            ></span>
            <UncontrolledPopover
              placement="top"
              trigger="hover"
              target={'visibility-activity-' + props.activity.id}
            >
              {visibility.name}
            </UncontrolledPopover>
          </>
        }
        {isMe && myContribution && props.onClickAcceptCredit && (
          <span className="display-inline-on-list-group-item-hover">
            {' '}
            <FormattedMessage
              id="util.list_full_stop"
              defaultMessage="·"
            />{' '}
            <span
              className="text-primary"
              onClick={props.onClickAcceptCredit}
              role="button"
            >
              <FormattedMessage
                id="app.activity.activity_card.description.unconfirmed.badge.popover.me.edit_contribution.button.text"
                defaultMessage="Edit my contribution"
              />
            </span>
          </span>
        )}
        {props.showGiveOrRequestFeedbackButton && (
          <span className="display-inline-on-list-group-item-hover">
            {' '}
            <FormattedMessage
              id="util.list_full_stop"
              defaultMessage="·"
            />{' '}
            <ModalFeedbackEditorButton
              hideButton
              isOpen={showGiveOrRequestFeedbackModal}
              feedback={{
                type: isMe
                  ? FEEDBACK_TYPE_REQUEST(formatMessage)
                  : FEEDBACK_TYPE_ACTIONABLE(formatMessage),
                subject_people: [props.focalPerson],
                activities: [props.activity],
              }}
            />
            <span
              className="text-primary"
              onClick={toggleGiveOrRequestFeedback}
              role="button"
            >
              {isMe ? (
                <FormattedMessage
                  id="app.activity.activity_card.description.unconfirmed.badge.popover.me.request_feedback.button.text"
                  defaultMessage="Request feedback"
                />
              ) : (
                <FormattedMessage
                  id="app.activity.activity_card.description.unconfirmed.badge.popover.me.give_feedback.button.text"
                  defaultMessage="Give feedback"
                />
              )}
            </span>
          </span>
        )}
        {isMe && myContribution && props.visibilityToggleCallback && (
          <span className="display-inline-on-list-group-item-hover">
            {' '}
            <FormattedMessage
              id="util.list_full_stop"
              defaultMessage="·"
            />{' '}
            <span
              className="text-primary"
              onClick={toggleVisibility}
              role="button"
            >
              <FormattedMessage
                id="app.views.activities.activity_card.show_hide_action_type"
                defaultMessage="{action, select, show {Show in} other {Hide from}} {type, select, list {list} other {resume}}"
                values={{
                  action: myContribution.ignored_at ? 'show' : 'hide',
                  type: props.isEditablePerfResumeMode ? 'list' : 'resume',
                }}
              />
            </span>
          </span>
        )}
        {myContribution?.description && (
          <div className="text-dark mt-3">
            <RichTextViewer
              model={myContribution.description}
              expanded={true}
            />
          </div>
        )}
      </>
    ),
    [
      hasSoleContributor,
      props.isCompressedView,
      props.hideDate,
      props.activity,
      props.onClickAcceptCredit,
      props.showGiveOrRequestFeedbackButton,
      props.focalPerson,
      props.visibilityToggleCallback,
      props.isEditablePerfResumeMode,
      isUnconfirmed,
      isMe,
      onClickAcceptCredit,
      person,
      myContribution,
      showGiveOrRequestFeedbackModal,
      toggleGiveOrRequestFeedback,
      toggleVisibility,
      locale,
      formatMessage,
      visibility,
    ]
  );

  const images = useMemo(
    () => getImagesFromHtmlDescription(props.activity.description),
    [props.activity.description]
  );

  const lockedMessage = useMemo(
    () =>
      props.isLocked
        ? intl.formatMessage({
            id: 'app.activity.activity_card.object.locked.text',
            defaultMessage:
              'Create an activity to see what others are sharing.',
          })
        : undefined,
    [props.isLocked, intl]
  );

  const showCreateActivityDialog = useCallback(() => {
    if (propsOpenCreateActivityDialog) {
      propsOpenCreateActivityDialog();
    }
  }, [propsOpenCreateActivityDialog]);
  const contributionFeedbackList = useMemo(() => {
    return myContribution?.contribution_feedback;
  }, [myContribution?.contribution_feedback]);

  const feedbackList = useMemo(() => {
    return props.focalPerson ? props.activity.feedback : null;
  }, [props.activity.feedback, props.focalPerson]);

  const emptyStatePromptText = intl.formatMessage({
    id: 'app.activity.activity_card.emoji_bar.empty_state_prompt_text',
    defaultMessage: 'Be the first to react!',
  });

  const headerOptions = useMemo(
    () =>
      props.hideEmojis ? undefined : (
        <EmojiBar
          showIconsOnly={true}
          isSmall={false}
          className="mb-0"
          objectId={props.activity?.id?.toString()}
          contentType="activity"
          reactions={props.activity.reactions}
          emptyStatePromptText={
            props.showPrompt ? emptyStatePromptText : undefined
          }
        />
      ),
    [
      props.activity?.id,
      props.activity.reactions,
      props.hideEmojis,
      props.showPrompt,
      emptyStatePromptText,
    ]
  );

  const isExternalUrl = useMemo(
    () => props.isEditablePerfResumeMode || props.isUneditablePerfResumeMode,
    [props.isEditablePerfResumeMode, props.isUneditablePerfResumeMode]
  );

  const shouldLink = !isDemoOrPreviewMode && !!props.activity.id;

  return (
    <ObjectCard
      role={props.role}
      style={props.style}
      insertBefore={
        props.insertBefore
          ? (isOpen, toggle, onClosed) =>
              props.insertBefore(props.activity, isOpen, toggle, onClosed)
          : undefined
      }
      onClickIcon={!props.insertBefore && props.onClickAcceptCredit}
      headerClassName={
        props.isCompressedView ? 'small mb-0 fw-normal' : undefined
      }
      headerStyle={props.isCompressedView ? { lineHeight: '1.5' } : undefined}
      className={props.className}
      lockedMessage={lockedMessage}
      lockedMessageOnClick={showCreateActivityDialog}
      icon={
        props.hideIcon ? undefined : getActivityType(props.activity, intl)?.icon
      }
      iconTitle={
        props.hideIcon
          ? undefined
          : getActivityType(props.activity, intl)?.heading
      }
      name={activityTitle}
      formatNameForQuery={formatActivityTitle}
      stringNameForQueries={props.activity.name}
      description={description}
      headerOptions={headerOptions}
      url={
        shouldLink
          ? consts.ACTIVITIES().path + '/' + props.activity.id
          : undefined
      }
      isExternalUrl={isExternalUrl}
      query={props.query}
      size={props.size}
      inDropdown={props.inDropdown}
      isValid={props.isValid}
      bodyOnly={props.bodyOnly}
      aside={
        props.hidePeople ? undefined : (
          <AvatarGroup
            isExternalUrl={isExternalUrl}
            people={contributors}
            maxFaces={props.maxFaces}
            size="xs"
            className="py-2"
          />
        )
      }
      // @ts-expect-error
      secondAside={props.secondAside}
      headerChildren={
        props.showFeedback &&
        (contributionFeedbackList?.length > 0 || feedbackList?.length > 0) && (
          <div className="ms-n3 mt-3">
            {contributionFeedbackList?.map((cf, cfIndex) => {
              const feedbackAuthor = cf.author_person
                ? cf.author_person
                : getUnattributedPerson(intl.formatMessage, cfIndex, cf.type);

              return (
                <Row className="mb-3" key={cfIndex}>
                  <Col>
                    <Row className="flex-nowrap">
                      <Col className="col-auto">
                        <Avatar person={feedbackAuthor} size="sm" />
                      </Col>
                      <Col className="ms-n3">
                        <div className="comment-body d-block px-3 py-2 small">
                          <Row>
                            <Col>
                              {getAttributedOrUnattributedPersonHeading(
                                intl.formatMessage,
                                feedbackAuthor,
                                cfIndex,
                                cf.type,
                                false
                              )}
                              <Row className="my-2">
                                {[
                                  cf.positive_comments,
                                  cf.negative_comments,
                                ].map((feedbackComment, commentIndex) => (
                                  <Col
                                    className={
                                      'col-6' +
                                      (commentIndex === 1
                                        ? ' border-start'
                                        : '')
                                    }
                                    key={cfIndex + '-' + commentIndex}
                                  >
                                    <Row className="mb-2">
                                      <Col className="col-auto">
                                        <span
                                          className={
                                            'fe me-2 ' +
                                            (commentIndex === 0
                                              ? 'fe-chevrons-up text-success'
                                              : 'fe-chevrons-down text-danger')
                                          }
                                        ></span>
                                      </Col>
                                      <Col className="ms-n4 text-dark">
                                        {feedbackComment}
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
            {feedbackList?.map((f, fIndex) => {
              const feedbackAuthor = f.author_person;

              return (
                <Row key={fIndex}>
                  <Col>
                    <Row className="flex-nowrap">
                      <Col className="col-auto">
                        <Avatar person={feedbackAuthor} size="sm" />
                      </Col>
                      <Col className="ms-n3">
                        <FeedbackCard
                          feedback={f}
                          // @ts-expect-error
                          bodyOnly={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </div>
        )
      }
      addOrRemoveHighlight={() => props.addOrRemoveHighlight(props.activity.id)}
      isHighlight={props.isHighlight}
      activeHighlights={props.activeHighlights}
    >
      <>
        {
          // @ts-expect-error
          !props.hideImages && images?.length > 0 && (
            <>
              <hr />
              <Row>
                <Col>
                  <ActivityGallery
                    // @ts-expect-error
                    images={images}
                  />
                </Col>
              </Row>
            </>
          )
        }
        {!props.hideDescription &&
          // @ts-expect-error
          !(images?.length > 0) &&
          props.activity.description && (
            <>
              <hr />
              <Row>
                <Col>
                  <RichTextViewer model={props.activity.description} />
                </Col>
              </Row>
            </>
          )}
        {!shouldHideContribution && hasFocus && (
          <>
            <hr />
            <div>
              {!showAllContributions &&
                props.activity.contributions?.length > 0 &&
                props.activity.contributions.length >
                  contributionsToShow?.length && (
                  <p
                    className="text-muted mb-3"
                    role="button"
                    onClick={() => setShowAllContributions(true)}
                  >
                    <FormattedMessage
                      id="app.activity.activity_card.description.contributions.button.text"
                      defaultMessage="View {activityContributions} more {activityContributions, plural, one {contribution} other {contributions}}"
                      values={{
                        activityContributions:
                          props.activity.contributions.length - 1,
                      }}
                    />
                  </p>
                )}
              <ul className="list-group-flush mb-n3 list-group">
                {contributionsToShow.map((c, contributionIndex) => {
                  const isFocus = isFocalContribution(c);
                  const contributorPerson = getContributionPerson(c);
                  const isMyContribution = peopleIdsAreEqual(
                    contributorPerson?.id,
                    props.meId
                  );

                  return (
                    (showAllContributions || !hasFocus || isFocus) && (
                      <li
                        key={contributionIndex}
                        className="list-group-item border-0 p-0"
                      >
                        <ContributionCard
                          activity={props.activity}
                          contributionIndex={
                            props.activity?.contributions &&
                            props.activity.contributions.findIndex(
                              (c2) => c.id === c2.id
                            )
                          }
                          contributionsWithRecommendations={
                            props.activity.contributions
                          }
                          callback={callback}
                          onSubmitActivity={(activity) =>
                            activity ? props.setActivity(activity) : undefined
                          }
                          onDeleteContribution={
                            isMyContribution ? onDeleteContribution : undefined
                          }
                        />
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </>
        )}
      </>
    </ObjectCard>
  );
};

ActivityCard.defaultProps = {
  bodyOnly: false,
  inline: false,
  // Makes user see blurred out Activity.
  // Originally used to block brand new users who haven't engaged with the app
  isLocked: false,
  showPrompt: false,
  hideEmojis: false,
  hideImages: false,
  hideDescription: false,
  hideContributions: false,
  addOrRemoveHighlight: false,
  isHighlight: false,
  activeHighlights: false,
};

ActivityCard.propTypes = {
  activity: PropTypes.object,
  setActivity: PropTypes.func,
  meId: PropTypes.number.isRequired,
  loadTasks: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  openCreateActivityDialog: PropTypes.func,
  inDropdown: PropTypes.bool,
  isValid: PropTypes.bool,
  query: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  bodyOnly: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  focalPerson: PropTypes.object,
  focalSkill: PropTypes.object,
  focalCredential: PropTypes.object,
  focalContributions: PropTypes.arrayOf(PropTypes.object),
  showEmptyContributionsWithoutComments: PropTypes.bool,
  showPrompt: PropTypes.bool,
  hideEmojis: PropTypes.bool,
  maxFaces: PropTypes.number,
  isResumeView: PropTypes.bool,
  isCompressedView: PropTypes.bool,
  hideIcon: PropTypes.bool,
  hidePeople: PropTypes.bool,
  hideDate: PropTypes.bool,
  isEditablePerfResumeMode: PropTypes.bool,
  isUneditablePerfResumeMode: PropTypes.bool,
  onClickAcceptCredit: PropTypes.func,
  showFeedback: PropTypes.bool,
  currentProxyPerson: PropTypes.object,
  hideImages: PropTypes.bool,
  hideContributions: PropTypes.bool,
  hideDescription: PropTypes.bool,
  visibilityToggleCallback: PropTypes.func,
  showGiveOrRequestFeedbackButton: PropTypes.bool,
  addOrRemoveHighlight: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isHighlight: PropTypes.bool,
  activeHighlights: PropTypes.bool,
  isDemoOrPreviewMode: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { me, currentProxyPerson } = state;

  return {
    meId: me?.id,
    currentProxyPerson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTasks: (userSub, proxy) => dispatch(loadTasks(userSub, proxy)),
    ignoreContribution: (contribution, callback) =>
      dispatch(ignoreContribution(contribution, callback)),
    unignoreContribution: (contribution, callback) =>
      dispatch(unignoreContribution(contribution, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ActivityCard));
