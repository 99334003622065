import { Button, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useState } from 'react';

import ConfirmationDialogModalMultiAction from '../Modals/ConfirmationDialogModalMultiAction';

type Props = {
  onDiscardDraft: () => void;
  className?: string;
  disabled?: boolean;
};

const AutoSaveInfoBox: FC<Props> = ({
  onDiscardDraft,
  className = '',
  disabled = false,
}) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleOnCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleOnConfirm = useCallback(() => {
    handleOnCloseModal();
    onDiscardDraft();
  }, [handleOnCloseModal, onDiscardDraft]);

  return (
    <div
      className={
        'd-inline-block align-middle' + (className ? ' ' + className : '')
      }
    >
      <div className="d-inline-block small text-muted">
        <i
          className="fe fe-check me-1"
          style={{ position: 'relative', top: '1px' }}
        />
        <FormattedMessage
          id="app.views.widgets.forms.auto_save_info_box.draft_autosaved"
          defaultMessage="Draft auto-saved."
        />
      </div>
      <Button
        id="autosave-discard-button"
        className="ps-1 btn-sm"
        color="link"
        role="button"
        onClick={handleClick}
        disabled={disabled}
      >
        <FormattedMessage
          id="app.views.widgets.forms.validated_form.auto_save_info_box.discard.text"
          defaultMessage="Discard draft"
        />
      </Button>
      <UncontrolledPopover
        target="autosave-discard-button"
        placement="bottom"
        trigger="hover"
      >
        <FormattedMessage
          id="app.views.widgets.forms.validated_form.auto_save_info_box.discard.hover.popover"
          defaultMessage="You have made recent changes which have not been submitted. Click to discard these changes and revert to the previously submitted version."
        />
      </UncontrolledPopover>
      <ConfirmationDialogModalMultiAction
        className=""
        title={formatMessage({
          id: 'app.views.widgets.forms.validated_form.auto_save_info_box.discard.modal.title',
          defaultMessage: 'Discard draft',
        })}
        description={formatMessage({
          id: 'app.views.widgets.forms.validated_form.auto_save_info_box.discard.modal.description',
          defaultMessage:
            'Are you sure you want to discard your draft changes? This will revert to the previously submitted version.',
        })}
        toggle={() => setIsModalOpen(!isModalOpen)}
        isOpen={isModalOpen}
        actions={[
          {
            text: formatMessage({
              id: 'app.views.widgets.forms.validated_form.auto_save_info_box.discard.modal.button.cancel.text',
              defaultMessage: 'Cancel',
            }),
            color: 'light',
            onClick: handleOnCloseModal,
            className: 'col-md-4',
            outline: false,
          },
          {
            text: formatMessage({
              id: 'app.views.widgets.forms.validated_form.auto_save_info_box.discard.modal.button.discard.text',
              defaultMessage: 'Discard',
            }),
            color: 'primary',
            onClick: handleOnConfirm,
            className: 'col-md-4',
            outline: false,
          },
        ]}
        onClosed={handleOnCloseModal}
      />
    </div>
  );
};

export default AutoSaveInfoBox;
