import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import SimplePage from 'views/Layout/Pages/SimplePage';

const PageUserNotProvisioned: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <SimplePage
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page_user_not_provisioned.title.user_not_provisioned',
        defaultMessage: "You don't have permission to use Confirm.",
      })}
    >
      <p>
        <FormattedMessage
          id="app.views.layout.pages.errors.page_user_not_provisioned.no_longer_access"
          defaultMessage="It looks like your account hasn't been provisioned yet. Please reach out to your IT manager to get access."
        />
      </p>
    </SimplePage>
  );
};

export default React.memo(PageUserNotProvisioned);
