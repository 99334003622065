import { type IntlShape } from 'react-intl';
import { INPUT_TYPES } from '../../../Inputs/ValidatedInputTypes';
import { Campaign } from '../../../../../types';
import { hasPhase } from '../../../../../utils/models/Campaign';
import {
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_REPORTING,
  PHASE_TYPE_SELF,
  onaIsEnabled,
  peer360Enabled,
  upwardFeedbackEnabled,
} from '../../../../../utils/models/Performance';

export type HeaderDescriptor = {
  key: string;
  label: string;
};

export type GroupField =
  | 'detailed_metadata'
  | 'participation_and_completion'
  | 'ona'
  | 'ona_details'
  | 'self_evaluation'
  | 'peer_feedback'
  | 'upward'
  | 'manager_evaluation_ratings_calibration'
  | 'manager_conversations';

// note: we don't include management_tier in this list because it's expensive
// to calculate, only used in filters, and it's likely not that useful to the
// end user otherwise (or at least worth the calculation)
const PERSON_METADATA_FIELDS = [
  'manager_full_name',
  'manager_email',
  'manager_title',
  'believed_manager_email',
  'believed_manager_full_name',
  'previous_managers_text',
  'department',
  'department_id',
  'level_id',
  'level',
  'function',
  'function_id',
  'cost_center',
  'cost_center_id',
  'country',
  'business_unit',
  'business_unit_id',
  'employee_number',
  'location',
  'original_hire_date',
  'latest_hire_date',
  'position_effective_date',
  'position_start_date',
  'manager_effective_date',
  'department_id',
  'classification',
  'status',
  'hrbp',
  'leader',
  'formers_count',
];

const ONA_DETAILS_REGEX =
  /^(influence|goldstars|headsups)_.*(below_chain_of_command|same_group|other)$/;

const SHOW_WHEN_ALWAYS = () => true;

const HEADER_CLASSIFICATIONS = (
  formatMessage
): Record<
  GroupField,
  {
    classifier: (header: HeaderDescriptor) => boolean;
    showWhen: (campaign: Campaign) => boolean;
    label: string;
  }
> => ({
  detailed_metadata: {
    showWhen: SHOW_WHEN_ALWAYS,
    classifier: (header) => PERSON_METADATA_FIELDS.includes(header.key),
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.detailed_metadata',
      defaultMessage: 'Detailed metadata',
    }),
  },
  participation_and_completion: {
    showWhen: SHOW_WHEN_ALWAYS,
    classifier: (header) =>
      header.key === 'released_at' ||
      header.key.startsWith('perf_progress_') ||
      header.key.startsWith('team_perf_'),
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.participation_and_completion',
      defaultMessage: 'Participation and completion data',
    }),
  },
  ona: {
    showWhen: (campaign) =>
      hasPhase(campaign, PHASE_TYPE_SELF) && onaIsEnabled(campaign),
    classifier: (header) =>
      (header.key == 'formers_comments' ||
        header.key == 'formers_skills' ||
        header.key == 'formers_count' ||
        header.key.startsWith('influence_') ||
        header.key.startsWith('goldstars_') ||
        header.key.startsWith('headsups_')) &&
      !ONA_DETAILS_REGEX.test(header.key),
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.ona',
      defaultMessage: 'ONA data',
    }),
  },
  ona_details: {
    showWhen: (campaign) =>
      hasPhase(campaign, PHASE_TYPE_SELF) && onaIsEnabled(campaign),
    classifier: (header) => ONA_DETAILS_REGEX.test(header.key),
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.ona_details',
      defaultMessage: 'Detailed ONA data breakdown',
    }),
  },
  self_evaluation: {
    showWhen: (campaign) => hasPhase(campaign, PHASE_TYPE_SELF),
    classifier: (header) => header.key.startsWith('_self_phase'),
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.self_evaluation',
      defaultMessage: 'Self-evaluation data',
    }),
  },
  peer_feedback: {
    showWhen: (campaign) =>
      hasPhase(campaign, PHASE_TYPE_OTHERS) && peer360Enabled(campaign),
    classifier: (header) =>
      header.key.startsWith('_peer_feedback_') ||
      header.key === 'incoming_peers' ||
      header.key === 'outgoing_peers',
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.peer_360s',
      defaultMessage: 'Peer Feedback',
    }),
  },
  upward: {
    showWhen: (campaign) =>
      hasPhase(campaign, PHASE_TYPE_OTHERS) && upwardFeedbackEnabled(campaign),
    classifier: (header) =>
      header.key.startsWith('_upward_feedback') ||
      header.key === 'upward_feedback_score',
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.upward',
      defaultMessage: 'Upward feedback',
    }),
  },
  manager_evaluation_ratings_calibration: {
    showWhen: (campaign) =>
      hasPhase(campaign, PHASE_TYPE_CALIBRATION) ||
      hasPhase(campaign, PHASE_TYPE_EVALUATION),
    classifier: (header) =>
      header.key.startsWith('_evaluation_phase') ||
      header.key.startsWith('draft_rating') ||
      header.key === 'recommend_for_promotion' ||
      header.key === 'promotion_comments' ||
      header.key === 'final_rating' ||
      header.key === 'final_rating_comments' ||
      header.key === 'final_rating_provided_by_person_full_name' ||
      header.key === 'calibration_status' ||
      header.key === 'original_calibration_flags' ||
      header.key === 'draft_to_final_delta' ||
      header.key === 'previous_to_final_delta',
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.manager_evaluation_ratings_calibration',
      defaultMessage: 'Manager evaluation, ratings, and calibration',
    }),
  },
  manager_conversations: {
    showWhen: (campaign) => hasPhase(campaign, PHASE_TYPE_REPORTING),
    classifier: (header) => header.key.startsWith('conversation_'),
    label: formatMessage({
      id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.groups.manager_conversations',
      defaultMessage: 'Manager conversations',
    }),
  },
});

export const computeAvailableData = ({
  selectedGroupFields,
  headers,
  intl,
}: {
  selectedGroupFields: GroupField[];
  headers: HeaderDescriptor[];
  intl: IntlShape;
}): {
  filteredHeaders: HeaderDescriptor[];
} => {
  const { formatMessage } = intl;
  let filteredHeaders = headers;

  Object.entries(HEADER_CLASSIFICATIONS(formatMessage)).forEach(
    ([groupField, { classifier }]) => {
      if (!selectedGroupFields.includes(groupField as GroupField)) {
        filteredHeaders = filteredHeaders.filter(
          (header) => !classifier(header)
        );
      }
    }
  );

  return { filteredHeaders };
};

const showIf = (
  condition: boolean,
  value: { key: GroupField; label: string }
): { key: GroupField; label: string }[] => {
  return condition ? [value] : [];
};

type CSVSelectionOptions = { key: GroupField; label: string };

const generateOptions = (
  campaign: Campaign,
  headers: HeaderDescriptor[],
  intl
): CSVSelectionOptions[] => {
  const { formatMessage } = intl;

  const options: CSVSelectionOptions[] = Object.entries(
    HEADER_CLASSIFICATIONS(formatMessage)
  ).reduce(
    (
      acc: CSVSelectionOptions[],
      [groupField, { showWhen, classifier, label }]
    ) => {
      return [
        ...acc,
        ...showIf(
          !!headers.find((header) => classifier(header)) && showWhen(campaign),
          {
            key: groupField,
            label,
          } as CSVSelectionOptions
        ),
      ];
    },
    []
  );

  return options;
};

export const generateFields = (
  campaign: Campaign,
  headers: HeaderDescriptor[],
  intl: IntlShape
) => {
  const { formatMessage } = intl;
  const options: { key: GroupField; label: string }[] = generateOptions(
    campaign,
    headers,
    intl
  );
  return [
    {
      type: INPUT_TYPES.CHECKBOX_MULTI,
      name: 'group_fields',
      label: formatMessage({
        id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.group_fields.label',
        defaultMessage: 'Group fields',
      }),
      helperText: formatMessage({
        id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.group_fields.helper_text',
        defaultMessage:
          'If you select a group, all fields within that group will be exported.',
      }),
      options,
    },
  ];
};
