import React, { FC, MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

type Props = {
  className?: string;
  onClick: MouseEventHandler<HTMLSpanElement>;
};

const BackButtonLink: FC<Props> = ({ className, onClick }) => {
  return (
    <Button
      className={'p-0 btn-link' + (className ? ' ' + className : '')}
      type="button"
      onClick={onClick}
    >
      <FormattedMessage
        id="app.views.widgets.buttons.back_button_link.go_back"
        defaultMessage="Go back"
      />
    </Button>
  );
};

export default BackButtonLink;
