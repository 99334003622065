import { Person } from 'types';

export interface TeamDashboardPersonFilterFields {
  management_tier: number;
}

// go through dictionary of people ids to people manager ids
// as means to get the management tier of the given person
// (NOTE: these are all person object ids, not user ids)
// ALSO: WE ARE NOT USING GETEXTERNALID TO CHECK FOR EQUIVALENCE,
// SO WE ARE ASSUMING THAT THE PEOPLE IDS IN SURVEY RESPONSES
// MATCH THOSE IN RELATIONSHIP OBJECTS (which is a reasonable
// assumption given that both pieces of data come from the same
// Dataset object associated to the given campaign)
export const getManagementTier = (
  personId: number,
  peopleManagerIdMap: Record<number, number>
) => {
  let tier = 1;
  let currentPersonId = personId;

  const seenPeopleIds = new Set([currentPersonId]);
  while (currentPersonId in peopleManagerIdMap) {
    tier++;
    currentPersonId = peopleManagerIdMap[currentPersonId];

    // avoid loops for reporting cycles (e.g. founders reporting to each other)
    if (seenPeopleIds.has(currentPersonId)) {
      break;
    }
    seenPeopleIds.add(currentPersonId);
  }

  return tier;
};

export function calculateFilterOnlyDataHashMap(
  unsortedPeople: Array<Person>,
  peopleManagerIdMap: Record<number, number>
): Record<string, TeamDashboardPersonFilterFields> {
  const hashmap = {};

  for (const person of unsortedPeople) {
    hashmap[person.id] = {
      // TODO: get management tier based on dataset and NOT campaign
      // when personDatasourceCampaign is not set
      management_tier: getManagementTier(person.id, peopleManagerIdMap),
    };
  }

  return hashmap;
}
