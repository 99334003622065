import React, { CSSProperties, FC, PropsWithChildren } from 'react';

interface Props {
  className?: string;
  spinnerStyle?: CSSProperties;
}

const DEFAULT_SPINNER_STYLE: CSSProperties = {
  width: '0.8rem',
  height: '0.8rem',
  position: 'relative',
  top: '-2px',
};

const Spinner: FC<PropsWithChildren<Props>> = ({
  children,
  className = 'small',
  spinnerStyle = {},
}) => {
  return (
    <span className={className}>
      <i
        className="spinner-border"
        style={{ ...DEFAULT_SPINNER_STYLE, ...spinnerStyle }}
      />
      {!!children && <span className="ms-2">{children}</span>}
    </span>
  );
};

export default Spinner;
