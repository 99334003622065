import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import PersonFeedbackList, {
  PEOPLE_GROUPINGS,
} from '../Person/PersonFeedbackList';
import React, { FC, useCallback, useEffect, useState } from 'react';

import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

const FeedbackDirectory: FC<Props> = (props) => {
  const [currentPeopleGrouping, setCurrentPeopleGrouping] = useState(
    PEOPLE_GROUPINGS.DIRECT_REPORTS
  );
  const [feedbackList, setFeedbackList] = useState(undefined);
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState(null);
  const { user } = useAuth0();
  const userSub = user?.sub;

  // @ts-expect-error
  const person = props.me;

  useEffect(
    function queryFeedback() {
      const params = {
        person: person.id || undefined,
        // @ts-expect-error
        organization: props.currentOrganization?.id,
        // @ts-expect-error
        proxy: props.currentProxyPerson
          ? // @ts-expect-error
            props.currentProxyPerson.email
          : undefined,
      };

      if (currentPeopleGrouping === PEOPLE_GROUPINGS.DIRECT_REPORTS) {
        // @ts-expect-error
        params.filter = 'direct_reports';
      } else if (currentPeopleGrouping === PEOPLE_GROUPINGS.FULL_TEAM) {
        // @ts-expect-error
        params.filter = 'full_team';
      }

      setFeedbackList(undefined);
      ElasticsearchAPI.getFeedbackFeed(
        null, // this is too much data, don't cache it
        // @ts-expect-error
        props.currentProxyPerson,
        // @ts-expect-error
        props.currentOrganization?.id,
        params,
        (newFeedbackList) => {
          setFeedbackList(newFeedbackList);
        },
        (message) => {
          setFeedbackErrorMessage(message);
        }
      );
    },
    [
      currentPeopleGrouping,
      person.id,
      // @ts-expect-error
      props.currentOrganization?.id,
      // @ts-expect-error
      props.currentProxyPerson,
      userSub,
    ]
  );

  const onClickLeftNav = useCallback(
    (newGrouping) => {
      if (newGrouping != currentPeopleGrouping) {
        setFeedbackList(undefined);
        setCurrentPeopleGrouping(newGrouping);
      }
    },
    [currentPeopleGrouping]
  );

  return (
    <Row className="justify-content-center">
      <Col className="col-12 col-md-2 pt-0 pt-md-6">
        <ListGroup className="list-group-flush">
          <ListGroupItem
            role="button"
            className={
              currentPeopleGrouping === PEOPLE_GROUPINGS.DIRECT_REPORTS
                ? 'fw-bold'
                : ''
            }
            onClick={() => onClickLeftNav(PEOPLE_GROUPINGS.DIRECT_REPORTS)}
          >
            <FormattedMessage
              id="app.views.feedback.feedback_directory.my_direct_reports_people"
              defaultMessage="My direct reports"
            />
          </ListGroupItem>
          <ListGroupItem
            role="button"
            className={
              currentPeopleGrouping === PEOPLE_GROUPINGS.FULL_TEAM
                ? 'fw-bold'
                : ''
            }
            onClick={() => onClickLeftNav(PEOPLE_GROUPINGS.FULL_TEAM)}
          >
            <FormattedMessage
              id="app.views.feedback.feedback_directory.my_full_team_people"
              defaultMessage="My full team"
            />
          </ListGroupItem>
          <ListGroupItem
            role="button"
            className={
              currentPeopleGrouping === PEOPLE_GROUPINGS.EVERYONE
                ? 'fw-bold'
                : ''
            }
            onClick={() => onClickLeftNav(PEOPLE_GROUPINGS.EVERYONE)}
          >
            <FormattedMessage
              id="app.views.feedback.feedback_directory.everyone"
              defaultMessage="Everyone"
            />
          </ListGroupItem>
          <ListGroupItem>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/profile/feedback"
            >
              <FormattedMessage
                id="app.views.person.person_profile.my_feedback"
                defaultMessage="My feedback"
              />{' '}
              <i className="fe fe-external-link" />
            </Link>
          </ListGroupItem>
        </ListGroup>
      </Col>
      <Col className="col-12 col-md-10">
        <PersonFeedbackList
          person={person}
          feedbackList={feedbackList}
          errorMessage={feedbackErrorMessage}
          showManagerOnlyPerformanceDetails={true}
          showGlobalFeedback={currentPeopleGrouping}
        />
      </Col>
    </Row>
  );
};

const FeedbackDirectory_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<typeof FeedbackDirectory_propTypes>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, me } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(FeedbackDirectory));
