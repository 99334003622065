import { Person, Relationship } from '../../../../types';
import { Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useContext, useMemo } from 'react';
import { compareRatingScales } from './TrajectoryUtils';

import { CycleDiffBadge } from '../../components/CycleDiffBadge';
import { ManagerComments } from './ManagerComments';
import PersonPerformanceContext from '../PersonPerformanceContext';
import { PrivateDataBadge } from '../../components/PrivateDataBadge';
import { getCampaignRatings } from '../../../../utils/models/Performance';

interface ManagerRatingCardProps {
  person?: {
    id: number;
    given_name: string;
  };
  isInReviewFlow: boolean;
  hideRatingFromDirectReport: boolean;
  showManagerOnlyPerformanceDetails: boolean;
  managerRelationshipsWithFeedback?: Relationship[];
  previousRatingDifference?: number;
  manager: Person;
}

const ManagerRatingCard: React.FC<ManagerRatingCardProps> = ({
  isInReviewFlow,
  hideRatingFromDirectReport,
  showManagerOnlyPerformanceDetails,
  managerRelationshipsWithFeedback,
  manager,
}) => {
  const { formatMessage } = useIntl();
  const personPerformanceContext = useContext(PersonPerformanceContext);

  const selectedCampaign = personPerformanceContext.selectedCampaignData;

  const previousCampaignWithRating =
    personPerformanceContext.previousCampaignDataWithRating;

  const latestRating = selectedCampaign?.rating;

  const latestRatingValue = latestRating?.value;

  const selectedCampaignRatingScale = useMemo(
    () =>
      getCampaignRatings(selectedCampaign?.campaign).sort(
        (a, b) => b.value - a.value
      ),
    [selectedCampaign]
  );

  const latestRatingDefinition = useMemo(
    () =>
      selectedCampaignRatingScale?.find((r) => r.value === latestRatingValue),
    [selectedCampaignRatingScale, latestRatingValue]
  );

  const latestRatingName = latestRatingDefinition?.name;

  const latestRatingDescription = useMemo(() => {
    const ratingDescription =
      latestRatingDefinition?.description ||
      formatMessage({
        id: 'app.views.person.person_performance.trajectory.summary.no_rating_description',
        defaultMessage:
          'Your manager has not provided you with a rating for this cycle.',
      });

    return ratingDescription;
  }, [formatMessage, latestRatingDefinition?.description]);

  const previousRatingDifference = useMemo(() => {
    if (!previousCampaignWithRating) return null;
    // if the person is not eligible in one of the two campaigns,
    // there is no point in calculating a difference
    if (
      !previousCampaignWithRating?.is_eligible_for_reporting ||
      !selectedCampaign?.is_eligible_for_reporting
    ) {
      return null;
    }

    // if the person didn't receive a rating, don't compute the difference
    // == on purpose here, I want to check null or undefined
    if (
      selectedCampaign?.rating?.value == null ||
      previousCampaignWithRating?.rating?.value == null
    ) {
      return null;
    }

    const previousCampaignRatingScale = getCampaignRatings(
      previousCampaignWithRating?.campaign
    ).sort((a, b) => b.value - a.value);

    if (
      !previousCampaignRatingScale ||
      !compareRatingScales(
        previousCampaignRatingScale,
        selectedCampaignRatingScale
      )
    )
      return null;

    const prevRating = previousCampaignWithRating?.rating?.value;
    return (latestRatingValue ?? 0) - prevRating;
  }, [
    latestRatingValue,
    previousCampaignWithRating,
    selectedCampaign,
    selectedCampaignRatingScale,
  ]);

  return (
    <Card className="rating-card">
      <CardBody>
        {/* latest-rating */}
        {!isInReviewFlow && (
          <div className="mb-5">
            <h6 className="text-secondary text-uppercase">
              <FormattedMessage
                id="app.views.person.person_performance.trajectory.trajectory_summary.manager_rating"
                defaultMessage="Manager rating"
              />{' '}
              {hideRatingFromDirectReport && <PrivateDataBadge />}
            </h6>
            <h2 className="mb-3">
              {latestRatingName || (
                <span className="fst-italic">
                  <FormattedMessage
                    id="app.views.person.person_performance.trajectory.trajectory_summary.no_rating_provided"
                    defaultMessage="No rating provided"
                  />
                </span>
              )}
              {!!showManagerOnlyPerformanceDetails &&
                personPerformanceContext.canDisplayDeltas && (
                  // @ts-expect-error
                  <CycleDiffBadge value={previousRatingDifference} />
                )}
            </h2>
          </div>
        )}
        {/* manager-comments */}
        {managerRelationshipsWithFeedback &&
        managerRelationshipsWithFeedback.length > 0 &&
        showManagerOnlyPerformanceDetails ? (
          <ManagerComments
            author={manager}
            comments={managerRelationshipsWithFeedback[0]?.rating_comments}
          />
        ) : (
          <>
            <h6 className="text-uppercase text-secondary">
              <FormattedMessage
                id="app.views.person.person_performance.trajectory.trajectory_summary.rating_description"
                defaultMessage="Rating description"
              />
            </h6>
            <p className="text-muted mb-0">{latestRatingDescription}</p>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ManagerRatingCard;
