import * as consts from '../../consts/consts';

import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Features } from '../../types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isSkillsTalentInventoryEnabled } from 'utils/util/features';

const getHTagBasedOnFrequency = (count) => {
  if (count > 5) {
    count = 5;
  }

  // h1, h2, h3, h4, or h5
  return 'h' + (6 - count);
};

const getTextColorBasedOnFrequency = (
  count: number,
  talentInventoryEnabledWithDataToShow: boolean = false
) => {
  const colors = {
    selfDeclaredOnlyCase: {
      1: '#12263f70',
      2: '#12263fb0',
      default: '#12263F',
    },
    talentInventory: {
      1: '#2c7be570',
      2: '#2c7be5b0',
      default: '#2c7be5',
    },
  };

  const lookupKey = talentInventoryEnabledWithDataToShow
    ? 'talentInventory'
    : 'selfDeclaredOnlyCase';

  switch (count) {
    case 1:
      return colors[lookupKey][1];
    case 2:
      return colors[lookupKey][2];
    default:
      return colors[lookupKey]['default'];
  }
};

type WordCloudWordProps = {
  // Upstream there is a spread of props,
  // this list here is a best guess.
  advisedPeople?: object[];
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  contributedPeople?: object[];
  contributions?: object[];
  count?: number;
  customDisplay?: any;
  declaredPeople?: object;
  energizedPeople?: object[];
  features: Features;
  feedback?: object;
  feedbackPeople?: object[];
  id: number;
  isExternalUrl?: boolean;
  maxWords?: number;
  noun: string | React.ReactNode;
  nounPlural: string | React.ReactNode;
  talentInventoryCount?: number;
  talentInventoryPeopleCount?: number;
  url?: string;
  word?: string;
  isDemoOrPreviewMode?: boolean;
};

const WordCloudWord: FC<WordCloudWordProps> = (props: WordCloudWordProps) => {
  const linkRef = useRef();
  const [ready, setReady] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const talentInventoryEnabledWithDataToShow: boolean = !!(
    isSkillsTalentInventoryEnabled(props.features) &&
    props.talentInventoryPeopleCount &&
    props.talentInventoryPeopleCount > 0
  );

  useEffect(() => {
    if (linkRef.current) {
      setReady(true);
    }
  }, []);

  const hTagClass = getHTagBasedOnFrequency(props.count);
  const textColor = getTextColorBasedOnFrequency(
    // @ts-expect-error
    props.count,
    talentInventoryEnabledWithDataToShow
  );

  const linkedWord =
    !props.isDemoOrPreviewMode && props.url ? (
      <Link
        target={props.isExternalUrl ? '_blank' : undefined}
        rel="noopener noreferrer"
        style={{ color: textColor }}
        to={props.url}
      >
        {props.word}
      </Link>
    ) : (
      <span style={{ color: textColor }}>{props.word}</span>
    );

  const showFeedbackPill = useMemo(
    // @ts-expect-error
    () => props.feedback?.length > 0,
    [props.feedback]
  );

  const count = useMemo(
    () => props?.customDisplay ?? props.count,
    [props?.customDisplay, props.count]
  );

  const talentInventoryPopupExtraText = useMemo(() => {
    if (!talentInventoryEnabledWithDataToShow) {
      return null;
    }

    return (
      <div className="mt-3">
        <FormattedMessage
          id="app.widgets.word_cloud.talent_inventory.count_description"
          defaultMessage="Recognized by {count, plural, one {# person} other {# people}} in continuous recognition or performance cycles {times, plural, one {# time} other {# times}}."
          values={{
            count: props.talentInventoryPeopleCount,
            times: props.talentInventoryCount,
          }}
        />
      </div>
    );
  }, [
    talentInventoryEnabledWithDataToShow,
    props.talentInventoryPeopleCount,
    props.talentInventoryCount,
  ]);

  return (
    // @ts-expect-error
    <li ref={linkRef} className={'list-inline-item tag-cloud-tag ' + hTagClass}>
      {showFeedbackPill && (
        <div
          className={'rounded-pill bg-primary-soft'}
          style={{ padding: '0.5rem' }}
        >
          <span className={props.className}>{linkedWord}</span>
          <span
            style={{ position: 'relative', top: '-2px' }}
            className={
              consts.ICONS.FEEDBACK + ' ms-2 align-middle text-primary'
            }
          ></span>
        </div>
      )}
      {!showFeedbackPill && (
        <span className={props.className}>{linkedWord}</span>
      )}
      {ready && (
        <Popover
          trigger="hover focus"
          placement="bottom"
          isOpen={popoverOpen}
          // @ts-expect-error
          target={linkRef.current}
          toggle={toggle}
        >
          <PopoverHeader>{props.word}</PopoverHeader>
          <PopoverBody>
            <div>
              {count === 1 && (
                <FormattedMessage
                  id="app.widgets.word_cloud.count_description_singular"
                  defaultMessage="1 relevant {noun}"
                  description="for a popover on a word cloud indicating how many times a word is mentioned when count is 1; noun variable is 'mention' most of the time but is sometimes 'person'"
                  values={{ noun: props.noun }}
                />
              )}
              {count !== 1 && (
                <FormattedMessage
                  id="app.widgets.word_cloud.count_description_plural"
                  defaultMessage="{count} relevant {noun_plural}"
                  description="for a popover on a word cloud indicating how many times a word is mentioned when count is not 1; noun_plural is 'mentions' most of the time but is sometimes be 'people'"
                  values={{
                    count: count,
                    noun_plural: props.nounPlural,
                  }}
                />
              )}
            </div>
            {props.children && <div>{props.children}</div>}
            {talentInventoryEnabledWithDataToShow && (
              <div>{talentInventoryPopupExtraText}</div>
            )}
          </PopoverBody>
        </Popover>
      )}
    </li>
  );
};

const mapStateToProps = (state) => {
  const { features } = state;
  return { features };
};

export default connect(mapStateToProps)(React.memo(WordCloudWord));
