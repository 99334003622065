import { Col, Row } from 'reactstrap';
import React, { FC, useEffect, useState } from 'react';
import {
  TalentMatrixCampaignModel,
  generateDefaultTalentMatrix,
  useTalentMatrixConfiguration,
} from '../TalentMatrix';

import DeleteModalButton from './DeleteModalButton';
import EditModalButton from './EditModalButton';
import { FormattedMessage } from 'react-intl';
import { useCampaignEditorPhase } from 'views/Administration/CampaignEditorPhaseContext';

interface Props {
  onChange: (value: { target: { value: TalentMatrixCampaignModel } }) => void;
  onDelete: () => void;
  value: TalentMatrixCampaignModel | undefined;
}

const TableItem: FC<Props> = ({ onChange, value, onDelete }) => {
  const campaignContext = useCampaignEditorPhase();
  const [triggerModelOpen, setTriggerModelOpen] = useState<boolean>(!value);

  const { status, data } = useTalentMatrixConfiguration({
    campaignId: campaignContext?.campaignId,
    disabled: !!value,
  });

  useEffect(() => {
    if (status === 'SUCCESS' && data && !value) {
      onChange({ target: { value: generateDefaultTalentMatrix(data) } });
      setTriggerModelOpen(true);
    }
  }, [status, data, value, onChange]);

  if (!value) {
    return (
      <div className="p-0 fw-normal">
        <div
          className="spinner-border me-2"
          style={{ width: '1rem', height: '1rem' }}
        />
        <FormattedMessage
          id="app.views.talent_matrix.talent_matrix_campaign_editor_table_item.generating_default_data"
          defaultMessage="Generating default talent matrix..."
        />
      </div>
    );
  }

  return (
    <Row className="ps-0 py-0 pe-3">
      <Col className="align-self-center pe-0">
        {`${value.y_axis_label} (${value.y_axis_metric_description}) vs ${value.x_axis_label} (${value.x_axis_metric_description})`}
      </Col>
      <Col className="col-auto pe-0">
        <EditModalButton
          isEditorOpen={triggerModelOpen}
          onChange={onChange}
          value={value}
        />
      </Col>
      <Col className="col-auto pe-0">
        <DeleteModalButton onDelete={onDelete} />
      </Col>
    </Row>
  );
};

export default React.memo(TableItem);
