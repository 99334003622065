import { Col, Fade, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';

import { MY_TEAM_OBJECTIVES } from '../../consts/consts';
import PersonalObjectives from '../Person/PersonalObjectives';
import PulseCheckSummary from '../PulseChecks/PulseCheckSummary';
import { connect } from 'react-redux';
import {
  objectivesInDashboardAreEnabled,
  pulseChecksAreEnabled,
} from 'utils/util/features';

const RightSidebarTwo = (props) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const showObjectives = objectivesInDashboardAreEnabled(props.features);
  const showPulseChecks = pulseChecksAreEnabled(props.features);

  const anySidebarsEnabled = showObjectives || showPulseChecks;

  const currentOrgId = props.currentOrganization?.id;

  useEffect(() => {
    /* DO NOTHING */
  }, [anySidebarsEnabled, currentOrgId]);

  const goToMyObjectives = useCallback(() => {
    history.push(MY_TEAM_OBJECTIVES(formatMessage).path);
  }, [history, formatMessage]);

  const companyObjectivesLink = props.settings?.company_objectives_link;

  if (!currentOrgId || !anySidebarsEnabled) {
    return <></>;
  }

  return (
    <Col className="col-12 col-xl-4 d-none d-md-block">
      <div>
        <Fade>
          {showPulseChecks && (
            <>
              <Row>
                <Col>
                  <h4 className="mb-4 text-muted">
                    <FormattedMessage
                      id="app.views.dashboard.right_sidebar.checkins"
                      defaultMessage="Check-ins"
                    />
                  </h4>
                </Col>
              </Row>
              <PulseCheckSummary
                hideTitle={true}
                isMe={true}
                person={props.me}
              />
            </>
          )}
          {showObjectives && (
            <>
              <Row>
                <Col>
                  <h4 className="mb-4 text-muted">
                    <Link to={MY_TEAM_OBJECTIVES(formatMessage).path}>
                      <FormattedMessage
                        id="app.views.dashboard.right_sidebar.current_objectives"
                        defaultMessage="Current objectives"
                      />
                    </Link>
                  </h4>
                </Col>
                {companyObjectivesLink && (
                  <Col className="ps-0 col-auto">
                    <h4
                      className="mb-4 text-muted fw-normal"
                      style={{ position: 'relative', top: '-1px' }}
                    >
                      <a
                        href={companyObjectivesLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="app.views.dashboard.right_sidebar.view_company_objectives"
                          defaultMessage="View company objectives"
                        />{' '}
                        <i className="fe fe-external-link" />
                      </a>
                    </h4>
                  </Col>
                )}
              </Row>
              <div
                onClick={goToMyObjectives}
                role="button"
                style={{ cursor: 'pointer' }}
                className="home-objective-list-view"
              >
                <PersonalObjectives
                  showTeamNav={false}
                  person={props.me}
                  isReadOnly={true}
                  hideDatePicker={true}
                  hideScoresAndWeight={true}
                  hideRelatedObjectives={true}
                  showMiniEmptyStateCallToAction={true}
                  shouldCacheObjectivesOnFrontend={true}
                />
              </div>
            </>
          )}
        </Fade>
      </div>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, me, currentProxyPerson, features, settings } =
    state;

  return {
    currentOrganization,
    me,
    currentProxyPerson,
    features,
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(RightSidebarTwo));
