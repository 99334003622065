import * as consts from '../../consts/consts';

import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { FormattedList, FormattedMessage } from 'react-intl';
import React, { FC, Fragment, useMemo, useState } from 'react';

import FeedbackCard from '../Feedback/FeedbackCard';
import { Link } from 'react-router-dom';
import TooltipList from './TooltipList';
import WordCloudWord from './WordCloudWord';
import { cloneDeep } from 'lodash';
import { getPersonField } from '../../utils/models/Takeaways';

export const getWordCloudWordDictionaries = (objects, pathPrefix) => {
  if (!objects || objects.length === 0) {
    return [];
  } else {
    // NOTE: objects have a "wordObject" and any number of associated source objects,
    // namely contribution, contributedPerson, or declaredPerson so far (more to be added in the future)
    // NOTE: contribution OR contributedPerson should be use depending on desired focus in popup
    // (not both)

    // merge objects an increment their counts when merged
    return Object.values(
      objects.reduce((acc, obj) => {
        if (!obj) {
          return acc;
        }

        // note: demo data (e.g. perf preview mode) does not have an id
        // so use the object name for uniqueness in that case
        const uniqueValue = obj.wordObject.id ?? obj.wordObject.name;

        if (acc[uniqueValue]) {
          acc[uniqueValue].count =
            acc[uniqueValue].count + (obj.wordObject?.count || 1);
          acc[uniqueValue].talentInventoryCount =
            acc[uniqueValue].talentInventoryCount +
            (obj.wordObject.talentInventoryCount || 0);
          acc[uniqueValue].talentInventoryPeopleCount =
            acc[uniqueValue].talentInventoryPeopleCount +
            (obj.wordObject.talentInventoryPeopleCount || 0);
        } else {
          acc[uniqueValue] = {
            id: uniqueValue,
            word: obj.wordObject?.name ? obj.wordObject.name : obj.toString(),
            count: obj.wordObject?.count || 1,
            // note: demo data (e.g. perf preview mode) does not have an id
            ...(obj.wordObject.id
              ? {
                  url: pathPrefix + '/' + encodeURIComponent(obj.wordObject.id),
                }
              : {}),
            contributions: [],
            contributedPeople: [],
            declaredPeople: [],
            feedbackPeople: [],
            feedback: [],
            advisedPeople: [],
            energizedPeople: [],
            talentInventoryCount: obj.wordObject.talentInventoryCount || 0,
            talentInventoryPeopleCount:
              obj.wordObject.talentInventoryPeopleCount || 0,
          };
        }

        // add source
        if (obj.contribution) {
          acc[uniqueValue].contributions.push(obj.contribution);
        }
        if (obj.feedback) {
          acc[uniqueValue].feedback.push(obj.feedback);
        }

        // add relevant person(s)
        // note: we check for id here because we don't want to show "one of your peers"
        // in this attribution line for unattributed peers
        if (obj.contributedPerson?.id) {
          acc[uniqueValue].contributedPeople.push(obj.contributedPerson);
        }
        if (obj.declaredPerson) {
          acc[uniqueValue].declaredPeople.push(obj.declaredPerson);
        }
        if (obj.feedbackPerson) {
          acc[uniqueValue].feedbackPeople.push(obj.feedbackPerson);
        }
        if (obj.advisedPerson) {
          acc[uniqueValue].advisedPeople.push(obj.advisedPerson);
        }
        if (obj.energizedPerson) {
          acc[uniqueValue].energizedPeople.push(obj.energizedPerson);
        }

        return acc;
      }, {})
    );
  }
};

type WordCloudProps = {
  objects: object[];
  pathPrefix: string;
  name?: string;
  emptyText?: string;
  className?: string;
  minDisplayCount?: number;
  noun?: string | React.ReactNode;
  nounPlural?: string | React.ReactNode;
  isExternalUrl?: boolean;
  skillOwners?: object;
  maxWords?: number;
  isDemoOrPreviewMode?: boolean;
};

const WordCloud: FC<WordCloudProps> = ({
  minDisplayCount = 1,
  noun = (
    <FormattedMessage
      id="app.widgets.word_cloud.singular.mention"
      defaultMessage="mention"
    />
  ),
  nounPlural = (
    <FormattedMessage
      id="app.widgets.word_cloud.plural.mentions"
      defaultMessage="mentions"
    />
  ),
  isExternalUrl = false,
  maxWords = 30,
  isDemoOrPreviewMode = false,
  ...props
}) => {
  const emptyElement = (
    <p
      className={
        'text-center text-muted my-3 px-5' +
        (props.className ? ' ' + props.className : '')
      }
    >
      {props.emptyText}
    </p>
  );

  const peopleList = (word) => {
    const people = props.skillOwners ? props.skillOwners[word] : [];
    const peopleObjs = people.map((p) => ({
      name: getPersonField(p, 'full_name'),
      url: getPersonField(p, 'url'),
    }));

    return <TooltipList people={peopleObjs} />;
  };

  const propsWords = useMemo(
    () =>
      getWordCloudWordDictionaries(props.objects, props.pathPrefix)
        .sort((a, b) =>
          // @ts-expect-error
          a.word.toLowerCase().localeCompare(b.word.toLowerCase())
        )
        // @ts-expect-error
        .filter((word) => word.count >= minDisplayCount),
    [props.objects, props.pathPrefix, minDisplayCount]
  );

  // whether or not to show the view all / some toggle
  const showToggle = useMemo(() => {
    const allSameCount = propsWords.every(
      // @ts-expect-error
      (word) => word.count === propsWords[0].count
    );
    return !allSameCount && propsWords.length > maxWords;
  }, [propsWords, maxWords]);

  // whether or not we are currently showing all / some
  const [showingReduced, setShowingReduced] = useState(true);

  // the list of words to display
  const wordList = useMemo(() => {
    if (!showToggle || !showingReduced) {
      return propsWords;
    }

    let words = cloneDeep(propsWords);

    while (words.length >= maxWords) {
      // decrement the count of each word and remove any with a count of 0
      words.forEach((dict) => {
        // @ts-expect-error
        dict.count = dict.count - 1;
      });
      // @ts-expect-error
      words = words.filter((dict) => dict.count > 0);
    }

    return words;
  }, [propsWords, maxWords, showToggle, showingReduced]);

  if (!(props.objects?.length > 0)) {
    if (props.emptyText) {
      return emptyElement;
    } else {
      return <></>;
    }
  }

  return (
    <div>
      {props.name && (
        <div className="text-center text-muted mb-3">{props.name}</div>
      )}
      <div className={props.className}>
        <ul className="list-inline mb-0" style={{ lineHeight: 1.1 }}>
          {wordList.map((dict, index) => (
            // @ts-expect-error
            <WordCloudWord
              key={index}
              noun={noun}
              nounPlural={nounPlural}
              isExternalUrl={isExternalUrl}
              isDemoOrPreviewMode={isDemoOrPreviewMode}
              customDisplay={
                // @ts-expect-error
                props?.skillOwners && props?.skillOwners[dict?.word]?.length
              }
              // @ts-expect-error
              {...dict}
            >
              {props.skillOwners ? (
                // @ts-expect-error
                peopleList(dict.word)
              ) : (
                <>
                  {
                    // @ts-expect-error
                    dict?.feedback?.length > 0 && (
                      <div className="mt-3">
                        <ListGroup className="list-group-flush mb-n3">
                          {
                            // @ts-expect-error
                            dict.feedback.map((feedback, index) => (
                              <ListGroupItem
                                key={index}
                                className="border-0 p-0 pb-3"
                              >
                                <FeedbackCard
                                  feedback={feedback}
                                  // @ts-expect-error
                                  bodyOnly={true}
                                />
                              </ListGroupItem>
                            ))
                          }
                        </ListGroup>
                      </div>
                    )
                  }
                  {
                    // @ts-expect-error
                    dict?.contributions?.length > 0 && (
                      <div className="mt-3">
                        <span>
                          <FormattedMessage
                            id="app.widgets.word_cloud.used_in"
                            defaultMessage="Used in"
                            description="Sits next to a skill or behavior, precedes a comma-separated list of one or more activities. It is meant to list which activities a person did that used that skill."
                          />{' '}
                        </span>
                        <FormattedList
                          // @ts-expect-error
                          value={dict.contributions.map((c, cIndex) => (
                            <Fragment key={cIndex}>
                              <Link
                                target={isExternalUrl ? '_blank' : undefined}
                                rel="noopener noreferrer"
                                to={
                                  consts.ACTIVITIES().path + '/' + c.activity.id
                                }
                              >
                                {c.activity.name}
                              </Link>
                            </Fragment>
                          ))}
                        />
                      </div>
                    )
                  }
                  {
                    // @ts-expect-error
                    dict?.contributedPeople?.length > 0 && (
                      <div className="mt-3">
                        <span>
                          <FormattedMessage
                            id="app.widgets.word_cloud.attributed_to"
                            defaultMessage="Attributed to"
                            description="Comes before a person's full name, meant to indicate that a given skill or behavior is attributed to a certain person."
                          />{' '}
                        </span>
                        <FormattedList
                          // @ts-expect-error
                          value={dict.contributedPeople.map((p, pIndex) => (
                            <Fragment key={pIndex}>
                              {p.id && (
                                <Link
                                  target={isExternalUrl ? '_blank' : undefined}
                                  rel="noopener noreferrer"
                                  to={p.url}
                                >
                                  {p.full_name}
                                </Link>
                              )}
                              {!p.id && (
                                <span>{p.full_name.toLowerCase()}</span>
                              )}
                            </Fragment>
                          ))}
                        />
                      </div>
                    )
                  }
                  {
                    // @ts-expect-error
                    dict?.declaredPeople?.length > 0 && (
                      <div className="mt-3">
                        {
                          // @ts-expect-error
                          dict.declaredPeople.map((p, pIndex) => (
                            <span key={pIndex}>
                              <span>
                                <FormattedMessage
                                  id="app.widgets.word_cloud.self_declared_by"
                                  defaultMessage="Self-declared by"
                                  description="Precedes a person's full name, meant to indicate that a given skill or behavior is self-declared by a certain person as having that skill or behavior."
                                />{' '}
                              </span>
                              <Link
                                target={isExternalUrl ? '_blank' : undefined}
                                rel="noopener noreferrer"
                                to={p.url}
                              >
                                {p.full_name}
                              </Link>
                            </span>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    // @ts-expect-error
                    dict?.advisedPeople?.length > 0 && (
                      <div className="mt-3">
                        <span>
                          <FormattedMessage
                            id="app.widgets.word_cloud.advised"
                            defaultMessage="Advised"
                            description="Precedes a person's full name, meant to indicate that this person advised someone else."
                          />{' '}
                        </span>
                        <FormattedList
                          // @ts-expect-error
                          value={dict.advisedPeople.map((p, pIndex) => (
                            <Fragment key={pIndex}>
                              {p.id && (
                                <Link
                                  target={isExternalUrl ? '_blank' : undefined}
                                  rel="noopener noreferrer"
                                  to={p.url}
                                >
                                  {p.full_name}
                                </Link>
                              )}
                              {!p.id && (
                                <span>{p.full_name.toLowerCase()}</span>
                              )}
                            </Fragment>
                          ))}
                        />
                      </div>
                    )
                  }
                  {
                    // @ts-expect-error
                    dict?.energizedPeople?.length > 0 && (
                      <div className="mt-3">
                        <span>
                          <FormattedMessage
                            id="app.widgets.word_cloud.energized"
                            defaultMessage="Energized"
                            description="Precedes a person's full name, meant to indicate that this person energized (i.e. motivated) someone else at work."
                          />{' '}
                        </span>
                        <FormattedList
                          // @ts-expect-error
                          value={dict.energizedPeople.map((p, pIndex) => (
                            <Fragment key={pIndex}>
                              {p.id && (
                                <Link
                                  target={isExternalUrl ? '_blank' : undefined}
                                  rel="noopener noreferrer"
                                  to={p.url}
                                >
                                  {p.full_name}
                                </Link>
                              )}
                              {!p.id && (
                                <span>{p.full_name.toLowerCase()}</span>
                              )}
                            </Fragment>
                          ))}
                        />
                      </div>
                    )
                  }
                </>
              )}
            </WordCloudWord>
          ))}
        </ul>
      </div>
      {showToggle && (
        <div className="text-center">
          <Button
            color="link"
            className="px-0 pb-0"
            onClick={() => setShowingReduced(!showingReduced)}
          >
            {showingReduced ? (
              <FormattedMessage
                id="app.widgets.word_cloud.view_all"
                defaultMessage="View all"
              />
            ) : (
              <FormattedMessage
                id="app.widgets.word_cloud.view_less"
                defaultMessage="View less"
              />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(WordCloud);
