import * as consts from '../../consts/consts';

import { Button, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import React, { FC, useMemo } from 'react';

import NavbarBrandDropdown from '../Widgets/Dropdowns/NavbarBrandDropdown';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import UncontrolledHoverDropdown from '../Widgets/Dropdowns/UncontrolledHoverDropdown';
import { connect } from 'react-redux';
import { logOutAndCleanUp } from '../../utils/models/User';
import { redirectLoginOptionsGenerator } from '../../utils/util/utiltsx';
import { useAuth0 } from '@auth0/auth0-react';

const AccountDropdown: FC<Props> = ({
  hasMultipleActiveOrDemoCompanies = false,
  isSuperAdmin = false,
  isOrgSystemAdmin = false,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const location = useLocation();
  const showWhatsNew = useMemo(
    // @ts-expect-error
    () => props?.currentOrganization?.status === 'A',
    // @ts-expect-error
    [props?.currentOrganization]
  );

  const isSignup = location.pathname === '/signup';

  return (
    <>
      {(!isAuthenticated || !user || !props.meId) && (
        <Button
          color="link"
          onClick={() => {
            console.debug('[accountdropdown][call] loginWithRedirect');
            loginWithRedirect(
              // @ts-expect-error
              redirectLoginOptionsGenerator(location, isSignup)
            );
            console.debug('[accountdropdown][response] loginWithRedirect');
          }}
        >
          <FormattedMessage
            id="app.views.layout.account_dropdown.login"
            defaultMessage="Log in"
            description="Label on log in button"
          />
        </Button>
      )}
      {props.meId && (
        // @ts-expect-error
        <UncontrolledHoverDropdown
          tag="div"
          className={
            'd-md-down-none' + (props.className ? ' ' + props.className : '')
          }
        >
          <DropdownToggle nav className="p-0">
            <div className="avatar avatar-sm">
              <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                <i className="fe fe-chevron-down"></i>
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu end>
            {!props.errorMessage && hasMultipleActiveOrDemoCompanies && (
              <>
                <DropdownItem>
                  <div
                    onClick={(e) => {
                      // prevent close of outer dropdown
                      e.stopPropagation();
                    }}
                  >
                    <NavbarBrandDropdown isSuperAdmin={isSuperAdmin} />
                  </div>
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            {!props.errorMessage && !props.isInFocusedMode && (
              <>
                <DropdownItem
                  tag={Link}
                  to={consts.PROFILE(formatMessage).path}
                >
                  <FormattedMessage
                    id="app.views.layout.account_dropdown.profile"
                    defaultMessage="Profile"
                    description="Label on dropdown menu item going to user's profile page"
                  />
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to={consts.ACCOUNT(formatMessage).path}
                >
                  <FormattedMessage
                    id="app.views.layout.account_dropdown.account"
                    defaultMessage="Account"
                    description="Label on dropdown menu item going to user's account page"
                  />
                </DropdownItem>
                {(isOrgSystemAdmin || isSuperAdmin) && (
                  <DropdownItem
                    tag={Link}
                    to={consts.ORGANIZATION_SETTINGS(formatMessage).path}
                  >
                    <FormattedMessage
                      id="app.views.layout.account_dropdown.organization_settings"
                      defaultMessage="Organization Settings"
                      description="Label on dropdown menu item going to organization settings"
                    />
                  </DropdownItem>
                )}
                {showWhatsNew && (
                  <DropdownItem
                    tag="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={consts.WHATS_NEW.path}
                  >
                    <FormattedMessage
                      id="app.views.layout.account_dropdown.whats_new"
                      defaultMessage="What's new?"
                      description="Label on dropdown menu item going to a page showing the newest features released by Confirm"
                    />
                  </DropdownItem>
                )}
              </>
            )}
            <DropdownItem onClick={props.onSupport}>
              <FormattedMessage
                id="app.views.layout.account_dropdown.help_and_support"
                defaultMessage="Help & Support"
                description="Label on dropdown menu item to get help and support for using Confirm"
              />
            </DropdownItem>
            {isSuperAdmin && (
              <>
                <DropdownItem divider />
                <DropdownItem
                  tag={'a'}
                  href={consts.SUPERUSER_ADMIN(formatMessage).path}
                >
                  {consts.SUPERUSER_ADMIN(formatMessage).name}
                </DropdownItem>
              </>
            )}
            <DropdownItem divider />
            <DropdownItem onClick={() => logOutAndCleanUp(logout)}>
              <FormattedMessage
                id="app.views.layout.account_dropdown.logout"
                defaultMessage="Log out"
                description="Label on dropdown option to log out"
              />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledHoverDropdown>
      )}
    </>
  );
};

const AccountDropdown_propTypes = {
  isInFocusedMode: PropTypes.bool.isRequired,
  meId: PropTypes.number.isRequired,
  features: PropTypes.object.isRequired,
  hasMultipleActiveOrDemoCompanies: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isOrgSystemAdmin: PropTypes.bool,
  onLogout: PropTypes.func,
  onSupport: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof AccountDropdown_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, me, features } = state;

  return {
    currentOrganization,
    meId: me?.id,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(AccountDropdown));
