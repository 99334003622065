import React, { FC } from 'react';
import { Feature, Organization, Person } from 'types';
import { connect } from 'react-redux';
import SwitchInput from 'views/Widgets/Inputs/SwitchInput';
import { Col, Row } from 'reactstrap';

interface Props {
  className?: string;
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  feature: Feature;
}

const FeatureToggle: FC<Props> = ({ className, feature }) => {
  const isSuperUserOnly = feature.superuser_only;

  return (
    <Row
      className={
        (className ?? '') + ` ${isSuperUserOnly ? 'superuser-feature' : ''}`
      }
    >
      <Col>
        <div>{feature.display_name}</div>
        {!!feature.description && (
          <div className="text-muted small">{feature.description}</div>
        )}
      </Col>
      <Col className="col-auto pt-2">
        <SwitchInput
          name={feature.name}
          value={!!feature.enabled}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(FeatureToggle));
