import * as consts from '../../../consts/consts';

import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';

import ActivityMentionCard from '../Cards/ActivityMentionCard';
import Autosuggest from 'react-autosuggest';
import AvatarGroup from '../People/AvatarGroup';
import { ConfirmIntlProvider } from '../../../locale/ConfirmIntlContext';
import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import { InputGroup } from 'reactstrap';
import ObjectCard from '../Cards/ObjectCard';
import PersonCard from '../Cards/PersonCard';
import PrependedInput from './PrependedInput';
import PropTypes from 'prop-types';
import { SKILL_TYPE_BEHAVIOR } from '../../../utils/models/Skill';
import { connect } from 'react-redux';
import { getActivityType } from '../../../utils/models/Activity';
import { renderValidationError } from '../../../utils/util/formatter';
import { titleCase } from 'title-case';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

export const getSuggestionTitle = (item, intl) => {
  const index = item._index;

  switch (index) {
    // ElasticSearch
    case 'people':
      return item.object.full_name;
    case 'activities':
      return item.object.name
        ? item.object.name
        : 'Untitled ' + getActivityType(item.object, intl)?.name;
    case 'skills':
      return titleCase(item.object.name);
    case 'credentials':
      return titleCase(item.object.name);
    case 'organizations':
      return titleCase(item?.object?.name);
    // not ElasticSearch, but matching format for convenience
    case 'survey_responses':
      return `${item.object.email} (${titleCase(item.object.name)})`;
    case 'clearbit':
      // needs email for matching in search
      return item.object.email;
    default:
      // object.name should take precedence (to allow for crafting
      // special messages on outside with actual object on inside)
      return item?.object?.name
        ? item?.object?.name
        : item?.name
        ? item.name
        : '';
  }
};

const WrappedPersonCard = (props) => {
  const { formatMessage } = useIntl();
  const nameTransformerFunction = props.nameTransformerFunction;
  const formattedNameTransformerFunction = nameTransformerFunction
    ? (name) => nameTransformerFunction(name, formatMessage)
    : undefined;

  return (
    <PersonCard
      {...props}
      nameTransformerFunction={formattedNameTransformerFunction}
    />
  );
};

export const renderSuggestion = (
  intl,
  item,
  queryToUse = '',
  isHighlighted = true,
  size = 'xs',
  showDescription = true,
  closeButtonText = null,
  onClose = null,
  inline = false,
  bodyOnly = true,
  inDropdown = true,
  newObjectValidationFunction = null,
  disabled = false
) => {
  let icon = item?.icon ? item.icon : null;
  let name = '';
  let people = null;
  let description = item?.description ? item.description : '';
  const index = item._index;

  let role = 'button';

  const query = isHighlighted ? queryToUse : '';
  let isValid = true;
  let nameTransformerFunction = undefined;
  let isSpecialPerson = false;

  switch (index) {
    case 'people':
      return (
        <ConfirmIntlProvider>
          <PersonCard
            disabled={disabled}
            role={role}
            person={item.object}
            query={query}
            size={size}
            showDescription={showDescription}
            closeButtonText={closeButtonText}
            onClose={onClose}
            inline={inline}
            bodyOnly={bodyOnly}
            inDropdown={inDropdown}
          />
        </ConfirmIntlProvider>
      );
    case 'activities':
      return (
        <ConfirmIntlProvider>
          <ActivityMentionCard
            disabled={disabled}
            role={role}
            activity={item.object}
            query={query}
            size={size}
            showDescription={showDescription}
            closeButtonText={closeButtonText}
            onClose={onClose}
            inline={inline}
            bodyOnly={bodyOnly}
            inDropdown={inDropdown}
            isValid={isValid}
            hideEmojis={true}
            hideImages={true}
            hideContributions={true}
            hideDescription={true}
            isCompressedView={false}
          />
        </ConfirmIntlProvider>
      );
    case index?.match(/^objectives/)?.input ?? false:
      return (
        <ConfirmIntlProvider>
          <ObjectCard
            icon={consts.ICONS.OBJECTIVE}
            name={item.object.name}
            query={query}
            role={role}
            size={size}
            closeButtonText={closeButtonText}
            onClose={onClose}
            inline={inline}
            bodyOnly={bodyOnly}
            inDropdown={inDropdown}
            isValid={isValid}
            disabled={disabled}
            aside={<AvatarGroup size="xs" people={item.object.collaborators} />}
          />
        </ConfirmIntlProvider>
      );
    case 'skills':
      icon = consts.ICONS.SKILL;
      name = getSuggestionTitle(item, intl);
      // default to "Skill" if no type provided
      description =
        item?.object?.type === SKILL_TYPE_BEHAVIOR.id ? (
          <FormattedMessage
            id="app.views.widgets.inputs.validated_autosuggest.behavior"
            defaultMessage="Behavior"
          />
        ) : (
          <FormattedMessage
            id="app.views.widgets.inputs.validated_autosuggest.skill"
            defaultMessage="Skill"
          />
        );
      break;
    case 'credentials':
      icon = consts.ICONS.CREDENTIAL;
      name = getSuggestionTitle(item, intl);
      description = (
        <FormattedMessage
          id="app.views.widgets.inputs.validated_autosuggest.credential"
          defaultMessage="Credential"
        />
      );
      break;
    case 'organizations':
      name = getSuggestionTitle(item, intl);

      description = (
        <FormattedMessage
          id="app.views.widgets.inputs.validated_autosuggest.organization"
          defaultMessage="{emailDomains}"
          values={{
            emailDomains:
              item?.object?.email_domains &&
              item.object.email_domains.length > 0 ? (
                '@' + item.object.email_domains.join(', @')
              ) : (
                <FormattedMessage
                  id="app.views.widgets.inputs.validated_autosuggest.no_email_domains"
                  defaultMessage="no email domains associated"
                />
              ),
          }}
        />
      );
      break;
    case 'manager': // use for "manager" case for use in Perf calibration UI
      isSpecialPerson = true;
      nameTransformerFunction = (name, formatMessage) =>
        formatMessage(
          {
            id: 'app.views.widgets.inputs.validated_autosuggest.manager',
            defaultMessage: "{name}'s direct reports",
          },
          { name }
        );
      break;
    case 'manager_or_above': // use for "manager" case for use in Perf calibration UI
      isSpecialPerson = true;
      nameTransformerFunction = (name, formatMessage) =>
        formatMessage(
          {
            id: 'app.views.widgets.inputs.validated_autosuggest.manager_or_above',
            defaultMessage: "{name}'s full team",
          },
          { name }
        );
      break;
    case 'final_rating_provided_by_person': // use for "final_rating_provided_by_person" case for use in Perf calibration UI
      isSpecialPerson = true;
      nameTransformerFunction = (name, formatMessage) =>
        formatMessage(
          {
            id: 'app.views.widgets.inputs.validated_autosuggest.calibrated_final_rating',
            defaultMessage: '{name} calibrated final rating',
          },
          { name }
        );
      break;
    case 'clearbit': // not ElasticSearch, but matching format for convenience
      isSpecialPerson = true;
      break;
    case 'survey_responses':
      name = getSuggestionTitle(item, intl);
      break;

    default:
      name = getSuggestionTitle(item, intl);
      if (newObjectValidationFunction) {
        isValid = newObjectValidationFunction(name);
      }
  }

  // can define "isPerson" in the item itself
  if (isSpecialPerson || item?.isPerson) {
    if (newObjectValidationFunction) {
      isValid = newObjectValidationFunction(item.object?.email);
    }

    return (
      <ConfirmIntlProvider>
        <WrappedPersonCard
          disabled={disabled}
          role={role}
          person={item.object}
          query={query}
          size={size}
          showDescription={showDescription}
          closeButtonText={closeButtonText}
          onClose={onClose}
          inline={inline}
          bodyOnly={bodyOnly}
          inDropdown={inDropdown}
          isValid={isValid}
          nameTransformerFunction={
            nameTransformerFunction
              ? nameTransformerFunction
              : item?.nameTransformerFunction
          }
        />
      </ConfirmIntlProvider>
    );
  }

  return (
    <ConfirmIntlProvider>
      <ObjectCard
        disabled={disabled}
        icon={icon}
        name={name}
        people={people}
        description={showDescription ? description : undefined}
        // no index means not a real object, so don't match query to it
        // (example: creating inline team should show "Create team <NAME>" without matching to "Create team")
        query={index ? query : undefined}
        role={role}
        size={size}
        closeButtonText={closeButtonText}
        onClose={onClose}
        inline={inline}
        bodyOnly={bodyOnly}
        inDropdown={inDropdown}
        isValid={isValid}
      />
    </ConfirmIntlProvider>
  );
};

const ValidatedAutosuggest = (props) => {
  const { formatMessage } = useIntl();
  const { user } = useAuth0();
  const userSub = user?.sub;

  const [inputValue, setInputValue] = useState('');
  const [elasticsearchSuggestions, setElasticsearchSuggestions] = useState([]);
  const [overallSuggestions, setOverallSuggestions] = useState(
    props.suggestions
  );
  const [validationErrors, setValidationErrors] = useState(
    props.validationErrors
  );

  const history = useHistory();

  useEffect(() => {
    setInputValue(
      typeof props.value === 'string'
        ? props.value
        : props.value?.name
        ? props.value.name
        : ''
    );
  }, [props.value]);

  // re-render validation errors if they are updated
  useEffect(() => {
    setValidationErrors(props.validationErrors);
  }, [props.validationErrors]);

  useEffect(() => {
    // combine suggestions with input value, elasticsearchSuggestions, and props.suggestions
    let newSuggestions = [...elasticsearchSuggestions, ...props.suggestions];

    if (props.appendInputValueToSuggestions) {
      newSuggestions.push(inputValue);
    }

    setOverallSuggestions(newSuggestions);
  }, [
    props.appendInputValueToSuggestions,
    inputValue,
    elasticsearchSuggestions,
    props.suggestions,
  ]);

  const focusRef = useRef(null);
  const refToUse = props.innerRef ? props.innerRef : focusRef;

  useEffect(() => {
    if (props.autoFocus && refToUse && refToUse.current) {
      refToUse.current.focus();
    }
  }, [props.autoFocus, refToUse]);

  const autosuggestTheme = {
    container: 'autosuggest',
    suggestionsContainer: 'dropdown',
    suggestionsList:
      'dropdown-menu' +
      (overallSuggestions.length ? ' show' : '') +
      (props.dropdownMenuClass ? ' ' + props.dropdownMenuClass : ''),
    suggestion: 'dropdown-item',
    suggestionHighlighted: 'active',
  };

  const onKeyDown = (event) => {
    switch (event.keyCode) {
      // ENTER
      case 13: {
        // don't auto-submit form when pressing enter; enter should just select item from list
        event.preventDefault();
        break;
      }
      default:
        break;
    }
  };

  const isOnExcludeList = (suggestion) => {
    if (!props.excludeList) {
      return false;
    }

    return (
      props.excludeList &&
      props.excludeList.findIndex(
        (object) =>
          (object.email && object.email === suggestion.email) ||
          (object.id && object.id === suggestion.id)
      ) !== -1
    );
  };

  const onInputChange = (e, { newValue }) => {
    if (typeof e?.target?.value !== 'undefined') {
      setInputValue(e.target.value);
      if (props.onInputChange) {
        return props.onInputChange(e);
      }
    } else {
      // this is a suggestion that was selected via click;
      // in this case, only clear value if object is NOT on the exclude list
      // (as an error will be shown, so we want to keep the text the user inputted)
      // and set focus back on input field
      if (isOnExcludeList(newValue)) {
        setInputValue(newValue);
        if (refToUse && refToUse.current) {
          refToUse.current.focus();
        }
      } else {
        if (props.clearInputOnSelect) {
          setInputValue('');
        } else {
          setInputValue(newValue);
        }
      }
    }
  };

  const renderInputComponent = (ipr) => {
    let inputProps = ipr;

    // NOTE: we need to use "input" instead of "Input" to avoid the "form-control"
    // class from being included
    if (props.iconClassName) {
      return (
        <InputGroup>
          <PrependedInput iconClassName={props.iconClassName}>
            <input {...inputProps} />
          </PrependedInput>
          {validationErrors &&
            validationErrors[props.name] &&
            renderValidationError(validationErrors[props.name])}
        </InputGroup>
      );
    } else {
      return (
        <>
          <input
            {...inputProps}
            style={{ border: 'none', outline: 'none', width: '100%' }}
          />
          {renderValidationError(validationErrors[props.name])}
        </>
      );
    }
  };

  const onSuggestionsFetchRequested = (event) => {
    const query = event.value;

    if (props.elasticsearchOptions) {
      const q = props.elasticsearchOptions.getQuery
        ? props.elasticsearchOptions.getQuery(query)
        : query
        ? { query: query }
        : {};

      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        q && Object.keys(q).length > 0 ? undefined : userSub,
        props.currentProxyPerson,
        props.currentOrganization?.id,
        props.elasticsearchOptions.url,
        q,
        (hits) => {
          setElasticsearchSuggestions(
            hits.map(
              props.elasticsearchOptions.mapFunction
                ? props.elasticsearchOptions.mapFunction
                : ElasticsearchAPI.defaultSelectorMapFunction
            )
          );
        },
        (error) => {
          console.error(
            'Validated autosuggest error: ' + JSON.stringify(error)
          );
          setElasticsearchSuggestions([]);
        }
      );
    } else if (props.onSuggestionsFetchRequested) {
      return props.onSuggestionsFetchRequested(event);
    }
  };

  const onSuggestionsClearRequested = () => {
    setElasticsearchSuggestions([]);
  };

  const inputProps = {
    ref: refToUse,
    name: props.name,
    type: props.type ? props.type : 'text',
    placeholder: props.placeholder,
    value: inputValue,
    className:
      (props.validationErrors[props.name]?.length > 0 ? 'is-invalid ' : '') +
      (props.iconClassName ? 'form-control form-control-prepended' : ''),
    onChange: onInputChange,
    autoComplete: props.autoComplete ? props.autoComplete : 'off',
    autoFocus: props.autoFocus,
    onKeyDown: onKeyDown,
    required: props.required,
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    if (props.linked) {
      // default url is correct for activities, skills, credentials
      let url = '/' + suggestion._index + '/' + suggestion.object.id;

      switch (suggestion._index) {
        case 'people':
          url = suggestion.object.url;
          break;
        case 'activities':
          break;
        case 'skills':
          break;
        case 'credentials':
          break;
        default:
          break;
      }

      history.push(url);
    }

    // if enter was used, this isn't triggered automatically, so do it manually
    setInputValue(props.clearInputOnSelect ? '' : suggestionValue);

    // if suggestion is on exclude list (as indicating by matching email address), show user error
    if (isOnExcludeList(suggestion)) {
      const newValidationErrors = {};
      newValidationErrors[props.name] =
        suggestion.email +
        ' ' +
        (props.excludeListErrorMessage ??
          formatMessage({
            id: 'app.views.widgets.inputs.validated_auto_suggest.is_duplicate',
            defaultMessage: 'is a duplicate.',
          }));
      setValidationErrors({
        ...validationErrors,
        ...newValidationErrors,
      });
    } else {
      // clear previous validation errors (e.g. exclude list)
      setValidationErrors(props.validationErrors);
      if (props.onSuggestionSelected) {
        props.onSuggestionSelected(suggestion);
      }
    }
  };

  const intl = useIntl();
  const rs = props.renderSuggestion
    ? props.renderSuggestion
    : (suggestion, { query, isHighlighted }) =>
        renderSuggestion(intl, suggestion, query, isHighlighted, props.size);

  const autosuggest = (
    <Autosuggest
      theme={autosuggestTheme}
      renderInputComponent={renderInputComponent}
      suggestions={overallSuggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={props.getSuggestionValue}
      renderSuggestion={rs}
      inputProps={inputProps}
      highlightFirstSuggestion={true}
    />
  );

  if (props.className) {
    return <div className={props.className}>{autosuggest}</div>;
  }

  return autosuggest;
};

ValidatedAutosuggest.defaultProps = {
  size: 'xs',
  linked: false,
  value: '',
  suggestions: [],
  getSuggestionValue: (x) => (x?.name ? x.name : x),
  excludeList: [],
  validationErrors: {},
  clearInputOnSelect: false,
};

ValidatedAutosuggest.propTypes = {
  appendInputValueToSuggestions: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  clearInputOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  dropdownMenuClass: PropTypes.string,
  elasticsearchOptions: PropTypes.object,
  excludeList: PropTypes.arrayOf(PropTypes.object),
  excludeListErrorMessage: PropTypes.string,
  getSuggestionValue: PropTypes.func,
  iconClassName: PropTypes.string,
  innerRef: PropTypes.object,
  linked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  onSuggestionsClearRequested: PropTypes.func,
  onSuggestionsFetchRequested: PropTypes.func,
  placeholder: PropTypes.string,
  renderSuggestion: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.string,
  suggestions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  type: PropTypes.string,
  validationErrors: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ValidatedAutosuggest.propTypes = {
  currentOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(ValidatedAutosuggest));
