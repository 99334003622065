import * as consts from '../../consts/consts';

import { CAMPAIGN_STATUSES, getPhaseByType } from '../../utils/models/Campaign';
import { Card, CardBody } from 'reactstrap';
import {
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_PEER_FEEDBACK,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_SELF,
  getCampaignHasFeatureEnabled,
  getPhaseDateHasPassed,
} from '../../utils/models/Performance';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import Page from '../Layout/Pages/Page';
import ParticipationDashboard from '../Widgets/Dashboards/ParticipationDashboard';
import PerformanceCycleDropdown from '../Widgets/Dropdowns/PerformanceCycleDropdown';
import PropTypes from 'prop-types';
import RatingsDashboard from '../Widgets/Dashboards/RatingsDashboard';
import RecalculatePeersButton from './Peers/RecalculatePeersButton';
import { ReduxState } from 'types';
import TakeawaysDashboard from '../Widgets/Dashboards/TakeawaysDashboard';
import TeamDashboard from '../Widgets/Dashboards/TeamDashboard';
import { asInt } from '../../utils/util/util';
import { connect } from 'react-redux';
import { getUserIsSuperAdmin } from '../../utils/models/User';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';
import {
  keyTakeawaysIsEnabled,
  participationDashboardIsEnabled,
  ratingDashboardIsEnabled,
  teamDashboardIsEnabled,
} from 'utils/util/features';

/**
 * A central Performance page that presents a tabbed navigation to all the
 * Performance-related dashboards for better discoverability. It manages a
 * common dropdown control through which the user can select which perfor-
 * mance cycle the dashboards are providing information about.
 *
 *
 * @param {*} props
 * @returns
 */
const PerformanceHubPage: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [campaigns, setCampaigns] = useState();
  const [teamDashboardReload, setTeamDashboardReload] = useState(0);
  const [pageStatusIndicator, setPageStatusIndicator] = useState(undefined);
  const history = useHistory();
  const location = useLocation();
  const shouldAllowNoSelection = true;
  const shouldShowPhaseDropdown =
    location.pathname === consts.PARTICIPATION_DASHBOARD().path;
  const { user } = useAuth0();

  const isSuperAdmin = getUserIsSuperAdmin(props.authUser);

  const isSystemAdmin = useMemo(
    // @ts-expect-error
    () => props.currentOrganization?.is_system_admin,
    [props.currentOrganization]
  );

  // the campaign currently selected in the dropdown
  const campaignId = useMemo(() => {
    return asInt(new URLSearchParams(location.search).get('campaignId'));
  }, [location]);

  const currentCampaignIndex = useMemo(() => {
    // @ts-expect-error
    if (!campaigns?.length) {
      return null;
    }
    // @ts-expect-error
    let idx = campaigns.findIndex((x) => x.id === campaignId);
    if (idx < 0) {
      idx = 0;
    }
    return idx;
  }, [campaigns, campaignId]);

  const [isRatingDashboardAllowed, setIsRatingDashboardAllowed] = useState<
    boolean | undefined
  >(undefined);

  const features = props.features;
  const ratingDashboardEnabled = useMemo(() => {
    // @ts-expect-error
    return ratingDashboardIsEnabled(features);
  }, [features]);

  useEffect(() => {
    if (!ratingDashboardEnabled) {
      setIsRatingDashboardAllowed(false);
      return;
    }

    // @ts-expect-error
    const campaignIdToUse = campaignId || (campaigns && campaigns[0]?.id);
    // @ts-expect-error
    if (props.currentOrganization?.id && campaignIdToUse) {
      // @ts-expect-error
      const url = `/can-get-rating-stats?organization_id=${props.currentOrganization.id}&campaign=${campaignIdToUse}`;
      ConfirmAPI.getUrlWithCache(
        url,
        'can-get-rating-stats',
        // @ts-expect-error
        user.sub,
        // @ts-expect-error
        props.currentProxyPerson,
        undefined,
        (data) => {
          setIsRatingDashboardAllowed(data?.canSeeRatingStats);
        },
        (message) => {
          console.error(
            'Could not get rating dashboard permission: ' + message
          );
          setIsRatingDashboardAllowed(undefined);
        }
      );
    }
  }, [
    campaignId,
    campaigns,
    ratingDashboardEnabled,
    // @ts-expect-error
    props.currentOrganization?.id,
    props.currentProxyPerson,
    // @ts-expect-error
    user.sub,
  ]);

  // the phase type currently selected in the dropdown
  const phaseType = useMemo(() => {
    return (
      new URLSearchParams(location.search).get('phaseType') ||
      // @ts-expect-error
      campaigns?.find((x) => x.id === campaignId)?.phases?.[0]?.type ||
      // @ts-expect-error
      campaigns?.[0]?.phases[0]?.type ||
      PHASE_TYPE_SELF
    );
  }, [location, campaignId, campaigns]);

  const buildQueryString = useCallback(
    (campaignId, phaseType) => {
      const queryParameters = {
        ...Object.entries(new URLSearchParams(location.search)),
        ...(campaignId > 0 ? { campaignId: campaignId } : {}),
        ...(phaseType === PHASE_TYPE_SELF ? {} : { phaseType: phaseType }),
      };
      const querystring = new URLSearchParams(queryParameters).toString();
      return querystring ? `?${querystring}` : '';
    },
    [location.search]
  );

  useEffect(
    function redirectBaseURLToTeamDashboard() {
      if (location.pathname === consts.PERFORMANCE().path) {
        history.push(`${consts.PERFORMANCE().path}/admin${location.search}`);
      }
    },
    [history, location]
  );

  useEffect(
    function populateCampaigns() {
      ConfirmAPI.getUrlWithCache(
        '/campaigns',
        'campaigns',
        user?.sub,
        // @ts-expect-error
        props.currentProxyPerson,
        {
          status: CAMPAIGN_STATUSES.ACTIVE,
          // @ts-expect-error
          organization: props.currentOrganization?.id,
        },
        (data) => {
          if (data?.results) {
            setCampaigns(data.results.filter((x) => !!x.phases));
          }
        },
        (message) => {
          console.error('Could not fetch campaigns: ' + message);
          // @ts-expect-error
          setCampaigns(null);
        }
      );
    },
    // @ts-expect-error
    [props.currentOrganization?.id, props.currentProxyPerson, user?.sub]
  );

  const onChange = useCallback(
    function handleDropdownSelection({ campaignId, phaseType }) {
      const querystring = buildQueryString(campaignId, phaseType);
      history.replace(`${location.pathname}${querystring}`);
    },
    [buildQueryString, history, location.pathname]
  );

  const additionalActions = useMemo(() => {
    if (
      location.pathname === consts.TEAM_PERFORMANCE_DASHBOARD().path &&
      // @ts-expect-error
      campaigns?.length > 0
    ) {
      // @ts-expect-error
      const campaign = campaigns[currentCampaignIndex];
      const selfPhase = getPhaseByType(campaign, PHASE_TYPE_SELF);
      const othersPhase = getPhaseByType(campaign, PHASE_TYPE_OTHERS);
      const showRecalculatePeers =
        (isSuperAdmin || isSystemAdmin) &&
        selfPhase &&
        othersPhase &&
        getPhaseDateHasPassed(selfPhase.start_date) &&
        !getPhaseDateHasPassed(othersPhase.end_date);
      if (
        showRecalculatePeers &&
        props.currentOrganization &&
        getCampaignHasFeatureEnabled(
          campaign,
          PERFORMANCE_FEATURE_PEER_FEEDBACK
        )
      ) {
        return (
          <RecalculatePeersButton
            // @ts-expect-error
            organization={props.currentOrganization}
            campaign={campaign}
            callback={(relationships) => {
              if (relationships.length > 0) {
                setTeamDashboardReload(teamDashboardReload + 1);
              }
            }}
          />
        );
      }
    }
    return null;
  }, [
    campaigns,
    currentCampaignIndex,
    props.currentOrganization,
    isSuperAdmin,
    isSystemAdmin,
    teamDashboardReload,
    setTeamDashboardReload,
    location.pathname,
  ]);

  const cycleDropdown = useMemo(() => {
    return (
      // @ts-expect-error
      campaigns?.length > 0 && (
        <PerformanceCycleDropdown
          allowNoSelection={shouldAllowNoSelection}
          campaignId={campaignId}
          // @ts-expect-error
          campaigns={campaigns}
          onChange={onChange}
          phaseType={phaseType}
          showPhaseDropdown={shouldShowPhaseDropdown}
        >
          {additionalActions}
        </PerformanceCycleDropdown>
      )
    );
  }, [
    campaignId,
    campaigns,
    onChange,
    phaseType,
    shouldAllowNoSelection,
    shouldShowPhaseDropdown,
    additionalActions,
  ]);

  // @ts-expect-error
  const isTeamDashboardEnabled = teamDashboardIsEnabled(features);
  const isParticipationDashboardEnabled =
    // @ts-expect-error
    participationDashboardIsEnabled(features);
  // @ts-expect-error
  const isKeyTakeawaysEnabled = keyTakeawaysIsEnabled(features);

  const tabs = useMemo(
    function setupPerformanceTabs() {
      // @ts-expect-error
      if (!campaigns?.length) {
        return [];
      }
      const currentCampaign = campaigns[currentCampaignIndex];
      const querystring = buildQueryString(campaignId, phaseType);
      const ratingRequired = !getCampaignHasFeatureEnabled(
        currentCampaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      );

      const isEngagementSurveyOnly =
        // @ts-expect-error
        currentCampaign?.properties?.is_engagement_survey_only ?? false;
      const teamDashboardContent =
        isEngagementSurveyOnly || isTeamDashboardEnabled ? (
          <TeamDashboard
            // @ts-expect-error
            campaignIndex={currentCampaignIndex}
            campaignId={campaignId}
            campaign={currentCampaign}
            campaigns={campaigns}
            phaseType={phaseType}
            reload={teamDashboardReload}
            setPageStatusIndicator={setPageStatusIndicator}
          />
        ) : undefined;

      return [
        ...(isEngagementSurveyOnly
          ? [
              {
                name: formatMessage({
                  id: 'app.views.performance.participation_dashboard.tab_label',
                  defaultMessage: 'Participation',
                }),
                action: cycleDropdown,
                path: consts.PARTICIPATION_DASHBOARD().path + querystring,
                paths: [
                  consts.TEAM_PERFORMANCE_DASHBOARD().path + querystring,
                  consts.PARTICIPATION_DASHBOARD().path + querystring,
                ],
                isUltraWide: true,
                content: teamDashboardContent,
              },
            ]
          : [
              ...(isTeamDashboardEnabled
                ? [
                    {
                      name: formatMessage({
                        id: 'app.views.performance.team_dashboard.tab_label',
                        defaultMessage: 'Team',
                      }),
                      action: cycleDropdown,
                      path:
                        consts.TEAM_PERFORMANCE_DASHBOARD().path + querystring,
                      isUltraWide: true,
                      content: teamDashboardContent,
                    },
                  ]
                : []),
              ...(isParticipationDashboardEnabled
                ? [
                    {
                      name: formatMessage({
                        id: 'app.views.performance.participation_dashboard.tab_label',
                        defaultMessage: 'Participation',
                      }),
                      path: consts.PARTICIPATION_DASHBOARD().path + querystring,
                      action: cycleDropdown,
                      content: (
                        <ParticipationDashboard
                          campaignId={campaignId}
                          campaign={currentCampaign}
                          // @ts-expect-error
                          campaigns={campaigns}
                          phaseType={phaseType}
                        />
                      ),
                    },
                  ]
                : []),
            ]),
        ...((ratingRequired && isRatingDashboardAllowed) ||
        location.pathname.startsWith(consts.RATINGS_DASHBOARD().path)
          ? [
              {
                name: formatMessage({
                  id: 'app.views.performance.ratings_dashboard.tab_label',
                  defaultMessage: 'Ratings',
                }),
                action: cycleDropdown,
                path: consts.RATINGS_DASHBOARD().path + querystring,
                content: (
                  <RatingsDashboard
                    // @ts-expect-error
                    campaign={currentCampaign}
                    campaigns={campaigns}
                  />
                ),
              },
            ]
          : []),
        ...(isKeyTakeawaysEnabled
          ? [
              {
                name: formatMessage({
                  id: 'app.views.performance.takeaways_dashboard.tab_label',
                  defaultMessage: 'Key takeaways',
                }),
                action: cycleDropdown,
                path: consts.TAKEAWAYS_DASHBOARD().path + querystring,
                content: (
                  <TakeawaysDashboard
                    campaign={currentCampaign}
                    campaigns={campaigns}
                    setPageStatusIndicator={setPageStatusIndicator}
                  />
                ),
              },
            ]
          : []),
      ];
    },
    [
      campaigns,
      currentCampaignIndex,
      buildQueryString,
      campaignId,
      phaseType,
      formatMessage,
      cycleDropdown,
      teamDashboardReload,
      isTeamDashboardEnabled,
      isParticipationDashboardEnabled,
      isRatingDashboardAllowed,
      location.pathname,
      isKeyTakeawaysEnabled,
    ]
  );

  // if team dashboard is disabled and you're on /performance/admin, redirect to the first tab that is available
  useEffect(
    function redirectToFirstAvailableTabIfTeamDashboardDisabled() {
      if (
        !isTeamDashboardEnabled &&
        tabs.length > 0 &&
        location.pathname === consts.TEAM_PERFORMANCE_DASHBOARD().path
      ) {
        history.push(tabs[0].path);
      }
    },
    [history, isTeamDashboardEnabled, location, tabs]
  );

  const output = useMemo(() => {
    if (tabs?.length > 0) {
      return (
        <Page
          title={
            consts.TEAM_PERFORMANCE_DASHBOARD_HEADER_LINK(formatMessage).name
          }
          tabs={tabs}
          statusIndicator={pageStatusIndicator}
        />
      );
    } else {
      return (
        <Page
          title={formatMessage({
            id: 'app.views.performance.performance_hub_page.title.performance',
            defaultMessage: 'Performance',
          })}
        >
          <Card>
            <CardBody>
              <EmptyState
                title={formatMessage({
                  id: 'app.views.performance.performance_hub_page.title.nothing_to_see_here_yet',
                  defaultMessage: 'Nothing to see here yet',
                })}
                subtitle={formatMessage({
                  id: 'app.views.performance.performance_hub_page.subtitle.your_performance_cycle_is_currently',
                  defaultMessage:
                    'Your performance cycle is currently under construction.',
                })}
              />
            </CardBody>
          </Card>
        </Page>
      );
    }
  }, [tabs, formatMessage, pageStatusIndicator]);

  return output;
};

const PerformanceHubPage_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  authUser: PropTypes.object,
  features: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<typeof PerformanceHubPage_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { authUser, currentProxyPerson, currentOrganization, features } = state;

  return {
    authUser,
    currentProxyPerson,
    currentOrganization,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PerformanceHubPage));
