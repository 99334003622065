// https://github.com/facebook/react/issues/11538#issuecomment-417504600
// https://app.asana.com/0/home/1203665124224790/1205862660633723
// The translation plugin throws an error when it tries to remove a node that has already been removed.

import React, { FC } from 'react';

import SimpleErrorPage from '../views/Error/SimpleErrorPage';
import { useIntl } from 'react-intl';

const rethrowDomRemovedError = (e: any) => {
  return { ...e, domRemovedError: true };
};

// This is a hack to catch that error and add extra information so the error boundary can show a better message.
export const mokeyPatchForTranslationPluginErrorMessage = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // @ts-expect-error
    Node.prototype.removeChild = function (child, ...args) {
      try {
        return originalRemoveChild.apply(this, [child, ...args]);
      } catch (e: any) {
        if (child.parentNode !== this) {
          throw rethrowDomRemovedError(e);
        }
        throw e;
      }
    };

    const originalInsertBefore = Node.prototype.insertBefore;

    // @ts-expect-error
    Node.prototype.insertBefore = function (newNode, referenceNode, ...args) {
      try {
        return originalInsertBefore.apply(this, [
          newNode,
          referenceNode,
          ...args,
        ]);
      } catch (e: any) {
        if (referenceNode && referenceNode.parentNode !== this) {
          throw rethrowDomRemovedError(e);
        }
        throw e;
      }
    };
  }
};

export const withFallbackErrorWithSpecialInstructions = (
  error: any,
  _info: any
) => <ErrorWithSpecialInstructions error={error} />;

const ErrorWithSpecialInstructions: FC<{ error: any }> = ({ error }) => {
  const { formatMessage } = useIntl();
  if (error?.domRemovedError) {
    return (
      <SimpleErrorPage
        errorHeading={formatMessage({
          id: 'app.hacks.error.with.special.instructions.heading',
          defaultMessage: 'Please disable your language browser extension',
        })}
        errorMessage={formatMessage(
          {
            id: 'app.hacks.error.with.special.instructions.text',
            defaultMessage:
              'Language plugins like Google Translate change the data in Confirm and causes it to break. Disable the extension, refresh, and try again.<br></br><br></br>' +
              'You can set your preferred language from your account page if your organization has enabled that language as an option. If you do not see your language, contact your HR representative to ask for support in Confirm for that language.',
          },
          { br: () => <br /> }
        )}
      />
    );
  }
  return <SimpleErrorPage />;
};
