import ConfirmAPI from 'utils/api/ConfirmAPI';
import { NudgeForm, NudgeType, nudgeTemplateById } from '../types';
import { LocalesDict } from 'locale/ConfirmIntlsContext';

export function createNudgeLocalizations(
  nudgeType: NudgeType,
  nudge: NudgeForm,
  organization_id: number,
  locales: LocalesDict | null,
  setNudgeCallback: (nudge: NudgeForm | null) => void
) {
  if (locales) {
    const localeKeys = Object.keys(locales);
    const localizedTemplates: { [key: string]: NudgeType } = localeKeys.reduce(
      (acc, locale) => {
        const localizedTemplate = nudgeTemplateById(
          nudgeType.id,
          locales[locale].formatMessage
        );

        return {
          ...acc,
          [locale]: localizedTemplate,
        };
      },
      {}
    );

    const localizedSubject = localeKeys.reduce(
      (acc, locale) => ({
        ...acc,
        [locale]: localizedTemplates[locale].subject,
      }),
      {}
    );

    const localizedText = localeKeys.reduce(
      (acc, locale) => ({
        ...acc,
        [locale]: localizedTemplates[locale].text,
      }),
      {}
    );

    const nudgeTranslationNamespace = `nudge_${nudge?.key}`;

    const subjectPromise = new Promise((resolve, reject) =>
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        '/custom-translations',
        {
          translations: localizedSubject,
          key: '$.subject',
          namespace: nudgeTranslationNamespace,
          organization_id: organization_id,
          visibility: 'U',
        },
        (response, error) => {
          if (error) reject(error);
          else resolve(response);
        },
        null
      )
    );
    const textPromise = new Promise((resolve, reject) =>
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        '/custom-translations',
        {
          translations: localizedText,
          key: '$.text',
          namespace: nudgeTranslationNamespace,
          organization_id: organization_id,
          visibility: 'U',
        },
        (response, error) => {
          if (error) reject(error);
          else resolve(response);
        },
        null
      )
    );

    Promise.all([subjectPromise, textPromise])
      .then(() => {
        setNudgeCallback(nudge);
      })
      .catch(() => {
        setNudgeCallback(null);
      });
  } else {
    setNudgeCallback(nudge);
  }
}
