import * as consts from '../../consts/consts';

import {
  CAMPAIGN_STATUSES,
  getPerfCampaignIsClosed,
  getPhaseByType,
} from '../../utils/models/Campaign';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_SELF,
  canViewStepInCampaign,
  getCurrentPerfStep,
  getPhaseDateHasPassed,
  useCampaignsAndSurveyResponses,
} from '../../utils/models/Performance';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCampaignSpecialAccessToken,
  useCurrentActiveCampaign,
} from '../../utils/util/hooks';

import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import Page404 from '../Layout/Pages/Errors/Page404';
import PerformanceStepAssessDirectReports from './PerformanceStepAssessDirectReports';
import PerformanceStepAssessManager from './PerformanceStepAssessManager';
import PerformanceStepAssessOptionalPeers from './PerformanceStepAssessOptionalPeers';
import PerformanceStepAssessOrganization from './PerformanceStepAssessOrganization';
import PerformanceStepAssessPeers from './PerformanceStepAssessPeers';
import PerformanceStepAssessSelf from './PerformanceStepAssessSelf';
import PerformanceStepBasics from './PerformanceStepBasics';
import PerformanceStepCallouts from './PerformanceStepCallouts';
import PerformanceStepEvaluationPhaseWelcome from './PerformanceStepEvaluationPhaseWelcome';
import PerformanceStepEverythingComplete from './PerformanceStepEverythingComplete';
import PerformanceStepNetwork from './PerformanceStepNetwork';
import PerformanceStepOthersPhaseComplete from './PerformanceStepOthersPhaseComplete';
import PerformanceStepOthersPhaseWelcome from './PerformanceStepOthersPhaseWelcome';
import PerformanceStepRequestHighPriorityPeers from './PerformanceStepRequestHighPriorityPeers';
import PerformanceStepSelfPhaseComplete from './PerformanceStepSelfPhaseComplete';
import PerformanceStepWelcome from './PerformanceStepWelcome';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import SimplePage from '../Layout/Pages/SimplePage';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';

const perfRoutes = (formatMessage) => [
  {
    route: consts.PERFORMANCE_STEP_WELCOME(formatMessage),
    component: PerformanceStepWelcome,
    needsDirectReportReceivedRelationships: false,
  },
  {
    route: consts.PERFORMANCE_STEP_EVALUATION_PHASE_WELCOME(formatMessage),
    component: PerformanceStepEvaluationPhaseWelcome,
    // need for review of directs
    needsDirectReportReceivedRelationships: true,
    phaseType: PHASE_TYPE_EVALUATION,
  },
  {
    route: consts.PERFORMANCE_STEP_SELF_PHASE_COMPLETE(formatMessage),
    component: PerformanceStepSelfPhaseComplete,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_SELF,
  },
  {
    route: consts.PERFORMANCE_STEP_OTHERS_PHASE_COMPLETE(formatMessage),
    component: PerformanceStepOthersPhaseComplete,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_OTHERS,
  },
  {
    route: consts.PERFORMANCE_STEP_EVERYTHING_COMPLETE(formatMessage),
    component: PerformanceStepEverythingComplete,
    // since managers can click on their direct reports
    // even when all done, we set this to true
    needsDirectReportReceivedRelationships: true,
  },
  {
    route: consts.PERFORMANCE_STEP_BASICS(formatMessage),
    component: PerformanceStepBasics,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_SELF,
  },
  {
    route: consts.PERFORMANCE_STEP_CALLOUTS(formatMessage),
    component: PerformanceStepCallouts,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_SELF,
  },
  {
    route: consts.PERFORMANCE_STEP_NETWORK(formatMessage),
    component: PerformanceStepNetwork,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_SELF,
  },
  {
    route: consts.PERFORMANCE_STEP_OTHERS_PHASE_WELCOME(formatMessage),
    component: PerformanceStepOthersPhaseWelcome,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_OTHERS,
  },
  {
    route: consts.PERFORMANCE_STEP_CHOOSE_MUST_HAVE_PEERS(formatMessage),
    component: PerformanceStepRequestHighPriorityPeers,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_OTHERS,
  },
  {
    route: consts.PERFORMANCE_STEP_ASSESS_SELF(formatMessage),
    component: PerformanceStepAssessSelf,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_SELF,
  },
  {
    route: consts.PERFORMANCE_STEP_ASSESS_MANAGER(formatMessage),
    component: PerformanceStepAssessManager,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_OTHERS,
  },
  {
    route: consts.PERFORMANCE_STEP_ASSESS_PEERS(formatMessage),
    component: PerformanceStepAssessPeers,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_OTHERS,
  },
  {
    route: consts.PERFORMANCE_STEP_ASSESS_OPTIONAL_PEERS(formatMessage),
    component: PerformanceStepAssessOptionalPeers,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_OTHERS,
  },
  {
    route: consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS(formatMessage),
    component: PerformanceStepAssessDirectReports,
    // need for review of directs
    needsDirectReportReceivedRelationships: true,
    phaseType: PHASE_TYPE_EVALUATION,
  },
  {
    route: consts.PERFORMANCE_STEP_ASSESS_ORGANIZATION(formatMessage),
    component: PerformanceStepAssessOrganization,
    needsDirectReportReceivedRelationships: false,
    phaseType: PHASE_TYPE_OTHERS,
  },
];

const getRouteFromStepName = (stepName, formatMessage) => {
  return perfRoutes(formatMessage).find(
    (r) => r.route.path === consts.PERFORMANCE().path + '/' + stepName
  );
};

const PerformanceCurrentStep: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  // @ts-expect-error
  const campaignId = props.match.params.campaignId;
  // @ts-expect-error
  const stepName = props.match.params.step;

  const unableToLoadMessage = props.isUnableToLoadDueToError
    ? formatMessage({
        id: 'app.views.performance.performance_current_step.unable_to_load.error',
        defaultMessage:
          'Unable to load the campaign data. Please reload the page to try again.',
      })
    : undefined;

  const [
    needsDirectReportReceivedRelationships,
    setNeedsDirectReportReceivedRelationships,
  ] = useState(undefined);
  const [stepComponent, setStepComponent] = useState(undefined);

  // (re)fetch campaign, survey responses, current perf campaign, etc.
  // NOTE: needsDirectReportReceivedRelationships needs to NOT be undefined
  // for the fetch to actually occur
  const { campaign, setCampaign, errorMessage } =
    useCampaignsAndSurveyResponses(
      campaignId,
      props.isPreview,
      needsDirectReportReceivedRelationships
    );

  const history = useHistory();
  const location = useLocation();

  const isDemoOrPreviewOfCampaign = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  const { user } = useAuth0();

  const [currentActiveCampaign, permissions] = useCurrentActiveCampaign(
    // @ts-expect-error
    props.currentOrganization?.id,
    // @ts-expect-error
    props.currentProxyPerson,
    user?.sub
  );

  const currentRouteforStepName = getRouteFromStepName(stepName, formatMessage);
  const phaseTypeForStep = currentRouteforStepName?.phaseType;
  const pathForStep = currentRouteforStepName?.route?.path;

  const {
    hasToken: hasPhaseSpecialAccessToken,
    hasValidToken: hasValidPhaseSpecialAccessToken,
    isVerifyingToken: isVerifyingPhaseSpecialAccessToken,
  } = useCampaignSpecialAccessToken({
    // @ts-expect-error
    campaignId: campaign?.id,
    phaseType: phaseTypeForStep,
  });

  // An additional check that it makes sense to show the step to the user.
  // This checks both that the relevant phase exists and that the user should
  // be able to see it.
  const userShouldSeeStep = useCallback(
    (step) => {
      // if it's a demo or preview campaign we do not implement any access
      // restrictions
      if (isDemoOrPreviewOfCampaign) {
        return true;
      }

      // if the step is not defined or the campaign is not active, do not show.
      // else, we show if the user has access to the phase related to this
      // performance step
      if (!step || !currentActiveCampaign) {
        return false;
      } else if (
        step.route.path ===
          consts.PERFORMANCE_STEP_BASICS(formatMessage).path &&
        (!getPhaseByType(currentActiveCampaign, PHASE_TYPE_SELF) ||
          !permissions[PHASE_TYPE_SELF])
      ) {
        return false;
      } else if (
        step.route.path ===
          consts.PERFORMANCE_STEP_OTHERS_PHASE_WELCOME(formatMessage).path &&
        (!getPhaseByType(currentActiveCampaign, PHASE_TYPE_OTHERS) ||
          !permissions[PHASE_TYPE_OTHERS])
      ) {
        return false;
      } else if (
        step.route.path ===
          consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS(formatMessage).path &&
        (!getPhaseByType(currentActiveCampaign, PHASE_TYPE_EVALUATION) ||
          !permissions[PHASE_TYPE_EVALUATION])
      ) {
        return false;
      }
      return true;
    },
    [
      currentActiveCampaign,
      isDemoOrPreviewOfCampaign,
      permissions,
      formatMessage,
    ]
  );

  useEffect(() => {
    // redirect to appropriate place
    if (history) {
      // if the step was provided explicitly in the url, go there, else go to the current step
      // (we check that the step is valid and the previous steps have been completed)
      const canViewTheStep = canViewStepInCampaign(
        isDemoOrPreviewOfCampaign,
        hasPhaseSpecialAccessToken,
        pathForStep,
        props.me,
        props.currentOrganization,
        campaign,
        props.currentPerfSurveyResponse,
        props.demoPeople,
        formatMessage
      );
      if (stepName && canViewTheStep) {
        const step = getRouteFromStepName(stepName, formatMessage);

        if (canViewTheStep && step && userShouldSeeStep(step)) {
          setNeedsDirectReportReceivedRelationships(
            // @ts-expect-error
            isDemoOrPreviewOfCampaign
              ? undefined
              : step.needsDirectReportReceivedRelationships
          );
          // @ts-expect-error
          setStepComponent(step.component);
        } else if (campaign) {
          // If campaign is undefined we should stay loading i.e. don't set stepComponent to null,
          // leave it as undefined so we stay loading. BUT if campaign IS defined, then:
          // user tried going to /performance/<something we don't recognize>,
          // or user doesn't have permission to phase (or campaign doesn't have phase)
          // so use null to indicate 404
          setNeedsDirectReportReceivedRelationships(undefined);
          // @ts-expect-error
          setStepComponent(null);
        }
      } else if (campaign) {
        // step was either not found or not explicitly specified,
        // so go to current step for the given campaign
        const currentStep = getCurrentPerfStep(
          props.me,
          props.currentOrganization,
          campaign,
          props.currentPerfSurveyResponse,
          props.demoPeople,
          formatMessage
        ).path;

        // campaignId must be provided because then this is a PREVIEW; otherwise,
        // it's a DEMO (i.e. shareable url instead of a private preview)
        // and we don't want to redirect to the preview url
        if (isDemoOrPreviewOfCampaign && campaignId) {
          // prepend PREVIEW_CAMPAIGN + '/' + campaign id
          // to go to preview for step
          return history.replace(
            consts.PREVIEW_CAMPAIGN().path + '/' + campaignId + currentStep
          );
        } else {
          return history.replace(currentStep);
        }
      } else {
        // need to fetch campaign from server (so set this
        // to someting other than undefined; do so without fetching
        // directs to start
        // @ts-expect-error
        setNeedsDirectReportReceivedRelationships(false);
      }
    }
  }, [
    hasPhaseSpecialAccessToken,
    history,
    location,
    props.currentOrganization,
    campaign,
    props.currentPerfSurveyResponse,
    stepName,
    pathForStep,
    props.demoPeople,
    props.me,
    isDemoOrPreviewOfCampaign,
    campaignId,
    props.isPreview,
    userShouldSeeStep,
    formatMessage,
  ]);

  // if this is not a manually passed in campaign id and
  // this org has no campaigns to fetch the current one, there is no step to go to
  if (
    ((!campaignId || campaign === null) &&
      // @ts-expect-error
      Array.isArray(props.campaigns) &&
      // @ts-expect-error
      props.campaigns.length === 0) ||
    // null stepComponent (as opposed to undefined) indicates 404
    stepComponent === null
  ) {
    return <Page404 />;
  }

  const loadOrRenderOutput = loadOrRender(
    campaign,
    errorMessage || unableToLoadMessage
  );
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (!stepComponent || !campaign || isVerifyingPhaseSpecialAccessToken) {
    return <Loading />;
  }

  if (
    !isDemoOrPreviewOfCampaign &&
    // @ts-expect-error
    (!(props?.currentPerfSurveyResponse?.configs?.is_participating ?? true) ||
      // @ts-expect-error
      props?.currentPerfSurveyResponse?.configs?.is_only_receiving_review)
  ) {
    // the person is not eligible to participate in this perf cycle
    return (
      <Page
        title={formatMessage(
          {
            id: 'app.views.performance.performance_current_step.no_eligible.title.generic',
            defaultMessage: '{organizationName} {campaignName}',
          },
          {
            // @ts-expect-error
            organizationName: props.currentOrganization?.name,
            // @ts-expect-error
            campaignName: campaign?.name,
          }
        )}
      >
        <Row className="mt-4 mt-md-5">
          <Col className="col-12 col-xl-8">
            <FormattedMessage
              id="app.views.performance.performance_current_step.no_eligible.description"
              defaultMessage="
            You are currently not eligible to participate in this cycle. If you
            believe this is a mistake, please contact your HR department.
          "
            />
          </Col>
        </Row>
      </Page>
    );
  }

  // if trying to access to a phase that is over (without a special token), show a page
  // indicating as such
  if (
    !isDemoOrPreviewOfCampaign &&
    !getPerfCampaignIsClosed(campaign) &&
    phaseTypeForStep &&
    getPhaseDateHasPassed(
      getPhaseByType(campaign, phaseTypeForStep)?.end_date
    ) &&
    !hasValidPhaseSpecialAccessToken
  ) {
    return (
      <SimplePage
        title={formatMessage({
          id: 'app.views.performance.performance_current_step.phase_finished.title.generic',
          defaultMessage: 'This phase is complete',
        })}
        titleClassName="text-center"
      >
        <div className="text-center">
          <p className="text-start mb-4">
            <FormattedMessage
              id="app.views.performance.performance_current_step.phase_finished.description"
              defaultMessage="
            If you need to amend your information, ask your manager or HR administrator to give you access."
            />
          </p>
        </div>
      </SimplePage>
    );
  }

  // if the perf campaign is totally over, show a page
  // indicating as such
  if (
    !isDemoOrPreviewOfCampaign &&
    getPerfCampaignIsClosed(campaign) &&
    !hasValidPhaseSpecialAccessToken
  ) {
    return (
      <SimplePage
        title={formatMessage(
          {
            id: 'app.views.performance.performance_current_step.campaign_finished.title.generic',
            defaultMessage: '{organizationName} {campaignName} is complete',
          },
          {
            // @ts-expect-error
            organizationName: props.currentOrganization?.name,
            // @ts-expect-error
            campaignName: campaign?.name,
          }
        )}
        titleClassName="text-center"
      >
        <div className="text-center">
          <p className="text-start mb-4">
            <FormattedMessage
              id="app.views.performance.performance_current_step.campaign_finished.description"
              defaultMessage="
            After your manager or HR administrator releases your report, you can <link>view your report in your {organizationName} resume</link>."
              values={{
                // @ts-expect-error
                organizationName: props.currentOrganization?.name,
                link: (chunks) => (
                  <Link to={consts.PERFORMANCE_MY_REPORT().path}>{chunks}</Link>
                ),
              }}
            />
          </p>
        </div>
      </SimplePage>
    );
  }

  // show the relevant perf step if the person is eligible
  return React.createElement(stepComponent, { campaign, setCampaign });
};

const PerformanceCurrentStep_propTypes = {
  me: PropTypes.object,
  currentOrganization: PropTypes.object,
  currentPerfSurveyResponse: PropTypes.object,
  isPreview: PropTypes.bool,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  isUnableToLoadDueToError: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof PerformanceCurrentStep_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const {
    me,
    currentOrganization,
    campaigns,
    currentPerfSurveyResponse,
    demoPeople,
    isUnableToLoadDueToError,
  } = state;

  return {
    me,
    currentOrganization,
    campaigns,
    currentPerfSurveyResponse,
    demoPeople,
    isUnableToLoadDueToError,
  };
};

export default connect(mapStateToProps)(React.memo(PerformanceCurrentStep));
