import * as consts from '../../consts/consts';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person, ReduxState } from 'types';
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import JobFamilyDropdown from '../Widgets/JobFamilyDropdown';
import { Link } from 'react-router-dom';
import Page from '../Layout/Pages/Page';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';

const SKILLS_MATRIX_URL_SLUG_ALL = 'all';

interface Props {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
}

interface Level {
  color?: string;
  title?: string;
}

interface LevelDict {
  management?: Level;
  exempt?: Level;
  nonexempt?: Level;
}

interface JobFamilyDataset {
  levels?: LevelDict[];
  job_family_descriptions?: string;
  job_families?: object[];
  expectations?: object[];
  custom_names?: {
    management?: string;
    exempt?: string;
    nonexempt?: string;
  };
}

// Rest of the code where jobFamiliesDataset is used

const LevelingFramework: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [isMounted, setIsMounted] = useState(false);
  const [jobFamiliesDataset, setJobFamiliesDataset] = useState<
    JobFamilyDataset | undefined
  >(undefined);
  const [selectedJobFamily, setSelectedJobFamily] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [skillsMatrixUrlSlug, setSkillsMatrixUrlSlug] = useState(
    SKILLS_MATRIX_URL_SLUG_ALL
  );

  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch levels from the backend based on your permissions
    if (
      userSub &&
      props.currentOrganization?.id &&
      typeof jobFamiliesDataset === 'undefined'
    ) {
      ConfirmAPI.getUrlWithCache(
        '/job-families',
        'job-families',
        userSub,
        props.currentProxyPerson,
        { organization: props.currentOrganization?.id },
        (dataset) => {
          if (isMounted) {
            setJobFamiliesDataset(dataset?.data);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [
    isMounted,
    jobFamiliesDataset,
    userSub,
    props.currentOrganization,
    props.currentProxyPerson,
  ]);

  const baseLevels: LevelDict[] | undefined = jobFamiliesDataset?.levels;
  const levels =
    skillsMatrixUrlSlug === SKILLS_MATRIX_URL_SLUG_ALL || !selectedJobFamily
      ? baseLevels
      : // @ts-expect-error
        selectedJobFamily?.levels;

  const onChangeJobFamily = useCallback(
    (jobFamily) => {
      if (jobFamily) {
        if (jobFamily.levels && baseLevels) {
          // generate levels based on base data combined with data for the specific career path
          const newLevels = baseLevels.map((level, index) => ({
            nonexempt: jobFamily.levels[index]?.nonexempt
              ? {
                  color: baseLevels[index]?.nonexempt?.color,
                  ...jobFamily.levels[index]?.nonexempt,
                }
              : undefined,
            exempt: jobFamily.levels[index]?.exempt
              ? {
                  color: baseLevels[index]?.exempt?.color,
                  ...jobFamily.levels[index]?.exempt,
                }
              : undefined,
            management: jobFamily.levels[index]?.management
              ? {
                  color: baseLevels[index]?.management?.color,
                  ...jobFamily.levels[index]?.management,
                }
              : undefined,
          }));

          setSelectedJobFamily({
            ...jobFamily,
            levels: newLevels,
          });
          setSkillsMatrixUrlSlug(jobFamily.url_slug);
        } else {
          // if no levels defined, just set job family but not levels
          setSelectedJobFamily(jobFamily);
        }
      } else {
        // set to default
        setSelectedJobFamily(undefined);
        setSkillsMatrixUrlSlug(SKILLS_MATRIX_URL_SLUG_ALL);
      }
    },
    [baseLevels]
  );

  const selectedJobFamilyDescription = useMemo(() => {
    if (!selectedJobFamily) {
      return null;
    }

    // if jobFamiliesDataset.job_family_descriptions is defined and there
    // is an item in the list where job_family matches (and job_family_group
    // if provided), return it.
    // @ts-expect-error
    if (jobFamiliesDataset?.job_family_descriptions?.length > 0) {
      // @ts-expect-error
      const description = jobFamiliesDataset.job_family_descriptions.find(
        (d) =>
          // @ts-expect-error
          d.job_family === selectedJobFamily?.name &&
          // @ts-expect-error
          (!selectedJobFamily?.job_family_group ||
            !d?.job_family_group ||
            // @ts-expect-error
            d.job_family_group === selectedJobFamily.job_family_group)
      );
      return description?.description;
    }

    return null;
  }, [jobFamiliesDataset?.job_family_descriptions, selectedJobFamily]);

  const loadOrRenderOutput = loadOrRender(jobFamiliesDataset, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  const hasNonexemptLevels =
    levels && levels.findIndex((l) => l.nonexempt?.title) !== -1;

  // NOTE: if this org has a "job catalog" dataset, we extract the list of jobs
  // to show from there and all the levels will show that are provided. But if
  // it does not, and it just has a "job families" dataset (which is simpler
  // and doesn't define every level for every job) which contains job
  // descriptions for given job families, default to that instead.
  return (
    <Page
      pretitle={formatMessage({
        id: 'app.views.levels.leveling_framework.pretitle.overview',
        defaultMessage: 'Overview',
      })}
      title={
        props.currentOrganization.name +
        "'s " +
        consts.LEVELING_FRAMEWORK(formatMessage).name
      }
    >
      {jobFamiliesDataset && (
        <Row>
          {jobFamiliesDataset.job_families && (
            <Col className="col-auto">
              <JobFamilyDropdown
                jobFamilies={jobFamiliesDataset.job_families}
                onChange={onChangeJobFamily}
              />
            </Col>
          )}
          {!jobFamiliesDataset.job_families &&
            jobFamiliesDataset.job_family_descriptions && (
              <Col className="col-auto">
                <JobFamilyDropdown
                  // @ts-expect-error
                  jobFamilies={jobFamiliesDataset.job_family_descriptions}
                  onChange={onChangeJobFamily}
                />
              </Col>
            )}
          <Col>
            <Link
              // @ts-expect-error
              tag="Button"
              className="btn btn-primary d-block"
              to={
                consts.LEVELING_FRAMEWORK(formatMessage).path +
                '/' +
                (skillsMatrixUrlSlug
                  ? skillsMatrixUrlSlug
                  : SKILLS_MATRIX_URL_SLUG_ALL)
              }
            >
              {levels === baseLevels || !skillsMatrixUrlSlug
                ? formatMessage({
                    id: 'app.views.levels.leveling_framework.button.universal_expectations',
                    defaultMessage: 'Universal expectations',
                  })
                : formatMessage({
                    id: 'app.views.levels.leveling_framework.button.team_expectations',
                    defaultMessage: 'Team expectations',
                  })}
            </Link>
          </Col>
        </Row>
      )}
      {jobFamiliesDataset && (
        <>
          {selectedJobFamilyDescription && (
            <Card>
              {/* @ts-expect-error */}
              {selectedJobFamily?.name && (
                <CardHeader>
                  {/* @ts-expect-error */}
                  <CardHeaderTitle>{selectedJobFamily?.name}</CardHeaderTitle>
                </CardHeader>
              )}
              <CardBody>{selectedJobFamilyDescription}</CardBody>
            </Card>
          )}
          <div className="leveling-career-paths card">
            <table>
              <thead>
                <tr>
                  <th style={{ width: '6.5rem' }}>
                    <FormattedMessage
                      id="app.views.levels.leveling_framework.card.body.column.level.header"
                      defaultMessage="Level"
                    />
                  </th>
                  <th style={{ width: '33%' }}>
                    {jobFamiliesDataset?.custom_names?.management ?? (
                      <FormattedMessage
                        id="app.views.levels.leveling_framework.card.body.column.management_track.header"
                        defaultMessage="Management Track"
                      />
                    )}
                  </th>
                  <th style={{ width: '33%' }}>
                    {jobFamiliesDataset?.custom_names?.exempt ??
                      (hasNonexemptLevels ? (
                        <FormattedMessage
                          id="app.views.levels.leveling_framework.card.body.column.exempt_track.header"
                          defaultMessage="Exempt Track"
                        />
                      ) : (
                        <FormattedMessage
                          id="app.views.levels.leveling_framework.card.body.column.ic_track.header"
                          defaultMessage="IC Track"
                        />
                      ))}
                  </th>
                  {hasNonexemptLevels && (
                    <th style={{ width: '33%' }}>
                      {jobFamiliesDataset?.custom_names?.nonexempt ?? (
                        <FormattedMessage
                          id="app.views.levels.leveling_framework.card.body.column.non_emtpy_track.header"
                          defaultMessage="Non-exempt Track"
                        />
                      )}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {levels
                  .slice()
                  .reverse()
                  .map((level, levelIndex) => (
                    <tr key={levelIndex}>
                      <td>
                        <FormattedMessage
                          id="app.views.levels.leveling_framework.card.body.column.level.body"
                          defaultMessage="
                        Level"
                        />{' '}
                        {levels.length - levelIndex - 1 === 0
                          ? 'Intern'
                          : levels.length - levelIndex - 1}
                      </td>
                      {(hasNonexemptLevels
                        ? ['management', 'exempt', 'nonexempt']
                        : ['management', 'exempt']
                      ).map((item, itemIndex) => (
                        <Fragment key={itemIndex}>
                          {level[item] && (
                            <>
                              <td
                                className={
                                  level[item]?.title?.length > 0
                                    ? ''
                                    : 'empty-cell'
                                }
                                style={{
                                  boxShadow: level[item].title
                                    ? 'inset 4px 0px 0px ' + level[item].color
                                    : undefined,
                                }}
                                id={'level-' + item + '-' + levelIndex}
                              >
                                <div>{level[item].title}</div>
                                {level[item].description && (
                                  <div className="text-muted">
                                    {level[item].description}
                                  </div>
                                )}
                              </td>
                              {level[item].helperText && (
                                <UncontrolledPopover
                                  placement="left"
                                  trigger="hover"
                                  target={'level-' + item + '-' + levelIndex}
                                >
                                  {level[item]?.helperText}
                                </UncontrolledPopover>
                              )}
                            </>
                          )}
                          {!level[item] && <td className="empty-cell" />}
                        </Fragment>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(LevelingFramework));
