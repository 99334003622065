import * as consts from '../../consts/consts';

import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';

import Avatar from '../Widgets/People/Avatar';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import EmptyState from '../Widgets/EmptyState';
import Page from '../Layout/Pages/Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPersonDisplayTitleHtml } from '../../utils/models/Person';
import { loadOrRender } from '../../utils/util/formatter';
import { titleCase } from 'title-case';
import { useAuth0 } from '@auth0/auth0-react';

const CredentialPage: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const params = useParams();
  // @ts-expect-error
  const credentialId = params.id;

  const [credential, setCredential] = useState(undefined);
  const [people, setPeople] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth0();
  const userSub = user?.sub;
  const orgId = props.currentOrganizationId;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch credential details
    if (userSub && typeof credential === 'undefined' && credentialId && orgId) {
      ConfirmAPI.getObject(
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.CREDENTIALS,
        credentialId,
        (data) => {
          if (isMounted) {
            setCredential(data);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );

      // fetch people with credential
      ElasticsearchAPI.getPeopleWithCredential(
        userSub,
        props.currentProxyPerson,
        orgId,
        credentialId,
        (newPeople) => {
          if (isMounted) {
            setPeople(newPeople);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [
    isMounted,
    userSub,
    credentialId,
    credential,
    orgId,
    props.currentProxyPerson,
  ]);

  const loadOrRenderOutput = loadOrRender(credential && people, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  // @ts-expect-error
  const mentions = people?.map((p) => ({ person: p, count: 1 }));

  return (
    <Page
      avatarIcon={consts.ICONS.CREDENTIAL}
      pretitle={formatMessage({
        id: 'app.views.credentials.credential_page.pretitle.credential',
        defaultMessage: 'Credential',
      })}
      // @ts-expect-error
      title={credential?.name ? titleCase(credential.name) : ''}
      subtitle={
        typeof people !== 'undefined'
          ? // @ts-expect-error
            people?.length + ' with experience'
          : undefined
      }
    >
      <></>
      {mentions?.length === 0 && (
        <EmptyState
          title={formatMessage({
            id: 'app.views.credentials.credential_page.title.no_people_found',
            defaultMessage: 'No people found',
          })}
          subtitle={formatMessage({
            id: 'app.views.credentials.credential_page.subtitle.nobody_at_this_organization_has',
            defaultMessage: 'Nobody at this organization has this credential.',
          })}
        />
      )}
      {mentions?.length > 0 && (
        <Row>
          <Col className="col-12 col-xl-8">
            {mentions.map((mention, mentionIndex) => (
              <div key={mentionIndex} className="card mb-3">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <Avatar className="avatar-md" person={mention.person} />
                    </div>
                    <div className="col ms-n2">
                      <h4 className="mb-1 mt-1">
                        <Link to={mention.person.url}>
                          {mention.person.full_name}
                        </Link>
                      </h4>
                      <p className="text-muted mb-0">
                        {getPersonDisplayTitleHtml(
                          formatMessage,
                          mention.person
                        )}
                      </p>
                    </div>
                    <div className="col-auto text-center">
                      <h1 className="mt-1 mb-n1">{mention.count}</h1>
                      <p className="text-muted mb-0">
                        <FormattedMessage
                          id="app.views.credentials.credential_page.mentions"
                          defaultMessage="Mentions"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      )}
    </Page>
  );
};

const CredentialPage_propTypes = {
  features: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  currentOrganizationId: PropTypes.number.isRequired,
};

type Props = PropTypes.InferProps<typeof CredentialPage_propTypes>;

const mapStateToProps = (state) => {
  const { features, currentProxyPerson, currentOrganization } = state;

  return {
    features,
    currentProxyPerson,
    currentOrganizationId: currentOrganization?.id,
  };
};

export default connect(mapStateToProps)(React.memo(CredentialPage));
