import { ModalBody, ModalHeader } from 'reactstrap';
import React, { FC, useCallback } from 'react';

import Modal from '../../../components/SafeModal';
import PersonTimelineActivities from '../../Person/PersonTimelineActivities';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ModalActivitySelector: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const campaign = props.campaign;

  const onSelect = useCallback(
    (activity) => {
      props.toggle();
      // @ts-expect-error
      props.onSelect(activity);
    },
    [props]
  );

  return (
    <Modal
      isOpen={props.isOpen}
      // @ts-expect-error
      onClosed={props.onClosed}
      toggle={props.toggle}
      className="modal-activity-selector"
    >
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody className="p-0">
        <PersonTimelineActivities
          campaign={campaign}
          hideHeader={true}
          hidePeople={true}
          aggregateCampaignActivities={true}
          // @ts-expect-error
          collapseActivitiesBeforeDate={props.startDate}
          showAddToProfileButton={true}
          addButtonText={props.selectActivityText}
          // @ts-expect-error
          person={props.person}
          hideActivitiesWithoutFeedbackForFocalPerson={false}
          showFeedback={true}
          isUneditablePerfResumeMode={true}
          showManagerOnlyPerformanceDetails={
            // @ts-expect-error
            props.showManagerOnlyPerformanceDetails
          }
          selectButtonText={formatMessage({
            id: 'app.views.widgets.modals.modal_activity_selector.selectButtonText.import_this_activity',
            defaultMessage: 'Import this activity',
          })}
          onSelect={onSelect}
          // @ts-expect-error
          omit={props.omit}
        />
      </ModalBody>
    </Modal>
  );
};

const ModalActivitySelector_propTypes = {
  campaign: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onClosed: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  confirmationButtonColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  selectActivityText: PropTypes.string,
  cancelText: PropTypes.string,
  validationErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  omit: PropTypes.arrayOf(PropTypes.number),
};

type Props = PropTypes.InferProps<typeof ModalActivitySelector_propTypes>;

export default React.memo(ModalActivitySelector);
