import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CSVLink } from 'react-csv';

// This is a Async version of the CSVLink component
// Since this issue https://github.com/react-csv/react-csv/issues/350
// will probably never be fixed, we need to create our own version of the CSVLink component

interface Props {
  getData: () => object[];
  headers: HeaderDescriptor[];
  filename: string;
  onDownloadComplete?: () => void;
}

type State = 'READY' | 'IN_PROGRESS';

export type HeaderDescriptor = {
  key: string;
  label: string;
};

const CSVLinkAsync: FC<PropsWithChildren<Props>> = ({
  getData,
  headers,
  filename,
  onDownloadComplete,
}) => {
  const [state, setState] = useState<State>('READY');
  const ref = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(
    undefined
  );

  // for now, we generate the data syncronously, but in the future when
  // we have web workers, we can consider using the generator to generate
  // this data asyncronously
  const data = useMemo(() => getData(), [getData]);

  useEffect(() => {
    if (data) {
      setState('IN_PROGRESS');
    }
  }, [data]);

  useEffect(() => {
    if (state === 'IN_PROGRESS') {
      ref?.current?.link?.click();

      if (onDownloadComplete) {
        onDownloadComplete();
      }
    }
  }, [onDownloadComplete, state]);

  return (
    <CSVLink
      data={data}
      ref={ref}
      headers={headers}
      filename={filename}
      target="_blank"
    />
  );
};

export default CSVLinkAsync;
