import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getRelationships,
  replaceRelationships,
} from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_MAXIMUM_MANDATORY_PEERS,
  PERFORMANCE_FEATURE_MINIMUM_MANDATORY_PEERS,
  getCampaignFeature,
  getCurrentPerformancePreviewPathPrefix,
  getDirectReportsEligibleForFeedback,
  getSelectHighPriorityOnlyPhases,
  getStepNumber,
  perfCampaignCallback,
} from '../../utils/models/Performance';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';

import Avatar from '../Widgets/People/Avatar';
import AvatarGroup from '../Widgets/People/AvatarGroup';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import { Link } from 'react-router-dom';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import { PERFORMANCE_STEP_SELF_PHASE_COMPLETE } from '../../consts/consts';
import PerformancePage from './PerformancePage';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { peopleObjectsAreEqual } from '../../utils/models/Person';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { toPersonIdToSurveyResponseLookup } from 'utils/models/SurveyResponse';

const relationshipTypes = [RELATIONSHIP_TYPES.NEEDS_AS_HIGH_PRIORITY_PEER];
const MAX_MANDATORY_PEERS_DEFAULT = 100000;

const generatePeerReviewersLabelText = (
  mandatoryPeerMinimum: number,
  mandatoryPeerMaximum: number,
  formatMessage,
  p: any
) => {
  if (
    mandatoryPeerMinimum > 0 &&
    mandatoryPeerMaximum !== MAX_MANDATORY_PEERS_DEFAULT
  ) {
    return formatMessage(
      {
        id: 'app.views.performance.performance_step_request_high_priority_peers.label.request_minimum_maximum_peer_reviewers_for_person',
        defaultMessage:
          'Request between {mandatoryPeerMinimum} and {mandatoryPeerMaximum} peer reviewers for {personName}.',
      },
      {
        personName: p.given_name,
        mandatoryPeerMaximum,
        mandatoryPeerMinimum,
      }
    );
  }

  if (
    mandatoryPeerMinimum === 0 &&
    mandatoryPeerMaximum !== MAX_MANDATORY_PEERS_DEFAULT
  ) {
    return formatMessage(
      {
        id: 'app.views.performance.performance_step_request_high_priority_peers.label.request_maximum_only_peer_reviewers_for_person',
        defaultMessage:
          'Request maximum {mandatoryPeerMaximum} peer reviewers for {personName}.',
      },
      {
        personName: p.given_name,
        mandatoryPeerMaximum,
      }
    );
  }

  if (
    mandatoryPeerMinimum > 0 &&
    mandatoryPeerMaximum === MAX_MANDATORY_PEERS_DEFAULT
  ) {
    return formatMessage(
      {
        id: 'app.views.performance.performance_step_request_high_priority_peers.label.request_minumum_only_peer_reviewers_for_person',
        defaultMessage:
          'Request minimum {mandatoryPeerMinimum} peer reviewers for {personName}.',
      },
      {
        personName: p.given_name,
        mandatoryPeerMinimum,
      }
    );
  }

  return formatMessage(
    {
      id: 'app.views.performance.performance_step_request_high_priority_peers.label.request_generic_peer_reviewers_for_person',
      defaultMessage: 'Request peer reviewers for {personName}.',
    },
    {
      personName: p.given_name,
    }
  );
};

const PerformanceStepRequestHighPriorityPeers: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  // eslint-disable-next-line no-undef
  const location = useLocation();
  const history = useHistory();
  const [currentDirectReport, setCurrentDirectReport] = useState(null);

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;
  const propsDemoPeople = props.demoPeople;

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;

  const isOnlySelectingHighPriorityPeers = useMemo(
    () =>
      // @ts-expect-error
      props.currentPerfSurveyResponse?.configs
        ?.is_only_selecting_high_priority_peers,
    [
      // @ts-expect-error
      props.currentPerfSurveyResponse?.configs
        ?.is_only_selecting_high_priority_peers,
    ]
  );

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(data);
        // We mark them as complete if they are only selecting peers
        if (isOnlySelectingHighPriorityPeers) {
          history.push(
            previewPathPrefix +
              PERFORMANCE_STEP_SELF_PHASE_COMPLETE(formatMessage).path
          );
        } else {
          perfCampaignCallback(
            props.me,
            props.currentOrganization,
            campaign,
            history,
            propsDemoPeople,
            data,
            formatMessage
          );
        }
      }
    },
    [
      propsSetCurrentPerfSurveyResponse,
      isOnlySelectingHighPriorityPeers,
      history,
      previewPathPrefix,
      props.me,
      props.currentOrganization,
      campaign,
      propsDemoPeople,
      formatMessage,
    ]
  );

  const mandatoryPeerMinimum = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_MINIMUM_MANDATORY_PEERS
      ) ?? 0,
    [campaign]
  );

  const mandatoryPeerMaximum = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_MAXIMUM_MANDATORY_PEERS
      ) ?? MAX_MANDATORY_PEERS_DEFAULT,
    [campaign]
  );

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (obj?.to_people?.length < mandatoryPeerMinimum) {
        errors['to_people'] = formatMessage(
          {
            id: 'app.views.performance.performance_step_request_high_priority_peers.error.minimum_of_people_selected',
            defaultMessage:
              'You must select at least {count, plural, one {# person} other {# people}}.',
          },
          { count: mandatoryPeerMinimum }
        );
      }

      if (obj?.to_people?.length > mandatoryPeerMaximum) {
        errors['to_people'] = formatMessage(
          {
            id: 'app.views.performance.performance_step_request_high_priority_peers.error.maximum_of_people_selected',
            defaultMessage:
              'You must select at most {count, plural, one {# person} other {# people}}.',
          },
          { count: mandatoryPeerMaximum }
        );
      }

      if (
        obj?.to_people &&
        obj.to_people.findIndex((p) =>
          peopleObjectsAreEqual(p, obj.from_person)
        ) !== -1
      ) {
        errors['to_people'] = formatMessage({
          id: 'app.views.performance.performance_step_request_high_priority_peers.error.cannot_request_peer_to_review_themselves',
          defaultMessage: 'You cannot request a peer to review themselves.',
        });
      }

      const invalidPeople = obj?.to_people?.filter((p) => !p.id);
      if (invalidPeople?.length > 0) {
        if (invalidPeople.length === 1) {
          errors['to_people'] = formatMessage(
            {
              id: 'app.views.performance.performance_step_request_high_priority_peers.error.person_not_found',
              defaultMessage: '"{email}" was not found.',
            },
            { email: invalidPeople[0].email }
          );
        } else {
          errors['to_people'] = formatMessage(
            {
              id: 'app.views.performance.performance_step_request_high_priority_peers.error.people_not_found',
              defaultMessage: 'The following people were not found: {people}',
            },
            { people: invalidPeople.map((p) => '"' + p.email + '"').join(', ') }
          );
        }
      }

      return errors;
    },
    [mandatoryPeerMaximum, mandatoryPeerMinimum, formatMessage]
  );

  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  const onSubmitHighPriorityPeers = useCallback(
    (data) => {
      if (data?.relationships) {
        // since each from_person is already populated in data.relationships,
        // we don't need to do anything special here for demo mode
        setCampaign(
          replaceRelationships(
            campaign,
            relationshipTypes,
            data.relationships,
            currentDirectReport
          )
        );
      }
    },
    [setCampaign, campaign, currentDirectReport]
  );

  const getNominatedPeopleForPerson = useCallback(
    (p) => {
      return getRelationships(
        campaign,
        RELATIONSHIP_TYPES.NEEDS_AS_HIGH_PRIORITY_PEER
      )
        .filter((r) => peopleObjectsAreEqual(r.from_person, p))
        .map((r) => r.to_person);
    },
    [campaign]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      setCurrentDirectReport(object.from_person);
      const fromPersonId = object.from_person.id;

      return {
        // @ts-expect-error
        campaign: isDemoOrPreviewMode ? undefined : campaign.id,
        types: isDemoOrPreviewMode ? undefined : relationshipTypes,
        // we subtract 1 as completing this step only happens when clicking continue above
        step: isDemoOrPreviewMode ? undefined : currentStepNumber - 1,
        from_person: isDemoOrPreviewMode ? object.from_person : fromPersonId, // need from person in case list is blank (cleared out)
        relationships: object?.to_people.map((p) => ({
          from_person: isDemoOrPreviewMode ? object.from_person : fromPersonId,
          to_person: isDemoOrPreviewMode ? p : p.id,
          type: RELATIONSHIP_TYPES.NEEDS_AS_HIGH_PRIORITY_PEER,
        })),
      };
    },
    // @ts-expect-error
    [campaign.id, currentStepNumber, isDemoOrPreviewMode]
  );

  const [isOpenPeerApprovalIndex, setIsOpenPeerApprovalIndex] = useState(-1);

  const directReports = getDirectReportsEligibleForFeedback(
    props.me,
    campaign,
    // @ts-expect-error
    campaign.relationships,
    // @ts-expect-error
    toPersonIdToSurveyResponseLookup(campaign.survey_responses),
    props.demoPeople
  );

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? // @ts-expect-error
          props.currentPerfSurveyResponse.id
        : undefined,
      // @ts-expect-error
      campaign: campaign.id,
      step: currentStepNumber,
      responses: {},
    }),
    // @ts-expect-error
    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse]
  );

  const directReportsRemaining = useMemo(
    () =>
      directReports.reduce((total, p) => {
        const nominatedPeopleCount = getNominatedPeopleForPerson(p)?.length;
        if (
          nominatedPeopleCount < mandatoryPeerMinimum ||
          nominatedPeopleCount > mandatoryPeerMaximum
        ) {
          return total + 1;
        }
        return total;
      }, 0),
    [
      directReports,
      getNominatedPeopleForPerson,
      mandatoryPeerMaximum,
      mandatoryPeerMinimum,
    ]
  );

  const sufficientPeers = directReportsRemaining === 0;

  const proceedButton = useMemo(() => {
    if (sufficientPeers) {
      return (
        <ValidatedForm
          buttonClassName="mt-0"
          method={object.id ? 'PATCH' : 'POST'}
          url={isDemoOrPreviewMode ? undefined : 'survey-responses'}
          buttonIsBlock={false}
          object={object}
          inputs={[]}
          callback={callback}
          submitText={formatMessage({
            id: 'app.views.performance.performance_step_request_high_priority_peers.submit_text.save_and_continue',
            defaultMessage: 'Save and continue',
          })}
        />
      );
    } else {
      return (
        <Row>
          <Col>
            <span className="text-muted me-4">
              <FormattedMessage
                id="app.views.performance.performance_step_request_high_priority_peers.direct_reports_remaining"
                defaultMessage="{directReportsRemaining, plural, one { 1 direct report left} other {{directReportsRemaining} direct reports left}}"
                values={{ directReportsRemaining }}
              />
            </span>
            <Button className="mt-0" color="primary" disabled>
              <FormattedMessage
                id="app.views.performance.performance_step_request_high_priority_peers.button.continue"
                defaultMessage="
              Continue
            "
              />
            </Button>
          </Col>
        </Row>
      );
    }
  }, [
    sufficientPeers,
    object,
    isDemoOrPreviewMode,
    callback,
    directReportsRemaining,
    formatMessage,
  ]);

  // We show custom phases in case someone is only selecting high
  // priority peers
  const phases = useMemo(() => {
    if (isOnlySelectingHighPriorityPeers) {
      return getSelectHighPriorityOnlyPhases(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        formatMessage
      );
    }
    return null;
  }, [
    isOnlySelectingHighPriorityPeers,
    props.currentOrganization,
    campaign,
    props.demoPeople,
    props.me,
    formatMessage,
  ]);

  return (
    <PerformancePage
      campaign={campaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_request_high_priority_peers.title.request_peer_reviewers_for_your',
        defaultMessage: 'Request peer reviewers for your team.',
      })}
      phases={phases}
      // this page's content is full of cards so we don't need
      // an outer card
      wrapBodyInCard={false}
    >
      {proceedButton}
      <Row>
        <Col className="col-12 col-lg-4">
          <div className="mb-4">
            {mandatoryPeerMinimum > 0 &&
              mandatoryPeerMaximum !== MAX_MANDATORY_PEERS_DEFAULT && (
                <FormattedMessage
                  id="app.views.performance.performance_step_request_high_priority_peers.request_minimum_maximum_present"
                  defaultMessage="You must request between {mandatoryPeerMinimum} and {mandatoryPeerMaximum} reviewers
            for each member of your team. Confirm will strongly weigh your
            preferences when selecting your team members' peer reviewers.
          "
                  values={{
                    mandatoryPeerMaximum,
                    mandatoryPeerMinimum,
                  }}
                />
              )}
            {mandatoryPeerMinimum > 0 &&
              mandatoryPeerMaximum === MAX_MANDATORY_PEERS_DEFAULT && (
                <FormattedMessage
                  id="app.views.performance.performance_step_request_high_priority_peers.request_minimum_only_present"
                  defaultMessage="You must request {mandatoryPeerMinimum, plural, one {# minimum reviewer} other {# minimum reviewers}}
            for each member of your team. Confirm will strongly weigh your
            preferences when selecting your team members' peer reviewers.
          "
                  values={{
                    mandatoryPeerMinimum,
                  }}
                />
              )}
            {mandatoryPeerMinimum === 0 &&
              mandatoryPeerMaximum !== MAX_MANDATORY_PEERS_DEFAULT && (
                <FormattedMessage
                  id="app.views.performance.performance_step_request_high_priority_peers.request_maximum_only_present"
                  defaultMessage="You must request {mandatoryPeerMaximum, plural, one {# maximum reviewer} other {# maximum reviewers}}
            for each member of your team. Confirm will strongly weigh your
            preferences when selecting your team members' peer reviewers.
          "
                  values={{
                    mandatoryPeerMaximum,
                  }}
                />
              )}
          </div>
          <h4>
            <FormattedMessage
              id="app.views.performance.performance_step_request_high_priority_peers.diversity_makes_a_good_peer_group"
              defaultMessage="Diversity makes a good peer group."
            />
          </h4>
          <ul className="list-unstyled">
            <li>
              <i className="fe fe-check text-success me-2"></i>
              <FormattedMessage
                id="app.views.performance.performance_step_request_high_priority_peers.peers_within_and_outside_the_team"
                defaultMessage="Peers within and
              outside the team
            "
              />
            </li>
            <li>
              <i className="fe fe-check text-success me-2"></i>
              <FormattedMessage
                id="app.views.performance.performance_step_request_high_priority_peers.variety_of_expertise_and_skills"
                defaultMessage="Variety of
              expertise and skills
            "
              />
            </li>
            <li>
              <i className="fe fe-check text-success me-2"></i>
              <FormattedMessage
                id="app.views.performance.performance_step_request_high_priority_peers.diversity_of_viewpoints"
                defaultMessage="Diversity of
              viewpoints
            "
              />
            </li>
            <li>
              <i className="fe fe-check text-success me-2"></i>
              <FormattedMessage
                id="app.views.performance.performance_step_request_high_priority_peers.previous_managers"
                defaultMessage="Previous managers
            "
              />
            </li>
          </ul>
        </Col>
        <Col className="col-12 col-lg-8">
          <Card>
            <CardBody>
              <ListGroup className="list-group-flush my-n3">
                {directReports.map((p, index) => {
                  const nominations = getNominatedPeopleForPerson(p);

                  return (
                    <ListGroupItem key={index}>
                      <ModalEditor
                        method="POST"
                        url={
                          isDemoOrPreviewMode ? undefined : 'relationships-list'
                        }
                        title={formatMessage(
                          {
                            id: 'app.views.performance.performance_step_request_high_priority_peers.title.request_peer_reviewers_for_person',
                            defaultMessage:
                              'Request peer reviewers for {personName}',
                          },
                          { personName: p.full_name }
                        )}
                        isOpen={isOpenPeerApprovalIndex === index}
                        toggle={() =>
                          isOpenPeerApprovalIndex === -1
                            ? setIsOpenPeerApprovalIndex(index)
                            : setIsOpenPeerApprovalIndex(-1)
                        }
                        submitText={formatMessage({
                          id: 'app.views.performance.performance_step_request_high_priority_peers.submitText.request_peer_reviewers',
                          defaultMessage: 'Request peer reviewers',
                        })}
                        onValidate={onValidate}
                        callback={onSubmitHighPriorityPeers}
                        transformObjectBeforeSubmit={
                          transformObjectBeforeSubmit
                        }
                        object={{
                          from_person: p,
                          to_people: nominations,
                        }}
                        inputs={[
                          {
                            type: INPUT_TYPES.PEOPLE_EDITOR,
                            isDemoMode: isDemoOrPreviewMode,
                            name: 'to_people',
                            label: generatePeerReviewersLabelText(
                              mandatoryPeerMinimum,
                              mandatoryPeerMaximum,
                              formatMessage,
                              p
                            ),
                            helperText: formatMessage(
                              {
                                id: 'app.views.performance.performance_step_request_high_priority_peers.helper_text.confirm_will_strongly_weigh_your_preferences',
                                defaultMessage:
                                  "Confirm will strongly weigh your preferences when selecting {personName}'s peer reviewers.",
                              },
                              { personName: p.given_name }
                            ),
                          },
                        ]}
                      />
                      <Row className="flex-nowrap">
                        <Col className="col-auto">
                          <Avatar size="sm" person={p} />
                        </Col>
                        <Col>
                          <Row className="p-0">
                            <Col className="ms-n3">
                              <h4 className="mb-1 mt-1 text-dark">
                                {p.url && (
                                  <Link
                                    to={p.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {p.full_name}
                                  </Link>
                                )}
                                {!p.url && p.full_name}
                              </h4>
                              <span className="small text-muted mb-0">
                                {p.title}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="col-auto">
                          {nominations?.length > 0 && (
                            <AvatarGroup
                              className="d-xs-flex"
                              size="sm"
                              people={nominations}
                              isExternalUrl={true}
                            />
                          )}
                        </Col>
                        <Col
                          className="col-auto"
                          onClick={() =>
                            isOpenPeerApprovalIndex === -1
                              ? setIsOpenPeerApprovalIndex(index)
                              : setIsOpenPeerApprovalIndex(-1)
                          }
                        >
                          <Button color="light">
                            <FormattedMessage
                              id="app.views.performance.performance_step_request_high_priority_peers.button.edit"
                              defaultMessage="Edit"
                            />
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PerformancePage>
  );
};

const PerformanceStepRequestHighPriorityPeers_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<
  typeof PerformanceStepRequestHighPriorityPeers_propTypes
>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepRequestHighPriorityPeers)));
