import * as Sentry from '@sentry/browser';

import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { ReduxState } from 'types';
import config from '../../utils/util/config';
import { connect } from 'react-redux';
import { getUserRole } from '../../utils/models/User';
import { log } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';

// @ts-expect-error
const tracking = config.getTracking();

const trackPageview = (location) => {
  if (tracking.useGoogleAnalyticsTracking) {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }

  if (tracking.useConsoleEventTracking) {
    console.log('Page view: ' + location.pathname + location.search);
  }
};

const Analytics: FC<Props> = (props) => {
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [person, setPerson] = useState(null);

  const { user } = useAuth0();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (props.underlyingAuthMe) {
      log('underlyingAuthMe set from server or local storage:');
      log(props.underlyingAuthMe);
      setPerson({
        ...props.underlyingAuthMe,
        // add "first_name" and "last_name" for any analytics platforms that need it
        // instead of give_name/family_name/full_name
        // @ts-expect-error
        first_name: props.underlyingAuthMe.given_name,
        // @ts-expect-error
        last_name: props.underlyingAuthMe.family_name,
      });
    }

    if (props.me) {
      log('me (or proxy) set from server or local storage:');
      log(props.me);
    }
  }, [props.me, props.underlyingAuthMe]);

  useEffect(() => {
    if (!isAnalyticsInitialized && history && location && person && user) {
      const role = getUserRole(user);

      // identify users for analytics software

      if (tracking.useLogRocketEventTracking) {
        // @ts-expect-error
        LogRocket.identify(person.id, {
          // @ts-expect-error
          name: person.full_name,
          role: role,
          // @ts-expect-error
          ...person,
        });
      }

      if (tracking.useGoogleAnalyticsTracking) {
        const gaConfig = {
          titleCase: false,
          gaOptions: {
            // @ts-expect-error
            userId: person.user_id,
          },
        };

        ReactGA.initialize('G-KGSLTCGKLY', gaConfig);

        // @ts-expect-error
        if (!config.isProduction()) {
          // track locally but don't send to GA
          ReactGA.set({ sendHitTask: null });
        }
      }

      // identify user for Sentry
      Sentry.setUser({
        // @ts-expect-error
        id: person.user_id,
        // @ts-expect-error
        email: person.email,
        // @ts-expect-error
        full_name: person.full_name,
      });

      // track history for each pageview change
      history.listen(trackPageview);

      setIsAnalyticsInitialized(true);
      log('Analytics are initialized.');

      // manually track first page
      trackPageview(location);
    }
  }, [isAnalyticsInitialized, history, location, person, user]);

  return <></>;
};

const Analytics_propTypes = {
  me: PropTypes.object,
  underlyingAuthMe: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof Analytics_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  // @ts-expect-error
  const { me, underlyingAuthMe } = state;

  return {
    me,
    underlyingAuthMe,
  };
};

export default connect(mapStateToProps)(React.memo(Analytics));
