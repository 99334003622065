import { BasicPerson, Relationship } from '../../../types';
import React, { FC } from 'react';

import ContentSection from './ContentSection';
import { ICONS } from '../../../consts/consts';
import PersonResponseRow from './PersonResponseRow';
import { getUnattributedPerson } from '../../../utils/models/Person';
import { getUniqueHtmlId } from '../../../utils/util/formatter';
import { useIntl } from 'react-intl';

interface CalloutsBoxProps {
  id?: string;
  title?: string;
  titleType?: string; // do not translate this, since it is used as a key in CALLOUT_MAPPING
  relationships?: Array<Relationship>;
  helperText?: string;
}

const CalloutsBox: FC<CalloutsBoxProps> = ({
  id,
  title,
  titleType,
  relationships,
  helperText,
}) => {
  const { formatMessage } = useIntl();
  const CALLOUT_MAPPING = {
    'Gold stars': {
      skills: 'positive_skills',
      text: 'positive_comments',
      image: ICONS.GOLD_STAR,
    },
    'Heads ups': {
      skills: 'negative_skills',
      text: 'negative_comments',
      image: ICONS.HEADS_UP,
    },
    'Former manager comments': {
      skills: 'positive_skills',
      text: 'positive_comments',
      image: ICONS.FORMER_MANAGER,
    },
  };
  const uniqueId = getUniqueHtmlId();

  return (
    <ContentSection
      id={id ?? uniqueId}
      title={title}
      helperText={helperText}
      body={
        <>
          <div
            className="h3 mb-4 position-absolute"
            style={{ right: '24px', top: '24px' }}
          >
            <span
              className={
                CALLOUT_MAPPING[titleType || '']?.image +
                ' me-2 position-relative'
              }
              style={{ top: '1px' }}
            ></span>
            {relationships?.length ?? 0}
          </div>
          {!!relationships?.length && (
            <div className="mt-4 mb-n3">
              {relationships?.map((r, rIndex, arr) => {
                // Presence of from_person and is_anonymous indicates
                // self-authored heads up, which is shown as Anonymous in UI
                const author: BasicPerson | undefined =
                  r?.from_person && !r?.is_anonymous
                    ? r.from_person
                    : getUnattributedPerson(
                        formatMessage,
                        rIndex,
                        r.type,
                        r?.is_anonymous
                      );

                return (
                  <PersonResponseRow
                    key={rIndex}
                    index={rIndex}
                    author={author}
                    type={r.type}
                    text={r[CALLOUT_MAPPING[titleType || '']?.text]}
                    skills={r[CALLOUT_MAPPING[titleType || '']?.skills]}
                    anonymous={r?.is_anonymous}
                    last={rIndex === arr.length - 1}
                  />
                );
              })}
            </div>
          )}
        </>
      }
    />
  );
};

export default CalloutsBox;
