import { Card, CardBody, Col, Row, UncontrolledPopover } from 'reactstrap';
import {
  FEEDBACK_TYPE_RECOGNITION,
  getFeedbackType,
  getFeedbackVisibilityType,
} from '../../utils/models/Feedback';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import React, { FC, Fragment, useMemo } from 'react';

import Avatar from '../Widgets/People/Avatar';
import AvatarGroup from '../Widgets/People/AvatarGroup';
import LinkedPerson from '../Widgets/People/LinkedPerson';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import RelativeTime from '../Widgets/RelativeTime';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import TagsList from '../Widgets/TagsList';
import { connect } from 'react-redux';
import { customizableSkillAndActivityIndicatorOnCard } from './CustomizableFeedback';
import { findAttachedObjectType } from 'utils/util/util';
import { parseAndFormatQuestionResponse } from '../../utils/models/Performance';

const FeedbackCard: FC<Props> = ({ isDemoOrPreviewMode = false, ...props }) => {
  const { formatMessage, locale } = useIntl();
  const feedback = props.feedback;
  // @ts-expect-error
  const author = feedback.author_person;
  // @ts-expect-error
  const subjectPeople = feedback.subject_people;
  const type = getFeedbackType(feedback, formatMessage);
  const visibility = getFeedbackVisibilityType(feedback, formatMessage);

  const customQuestions = useMemo(() => {
    // @ts-expect-error
    return feedback?.feedback_request?.custom_questions;
    // @ts-expect-error
  }, [feedback?.feedback_request?.custom_questions]);

  const customResponses = useMemo(() => {
    // @ts-expect-error
    return feedback?.custom_responses;
    // @ts-expect-error
  }, [feedback?.custom_responses]);

  const feedbackCardBody = useMemo(
    () => (
      <Row>
        <Col>
          <Row>
            <Col>
              {type?.id === FEEDBACK_TYPE_RECOGNITION(formatMessage).id &&
                '🎉 '}
              {type?.id !== FEEDBACK_TYPE_RECOGNITION(formatMessage).id && (
                // @ts-expect-error
                <i className={'fe ' + type.icon + ' me-2'} />
              )}
              <FormattedMessage
                id="app.views.feedback.feedback_card.action"
                defaultMessage="<headline>{author} {typeName, select, recognition {recognized} feedback {gave feedback to} other {noted about}} {subjects}</headline><secondary>{date}{activitiesOrSkills, select, null {} other { for } }{activitiesOrSkills}</secondary>"
                values={{
                  headline: (chunks) => (
                    <span className="mb-1 mt-1 fw-normal">{chunks}</span>
                  ),
                  secondary: (chunks) => (
                    <span className="d-block text-muted small mb-2">
                      {chunks}
                      <>
                        {' '}
                        <span
                          // @ts-expect-error
                          id={'visibility-feedback-' + feedback.id}
                          style={{ position: 'relative', top: '1px' }}
                          // @ts-expect-error
                          className={'ms-1 ' + visibility.icon}
                        ></span>
                        <UncontrolledPopover
                          placement="top"
                          trigger="hover"
                          // @ts-expect-error
                          target={'visibility-feedback-' + feedback.id}
                        >
                          {/* @ts-expect-error */}
                          {visibility.popoverContent}
                        </UncontrolledPopover>
                      </>
                    </span>
                  ),
                  author: <LinkedPerson person={author} />,
                  // @ts-expect-error
                  typeName: type.name,
                  subjects: (
                    <FormattedList
                      value={subjectPeople.map((person, index) => {
                        return (
                          <span key={index}>
                            <LinkedPerson
                              person={person}
                              isExternalUrl={false}
                            />
                          </span>
                        );
                      })}
                    />
                  ),
                  date: (
                    // @ts-expect-error
                    <RelativeTime unit="day" datetime={feedback.created_at} />
                  ),
                  activitiesOrSkills:
                    customizableSkillAndActivityIndicatorOnCard({
                      attachedObjectType: findAttachedObjectType(
                        // @ts-expect-error
                        props.attachedContentTypes,
                        // @ts-expect-error
                        feedback?.content_type
                      ),
                      // @ts-expect-error
                      subjectPeople: feedback?.subject_people,
                    }) ??
                    // @ts-expect-error
                    (feedback?.activities?.length > 0 ||
                    // @ts-expect-error
                    feedback?.skills?.length > 0 ? (
                      <TagsList
                        className="d-inline"
                        style={{ position: 'relative', top: '-1px' }}
                        // @ts-expect-error
                        activities={feedback.activities}
                        // @ts-expect-error
                        skills={feedback.skills}
                        isExternalUrl={props.isExternalUrl}
                        truncated={true}
                        isDemoOrPreviewMode={isDemoOrPreviewMode}
                      />
                    ) : null),
                }}
              />
            </Col>
          </Row>
          <div
            className={'comment-body d-block mt-2 px-3'}
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            {/* @ts-expect-error */}
            {feedback.comments && (
              <div className="mb-0 pt-2 pb-1">
                {/* @ts-expect-error */}
                <RichTextViewer model={feedback.comments} expanded={true} />
              </div>
            )}
            {Array.isArray(customQuestions) && customQuestions?.length > 0 && (
              <>
                {customQuestions.map((q, index) => (
                  <div key={index} className="mb-2 pt-2 pb-1">
                    <h4 className="mb-0">
                      <RichTextViewer model={q.label} expanded={true} />
                    </h4>
                    {parseAndFormatQuestionResponse({
                      q: {
                        question: q,
                        response: customResponses?.[q.name],
                      },
                      // @ts-expect-error
                      noneElement: '-',
                      locale,
                      formatMessage,
                    })}
                  </div>
                ))}
              </>
            )}
          </div>
        </Col>
      </Row>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      author,
      customQuestions,
      // @ts-expect-error
      feedback.activities,
      // @ts-expect-error
      feedback.comments,
      // @ts-expect-error
      feedback.created_at,
      customResponses,
      // @ts-expect-error
      feedback.id,
      // @ts-expect-error
      feedback.skills,
      // @ts-expect-error
      feedback?.subject_people,
      // @ts-expect-error
      feedback?.content_type,
      props.attachedContentTypes,
      props.isExternalUrl,
      subjectPeople,
      type,
      // @ts-expect-error
      visibility.icon,
      // @ts-expect-error
      visibility.popoverContent,
      formatMessage,
      locale,
    ]
  );

  const wrappedBody = useMemo(
    () => (
      <Row
        className={
          (props.className ? props.className + ' ' : '') + 'feedback-card'
        }
      >
        {props.showAvatars && (
          <>
            <Col className="col-auto pe-2">
              <Avatar
                className="avatar-sm mt-2"
                person={author}
                linked
                // @ts-expect-error
                isExternalUrl={props.isExternalUrl}
              />
            </Col>
            <Col className="col-auto mt-2 pt-2 px-0">
              <i className="fe fe-arrow-right" />
            </Col>
            <Col className="col-auto ps-2 me-n3">
              <AvatarGroup
                format="circle"
                className="mt-2"
                size="sm"
                people={subjectPeople}
                isDemoOrPreviewMode={isDemoOrPreviewMode}
              />
            </Col>
          </>
        )}
        <Col className="col-12 col-md pt-3 pt-md-0">{feedbackCardBody}</Col>
      </Row>
    ),
    [
      author,
      feedbackCardBody,
      isDemoOrPreviewMode,
      props.className,
      props.isExternalUrl,
      props.showAvatars,
      subjectPeople,
    ]
  );

  const output = useMemo(() => {
    // @ts-expect-error
    if (props.bodyOnly) {
      return wrappedBody;
    } else {
      return (
        <Card>
          <CardBody>{wrappedBody}</CardBody>
        </Card>
      );
    }
    // @ts-expect-error
  }, [props.bodyOnly, wrappedBody]);

  return output;
};

const FeedbackCard_propTypes = {
  className: PropTypes.string,
  isExternalUrl: PropTypes.bool,
  feedback: PropTypes.object.isRequired,
  showAvatars: PropTypes.bool,
  attachedContentTypes: PropTypes.object.isRequired,
  isDemoOrPreviewMode: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof FeedbackCard_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { attachedContentTypes } = state;

  return {
    attachedContentTypes,
  };
};

export default connect(mapStateToProps)(React.memo(FeedbackCard));
