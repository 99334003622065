import * as consts from '../../consts/consts';

import {
  ALL_REQUEST_VISIBILITIES,
  CONSTRUCTIVE_FEEDBACK_VISIBILITIES,
  FEEDBACK_REQUEST_VISIBILITY_MANAGER_ONLY,
  FEEDBACK_REQUEST_VISIBILITY_NOT_SET,
  FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_ONLY,
  FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_WITH_MANAGER,
  FEEDBACK_TYPE_ACTIONABLE,
  FEEDBACK_TYPE_NOTE,
  FEEDBACK_TYPE_RECOGNITION,
  FEEDBACK_TYPE_REQUEST,
  FEEDBACK_VISIBILITY_AUTHOR_ONLY,
  FEEDBACK_VISIBILITY_EVERYONE,
  FEEDBACK_VISIBILITY_MANAGER_ONLY,
  FEEDBACK_VISIBILITY_RECIPIENT_ONLY,
  FEEDBACK_VISIBILITY_RECIPIENT_WITH_MANAGER,
  RECOGNITION_VISIBILITIES,
  getDefaultFeedbackVisibilityValue,
  getFeedbackRequestVisibilityType,
  getFeedbackVisibilityType,
} from '../../utils/models/Feedback';
import { AttachedContentTypes, Me, Organization, ReduxState } from 'types';
import { Button, Card, Col, Row, UncontrolledPopover } from 'reactstrap';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import {
  INPUT_ATTRIBUTES,
  INPUT_TYPES,
} from '../Widgets/Inputs/ValidatedInputTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  continuousFeedbackGivingFeedbackIsEnabled,
  continuousFeedbackPrivateNotesAreEnabled,
  continuousFeedbackRecognitionIsEnabled,
  continuousFeedbackRequestCustomQuestionsAreEnabled,
  continuousFeedbackRequestsAreEnabled,
} from '../../utils/util/features';
import {
  findAttachedObjectType,
  prepTagsForSubmit,
} from '../../utils/util/util';
import {
  getInvalidPeopleError,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import { DEFAULT_FEEDBACK_QUESTION_PROPERTIES } from './consts';
import FeedbackRequestCard from './FeedbackRequestCard';
import ModalEditorButton from '../Widgets/Modals/ModalEditorButton';
import { connect } from 'react-redux';
import { customizationsForSpecificFeedbackType } from './CustomizableFeedback';
import { loadTasks } from '../../actions';
import {
  getAutoFocusIndex,
  prepareOpenResponseQuestion,
} from '../../utils/models/Performance';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

const getTopics = (activities = [], skills = []) => {
  return [
    ...activities.map((a) => ({
      // @ts-expect-error
      ...a,
      _index: 'activities',
    })),
    ...skills.map((s) => ({
      // @ts-expect-error
      ...s,
      _index: 'skills',
    })),
  ];
};

const getEditableFeedbackFromRawFeedback = (
  rawFeedback = null,
  rawFeedbackRequest = null,
  feedback_visibility,
  formatMessage
) => {
  // prepare feedback object from provided request
  if (rawFeedbackRequest) {
    const { subject_people, requested_people, activities, skills } =
      rawFeedbackRequest;

    return {
      type: FEEDBACK_TYPE_ACTIONABLE(formatMessage),
      subject_people,
      requested_people,
      // convert activities and skills to "topics"
      topics: getTopics(activities, skills),
      // associated with this feedback request
      feedback_request: rawFeedbackRequest,
      visibility:
        // @ts-expect-error
        rawFeedbackRequest.feedback_visibility !==
        FEEDBACK_REQUEST_VISIBILITY_NOT_SET(formatMessage).id
          ? // @ts-expect-error
            rawFeedbackRequest.feedback_visibility
          : undefined,
      // @ts-expect-error
      object_id: rawFeedbackRequest?.object_id,
      // @ts-expect-error
      content_type: rawFeedbackRequest?.content_type,
    };
  } else {
    // pre-populated based on passed-in feedback
    const {
      // @ts-expect-error
      id,
      // @ts-expect-error
      type,
      // @ts-expect-error
      visibility,
      // @ts-expect-error
      subject_people,
      // @ts-expect-error
      requested_people,
      // @ts-expect-error
      comments,
      // @ts-expect-error
      activities,
      // @ts-expect-error
      skills,
      // @ts-expect-error
      object_id,
      // @ts-expect-error
      content_type,
      // @ts-expect-error
      custom_questions,
    } = rawFeedback;

    return {
      id,
      type,
      visibility,
      subject_people,
      requested_people,
      comments,
      feedback_visibility: feedback_visibility ?? undefined,
      // convert activities and skills to "topics"
      topics: getTopics(activities, skills),
      object_id,
      content_type,
      custom_questions,
    };
  }
};

interface Props {
  modalTitle?: string;
  buttonText?: string;
  feedback?: object;
  anchorTrigger?: string;
  isOpen?: boolean;
  toggle?: () => void;
  onOpened?: () => void;
  onClosed?: () => void;
  inline?: boolean;
  buttonClassName?: string;
  me: Me;
  currentOrganization: Organization;
  hideButton?: boolean;
  callback?: (data: object) => void;
  feedbackRequest?: object;
  forceRequestFeedback?: object;
  transformObjectBeforeSubmit?: (obj: object) => void;
  attachedContentTypes: AttachedContentTypes;
  enableCustomQuestionsCreation?: boolean;
  customQuestions?: object[];
}

const ModalFeedbackEditorButton: FC<Props> = ({
  enableCustomQuestionsCreation = true,
  ...props
}) => {
  const { user } = useAuth0();
  const userSub = user?.sub;
  const intl = useIntl();
  const { formatMessage } = intl;

  const recognitionIsEnabled = continuousFeedbackRecognitionIsEnabled(
    // @ts-expect-error
    props.features
  );
  const privateNotesAreEnabled = continuousFeedbackPrivateNotesAreEnabled(
    // @ts-expect-error
    props.features
  );
  const givingFeedbackIsEnabled = continuousFeedbackGivingFeedbackIsEnabled(
    // @ts-expect-error
    props.features
  );
  const requestingFeedbackIsEnabled = continuousFeedbackRequestsAreEnabled(
    // @ts-expect-error
    props.features
  );
  const requestingFeedbackCustomQuestionsAreEnabled =
    // @ts-expect-error
    continuousFeedbackRequestCustomQuestionsAreEnabled(props.features);

  const [showCustomQuestions, setShowCustomQuestions] = useState(false);

  const propsButtonText =
    props.buttonText ||
    intl.formatMessage({
      id: 'app.feedback.modal_feedback_editor.add_feedback.button.text',
      defaultMessage: 'Add feedback or recognition',
    });
  const forceRequestFeedbackVisibility =
    // @ts-expect-error
    props?.forceRequestFeedback?.feedback_visibility;

  const [feedback, setFeedback] = useState(
    props.feedback || props.feedbackRequest
      ? getEditableFeedbackFromRawFeedback(
          // @ts-expect-error
          props.feedback,
          props.feedbackRequest,
          forceRequestFeedbackVisibility,
          formatMessage
        )
      : {}
  );

  const attachedObjectType = useMemo(
    () =>
      findAttachedObjectType(
        props.attachedContentTypes,
        // @ts-expect-error
        feedback?.content_type
      ),
    // @ts-expect-error
    [feedback?.content_type, props.attachedContentTypes]
  );

  const { customInfoSection, requireSkillsAndActivities, disabledFields } =
    useMemo(() => {
      return customizationsForSpecificFeedbackType({
        attachedObjectType,
        feedback,
      });
    }, [attachedObjectType, feedback]);

  useEffect(() => {
    setFeedback(
      props.feedback || props.feedbackRequest
        ? getEditableFeedbackFromRawFeedback(
            // @ts-expect-error
            props.feedback,
            props.feedbackRequest,
            forceRequestFeedbackVisibility,
            formatMessage
          )
        : {}
    );
  }, [
    props.feedback,
    props.feedbackRequest,
    formatMessage,
    forceRequestFeedbackVisibility,
  ]);

  const resetModalData = useCallback(() => {
    setFeedback(
      props.feedback || props.feedbackRequest
        ? getEditableFeedbackFromRawFeedback(
            // @ts-expect-error
            props.feedback,
            props.feedbackRequest,
            forceRequestFeedbackVisibility,
            formatMessage
          )
        : {}
    );
  }, [
    props.feedback,
    props.feedbackRequest,
    forceRequestFeedbackVisibility,
    formatMessage,
  ]);

  const setFeedbackType = useCallback(
    (type) => {
      // if type set to request and subject_people not populated,
      // default it to self
      setFeedback({
        ...feedback,
        type,
        subject_people:
          type?.id !== FEEDBACK_TYPE_REQUEST(formatMessage).id ||
          // @ts-expect-error
          feedback.subject_people?.length > 0
            ? // ensure subject doesn't include self for non-requests
              // as you shouldn't be able to write about yourself
              // @ts-expect-error
              feedback.subject_people?.filter(
                (p) => !peopleObjectsAreEqual(p, props.me)
              )
            : [props.me],
      });
    },
    [feedback, props.me, formatMessage]
  );

  const toFormattedList = (people) => {
    return (
      <FormattedList
        value={people?.map((e, i) => <span key={i}>{e.full_name}</span>) ?? []}
      />
    );
  };

  const postscript = useMemo(() => {
    if (
      // @ts-expect-error
      feedback?.type?.id === FEEDBACK_TYPE_RECOGNITION(formatMessage).id &&
      // @ts-expect-error
      getFeedbackVisibilityType(feedback, formatMessage).id ===
        FEEDBACK_VISIBILITY_EVERYONE(formatMessage).id
    ) {
      // Special case for public recognition.
      return formatMessage(
        {
          id: 'app.views.feedback.modal_feedback_editor_button.everyone_will_see',
          defaultMessage:
            'Everyone at {organizationName, select, noorg {your company} other {{organizationName}}} will be able to see this recognition.',
        },
        {
          organizationName: props.currentOrganization?.name || 'noorg',
        }
      );
    }

    let postscript = '';
    const names =
      // @ts-expect-error
      feedback?.subject_people?.length > 0
        ? // @ts-expect-error
          toFormattedList(feedback?.subject_people)
        : null;

    if (
      getFeedbackVisibilityType(feedback, formatMessage)?.id ===
      FEEDBACK_VISIBILITY_RECIPIENT_ONLY(formatMessage).id
    ) {
      // @ts-expect-error
      postscript = (
        <FormattedMessage
          id="app.views.feedback.modal_feedback_editor_button.only_names_will_see"
          defaultMessage="Only {names, select, null {the individual} other {{names}}} will be able to see this feedback."
          values={{ names }}
        />
      );
    } else if (
      getFeedbackVisibilityType(feedback, formatMessage)?.id ===
      FEEDBACK_VISIBILITY_RECIPIENT_WITH_MANAGER(formatMessage).id
    ) {
      // @ts-expect-error
      postscript = (
        <FormattedMessage
          id="app.views.feedback.modal_feedback_editor_button.names_and_their_managers_will_see"
          defaultMessage="{names, select, null {The individual} other {{names}}} and their manager(s) and above, and HR administrators, will be able to see this feedback."
          values={{ names }}
        />
      );
    } else if (
      getFeedbackVisibilityType(feedback, formatMessage)?.id ===
      FEEDBACK_VISIBILITY_MANAGER_ONLY(formatMessage).id
    ) {
      // @ts-expect-error
      postscript = (
        <FormattedMessage
          id="app.views.feedback.modal_feedback_editor_button.only_managers_will_see"
          defaultMessage="Only {names, select, null {the individual's manager and above} other {the manager(s) and above of {names}}}, and HR administrators, will be able to see this feedback."
          values={{ names }}
        />
      );
    }

    return postscript;
  }, [feedback, props.currentOrganization, formatMessage]);

  const feedbackRequestPostscript = useMemo(() => {
    // @ts-expect-error
    if (feedback?.type?.id !== FEEDBACK_TYPE_REQUEST(formatMessage).id) {
      return '';
    }

    let postscript = '';
    const names =
      // @ts-expect-error
      feedback?.subject_people?.length > 0
        ? // @ts-expect-error
          toFormattedList(feedback?.subject_people)
        : null;

    const requestedNames =
      // @ts-expect-error
      feedback?.requested_people?.length > 0
        ? // @ts-expect-error
          toFormattedList(feedback?.requested_people)
        : null;

    if (
      getFeedbackRequestVisibilityType(feedback, formatMessage)?.id ===
      FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_ONLY(formatMessage).id
    ) {
      // @ts-expect-error
      postscript = (
        <FormattedMessage
          id="app.views.feedback.modal_feedback_editor_button.feedback_request.only_names_will_see"
          defaultMessage="Only {names, select, null {the individual} other {{names}}} will be able to see this feedback."
          values={{ names }}
        />
      );
    } else if (
      getFeedbackRequestVisibilityType(feedback, formatMessage)?.id ===
      FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_WITH_MANAGER(formatMessage).id
    ) {
      // @ts-expect-error
      postscript = (
        <FormattedMessage
          id="app.views.feedback.modal_feedback_editor_button.feedback_request.names_and_their_managers_will_see"
          defaultMessage="{names, select, null {The individual} other {{names}}} and their manager(s) and above, and HR administrators, will be able to see this feedback."
          values={{ names }}
        />
      );
    } else if (
      getFeedbackRequestVisibilityType(feedback, formatMessage)?.id ===
      FEEDBACK_REQUEST_VISIBILITY_MANAGER_ONLY(formatMessage).id
    ) {
      // @ts-expect-error
      postscript = (
        <FormattedMessage
          id="app.views.feedback.modal_feedback_editor_button.feedback_request.only_managers_will_see"
          defaultMessage="Only {names, select, null {the individual's manager and above} other {the manager(s) and above of {names}}}, and HR administrators, will be able to see this feedback."
          values={{ names }}
        />
      );
    } else if (
      getFeedbackRequestVisibilityType(feedback, formatMessage)?.id ===
      FEEDBACK_REQUEST_VISIBILITY_NOT_SET(formatMessage).id
    ) {
      // @ts-expect-error
      postscript = (
        <FormattedMessage
          id="app.views.feedback.modal_feedback_editor_button.feedback_request.author_will_set"
          defaultMessage="{names, select, null {the individual} other {{names}}} will decide who can see this feedback."
          values={{ names: requestedNames }}
        />
      );
    }

    return postscript;
  }, [feedback, formatMessage]);

  const customQuestionsList = useMemo(() => {
    // @ts-expect-error
    if (!feedback?.feedback_request?.custom_questions) {
      return null;
    }

    // @ts-expect-error
    const names = toFormattedList(feedback?.subject_people);

    const autoFocusIndex = getAutoFocusIndex(
      // @ts-expect-error
      feedback?.feedback_request?.custom_questions
    );

    // @ts-expect-error
    return feedback?.feedback_request?.custom_questions.map((q, index) =>
      prepareOpenResponseQuestion(
        formatMessage,
        q,
        [],
        false,
        names,
        false,
        false,
        null,
        props.currentOrganization,
        autoFocusIndex === index,
        index,
        false
      )
    );
  }, [
    // @ts-expect-error
    feedback?.feedback_request?.custom_questions,
    // @ts-expect-error
    feedback?.subject_people,
    formatMessage,
    props.currentOrganization,
  ]);

  // @ts-expect-error
  const feedbackCustomQuestions = feedback?.custom_questions;

  const FEEDBACK_INPUTS = useMemo(() => {
    const inputs = {};

    if (recognitionIsEnabled) {
      inputs[FEEDBACK_TYPE_RECOGNITION(formatMessage).id] = [
        {
          required: true,
          name: 'subject_people',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.subject_people"
              defaultMessage="Who are you recognizing?"
            />
          ),
          type: INPUT_TYPES.PEOPLE_EDITOR,
          // @ts-expect-error
          autoFocus: !(feedback?.subject_people?.length > 0),
          // we allow self-recognition, e.g. if someone wants to recognize an achievement
          // they made and ensure it shows up for their next perf cycle
          allowSelf: true,
        },
        {
          required: true,
          name: 'visibility',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.visibility"
              defaultMessage="Who can see this recognition?"
            />
          ),
          type: INPUT_TYPES.BUTTON_OPTIONS,
          className: 'w-100',
          buttonClassName: 'btn-sm',
          selectedButtonClassName: 'btn-sm',
          options: RECOGNITION_VISIBILITIES(formatMessage),
          defaultValue: getDefaultFeedbackVisibilityValue(
            FEEDBACK_TYPE_RECOGNITION(formatMessage),
            formatMessage
          ),
          postscript: postscript,
        },
        {
          required: true,
          name: 'comments',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.comments"
              defaultMessage="What is your recognition?"
            />
          ),
          type: INPUT_TYPES.RICH_TEXT_EDITOR,
          placeholder: formatMessage({
            id: 'app.feedback.modal_feedback_editor_button.placeholder.comments',
            defaultMessage: 'Thank you for...',
          }),

          multiLine: true,
          minRows: 3,
          // tags go after this input for recognition, so autofocus regardless
          // of topics provided
          // @ts-expect-error
          autoFocus: feedback?.subject_people?.length > 0,
        },
        ...(requireSkillsAndActivities
          ? [
              {
                ...INPUT_ATTRIBUTES(formatMessage).SKILLS_AND_ACTIVITIES,
                required: true,
                name: 'topics',
                label: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.label.topics"
                    defaultMessage="Tag skills or activities that earned this recognition."
                  />
                ),
                helperText: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.helper_text.topics"
                    defaultMessage="Choose any skills or existing activities."
                  />
                ),
                type: INPUT_TYPES.TAGS_INPUT,
                // tags go after this input for recognition, so do not autofocus regardless
                // of topics provided
                autoFocus: false,
              },
            ]
          : []),
        ...customInfoSection,
      ];
    }

    if (givingFeedbackIsEnabled) {
      inputs[FEEDBACK_TYPE_ACTIONABLE(formatMessage).id] = [
        {
          required: true,
          name: 'subject_people',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.subject_people_actionable"
              defaultMessage="Who is this feedback about?"
            />
          ),
          type: INPUT_TYPES.PEOPLE_EDITOR,
          // @ts-expect-error
          autoFocus: !(feedback?.subject_people?.length > 0),
          // allow self-reflections (use case: a company wants periodic self reflections; if
          // via custom questions, it requires sending a feedback request to yourself which
          // we are also allowing for this use case)
          allowSelf: true,
          disabled: disabledFields.includes('subject_people'),
        },
        // @ts-expect-error
        ...(!feedback.feedback_request ||
        // @ts-expect-error
        feedback.feedback_request?.feedback_visibility ===
          FEEDBACK_REQUEST_VISIBILITY_NOT_SET(formatMessage).id
          ? [
              {
                required: true,
                name: 'visibility',
                label: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.label.visibility_actionable"
                    defaultMessage="Who can see this feedback?"
                  />
                ),
                type: INPUT_TYPES.BUTTON_OPTIONS,
                className: 'w-100',
                buttonClassName: 'btn-sm',
                selectedButtonClassName: 'btn-sm',
                options: CONSTRUCTIVE_FEEDBACK_VISIBILITIES(formatMessage),
                defaultValue: getDefaultFeedbackVisibilityValue(
                  FEEDBACK_TYPE_ACTIONABLE(formatMessage),
                  formatMessage
                ),
                postscript: postscript,
              },
            ]
          : [
              {
                type: INPUT_TYPES.CUSTOM_INPUT,
                name: 'visibility',
                // @ts-expect-error
                value: feedback.feedback_request?.feedback_visibility,
                component: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.label.visibility_preset"
                    defaultMessage="NOTE: <strong>{person}</strong> has set your feedback to be visible to <strong>{visibility}</strong>."
                    values={{
                      strong: (chunks) => <strong>{chunks}</strong>,
                      person:
                        // @ts-expect-error
                        feedback.feedback_request?.author_person?.full_name,
                      visibility: ALL_REQUEST_VISIBILITIES(formatMessage).find(
                        (v) =>
                          v.id ===
                          // @ts-expect-error
                          feedback.feedback_request?.feedback_visibility
                      )?.name,
                    }}
                  />
                ),
                postscript: postscript,
              },
            ]),
        ...(requireSkillsAndActivities
          ? [
              {
                ...INPUT_ATTRIBUTES(formatMessage).SKILLS_AND_ACTIVITIES,
                required: true,
                name: 'topics',
                label: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.label.topics_actionable"
                    defaultMessage="Tag skills or activities that this feedback is about."
                  />
                ),
                helperText: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.helper_text.topics_actionable"
                    defaultMessage="Choose any skills or existing activities."
                  />
                ),
                type: INPUT_TYPES.TAGS_INPUT,
                autoFocus:
                  // @ts-expect-error
                  feedback?.subject_people?.length > 0 &&
                  // @ts-expect-error
                  !(feedback?.topics?.length > 0),
              },
            ]
          : []),
        ...customInfoSection,
        ...(customQuestionsList?.length > 0
          ? customQuestionsList.map((q, index) => ({
              ...q,
              autoFocus: index === 0,
              name: `custom_responses_${q.name}`,
            }))
          : [
              {
                name: 'comments',
                ...DEFAULT_FEEDBACK_QUESTION_PROPERTIES(formatMessage),
                required: customQuestionsList ? false : true,
                autoFocus:
                  // @ts-expect-error
                  feedback?.subject_people?.length > 0 &&
                  // @ts-expect-error
                  feedback?.topics?.length > 0,
              },
            ]),
      ];
    }

    if (requestingFeedbackIsEnabled) {
      inputs[FEEDBACK_TYPE_REQUEST(formatMessage).id] = [
        {
          required: true,
          name: 'requested_people',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.requested_people_feedback"
              defaultMessage="Who do you want to ask for feedback?"
            />
          ),
          type: INPUT_TYPES.PEOPLE_EDITOR,
          // @ts-expect-error
          autoFocus: !(feedback?.requested_people?.length > 0),
          // we allow self so someone can create a structured set of questions to answer
          // through question templates
          allowSelf: true,
        },
        {
          required: true,
          name: 'feedback_visibility',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.feedback_visibility"
              defaultMessage="Who can see the provided feedback?"
            />
          ),
          type: INPUT_TYPES.BUTTON_OPTIONS,
          className: 'w-100',
          buttonClassName: 'btn-sm',
          selectedButtonClassName: 'btn-sm',
          options: ALL_REQUEST_VISIBILITIES(formatMessage),
          defaultValue: FEEDBACK_REQUEST_VISIBILITY_NOT_SET(formatMessage).id,
          postscript: feedbackRequestPostscript,
          disabled: disabledFields.includes('feedback_visibility'),
        },
        {
          required: true,
          name: 'subject_people',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.subject_people_feedback"
              defaultMessage="Who is this feedback about?"
            />
          ),
          type: INPUT_TYPES.PEOPLE_EDITOR,
          allowSelf: true,
          autoFocus:
            // @ts-expect-error
            feedback?.requested_people?.length > 0 &&
            // @ts-expect-error
            !(feedback?.subject_people?.length > 0),
          disabled: disabledFields.includes('subject_people'),
        },
        ...(requireSkillsAndActivities
          ? [
              {
                ...INPUT_ATTRIBUTES(formatMessage).SKILLS_AND_ACTIVITIES,
                required: true,
                name: 'topics',
                label:
                  'What activity or skills would you like the feedback to cover?',
                helperText: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.helper_text.topics_feedback"
                    defaultMessage="Choose any skills or existing activities."
                  />
                ),
                type: INPUT_TYPES.TAGS_INPUT,
                autoFocus:
                  // @ts-expect-error
                  feedback?.requested_people?.length > 0 &&
                  // @ts-expect-error
                  feedback?.subject_people?.length > 0 &&
                  // @ts-expect-error
                  !(feedback?.topics?.length > 0),
              },
            ]
          : []),
        ...customInfoSection,
        ...(feedbackCustomQuestions?.length > 0
          ? [
              {
                type: INPUT_TYPES.CUSTOM_INPUT,
                name: 'specific_organization_questions_description',
                component: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.specific_organization_questions_description.text"
                    defaultMessage="This feedback request includes a specific set of questions defined by your organization."
                  >
                    {(text) => (
                      <>
                        <div className="position-relative">
                          {text}
                          <button
                            className="btn btn-link p-0 m-0 b-0"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowCustomQuestions(!showCustomQuestions);
                            }}
                          >
                            {showCustomQuestions
                              ? formatMessage({
                                  id: 'app.feedback.modal_feedback_editor_button.button.hide_questions',
                                  defaultMessage: 'Hide questions',
                                })
                              : formatMessage({
                                  id: 'app.feedback.modal_feedback_editor_button.button.view_questions',
                                  defaultMessage: 'View questions',
                                })}
                          </button>
                        </div>
                        <div></div>
                      </>
                    )}
                  </FormattedMessage>
                ),
              },
            ]
          : []),
        ...(requestingFeedbackCustomQuestionsAreEnabled &&
        enableCustomQuestionsCreation
          ? [
              {
                required: true,
                name: 'ask_custom_questions',
                type: INPUT_TYPES.BUTTON_OPTIONS,
                label: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.label.ask_custom_questions"
                    defaultMessage="Do you want to ask specific questions?"
                  />
                ),
                options: [
                  {
                    id: 'N',
                    name: formatMessage({
                      id: 'app.feedback.modal_feedback_editor_button.button.ask_custom_questions.no',
                      defaultMessage: 'No, just ask "What is your feedback?"',
                    }),
                  },
                  {
                    id: 'Y',
                    name: formatMessage({
                      id: 'app.feedback.modal_feedback_editor_button.button.ask_custom_questions.yes',
                      defaultMessage: 'Yes, ask specific questions.',
                    }),
                  },
                ],
                defaultValue: 'N',
                className: 'w-100',
                summaryLabel: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.summary_label.ask_custom_questions"
                    defaultMessage="test"
                  />
                ),
                buttonClassName: 'btn-sm',
                selectedButtonClassName: 'btn-sm',
              },
            ]
          : []),
        // @ts-expect-error
        ...(feedback?.ask_custom_questions === 'Y' ||
        (feedbackCustomQuestions?.length > 0 && showCustomQuestions)
          ? [
              {
                name: 'custom_questions',
                type: INPUT_TYPES.QUESTIONS_EDITOR,
                // instead of submitting which will close this form,
                // we want to change the button type to a regular button
                submitButtonType: 'button',
                inModal: true,
                disabled: !enableCustomQuestionsCreation,
              },
            ]
          : []),
        {
          name: 'comments',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.comments_feedback"
              defaultMessage="Additional comments (optional)"
            />
          ),
          helperText: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.helper_text.comments_feedback"
              defaultMessage="Provide more explanation about why you are making this request."
            />
          ),
          type: INPUT_TYPES.RICH_TEXT_EDITOR,
          multiLine: true,
          minRows: 3,
          autoFocus:
            // @ts-expect-error
            feedback?.requested_people?.length > 0 &&
            // @ts-expect-error
            feedback?.subject_people?.length > 0 &&
            // @ts-expect-error
            feedback?.topics?.length > 0,
        },
      ];
    }

    if (privateNotesAreEnabled) {
      inputs[FEEDBACK_TYPE_NOTE(formatMessage).id] = [
        {
          required: true,
          name: 'subject_people',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.subject_people_note"
              defaultMessage="Who is this note about?"
            />
          ),
          type: INPUT_TYPES.PEOPLE_EDITOR,
          // someone may want to take a note on themselves (someone
          // wrote in requesting this; trivial to add so adding it)
          allowSelf: true,
          // @ts-expect-error
          autoFocus: !(feedback?.subject_people?.length > 0),
        },
        {
          required: true,
          name: 'comments',
          label: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.label.comments_note"
              defaultMessage="What do you want to remember for later?"
            />
          ),
          type: INPUT_TYPES.RICH_TEXT_EDITOR,
          minRows: 3,
          // tags go after this input for recognition, so do not autofocus regardless
          // of topics provided
          // @ts-expect-error
          autoFocus: feedback?.subject_people?.length > 0,
        },
        ...(requireSkillsAndActivities
          ? [
              {
                ...INPUT_ATTRIBUTES(formatMessage).SKILLS_AND_ACTIVITIES,
                required: true,
                name: 'topics',
                label: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.label.topics_note"
                    defaultMessage="Tag skills or activities that this note is about."
                  />
                ),
                helperText: (
                  <FormattedMessage
                    id="app.feedback.modal_feedback_editor_button.helper_text.topics_note"
                    defaultMessage="Choose any skills or existing activities."
                  />
                ),
                type: INPUT_TYPES.TAGS_INPUT,
                // tags go after this input for recognition, so do not autofocus regardless
                // of topics provided
                autoFocus: false,
              },
            ]
          : []),
        ...customInfoSection,
      ];
    }

    return inputs;
  }, [
    recognitionIsEnabled,
    givingFeedbackIsEnabled,
    requestingFeedbackIsEnabled,
    enableCustomQuestionsCreation,
    showCustomQuestions,
    privateNotesAreEnabled,
    // @ts-expect-error
    feedback?.subject_people?.length,
    // @ts-expect-error
    feedback?.topics?.length,
    // @ts-expect-error
    feedback?.requested_people?.length,
    // @ts-expect-error
    feedback?.ask_custom_questions,
    feedbackCustomQuestions,
    disabledFields,
    postscript,
    formatMessage,
    customQuestionsList,
    customInfoSection,
    requestingFeedbackCustomQuestionsAreEnabled,
    requireSkillsAndActivities,
    // @ts-expect-error
    feedback.feedback_request,
    feedbackRequestPostscript,
  ]);

  const title = useMemo(
    // @ts-expect-error
    () => (feedback?.type?.heading ? feedback?.type?.heading : propsButtonText),
    // @ts-expect-error
    [feedback?.type, propsButtonText]
  );

  const modalTitle = props.modalTitle ?? title;
  const submitText = title;
  const anchorTrigger = props.anchorTrigger;
  const propsOnClosed = props.onClosed;
  const propsCallback = props.callback;

  // @ts-expect-error
  const propsLoadTasks = props.loadTasks;
  const onSubmitCallback = useCallback(
    (data) => {
      if (data) {
        // always show toast in UI, even if callback provided
        if (data.type) {
          if (data.type === FEEDBACK_TYPE_RECOGNITION(formatMessage).id) {
            toast.success(
              formatMessage({
                id: 'app.feedback.modal_feedback_editor_button.toast.recognition_sent',
                defaultMessage: 'Recognition sent!',
              })
            );
          } else if (data.type === FEEDBACK_TYPE_ACTIONABLE(formatMessage).id) {
            toast.success(
              formatMessage({
                id: 'app.feedback.modal_feedback_editor_button.toast.feedback_sent',
                defaultMessage: 'Feedback sent!',
              })
            );
          } else if (data.type === FEEDBACK_TYPE_NOTE(formatMessage).id) {
            toast.success(
              formatMessage({
                id: 'app.feedback.modal_feedback_editor_button.toast.note_saved',
                defaultMessage: 'Note saved!',
              })
            );
          } else {
            console.error(
              formatMessage(
                {
                  id: 'app.feedback.modal_feedback_editor_button.toast.unrecognized_type',
                  defaultMessage: 'Unrecognized type: {type}',
                },
                { type: data.type }
              )
            );
          }
        } else {
          // no type means this is a feedback request
          toast.success(
            formatMessage({
              id: 'app.feedback.modal_feedback_editor_button.toast.feedback_request_sent',
              defaultMessage: 'Feedback request sent!',
            })
          );
        }

        // if external callback provided, pass data back to it as well
        if (propsCallback) {
          propsCallback(data);
        }

        // reset data so showing model doesn't have old data in it
        resetModalData();

        // refresh tasks (which may go down by one if this feedback was in response
        // to a request
        // @ts-expect-error
        propsLoadTasks(userSub, props.currentProxyPerson?.email);
      }
    },
    [
      propsCallback,
      propsLoadTasks,
      resetModalData,
      userSub,
      // @ts-expect-error
      props.currentProxyPerson?.email,
      formatMessage,
    ]
  );

  const getButtonComponent = useCallback(
    (p) =>
      props.hideButton ? (
        <></>
      ) : (
        <Button color="primary" {...p}>
          {propsButtonText}
        </Button>
      ),
    [propsButtonText, props.hideButton]
  );

  const onClosed = useCallback(() => {
    // we reset this dialog as we've disabled closing accidentally via escape
    // and the component itself asks "are you sure?" if they try to close it
    // without submitting after putting data in
    resetModalData();
    if (propsOnClosed) {
      propsOnClosed();
    }
  }, [propsOnClosed, resetModalData]);

  // @ts-expect-error
  const validDomains = props.currentOrganization?.email_domains;

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (!obj.type) {
        errors['type'] = formatMessage({
          id: 'app.feedback.modal_feedback_editor_button.error.type',
          defaultMessage: 'A type is required.',
        });
      }

      errors['subject_people'] =
        obj.subject_people?.length > 0
          ? getInvalidPeopleError(
              formatMessage,
              obj.subject_people,
              validDomains
            )
          : formatMessage({
              id: 'app.feedback.modal_feedback_editor_button.error.subject_people',
              defaultMessage: 'At least one person is required.',
            });

      if (obj.type?.id === FEEDBACK_TYPE_REQUEST(formatMessage).id) {
        errors['requested_people'] =
          obj.requested_people?.length > 0
            ? getInvalidPeopleError(
                formatMessage,
                obj.requested_people,
                validDomains
              )
            : formatMessage({
                id: 'app.feedback.modal_feedback_editor_button.error.requested_people',
                defaultMessage: 'At least one person is required.',
              });
      }

      if (!(obj.topics?.length > 0) && requireSkillsAndActivities) {
        errors['topics'] = formatMessage({
          id: 'app.feedback.modal_feedback_editor_button.error.topics',
          defaultMessage: 'At least one activity or skill is required.',
        });
      }

      // comments are only required if there are no custom
      // questions
      if (
        !customQuestionsList &&
        obj.type?.id !== FEEDBACK_TYPE_REQUEST(formatMessage).id &&
        !(obj.comments?.length > 0)
      ) {
        errors['comments'] = formatMessage({
          id: 'app.feedback.modal_feedback_editor_button.error.comments',
          defaultMessage: 'Comments are required.',
        });
      }

      return errors;
    },
    [
      formatMessage,
      validDomains,
      requireSkillsAndActivities,
      customQuestionsList,
    ]
  );

  const sanitizeCustomQuestionsForSubmit = useCallback((customQuestions) => {
    return customQuestions?.map((q, index) => ({
      ...q,
      // strip organization field that is injected in each
      // custom question
      organization: undefined,
      // replace any unique names (which are used as IDs) with custom_question_<#>
      // (1-indexed instead of 0-indexed in case we export these to CSV for a client)
      // to ensure they are unique which is necessary so when a person replies,
      // we store the responses uniquely and avoid data loss
      name: `custom_question_${index + 1}`,
    }));
  }, []);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      // if object has fields that start with custom_responses_*
      // then we need to transform them into a custom_responses array
      // with the id of the question as the key and the value as the value
      // and remove the custom_responses_* fields
      const customResponses = {};
      Object.keys(object).forEach((key) => {
        if (key.startsWith('custom_responses_')) {
          const questionId = key.substring('custom_responses_'.length);
          customResponses[questionId] = object[key];
        }
      });

      return {
        id: object.id,
        organization: props.currentOrganization?.id,
        type:
          object.type?.id === FEEDBACK_TYPE_REQUEST(formatMessage).id
            ? undefined
            : object.type.id,
        visibility:
          object.type?.id === FEEDBACK_TYPE_REQUEST(formatMessage).id
            ? undefined
            : object.type?.id === FEEDBACK_TYPE_NOTE(formatMessage).id
            ? FEEDBACK_VISIBILITY_AUTHOR_ONLY(formatMessage).id
            : object.visibility
            ? object.visibility
            : getDefaultFeedbackVisibilityValue(object.type, formatMessage),
        feedback_visibility:
          object.type?.id === FEEDBACK_TYPE_REQUEST(formatMessage).id
            ? object.feedback_visibility
            : undefined,
        subject_people: object.subject_people?.map((p) =>
          p.id ? p.id : { email: p.email }
        ),
        requested_people:
          object.type?.id === FEEDBACK_TYPE_REQUEST(formatMessage).id
            ? object.requested_people?.map((p) =>
                p.id ? p.id : { email: p.email }
              )
            : undefined,
        activities: prepTagsForSubmit(
          object.topics?.filter((t) => t._index === 'activities'),
          props.currentOrganization?.id,
          false
        ),
        skills: prepTagsForSubmit(
          object.topics?.filter((t) => t._index === 'skills'),
          props.currentOrganization?.id
        ),
        custom_questions: sanitizeCustomQuestionsForSubmit(
          object.custom_questions
        ),
        custom_responses: customResponses,
        comments: object.comments,

        // attach to feedback request if one was provided
        feedback_request:
          object.feedback_request &&
          (object.type?.id === FEEDBACK_TYPE_RECOGNITION(formatMessage).id ||
            object.type?.id === FEEDBACK_TYPE_ACTIONABLE(formatMessage).id)
            ? object.feedback_request.id
            : undefined,
        // Attach a mapped type if explicitly requested with object_id and content_type
        content_type: object?.content_type,
        object_id: object?.object_id,
      };
    },
    [
      props.currentOrganization?.id,
      formatMessage,
      sanitizeCustomQuestionsForSubmit,
    ]
  );

  const renderModalHeader = useCallback(
    (modalTitle) => {
      // @ts-expect-error
      return feedback?.type ? (
        <>
          <Button
            className="btn-sm me-2 btn-rounded-circle bg-primary-soft text-primary border-0"
            color="light"
            style={{ width: '1.5rem', height: '1.5rem' }}
            onClick={() => setFeedbackType(null)}
          >
            <span className="fe fe-chevron-left" />
          </Button>{' '}
          {modalTitle}
        </>
      ) : (
        modalTitle
      );
    },
    // @ts-expect-error
    [feedback?.type, setFeedbackType]
  );

  // render inputs into separate steps
  const renderInputs = useCallback(
    (inputs) => {
      const types = [];

      if (recognitionIsEnabled) {
        // @ts-expect-error
        types.push(FEEDBACK_TYPE_RECOGNITION(formatMessage));
      }

      if (givingFeedbackIsEnabled) {
        // @ts-expect-error
        types.push(FEEDBACK_TYPE_ACTIONABLE(formatMessage));
      }

      if (requestingFeedbackIsEnabled) {
        // @ts-expect-error
        types.push(FEEDBACK_TYPE_REQUEST(formatMessage));
      }

      if (privateNotesAreEnabled) {
        // @ts-expect-error
        types.push(FEEDBACK_TYPE_NOTE(formatMessage));
      }

      return (
        <>
          {/* @ts-expect-error */}
          {!feedback?.type && (
            <Row className="mb-n4">
              {types.map((type, index) => (
                <Col
                  key={index}
                  className="col-12 col-md-6 py-2 py-md-0 mb-4"
                  onClick={() => setFeedbackType(type)}
                >
                  <Card
                    // @ts-expect-error
                    id={'add-feedback-type-' + type.id}
                    className="py-4 mb-0 align-items-center lift"
                    role="button"
                    style={{
                      border:
                        // @ts-expect-error
                        feedback?.type === type ? '1px solid #2c7be5' : '',
                    }}
                  >
                    <div className="avatar">
                      <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                        {/* @ts-expect-error */}
                        <i className={type.icon}></i>
                      </div>
                    </div>
                    {/* @ts-expect-error */}
                    <h3 className="mb-0 mt-3">{type.heading}</h3>
                    {/* @ts-expect-error */}
                    <p className="small text-muted mb-0">{type.subheading}</p>
                  </Card>
                  <UncontrolledPopover
                    placement="top"
                    trigger="hover"
                    // @ts-expect-error
                    target={'add-feedback-type-' + type.id}
                  >
                    {/* @ts-expect-error */}
                    {type.description}
                  </UncontrolledPopover>
                </Col>
              ))}
            </Row>
          )}
          {/* @ts-expect-error */}
          {feedback.feedback_request &&
            // @ts-expect-error
            (feedback?.type?.id ===
              FEEDBACK_TYPE_RECOGNITION(formatMessage).id ||
              // @ts-expect-error
              feedback?.type?.id ===
                FEEDBACK_TYPE_ACTIONABLE(formatMessage).id) && (
              <div className="mb-3">
                {/* @ts-expect-error */}

                {feedback.feedback_request && (
                  <FeedbackRequestCard
                    className="mb-4"
                    // @ts-expect-error
                    feedbackRequest={feedback.feedback_request}
                  />
                )}
              </div>
            )}
          {/* @ts-expect-error */}
          {feedback?.type && <div className="mb-n4">{inputs}</div>}
        </>
      );
    },
    [
      // @ts-expect-error
      feedback?.feedback_request,
      // @ts-expect-error
      feedback?.type,
      givingFeedbackIsEnabled,
      privateNotesAreEnabled,
      recognitionIsEnabled,
      requestingFeedbackIsEnabled,
      setFeedbackType,
      formatMessage,
    ]
  );

  // render inputs into separate steps
  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {/* @ts-expect-error */}
          {feedback?.type?.id === FEEDBACK_TYPE_NOTE(formatMessage).id && (
            <div className="alert alert-light" role="alert">
              {/* @ts-expect-error */}
              {props.features?.continuous_feedback
                ?.notes_visible_to_hr_admins && (
                <>
                  <i className={'me-2 ' + consts.ICONS.PRIVATE} />{' '}
                  <FormattedMessage
                    id="app.views.feedback.modal_feedback_editor_button.only_you_and_hr_can_see"
                    defaultMessage="Only you and HR administrators can see these notes."
                  />
                </>
              )}
              {/* @ts-expect-error */}
              {!props.features?.continuous_feedback
                ?.notes_visible_to_hr_admins && (
                <>
                  <i className={'me-2 ' + consts.ICONS.PRIVATE} />{' '}
                  <FormattedMessage
                    id="app.views.feedback.modal_feedback_editor_button.only_you_can_see"
                    defaultMessage="Only you can see these notes."
                  />
                </>
              )}
            </div>
          )}
          {inputs}
          {/* @ts-expect-error */}
          <Row>{feedback?.type && <Col>{submitButton}</Col>}</Row>
        </>
      );
    },
    [
      // @ts-expect-error
      feedback?.type,
      // @ts-expect-error
      props.features?.continuous_feedback?.notes_visible_to_hr_admins,
      formatMessage,
    ]
  );

  const onChange = useCallback((obj) => {
    setFeedback(obj);
  }, []);

  const inputs = useMemo(() => {
    // @ts-expect-error
    return feedback?.type ? FEEDBACK_INPUTS[feedback?.type.id] : [];
    // @ts-expect-error
  }, [FEEDBACK_INPUTS, feedback?.type]);

  const getFormOnInputsChangeCallback = useCallback(() => {
    return (object, clear) => {
      // @ts-expect-error
      if (!feedback?.type) {
        clear();
      }
    };
    // @ts-expect-error
  }, [feedback?.type]);

  return (
    <ModalEditorButton
      block
      isOpen={props.isOpen}
      toggle={props.toggle}
      onOpened={props.onOpened}
      onClosed={onClosed}
      onChange={onChange}
      buttonComponentGenerator={getButtonComponent}
      inline={props.inline}
      buttonClassName={props.buttonClassName}
      url={
        // @ts-expect-error
        feedback?.type?.id === FEEDBACK_TYPE_REQUEST(formatMessage).id
          ? 'feedback-requests'
          : 'feedback'
      }
      title={title}
      modalTitle={modalTitle}
      submitText={submitText}
      anchorTrigger={anchorTrigger}
      object={feedback}
      onValidate={onValidate}
      transformObjectBeforeSubmit={transformObjectBeforeSubmit}
      callback={onSubmitCallback}
      renderModalHeader={renderModalHeader}
      renderInputs={renderInputs}
      renderForm={renderForm}
      inputs={inputs}
      // if there's an inner form with a preview element that has "required" on it
      // we don't want to prevent the request from submitting
      noValidate={
        requestingFeedbackCustomQuestionsAreEnabled ? true : undefined
      }
      onInputsChange={getFormOnInputsChangeCallback()}
    />
  );
};

const mapStateToProps = (state: ReduxState) => {
  const {
    me,
    currentOrganization,
    currentProxyPerson,
    features,
    attachedContentTypes,
  } = state;

  return {
    me,
    currentOrganization,
    currentProxyPerson,
    features,
    attachedContentTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTasks: (userSub, proxy) => dispatch(loadTasks(userSub, proxy)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalFeedbackEditorButton));
