import React, { CSSProperties, FC } from 'react';

import Image from './Image';
import Video from './Video';

interface Props {
  link: string;
  style?: CSSProperties;
}

const isVideo = (fileName) => {
  const extension = fileName.split('.').pop();
  switch (extension.toLowerCase()) {
    case 'mp4':
    case 'webm':
    case 'ogg':
    case 'mov':
    case 'quicktime':
    case 'm4v':
    case 'avi':
    case 'mpg':
      return true;
    default:
      return false;
  }
};

export const Media: FC<Props> = ({
  link,
  // cap at 100% so we don't overflow content, and
  // set a default width that is reasonable for most
  // content, especially in activity feeds, mobile, etc.
  style = { maxWidth: '100%', width: '470px' },
}) => {
  return (
    <>
      {link &&
        (isVideo(link) ? (
          <Video link={link} style={style} />
        ) : (
          <Image link={link} style={style} />
        ))}
    </>
  );
};

export default Media;
